import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import humanFileSize from "@src/helpers/humanFileSize"
import Dropzone from "react-dropzone"
import { updateVideo } from "@api/ondemand_videos"
import { initializeHttpUpload } from "@api/transfer/http/upload"
import { videoStatusLabels } from "@helpers/constants"

class VideoUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      iteration: 0
    }
  }
  onDropSourceFile(file) {
    this.setState({ file_size_error: false, upload_pending: true })
    if (file.size > this.props.uploadLimitInGB * 1024 * 1024 * 1024) {
      this.setState({ file_size_error: true, upload_pending: false })
      return
    }
    const specs = {
      video_id: this.props.video._id
    }
    this.props.uploading()
    this.props.initializeHttpUpload(
      file,
      specs,
      result => {
        this.setState({ upload_FULFILLED: true })
        this.props.updateVideo(this.props.video._id, { status: videoStatusLabels.UPLOADED })
      },
      transfer => {
        this.setState({
          message: transfer.status,
          uploaded: transfer.current_size,
          total: transfer.total_size
        })
      }
    )
  }
  render() {
    return (
      <div className={this.state.upload_pending ? "flex-auto" : ""}>
        {this.state.upload_pending ? (
          <div className='col-12'>
            {this.state.message}
            <progress max={this.state.total} value={this.state.uploaded} className='cs-progress col-12 block my1'/>
            <div className='small muted'>
              {humanFileSize(this.state.uploaded)} / {humanFileSize(this.state.total)} uploaded
            </div>
          </div>
        ) : (
          <>
            <Dropzone
              multiple={false}
              disabled={this.state.upload_pending}
              accept='.mp4,.mov'
              onDrop={(accepted, rejected) => this.onDropSourceFile(accepted[0])}
              className='dropzone'>
              <button
                disabled={this.state.upload_pending}
                className={`cs-button col-12 outline small nowrap ${this.state.upload_pending ? "pending" : ""}`}>
                Upload File From Computer
              </button>
            </Dropzone>
            {this.state.file_size_error && (
              <div className='red center'>Max file size is {this.props.uploadLimitInGB}GB.</div>
            )}
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  video: state.ondemand_videos.video,
  uploadLimitInGB: state.ondemand_videos.uploadLimitInGB
})

const mapDispatchToProps = dispatch => ({
  initializeHttpUpload: bindActionCreators(initializeHttpUpload, dispatch),
  updateVideo: bindActionCreators(updateVideo, dispatch)
})

VideoUpload.propTypes = {
  video: PropTypes.object.isRequired,
  initializeHttpUpload: PropTypes.func.isRequired,
  updateVideo: PropTypes.func.isRequired,
  uploadLimitInGB: PropTypes.number.isRequired,
  uploading: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoUpload)
