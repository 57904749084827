import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateNewReviewers } from "@api/preview/send"

const EmailsFooter = ({ isNextDisabled, updateNewReviewers }) => {
  return (
    <div className='mt3 flex justify-end'>
      <button
        className='cs-button'
        disabled={isNextDisabled}
        onClick={() => updateNewReviewers("email", "step", "sharing-options")}>
        Next: Sharing Options
      </button>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  updateNewReviewers: bindActionCreators(updateNewReviewers, dispatch)
})

EmailsFooter.propTypes = {
  isNextDisabled: PropTypes.bool.isRequired,
  updateNewReviewers: PropTypes.func.isRequired
}

export default connect(
  null,
  mapDispatchToProps
)(EmailsFooter)
