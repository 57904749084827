import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateSupport } from "@api/account/support"
import { push } from "connected-react-router"
import { logout } from "@api/account/auth"
import { updateReviewersTopUp } from "@api/account/reviewers"
import { ButtonDropdown } from "@bitcine/cs-theme"
import { css } from "emotion"
import humanFileSize from "@src/helpers/humanFileSize"
import { toggleOnboardingModal } from "@api/modals"
import { GLOBALS } from "@src/config"
import AsperaLogo from "@src/public/aspera.png"
import 'styled-components/macro'

const User = ({
  user,
  updateSupport,
  toggleOnboardingModal, 
  updateReviewersTopUp,
  logout,
  push,
  mobile = false
}) => (
  <ButtonDropdown
    button={mobile ? {
      className: "link ml2 material-icons",
      text: "manage_accounts"
    } : {
      className: "link ml2",
      text: (
        <div className='flex items-center'>
          <span>{user.full_name}</span>
          <span className='ml2 material-icons'>keyboard_arrow_down</span>
        </div>
      )
    }}
    dropdown={{
      clickCloses: true,
      content: [
        {
          text: (
            <div className='py1'>
              <strong className='block'>My Storage Usage</strong>
              <div className='relative rounded overflow-hidden bg-gray-6 py1 mt1'>
                <div
                  css={`
                    width: ${(user.subscription.storage.used / user.subscription.storage.total) * 100}%;
                  `}
                  className='absolute left-0 top-0 bottom-0 bg-red'/>
              </div>
              <small>
                <strong>{humanFileSize(user.subscription.storage.used)}</strong>
                &nbsp;used of&nbsp;
                <strong>{humanFileSize(user.subscription.storage.total)}</strong>
              </small>
            </div>
          ),
          className: css`
            height: auto;
            min-height: auto;
            text-align: left;
            :hover {
              background: white;
              cursor: default;
            }
            > div {
              margin-right: 0;
              width: 100%;
            }
          `,
          breakAfter: true,
          show: user.subscription.storage
        },
        {
          text: (
            <div className='flex items-center'>
              <img
                className='cs-img mr2'
                src={AsperaLogo}
                css={`
                  width: 20px;
                  height: 20px;
                  filter: grayscale(100%);
                `}/>
              <strong>
                {user.organization && user.organization.aspera_entitlement_bytes_remaining
                  ? humanFileSize(user.organization.aspera_entitlement_bytes_remaining)
                  : "No"}
              </strong>
              &nbsp;Aspera Entitlements Remaining
            </div>
          ),
          onClick: () => window.open(GLOBALS.ACCOUNTS_URL + '/settings/aspera', "_blank"),
          show: user.organization
        },
        {
          text: (
            <div className='flex items-center'>
              <strong>
                {user.subscription.reviewers.remaining} / {user.subscription.reviewers.total}
              </strong>
              &nbsp;Monthly Screener Links
            </div>
          ),
          icon: "ondemand_video",
          show: user.subscription.reviewers.total > 0,
          breakAfter: user.subscription.forensic_watermark_reviewers.total === 0,
          onClick: () => {
            updateReviewersTopUp("visible", true)
          }
        },
        {
          text: (
            <div className='flex items-center'>
              <strong>
                {user.subscription.forensic_watermark_reviewers.remaining} /{" "}
                {user.subscription.forensic_watermark_reviewers.total}
              </strong>
              &nbsp;Monthly Forensic Watermark Screener Links
            </div>
          ),
          icon: "policy",
          className: css`
            :hover {
              background: white;
              cursor: default;
            }
          `,
          show: user.subscription.forensic_watermark_reviewers.total > 0,
          breakAfter: true
        },
        {
          text: "My Orders",
          icon: "shopping_basket",
          onClick: () => push("/orders"),
          show: true,
          breakAfter: true
        },
        {
          text: "Organization Settings",
          icon: "settings",
          onClick: () => window.open(GLOBALS.ACCOUNTS_URL + '/settings/team', "_blank"),
          show: user.organization_id !== null
        },
        {
          text: "My Settings",
          icon: "person",
          onClick: () => window.open(GLOBALS.ACCOUNTS_URL + '/settings/profile', "_blank"),
          show: true,
          breakAfter: true
        },
        {
          text: "Support",
          icon: "help",
          onClick: () => updateSupport("visible", true),
          show: true
        },
        {
          text: "Show Tutorial",
          icon: "error",
          onClick: toggleOnboardingModal,
          show: true
        },
        {
          text: "Sign Out",
          icon: "exit_to_app",
          onClick: logout,
          show: true
        }
      ].filter(({ show }) => show)
    }}/>
)

User.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  updateSupport: PropTypes.func.isRequired,
  toggleOnboardingModal: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: state.user.auth
})

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(logout, dispatch),
  updateSupport: bindActionCreators(updateSupport, dispatch),
  toggleOnboardingModal: bindActionCreators(toggleOnboardingModal, dispatch),
  updateReviewersTopUp: bindActionCreators(updateReviewersTopUp, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(User)
