import {
  UPDATE_REVIEWERS_TOP_UP,
  PURCHASE_REVIEWERS_PENDING,
  PURCHASE_REVIEWERS_FAILED,
  PURCHASE_REVIEWERS_FULFILLED
} from "@actions/account/reviewers"

const initialState = {
  visible: false,
  quantity: 1,
  credit_card_id: ""
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_REVIEWERS_TOP_UP:
      if (action.key === "visible" && !action.value) return initialState
      else
        return {
          ...state,
          [action.key]: action.value
        }

    case PURCHASE_REVIEWERS_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case PURCHASE_REVIEWERS_FAILED:
      return {
        ...state,
        status: "ERROR",
        error_message: action.payload.message
      }

    case PURCHASE_REVIEWERS_FULFILLED:
      return {
        ...state,
        status: "COMPLETE",
        invoice_id: action.payload.invoice_id
      }

    default:
      return state
  }
}
