import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import copy from "copy-to-clipboard"
import { addGlobalMessage } from "@api/utils/"
import { Modal, Status } from "@bitcine/cs-theme"
import { toggleModal, sendRequest } from "@api/project/requests"

import { VALIDATE_EMAIL } from "@constants"

class Share extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      message: "",
      show_message: false
    }
    this.url = `${window.location.protocol}//${window.location.host}/file-request/${props.request._id}`
  }
  close = () => {
    this.props.toggleModal("show_share")
  }
  send = () => {
    this.props.sendRequest(this.props.request._id, this.state.email, this.state.message)
  }
  render() {
    return (
      <Modal open title='Share File Request' onClose={this.close}>
        <Status pending={this.props.status === "PENDING"} error={this.props.status === "ERROR"}>
          <div>
            <div className='border-bottom border-gray-5 pb3'>
              <label className='cs-label'>Copy link below to share</label>
              <div className='flex'>
                <input
                  ref={input => (this.linkCopy = input)}
                  type='text'
                  placeholder='Link'
                  value={this.url}
                  onChange={() => null}
                  onClick={() => this.linkCopy.select()}
                  className='cs-input flex-auto'/>
                <button
                  className='cs-button white ml1'
                  onClick={() => {
                    this.props.addGlobalMessage("Link Copied!", "success")
                    copy(this.url, { format: "text/plain" })
                  }}>
                  Copy link
                </button>
              </div>
            </div>
            <div className='border-bottom border-gray-5 py3 mb3'>
              <h4 className='mb1'>Share by email</h4>
              <label className='cs-label'>To:</label>
              <input
                type='email'
                placeholder='Enter an email address'
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                className='cs-input col-12'/>
              {!this.state.show_message && (
                <div className='col-12 right-align'>
                  <button className='cs-button link' onClick={() => this.setState({ show_message: true })}>
                    Add a personal message
                  </button>
                </div>
              )}
              {this.state.show_message && (
                <div className='pt1'>
                  <label className='cs-label'>Add a personal message:</label>
                  <textarea
                    value={this.state.message}
                    onChange={e => this.setState({ message: e.target.value })}
                    placeholder='Enter a message...'
                    className='cs-textarea col-12'/>
                </div>
              )}
            </div>
            <div className='col-12 right-align'>
              <button className='cs-button ml2' onClick={this.send} disabled={!VALIDATE_EMAIL.test(this.state.email)}>
                Send
              </button>
            </div>
          </div>
        </Status>
      </Modal>
    )
  }
}

Share.propTypes = {
  addGlobalMessage: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  sendRequest: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  request: state.project.requests.active_request,
  status: state.project.requests.share_status
})

const mapDispatchToProps = dispatch => ({
  addGlobalMessage: bindActionCreators(addGlobalMessage, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  sendRequest: bindActionCreators(sendRequest, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Share)
