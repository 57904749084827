import React from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import DateTime from "./datetime"

const ReviewerAvailableAt = ({ available_at }) => (
  <span className='caption'>
    {available_at ? (
      dayjs(available_at).isBefore() ? (
        <span className='muted'>Available Now</span>
      ) : (
        <DateTime date={available_at}/>
      )
    ) : (
      <span className='muted'>Available Now</span>
    )}
  </span>
)

ReviewerAvailableAt.propTypes = {
  available_at: PropTypes.string
}

export default ReviewerAvailableAt
