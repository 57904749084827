import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateNewPaymentMethod, submit } from "@api/account/billing"
import Select from "react-select"
import CreditCardInput from "@components/credit_card_input"
import { ReactComponent as AmexSVG } from "@public/payment/svgs/amex-accepted.svg"
import { ReactComponent as MastercardSVG } from "@public/payment/svgs/mastercard-accepted.svg"
import { ReactComponent as VisaSVG } from "@public/payment/svgs/visa-accepted.svg"
import { Modal } from "@bitcine/cs-theme"
import { NEW_PAYMENT_INPUTS } from "./constants"
import 'styled-components/macro'

const InnerForm = ({ inline, updateNewPaymentMethod, newPaymentMethod, submit, disableGiftCards }) => (
  <div>
    {!disableGiftCards && (
      <div className='pb3 flex items-center'>
        <a
          data-testid='settings-billing-add-credit-card-btn'
          className={`pointer rounded p2 mr2 center inline-block border ${
            newPaymentMethod.mode === "CREDIT_CARD" ? "" : "light border-gray-5"
          }`}
          css={`
            width: 105px;
            ${newPaymentMethod.mode === "CREDIT_CARD" ? `box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);` : ""}
          `}
          onClick={() => updateNewPaymentMethod("mode", "CREDIT_CARD")}>
          <span className='block material-icons'>credit_card</span>
          Credit Card
        </a>
        <a
          data-testid='settings-billing-add-gift-card-btn'
          className={`pointer rounded center p2 mr2 inline-block border ${
            newPaymentMethod.mode === "GIFT_CARD" ? "" : "light border-gray-5"
          }`}
          css={`
            width: 105px;
            ${newPaymentMethod.mode === "CREDIT_CARD" ? `box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);` : ""}
          `}
          onClick={() => updateNewPaymentMethod("mode", "GIFT_CARD")}>
          <span className='block material-icons'>card_giftcard</span>
          Gift Card
        </a>
        <div className='flex-auto'/>
        {newPaymentMethod.mode === "CREDIT_CARD" && (
          <div>
            <div>Accepted cards</div>
            <VisaSVG alt='Visa'/>
            <MastercardSVG alt='MasterCard'/>
            <AmexSVG alt='Amex'/>
          </div>
        )}
      </div>
    )}
    <div className='flex flex-wrap my1'>
      {NEW_PAYMENT_INPUTS[newPaymentMethod.mode].map((input, i) => (
        <div className={input.className} key={i}>
          <label className='cs-label' htmlFor={`__${input.key}`}>
            {input.label}
          </label>
          {input.type === "text" ? (
            <div>
              {input.key === "cardNumber" || input.key === "code" ? (
                <CreditCardInput
                  testid={`settings-billing-new-${input.key}`}
                  value={newPaymentMethod[input.key]}
                  id={`__${input.key}`}
                  extraClasses={
                    newPaymentMethod.error && input.errorTypes.includes(newPaymentMethod.error.code) ? "error" : ""
                  }
                  onChange={value => updateNewPaymentMethod(input.key, value)}
                  onCardStatusChange={status => {}}
                  isGiftCard={input.key === "code"}/>
              ) : (
                <input
                  type='text'
                  data-testid={`settings-billing-new-${input.key}`}
                  value={newPaymentMethod[input.key]}
                  className={`cs-input col-12 no-min-width ${
                    newPaymentMethod.error && input.errorTypes.includes(newPaymentMethod.error.code) ? "error" : ""
                  }`}
                  id={`__${input.key}`}
                  onChange={({ target: { value } }) => updateNewPaymentMethod(input.key, value)}/>
              )}
            </div>
          ) : (
            <Select
              inputProps={{
                "data-testid": `settings-billing-new-${input.key}`
              }}
              options={input.options.map(opt => ({
                value: opt,
                label: opt
              }))}
              value={newPaymentMethod[input.key]}
              className={`col-12 no-min-width ${
                newPaymentMethod.error && input.errorTypes.includes(newPaymentMethod.error.code) ? "error" : ""
              }`}
              placeholder={input.placeholder}
              onChange={({ value }) => updateNewPaymentMethod(input.key, value)}
              clearable={false}/>
          )}
        </div>
      ))}
    </div>
    {newPaymentMethod.error && (
      <div data-testid='settings-billing-new-card-error-msg' className='red my2'>
        {newPaymentMethod.error.message}
      </div>
    )}
    <div className='flex justify-end mt2 pt3'>
      <button
        data-testid='settings-billing-new-add-card-btn'
        disabled={newPaymentMethod.status === "PENDING"}
        className={`cs-button ${newPaymentMethod.status === "PENDING" ? "pending" : ""}`}
        onClick={submit}>
        Add card
      </button>
    </div>
  </div>
)

InnerForm.propTypes = {
  updateNewPaymentMethod: PropTypes.func.isRequired,
  newPaymentMethod: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  disableGiftCards: PropTypes.bool.isRequired,
  inline: PropTypes.bool.isRequired
}

const NewPaymentMethod = ({ inline, updateNewPaymentMethod, newPaymentMethod, submit, disableGiftCards }) =>
  inline ? (
    <InnerForm
      inline={inline}
      updateNewPaymentMethod={updateNewPaymentMethod}
      newPaymentMethod={newPaymentMethod}
      submit={submit}
      disableGiftCards={disableGiftCards}/>
  ) : (
    <Modal
      testId='add-payment-method-modal'
      id={newPaymentMethod.mode}
      open
      title='Add Payment Method'
      onClose={() => updateNewPaymentMethod("visible", false)}
      showConfirmationOnClose={true}>
      <InnerForm
        inline={inline}
        updateNewPaymentMethod={updateNewPaymentMethod}
        newPaymentMethod={newPaymentMethod}
        submit={submit}
        disableGiftCards={disableGiftCards}/>
    </Modal>
  )

NewPaymentMethod.defaultProps = {
  inline: false
}

NewPaymentMethod.propTypes = {
  updateNewPaymentMethod: PropTypes.func.isRequired,
  newPaymentMethod: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  disableGiftCards: PropTypes.bool.isRequired,
  inline: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  newPaymentMethod: state.user.billing.newPaymentMethod
})

const mapDispatchToProps = dispatch => ({
  updateNewPaymentMethod: bindActionCreators(updateNewPaymentMethod, dispatch),
  submit: bindActionCreators(submit, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPaymentMethod)
