import { ADD_TRANSFER, ASPERA_PROGRESS } from "@actions/transfers/"
import { removeTransfer, updateTransfer } from "@api/transfer/"
import { updateUpload, completeUpload } from "@api/transfer/upload"
//import { TOGGLE_ASPERA_UPGRADE_MESSAGE } from "@actions/utils/"
import { addGlobalMessage, removeGlobalMessage } from "@api/utils/"
import dayjs from "dayjs"
import uuidv4 from "uuid/v4"
import { POST_DEFAULTS } from "@constants"
import { GLOBALS } from "@src/config"
import { createTransfer } from "../utils"

export const initializeAsperaUpload = specifications => (dispatch, getState) => {
  const isDCP = specifications.is_dcp

  // @Todo: Add a "source" to specifications to specify if it comes from CineSend Files, if so, then perform below:
  // Otherwise we are blocking unauthenticated uploads to file requests.
  // if (!getState().user.auth.can_use_udp_uploads) {
  //   dispatch({ type: TOGGLE_ASPERA_UPGRADE_MESSAGE })
  //   return
  // }

  const msgID = dispatch(addGlobalMessage("Initializing Aspera...", "info", "cloud_upload", 0))
  const uuid = uuidv4()
  const sdkLocation = "//d3gcli72yxqn2z.cloudfront.net/connect/v4"

  const asperaWeb = new AW4.Connect({
    sdkLocation,
    minVersion: "3.6.0",
    id: uuid
  })

  const asperaInstaller = new AW4.ConnectInstaller({ sdkLocation })

  asperaWeb.initSession(`nodeConnect-${uuid}`)

  asperaWeb.addEventListener(AW4.Connect.EVENT.STATUS, (eventType, status) =>
    dispatch(asperaWebEventListener(eventType, status, asperaInstaller))
  )
  asperaWeb.addEventListener("transfer", (e, asperaObject) => dispatch(asperaTransferEvents(e, asperaObject, uuid)))
  const callBacks = {
    success: ({ dataTransfer }) => {
      dispatch(removeGlobalMessage(msgID))

      if (!dataTransfer.files.length) {
        asperaWeb.stop()
        return
      }

      dataTransfer.files
        // .filter(file => {
        //   return file.type !== "application/zip"
        // })
        .forEach(file => {
          const sourceFilePath = file.name
          const name = file.name.split("/").pop()
          const newFileList = file
          newFileList.name = name

          const data = {
            method: "Aspera",
            file_list: [newFileList],
            specifications
          }

          const OPTS = {
            ...POST_DEFAULTS,
            body: JSON.stringify(data)
          }

          dispatch(addGlobalMessage(`Preparing to upload ${name}...`, "info", "cloud_upload"))

          fetch(`${GLOBALS.API_URL}/uploads`, OPTS)
            .then(res => res.json())
            .then(({ upload_details, aspera_setup }) => {
              const contentDisposition = isDCP ? "attachment;" : 'attachment; filename="' + encodeURI(name) + '"'
              const transferSpec = {
                ...aspera_setup,
                tags: {
                  aspera: {
                    "cloud-metadata": [{ "Content-Disposition": contentDisposition }]
                  }
                },
                paths: [
                  {
                    source: sourceFilePath
                  }
                ],
                create_dir: isDCP, // only create a directory if its a folder upload
                destination_root: upload_details.destination,
                authentication: "token"
              }

              const connectSettings = {
                allow_dialogs: "no",
                use_absolute_destination_path: false,
                upload_details: upload_details
              }

              asperaWeb.startTransfer(transferSpec, connectSettings)
            })
        })
    },
    error: err => {
      dispatch(removeGlobalMessage(msgID))
      dispatch(setError({ message: err.user_message, code: err.code }))
    }
  }

  // Remove restrictions so people can upload any file using Aspera.
  // let restrictions = {
  //   allowedFileTypes: [
  //     {
  //       filter_name: "Video files",
  //       extensions: ["mp4", "mov"]
  //     },
  //     {
  //       filter_name: "Image files",
  //       extensions: ["jpeg", "jpg", "png"]
  //     },
  //     {
  //       filter_name: "Timed Text Files",
  //       extensions: ["vtt", "srt", "ttml"]
  //     }
  //   ]
  // }
  if (isDCP) asperaWeb.showSelectFolderDialog(callBacks, null)
  else asperaWeb.showSelectFileDialog(callBacks, null)

  if (!window.asperaWeb) window.asperaWeb = asperaWeb
}

/**
 * Aspera Web Callback
 * - Handles events around the aspera web events
 *
 * @param eventType (string)
 * @param dataStatus (string)
 *
 * @returns dispatch
 */

export const asperaWebEventListener = (eventType, dataStatus, asperaInstaller) => (dispatch, getState) => {
  const status = AW4.Connect.STATUS
  if (eventType === AW4.Connect.EVENT.STATUS) {
    switch (dataStatus) {
      case status.INITIALIZING:
        asperaInstaller.showLaunching()
        break
      case status.FAILED:
        asperaInstaller.showDownload()
        break
      case status.OUTDATED:
        asperaInstaller.showUpdate()
        break
      case status.RUNNING:
        asperaInstaller.connected()
        break
      case status.RETRYING:
        break
    }
  }
}

const asperaTransferEvents = (event, asperaObject, uuid) => (dispatch, getState) => {
  asperaObject.transfers
    .filter(transfer => transfer.aspera_connect_settings.app_id === `nodeConnect-${uuid}`)
    .forEach(transfer => {
      const uploadDetails = transfer.aspera_connect_settings.upload_details
      switch (transfer.status) {
        case "initiating":
          dispatch(add(transfer, uploadDetails))
          break
        case "queued":
          dispatch(updateTransfer("status", "Queued", transfer.uuid))
          break
        case "running":
          dispatch(running(transfer, uploadDetails))
          break
        case "completed":
          dispatch(completed(transfer.uuid))
          break
        case "removed":
          dispatch(removeTransfer(transfer.uuid))
          break
        case "cancelled":
          dispatch(updateTransfer("status", "Paused", transfer.uuid))
          break
        case "failed":
          dispatch(updateTransfer("status", "Error", transfer.uuid))
          dispatch(updateTransfer("errorMsg", "Upload failed", transfer.uuid))
          break
        default:
          break
      }
    })
}

const add = (transfer, uploadDetails) => (dispatch, getState) => {
  //const size = transfer.files ? transfer.files.forEach(f => (size += f.bytes_expected)) : 0
  dispatch({
    type: ADD_TRANSFER,
    uuid: transfer.uuid,
    transfer: createTransfer(uploadDetails, transfer.uuid)
  })
}

const running = (transfer, uploadDetails) => (dispatch, getState) => {
  if (!getState().transfers[transfer.uuid]) {
    dispatch(add(transfer, uploadDetails))
    return
  }

  let currentSize = 0
  const totalSize = transfer.bytes_expected
  transfer.files.forEach(f => {
    currentSize += f.bytes_written
  })
  const speed = Math.ceil(transfer.calculated_rate_kbps / 1024)
  const progress = `${Math.round((currentSize / totalSize) * 100)}%`
  const timeRemaining = `Will be finished ${dayjs().to(dayjs().add(transfer.remaining_usec / 1000, "milliseconds"))}`

  dispatch({
    type: ASPERA_PROGRESS,
    uuid: transfer.uuid,
    current_size: currentSize,
    time_remaining: timeRemaining,
    speed: `${speed.toFixed(0)} mbps`,
    total_size: totalSize,
    progress
  })

  let parentTransfer = getState().transfers[transfer.uuid]
  if (parentTransfer.transfer_iteration_token % 30 === 0) {
    dispatch(updateUpload(parentTransfer))
  }
}

const completed = transferUUID => (dispatch, getState) => {
  const transfer = getState().transfers[transferUUID]
  dispatch(
    completeUpload({
      method: "Aspera",
      uuid: transferUUID,
      total_size: transfer.total_size,
      name: transfer.name,
      upload_id: transfer.upload_id,
      callback: transfer.callback
    })
  )
  dispatch({
    type: ASPERA_PROGRESS,
    uuid: transferUUID,
    current_size: transfer.total_size,
    time_remaining: null,
    status: "Complete",
    total_size: transfer.total_size,
    progress: "100%"
  })
}

const setError = ({ message, code }) => dispatch => {}
