import React from "react"

const NotFound = () => (
  <section className='py4 my4 center'>
    <span className='material-icons light block'>warning</span>
    <h4 className='mb0'>404 Error</h4>
    <p className='mt0'>Whoops, this page was not found!</p>
  </section>
)

export default NotFound
