import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateNewReviewers } from "@api/preview/send"

const DeleteReviewer = ({ updateNewReviewers, list, reviewerKey }) => {
  const onDelete = () => {
    const new_list = JSON.parse(JSON.stringify(list))
    delete new_list[reviewerKey]
    updateNewReviewers("email", "list", new_list)
  }
  return (
    <button onClick={onDelete} className='cs-button link material-icons ml2'>
      delete
    </button>
  )
}

const mapStateToProps = state => ({
  list: state.preview.send.email.list
})

const mapDispatchToProps = dispatch => ({
  updateNewReviewers: bindActionCreators(updateNewReviewers, dispatch)
})

DeleteReviewer.propTypes = {
  list: PropTypes.object.isRequired,
  updateNewReviewers: PropTypes.func.isRequired,
  reviewerKey: PropTypes.string.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteReviewer)
