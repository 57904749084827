import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { logout } from "@api/account/auth"

const Suspended = ({ suspended, children, logout }) =>
  !suspended ? (
    children
  ) : (
    <div className='py4 my4 center'>
      <span className='large-icon material-icons'>error</span>
      <h4>Suspended</h4>
      <p>Your account has been suspended by your administrator.</p>
      <button onClick={logout} className='cs-button mt2 white'>
        Logout
      </button>
    </div>
  )

Suspended.propTypes = {
  children: PropTypes.node.isRequired,
  suspended: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  suspended: state.user.auth.is_suspended
})

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(logout, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Suspended)
