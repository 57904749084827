// TEAM MEMBERS
export const GET_TEAM_MEMBERS = "GET_TEAM_MEMBERS"
export const GET_TEAM_MEMBERS_PENDING = "GET_TEAM_MEMBERS_PENDING"
export const GET_TEAM_MEMBERS_FULFILLED = "GET_TEAM_MEMBERS_FULFILLED"

export const UPDATE_SEARCH_STRING = "UPDATE_SEARCH_STRING"

export const TOGGLE_ACTIVE_STATE = "TOGGLE_ACTIVE_STATE"
export const TOGGLE_ACTIVE_STATE_FULFILLED = "TOGGLE_ACTIVE_STATE_FULFILLED"

export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_FULFILLED = "DELETE_USER_FULFILLED"

export const CHANGE_ROLE = "CHANGE_ROLE"
export const CHANGE_ROLE_FULFILLED = "CHANGE_ROLE_FULFILLED"

export const RESEND_INVITE = "RESEND_INVITE"

export const UPDATE_NEW_MEMBER_FIELD = "UPDATE_NEW_MEMBER_FIELD"

export const INVITE_USER = "INVITE_USER"
export const INVITE_USER_PENDING = "INVITE_USER_PENDING"
export const INVITE_USER_FULFILLED = "INVITE_USER_FULFILLED"

export const TOGGLE_INVITE_MODAL = "TOGGLE_INVITE_MODAL"

// ROLES
export const GET_TEAM_ROLES = "GET_TEAM_ROLES"
export const GET_TEAM_ROLES_PENDING = "GET_TEAM_ROLES_PENDING"
export const GET_TEAM_ROLES_FULFILLED = "GET_TEAM_ROLES_FULFILLED"

export const TOGGLE_CREATE_ROLE_MODAL = "TOGGLE_CREATE_ROLE_MODAL"

export const GET_DEFAULT_PERMISSIONS = "GET_DEFAULT_PERMISSIONS"
export const GET_DEFAULT_PERMISSIONS_PENDING = "GET_DEFAULT_PERMISSIONS_PENDING"
export const GET_DEFAULT_PERMISSIONS_FULFILLED = "GET_DEFAULT_PERMISSIONS_FULFILLED"

export const UPDATE_NEW_ROLE_PERMISSION = "UPDATE_NEW_ROLE_PERMISSION"
export const UPDATE_NEW_ROLE = "UPDATE_NEW_ROLE"

export const SAVE_NEW_ROLE = "SAVE_NEW_ROLE"
export const SAVE_NEW_ROLE_PENDING = "SAVE_NEW_ROLE_PENDING"
export const SAVE_NEW_ROLE_FULFILLED = "SAVE_NEW_ROLE_FULFILLED"

export const TOGGLE_EDIT_MODAL = "TOGGLE_EDIT_MODAL"

export const UPDATE_ROLE_PERMISSION = "UPDATE_ROLE_PERMISSION"
export const UPDATE_ROLE = "UPDATE_ROLE"

export const SAVE_ROLE = "SAVE_ROLE"
export const SAVE_ROLE_PENDING = "SAVE_ROLE_PENDING"
export const SAVE_ROLE_FULFILLED = "SAVE_ROLE_FULFILLED"

export const DELETE_ROLE = "DELETE_ROLE"
export const DELETE_ROLE_FULFILLED = "DELETE_ROLE_FULFILLED"

export const SHOW_PROJECT_ACCESS_MODAL = "SHOW_PROJECT_ACCESS_MODAL"
export const HIDE_PROJECT_ACCESS_MODAL = "HIDE_PROJECT_ACCESS_MODAL"

export const GET_USER_ACCESS_PROJECTS = "GET_USER_ACCESS_PROJECTS"
export const GET_USER_ACCESS_PROJECTS_PENDING = "GET_USER_ACCESS_PROJECTS_PENDING"
export const GET_USER_ACCESS_PROJECTS_FULFILLED = "GET_USER_ACCESS_PROJECTS_FULFILLED"

export const SAVE_USER_ACCESS_PROJECTS = "SAVE_USER_ACCESS_PROJECTS"
export const SAVE_USER_ACCESS_PROJECTS_PENDING = "SAVE_USER_ACCESS_PROJECTS_PENDING"
export const SAVE_USER_ACCESS_PROJECTS_FULFILLED = "SAVE_USER_ACCESS_PROJECTS_FULFILLED"
