import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import dayjs from "dayjs"
import { Modal, Status, Button, Table, Checkbox } from "@bitcine/cs-theme"
import { getAvailableScreeners, addScreenersToRoom } from "@api/screener_rooms/"
import sort from '@helpers/sort'

const addScreeners = ({
  organizationID,
  screenerRoomID,
  projectID,
  isPending,
  items,
  getAvailableScreeners,
  addScreenersToRoom,
  onClose
}) => {
  const [selectedScreenerIDs, setSelectedScreenerIDs] = useState([])
  const [notifyReviewers, setNotifyReviewers] = useState(false)
  const savedSort = localStorage.getItem('screener-room-sorting')
  const [sorting, setSorting] = useState(savedSort ? JSON.parse(savedSort) : { direction: 'ASC', key: 'name' })
  useEffect(() => {
    getAvailableScreeners(organizationID, screenerRoomID, projectID)
  }, [organizationID, screenerRoomID, projectID, getAvailableScreeners])
  const onToggle = id => {
    if (selectedScreenerIDs.includes(id)) {
      setSelectedScreenerIDs(selectedScreenerIDs.filter(screenerID => screenerID != id))
    } else {
      setSelectedScreenerIDs([...selectedScreenerIDs, id])
    }
  }
  return (
    <Modal open={true} title='Add Screeners to Room' onClose={onClose} width={3}>
      <Status pending={isPending}>
        <Table
          widths={["auto", 350, 200]}
          header={{
            columns: [{ text: "Screener Name", key: 'name' }, { text: "Source File Name" }, { text: "Created At", key: 'created_at' }],
            sorting: {
              direction: sorting.direction,
              key: sorting.key,
              onSortChange: sorting => {
                setSorting(sorting)
                localStorage.setItem('screener-room-sorting', JSON.stringify(sorting))
              }
            }
          }}
          body={{
            data: sort(items, sorting.key, sorting.direction),
            row: {
              empty: {
                icon: "ondemand_video",
                title: "No encoded screeners were found in this project."
              },
              checkbox: {
                checked: ({ _id }) => selectedScreenerIDs.includes(_id),
                onChange: ({ _id }) => onToggle(_id)
              },
              onClick: (event, screener) => onToggle(screener._id),
              render: [
                screener => <span className='truncate'>{screener.name}</span>,
                screener => <span className='truncate'>{screener.source_file_name}</span>,
                screener => dayjs.utc(screener.created_at).format("MMMM D, YYYY")
              ]
            }
          }}/>
        <div className='flex justify-end mt2'>
          <div className='flex items-center'>
            <Checkbox
              label='Notify reviewers of changes'
              checked={notifyReviewers}
              onChange={() => setNotifyReviewers(!notifyReviewers)}/>
            <Button
              className='ml2'
              disabled={selectedScreenerIDs.length === 0}
              onClick={() =>
                addScreenersToRoom(organizationID, screenerRoomID, projectID, selectedScreenerIDs, notifyReviewers)
              }>
              Add Screeners
            </Button>
          </div>
        </div>
      </Status>
    </Modal>
  )
}

addScreeners.propTypes = {
  organizationID: PropTypes.string.isRequired,
  screenerRoomID: PropTypes.string.isRequired,
  projectID: PropTypes.string.isRequired,
  isPending: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  getAvailableScreeners: PropTypes.func.isRequired,
  addScreenersToRoom: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  isPending: state.screener_rooms.screenerRoom.availableScreeners.status === "PENDING",
  items: state.screener_rooms.screenerRoom.availableScreeners.items,
  organizationID: state.user.auth.organization_id,
  screenerRoomID: state.screener_rooms.screenerRoom.data._id
})

const mapDispatchToProps = dispatch => ({
  getAvailableScreeners: bindActionCreators(getAvailableScreeners, dispatch),
  addScreenersToRoom: bindActionCreators(addScreenersToRoom, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(addScreeners)
