import React from "react"

const Empty = () => (
  <div className='light absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center'>
    <div className='center muted'>
      <span className='large-icon block material-icons'>image</span>
      <h4>No Thumbnail</h4>
    </div>
  </div>
)

export default Empty
