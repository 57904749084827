import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import CSVReader from "react-csv-reader"
import { updateNewReviewers } from "@api/preview/send"
import uuid from "uuid/v4"

const AddReviewer = ({ remainingReviewers, updateNewReviewers, list }) => {
  return (
    <div className={"mt1"}>
      <div className='mt1 flex items-center'>
        <button
          onClick={() => {
            updateNewReviewers("email", "list", {
              ...list,
              [uuid()]: { email: "", full_name: "" }
            })
          }}
          disabled={remainingReviewers < 1}
          className='cs-button white small'>
          Add Another Reviewer
        </button>
        <button className='cs-button white small ml2' onClick={() => document.getElementById("csv-input-id").click()}>
          Import Reviewers by CSV
        </button>
        <CSVReader
          cssClass='display-none'
          inputId='csv-input-id'
          onFileLoaded={data => {
            let newReviewers = {}
            data.forEach(row => {
              newReviewers[uuid()] = {
                full_name: row[0] ? row[0].trim() : "",
                email: row[1] ? row[1].trim() : ""
              }
            })
            updateNewReviewers("email", "list", newReviewers)
          }}/>
      </div>
      <p className={"mt1 small italic"}>
        To import reviewers by CSV, upload a .csv file with no header row, the full name in the first column, and the
        email in the second column.
      </p>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const remaining = state.user.auth.subscription.reviewers.remaining
  const newReviewers = state.preview.send.email
  return {
    remainingReviewers:
      remaining === 1
        ? 0
        : Object.keys(newReviewers.list).length === 1
          ? remaining
          : remaining - Object.keys(newReviewers.list).length,
    list: newReviewers.list
  }
}

const mapDispatchToProps = dispatch => ({
  updateNewReviewers: bindActionCreators(updateNewReviewers, dispatch)
})

AddReviewer.propTypes = {
  updateNewReviewers: PropTypes.func.isRequired,
  remainingReviewers: PropTypes.number.isRequired,
  list: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(AddReviewer)
