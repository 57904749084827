import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { prevStep, nextStep, updateOrderFile } from "@api/project/orders"
import { saveFile } from "@api/project/files"
import Select from "react-select"
import "../styles.scss"

const FileInfo = ({ prevStep, nextStep, file, items, updateOrderFile, saveFile, fileOrderType }) => {
  return (
    <div className='col-12'>
      <div className={"orderInnerContainer"}>
        <p>
          Before continuing, we need a few more details about<strong> {file.name}.</strong>
        </p>
        <div className='mb2'>
          <label className='cs-label' htmlFor='length'>
            Duration (Mins.) <sup className='red'> *</sup>
          </label>
          <input
            className='cs-input col-5 block'
            placeholder='Minutes'
            id='length'
            autoComplete='off'
            onPaste={e => e.preventDefault()}
            value={file.length_in_minutes}
            onChange={({ target: { value } }) => updateOrderFile("length_in_minutes", value)}
            onBlur={({ target: { value } }) => saveFile(file._id, file.type, "length_in_minutes", parseFloat(value))}/>
        </div>
        {[
          {
            key: "frame_rate",
            opts: "frame_rates",
            label: "Frame Rate",
            required: true,
            searchable: false,
            include: true
          },
          {
            key: "resolution",
            opts: "resolutions",
            label: "Resolution",
            required: true,
            searchable: false,
            include: true
          },
          {
            key: "spoken_language",
            opts: "languages",
            label: "Spoken Language",
            required: false,
            searchable: true,
            include: fileOrderType !== "inspection" && fileOrderType !== "conversion"
          }
        ]
          .filter(prop => prop.include === true)
          .map(prop => (
            <div key={prop.key} className='mb2'>
              <label className='cs-label' htmlFor={prop.key}>
                {prop.label}
                {prop.required && <sup className='red'> *</sup>}
              </label>
              <Select
                id={prop.key}
                placeholder={`Select ${prop.label}...`}
                value={file[prop.key]}
                searchable={prop.searchable}
                clearable={false}
                isLoading={!items[prop.opts]}
                options={items[prop.opts]}
                onChange={({ value }) => {
                  updateOrderFile(prop.key, value)
                  saveFile(file._id, file.type, prop.key, value)
                }}/>
            </div>
          ))}
      </div>
      <div className='flex justify-between col-12 pt3'>
        <button className='cs-button white' onClick={prevStep}>
          Back
        </button>
        <button
          className='cs-button'
          disabled={!file.length_in_minutes || !file.resolution || !file.frame_rate}
          onClick={nextStep}>
          Next
        </button>
      </div>
    </div>
  )
}

FileInfo.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  items: PropTypes.object.isRequired,
  saveFile: PropTypes.func.isRequired,
  updateOrderFile: PropTypes.func.isRequired,
  fileOrderType: PropTypes.string.isRequired
}

const mapStateToProps = state => {
  const order = state.orders.active_order
  return {
    file: order.video_file,
    items: {
      resolutions: state.utils.resolutions,
      frame_rates: state.utils.frame_rates,
      languages: state.utils.languages
    },
    fileOrderType: order.type
  }
}

const mapDispatchToProps = dispatch => ({
  prevStep: bindActionCreators(prevStep, dispatch),
  nextStep: bindActionCreators(nextStep, dispatch),
  saveFile: bindActionCreators(saveFile, dispatch),
  updateOrderFile: bindActionCreators(updateOrderFile, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileInfo)
