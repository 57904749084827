import {
  SHOW_CREATE_PROJECT_FOLDER_MODAL,
  HIDE_CREATE_PROJECT_FOLDER_MODAL,
  CREATE_PROJECT_FOLDER_PENDING,
  CREATE_PROJECT_FOLDER_FULFILLED,
  GET_PROJECT_FOLDER_TREE_PENDING,
  GET_PROJECT_FOLDER_TREE_FULFILLED,
  SHOW_DELETE_FOLDER,
  HIDE_DELETE_FOLDER,
  DELETE_PROJECT_FOLDER_PENDING,
  DELETE_PROJECT_FOLDER_FULFILLED
} from "@actions/project_folders"

const initialState = {
  showCreateModal: false,
  isSaving: false,
  tree: [],
  loadingTree: true,
  folderToDelete: null,
  deleting: false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_CREATE_PROJECT_FOLDER_MODAL:
      return {
        ...state,
        showCreateModal: true
      }

    case HIDE_CREATE_PROJECT_FOLDER_MODAL:
      return {
        ...state,
        showCreateModal: false
      }

    case CREATE_PROJECT_FOLDER_PENDING:
      return {
        ...state,
        isSaving: true
      }

    case CREATE_PROJECT_FOLDER_FULFILLED:
      return {
        ...state,
        isSaving: false,
        showCreateModal: false
      }

    case GET_PROJECT_FOLDER_TREE_PENDING:
      return {
        ...state,
        loadingTree: true,
        tree: []
      }

    case GET_PROJECT_FOLDER_TREE_FULFILLED:
      return {
        ...state,
        loadingTree: false,
        tree: action.payload
      }

    case SHOW_DELETE_FOLDER:
      return {
        ...state,
        folderToDelete: action.payload.folder
      }

    case HIDE_DELETE_FOLDER:
      return {
        ...state,
        folderToDelete: null
      }

    case DELETE_PROJECT_FOLDER_PENDING:
      return {
        ...state,
        deleting: true
      }

    case DELETE_PROJECT_FOLDER_FULFILLED:
      return {
        ...state,
        folderToDelete: null,
        deleting: false
      }

    default:
      return state
  }
}
