import { addGlobalMessage } from "@api/utils"
import { DEFAULTS } from "@constants"
import { GLOBALS } from "@src/config"

export const initializeHTTPDownload = (file, downloadID = null) => dispatch =>
  fetch(`${GLOBALS.API_URL}/s3/download?fileID=${file._id}&fileType=${file.type}${downloadID ? `&downloadID=${downloadID}` : ''}`, DEFAULTS)
    .then(res => res.json())
    .then(res => {
      if (!res.token) {
        let message = "An error occurred. "
        message += res.message || "Please contact CineSend support."
        dispatch(addGlobalMessage(message, "error"))
        return
      }
      const link = document.createElement("a")
      link.style.display = "none"
      link.href = res.token
      link.download = "true"
      if (typeof link.download === "undefined") {
        link.setAttribute("target", "_blank")
      }
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
