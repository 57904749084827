export const SET_PROJECT = "SET_PROJECT"

export const GET_PROJECTS = "GET_PROJECTS"
export const GET_PROJECTS_PENDING = "GET_PROJECTS_PENDING"
export const GET_PROJECTS_FULFILLED = "GET_PROJECTS_FULFILLED"
export const GET_PROJECTS_FAILED = "GET_PROJECTS_REJECTED"

export const UPDATE_PROJECTS = "UPDATE_PROJECTS"

export const ADD_PROJECT = "ADD_PROJECT"
export const ADD_PROJECT_PENDING = "ADD_PROJECT_PENDING"
export const ADD_PROJECT_FAILED = "ADD_PROJECT_REJECTED"
export const ADD_PROJECT_FULFILLED = "ADD_PROJECT_FULFILLED"

export const UPDATE_PROJECTS_LIST = "UPDATE_PROJECTS_LIST"

export const PROJECT_IS_DRAGGING = "PROJECT_IS_DRAGGING"
export const PROJECT_IS_FINISHED_DRAGGING = "PROJECT_IS_FINISHED_DRAGGING"

export const TOGGLE_CHECKED_PROJECT = "TOGGLE_CHECKED_PROJECT"

export const SET_CONVERT_PROJECT_TO_FOLDER = "SET_CONVERT_PROJECT_TO_FOLDER"

export const DELETE_PROJECTS = "DELETE_PROJECTS"
export const DELETE_PROJECTS_PENDING = "DELETE_PROJECTS_PENDING"
export const DELETE_PROJECTS_FULFILLED = "DELETE_PROJECTS_FULFILLED"

export const CLOSE_PROJECT_DELETION_ERROR_MODAL = "CLOSE_PROJECT_DELETION_ERROR_MODAL"

export const SHOW_DELETE_PROJECT_MODAL = "SHOW_DELETE_PROJECT_MODAL"
export const HIDE_DELETE_PROJECT_MODAL = "HIDE_DELETE_PROJECT_MODAL"

export const TOGGLE_DELETE_PROJECT_MODAL = "TOGGLE_DELETE_PROJECT_MODAL"
