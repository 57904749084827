import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { showDeleteFolder } from "@api/project_folders"
import { push } from "connected-react-router"
import { ButtonDropdown } from "@bitcine/cs-theme"
import "../styles.scss"

class Header extends React.Component {
  getContent() {
    return [
      {
        text: "View Folder",
        onClick: e => this.props.push(`/projects/folder/${this.props.folder.slug}`)
      },
      {
        text: "Delete Folder",
        onClick: e => this.props.showDeleteFolder(this.props.folder)
      }
    ]
  }
  render() {
    return (
      <div className={`absolute z3 top-0 left-0 right-0 flex justify-end p1 header`}>
        <ButtonDropdown
          button={{
            className: "white",
            style: {
              width: "34px",
              minWidth: "34px",
              height: "34px",
              padding: "0"
            },
            onClick: e => {
              e.preventDefault()
              e.stopPropagation()
            },
            text: <span className='material-icons'>more_vert</span>
          }}
          dropdown={{
            clickCloses: true,
            content: this.getContent()
          }}/>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  showDeleteFolder: bindActionCreators(showDeleteFolder, dispatch),
  push: bindActionCreators(push, dispatch)
})

Header.propTypes = {
  showDeleteFolder: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  folder: PropTypes.object.isRequired
}

export default connect(
  null,
  mapDispatchToProps
)(Header)
