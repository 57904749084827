import React from "react"
import PropTypes from "prop-types"
import humanFileSize from "@helpers/humanFileSize"
import dayjs from "dayjs"
import Wrap from "./wrap"

const General = ({ types, file }) => (
  <Wrap title='General Info' defaultOpen={true}>
    <div>
      {[
        {
          label: "Type",
          value: types[file.type] === "Disc" ? file.disc_type : types[file.type]
        },
        {
          label: "Size",
          value: humanFileSize(file.size)
        },
        { label: "Bytes", value: file.size },
        {
          label: "Created",
          value: dayjs(file.created_at).format("MMM Do, YYYY")
        },
        {
          label: "Modified",
          value: dayjs(file.updated_at).format("MMM Do, YYYY")
        }
      ]
        .filter(row => !!row.value)
        .map(row => (
          <div key={row.label} className='flex items-center mb1'>
            <div className='col-6'>{row.label}:</div>
            <div className='col-6 bold'>{row.value}</div>
          </div>
        ))}
    </div>
  </Wrap>
)

General.defaultProps = {
  types: {
    dcp: "DCP",
    source_file: "Source File",
    audio_track: "Audio Track",
    subtitle_track: "Subtitles",
    closed_caption: "Closed Captions",
    disc_output: "Disc",
    folder: "Folder",
    document: "Document"
  }
}

General.propTypes = {
  file: PropTypes.object.isRequired,
  types: PropTypes.object.isRequired
}

export default General
