export const GET_SCREENER_REQUEST = "GET_SCREENER_REQUEST"
export const GET_SCREENER_REQUEST_PENDING = "GET_SCREENER_REQUEST_PENDING"
export const GET_SCREENER_REQUEST_FAILED = "GET_SCREENER_REQUEST_REJECTED"
export const GET_SCREENER_REQUEST_FULFILLED = "GET_SCREENER_REQUEST_FULFILLED"

export const UPDATE_SCREENER_IN_SCREENER_REQUEST = "UPDATE_SCREENER_IN_SCREENER_REQUEST"

export const GET_AVAILABLE_SCREENERS_FOR_SCREENER_REQUEST_PENDING =
  "GET_AVAILABLE_SCREENERS_FOR_SCREENER_REQUEST_PENDING"
export const GET_AVAILABLE_SCREENERS_FOR_SCREENER_REQUEST = "GET_AVAILABLE_SCREENERS_FOR_SCREENER_REQUEST"
export const GET_AVAILABLE_SCREENERS_FOR_SCREENER_REQUEST_FULFILLED =
  "GET_AVAILABLE_SCREENERS_FOR_SCREENER_REQUEST_FULFILLED"
export const GET_AVAILABLE_SCREENERS_FOR_SCREENER_REQUEST_FAILED = "GET_AVAILABLE_SCREENERS_FOR_SCREENER_REQUEST_REJECTED"

export const SYNC_SCREENER_WITH_SCREENERLAB = "SYNC_SCREENER_WITH_SCREENERLAB"
export const SYNC_SCREENER_WITH_SCREENERLAB_PENDING = "SYNC_SCREENER_WITH_SCREENERLAB_PENDING"
export const SYNC_SCREENER_WITH_SCREENERLAB_FAILED = "SYNC_SCREENER_WITH_SCREENERLAB_REJECTED"
export const SYNC_SCREENER_WITH_SCREENERLAB_FULFILLED = "SYNC_SCREENER_WITH_SCREENERLAB_FULFILLED"

export const UPDATE_SCREENER = {
  REQUEST: "UPDATE_SCREENER",
  PENDING: "UPDATE_SCREENER_PENDING",
  FAILED: "UPDATE_SCREENER_REJECTED",
  COMPLETE: "UPDATE_SCREENER_FULFILLED"
}
