export const AUTH_CHECK = "AUTH_CHECK"
export const AUTH_CHECK_PENDING = "AUTH_CHECK_PENDING"
export const AUTH_CHECK_FULFILLED = "AUTH_CHECK_FULFILLED"
export const AUTH_CHECK_FAILED = "AUTH_CHECK_REJECTED"

export const LOG_OUT = "LOG_OUT"
export const LOG_OUT_PENDING = "LOG_OUT_PENDING"

export const UPDATE_AUTH_USER = "UPDATE_AUTH_USER"

export const SAVE_USER = "SAVE_USER"
export const SAVE_USER_FULFILLED = "SAVE_USER_FULFILLED"

export const CLOSE_GLOBAL_AUTH = "CLOSE_GLOBAL_AUTH"
export const SHOW_GLOBAL_AUTH = "SHOW_GLOBAL_AUTH"

export const SET_AUTH_USER = "SET_AUTH_USER"
