import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal } from "@bitcine/cs-theme"
import { updatePackage } from "@api/project/packages"

const PasswordModal = ({ isPending, onClose, selectedPackage, updatePackage }) => {
  const [password, updatePassword] = useState("")
  return (
    <Modal open={true} title='Password Settings' onClose={onClose} isDismissable={!isPending}>
      <div>
        <label className='cs-label' htmlFor='password'>
          {selectedPackage.is_password_enabled ? "Update" : "Set"} Password
        </label>
        <input
          id='password'
          type='password'
          className='cs-input col-12'
          value={password}
          onChange={({ target: { value } }) => updatePassword(value)}/>
        {selectedPackage.is_password_enabled && <small>Leave blank for no password</small>}
        <div className='flex mt3'>
          <div className='flex-auto'>
            <button className='cs-button white mr2' disabled={isPending} onClick={onClose}>
              Cancel
            </button>
          </div>
          <button
            className='cs-button'
            onClick={() => {
              updatePackage(selectedPackage._id, "password", password, () => {
                updatePassword("")
                onClose()
              })
            }}
            disabled={isPending || (!selectedPackage.is_password_enabled && !password.length)}>
            {selectedPackage.is_password_enabled && !password.length ? "Remove Password" : "Save"}
          </button>
        </div>
      </div>
    </Modal>
  )
}

PasswordModal.propTypes = {
  isPending: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedPackage: PropTypes.object.isRequired,
  updatePackage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  isPending: state.project.packages.modal.status === "PENDING"
})

const mapDispatchToProps = dispatch => ({
  updatePackage: bindActionCreators(updatePackage, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordModal)
