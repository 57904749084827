import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateFilter } from "@api/project/filter/"
import { getTags } from "@api/project/tags/"
import { Status, Tag, ButtonDropdown, Checkbox } from "@bitcine/cs-theme"
import EmptyTagsIcon from "@src/public/icons/empty/tags.inline.svg"
import { filters } from "@components/files/constants"
import 'styled-components/macro'

class Filter extends React.Component {
  componentDidMount() {
    this.props.getTags()
  }
  onChange(key) {
    this.updateFilter(
      "types",
      this.props.filter.types.includes(key)
        ? this.props.filter.types.filter(type => type !== key)
        : [...this.props.filter.types, key]
    )
  }
  onAllChange() {
    this.updateFilter(
      "types",
      this.props.filter.types.length ? [] : filters.reduce((filter, allFilters) => ([...allFilters, filter], []))
    )
  }
  onClickTag(_id) {
    this.updateFilter(
      "tags",
      this.props.filter.tags.includes(_id)
        ? this.props.filter.tags.filter(tagID => tagID !== _id)
        : [...this.props.filter.tags, _id]
    )
  }
  updateFilter(key, value) {
    this.props.updateFilter(key, value)
  }
  render() {
    const { tags } = this.props
    const hasActiveFilters = this.props.filter.types.length > 0 || this.props.filter.tags.length > 0
    return (
      <ButtonDropdown
        button={{
          className: `ml1 small ${hasActiveFilters ? "outline" : "white"}`,
          text: (
            <div className='flex items-center'>
              <span style={{ fontSize: "16px" }} className='material-icons mr1'>
                filter_list
              </span>
              Filter
              {hasActiveFilters ? "s On" : ""}
            </div>
          )
        }}
        dropdown={{
          arrow: true,
          content: (
            <div className='p3 flex items-start'>
              {/* Disabled temporarily until tagging is fixed
              <div className='col-6 border-right border-gray-5'> */}
              <div>
                <h4>Filter By Type</h4>
                <div className='flex items-center mt1'>
                  <Checkbox onChange={() => this.onAllChange()} checked={this.props.filter.types.length === 0}/>
                  <label className='cs-label'>All</label>
                </div>
                {filters.map(filter => (
                  <div key={filter.key} className='mt1 pr4 flex items-center'>
                    <Checkbox
                      onChange={() => this.onChange(filter.key)}
                      checked={this.props.filter.types.includes(filter.key)}/>
                    <label className='cs-label'>{filter.label}</label>
                  </div>
                ))}
              </div>
              
              {/* Disable temporarily until tagging is fixed
              <div style={{ maxWidth: "300px" }} className='col-6 flex-auto pl3'>
                <h4>Filter By Tag</h4>
                <Status height={250} error={tags.status === "ERROR"} pending={tags.status === "PENDING"}>
                  {tags.status === "COMPLETE" && !tags.list.length ? (
                    <div className='center p2'>
                      <EmptyTagsIcon
                        css={`
                          height: 120px;
                        `}/>
                      <h4
                        css={`
                          color: #c7cdcf;
                          margin-top: -1em;
                        `}>
                        No tags
                      </h4>
                    </div>
                  ) : (
                    <div className='flex items-start flex-wrap mt1'>
                      {tags.list.map(tag => (
                        <Tag
                          red={this.props.filter.tags.includes(tag._id)}
                          onClick={() => this.onClickTag(tag._id)}
                          key={tag._id}
                          label={tag.name}/>
                      ))}
                    </div>
                  )}
                </Status>
              </div>
 */}

            </div>
          )
        }}/>
    )
  }
}

Filter.propTypes = {
  updateFilter: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  getTags: PropTypes.func.isRequired,
  tags: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  filter: state.project.files.filter,
  tags: state.project.files.tags
})

const mapDispatchToProps = dispatch => ({
  updateFilter: bindActionCreators(updateFilter, dispatch),
  getTags: bindActionCreators(getTags, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Filter)
