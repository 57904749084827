import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateOrder } from "@api/project/orders"
import { Checkbox } from "@bitcine/cs-theme"

const DriveSelection = ({ order, updateOrder }) =>
  order.drive ? (
    <div>
      {[{ key: "buy_asset", stateKey: "purchase" }, { key: "rent_asset", stateKey: "rent" }]
        .filter(type => order.drive[type.key])
        .map(type => (
          <div key={type.key} className='flex items-center mb1'>
            <Checkbox
              checked={order.drive_type === type.stateKey}
              id={type.key}
              onChange={() => updateOrder("drive_type", order.drive_type === type.stateKey ? "" : type.stateKey)}/>
            <label htmlFor={type.key} className='cs-label flex-auto pl1'>
              <strong className='capitalize'>{type.stateKey} a Drive</strong>
            </label>
            <strong>{order.drive[type.key].formatted_price}</strong>
          </div>
        ))}
      {order.drive_type && (
        <div className='flex justify-end items-center mt1'>
          <button
            disabled={order.drive_quantity < 2}
            onClick={() => updateOrder("drive_quantity", parseFloat(order.drive_quantity) - 1)}
            className='cs-button link material-icons'>
            remove_circle
          </button>
          <input
            className='cs-input no-min-width mx1'
            onPaste={e => e.preventDefault()}
            value={order.drive_quantity}
            style={{ width: "75px" }}
            onChange={({ target: { value } }) => {
              updateOrder("drive_quantity", value === "" ? 1 : parseFloat(value.replace(/\D/, "")))
            }}/>
          <button
            onClick={() => updateOrder("drive_quantity", parseFloat(order.drive_quantity) + 1)}
            className='cs-button link material-icons'>
            add_circle
          </button>
        </div>
      )}
      {order.drive_type === "rent" && (
        <div className='my3 rounded bg-gray-6 border border-gray-5 p2'>
          <strong>Rental Drive Terms</strong>
          <p>
            Rental drives require a pre-authorization for the full price. The drive must be returned within 60 days of
            the arrival date or the full price of the drive will be charged.
          </p>
          <div className='my2 flex items-center'>
            <strong className='flex-auto'>Full Price of Drive</strong>
            <strong>{order.drive.rent_asset.formatted_purchase_price}</strong>
          </div>
          <div className='flex items-center'>
            <Checkbox
              checked={order.accept_rental_drive_terms || false}
              onChange={() => updateOrder("accept_rental_drive_terms", !order.accept_rental_drive_terms)}
              id='acceptRentalTerms'/>
            <label className='cs-label pl1' htmlFor='acceptRentalTerms'>
              I agree to the rental terms
            </label>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className='my2 center'>
      <div className='spinner'/>
      <small className='block'>Loading Drives...</small>
    </div>
  )

DriveSelection.propTypes = {
  order: PropTypes.object.isRequired,
  updateOrder: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  order: state.orders.active_order
})

const mapDispatchToProps = dispatch => ({
  updateOrder: bindActionCreators(updateOrder, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriveSelection)
