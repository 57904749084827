import React from "react"
import PropTypes from "prop-types"

const ReviewerViewCountLimit = ({ viewCountLimit }) => (
  <span className='caption'>
    {viewCountLimit !== null ? <span>{viewCountLimit}</span> : (
      <span className='muted'>No Limit</span>
    )}
  </span>
)

ReviewerViewCountLimit.propTypes = {
  viewCountLimit: PropTypes.string
}


export default ReviewerViewCountLimit
