import React from "react"
import PropTypes from "prop-types"
import { args } from "../constants"

class File extends React.Component {
  constructor(props) {
    super(props)
  }
  removeFile(file) {
    const requiredFile = this.props.requiredFile

    // Either drop adds to an array or it takes the selected file's spot:
    if (requiredFile.allow_multiple) {
      let files = this.props.order[this.props.requiredFile.type]
      let index = files.indexOf(file)
      if (index !== -1) {
        files.splice(index)
        this.props.updateOrder(requiredFile.type, files)
      }
    } else {
      this.props.updateOrder(requiredFile.type, null)
    }
  }
  render() {
    const { requiredFile, selectedFile } = this.props
    return (
      <div
        style={{
          height: args[requiredFile.type].tall ? "140px" : "100px"
        }}
        className={`mb1 p2 border border-gray-5 rounded border-width-3`}>
        <div className='flex items-center catpion'>
          <div className='flex-auto muted flex items-center'>
            <span className='material-icons mr1'>{args[requiredFile.type].icon}</span>
            <span>{args[requiredFile.type].label}</span>
          </div>
          <a onClick={() => this.removeFile(selectedFile)} className='underline pointer'>
            Remove
          </a>
        </div>
        <strong className='truncate mt1 block'>{selectedFile.name}</strong>
      </div>
    )
  }
}

File.propTypes = {
  updateOrder: PropTypes.func.isRequired,
  requiredFile: PropTypes.object.isRequired,
  selectedFile: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  setActiveError: PropTypes.func.isRequired
}

export default File
