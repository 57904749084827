import {
  GET_TEAM_MEMBERS,
  UPDATE_SEARCH_STRING,
  TOGGLE_ACTIVE_STATE,
  DELETE_USER,
  CHANGE_ROLE,
  RESEND_INVITE,
  UPDATE_NEW_MEMBER_FIELD,
  INVITE_USER,
  TOGGLE_INVITE_MODAL,
  SHOW_PROJECT_ACCESS_MODAL,
  HIDE_PROJECT_ACCESS_MODAL,
  GET_USER_ACCESS_PROJECTS,
  SAVE_USER_ACCESS_PROJECTS
} from "@actions/settings/organizations"

import { get, post, put, del } from "@api/fetch"
import { addGlobalMessage } from "@api/utils/"

/**
 * Get all the team members
 *
 * @return dispatch
 */
export const getTeamMembers = () => (dispatch, getState) =>
  dispatch(get(GET_TEAM_MEMBERS, `organizations/${getState().user.auth.organization_id}/users`))

/**
 * Update the search string
 *
 * @param  string  searchString
 *
 * @return dispatch
 */
export const updateSearchString = searchString => dispatch =>
  dispatch({ type: UPDATE_SEARCH_STRING, payload: { searchString } })

/**
 * Toggle the members active / Suspended status
 *
 * @param  object  user
 *
 * @return dispatch
 */
export const toggleActiveState = user => (dispatch, getState) => {
  const endpoint = user.status === "Active" ? "deactivate" : "reactivate"
  dispatch(
    post(
      TOGGLE_ACTIVE_STATE,
      `organizations/${getState().user.auth.organization_id}/users/${user._id}/${endpoint}`,
      null,
      () => dispatch(addGlobalMessage(`User Updated`, "success"))
    )
  )
}

/**
 * The the members role
 *
 * @param  object  user
 * @param  strong  roleId
 *
 * @return dispatch
 */
export const changeRole = (user, roleId) => (dispatch, getState) =>
  dispatch(
    post(
      CHANGE_ROLE,
      `organizations/${getState().user.auth.organization_id}/roles/${roleId}/users/${user._id}`,
      null,
      () => dispatch(addGlobalMessage("Role Updated", "success"))
    )
  )

/**
 * Delete the member
 *
 * @param  object  user
 *
 * @return dispatch
 */
export const deleteUser = user => (dispatch, getState) => {
  dispatch(
    del(DELETE_USER, `organizations/${getState().user.auth.organization_id}/users/${user._id}`, {}, () =>
      dispatch(addGlobalMessage("User Deleted", "success"))
    )
  )
}

/**
 * Resend the member the invite
 *
 * @param  object  user
 *
 * @return dispatch
 */
export const resendInvite = user => (dispatch, getState) =>
  dispatch(
    put(RESEND_INVITE, `organizations/${getState().user.auth.organization_id}/users/${user._id}/resend`, {}, () =>
      dispatch(addGlobalMessage(`Invite resent to ${user.full_name}.`, "success"))
    )
  )

/**
 * Update the input fields
 *
 * @param  string  field
 * @param  string  value
 *
 * @return dispatch
 */
export const updateNewMemberField = (field, value) => dispatch =>
  dispatch({ type: UPDATE_NEW_MEMBER_FIELD, payload: { field, value } })

/**
 * Send the invite
 *
 * @return dispatch
 */
export const inviteUser = () => (dispatch, getState) => {
  dispatch(
    post(
      INVITE_USER,
      `organizations/${getState().user.auth.organization_id}/users`,
      {
        users: [
          {
            name: getState().settings.organizations.members.newMemberName,
            email: getState()
              .settings.organizations.members.newMemberEmail.toLowerCase()
              .trim(),
            role_id: getState().settings.organizations.members.newMemberRole
          }
        ]
      },
      () => dispatch(addGlobalMessage("User Invited", "success"))
    )
  )
}

/**
 * Toggle the invite modal
 *
 * @return dispatch
 */
export const toggleInviteModal = () => dispatch => dispatch({ type: TOGGLE_INVITE_MODAL })

/**
 * Hide / Show project access modal
 *
 * @param user
 *
 * @return dispatch
 */
export const showProjectAccessModal = user => dispatch =>
  dispatch({ type: SHOW_PROJECT_ACCESS_MODAL, payload: { user } })
export const hideProjectAccessModal = () => dispatch => dispatch({ type: HIDE_PROJECT_ACCESS_MODAL })

export const getUserProjects = user => (dispatch, getState) =>
  dispatch(
    get(GET_USER_ACCESS_PROJECTS, `organizations/${getState().user.auth.organization_id}/users/${user._id}/access`)
  )

export const saveUserProjectAccess = (user, projectIds) => (dispatch, getState) =>
  dispatch(
    post(
      SAVE_USER_ACCESS_PROJECTS,
      `organizations/${getState().user.auth.organization_id}/users/${user._id}/access`,
      { projectIds },
      () => dispatch(addGlobalMessage("Saved!", "success"))
    )
  )
