import React from "react"
import PropTypes from "prop-types"
import humanFileSize from "@src/helpers/humanFileSize"
import { ButtonDropdown } from "@bitcine/cs-theme"

class AsperaTransfer extends React.Component {
  toggle = () => {
    if (this.props.transfer.status === "PAUSED") {
      window.asperaWeb.resumeTransfer(this.props.transfer.aspera_uuid)
    } else {
      window.asperaWeb.stopTransfer(this.props.transfer.aspera_uuid)
    }
  }
  remove = () => {
    if (confirm("Are you sure you want to cancel this transfer?")) {
      window.asperaWeb.removeTransfer(this.props.transfer.aspera_uuid)
    }
  }
  render() {
    const { transfer } = this.props
    return (
      <div className='col-12'>
        {transfer.status === "INITIALIZING" ? (
          <div className='flex items-center'>
            <div className='spinner spinner-small mr1'/>
            Initializing Aspera...
          </div>
        ) : ["RUNNING", "PAUSED"].includes(transfer.status) ? (
          <div className='flex items-center col-12'>
            <div className='flex-auto'>
              <progress
                max={transfer.total_size}
                value={transfer.current_size}
                className='cs-progress col-12 block my1'/>
              <div className='caption small'>
                {humanFileSize(transfer.current_size)} / {humanFileSize(transfer.total_size)}
                &nbsp;
                {transfer.time_remaining ? <strong>{transfer.time_remaining}...</strong> : "..."}
              </div>
            </div>
            <div className='flex items-center caption'>
              <ButtonDropdown
                button={{
                  className: "link ml2 material-icons",
                  text: "more_vert"
                }}
                dropdown={{
                  clickCloses: true,
                  content: [
                    {
                      text: transfer.status === "RUNNING" ? "Pause" : "Resume",
                      onClick: this.toggle
                    },
                    {
                      text: "Cancel",
                      onClick: this.remove
                    }
                  ]
                }}/>
            </div>
          </div>
        ) : transfer.status === "COMPLETE" ? (
          <div className='flex items-center'>
            <div className='material-icons green mr1'>check_circle</div>
            Transfer complete
          </div>
        ) : null}
      </div>
    )
  }
}

AsperaTransfer.propTypes = {
  transfer: PropTypes.object
}

export default AsperaTransfer
