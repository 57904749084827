import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

const Breadcrumb = ({ breadcrumb = [] }) => (
  <h4 className='flex items-center flex-auto'>
    <Link className={`${breadcrumb.length ? "muted" : ""} pr1`} to={`/projects`}>
      Projects
    </Link>
    {breadcrumb.map((crumb, i) => (
      <div className='flex items-center pr1' key={i}>
        <div className='material-icons mr1 muted'>keyboard_arrow_right</div>
        {breadcrumb.length === i + 1 ? (
          crumb.title
        ) : (
          <Link className='muted' to={`/projects/folder/${crumb.slug}`}>
            {crumb.title}
          </Link>
        )}
      </div>
    ))}
  </h4>
)

Breadcrumb.propTypes = {
  breadcrumb: PropTypes.array
}

export default Breadcrumb
