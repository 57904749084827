import {
  GET_TEAM_ROLES,
  TOGGLE_CREATE_ROLE_MODAL,
  GET_DEFAULT_PERMISSIONS,
  UPDATE_NEW_ROLE_PERMISSION,
  UPDATE_NEW_ROLE,
  SAVE_NEW_ROLE,
  TOGGLE_EDIT_MODAL,
  UPDATE_ROLE_PERMISSION,
  UPDATE_ROLE,
  SAVE_ROLE,
  DELETE_ROLE
} from "@actions/settings/organizations"

import { get, post, put, del } from "@api/fetch"
import { addGlobalMessage } from "@api/utils/"

/**
 * Get all the roles for an organization / team
 *
 * @return dispatch
 */
export const getTeamRoles = () => (dispatch, getState) =>
  dispatch(get(GET_TEAM_ROLES, `organizations/${getState().user.auth.organization_id}/roles`))

/**
 * Toggle the create role modal
 *
 * @return dispatch
 */
export const toggleCreateRoleModal = () => dispatch => dispatch({ type: TOGGLE_CREATE_ROLE_MODAL })

/**
 * Get the default roles for an organization / team
 *
 * @return dispatch
 */
export const getDefaultPermissions = () => (dispatch, getState) =>
  dispatch(get(GET_DEFAULT_PERMISSIONS, `organizations/${getState().user.auth.organization_id}/roles/permissions`))

/**
 * Update the role/permission in the create role modal
 *
 * @param  object  role
 * @param  object  permission
 *
 * @return dispatch
 */
export const updateNewRolePermission = (role, permission) => dispatch =>
  dispatch({ type: UPDATE_NEW_ROLE_PERMISSION, payload: { role, permission } })

/**
 * Update the new role fields
 *
 * @param  string  field
 * @param  string  text
 *
 * @return dispatch
 */
export const updateNewRole = (field, text) => dispatch => dispatch({ type: UPDATE_NEW_ROLE, payload: { field, text } })

/**
 * Save the new role
 *
 * @return dispatch
 */
export const saveNewRole = () => (dispatch, getState) => {
  return dispatch(
    post(
      SAVE_NEW_ROLE,
      `organizations/${getState().user.auth.organization_id}/roles`,
      {
        name: getState().settings.organizations.roles.newRole.name,
        description: getState().settings.organizations.roles.newRole.description,
        permissions: getState().settings.organizations.roles.newRole.data
      },
      () => dispatch(addGlobalMessage("Role Created", "success"))
    )
  )
}

/**
 * Toggle the edit role modal and set the activeRole
 *
 * @param  object  role = null
 *
 * @return dispatch
 */
export const toggleEditModal = (role = null) => dispatch => dispatch({ type: TOGGLE_EDIT_MODAL, payload: { role } })

/**
 * Update the role/permission in the active role
 *
 * @param  object  role
 * @param  object  permission
 *
 * @return dispatch
 */
export const updateRolePermission = (role, permission) => dispatch =>
  dispatch({ type: UPDATE_ROLE_PERMISSION, payload: { role, permission } })

/**
 * Update the active role fields
 *
 * @param  string  field
 * @param  string  text
 *
 * @return dispatch
 */
export const updateRole = (field, text) => dispatch => dispatch({ type: UPDATE_ROLE, payload: { field, text } })

/**
 * Update the active role
 *
 * @return dispatch
 */
export const saveRole = () => (dispatch, getState) => {
  return dispatch(
    put(
      SAVE_ROLE,
      `organizations/${getState().user.auth.organization_id}/roles/${
        getState().settings.organizations.roles.activeRole._id
      }`,
      {
        name: getState().settings.organizations.roles.activeRole.name,
        description: getState().settings.organizations.roles.activeRole.description,
        permissions: getState().settings.organizations.roles.activeRole.permissions
      }
    )
  )
}

/**
 * Delete a role
 *
 * @param  object  role
 *
 * @return dispatch
 */
export const deleteRole = role => (dispatch, getState) =>
  dispatch(del(DELETE_ROLE, `organizations/${getState().user.auth.organization_id}/roles/${role._id}`))
