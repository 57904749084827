import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateCart, setShippingRate } from "@api/account/cart"
import { Radio } from "@bitcine/cs-theme"
import dayjs from "dayjs"

const Rates = ({ newAddress, updateCart, setShippingRate }) => (
  <div>
    <div className='flex items-start mb3 mt2'>
      <div className='flex-auto'>
        <strong>{newAddress.organization}</strong>
        <div>{newAddress.address_line1},</div>
        {newAddress.address_line2 && <div>{newAddress.address_line1},</div>}
        <div>
          {newAddress.city}, {newAddress.state_or_province_code}, {newAddress.country_code}
        </div>
        <div>{newAddress.postal_code}</div>
      </div>
      <button
        className='cs-button white small'
        onClick={() => updateCart("newAddress", { ...newAddress, step: "address" })}>
        Change Address
      </button>
    </div>
    <strong className='block'>Please Select a Shipping Rate</strong>
    <form>
      {newAddress.rates.map((rate, i) => (
        <div key={i} className={`flex items-center py2 ${i > 0 ? "border-top border-gray-5" : ""}`}>
          <Radio
            checked={newAddress.selected_rate === rate}
            id={`${rate.shipping_type}_${i}`}
            onChange={() => updateCart("newAddress", { ...newAddress, selected_rate: rate })}/>
          <label htmlFor={`${rate.shipping_type}_${i}`} className='cs-label pl2 flex-auto'>
            <strong className='capitalize'>
              {rate.shipping_type
                .split("_")
                .join(" ")
                .toLowerCase()}
            </strong>
            <small style={{ marginTop: "-0.4rem" }} className='block'>
              Averages {setDays(rate.delivery_date)} days
              <sup>*</sup>
            </small>
          </label>
          <div>
            {rate.currency_symbol}
            {rate.rate}
          </div>
        </div>
      ))}
      <div>
        <sup>*</sup>
        Does not include production time
      </div>
      {(newAddress.error || newAddress.status === "ERROR") && (
        <div className='red my2'>An error occured. Please try again.</div>
      )}
      <div className='flex mt3 justify-end'>
        <button
          className={`cs-button ${newAddress.status === "PENDING" ? "pending" : ""}`}
          onClick={setShippingRate}
          disabled={!newAddress.selected_rate || newAddress.status === "PENDING"}>
          Select Rate
        </button>
      </div>
    </form>
  </div>
)

const setDays = date => {
  const days = Math.ceil(dayjs(date).diff(dayjs(), "days", true))
  const day = days < 1 ? 1 : days
  return `${day} - ${day + 2}`
}

Rates.propTypes = {
  newAddress: PropTypes.object.isRequired,
  updateCart: PropTypes.func.isRequired,
  setShippingRate: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  newAddress: state.user.cart.newAddress
})

const mapDispatchToProps = dispatch => ({
  updateCart: bindActionCreators(updateCart, dispatch),
  setShippingRate: bindActionCreators(setShippingRate, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Rates)
