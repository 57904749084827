import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { getFileRequest } from "@api/file_request/"
import Header from "@components/requests/header"
import Footer from "@components/requests/footer"
import Summary from "@components/file_request/summary"
import Info from "@components/file_request/info"
import SelectExisting from "@components/file_request/select_existing"
import Files from "@components/file_request/files"
import IncompleteUploads from "@components/file_request/incomplete_uploads"
import Transfers from "@components/file_request/transfers"
import { Status } from "@bitcine/cs-theme"
import styled from "react-emotion"
import Imports from "../../components/file_request/imports"

const PageContainer = styled("div")`
  position: relative;
  min-height: 100vh;
`

const ContentContainer = styled("div")`
  position: relative;
  padding-top: 64px;
  padding-bottom: 140px;
`

class FileRequest extends React.Component {
  componentDidMount() {
    this.props.getFileRequest(this.props.match.params.requestID, this.props.location.pathname)
  }
  getUploads() {
    const uploads = this.props.request.aspera_uploads || []
    const cineuploads = this.props.request.cineuploads || []
    return [...uploads, ...cineuploads]
  }
  render() {
    const { request } = this.props

    return (
      <PageContainer>
        <Header/>
        <ContentContainer>
          <Status pending={request.status === "PENDING"} error={request.status === "ERROR"}>
            <div className='p3'>
              {request.status === "closed" ? (
                <div className='center py3'>
                  <span className='material-icons block large-icon'>lock</span>
                  <h3>Closed</h3>
                  <p>This file request has been closed.</p>
                </div>
              ) : (
                <div>
                  <Summary request={request}/>
                  <Info {...this.props}/>
                  <Transfers/>
                  <IncompleteUploads/>
                  <Imports/>
                  <Files/>
                  <SelectExisting/>
                </div>
              )}
            </div>
          </Status>
        </ContentContainer>
        <Footer/>
      </PageContainer>
    )
  }
}

FileRequest.propTypes = {
  request: PropTypes.object.isRequired,
  getFileRequest: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

const mapStateToProps = ({ file_request }) => ({
  request: file_request
})

const mapDispatchToProps = dispatch => ({
  getFileRequest: bindActionCreators(getFileRequest, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(FileRequest)
