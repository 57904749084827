import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Snackbar } from "@bitcine/cs-theme"

class GhostingBar extends React.Component {
  constructor() {
    super()
    this.state = {
      open: false
    }
  }
  componentDidMount() {
    this.setState({ open: true })
  }
  render() {
    const { isGhost } = this.props
    if (!isGhost || !this.state.open) return null

    return (
      <Snackbar
        alignment='center'
        items={[
          {
            message: "WARNING: You are ghosting this account!",
            type: "error",
            icon: "supervisor_account",
            _id: "1",
            onClose: () => {
              return null
            }
          }
        ]}/>
    )
  }
}

GhostingBar.propTypes = {
  isGhost: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  isGhost: state.user.auth.is_ghost
})

export default connect(
  mapStateToProps,
  null
)(GhostingBar)
