import {
  GET_HISTORY_PENDING,
  GET_HISTORY_FAILED,
  GET_HISTORY_FULFILLED,
  UPDATE_HISTORY,
  UPDATE_HISTORY_FILTER
} from "@actions/project/history"

const initialState = {
  status: "PENDING",
  list: [],
  count: "",
  filtered: ""
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_HISTORY_PENDING:
      return {
        ...state,
        status: state.status === "READY" ? "UPDATING" : "PENDING"
      }

    case GET_HISTORY_FULFILLED:
      return {
        ...state,
        status: "READY",
        count: action.payload.count,
        list: action.payload.logs
      }

    case GET_HISTORY_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case UPDATE_HISTORY_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.key]: action.value
        }
      }

    case UPDATE_HISTORY:
      return {
        ...state,
        [action.key]: action.value
      }

    default:
      return state
  }
}
