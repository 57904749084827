import React from "react"
import PropTypes from "prop-types"
import SecurityIcon from "@components/icons/password"
import PackageShareType from "@components/icons/package_share_type"

const SharingIcons = ({ sharedPackage }) => (
  <div className='flex items-center justify-between col-12 mr2'>
    <SecurityIcon active={sharedPackage.is_password_enabled}/>
    <PackageShareType method={sharedPackage.method}/>
  </div>
)

SharingIcons.propTypes = {
  sharedPackage: PropTypes.object.isRequired
}

export default SharingIcons
