import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getCartCount } from "@api/account/cart"
import { Link } from "react-router-dom"
import TourTip from "@components/tour/tip"

class Cart extends React.Component {
  componentDidMount() {
    this.props.getCartCount()
  }
  render() {
    const { cart } = this.props
    return (
      <div className='flex items-center mr3'>
        <div className='relative flex items-center'>
          <TourTip tipId='cart'>
            <Link className='material-icons' to='/cart'>
              shopping_cart
            </Link>
          </TourTip>
          {cart.count > 0 && (
            <span
              style={{
                width: "20px",
                height: "20px",
                top: "-.7rem",
                right: "-.5rem",
                border: "3px solid white"
              }}
              className='absolute flex items-center justify-center small bg-red white circle white bold'>
              {cart.count > 9 ? "9+" : cart.count.toString()}
            </span>
          )}
        </div>
      </div>
    )
  }
}

Cart.propTypes = {
  getCartCount: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  cart: state.user.cart
})

const mapDispatchToProps = dispatch => ({
  getCartCount: bindActionCreators(getCartCount, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cart)
