import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { TOGGLE_SEND_SUCCESS_MODAL } from "@actions/preview/send"
import copy from "copy-to-clipboard"
import { Modal } from "@bitcine/cs-theme"
import { updateLastSent } from "@api/shared_screeners"

const SendSuccess = ({ close, sharedScreeners, mode, updateLastSent }) => (
  <Modal open onClose={close} title={`Share by ${mode === "email" ? "Email" : "Link"}`}>
    <div>
      <div className='center'>
        <i className='material-icons red' style={{ fontSize: "5em" }}>
          check_circle
        </i>
        <h3 className='pb1'>Success!</h3>
        <p className='pb1'>
          {mode === "email" ? (
            <p>Your reviewer(s) will receive an email shortly. You can revoke access at any time.</p>
          ) : (
            <span>
              <p>Click the button to copy the generated URL. You can revoke access at any time.</p>
              <button
                className='cs-button white small'
                onClick={() => {
                  copy(sharedScreeners[0].url, { format: "text/plain" })
                  updateLastSent(sharedScreeners[0]._id)
                }}>
                Copy URL
              </button>
            </span>
          )}
        </p>
      </div>
    </div>
  </Modal>
)

SendSuccess.propTypes = {
  close: PropTypes.func.isRequired,
  sharedScreeners: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
  updateLastSent: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  sharedScreeners: state.preview.send.sharedScreeners,
  mode: state.preview.send.sharedScreeners[0].share_type
})

const mapDispatchToProps = dispatch => ({
  close: () => dispatch({ type: TOGGLE_SEND_SUCCESS_MODAL }),
  updateLastSent: bindActionCreators(updateLastSent, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SendSuccess)
