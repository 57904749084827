import React, { Component } from "react"
import PropTypes from "prop-types"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }
  componentDidCatch(error, info) {
    this.setState({ hasError: true })
    if (window.trackJs) {
      window.trackJs.console.error(error)
    }
  }
  render() {
    return this.state.hasError ? (
      <div className='pt4 col-12 my3 center'>
        <span className='material-icons red large-icon block'>error</span>
        <h4>Error</h4>
        <p className='max-width-1 mx-auto'>
          An internal error occurred. Please refresh the page. If this problem persists please contact support. This error has been logged
          automatically.
        </p>
      </div>
    ) : (
      this.props.children
    )
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
}

export default ErrorBoundary
