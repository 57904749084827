import React from "react"
import PropTypes from "prop-types"
import "@src/styles/index.scss" // eslint-disable-line no-unused-vars
import { addGlobalMessage, removeGlobalMessage } from "@api/utils"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Cookies from "js-cookie"
import { GLOBALS } from "@src/config"

class AccountWarningChecks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pending_payment_message_id: null,
      over_storage_limit_message_id: null,
      near_storage_limit_message_id: null
    }
  }
  componentDidMount() {
    // If there is no authorized user or subscription, ignore this.
    if (!this.props.user || !this.props.user.subscription) return

    this.checkPendingPayment()
    this.checkOverStorageLimit()
    this.checkNearStorageLimit()
  }
  componentDidUpdate() {
    // If there is no authorized user or subscription, ignore this.
    if (!this.props.user || !this.props.user.subscription) return

    this.checkPendingPayment()
    this.checkOverStorageLimit()
    this.checkNearStorageLimit()
  }
  checkPendingPayment() {
    // 1. If the message already exists and is no longer relevant, remove it.
    if (this.state.pending_payment_message_id) {
      if (!this.props.user.subscription.is_pending_storage_payment) {
        this.props.removeGlobalMessage(this.state.pending_payment_message_id)
        this.setState({ pending_payment_message_id: null })
      }
    }
    // 2. If it does not exist, check if it should.
    else if (this.props.user.subscription.is_pending_storage_payment) {
      const id = this.props.addGlobalMessage(
        <div>
          <b>Payment method error!</b>
          <br/>
          We were unable to process your payment. Please{" "}
          <a
            href={GLOBALS.ACCOUNTS_URL + '/settings/billing'}
            onClick={() => {
              this.props.removeGlobalMessage(id)
            }}
            className='bold underline pointer'>
            update your payment method.
          </a>
        </div>,
        "error",
        "error",
        null,
        true
      )
      this.setState({ pending_payment_message_id: id })
    }
  }
  checkOverStorageLimit() {
    // 1. If the message already exists and is no longer relevant, remove it.
    if (this.state.over_storage_limit_message_id) {
      if (!this.props.user.subscription.is_over_storage_limit) {
        this.props.removeGlobalMessage(this.state.over_storage_limit_message_id)
        this.setState({ over_storage_limit_message_id: null })
      }
    }
    // 2. If it does not exist, check if it should.
    else if (this.props.user.subscription.is_over_storage_limit) {
      const id = this.props.addGlobalMessage(
        <div>
          <b>Storage is full!</b>
          <br/>
          Remove files or{" "}
          <a
            href={GLOBALS.ACCOUNTS_URL + '/settings/billing'}
            onClick={() => {
              this.props.removeGlobalMessage(id)
            }}
            className='bold underline pointer'>
            upgrade your account{" "}
          </a>
          for more space.
        </div>,
        "error",
        "error",
        null,
        true
      )
      this.setState({ over_storage_limit_message_id: id })
    }
  }
  checkNearStorageLimit() {
    // 1. If the message already exists and is no longer relevant, remove it.
    if (this.state.near_storage_limit_message_id) {
      if (!this.props.user.subscription.is_near_storage_limit) {
        this.props.removeGlobalMessage(this.state.near_storage_limit_message_id)
        this.setState({ near_storage_limit_message_id: null })
      }
    }
    // 2. If it does not exist, check if it should.
    else if (this.props.user.subscription.is_near_storage_limit && !Cookies.get("storage_is_almost_full_dismissed")) {
      const id = this.props.addGlobalMessage(
        <div>
          <b>Storage is almost full!</b>
          <br/>
          To add more space,{" "}
          <a
            href={GLOBALS.ACCOUNTS_URL + '/settings/billing'}
            onClick={() => {
              this.props.removeGlobalMessage(id)
              Cookies.set("storage_is_almost_full_dismissed", 1, { expires: 1 })
            }}
            className='bold underline pointer'>
            upgrade your account.
          </a>
        </div>,
        "warning",
        "warning",
        null,
        true
      )
      this.setState({ near_storage_limit_message_id: id })
    }
  }
  render() {
    return this.props.children
  }
}

AccountWarningChecks.propTypes = {
  children: PropTypes.node.isRequired,
  addGlobalMessage: PropTypes.func.isRequired,
  removeGlobalMessage: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  user: state.user.auth
})

const mapDispatchToProps = dispatch => ({
  addGlobalMessage: bindActionCreators(addGlobalMessage, dispatch),
  removeGlobalMessage: bindActionCreators(removeGlobalMessage, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountWarningChecks)
