export const GET_SCREENER_ATTACHMENTS = "GET_SCREENER_ATTACHMENTS"
export const GET_SCREENER_ATTACHMENTS_FULFILLED = "GET_SCREENER_ATTACHMENTS_FULFILLED"
export const GET_SCREENER_ATTACHMENTS_PENDING = "GET_SCREENER_ATTACHMENTS_PENDING"
export const GET_SCREENER_ATTACHMENTS_FAILED = "GET_SCREENER_ATTACHMENTS_REJECTED"
export const UPDATE_SCREENER_ATTACHMENTS_LIST = "UPDATE_SCREENER_ATTACHMENTS_LIST"
export const DELETE_SCREENER_ATTACHMENT = "DELETE_SCREENER_ATTACHMENT"
export const DELETE_SCREENER_ATTACHMENT_FULFILLED = "DELETE_SCREENER_ATTACHMENT_FULFILLED"
export const DELETE_SCREENER_ATTACHMENT_PENDING = "DELETE_SCREENER_ATTACHMENT_PENDING"
export const DELETE_SCREENER_ATTACHMENT_FAILED = "DELETE_SCREENER_ATTACHMENT_REJECTED"
export const IMPORT_FILES_FOR_SCREENER_ATTACHMENT = "IMPORT_FILE_FOR_SCREENER_ATTACHMENT"
export const IMPORT_FILES_FOR_SCREENER_ATTACHMENT_PENDING = "IMPORT_FILE_FOR_SCREENER_ATTACHMENT_PENDING"
export const IMPORT_FILES_FOR_SCREENER_ATTACHMENT_FULFILLED = "IMPORT_FILE_FOR_SCREENER_ATTACHMENT_FULFILLED"
export const IMPORT_FILES_FOR_SCREENER_ATTACHMENT_FAILED = "IMPORT_FILE_FOR_SCREENER_ATTACHMENT_REJECTED"
