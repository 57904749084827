import React, { useEffect } from "react"
import { Route, Switch, withRouter, Redirect } from "react-router-dom"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Projects from "@containers/projects"
import ProjectRoutes from "./project"
import CartRoutes from "./cart"
import ScreenerRooms from '@components/screener_rooms/screener_rooms'
import ScreenerRoomRoutes from "./screener_room"
import SharedScreeners from "@containers/shared_screeners"
import FileRequest from "@containers/file_request"
import DownloadLink from "@containers/download_link"
import OnDemandVideoRequest from "@containers/ondemand_videos"
import Orders from "@containers/orders"
import SharedScreenerExtension from "@containers/shared_screeners/extension"
import NotFound from "@containers/notfound"
import AppWrapper from "@containers/app"
import Wrap from "@containers/app/wrap"
import AuthWrapper from "@containers/app/auth_wrapper"
import { authCheck } from '@api/account/auth'

const Routes = ({ status, authCheck }) => {
  useEffect(() => {
    authCheck(false)
  }, [authCheck])
  return (
    <Switch>
      <Redirect exact from='/' to='projects'/>
    
      {/* All public routes */}
      <Route path='/file-request/:requestID' component={FileRequest}/>
      <Route path='/cinerequest/:requestID' component={FileRequest}/>
      <Route path='/transfers/:transferID' component={DownloadLink}/>
      <Route path='/video-requests/:videoID' component={OnDemandVideoRequest}/>
    
      {/* All authenticated routes */}
      <AuthWrapper>
        <AppWrapper>
          <Wrap>
            <Switch>
              <Route exact path='/projects' component={Projects}/>
              <Route exact path='/projects/folder/:folderSlug' component={Projects}/>
              <Route path='/projects/:projectID' component={ProjectRoutes}/>
              <Route path='/cart' component={CartRoutes}/>
              <Route exact path='/screener-rooms' component={ScreenerRooms}/>
              <Route path='/screener-rooms/:id' component={ScreenerRoomRoutes}/>
              <Route path='/shared-screeners' component={SharedScreeners}/>
              <Route path='/shared-screener/:sharedScreenerID/extension' component={SharedScreenerExtension}/>
              <Route path='/reviewers' component={SharedScreeners}/>
              <Route path='/orders' component={Orders}/>
            </Switch>
          </Wrap>
        </AppWrapper>
      </AuthWrapper>
      <Route path='*' component={NotFound} status={404}/>
    </Switch>
  )
}

const mapStateToProps = state => ({
  status: state.user.auth.status,
})

const mapDispatchToProps = dispatch => ({
  authCheck: bindActionCreators(authCheck, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Routes))