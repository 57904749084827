import React from "react"
import PropTypes from "prop-types"
// import { bindActionCreators } from 'redux'
import { connect } from "react-redux"
import { Status, Table } from "@bitcine/cs-theme"
import Breadcrumb from "@components/breadcrumb"
import Icon from "@components/files/icon/"
import { DEFAULTS } from "@constants"
import { GLOBALS } from "@src/config"

class File extends React.Component {
  constructor(props) {
    super(props)
    this.get = `${GLOBALS.API_URL}/projects/${props.projectID}/files`
    this.state = {
      status: "PENDING",
      list: [],
      project_id: "",
      type: "",
      selected_files: {}
    }
  }
  componentDidMount() {
    this.getFiles()
  }
  getFiles() {
    fetch(this.get, DEFAULTS)
      .then(res => res.json())
      .then(res => this.setState({ status: "READY", files: res.files, breadcrumb: res.breadcrumb }))
      .catch(error => this.setState({ status: "ERROR", error }))
  }
  folderFiles(folderID) {
    fetch(`${GLOBALS.API_URL}/projects/${this.props.projectID}/files?folderID=${folderID}`, DEFAULTS)
      .then(res => res.json())
      .then(res => this.setState({ status: "READY", files: res.files, breadcrumb: res.breadcrumb }))
      .catch(error => this.setState({ status: "ERROR", error }))
  }
  onRowClick(data) {
    if (data.type === "folder") {
      this.folderFiles(data._id)
    } else {
      this.toggleFile(data._id, data.type)
    }
  }
  toggleFile(fileID, type) {
    let files = this.state.selected_files
    if (files[fileID]) {
      delete files[fileID]
    } else {
      files[fileID] = type
    }
    this.setState({ selectedFiles: files })
  }
  back = () => {
    this.props.close()
  }
  render() {
    const { status, files, selected_files, breadcrumb } = this.state
    return (
      <Status pending={status === "PENDING"} error={status === "ERROR"}>
        <div>
          <a onClick={this.back} className='muted block mb2'>
            Back to projects
          </a>
          <Breadcrumb
            breadcrumb={breadcrumb}
            onAllClick={() => this.getFiles()}
            onCrumbClick={crumb => this.folderFiles(crumb._id)}/>
          <Table
            widths={[55, "auto"]}
            header={{
              columns: [{ text: "" }, { text: "File" }]
            }}
            body={{
              data: files,
              row: {
                empty: {
                  icon: "folder",
                  title: "No Files or Folders Found"
                },
                checkbox: {
                  checked: ({ _id }) => !!selected_files[_id],
                  onChange: ({ _id, type }) => this.toggleFile(_id, type),
                  disabled: data => data.type === "folder"
                },
                onClick: (event, file) => this.onRowClick(file),
                render: [file => <Icon data={file}/>, file => file.name]
              }
            }}/>
          <div className='right-align mt3'>
            <button
              className='cs-button'
              onClick={() => this.props.selectFiles(selected_files, this.props.projectID)}
              disabled={!Object.keys(selected_files).length}>
              {`Select File${Object.keys(selected_files).length > 1 ? "s" : ""}`}
            </button>
          </div>
        </div>
      </Status>
    )
  }
}

File.propTypes = {
  type: PropTypes.oneOf(["dvd", "bluray", "dcp"]).isRequired,
  projectID: PropTypes.string.isRequired,
  selectFiles: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(File)
