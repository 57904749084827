import { GET_PROJECT, SAVE_PROJECT, UPDATE_PROJECT_DETAILS } from "@actions/project/"

import { get, put } from "@api/fetch"

/**
 * Get project
 * - Project may already be in state, so a fetch may not be needed
 *
 * @param {string} projectID
 *
 * @returns dispatch
 */

export const getProject = projectID => (dispatch, getState) => dispatch(get(GET_PROJECT, `projects/${projectID}`))

/**
 * Save Project
 * - update project on server
 * - NOTE: doesnt not refresh state on server response
 *
 * @returns dispatch
 */

export const saveProject = (projectID, key, value) => dispatch =>
  dispatch(put(SAVE_PROJECT, `projects/${projectID}`, { key, value, projectID }))

/**
 * Update Project Details
 * - update project state on client
 * - NOTE: doesnt not save to server
 *
 * @returns dispatch
 */

export const updateProjectDetails = (projectID, key, value) => dispatch =>
  dispatch({ type: UPDATE_PROJECT_DETAILS, key, value })
