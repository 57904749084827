import React from "react"
import PropTypes from "prop-types"
import config from "../../config"
import 'styled-components/macro'

const Footer = ({ bg, header, content }) => (
  <div
    css={`
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 150px;
      border-bottom-left-radius: ${config.borderRadius}px;
      border-bottom-right-radius: ${config.borderRadius}px;
      background: ${bg === "red"
    ? "linear-gradient(141.51deg, #FD2A51 0%, #FF4B6D 100%)"
    : "linear-gradient(135.28deg, #0096CA 0%, #10B5ED 100%)"};
    `}>
    <div
      css={`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 480px;
        color: #fff;
        text-align: center;
      `}>
      <h3
        css={`
          margin-bottom: 5px;
        `}>
        {header}
      </h3>
      <p>{content}</p>
    </div>
  </div>
)

Footer.propTypes = {
  bg: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
}

Footer.defaultProps = {
  bg: "red",
  header: "",
  content: ""
}

export default Footer
