import React from "react"
import ScreenerImg from "../images/screeners.png"
import BaseSlide from "./components/base"
import Footer from "./components/footer"
import 'styled-components/macro'

const ScreenersSlide = () => (
  <BaseSlide>
    <div
      css={`
        padding: 25px 80px 0 80px;
      `}>
      <img className='cs-img' src={ScreenerImg} alt='Screeners'/>
    </div>
    <Footer
      bg='red'
      header='Share secure screener links'
      content={`
				CineSend lets you share non-downloadable screener links with distributors,
				film festivals, or whomever else you'd like. Add advanced security options like 
				unique and personalized watermarks, passwords, and expiry rules.
			`}/>
  </BaseSlide>
)

export default ScreenersSlide
