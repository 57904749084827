import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Checkbox } from "@bitcine/cs-theme"
import Select from "react-select"
import { getCustomFields, setCustomFieldValue } from "@api/ondemand_videos"

const CustomFields = ({ getCustomFields, videos, video, setCustomFieldValue }) => {
  useEffect(() => {
    getCustomFields(video._id)
  }, [])
  const getValue = id => {
    const index = videos.customFieldValues.findIndex(v => {
      return v.fieldID === id
    })
    if (index === -1) {
      return ""
    }
    return videos.customFieldValues[index].value
  }
  return videos.loadingCustomFields ? (
    <div className='border-bottom border-top border-gray-5 py2'>Loading more fields...</div>
  ) : (
    <div>
      {videos.availableCustomFields.length > 0 ? (
        <div>
          {videos.availableCustomFields.map(field => (
            <div key={field._id}>
              {field.type === "string" && (
                <div className='mb2'>
                  <label className='cs-label'>{field.label}</label>
                  <input
                    className='cs-input col-12'
                    disabled={video.is_metadata_submitted}
                    placeholder={field.label + "..."}
                    value={getValue(field._id)}
                    onChange={e => {
                      setCustomFieldValue(field._id, e.target.value)
                    }}/>
                </div>
              )}
              {field.type === "dropdown" && !field.is_multiselect && (
                <div className='mb2'>
                  <label className='cs-label'>{field.label}</label>
                  <Select
                    className='col-12'
                    disabled={video.is_metadata_submitted}
                    options={field.options.map(option => {
                      return {
                        label: option.label,
                        value: option._id
                      }
                    })}
                    value={
                      field.options
                        .map(option => {
                          return {
                            label: option.label,
                            value: option._id
                          }
                        })
                        .filter(opt => opt.value === getValue(field._id))[0]
                    }
                    onChange={option => {
                      setCustomFieldValue(field._id, option.value)
                    }}/>
                </div>
              )}
              {field.type === "dropdown" && !!field.is_multiselect && (
                <div className='mb2'>
                  <label className='cs-label'>{field.label}</label>
                  <Select
                    multi={true}
                    disabled={video.is_metadata_submitted}
                    options={field.options.map(option => {
                      return {
                        label: option.label,
                        value: option._id
                      }
                    })}
                    value={getValue(field._id)}
                    onChange={options => {
                      setCustomFieldValue(field._id, options)
                    }}/>
                </div>
              )}
              {field.type === "boolean" && (
                <div className='mb2'>
                  <Checkbox
                    disabled={video.is_metadata_submitted}
                    checked={getValue(field._id) === 1}
                    label={field.label}
                    onChange={() => {
                      setCustomFieldValue(field._id, getValue(field._id) === 1 ? 0 : 1)
                    }}/>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div/>
      )}
    </div>
  )
}

CustomFields.propTypes = {
  getCustomFields: PropTypes.func.isRequired,
  videos: PropTypes.object.isRequired,
  video: PropTypes.object.isRequired,
  setCustomFieldValue: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  videos: state.ondemand_videos,
  video: state.ondemand_videos.video
})

const mapDispatchToProps = dispatch => ({
  getCustomFields: bindActionCreators(getCustomFields, dispatch),
  setCustomFieldValue: bindActionCreators(setCustomFieldValue, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomFields)
