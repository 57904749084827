import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateNewReviewers } from "@api/preview/send"
import { validateEmail } from "@api/account/"
import DeleteReviewer from "./delete_reviewer"

class Reviewer extends React.PureComponent {
  render() {
    const { reviewer, reviewerKey, updateNewReviewers, list } = this.props
    return (
      <div className='flex items-end col-12 mb2'>
        {[
          { key: "full_name", label: "Full Name" },
          { key: "email", label: "Email" }
        ].map(input => (
          <div key={input.key} className='col-6 pr2'>
            <label className='cs-label' htmlFor={`${input.key}-${reviewerKey}`}>
              {input.label}{" "}
              <sup className='red'>
                *
                {input.key === "email"
                  ? reviewer[input.key] !== "" && !validateEmail(reviewer[input.key])
                    ? " Invalid Email"
                    : null
                  : null}
              </sup>
            </label>
            <input
              value={reviewer[input.key]}
              id={`${input.key}-${reviewerKey}`}
              className={`cs-input block col-12 ${!reviewer[input.key] ? "error" : ""}
                ${input.key === "email" ? (!validateEmail(reviewer[input.key]) ? "error" : "") : ""} `}
              onChange={e => {
                updateNewReviewers("email", "list", {
                  ...list,
                  [reviewerKey]: {
                    ...reviewer,
                    [input.key]: e.target.value
                  }
                })
              }}/>
          </div>
        ))}
        {Object.keys(list).length > 1 && <DeleteReviewer reviewerKey={reviewerKey}/>}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  list: state.preview.send.email.list
})

const mapDispatchToProps = dispatch => ({
  updateNewReviewers: bindActionCreators(updateNewReviewers, dispatch)
})

Reviewer.propTypes = {
  list: PropTypes.object.isRequired,
  updateNewReviewers: PropTypes.func.isRequired,
  reviewerKey: PropTypes.string.isRequired,
  reviewer: PropTypes.shape({
    email: PropTypes.string,
    full_name: PropTypes.string,
    expiry: PropTypes.string
  }).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Reviewer)
