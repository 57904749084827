import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Logo from "@src/public/logo.inline.svg"
import { toggleGiftCard, updateCart } from "@api/account/cart"
import { updatePaymentMethod } from "@api/account/billing"
import { Checkbox } from "@bitcine/cs-theme"
import "./styles.scss"

const PostPay = ({ toggleGiftCard, usingPostPay, updatePaymentMethod, updateCart, activeGiftCards, cart }) => (
  <div
    className={`${cart ? "lg-col-4 xl-col-3" : "lg-col-3 xl-col-2"} col-12 sm-col-6 md-col-4 px1 pb2`}
    onClick={() => {
      updatePaymentMethod("using_post_pay", !usingPostPay)
      updateCart("credit_card_id", "")
      {
        activeGiftCards.map(card => toggleGiftCard(card._id))
      }
    }}>
    <div className={`card relative rounded box-shadow`}>
      <div className='absolute left-0 right-0 bottom-0 top-0 flex flex-column'>
        <div className={`relative flex-auto flex items-center justify-center bg-gray-6 giftCardHeader`}>
          <span className='absolute top-0 left-0 ml1 mt1'>
            <Checkbox checked={usingPostPay} onChange={() => null}/>
          </span>
          <div>
            <Logo className={"logo"}/>
          </div>
        </div>
        <div className='flex items-center justify-center p2'>
          <strong>Post Pay</strong>
        </div>
      </div>
    </div>
  </div>
)

PostPay.propTypes = {
  usingPostPay: PropTypes.bool.isRequired,
  updatePaymentMethod: PropTypes.func.isRequired,
  updateCart: PropTypes.func.isRequired,
  toggleGiftCard: PropTypes.func.isRequired,
  activeGiftCards: PropTypes.array.isRequired,
  cart: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  usingPostPay: state.user.billing.paymentMethods.list.using_post_pay === true,
  activeGiftCards: state.user.billing.paymentMethods.list.gift_cards.filter(card => card.is_payment_active)
})

const mapDispatchToProps = dispatch => ({
  updateCart: bindActionCreators(updateCart, dispatch),
  toggleGiftCard: bindActionCreators(toggleGiftCard, dispatch),
  updatePaymentMethod: bindActionCreators(updatePaymentMethod, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostPay)
