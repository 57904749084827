import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { moveIntoFolder, toggleNewFolder, updateNewFolder } from "@api/project/folders/"
import { updateUtils } from "@api/utils/"
import { DragSource, DropTarget } from "react-dnd"
import { getEmptyImage } from "react-dnd-html5-backend"
import shouldPureComponentUpdate from "@src/helpers/shouldPureComponentUpdate"
import flow from "lodash.flow"

class DragDropRow extends React.Component {
  componentDidMount() {
    /*
     * connectDragPreview()
     * This allows us to create a custom dragger - and hide the original element.
     */
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true
    })
  }
  shouldComponentUpdate = shouldPureComponentUpdate
  getStyles(isOver, canDrop, isDragging, file) {
    if (isDragging) {
      return {
        opacity: 0
      }
    } else if (isOver && canDrop && !file.frozen_at) {
      return {
        boxShadow: "0 0 1px 1px #e30a33"
      }
    }
  }
  render() {
    return (
      <div data-file-id={this.props.file._id} data-file-droppable={this.props.file.type === "folder"}>
        {this.props.connectDropTarget(
          this.props.connectDragSource(
            <div
              className={!!this.props.file.frozen_at && this.props.file.type !== "source_file" ? "" : "pointer"}
              style={this.getStyles(this.props.isOver, this.props.canDrop, this.props.isDragging, this.props.file)}>
              {this.props.children}
            </div>
          )
        )}
      </div>
    )
  }
}

DragDropRow.propTypes = {
  file: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool.isRequired,
  isDragging: PropTypes.bool.isRequired,
  moveIntoFolder: PropTypes.func.isRequired,
  toggleNewFolder: PropTypes.func.isRequired,
  updateNewFolder: PropTypes.func.isRequired,
  updateUtils: PropTypes.func.isRequired
}

const fileSource = {
  beginDrag(props) {
    props.updateUtils("active_dragging_type", "FILE")
    return props.file
  },
  endDrag(props) {
    props.updateUtils("active_dragging_type", null)
  }
}

const dragSource = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

const dragTarget = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  canDrop: monitor.canDrop(),
  isOver: monitor.isOver()
})

export const fileTarget = {
  canDrop(props, monitor) {
    const draggingItem = monitor.getItem()
    const droppingItem = props.file
    return !(
      draggingItem._id === droppingItem._id ||
      (draggingItem.type === "folder" && droppingItem.type !== "folder")
    )
  },
  drop(props, monitor, component) {
    const draggedID = monitor.getItem()._id
    const droppedID = component.props.file._id
    if (component.props.file.type === "folder") {
      component.props.moveIntoFolder([draggedID], droppedID)
    } else {
      component.props.toggleNewFolder()
      component.props.updateNewFolder("initialFiles", [monitor.getItem(), component.props.file])
    }
  }
}

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
  moveIntoFolder: bindActionCreators(moveIntoFolder, dispatch),
  toggleNewFolder: bindActionCreators(toggleNewFolder, dispatch),
  updateNewFolder: bindActionCreators(updateNewFolder, dispatch),
  updateUtils: bindActionCreators(updateUtils, dispatch)
})

export default flow(
  DragSource("FILE", fileSource, dragSource),
  DropTarget("FILE", fileTarget, dragTarget),
  connect(mapStateToProps, mapDispatchToProps)
)(DragDropRow)
