export const getDiscPrice = (items, lengthInMinutes, type, quantity) => {
  if (type !== "dvd" && type !== "blu_ray") throw new Error("Type must be dvd or bluray.")
  if (typeof lengthInMinutes !== "number") throw new Error("Length must be a number.")
  if (!items || typeof items !== "object") throw new Error("Items must be an object.")
  if (typeof quantity !== "number") throw new Error("Quantity must be a number.")

  // Per Disc Price
  const thermal = {
    dvd: "thermal_silver_dvd",
    blu_ray: "thermal_white_bluray"
  }

  let discPrice = 0
  discPrice += getPrice(items[thermal[type]], quantity)
  discPrice += getPrice(items.paper_sleeve, quantity)

  // Encode Price
  const types = {
    dvd: "disc_image_dvd_per_minute",
    blu_ray: "disc_image_bluray_per_minute"
  }

  let encodePrice = 0

  const minPrice = items[types[type]].price
  const realPrice = lengthInMinutes * items[types[type]].price

  encodePrice += Math.max(minPrice, realPrice)
  encodePrice += items.authoring_autoplay.price

  return {
    disc: parseFloat(discPrice).toFixed(2),
    encode: parseFloat(encodePrice).toFixed(2),
    total: (parseFloat(discPrice * quantity) + parseFloat(encodePrice)).toFixed(2)
  }
}

const getPrice = (item, quantity) => {
  let highestQuantity = 0

  item.available_quantities.forEach(qty => {
    if (quantity >= qty && quantity > highestQuantity) {
      highestQuantity = qty
    }
  })
  if (highestQuantity === 0) highestQuantity = 1

  const currencyCode = item.currency_code
  const matchingPriceFilter = item.prices.filter(
    price => price.currency_code === currencyCode && price.quantity === highestQuantity
  )

  const matchingPrice = matchingPriceFilter[0]

  return parseFloat(matchingPrice.price)
}
