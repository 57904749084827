import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { saveScreener } from "@api/project/files/screeners"
import "../styles.scss"

class Title extends React.Component {
  constructor(props) {
    super(props)
    this.state = { name: props.screener.name, editing: false }
  }
  render() {
    return this.state.editing ? (
      <input
        className='cs-input col-12'
        style={{ maxWidth: "300px" }}
        value={this.state.name}
        autoFocus
        onChange={({ target: { value } }) => this.setState({ name: value })}
        onBlur={({ target: { value } }) => {
          this.setState({ editing: false })
          if (value !== this.props.screener.name) {
            this.props.saveScreener("name", value || this.props.screener.name)
            if (!value) {
              this.setState({ name: this.props.screener.name })
            }
          }
        }}/>
    ) : (
      <div className={`flex items-start title`}>
        <div>
          <h4>{this.state.name}</h4>
          <small className='block muted'>{this.props.file.name}</small>
        </div>
        <button onClick={() => this.setState({ editing: true })} className='cs-button link ml2 material-icons small'>
          <span className='muted'>mode_edit</span>
        </button>
      </div>
    )
  }
}

const mapStateToProps = (state, context) => ({
  screener: state.project.files.screener
})

const mapDispatchToProps = dispatch => ({
  saveScreener: bindActionCreators(saveScreener, dispatch)
})

Title.propTypes = {
  screener: PropTypes.object.isRequired,
  file: PropTypes.object.isRequired,
  saveScreener: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Title)
