import {
  GET_DEPARTMENTS_PENDING,
  GET_DEPARTMENTS_FULFILLED,
  TOGGLE_CREATE_DEPARTMENT_MODAL,
  TOGGLE_EDIT_DEPARTMENT_MODAL,
  CREATE_DEPARTMENT_PENDING,
  CREATE_DEPARTMENT_FULFILLED,
  EDIT_DEPARTMENT_PENDING,
  EDIT_DEPARTMENT_FULFILLED,
  DELETE_DEPARTMENT_FULFILLED
} from "@actions/settings/departments"

const initialState = {
  status: "PENDING",
  items: [],
  department: {},
  showCreateModal: false,
  showEditModal: false,
  isSaving: false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DEPARTMENTS_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_DEPARTMENTS_FULFILLED:
      return {
        ...state,
        status: "COMPLETE",
        items: action.payload
      }

    case TOGGLE_CREATE_DEPARTMENT_MODAL:
      return {
        ...state,
        showCreateModal: !state.showCreateModal
      }

    case TOGGLE_EDIT_DEPARTMENT_MODAL:
      return {
        ...state,
        showEditModal: !state.showEditModal,
        department: !state.showEditModal ? action.payload.department : {}
      }

    case CREATE_DEPARTMENT_PENDING:
      return {
        ...state,
        isSaving: true
      }

    case CREATE_DEPARTMENT_FULFILLED:
      return {
        ...state,
        isSaving: false,
        items: action.payload,
        showCreateModal: false
      }

    case EDIT_DEPARTMENT_PENDING:
      return {
        ...state,
        isSaving: true
      }

    case EDIT_DEPARTMENT_FULFILLED:
      return {
        ...state,
        isSaving: false,
        items: action.payload,
        showEditModal: false
      }

    case DELETE_DEPARTMENT_FULFILLED:
      return {
        ...state,
        items: action.payload
      }

    default:
      return state
  }
}
