import React from "react"
import PropTypes from "prop-types"
import config from "../../config"
import 'styled-components/macro'

const BaseSlide = ({ children }) => (
  <div
    css={`
      position: relative;
      width: ${config.width}px;
      height: ${config.height}px;
      background: #fff;
      margin: 0 auto;
      border-radius: ${config.borderRadius}px;
    `}>
    {children}
  </div>
)

BaseSlide.propTypes = {
  children: PropTypes.object.isRequired
}

export default BaseSlide
