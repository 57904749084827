import React from "react"
import PropTypes from "prop-types"
import Select from "react-select"

const regionTypes = {
  CA: "provinces",
  US: "states"
}

const RegionalGeoblocking = ({ newReviewers, onUpdate, countries, region }) => {
  const country = countries.filter(c => c.value === region)[0]
  const regions = country.regions.map(r => ({ label: r.region, value: r["region-code"] }))
  const geoblockRegionCodes = newReviewers.geoblock_region_codes || {}
  const specificRegionCodes = geoblockRegionCodes[region] || []
  const selectedRegions = regions.filter(r => specificRegionCodes.includes(r.value))
  return (
    <div className='border rounded border-gray-5 mt2 p2'>
      <h4>{country.country} Policies</h4>
      <div className='pt2'>
        <label className='cs-label'>
          Select which {regionTypes[region]} are <strong>allowed</strong> to view videos. If none are selected, all{" "}
          {regionTypes[region]} are allowed.
        </label>
        <Select
          key={`${region}_region_select_key`}
          multi={true}
          options={regions}
          value={selectedRegions}
          className={"flex-auto"}
          onChange={values => {
            onUpdate("geoblock_region_codes", {
              ...newReviewers.geoblock_region_codes,
              [region]: values ? values.map(v => v.value) : []
            })
          }}/>
      </div>
    </div>
  )
}

RegionalGeoblocking.propTypes = {
  countries: PropTypes.array,
  newReviewers: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  region: PropTypes.string.isRequired
}

export default RegionalGeoblocking
