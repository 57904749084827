import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { removeCartItem, updateCart } from "@api/account/cart"
import Address from "@components/address"
import { Radio, Status } from "@bitcine/cs-theme"
import EmptyCartIcon from "@src/public/icons/empty/cart.inline.svg"
import Order from "./order"
import NewShippingAddress from "./new"

const Shipping = ({
  removeCartItem,
  updateCart,
  cart: { newAddress, orders, status, addressError, shipment, willPickup }
}) => (
  <div>
    {newAddress.visible && <NewShippingAddress/>}
    <Status
      pending={status === "PENDING"}
      empty={!orders.length}
      emptyMessage={{
        icon: <EmptyCartIcon/>,
        title: "Your cart is empty"
      }}>
      <React.Fragment>
        <div
          className={`rounded border ${
            addressError && !willPickup ? "border-red" : "border-gray-5"
          } bg-gray-6 px2 mb2`}>
          {orders.map((order, i) => (
            <Order index={i} order={order} removeCartItem={() => removeCartItem(order._id)} key={order._id}/>
          ))}
          {shipment && (
            <div>
              <div className='pt3 mb2 items-center border-top border-gray-5'>
                {addressError && !willPickup && (
                  <div className='flex items-center flex-auto red mb3'>
                    <span className='material-icons mr1'>error</span>
                    <strong>Address is required.</strong>
                  </div>
                )}

                <div className='flex items-center justify-between mb2'>
                  <div className='flex items-center'>
                    <Radio
                      id='destination_address_radio'
                      checked={!willPickup}
                      onChange={() => updateCart("willPickup", false)}/>
                    <label className='cs-label flex-auto' htmlFor='destination_address_radio'>
                      <strong className='px1'>Delivery to address of your choice</strong>
                    </label>
                  </div>
                  <button
                    disabled={willPickup}
                    className='cs-button white small ml2'
                    onClick={() => updateCart("newAddress", { ...newAddress, visible: true })}>
                    {shipment && shipment.destination_address ? "Change" : "Add"} Address
                  </button>
                </div>
                {shipment && shipment.destination_address && !willPickup && (
                  <div className='flex justify-between rounded border border-gray-5 p2 mb2 ml2'>
                    <Address address={shipment.destination_address}/>
                    {shipment.selected_rate && (
                      <b className='capitalize mt1'>
                        {shipment.selected_rate.shipping_type
                          .split("_")
                          .join(" ")
                          .toLowerCase()}
                        : {shipment.selected_rate.rate_formatted}
                      </b>
                    )}
                  </div>
                )}
                <div className='flex items-center'>
                  <Radio
                    id='cinesend_address_radio'
                    checked={willPickup}
                    onChange={() => updateCart("willPickup", true)}/>
                  <label className='cs-label flex-auto' htmlFor='cinesend_address_radio'>
                    <strong className='px1'>
                      Pickup from CineSend office (208 Adelaide St W 2nd Fl, Toronto ON Canada, M5H 1W7)
                    </strong>
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    </Status>
  </div>
)

Shipping.propTypes = {
  removeCartItem: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  updateCart: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  cart: state.user.cart
})

const mapDispatchToProps = dispatch => ({
  removeCartItem: bindActionCreators(removeCartItem, dispatch),
  updateCart: bindActionCreators(updateCart, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Shipping)
