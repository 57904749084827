import React from "react"
import PropTypes from "prop-types"
import * as Icons from "./icons"

const Icon = ({ data }) => {
  if (!!data.frozen_at) {
    return <Icons.Frozen/>
  }
  return {
    document: <Icons.Document file={data}/>,
    dcp: <Icons.DCP/>,
    subtitle_track: <Icons.Subtitles/>,
    closed_caption: <Icons.Captions/>,
    folder: <Icons.Folder/>,
    source_file: <Icons.SourceFile screener={data.screener}/>,
    audio_track: <Icons.AudioTrack/>,
    disc_output: <Icons.Disc/>
  }[data.type]
}

Icon.propTypes = {
  data: PropTypes.object.isRequired
}

export default Icon
