import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import "./styles.scss"

/*
  Snackbar Component
  This is a temp component until @bitcine/cs-theme has a new <SnackBar/> component built in
  This should not be used anywhere else
*/

class Snackbar extends React.Component {
  render() {
    return !this.props.open ? null : (
      <div
        className={`snackbar fixed bottom-0 bg-white rounded bold p2 box-shadow mb2 flex items-center border-bottom border-red border-width-2`}>
        <span className='material-icons red mr2'>cloud_upload</span>
        <strong>Drop files into {this.props.dropName}</strong>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let text = "All Files"
  if (ownProps.folderID) {
    const folder = [...state.project.files.items.list, ...state.project.files.items.breadcrumb].find(
      folder => folder._id === ownProps.folderID
    )
    if (folder) text = folder.name
  }
  return {
    dropName: text
  }
}

Snackbar.propTypes = {
  dropName: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  folderID: PropTypes.string
}

export default connect(
  mapStateToProps,
  null
)(Snackbar)
