import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import SharedScreeners from "@components/shared_screeners"

const Reviewers = ({ organizationID }) => (
  <div className='m2 pt2'>
    <SharedScreeners parentID={organizationID} parentType={"organization"}/>
  </div>
)

Reviewers.propTypes = {
  organizationID: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  organizationID: state.user.auth.organization_id
})

export default connect(mapStateToProps)(Reviewers)
