import {
  ADD_NEW_FOLDER,
  TOGGLE_NEW_FOLDER,
  UPDATE_NEW_FOLDER,
  TOGGLE_EDIT_FOLDER,
  EDIT_FOLDER,
  TOGGLE_MOVE_FOLDER,
  GET_FOLDERS,
  MOVE_INTO_FOLDER,
  DELETE_FOLDER
} from "@actions/project/folders/"
import { updateFile, updateFiles } from "@api/project/files/"
import { addGlobalMessage } from "@api/utils/"
import { post, put, get, del } from "@api/fetch"

/**
 * Add New Folder
 *
 * @param {String} parentFolderID
 * @param {String} name
 *
 * @returns {Function} dispatch
 */

export const addNewFolder = (parentFolderID = "", name, cb) => (dispatch, getState) => {
  const projectID = getState().project.details._id
  const files = getState().project.files.folders.newFolder.initialFiles || []
  const _files = files.map(({ _id, type }) => ({ _id, type }))
  const _ids = files.reduce((allFiles, item) => [...allFiles, item._id], [])
  const list = getState().project.files.items.list
  dispatch(updateFiles("list", list.filter(file => !_ids.includes(file._id))))
  dispatch(updateFiles("checked", []))
  dispatch(
    post(
      ADD_NEW_FOLDER,
      `projects/${projectID}/folders`,
      {
        name,
        folder_id: parentFolderID,
        _files
      },
      res => {
        dispatch(addGlobalMessage(`${name} Created!`, "success"))
        if (cb && typeof cb === "function") cb(res)
      }
    )
  )
}

/**
 * Toggle New Folder Visibility
 *
 * @returns {Function} dispatch
 */

export const toggleNewFolder = () => dispatch => dispatch({ type: TOGGLE_NEW_FOLDER })

/**
 * Update New Folder
 *
 * @param {String} key
 * @param {String} value
 *
 * @returns {Function} dispatch
 */

export const updateNewFolder = (key, value) => dispatch => dispatch({ type: UPDATE_NEW_FOLDER, key, value })

/**
 * Toggle Folder Editing State
 *
 * @param {Object} folder
 *
 * @returns {Function} dispatch
 */

export const toggleEditFolder = folder => dispatch => dispatch({ type: TOGGLE_EDIT_FOLDER, folder })

/**
 * Edit folder
 * Note: Updates state and server
 *
 * @param {String} folderID
 * @param {String} key
 * @param {Array} or {Object} or {String} value
 *
 * @returns {Function} dispatch
 */

export const editFolder = (folderID, key, value) => (dispatch, getState) => {
  const projectID = getState().project.details._id
  dispatch(
    put(EDIT_FOLDER, `projects/${projectID}/folder/${folderID}`, { key, value }, () => {
      dispatch(updateFile(folderID, key, value))
    })
  )
}

/**
 * Toggle Move Folder State
 *
 * @returns {Function} dispatch
 */

export const toggleMoveFolder = () => dispatch => dispatch({ type: TOGGLE_MOVE_FOLDER })

/**
 * Get folders
 * Note: This gets all folders without the files
 *
 * @param {String} folderID
 *
 * @returns {Function} dispatch
 */

export const getFolders = folderID => (dispatch, getState) =>
  dispatch(
    get(
      GET_FOLDERS,
      `projects/${getState().project.details._id}/folders/all${folderID ? `?folderID=${folderID}` : ""}`
    )
  )

/**
 * Move files/folders into folder
 *
 * @param {Array} fileIDs
 * @param {String} folderID
 *
 * @returns {Function} dispatch
 */

export const moveIntoFolder = (fileIDs, folderID = "") => (dispatch, getState) => {
  const { list } = getState().project.files.items
  const _files = list.filter(file => fileIDs.includes(file._id)).map(({ _id, type }) => ({ _id, type }))
  const _ids = _files.reduce((allFiles, item) => [...allFiles, item._id], [])

  // const location = getState().routerParams.location
  const routedFolderID = null // location && location.query.folderID ? location.query.folderID : ""
  // The state should only be updated if the asset is moving to a different folder than the current one
  if (routedFolderID !== folderID) {
    dispatch(updateFiles("list", list.filter(file => !_ids.includes(file._id))))
  }
  dispatch(updateFiles("checked", []))
  dispatch(
    post(
      MOVE_INTO_FOLDER,
      `projects/${getState().project.details._id}/folders/insert_file${folderID ? `?folderID=${folderID}` : ""}`,
      { _files },
      () => {
        dispatch(addGlobalMessage(`${_files.length} asset${_files.length > 1 ? "s" : ""} moved`, "success"))
      }
    )
  )
}

/**
 * Move files/folders into new folder
 *
 * @param {String} folderID
 * @param {String} name
 *
 * @returns {Function} dispatch
 */

export const createAndMoveIntoFolder = (folderID, name, fileIDs) => (dispatch, getState) => {
  const projectID = getState().project.details._id
  const { list } = getState().project.files.items
  const _files = list.filter(file => fileIDs.includes(file._id)).map(({ _id, type }) => ({ _id, type }))
  dispatch(updateFiles("list", list.filter(file => !fileIDs.includes(file._id))))
  dispatch(updateFiles("checked", []))
  dispatch(
    post(
      ADD_NEW_FOLDER,
      `projects/${projectID}/folders`,
      {
        name,
        folder_id: folderID,
        _files
      },
      res => {
        dispatch(addGlobalMessage(`${name} created`, "success"))
        dispatch(toggleMoveFolder())
      }
    )
  )
}

/**
 * Delete folder
 *
 * @param {String} folderID
 *
 * @returns {Function} dispatch
 */

export const deleteFolder = folderID => (dispatch, getState) => {
  const { list } = getState().project.files.items
  dispatch(updateFiles("checked", []))
  dispatch(
    del(DELETE_FOLDER, `projects/${getState().project.details._id}/folders/${folderID}`, null, () => {
      dispatch(updateFiles("list", list.filter(file => file._id !== folderID)))
      dispatch(addGlobalMessage("Folder deleted", "success"))
    })
  )
}
