import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Status } from "@bitcine/cs-theme"
import ScreenerRoomNav from "@components/nav/containers/screener_room"
import { getScreenerRoom, generatePreview } from "@api/screener_rooms/"

class ScreenerRoom extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.props.getScreenerRoom(this.props.organizationId, this.props.match.params.id)
    this.props.generatePreview(this.props.match.params.id)
  }
  componentDidUpdate(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.props.getScreenerRoom(this.props.organizationId, nextProps.match.params.id)
      this.props.generatePreview(nextProps.match.params.id)
    }
  }
  render() {
    const { screenerRoom } = this.props
    return (
      <div className='col-12'>
        <Status pending={screenerRoom.status === "PENDING"}>
          <div>
            <ScreenerRoomNav title={screenerRoom.data.name} _id={screenerRoom.data._id}/>
            <div className='col-12 mt4 pt4 px3'>{this.props.children}</div>
          </div>
        </Status>
      </div>
    )
  }
}

ScreenerRoom.propTypes = {
  children: PropTypes.node.isRequired,
  screenerRoom: PropTypes.object.isRequired,
  getScreenerRoom: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  generatePreview: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  screenerRoom: state.screener_rooms.screenerRoom,
  organizationId: state.user.auth.organization_id
})

const mapDispatchToProps = dispatch => ({
  getScreenerRoom: bindActionCreators(getScreenerRoom, dispatch),
  generatePreview: bindActionCreators(generatePreview, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ScreenerRoom)
