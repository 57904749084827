import React from "react"
import PropTypes from "prop-types"
import Mastercard from "@src/public/payment/mastercard-logo.png"
import Visa from "@src/public/payment/visa-logo.png"
import Amex from "@src/public/payment/amex-logo.png"
import { Checkbox } from "@bitcine/cs-theme"
import { cardExpired } from "@src/helpers/card_expired"

const bg = {
  visa: "linear-gradient(270deg, #6A94DC 0%, #3869C7 100%)",
  american_express: "linear-gradient(-269deg, #33A0E4 3%, #007ECD 97%)",
  mastercard: "linear-gradient(270deg, #58585A 0%, #58585A 100%)"
}

const img = {
  visa: Visa,
  american_express: Amex,
  mastercard: Mastercard
}

const CreditCardLine = ({ card, checked, onSelect, postPay }) => {
  const isExpired = cardExpired(card)
  return (
    <div
      style={{ background: bg[card.brand.replace(" ", "_").toLowerCase()] }}
      className='pointer flex items-center justify-between p1 rounded my1 white'>
      <div className='flex items-center'>
        {isExpired ?
          <div className='pr1'>Card Expired</div> :
          <Checkbox disabled={postPay} checked={checked} onChange={onSelect}/>
        }
        <div className='pl3'>
          {card.brand === "American Express" ? `**** ****** *${card.last4}` : `**** **** **** ${card.last4}`}
        </div>
        <div className='pl2'>
          ({isExpired ? 'Expired' : 'Expires'} {card.exp_month} / {card.exp_year})
        </div>
      </div>
      <img
        className='cs-img'
        style={{ width: "50px", float: "right", marginTop: "5px" }}
        src={img[card.brand.replace(" ", "_").toLowerCase()]}
        alt={card.brand}/>
    </div>
  )
}

CreditCardLine.propTypes = {
  card: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  checked: PropTypes.bool.isRequired,
  postPay: PropTypes.bool.isRequired
}

export default CreditCardLine
