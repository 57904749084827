import React from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"

dayjs.extend(LocalizedFormat)

const DateTime = ({ date }) => (
  <div>
    <div>
      {dayjs
        .utc(date)
        .local()
        .format("MMMM D, YYYY")}
    </div>
    <div>
      {dayjs
        .utc(date)
        .local()
        .format("LT")}{" "}
      <span className='muted small'>(UTC: {dayjs.utc(date).format("LT")})</span>
    </div>
  </div>
)

DateTime.propTypes = {
  date: PropTypes.string
}

export default DateTime
