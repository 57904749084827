import {
  GET_SCREENER_ATTACHMENTS_FULFILLED,
  GET_SCREENER_ATTACHMENTS_PENDING,
  GET_SCREENER_ATTACHMENTS_FAILED,
  UPDATE_SCREENER_ATTACHMENTS_LIST,
  DELETE_SCREENER_ATTACHMENT_FULFILLED,
  DELETE_SCREENER_ATTACHMENT_PENDING,
  DELETE_SCREENER_ATTACHMENT_FAILED,
  IMPORT_FILES_FOR_SCREENER_ATTACHMENT_PENDING,
  IMPORT_FILES_FOR_SCREENER_ATTACHMENT_FULFILLED,
  IMPORT_FILES_FOR_SCREENER_ATTACHMENT_FAILED
} from "@actions/project/screener_attachments"

const initialState = {
  status: "PENDING",
  items: []
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SCREENER_ATTACHMENTS_PENDING:
    case DELETE_SCREENER_ATTACHMENT_PENDING:
    case IMPORT_FILES_FOR_SCREENER_ATTACHMENT_PENDING:
      return {
        ...state,
        status: "PENDING",
        items: []
      }

    case GET_SCREENER_ATTACHMENTS_FULFILLED:
    case DELETE_SCREENER_ATTACHMENT_FULFILLED:
    case IMPORT_FILES_FOR_SCREENER_ATTACHMENT_FULFILLED:
      return {
        ...state,
        status: "READY",
        items: action.payload
      }

    case GET_SCREENER_ATTACHMENTS_FAILED:
    case DELETE_SCREENER_ATTACHMENT_FAILED:
    case IMPORT_FILES_FOR_SCREENER_ATTACHMENT_FAILED:
      return {
        ...state,
        status: "FAILED",
        items: []
      }

    case UPDATE_SCREENER_ATTACHMENTS_LIST:
      return {
        ...state,
        items: action.payload.items
      }

    default:
      return state
  }
}
