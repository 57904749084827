import {
  UPDATE_ASPERA_SHARING,
  GET_ASPERA_SHARING_PRICES_PENDING,
  GET_ASPERA_SHARING_PRICES_FAILED,
  GET_ASPERA_SHARING_PRICES_FULFILLED,
  GET_ASPERA_ORDER_PENDING,
  GET_ASPERA_ORDER_FAILED,
  GET_ASPERA_ORDER_FULFILLED,
  CREATE_PACKAGE_PENDING,
  CREATE_PACKAGE_FAILED,
  CREATE_PACKAGE_FULFILLED,
  UPDATE_ASPERA_SHARE_LABEL
} from "@actions/project/files"

const initialState = {
  visible: false,
  files: [],
  emails: [],
  status: "READY",
  label: "",
  item: null,
  order: null,
  errorMessage: "",
  password: null
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_ASPERA_SHARING:
      if (action.key === "visible" && !action.value) {
        return {
          ...initialState,
          visible: false
        }
      }
      return {
        ...state,
        [action.key]: action.value
      }

    case GET_ASPERA_SHARING_PRICES_PENDING:
      return {
        ...state,
        visible: true,
        status: "PENDING"
      }

    case GET_ASPERA_SHARING_PRICES_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case GET_ASPERA_SHARING_PRICES_FULFILLED:
      return {
        ...state,
        item: action.payload.aspera_transfers,
        status: "READY"
      }

    case GET_ASPERA_ORDER_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_ASPERA_ORDER_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case GET_ASPERA_ORDER_FULFILLED:
      return {
        ...state,
        order: action.payload,
        status: "READY"
      }

    case CREATE_PACKAGE_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case CREATE_PACKAGE_FAILED:
      return {
        ...state,
        errorMessage: action.payload.message,
        status: "ERROR"
      }

    case CREATE_PACKAGE_FULFILLED:
      return {
        ...state,
        invoice_id: action.payload.invoice_id,
        status: "COMPLETE"
      }

    case UPDATE_ASPERA_SHARE_LABEL:
      return {
        ...state,
        label: action.payload.label
      }

    default:
      return state
  }
}
