import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import Logo from "@src/public/logo.inline.svg"
import User from "./components/main/user"
import OrganizationNavigation from "./containers/organization"
import 'styled-components/macro'

const MobileNav = () => 
  <nav
    css={`
      z-index: 10;
    `}
    className='fixed top-0 left-0 col-12 bg-white px3 box-shadow'
    id='nav'>
    <div
      css={`
        height: 56px;
      `}
      className='flex items-center col-12 justify-between'>
      <Link className='flex items-center' to='/'>
        <Logo
          css={`
            width: 100px;
            height: 40px;
          `}/>
        <strong className='ml2'>Files</strong>
      </Link>
      <div className='flex items-center'>
        <OrganizationNavigation mobile={true}/>
        <User mobile={true}/>
      </div>
    </div>
  </nav>

MobileNav.propTypes = {
  user: PropTypes.shape({
    organization_id: PropTypes.string,
    app_permissions: PropTypes.object.isRequired
  }).isRequired
}

const mapStateToProps = state => ({
  user: state.user.auth
})

export default connect(mapStateToProps)(MobileNav)
