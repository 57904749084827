import { GET_HISTORY, UPDATE_HISTORY, UPDATE_HISTORY_FILTER } from "@actions/project/history"

import { get, put } from "@api/fetch"

/**
 * Get History
 * - retrives the history of the project
 * @param {projectID} - string
 *
 * @returns dispatch
 */

export const getHistory = (search, sortDir, sortBy, take, skip) => (dispatch, getState) => {
  const route = `projects/${getState().project.details._id}/history`
  const params = `?search=${search}&sortDir=${sortDir}&sortBy=
	${sortBy}&take=${take}&skip=${skip}`
  dispatch(get(GET_HISTORY, `${route}${params}`))
}
/**
 * Update History
 * - updates history state - mainly used for filter state
 *
 * @returns dispatch
 */

export const updateHistory = (key, value) => dispatch => dispatch({ type: UPDATE_HISTORY, key, value })

/**
 * Update the history filter (search, sort, etc)
 *
 * @returns dispatch
 */

export const updateHistoryFilter = (key, value) => dispatch => dispatch({ type: UPDATE_HISTORY_FILTER, key, value })
/**
 * Expire Links
 *
 *
 * @returns dispatch
 */

export const expireLinks = (packageID, expiryDate, logID, sharedScreenerID) => (dispatch, getState) =>
  dispatch(
    put(GET_HISTORY, `projects/${getState().project.details._id}/expireLinks`, {
      packageID,
      logID,
      expiryDate,
      sharedScreenerID
    })
  )
