import React from "react"
import { Link } from "react-router-dom"

const Complete = () => (
  <div className='flex flex-auto col-12 items-center justify-center mt3'>
    <div className='center'>
      <span className='material-icons large-icon inline-block red'>add_shopping_cart</span>
      <h4 className='mb0'>Success</h4>
      <p>Your cart has been updated</p>
      <Link className='block col-12' to='/cart'>
        <button className='cs-button col-12 block'>Checkout</button>
      </Link>
    </div>
  </div>
)

export default Complete
