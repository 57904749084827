import {
  CREATE_SHARED_SCREENERS,
  GET_SHARED_SCREENERS,
  TOGGLE_MODAL,
  UPDATE_SHARED_SCREENER,
  GET_SHARED_SCREENER_STATS,
  SET_QUERY_VALUE,
  SET_PARENT_VALUE,
  GET_SHARED_SCREENER_SESSIONS
} from "@actions/shared_screeners/"

const initialState = {
  status: "PENDING",
  list: [],
  size: 0,
  query: {
    skip: 0,
    search: "",
    sortBy: "created_at",
    sortDirection: "desc",
    screenerID: null,
    screenerGroupID: null,
    organizationID: null
  },
  modals: {
    password: {
      visible: false,
      sharedScreener: null,
      status: "READY"
    },
    expiryDate: {
      visible: false,
      sharedScreener: null
    },
    viewCountLimit: {
      visible: false,
      sharedScreener: null,
    },
    screenerRoomViewers: {
      visible: false,
      sharedScreener: null,
      analytics: [],
      status: "PENDING"
    },
    sessions: {
      visible: false,
      sharedScreener: null,
      status: "PENDING",
      list: []
    }
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PARENT_VALUE:
      return {
        ...state,
        query: {
          ...state.query,
          parentID: action.payload.parentID,
          parentType: action.payload.parentType
        }
      }
    case SET_QUERY_VALUE:
      return {
        ...state,
        query: {
          ...state.query,
          [action.payload.key]: action.payload.value
        }
      }
    case GET_SHARED_SCREENERS.PENDING:
      return {
        ...state,
        status: "PENDING"
      }
    case GET_SHARED_SCREENERS.COMPLETE:
      return {
        ...state,
        list: action.payload.list,
        size: action.payload.size,
        status: "READY"
      }
    case GET_SHARED_SCREENER_SESSIONS.PENDING:
      return {
        ...state,
        modals: {
          ...state.modals,
          sessions: {
            ...state.modals.sessions,
            status: "PENDING",
            list: []
          }
        }
      }
    case GET_SHARED_SCREENER_SESSIONS.COMPLETE:
      return {
        ...state,
        modals: {
          ...state.modals,
          sessions: {
            ...state.modals.sessions,
            status: "READY",
            list: action.payload
          }
        }
      }
    case GET_SHARED_SCREENER_SESSIONS.FAILED:
      return {
        ...state,
        modals: {
          ...state.modals,
          sessions: {
            ...state.modals.sessions,
            status: "FAILED",
            list: []
          }
        }
      }
    case TOGGLE_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload.key]: {
            ...state.modals[action.payload.key],
            visible: !state.modals[action.payload.key].visible,
            sharedScreener: action.payload.sharedScreener
          }
        }
      }
    }
    case UPDATE_SHARED_SCREENER.COMPLETE: {
      return {
        ...state,
        modals: initialState.modals,
        list: state.list.map(s => (s._id === action.payload._id ? action.payload : s))
      }
    }
    case GET_SHARED_SCREENER_STATS.PENDING:
      return {
        ...state,
        modals: {
          ...state.modals,
          screenerRoomViewers: {
            ...state.modals.screenerRoomViewers,
            status: "PENDING",
            analytics: []
          }
        }
      }
    case GET_SHARED_SCREENER_STATS.COMPLETE:
      return {
        ...state,
        modals: {
          ...state.modals,
          screenerRoomViewers: {
            ...state.modals.screenerRoomViewers,
            analytics: action.payload,
            status: "READY"
          }
        }
      }
    case CREATE_SHARED_SCREENERS.COMPLETE:
      return {
        ...state,
        status: "READY",
        list: action.payload.shared_screeners.list,
        size: action.payload.shared_screeners.size,
        query: {
          ...state.query,
          skip: 0,
          search: "",
          sortBy: "created_at",
          sortDirection: "desc"
        }
      }
    default:
      return state
  }
}
