import React from "react"

const ErrorUI = () => (
  <div className='flex py2 items-center justify-center col-12'>
    <div className='center'>
      <span className='inline-block large-icon muted material-icons'>error</span>
      <h4>An Error Occured</h4>
      <p>Please contact support if this problem continues</p>
    </div>
  </div>
)

export default ErrorUI
