import { SAVE_ACCESS, INVITE_USERS } from "@actions/project/access"

import { addGlobalMessage } from "@api/utils/"
import { post } from "@api/fetch"

export const saveAccess = (projectId, userIds, cb = null) => dispatch =>
  dispatch(
    post(SAVE_ACCESS, `projects/${projectId}/access`, { userIds }, res => {
      if (typeof cb === "function") {
        cb()
      }
      dispatch(addGlobalMessage("Saved!", "success"))
    })
  )

export const sendInvites = () => (dispatch, getState) => {
  dispatch(
    post(
      INVITE_USERS,
      `organizations/${getState().user.auth.organization_id}/users`,
      {
        users: getState().project.access.membersToInvite,
        projectId: getState().project.details._id
      },
      () => dispatch(addGlobalMessage("User(s) Invited", "success"))
    )
  )
}
