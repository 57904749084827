import React from "react"
import PropTypes from "prop-types"
import "./styles.scss"

class Document extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      image: null
    }
    const url = props.document.url || ""
    this.isTransparentImage = ["png"].includes(
      url
        .toLowerCase()
        .split(".")
        .pop()
    )
  }
  componentDidMount() {
    this.image = new Image()
    this.image.src = this.props.document.preview_image_url_lg
    this.image.onload = () => this.setState({ loaded: true, image: this.props.document.preview_image_url_lg })
  }
  componentWillUnmount() {
    if (this.image) {
      this.image.onload = null
      this.image = null
    }
  }
  render() {
    const { loaded, image } = this.state
    return (
      <div
        className={`absolute top-0 left-0 right-0 bottom-0 flex items-stretch  ${
          this.isTransparentImage ? `grid p2` : ""
        }`}>
        <div
          className={`col-12 bg-center bg-no-repeat ${this.isTransparentImage ? "bg-contain " : "bg-cover rounded"}
						gridDocumentThumbnail
						${loaded ? "gridDocumentThumbnailLoaded" : ""}`}
          style={{
            backgroundImage: `url(${image})`,
            borderBottomLeftRadius: "0",
            borderBottomRightRadius: "0"
          }}/>
      </div>
    )
  }
}

Document.propTypes = {
  document: PropTypes.object.isRequired
}

export default Document
