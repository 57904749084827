import React, { useState } from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { getSourceFiles, submitSourceFile } from "@api/ondemand_videos/"
import { showGlobalAuthModal } from "@api/account/auth/"
import { Modal } from "@bitcine/cs-theme"
import Select from "react-select"

const VideoSelect = ({ sourceFiles, getSourceFiles, submitSourceFile, video, authenticated, showGlobalAuthModal }) => {
  const [sourceFileID, setSourceFileID] = useState(null)
  const [showModal, setShowModal] = useState(false)
  return (
    <div>
      <button
        className='cs-button col-12 outline small nowrap'
        disabled={sourceFiles.status === "PENDING"}
        onClick={() => {
          if (!authenticated) {
            showGlobalAuthModal(() => {
              getSourceFiles()
              setShowModal(true)
            })
          } else {
            getSourceFiles()
            setShowModal(true)
          }
        }}>
        Import From CineSend Files
      </button>
      {showModal && (
        <Modal open={true} onClose={() => setShowModal(false)} title='Select Source File'>
          <>
            <p>Select your source file below:</p>
            <Select
              className='col-12'
              options={sourceFiles.list.map(sourceFile => ({
                label: sourceFile.name,
                value: sourceFile._id
              }))}
              value={sourceFileID}
              isLoading={sourceFiles.status === "PENDING"}
              onChange={option => setSourceFileID(option.value)}/>
            <div className='flex justify-end mt2'>
              <button
                className='cs-button'
                disabled={!sourceFileID}
                onClick={() => {
                  submitSourceFile(video._id, sourceFileID)
                  setShowModal(false)
                }}>
                Submit File
              </button>
            </div>
          </>
        </Modal>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  authenticated: state.user.auth.status === "AUTHENTICATED",
  video: state.ondemand_videos.video,
  sourceFiles: state.ondemand_videos.sourceFiles
})

const mapDispatchToProps = dispatch => ({
  getSourceFiles: bindActionCreators(getSourceFiles, dispatch),
  submitSourceFile: bindActionCreators(submitSourceFile, dispatch),
  showGlobalAuthModal: bindActionCreators(showGlobalAuthModal, dispatch)
})

VideoSelect.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  video: PropTypes.object.isRequired,
  getSourceFiles: PropTypes.func.isRequired,
  sourceFiles: PropTypes.object.isRequired,
  submitSourceFile: PropTypes.func.isRequired,
  showGlobalAuthModal: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoSelect)
