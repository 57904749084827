import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Checkbox } from "@bitcine/cs-theme"

const ForensicWatermark = ({
  newReviewers,
  onUpdate,
  disabled,
  remainingForensicWatermarkReviewers,
  monthlyForensicWatermarkReviewers
}) => (
  <div className={`py2 border-bottom border-gray-5 ${disabled ? "muted" : ""}`}>
    <div className='flex items-center'>
      <Checkbox
        id='enableWatermark'
        disabled={disabled}
        checked={newReviewers.use_forensic_watermark}
        onChange={e => onUpdate("use_forensic_watermark", !newReviewers.use_forensic_watermark)}/>
      <div className='pl1 col-12 flex items-start justify-between'>
        <div>
          <h4 className='mb0 flex items-center'>Enable Forensic Watermark</h4>
          <p className='mb0'>
            Enable a unique, hidden forensic watermark to protect your film from piracy.&nbsp;
            <a
              href='https://cinesend.com/products/files/features/online-screeners'
              target='_blank'
              className='underline'
              rel='noopener noreferrer'>
              Learn More
            </a>
          </p>
        </div>
        <p className='muted italic'>
          {remainingForensicWatermarkReviewers} / {monthlyForensicWatermarkReviewers} forensic watermark screeners
          remaining
        </p>
      </div>
    </div>
  </div>
)

const mapStateToProps = (state, context) => ({
  remainingForensicWatermarkReviewers: state.user.auth.subscription.forensic_watermark_reviewers.remaining,
  monthlyForensicWatermarkReviewers: state.user.auth.subscription.forensic_watermark_reviewers.total,
  disabled:
    state.user.auth.subscription.reviewers.remaining === 0 ||
    state.user.auth.subscription.forensic_watermark_reviewers.remaining === 0
})

ForensicWatermark.propTypes = {
  newReviewers: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  settingsLink: PropTypes.string.isRequired,
  remainingForensicWatermarkReviewers: PropTypes.number,
  monthlyForensicWatermarkReviewers: PropTypes.number,
  disabled: PropTypes.bool
}

export default connect(mapStateToProps)(ForensicWatermark)
