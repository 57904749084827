import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import SharedScreeners from "@components/shared_screeners"
import ReviewerButtons from "../components/reviewer_buttons"

const Reviewers = ({ screenerGroupID }) => (
  <SharedScreeners parentID={screenerGroupID} parentType='screener_group' headerComponents={<ReviewerButtons/>}/>
)

Reviewers.propTypes = {
  screenerGroupID: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  screenerGroupID: state.screener_rooms.screenerRoom.data._id
})

export default connect(mapStateToProps)(Reviewers)
