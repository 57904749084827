import {
  GET_COUNTRIES_FULFILLED,
  GET_LANGUAGES_FULFILLED,
  GET_TIMEZONES_FULFILLED,
  GET_USER_LOCATION_FULFILLED,
  GET_FRAME_RATES_FULFILLED,
  GET_RESOLUTIONS_FULFILLED,
  GET_VIDEO_FORMATS_FULFILLED,
  UPDATE_UTILS,
  GET_GENRES_FULFILLED,
  TOGGLE_ASPERA_UPGRADE_MESSAGE
} from "@actions/utils/"

import { SHOW_GLOBAL_AUTH, CLOSE_GLOBAL_AUTH } from "@actions/account/auth"

import { ADD_TRANSFER, UPDATE_TRANSFER } from "@actions/transfers/"

import {
  GET_CAPTIONS_ITEMS_FULFILLED,
  GET_DCP_ITEMS_FULFILLED,
  GET_DISC_ITEMS_FULFILLED,
  GET_SCREENERS_TOP_UP_ITEMS_FULFILLED,
  GET_INSPECTION_ITEMS_FULFILLED,
  GET_CONVERSION_ITEMS_FULFILLED
} from "@actions/utils/items"

const initialState = {
  location: {},
  dialogOpen: false,
  captions_items: null,
  conversion_items: null,
  inspection_items: {},
  global_auth: {
    show: false,
    allow_dismiss: false,
    callback: null
  },
  active_dragging_type: null,
  initialiseTransfer: false,
  show_aspera_upgrade_message: false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_UTILS:
      return {
        ...state,
        [action.key]: action.value
      }

    case ADD_TRANSFER:
      return {
        ...state,
        initialiseTransfer: true
      }

    case UPDATE_TRANSFER:
      return {
        ...state,
        initialiseTransfer: false
      }

    case GET_COUNTRIES_FULFILLED:
      return {
        ...state,
        countries: action.payload
      }

    case GET_GENRES_FULFILLED:
      return {
        ...state,
        genres: action.payload
      }

    case GET_LANGUAGES_FULFILLED:
      return {
        ...state,
        languages: action.payload
      }

    case GET_TIMEZONES_FULFILLED:
      return {
        ...state,
        timezones: action.payload
      }

    case GET_USER_LOCATION_FULFILLED:
      return {
        ...state,
        location: action.payload
      }

    case GET_CAPTIONS_ITEMS_FULFILLED:
      return {
        ...state,
        captions_items: action.payload
      }

    case GET_DCP_ITEMS_FULFILLED:
      return {
        ...state,
        dcp_items: action.payload
      }

    case GET_INSPECTION_ITEMS_FULFILLED:
      return {
        ...state,
        inspection_items: action.payload
      }

    case GET_CONVERSION_ITEMS_FULFILLED:
      return {
        ...state,
        conversion_items: action.payload
      }

    case GET_SCREENERS_TOP_UP_ITEMS_FULFILLED:
      return {
        ...state,
        screeners_top_up_items: action.payload.screeners_top_up
      }

    case GET_DISC_ITEMS_FULFILLED:
      return {
        ...state,
        disc_items: action.payload
      }

    case GET_RESOLUTIONS_FULFILLED:
      return {
        ...state,
        resolutions: action.payload
      }

    case GET_VIDEO_FORMATS_FULFILLED:
      return {
        ...state,
        video_formats: action.payload
      }

    case GET_FRAME_RATES_FULFILLED:
      return {
        ...state,
        frame_rates: action.payload
      }

    case SHOW_GLOBAL_AUTH:
      return {
        ...state,
        global_auth: {
          show: true,
          allow_dismiss: action.allowDismiss,
          callback: action.callback
        }
      }

    case CLOSE_GLOBAL_AUTH:
      return {
        ...state,
        global_auth: initialState.global_auth
      }

    case TOGGLE_ASPERA_UPGRADE_MESSAGE:
      return {
        ...state,
        show_aspera_upgrade_message: !state.show_aspera_upgrade_message
      }

    default:
      return state
  }
}
