import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Promo from "./promo"
import Next from "./next"

const Summary = ({ cart, step }) => (
  <div>
    <h4 className='mb2'>Order Summary</h4>
    <div className='rounded border border-gray-5 p2'>
      <div className='flex items-center justify-between mb2'>
        <span>Items:</span>
        <strong>
          {cart.currency_symbol}
          {cart.subtotal}
        </strong>
      </div>
      <div className='flex items-center justify-between mb2'>
        <span>Shipping &amp; Handling:</span>
        <strong>
          {cart.currency_symbol}
          {cart.willPickup ? "0.00" : cart.shipping_total}
        </strong>
      </div>
      <div className='flex items-center justify-between mb2'>
        <span>Gift Card:</span>
        <strong>
          {parseFloat(cart.gift_card_total) > 0 ? (
            <span className='green'>{`- ${cart.currency_symbol}${
              parseFloat(cart.gift_card_total) > parseFloat(cart.total) ? cart.total : cart.gift_card_total
            }`}</span>
          ) : (
            `${cart.currency_symbol}0.00`
          )}
        </strong>
      </div>
      <div className='flex items-center justify-between mb2'>
        <span>Taxes:</span>
        <strong>
          {cart.currency_symbol}
          {cart.tax_total}
        </strong>
      </div>
      <h4 className='flex mb0 items-center justify-between border-top border-gray-5 pt2'>
        <span>Order Total:</span>
        <span>
          {cart.willPickup
            ? cart.currency_symbol +
              (parseFloat(cart.payment_total) - parseFloat(cart.shipping_total)).toFixed(2) +
              " " +
              cart.currency_code
            : cart.payment_total_formatted}
        </span>
      </h4>
    </div>
    <Promo/>
    <Next step={step}/>
  </div>
)

Summary.propTypes = {
  cart: PropTypes.object,
  step: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  cart: state.user.cart
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Summary)
