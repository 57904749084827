import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Tracking from "@helpers/tracking"

class TrackJS extends React.Component {
  componentDidMount() {
    if (window.trackJs) {
      window.trackJs.configure({ userId: this.props.user.full_name })
    }
    Tracking.setUser(this.props.user)
  }
  render() {
    return this.props.children
  }
}

TrackJS.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  user: state.user.auth
})

export default connect(mapStateToProps)(TrackJS)
