import mixpanel from "mixpanel-browser"

const canUseDOM = require("exenv").canUseDOM

if (canUseDOM) {
  mixpanel.init(
    window.location.origin === "https://app.cinesend.com"
      ? "f12b8279981574b79c954add775c58dc"
      : "be88db9f04e701ced92579596a7d1ff7"
  )
}

/**
 * User event tracking layer
 *
 * Usage:
 *
 *     import Tracking from '@helpers/tracking'
 *
 *     // Track an event
 *     Tracking.record('Event name', {...optionalParams})
 */

let Tracking = {}

Tracking.record = (event, params = {}) => {
  if (!canUseDOM) return

  mixpanel.track(event, params)
}

Tracking.inc = (event, by = 1) => {
  if (!canUseDOM) return

  mixpanel.people.increment(event, by)
}

Tracking.setUser = user => {
  if (!canUseDOM) return

  if (user.is_ghost) {
    mixpanel.opt_out_tracking()
  } else {
    if (mixpanel.has_opted_out_tracking()) {
      mixpanel.opt_in_tracking()
    }
  }

  mixpanel.identify(user._id)
  mixpanel.people.set({
    Type: "User",
    $name: user.full_name,
    $email: user.email,
    "Total Dollars Spent": user.total_dollars_spent,
    ...(user.organization_id
      ? {
        "Organization ID": user.organization_id,
        "Organization Name": user.organization.name,
        "Organization Total Dollars Spent": user.organization_total_dollars_spent
      }
      : {})
  })
}

Tracking.reset = () => {
  mixpanel.reset()
}

export default Tracking
