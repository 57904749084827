import {
  UPDATE_PASSWORD_PENDING,
  UPDATE_PASSWORD_FULFILLED,
  UPDATE_PASSWORD_FAILED,
  UPDATE_OLD_PASSWORD,
  UPDATE_NEW_PASSWORD,
  UPDATE_NEW_PASSWORD_CONFIRM
} from "@actions/account/password"

const initialState = {
  status: "",
  errorMessage: "",
  oldPassword: "",
  newPassword: "",
  newPasswordConfirm: ""
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_PASSWORD_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case UPDATE_PASSWORD_FULFILLED:
      return {
        ...state,
        status: "SUCCESS",
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: ""
      }

    case UPDATE_PASSWORD_FAILED:
      return {
        ...state,
        errorMessage: action.payload.message,
        status: "FAILED"
      }

    case UPDATE_OLD_PASSWORD:
      return {
        ...state,
        oldPassword: action.payload.oldPassword
      }

    case UPDATE_NEW_PASSWORD:
      return {
        ...state,
        newPassword: action.payload.newPassword
      }

    case UPDATE_NEW_PASSWORD_CONFIRM:
      return {
        ...state,
        newPasswordConfirm: action.payload.newPasswordConfirm
      }

    default:
      return state
  }
}
