import {
  SET_SCREENER,
  UPDATE_SCREENER,
  GET_REVIEWERS_FAILED,
  GET_REVIEWERS_FULFILLED,
  GET_REVIEWERS_PENDING,
  UPDATE_REVIEWER,
  GET_SCREENER_ANALYTICS_PENDING,
  GET_SCREENER_ANALYTICS_FULFILLED,
  GET_SCREENER_VIEWS_FULFILLED,
  GET_SCREENER_PENDING,
  GET_SCREENER_FAILED,
  GET_SCREENER_FULFILLED,
  POLL_REVIEWERS_PENDING,
  POLL_REVIEWERS_FULFILLED,
  ACTIVATE_SCREENER_FULFILLED,
  UPDATE_SHARED_SCREENER_PASSWORD_PENDING,
  UPDATE_SHARED_SCREENER_PASSWORD_FULFILLED,
  UPDATE_SHARED_SCREENER_PASSWORD_FAILED
} from "@actions/project/screener"
import { CREATE_SHARED_SCREENERS } from "@actions/shared_screeners"

const initialState = {
  status: "PENDING",
  is_polling: false,
  active_tab: "reviewers",
  reviewers: {
    status: "PENDING",
    count: 0,
    list: []
  },
  analytics: {
    status: "PENDING"
  },
  passwordStatus: "READY"
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SCREENER:
      return {
        ...state,
        visible: true,
        ...action.screener,
        backTo: action.backTo
      }

    case GET_SCREENER_PENDING:
      return {
        ...initialState,
        status: "PENDING"
      }

    case GET_SCREENER_FULFILLED:
      return {
        ...state,
        ...action.payload,
        status: "READY"
      }

    case GET_SCREENER_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case UPDATE_SHARED_SCREENER_PASSWORD_PENDING:
      return {
        ...state,
        passwordStatus: "PENDING"
      }

    case UPDATE_SHARED_SCREENER_PASSWORD_FAILED:
      return {
        ...state,
        passwordStatus: "ERROR"
      }

    case UPDATE_SHARED_SCREENER_PASSWORD_FULFILLED:
      return {
        ...state,
        passwordStatus: "COMPLETE",
        reviewers: {
          status: "READY",
          list: action.payload.shared_screeners,
          count: action.payload.count
        }
      }

    case ACTIVATE_SCREENER_FULFILLED:
      return {
        ...state,
        ...action.payload,
        status: "READY"
      }

    case POLL_REVIEWERS_PENDING:
      return {
        ...state,
        is_polling: true
      }

    case POLL_REVIEWERS_FULFILLED:
      return {
        ...state,
        is_polling: false,
        ...action.payload
      }

    case UPDATE_SCREENER:
      return {
        ...state,
        [action.key]: action.value
      }

    case GET_REVIEWERS_PENDING:
      return {
        ...state,
        reviewers: {
          ...state.reviewers,
          status: "PENDING"
        }
      }

    case GET_REVIEWERS_FAILED:
      return {
        ...state,
        reviewers: {
          ...state.reviewers,
          status: "ERROR"
        }
      }

    case GET_REVIEWERS_FULFILLED:
      return {
        ...state,
        reviewers: {
          status: "READY",
          list: action.payload.shared_screeners,
          count: action.payload.count
        }
      }

    case UPDATE_REVIEWER:
      return {
        ...state,
        reviewers: {
          ...state.reviewers,
          list: state.reviewers.list.map(reviewer =>
            reviewer._id === action.reviewerID
              ? {
                ...reviewer,
                [action.key]: action.value
              }
              : reviewer
          )
        }
      }

    case GET_SCREENER_ANALYTICS_PENDING:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          status: state.analytics.status === "READY" ? "UPDATING" : "PENDING"
        }
      }

    case GET_SCREENER_ANALYTICS_FULFILLED:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          status: "READY",
          graph: action.payload
        }
      }

    case GET_SCREENER_VIEWS_FULFILLED:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          views: action.payload
        }
      }

    case CREATE_SHARED_SCREENERS.COMPLETE:
      return {
        ...state,
        reviewers: {
          ...state.reviewers,
          list: action.payload.shared_screeners
        }
      }

    default:
      return state
  }
}
