import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateCart, applyPromo, applyPromoByID } from "@api/account/cart"

class Promo extends React.Component {
  componentDidMount() {
    if (this.props.activePromoID) {
      this.props.applyPromoByID()
    }
  }
  render() {
    const {
      cart: { promo },
      updateCart,
      applyPromo
    } = this.props
    return (
      <div className='mt2 mb3'>
        <div className='flex items-end'>
          <div
            className={`pr2 flex-auto relative ${
              promo.status === "SUCCESS" ? "green" : promo.status === "ERROR" ? "red" : ""
            }`}>
            <label className='cs-label' htmlFor='promo'>
              Promo Code
            </label>
            <input
              value={promo.value}
              className={`cs-input col-12 ${
                promo.status === "SUCCESS" ? "success" : promo.status === "ERROR" ? "error" : ""
              }`}
              onChange={({ target: { value } }) => updateCart("promo", { ...promo, value })}/>
            {(promo.status === "ERROR" || promo.status === "SUCCESS") && (
              <span className='material-icons absolute right-0 bottom-0 mr3 mb1'>
                {promo.status === "ERROR" ? "warning" : "check_circle"}
              </span>
            )}
          </div>
          <button
            className={`cs-button ${promo.status === "PENDING" ? "pending" : ""} white`}
            disabled={promo.status === "PENDING"}
            style={{ width: "100px", minWidth: "100px" }}
            onClick={applyPromo}>
            Apply
          </button>
        </div>
      </div>
    )
  }
}

Promo.propTypes = {
  cart: PropTypes.object,
  updateCart: PropTypes.func.isRequired,
  applyPromo: PropTypes.func.isRequired,
  activePromoID: PropTypes.string,
  applyPromoByID: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  cart: state.user.cart,
  activePromoID: state.user.auth.active_promo_id
})

const mapDispatchToProps = dispatch => ({
  updateCart: bindActionCreators(updateCart, dispatch),
  applyPromo: bindActionCreators(applyPromo, dispatch),
  applyPromoByID: bindActionCreators(applyPromoByID, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Promo)
