import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Modal } from "@bitcine/cs-theme"
import { toggleAsperaModal } from "@api/modals"
import { GLOBALS } from "@src/config"
import PropTypes from "prop-types"

class AsperaModal extends React.Component {
  render() {
    return (
      <Modal
        open={true}
        title='Insufficient Aspera Entitlements'
        onClose={this.props.toggleAsperaModal}
        isDismissable={true}>
        <div>
          <p className='mb3'>
            Your account has run out of Aspera Entitlements. Please follow the link below to purchase more.
          </p>
          <div className='right-align'>
            <a href={GLOBALS.ACCOUNTS_URL + '/settings/aspera'} target='_blank' rel='noopener noreferrer'>
              <button className='cs-button white'>Purchase Aspera Entitlement</button>
            </a>
          </div>
        </div>
      </Modal>
    )
  }
}

AsperaModal.propTypes = {
  toggleAsperaModal: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
  toggleAsperaModal: bindActionCreators(toggleAsperaModal, dispatch)
})

export default connect(null, mapDispatchToProps)(AsperaModal)
