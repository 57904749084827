import {
  ADD_PROJECT_FULFILLED,
  GET_PROJECT_FAILED,
  GET_PROJECT_PENDING,
  GET_PROJECT_FULFILLED,
  SET_PROJECT,
  UPDATE_PROJECT_DETAILS,
  INIT_DEFROST_FULFILLED
} from "@actions/project"
import { SAVE_ACCESS_FULFILLED } from "@actions/project/access"

const initialState = {
  status: "PENDING"
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PROJECT:
      return {
        status: "READY",
        ...action.project
      }

    case ADD_PROJECT_FULFILLED:
      return {
        status: "READY",
        ...action.payload
      }

    case GET_PROJECT_PENDING:
      return {
        ...initialState,
        status: "PENDING"
      }

    case GET_PROJECT_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case GET_PROJECT_FULFILLED:
      return {
        status: "READY",
        ...action.payload
      }

    case UPDATE_PROJECT_DETAILS:
      return {
        ...state,
        [action.key]: action.value
      }

    case INIT_DEFROST_FULFILLED:
      return {
        ...state,
        started_defrosting_at: action.payload.started_defrosting_at
      }

    case SAVE_ACCESS_FULFILLED:
      return {
        status: "READY",
        ...action.payload
      }

    default:
      return state
  }
}
