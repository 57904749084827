import { GET_ACCESS_DEVICES_PENDING, GET_ACCESS_DEVICES_FULFILLED, REMOVE_ACCESS_DEVICE } from "@actions/account/devices"

const initialState = {
  list: [],
  status: ""
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ACCESS_DEVICES_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_ACCESS_DEVICES_FULFILLED:
      return {
        ...state,
        status: "SUCCESS",
        list: action.payload
      }

    case REMOVE_ACCESS_DEVICE:
      return {
        ...state,
        list: state.list.filter(device => device._id !== action.payload.device._id)
      }

    default:
      return state
  }
}
