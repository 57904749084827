export const GET_CAPTIONS_ITEMS = "GET_CAPTIONS_ITEMS"
export const GET_CAPTIONS_ITEMS_FULFILLED = "GET_CAPTIONS_ITEMS_FULFILLED"

export const GET_DCP_ITEMS = "GET_DCP_ITEMS"
export const GET_DCP_ITEMS_FULFILLED = "GET_DCP_ITEMS_FULFILLED"

export const GET_DISC_ITEMS = "GET_DISC_ITEMS"
export const GET_DISC_ITEMS_FULFILLED = "GET_DISC_ITEMS_FULFILLED"

export const GET_SCREENERS_TOP_UP_ITEMS = "GET_SCREENERS_TOP_UP_ITEMS"
export const GET_SCREENERS_TOP_UP_ITEMS_FULFILLED = "GET_SCREENERS_TOP_UP_ITEMS_FULFILLED"

export const GET_INSPECTION_ITEMS = "GET_INSPECTION_ITEMS"
export const GET_INSPECTION_ITEMS_FULFILLED = "GET_INSPECTION_ITEMS_FULFILLED"

export const GET_CONVERSION_ITEMS = "GET_CONVERSION_ITEMS"
export const GET_CONVERSION_ITEMS_FULFILLED = "GET_CONVERSION_ITEMS_FULFILLED"
