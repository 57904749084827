import { get, post } from "@api/fetch"
import {
  GET_FILE_REQUEST,
  UPDATE_FILE_REQUEST,
  SELECT_EXISTING_FILE_REQUEST,
  GET_FILE_REQUEST_SILENT
} from "@actions/file_request/"
import { addGlobalMessage } from "@api/utils"
import { buildFolderStructureOnServer, sortAndMerge } from "@api/project/folder_upload"
import { initializeHttpUpload } from "@api/transfer/http/upload"

const urls = {
  cinerequest: {
    get: cineRequestID => `cinerequests/fulfill/${cineRequestID}`,
    copy: cineRequestID => `cinerequests/fulfill/${cineRequestID}/copy`
  },
  "file-request": {
    get: fileRequestID => `fileRequests/${fileRequestID}`,
    copy: fileRequestID => `fileRequests/${fileRequestID}/copy`,
    httpFolder: projectID => `projects/${projectID}/build_folder_tree`
  }
}

/**
 * Gets a file request or cinerequest
 *
 * @param {String} _id
 * @param {String} path
 *
 * @returns {Function}
 */

export const getFileRequest = (_id, path) => dispatch => {
  const type = path.split("/")[1]
  dispatch(updateFileRequest("type", type))
  dispatch(get(GET_FILE_REQUEST, urls[type].get(_id)))
}

/**
 * Get the file Request data without updating the UI spinner.
 * @param _id
 * @param path
 * @returns {(function(*): void)|*}
 */
export const getFileRequestSilent = (_id, path) => dispatch => {
  const type = path.split("/")[1]
  dispatch(updateFileRequest("type", type))
  dispatch(get(GET_FILE_REQUEST_SILENT, urls[type].get(_id)))
}

/**
 * Updates file request state by key/value
 *
 * @param {String} key
 * @param {Any} value
 *
 * @returns {Function}
 */

export const updateFileRequest = (key, value) => dispatch => dispatch({ type: UPDATE_FILE_REQUEST, key, value })

/**
 * Selects an existing cinesend file as the cineupload
 *
 * @param {String} fileID the file to copy
 * @param {String} fileType
 *
 * @returns {Function}
 */

export const selectFiles = (files, project) => (dispatch, getState) => {
  let array = []
  Object.keys(files).forEach(key => {
    array.push({
      _id: key,
      type: files[key]
    })
  })

  dispatch(
    post(
      SELECT_EXISTING_FILE_REQUEST,
      urls[getState().file_request.type].copy(getState().file_request._id),
      { _files: array },
      res => {
        if (res.is_queued) {
          dispatch(
            addGlobalMessage("The selected files are being copied over. This can take a few minutes.", "success")
          )
        } else {
          dispatch(addGlobalMessage("File(s) Selected!", "success"))
        }
      }
    )
  )
}

export const initializeHttpFolderUpload = (request, folder) => (dispatch, getState) => {
  const buildFolderTree = buildFolderStructureOnServer(sortAndMerge(folder), request.project_id, request.destination_folder_id)
  buildFolderTree.then(res => {
    for (let i = 0; i < res.files.length; i++) {
      const specs = {
        folder_id: res.files[i].folder_id,
        project_id: request.project_id,
        file_request_id: request._id
      }
      dispatch(initializeHttpUpload(folder[res.files[i].file_index], specs))
    }
  })
}