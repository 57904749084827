import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toggleModal } from "@api/shared_screeners/modals"
import Heatmap from "./heatmap"

const Watched = ({ sharedScreener, isFirstReviewer, toggleModal, parentType }) => (
  <div>
    {sharedScreener.type === "screener_group" && parentType !== "screener" ? (
      <span
        onClick={() => toggleModal("screenerRoomViewers", sharedScreener)}
        className='pointer muted caption flex items-center border rounded p1 border-gray-3'
        style={{ height: "26px" }}>
        <span className='material-icons pr1 small' style={{ fontSize: "16px" }}>
          history
        </span>
        Viewer History
      </span>
    ) : (
      <div>
        <Heatmap data={sharedScreener.stats ? sharedScreener.stats : {}} isFirstReviewer={isFirstReviewer}/>
        <span className='block light caption'>
          Times watched: {sharedScreener.stats ? sharedScreener.stats.times_watched : 0}
        </span>
      </div>
    )}
  </div>
)

Watched.propTypes = {
  sharedScreener: PropTypes.object.isRequired,
  isFirstReviewer: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  parentType: PropTypes.string.isRequired
}

const mapStateToProps = ({ shared_screeners }) => ({
  parentType: shared_screeners.query.parentType
})

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Watched)
