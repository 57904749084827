export const SHOW_CREATE_PROJECT_FOLDER_MODAL = "SHOW_CREATE_PROJECT_FOLDER_MODAL"
export const HIDE_CREATE_PROJECT_FOLDER_MODAL = "HIDE_CREATE_PROJECT_FOLDER_MODAL"
export const CREATE_PROJECT_FOLDER = "CREATE_PROJECT_FOLDER"
export const CREATE_PROJECT_FOLDER_PENDING = "CREATE_PROJECT_FOLDER_PENDING"
export const CREATE_PROJECT_FOLDER_FULFILLED = "CREATE_PROJECT_FOLDER_FULFILLED"
export const MOVE_PROJECTS_TO_FOLDER = "MOVE_PROJECTS_TO_FOLDER"
export const MOVE_PROJECTS_TO_FOLDER_PENDING = "MOVE_PROJECTS_TO_FOLDER_PENDING"
export const MOVE_PROJECTS_TO_FOLDER_FULFILLED = "MOVE_PROJECTS_TO_FOLDER_FULFILLED"
export const REMOVE_PROJECTS_FROM_VIEW = "REMOVE_PROJECTS_FROM_VIEW"
export const FOLDER_HOVERED = "FOLDER_HOVERED"
export const UPDATE_PROJECT_FOLDER_TITLE = "UPDATE_PROJECT_FOLDER_TITLE"
export const GET_PROJECT_FOLDER_TREE = "GET_PROJECT_FOLDER_TREE"
export const GET_PROJECT_FOLDER_TREE_PENDING = "GET_PROJECT_FOLDER_TREE_PENDING"
export const GET_PROJECT_FOLDER_TREE_FULFILLED = "GET_PROJECT_FOLDER_TREE_FULFILLED"
export const SHOW_DELETE_FOLDER = "SHOW_DELETE_FOLDER"
export const HIDE_DELETE_FOLDER = "HIDE_DELETE_FOLDER"
export const REMOVE_FOLDER_FROM_VIEW = "REMOVE_FOLDER_FROM_VIEW"
export const DELETE_PROJECT_FOLDER = "DELETE_PROJECT_FOLDER"
export const DELETE_PROJECT_FOLDER_PENDING = "DELETE_PROJECT_FOLDER_PENDING"
export const DELETE_PROJECT_FOLDER_FULFILLED = "DELETE_PROJECT_FOLDER_FULFILLED"
