import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import uniqBy from "lodash.uniqby"
import PropTypes from "prop-types"
import MemberIcon from "@components/member/MemberIcon"
import { TOGGLE_ACCESS_MODAL } from "@actions/project/access"
import { can, MODIFY_USERS_AND_ROLES, VIEW_PROJECTS } from "@src/helpers/authorization"
import TourTip from "@components/tour/tip"
import { getTeamMembers } from "@api/settings/organizations/members"
import "./styles.scss"

class Members extends Component {
  componentDidMount() {
    this.props.getTeamMembers()
  }
  render() {
    const { projectUsers, roleUsers, visible, canEditAccess } = this.props
    if (!visible) return null

    const allMembers = uniqBy([...projectUsers, ...roleUsers], user => user._id)
    return (
      <TourTip tipId='project_members'>
        <div className='flex items-center pl3 py1'>
          <small className='mr2 bold'>Project members</small>
          <div className='flex items-center row-reverse'>
            {canEditAccess && (
              <button
                className={`user addBtn`}
                onClick={() => this.props.toggleAccessModal(this.props.project)}>
                <span>
                  <i className='material-icons'>add</i>
                </span>
              </button>
            )}
            {allMembers.length > 4 && (
              <button
                className={`user moreUsers tooltip`}
                data-tooltip='View members'
                onClick={() => this.props.toggleAccessModal(this.props.project)}>
                <span>+{allMembers.length - 4}</span>
              </button>
            )}
            {allMembers.slice(0, 4).map((user, i) => (
              <MemberIcon key={i} user={user} zindex={allMembers.length + 4 - i}/>
            ))}
          </div>
        </div>
      </TourTip>
    )
  }
}

Members.propTypes = {
  project: PropTypes.object.isRequired,
  toggleAccessModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  projectUsers: PropTypes.array,
  roleUsers: PropTypes.array,
  canEditAccess: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  projectUsers: state.project.details.status !== "PENDING" ? state.project.details.users : [],
  roleUsers: state.user.auth.organization_id
    ? state.settings.organizations.members.items.filter(user => can(user, [VIEW_PROJECTS]))
    : [],
  project: state.project.details.status !== "PENDING" ? state.project.details : {},
  visible: state.user.auth.organization_id !== null,
  canEditAccess: can(state.user.auth, [MODIFY_USERS_AND_ROLES])
})

const mapDispatchToProps = (dispatch, state) => ({
  toggleAccessModal: project => dispatch({ type: TOGGLE_ACCESS_MODAL, payload: { project } }),
  getTeamMembers: bindActionCreators(getTeamMembers, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Members)
