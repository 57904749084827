import React from "react"
import PropTypes from "prop-types"
import Logo from "@src/public/logo.inline.svg"
import { Checkbox } from "@bitcine/cs-theme"

const GiftCardLine = ({ card, checked, onSelect, postPay }) => (
  <label className='cs-label flex items-center p1 rounded my1 border-gray-5 border'>
    <Checkbox disabled={postPay} checked={checked} onChange={onSelect}/>
    <div className='inline-flex pl3'>
      Gift Card (
      <strong>
        {card.currency_symbol}
        {card.current_amount.toFixed(2)}
      </strong>
      )
    </div>
    <Logo style={{ width: "80px", float: "right" }} alt='CineSend'/>
  </label>
)

GiftCardLine.propTypes = {
  card: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  checked: PropTypes.bool.isRequired,
  postPay: PropTypes.bool.isRequired
}

export default GiftCardLine
