import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateCart, getShippingRate } from "@api/account/cart"
import CanadaPost from "./canada_post"

class Address extends React.Component {
  onClick = e => {
    e.preventDefault()
    e.stopPropagation()
    this.props.getShippingRate()
  }
  render() {
    const {
      cart: { newAddress },
      updateCart
    } = this.props
    return (
      <form>
        <label className='cs-label mt2' htmlFor='businessName'>
          Name of Business
          <sup className='red'>*</sup>
        </label>
        <input
          value={newAddress.organization || ""}
          className='cs-input col-12'
          id='businessName'
          autoComplete='off'
          onChange={({ target: { value } }) => updateCart("newAddress", { ...newAddress, organization: value })}/>
        <CanadaPost
          updateAddress={address => {
            updateCart("newAddress", { ...newAddress, ...address })
          }}/>
        <label className='cs-label mt2' htmlFor='phoneNumber'>
          Phone Number
          <sup className='red'>*</sup>
        </label>
        <input
          value={newAddress.mobile_number || ""}
          className='cs-input col-8'
          autoComplete='off'
          id='phoneNumber'
          onChange={({ target: { value } }) => updateCart("newAddress", { ...newAddress, mobile_number: value })}/>
        {(newAddress.error || newAddress.status === "ERROR") && (
          <div className='red my2'>An error occured. Please try again.</div>
        )}
        <div className='flex justify-end mt3'>
          <button
            onClick={this.onClick}
            type='submit'
            className={`cs-button ${newAddress.status === "PENDING" ? "pending" : ""}`}
            disabled={
              !newAddress.ready ||
              !newAddress.organization ||
              !newAddress.mobile_number ||
              newAddress.status === "PENDING"
            }>
            Calculate Price
          </button>
        </div>
      </form>
    )
  }
}

Address.propTypes = {
  cart: PropTypes.object.isRequired,
  getShippingRate: PropTypes.func.isRequired,
  updateCart: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  cart: state.user.cart
})

const mapDispatchToProps = dispatch => ({
  updateCart: bindActionCreators(updateCart, dispatch),
  getShippingRate: bindActionCreators(getShippingRate, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Address)
