import { initializeAsperaDownload } from "@api/transfer/aspera/download"
import { initializeHTTPDownload } from "@api/transfer/http/download"
import { toggleFolderContentVisibility } from "@api/project/files/modals"
import { TOGGLE_ASPERA_UPGRADE_MESSAGE } from "@actions/utils/"

/**
 * Download Files
 * - download file
 *
 * @param {file} - object - the file to download
 *
 * @returns dispatch
 */

export const downloadFileWithHTTP = (file, downloadID = null) => (dispatch, getState) => {
  if (file.type === "dcp") {
    dispatch(toggleFolderContentVisibility(file))
  }
  else {
    dispatch(initializeHTTPDownload(file, downloadID))
  }
}

export const downloadFileWithAspera = file => (dispatch, getState) => {
  const canUseAspera = getState().user.auth.can_use_udp_downloads
  if (canUseAspera) {
    dispatch(initializeAsperaDownload(file))
  }
  else {
    dispatch({ type: TOGGLE_ASPERA_UPGRADE_MESSAGE })
  }
}
