import { TOGGLE_ORDER_VISIBILITY, UPDATE_ORDER, ADD_ORDER_TO_CART, GET_DRIVES } from "@actions/orders"

import { getFrameRates, getResolutions, getLanguages, getVideoFormats } from "@api/utils/"

import { triggerTourStep } from "@api/tour/"

import {
  GET_CAPTIONS_ITEMS,
  GET_DCP_ITEMS,
  GET_DISC_ITEMS,
  GET_SCREENERS_TOP_UP_ITEMS,
  GET_INSPECTION_ITEMS,
  GET_CONVERSION_ITEMS
} from "@actions/utils/items"

import { get, post } from "@api/fetch"

import Tracking from "@helpers/tracking"

export const toggleOrderVisibility = orderType => dispatch => dispatch({ type: TOGGLE_ORDER_VISIBILITY, orderType })

export const updateOrder = (key, value) => dispatch => dispatch({ type: UPDATE_ORDER, key, value })

export const updateOrderFile = (key, value) => (dispatch, getState) => {
  let order = getState().orders.active_order
  if (order.video_file) {
    let videoFile = order.video_file
    videoFile[key] = value
    dispatch({ type: UPDATE_ORDER, key: "video_file", value: videoFile })
  } else if (order.source_file) {
    let sourceFile = order.source_file
    sourceFile[key] = value
    dispatch({ type: UPDATE_ORDER, key: "source_file", value: sourceFile })
  }
}

export const getDrives = (fileID, fileType) => dispatch => {
  if (fileType === "disc_output") {
    dispatch(get(GET_DISC_ITEMS, "items/disc"))
  } else {
    dispatch(get(GET_DRIVES, `billing/asset-prices?fileID=${fileID}&fileType=${fileType}`))
  }
}

export const nextStep = () => (dispatch, getState) => {
  const order = getState().orders.active_order
  const type = order.type
  const step = order.step

  // If we're on the file-picker stage, we go to file-info if the file doesn't have enough data:
  if (step === "file-picker") {
    if (
      order.video_file &&
      (!order.video_file.length_in_ms || !order.video_file.resolution || !order.video_file.frame_rate)
    ) {
      dispatch(goToFileInfo())
      return
    } else if (
      order.source_file &&
      (!order.source_file.length_in_ms || !order.source_file.resolution || !order.source_file.frame_rate)
    ) {
      dispatch(goToFileInfo())
      return
    }
  }

  switch (type) {
    case "closed-captions":
      switch (step) {
        case "file-picker":
          dispatch({
            type: UPDATE_ORDER,
            key: "step",
            value: "turnaround-time"
          })
          dispatch(get(GET_CAPTIONS_ITEMS, "items/captions"))
          break
        case "file-info":
          dispatch({
            type: UPDATE_ORDER,
            key: "step",
            value: "turnaround-time"
          })
          dispatch(get(GET_CAPTIONS_ITEMS, "items/captions"))
          break
        case "turnaround-time":
          const payload = {
            type: "closed-captions",
            specifications: {
              code: order.turnaround_time
            }
          }
          dispatch(addToCart(payload))
          break
        default:
          return null
      }
      break
    case "dcp":
      const resolution = order.source_file.resolution
      const frameRate = order.source_file.frame_rate
      switch (step) {
        case "file-picker":
        case "file-info":
          dispatch({
            type: UPDATE_ORDER,
            key: "step",
            value: "dcp-options"
          })
          dispatch(get(GET_DCP_ITEMS, "items/dcp"))
          break
        case "dcp-options":
          if (order.use_custom_title && order.dcp_title === "") {
            dispatch({
              type: UPDATE_ORDER,
              key: "inputError",
              value: "dcp_title"
            })
          } else if (order.subtitle_track && !order.subtitle_handling) {
            dispatch({
              type: UPDATE_ORDER,
              key: "inputError",
              value: "subtitles"
            })
          } else if (resolution === "1920x1080" && order.crop === undefined) {
            dispatch({
              type: UPDATE_ORDER,
              key: "inputError",
              value: "resolution"
            })
          } else if ((frameRate === "25" || frameRate === "48") && order.convert === undefined) {
            dispatch({
              type: UPDATE_ORDER,
              key: "inputError",
              value: "frame_rate"
            })
          } else {
            dispatch({ type: UPDATE_ORDER, key: "inputError", value: null })
            dispatch({ type: UPDATE_ORDER, key: "step", value: "dcp-addons" })
          }
          break
        case "dcp-addons":
          const params = `fileID=${order.source_file._id}&fileType=source_file`
          dispatch(get(GET_DRIVES, `billing/asset-prices?${params}`))
          dispatch({
            type: UPDATE_ORDER,
            key: "step",
            value: "dcp-turnaround"
          })
          break
        case "dcp-turnaround":
          dispatch({
            type: UPDATE_ORDER,
            key: "step",
            value: "dcp-confirmation"
          })
          break
        case "dcp-confirmation":
          const payload = {
            type: "digital-cinema-package",
            specifications: {
              title: order.dcp_title,
              base_code: order.dcp_base,
              per_minute_code: order.dcp_per_minute,
              rush_days: order.rush_days,
              subtitle_handling: order.subtitle_track ? order.subtitle_handling : "",
              encrypted: order.encrypt,
              qa_report: order.qa_report,
              add_captioning: order.add_captioning,
              cropping: order.crop,
              convert: order.convert,
              drive_quantity: order.drive_type ? order.drive_quantity : 0,
              drive_code: order.drive_type
                ? order.drive_type === "rent"
                  ? order.drive.rent_asset.code
                  : order.drive.buy_asset.code
                : ""
            }
          }
          dispatch(addToCart(payload))
          break
        default:
          return null
      }
      break
    case "disc":
      switch (step) {
        case "file-picker":
        case "file-info":
          dispatch(get(GET_DISC_ITEMS, "items/disc"))
          dispatch({ type: UPDATE_ORDER, key: "step", value: "disc-options" })
          break
        case "disc-options":
          dispatch({ type: UPDATE_ORDER, key: "step", value: "disc-quantity" })
          break
        case "disc-quantity":
          const payload = {
            type: "order-discs",
            specifications: {
              include_encode: true,
              dvd_quantity: order.dvd_quantity,
              bluray_quantity: order.blu_ray_quantity,
              title: order.disc_title,
              type: order.disc_type
            }
          }
          dispatch(addToCart(payload))
          break
        default:
          return null
      }
      break
    case "send-physical-copy":
      let payload = {
        type: "send-drives",
        specifications: {
          code: order.drive_type === "rent" ? order.drive.rent_asset.code : order.drive.buy_asset.code,
          quantity: order.drive_quantity
        }
      }
      dispatch(addToCart(payload))
      break
    case "send-discs":
      payload = {
        type: "order-discs",
        specifications: {
          include_encode: false,
          quantity: order.disc_quantity
        }
      }
      dispatch(addToCart(payload))
      break
    case "inspection":
      switch (step) {
        case "file-picker":
        case "file-info":
          dispatch({
            type: UPDATE_ORDER,
            key: "step",
            value: "select-inspection-type"
          })
          dispatch(get(GET_INSPECTION_ITEMS, "items/inspection"))
          break
        case "select-inspection-type":
          const payload = {
            type: "quality-inspection",
            specifications: {
              inspection_type: order.inspection_type
            }
          }
          dispatch(addToCart(payload))
          break
        default:
          return null
      }
      break
    case "conversion":
      switch (step) {
        case "file-picker":
        case "file-info":
          dispatch({
            type: UPDATE_ORDER,
            key: "step",
            value: "select-conversion-format"
          })
          dispatch(getVideoFormats())
          dispatch(getFrameRates())
          dispatch(getResolutions())
          dispatch(get(GET_CONVERSION_ITEMS, "items/conversion"))
          break
        case "select-conversion-format":
          dispatch({
            type: UPDATE_ORDER,
            key: "step",
            value: "confirm-conversion-details"
          })
          break
        case "confirm-conversion-details":
          payload = {
            type: "format-conversion",
            specifications: {
              video_type: order.output_video_type,
              resolution: order.resolution,
              scale_value: order.scale_value,
              frame_rate: order.frame_rate,
              bitrate: order.bitrate
            }
          }
          dispatch(addToCart(payload))
          break
        default:
          return null
      }
      break
    default:
      return null
  }
}

export const prevStep = () => (dispatch, getState) => {
  const order = getState().orders.active_order
  const type = order.type
  const step = order.step

  switch (type) {
    case "closed-captions":
      switch (step) {
        case "file-info":
          dispatch({ type: UPDATE_ORDER, key: "step", value: "file-picker" })
          break
        case "turnaround-time":
          dispatch({ type: UPDATE_ORDER, key: "step", value: "file-picker" })
          break
        default:
          return null
      }
      break
    case "dcp":
      switch (step) {
        case "file-info":
          dispatch({ type: UPDATE_ORDER, key: "step", value: "file-picker" })
          break
        case "dcp-options":
          dispatch({ type: UPDATE_ORDER, key: "step", value: "file-picker" })
          break
        case "dcp-addons":
          dispatch({ type: UPDATE_ORDER, key: "step", value: "dcp-options" })
          break
        case "dcp-turnaround":
          dispatch({ type: UPDATE_ORDER, key: "step", value: "dcp-addons" })
          break
        case "dcp-confirmation":
          dispatch({ type: UPDATE_ORDER, key: "step", value: "dcp-turnaround" })
          break
      }
      break
    case "disc":
      switch (step) {
        case "file-info":
          dispatch({ type: UPDATE_ORDER, key: "step", value: "file-picker" })
          break
        case "disc-options":
          dispatch({ type: UPDATE_ORDER, key: "step", value: "file-picker" })
          break
        case "disc-quantity":
          dispatch({ type: UPDATE_ORDER, key: "step", value: "disc-options" })
          break
      }
      break
    case "inspection":
      switch (step) {
        case "file-info":
          dispatch({ type: UPDATE_ORDER, key: "step", value: "file-picker" })
          break
        case "select-inspection-type":
          dispatch({ type: UPDATE_ORDER, key: "step", value: "file-picker" })
          break
      }
      break
    case "conversion":
      switch (step) {
        case "file-info":
          dispatch({ type: UPDATE_ORDER, key: "step", value: "file-picker" })
          break
        case "select-conversion-format":
          dispatch({ type: UPDATE_ORDER, key: "step", value: "file-picker" })
          break
        case "confirm-conversion-details":
          dispatch({ type: UPDATE_ORDER, key: "step", value: "select-conversion-format" })
      }
      break
    default:
      return null
  }
}

const addToCart = payload => (dispatch, getState) => {
  const projectID = getState().project.details._id
  const order = getState().orders.active_order
  dispatch({ type: UPDATE_ORDER, key: "step", value: "adding-to-cart" })
  dispatch(
    post(
      ADD_ORDER_TO_CART,
      `billing/${projectID}/orders`,
      {
        ...payload,
        _files: getSelectedFiles(order)
      },
      () => {
        dispatch({ type: UPDATE_ORDER, key: "step", value: "complete" })
        dispatch(triggerTourStep("cart"))
        Tracking.record("Create order", {
          "Order type": payload.type,
          Payload: payload
        })
      }
    )
  )
}

export const getScreenerTopUpItems = () => dispatch => {
  dispatch(get(GET_SCREENERS_TOP_UP_ITEMS, "items/getItemsByCodes?codes=screeners_top_up"))
}

const goToFileInfo = () => dispatch => {
  dispatch(getFrameRates())
  dispatch(getResolutions())
  dispatch(getLanguages())
  dispatch({ type: UPDATE_ORDER, key: "step", value: "file-info" })
}

const getSelectedFiles = order => {
  let selectedFiles = []
  if (order.video_file) selectedFiles.push(order.video_file)
  if (order.source_file) selectedFiles.push(order.source_file)
  if (order.subtitle_track) selectedFiles.push(order.subtitle_track)
  if (order.closed_captions) selectedFiles.push(order.closed_captions)
  if (order.audio_tracks) selectedFiles = selectedFiles.concat(order.audio_tracks)
  if (order.disc_output) selectedFiles.push(order.disc_output)
  return selectedFiles.map(f => ({ _id: f._id, type: f.type }))
}
