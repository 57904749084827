import {
  GET_CREDIT_CATEGORIES,
  REORDER_CREDIT_CATEGORIES,
  ADD_CREDIT_CATEGORY,
  REORDER_CREDIT_ROLES,
  UPDATE_CREDIT_CATEGORY_NAME,
  TOGGLE_MENU,
  TOGGLE_ROLES,
  REMOVE_CREDIT_CATEGORY,
  SAVE_CREDIT_CATEGORY_NAME,
  SAVE_CREDIT_CATEGORY,
  ADD_CREDIT_ROLE,
  UPDATE_CREDIT_ROLE_NAME,
  REMOVE_CREDIT_ROLE,
  SAVE_CREDIT_ROLE,
  SAVE_CREDIT_ROLE_NAME,
  UPDATE_CREDIT_ROLE,
  GET_CREDIT_ROLES
} from "@actions/credits/"

import { POST_DEFAULTS } from "@constants"
import { get, post } from "@api/fetch"

export const getCreditCategories = projectId => dispatch =>
  dispatch(get(GET_CREDIT_CATEGORIES, `projects/${projectId}/credits`))
export const addCreditCategory = projectId => dispatch =>
  dispatch(post(ADD_CREDIT_CATEGORY, `projects/${projectId}/credits`, {}))
export const toggleMenu = index => dispatch => dispatch({ type: TOGGLE_MENU, payload: { index } })
export const toggleRoles = index => dispatch => dispatch({ type: TOGGLE_ROLES, payload: { index } })
export const updateCreditRoleName = (categoryIndex, index, name) => dispatch =>
  dispatch({
    type: UPDATE_CREDIT_ROLE_NAME,
    payload: { categoryIndex, index, name }
  })
export const updateCreditCategoryName = (index, name) => dispatch =>
  dispatch({ type: UPDATE_CREDIT_CATEGORY_NAME, payload: { index, name } })

export const addCreditRole = (index, category) => dispatch =>
  dispatch(post(ADD_CREDIT_ROLE, `projects/${category.project_id}/credits/${category._id}`, {}))

export const reorderCreditCategories = (dragIndex, hoverIndex) => dispatch =>
  dispatch({
    type: REORDER_CREDIT_CATEGORIES,
    payload: { dragIndex, hoverIndex }
  })

export const reorderCreditRoles = (categoryIndex, dragIndex, hoverIndex) => dispatch =>
  dispatch({
    type: REORDER_CREDIT_ROLES,
    payload: { categoryIndex, dragIndex, hoverIndex }
  })

export const getCreditRoles = projectId => (dispatch, getState) =>
  dispatch(get(GET_CREDIT_ROLES, `projects/${projectId}/credit_roles`))

export const saveCreditCategoryOrder = (targetIndex, originalIndex, projectId, categoryId) => dispatch => {
  dispatch(post(SAVE_CREDIT_CATEGORY, `projects/${projectId}/credits/reorder`, {
    targetIndex,
    originalIndex
  }))
}

export const saveCreditRoleOrder = (targetIndex, originalIndex, projectId, categoryId) => dispatch => {
  dispatch(post(SAVE_CREDIT_ROLE, `projects/${projectId}/credits/${categoryId}/roles/reorder`, {
    targetIndex,
    originalIndex
  }))
}

export const saveCreditCategoryName = (category, name) => dispatch => {
  dispatch(post(SAVE_CREDIT_CATEGORY_NAME, `projects/${category.project_id}/credits/${category._id}/rename`, { name }))
}

export const saveCreditRoleName = (category, role, name) => dispatch => {
  dispatch(post(SAVE_CREDIT_ROLE_NAME, `projects/${category.project_id}/credits/${category._id}/roles/${role._id}/rename`, { name }))
}

export function archiveCreditCategory(index, category) {
  asyncPost(`projects/${category.project_id}/credits/${category._id}/archive`, {})
  return dispatch => dispatch({ type: REMOVE_CREDIT_CATEGORY, payload: { index, category } })
}

export const deleteCreditCategory = (index, category) => dispatch => {
  dispatch(
    post(
      REMOVE_CREDIT_CATEGORY,
      `projects/${category.project_id}/credits/${category._id}/destroy`,
      {}
    )
  )
}

export const deleteCreditRole = (categoryIndex, index, category, role) => dispatch => {
  dispatch(
    post(
      REMOVE_CREDIT_ROLE,
      `projects/${category.project_id}/credits/${category._id}/roles/${role._id}/destroy`,
      {
        categoryIndex,
        index
      }
    )
  )
}

export const updateCreditRole = (categoryIndex, index, category, role, value) => dispatch => {
  dispatch(
    post(
      UPDATE_CREDIT_ROLE,
      `projects/${category.project_id}/credits/${category._id}/roles/${role._id}/update_role`,
      {
        value,
        categoryIndex,
        index
      }
    )
  )
}

export function asyncPost(url, data) {
  post(url, data)
}
