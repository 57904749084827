import React from "react"
import Logo from "@src/public/logo.inline.svg"
import styled from "react-emotion"

const FooterDiv = styled("div")`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 140px;
`

const Footer = () => (
  <FooterDiv className='pb2 center'>
    <Logo style={{ width: "120px" }}/>
    <p>
      CineSend is the preferred delivery method for thousands of distributors, producers, and filmmakers all over the
      world.
    </p>
    <a href='https://cinesend.com' className='flex justify-center' target='_blank' rel='noopener noreferrer'>
      <button className='cs-button white small'>Learn More</button>
    </a>
    <small>
      Having trouble? Check our{" "}
      <a href='https://support.cinesend.com/' target='_blank' className='underline' rel='noopener noreferrer'>
        support page
      </a>{" "}
      for help.
    </small>
  </FooterDiv>
)

export default Footer
