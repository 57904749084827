import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Modal, Status } from "@bitcine/cs-theme"
import { bindActionCreators } from "redux"
import { addNewFolder } from "@api/project/folders/"
import { createRequest, toggleModal } from "@api/project/requests"
import SelectFolder from "./select"

class CreateRequest extends Component {
  constructor() {
    super()
    this.state = {
      show_folders: false,
      saving_folder: false,
      title: "",
      selected_folder_id: "",
      selected_folder_name: "All Files"
    }
  }
  createFolder(folderID, name) {
    this.props.addNewFolder(folderID, name, folder => {
      this.selectFolder(folder)
    })
  }
  selectFolder(folder) {
    this.setState({
      selected_folder_id: folder ? folder._id : "",
      selected_folder_name: folder ? folder.name : "All Files",
      show_folders: false
    })
  }
  createRequest = () => {
    this.props.createRequest(this.state.title, this.state.selected_folder_id)
  }
  close = () => {
    this.props.toggleModal("show_create")
  }
  render() {
    const { status } = this.props
    return !this.state.show_folders ? (
      <Modal open title='Create File Request' onClose={this.close}>
        <Status pending={status === "PENDING"} error={status === "ERROR"}>
          <div>
            <div className='border-bottom border-gray-5 pb3'>
              <h4 className='mb1'>What are you requesting?</h4>
              <label className='cs-label'>Name of request</label>
              <input
                autoFocus
                onChange={({ target: { value } }) => this.setState({ title: value })}
                value={this.state.title}
                type='text'
                placeholder='File name, image name...'
                className='cs-input col-12'/>
            </div>
            <div className='py3 '>
              <h4 className='mb1'>Where is this file going?</h4>
              <label className='cs-label'>Destination folder</label>
              <div className='col-12 flex'>
                <div
                  className='flex-auto flex items-center rounded border border-gray-5 pl1'
                  style={{ height: "34px" }}>
                  <i className='material-icons'>folder</i>
                  <strong className='ml1'>{this.state.selected_folder_name}</strong>
                </div>
                <button
                  className='cs-button outline capitalize small ml1'
                  onClick={() => this.setState({ show_folders: true })}>
                  Change folder
                </button>
              </div>
            </div>
            <div className='col-12 right-align'>
              <button className='cs-button ml2' onClick={this.createRequest} disabled={!this.state.title.length}>
                Save
              </button>
            </div>
          </div>
        </Status>
      </Modal>
    ) : (
      <Modal open title='Choose a Folder for Submissions' onClose={() => this.setState({ show_folders: false })}>
        <SelectFolder
          select={folder => this.selectFolder(folder)}
          create={(folderID, name) => this.createFolder(folderID, name)}/>
      </Modal>
    )
  }
}

CreateRequest.propTypes = {
  folders: PropTypes.array.isRequired,
  addNewFolder: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  createRequest: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  folders: state.project.files.folders.folders.list,
  status: state.project.requests.creation_status
})

const mapDispatchToProps = dispatch => ({
  addNewFolder: bindActionCreators(addNewFolder, dispatch),
  createRequest: bindActionCreators(createRequest, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRequest)
