import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Dropzone from "react-dropzone"
import { Status } from "@bitcine/cs-theme"
import { updateVideo } from "@api/ondemand_videos/"
import { initializeHttpUpload } from "@api/transfer/http/upload"
import Card from "./card"

class Artwork extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      thumbnail: {
        status: "Pending",
        percentage: 0
      },
      poster_image: {
        status: "Pending",
        percentage: 0
      },
      submitting: false
    }
  }
  setUploadingStatus(type, isUploading) {
    this.setState({
      [type]: {
        ...this.state[type],
        status: isUploading ? "Uploading" : "Complete"
      },
      submitting: isUploading
    })
  }
  onDropThumbnail(file, type) {
    this.setUploadingStatus(type, true)
    this.props.initializeHttpUpload(
      file,
      {
        video_id: this.props.video._id
      },
      result => {
        this.props.updateVideo(this.props.video._id, {
          [type + "_url"]: result.url,
          [type + "_file_name"]: result.name
        })
        this.setUploadingStatus(type, false)
      }
    )
  }
  render() {
    const { video, settings, updateVideo } = this.props
    return (
      <Card
        title='Artwork'
        submitted={video.is_artwork_submitted}
        isUpload={true}
        className='mr2'
        submitButtonDisabled={!video.thumbnail_url && !video.poster_image_url}
        isSubmitting={this.state.submitting}
        onSubmit={() => {
          this.setState({ submitting: true })
          updateVideo(video._id, { is_artwork_submitted: true }, () => this.setState({ submitting: false }))
        }}>
        <>
          {settings.can_upload_thumbnail && (
            <>
              {video.is_artwork_submitted && video.thumbnail_url ? (
                <div className='flex items-center'>
                  <i className='material-icons green mr1'>check_circle</i>
                  <div>
                    The following content has been supplied as a thumbnail image:
                    <div className='bold'>{video.thumbnail_file_name}</div>
                  </div>
                </div>
              ) : !video.is_artwork_submitted ? (
                <Status pending={this.state.thumbnail.status === "Uploading"}>
                  <p>
                    Please provide a high-quality <b>thumbnail image</b> containing no text. We recommend a JPEG that is
                    at least 1920 x 1080 pixels in size.
                  </p>
                  <Dropzone
                    multiple={false}
                    disabled={this.state.thumbnail.status === "Uploading" || video.status === "Approved"}
                    accept='image/*'
                    onDrop={(accepted, rejected) => this.onDropThumbnail(accepted[0], "thumbnail", "thumbnail_url")}
                    className='dropzone'>
                    <div className='flex justify-between'>
                      <button
                        disabled={this.state.thumbnail.status === "Uploading"}
                        className={`cs-button outline small nowrap ${
                          this.state.thumbnail.status === "Uploading" ? "pending" : ""
                        }`}>
                        Upload File From Computer
                      </button>
                      {video.thumbnail_url && (
                        <div>
                          <div className='flex justify-end'>
                            <img src={video.thumbnail_url} height='120px'/>
                          </div>
                          <p className='small flex justify-end bold mt1'>{video.thumbnail_file_name}</p>
                        </div>
                      )}
                    </div>
                  </Dropzone>
                </Status>
              ) : null}
            </>
          )}
          {settings.can_upload_poster && (
            <>
              {settings.can_upload_thumbnail && <div className='mt2 pt1 border-top border-gray-5'/>}
              {video.is_artwork_submitted && video.poster_image_url ? (
                <div className='flex items-center'>
                  <i className='material-icons green mr1'>check_circle</i>
                  <div>
                    The following content has been supplied as a poster image:
                    <div className='bold'>{video.poster_image_file_name}</div>
                  </div>
                </div>
              ) : !video.is_artwork_submitted ? (
                <Status pending={this.state.poster_image.status === "Uploading"}>
                  <p>
                    Please provide a high-quality <b>poster image</b> that is at least 600 x 900 pixels in size.
                  </p>
                  <Dropzone
                    multiple={false}
                    disabled={this.state.poster_image.status === "Uploading" || video.status === "Approved"}
                    accept='image/*'
                    onDrop={(accepted, rejected) =>
                      this.onDropThumbnail(accepted[0], "poster_image", "poster_image_url")
                    }
                    className='dropzone'>
                    <div className='flex justify-between'>
                      <button
                        disabled={this.state.poster_image.status === "Uploading"}
                        className={`cs-button outline small nowrap ${
                          this.state.poster_image.status === "Uploading" ? "pending" : ""
                        }`}>
                        Upload File From Computer
                      </button>
                    </div>
                    {video.poster_image_url && (
                      <div>
                        <div className='flex justify-end'>
                          <img src={video.poster_image_url} height='200px'/>
                        </div>
                        <p className='small flex justify-end bold mt1'>{video.poster_image_file_name}</p>
                      </div>
                    )}
                  </Dropzone>
                </Status>
              ) : null}
            </>
          )}
        </>
      </Card>
    )
  }
}

Artwork.propTypes = {
  updateVideo: PropTypes.func.isRequired,
  video: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  initializeHttpUpload: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  video: state.ondemand_videos.video,
  settings: state.ondemand_videos.settings
})

const mapDispatchToProps = dispatch => ({
  updateVideo: bindActionCreators(updateVideo, dispatch),
  initializeHttpUpload: bindActionCreators(initializeHttpUpload, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Artwork)
