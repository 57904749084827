import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal, Table, Status } from "@bitcine/cs-theme"
import update from "immutability-helper"
import MemberIcon from "@components/member/MemberIcon"
import Search from "@components/search/"
import { TOGGLE_ACCESS_MODAL, SHOW_ACCESS_INVITES } from "@actions/project/access"
import { can, MODIFY_USERS_AND_ROLES, VIEW_PROJECTS } from "@src/helpers/authorization"
import { getTeamMembers } from "@api/settings/organizations/members"
import { saveAccess } from "@api/project/access"

class ProjectAccess extends Component {
  constructor(props) {
    super(props)

    this.state = {
      previousAuthorizedUsers: props.authorizedUserIds,
      authorizedUserIds: props.authorizedUserIds,
      showSearch: false,
      searchString: ""
    }

    this.handleOnChange = this.handleOnChange.bind(this)
    this.save = this.save.bind(this)
    this.getButton = this.getButton.bind(this)
  }
  componentDidMount() {
    //const ids = this.props.project.users.map(u => u._id)
    this.props.getTeamMembers()
  }
  handleOnChange(id) {
    const index = this.state.authorizedUserIds.findIndex(userId => userId === id)

    this.setState(
      update(this.state, {
        authorizedUserIds: index === -1 ? { $push: [id] } : { $splice: [[index, 1]] }
      })
    )
  }
  save() {
    this.props.saveAccess(this.props.project._id, this.state.authorizedUserIds, () => {
      this.props.onSave()
    })
  }
  getButton() {
    const difference = this.state.authorizedUserIds.filter(id => this.state.previousAuthorizedUsers.includes(id)).length

    let msg = ""

    if (difference < this.state.previousAuthorizedUsers.length) {
      const c = this.state.previousAuthorizedUsers.length - difference
      msg += `Remove ${c} ${c > 1 ? "people" : "person"}`
    }

    const c2 = this.state.authorizedUserIds.length - difference
    if (c2) {
      msg += `${msg.length > 0 ? ", " : ""}Add ${c2} ${c2 > 1 ? "people" : "person"}`
    }

    return (
      <button
        className={`cs-button ${msg.length ? "" : "white"}`}
        onClick={() => {
          if (msg.length) {
            this.save()
          } else {
            this.props.onSave() // Closes modal
          }
        }}>
        {msg.length ? msg : "Close"}
      </button>
    )
  }
  canViewProjects(user) {
    return can(user, [VIEW_PROJECTS]) || this.state.authorizedUserIds.includes(user._id)
  }
  canBeEdited(user) {
    return !can(user, [VIEW_PROJECTS])
  }
  render() {
    const { membersStatus, members, showInviteView, toggleAccessModal, canEditAccess } = this.props

    const { showSearch, searchString } = this.state

    const filteredUsers = members.filter(member =>
      member.full_name
        .trim()
        .toLowerCase()
        .includes(searchString.trim().toLowerCase())
    )

    return (
      <Modal open width={3} title='Project Members' onClose={toggleAccessModal}>
        <Status pending={membersStatus === "PENDING"} error={membersStatus === "ERROR"}>
          <div>
            <div className='flex mb2'>
              <div className='flex-auto'/>
              <Search
                visible={showSearch}
                disabled={false}
                onChange={({ target: { value } }) =>
                  this.setState({
                    searchString: value
                  })
                }
                onCancel={() => {
                  this.setState({ showSearch: false, searchString: "" })
                }}
                value={searchString}
                onOpen={() => this.setState({ showSearch: true })}
                placeholder='Search for a user...'/>

              {canEditAccess && (
                <button className='cs-button white ml1' onClick={showInviteView}>
                  Invite new team members
                </button>
              )}
            </div>
            <Table
              className='my3'
              widths={[50, "auto", 200, 200]}
              header={{
                columns: [{ text: "" }, { text: "User" }, { text: "Role" }, { text: "Status" }]
              }}
              body={{
                data: filteredUsers,
                row: {
                  compact: true,
                  checkbox: {
                    disabled: data => !this.canBeEdited(data) || !canEditAccess,
                    checked: data => this.canViewProjects(data),
                    onChange: data => this.handleOnChange(data._id)
                  },
                  render: [
                    data => <MemberIcon user={data}/>,
                    data => data.full_name,
                    data => data.role_name,
                    data => data.status
                  ]
                },
                empty: {
                  title: "No Team Members",
                  text: searchString.length ? "Try your search again!" : "Invite a team member to get started",
                  icon: "people"
                }
              }}/>
            <div className='pt3 right-align'>
              {canEditAccess ? (
                this.getButton()
              ) : (
                <button className='cs-button white' onClick={this.props.onSave}>
                  Close
                </button>
              )}
            </div>
          </div>
        </Status>
      </Modal>
    )
  }
}

ProjectAccess.propTypes = {
  project: PropTypes.object.isRequired,
  members: PropTypes.array.isRequired,
  getTeamMembers: PropTypes.func.isRequired,
  membersStatus: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  showInviteView: PropTypes.func.isRequired,
  toggleAccessModal: PropTypes.func.isRequired,
  canEditAccess: PropTypes.bool.isRequired,
  saveAccess: PropTypes.func.isRequired,
  authorizedUserIds: PropTypes.array.isRequired
}

const mapStateToProps = (state, props) => ({
  authorizedUserIds: state.project.access.project.user_ids,
  members: state.settings.organizations.members.items,
  membersStatus: state.settings.organizations.members.status,
  canEditAccess: can(state.user.auth, [MODIFY_USERS_AND_ROLES])
})

const mapDispatchToProps = dispatch => ({
  getTeamMembers: bindActionCreators(getTeamMembers, dispatch),
  showInviteView: () => dispatch({ type: SHOW_ACCESS_INVITES }),
  toggleAccessModal: () => dispatch({ type: TOGGLE_ACCESS_MODAL, payload: { project: {} } }),
  saveAccess: bindActionCreators(saveAccess, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAccess)
