import React from "react"
import PropTypes from "prop-types"
import { Tooltip } from "@bitcine/cs-theme"
import { GLOBALS } from "@src/config"
import 'styled-components/macro'

class OverLimitPopOver extends React.Component {
  constructor() {
    super()

    this.state = {
      show: false
    }
  }
  componentDidMount() {
    this.setState({
      show: this.props.isOverLimit
    })
  }
  render() {
    const InnerTip = () => (
      <React.Fragment>
        <div
          className='flex items-center'
          css={`
            margin: 0 0 15px 0;
          `}>
          <h4 className='flex-auto m0'>Over Storage Limit</h4>
          <button
            className='cs-button link'
            css={`
              height: 20px !important;
              color: #fff !important;
            `}
            onClick={() => {
              this.setState({
                show: false
              })
            }}>
            <i className='material-icons'>close</i>
          </button>
        </div>

        <p
          css={`
            margin: 0;
            max-width: 340px;
          `}>
          You are over your storage limit. Please delete content or upgrade your storage to upload new files.
          <a href={GLOBALS.ACCOUNTS_URL + '/settings/billing'}>
            <button className='cs-button white mt2'>Upgrade Storage</button>
          </a>
        </p>
      </React.Fragment>
    )

    return (
      <Tooltip
        hide={!this.state.show}
        horizontal={"right"}
        type={`error`}
        offset={"-15px 0"}
        constraints={[
          {
            to: "scrollParent",
            attachment: "together"
          }
        ]}
        render={<InnerTip/>}>
        <React.Fragment>{this.props.children}</React.Fragment>
      </Tooltip>
    )
  }
}

OverLimitPopOver.propTypes = {
  children: PropTypes.object.isRequired,
  isOverLimit: PropTypes.bool.isRequired
}

export default OverLimitPopOver
