import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import WhiteLogo from "@src/public/white-logo.png"
import Amex from "@src/public/payment/amex-logo.png"
import MasterCard from "@src/public/payment/mastercard-logo.png"
import Visa from "@src/public/payment/visa-logo.png"

const img = {
  "American Express": Amex,
  MasterCard: MasterCard,
  Visa: Visa
}

const Confirmation = ({ cart, creditCard, giftCards, usingPostPay }) => (
  <div>
    <div className='rounded border border-gray-5 bg-gray-6 pt2 px2'>
      <div className='flex items-center mb1'>
        <h4 className='flex-auto mb0'>
          Payment Method
          {creditCard && giftCards.length > 0 ? "s" : ""}
        </h4>
        <Link className='underline' to='/cart/payment'>
          Edit
        </Link>
      </div>
      {giftCards.map(card => (
        <div className='flex items-center py2' key={card._id}>
          <div className='bg-gray-2 rounded p1 flex items-center' style={{ width: "70px" }}>
            <img className='cs-img' src={WhiteLogo} alt='CineSend' style={{ width: "50px" }}/>
          </div>
          <strong className='pl3 flex auto'>Gift Card</strong>
        </div>
      ))}
      {creditCard && (
        <div className='flex items-center py2'>
          <div className='bg-gray-2 rounded p1 flex items-center' style={{ width: "70px" }}>
            <img className='cs-img' src={img[creditCard.brand]} alt={creditCard.brand} style={{ width: "50px" }}/>
          </div>
          <div className='pl3 pr4'>
            <strong>Ending in </strong>
            {creditCard.last4}
          </div>
          <div className='flex-auto'>
            <strong>Expiry: </strong>
            {creditCard.exp_month} / {creditCard.exp_year}
          </div>
        </div>
      )}
      {usingPostPay && (
        <div className='flex items-center py2'>
          <div className='bg-gray-2 rounded p1 flex items-center' style={{ width: "70px" }}>
            <img className='cs-img' src={WhiteLogo} alt='CineSend' style={{ width: "50px" }}/>
          </div>
          <strong className='pl3 flex auto'>Post Pay</strong>
        </div>
      )}
    </div>
  </div>
)

Confirmation.propTypes = {
  cart: PropTypes.object.isRequired,
  creditCard: PropTypes.object,
  giftCards: PropTypes.array,
  usingPostPay: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  cart: state.user.cart,
  giftCards: state.user.billing.paymentMethods.list.gift_cards.filter(card => card.is_payment_active),
  creditCard: state.user.billing.paymentMethods.list.credit_cards.find(
    card => card._id === state.user.cart.credit_card_id
  ),
  usingPostPay: state.user.billing.paymentMethods.list.using_post_pay === true
})

const mapDispatchToProps = dispatch => ({
  // updateNewPaymentMethod: bindActionCreators(updateNewPaymentMethod, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirmation)
