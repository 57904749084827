import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { prevStep, nextStep, updateOrder } from "@api/project/orders"
import { Checkbox } from "@bitcine/cs-theme"
import { getDiscPrice } from "@src/helpers/pricing"
import "../styles.scss"

const DiscQuantity = ({ prevStep, nextStep, order, items, updateOrder, prices, currencySymbol }) => (
  <div className='col-12'>
    {!items ? (
      <div className={`orderInnerContainer center py2`}>
        <div className='spinner'/>
      </div>
    ) : (
      <div className={"orderInnerContainer"}>
        <p>Select either DVD and/or Blu-ray and the quantity needed.</p>
        {[{ title: "Blu-ray", key: "blu_ray" }, { title: "DVD", key: "dvd" }].map(type => (
          <div key={type.key} className='pb3'>
            <div className='flex items-center'>
              <div className='flex-auto flex items-center'>
                <Checkbox
                  checked={order[`${type.key}_quantity`] > 0}
                  onChange={e => {
                    if (order[`${type.key}_quantity`] > 0) {
                      updateOrder(`${type.key}_quantity`, 0)
                    } else {
                      updateOrder(`${type.key}_quantity`, 1)
                    }
                  }}
                  id={`${type.key}_quantity`}/>
                <label htmlFor={`${type.key}_quantity`} className='cs-label pl2'>
                  <strong>{type.title}</strong>
                </label>
              </div>
              <strong className='pl2'>
                {currencySymbol}
                {prices[type.key].total}
              </strong>
            </div>
            <div className='flex justify-end items-center mt1'>
              <button
                disabled={order[`${type.key}_quantity`] < 1}
                onClick={() => updateOrder(`${type.key}_quantity`, order[`${type.key}_quantity`] - 1)}
                className='cs-button link material-icons'>
                remove_circle
              </button>
              <input
                className='cs-input no-min-width mx1'
                onPaste={e => e.preventDefault()}
                value={order[`${type.key}_quantity`]}
                style={{ width: "75px" }}
                onChange={({ target: { value } }) => {
                  updateOrder(`${type.key}_quantity`, value === "" ? 0 : parseFloat(value.replace(/\D/, "")))
                }}/>
              <button
                onClick={() => updateOrder(`${type.key}_quantity`, order[`${type.key}_quantity`] + 1)}
                className='cs-button link material-icons'>
                add_circle
              </button>
            </div>
          </div>
        ))}
      </div>
    )}
    <div className='flex justify-between pt3 col-12'>
      <button className='cs-button white' onClick={prevStep}>
        Back
      </button>
      <button
        className='cs-button'
        disabled={order.dvd_quantity === 0 && order.blu_ray_quantity === 0}
        onClick={nextStep}>
        Add to Cart
      </button>
    </div>
  </div>
)

DiscQuantity.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  items: PropTypes.object,
  updateOrder: PropTypes.func.isRequired,
  prices: PropTypes.object,
  currencySymbol: PropTypes.string.isRequired
}

const mapStateToProps = state => {
  const order = state.orders.active_order
  const file = order.source_file
  const items = state.utils.disc_items
  return {
    order,
    items,
    currencySymbol: state.user.auth.currency_symbol,
    prices: items
      ? {
        dvd: getDiscPrice(items, file.length_in_minutes, "dvd", order.dvd_quantity),
        blu_ray: getDiscPrice(items, file.length_in_minutes, "blu_ray", order.blu_ray_quantity)
      }
      : null
  }
}

const mapDispatchToProps = dispatch => ({
  prevStep: bindActionCreators(prevStep, dispatch),
  nextStep: bindActionCreators(nextStep, dispatch),
  updateOrder: bindActionCreators(updateOrder, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiscQuantity)
