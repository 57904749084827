import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { getProjectFolderTree } from "@api/project_folders/"
import { Radio, Modal } from "@bitcine/cs-theme"

import { hideDeleteFolder, deleteFolderAndMoveContents } from "@api/project_folders"

class DeleteProjectFolder extends Component {
  constructor() {
    super()

    this.state = {
      selectedFolder: {
        _id: "all_projects",
        title: "All Projects"
      }
    }
  }
  componentDidMount() {
    this.props.getProjectFolderTree()
  }
  render() {
    const {
      loadingTree,
      branches,
      hideDeleteFolder,
      folderToDelete,
      deleteFolderAndMoveContents,
      deleting
    } = this.props

    const renderTree = (branches, disable = false) => (
      <div>
        {branches.map(branch => (
          <div key={branch._id}>
            <div style={{ paddingLeft: 25 + "px" }}>
              <div className={`flex items-center mb1 ${disable || folderToDelete._id === branch._id ? "muted" : ""}`}>
                <Radio
                  id={`branch_${branch._id}`}
                  disabled={disable || folderToDelete._id === branch._id}
                  checked={this.state.selectedFolder._id === branch._id}
                  onChange={() => this.setState({ selectedFolder: branch })}/>

                {disable || folderToDelete._id === branch._id ? (
                  <label htmlFor={`branch_${branch._id}`} className='cs-label ml1'>
                    {branch.title}
                  </label>
                ) : (
                  <label
                    htmlFor={`branch_${branch._id}`}
                    className='cs-label ml1'
                    onClick={() => this.setState({ selectedFolder: branch })}>
                    {branch.title}
                  </label>
                )}
              </div>
              {renderTree(branch.children, disable || folderToDelete._id === branch._id)}
            </div>
          </div>
        ))}
      </div>
    )

    return (
      <Modal open clickOutsideCloses title='Delete Folder' onClose={hideDeleteFolder}>
        <div>
          <div>
            {loadingTree ? (
              <div className='center py4'>
                <div className='spinner'/>
              </div>
            ) : (
              <div>
                <p>Where would you like to move the contents of this folder to?</p>

                <div className='flex items-center mb1'>
                  <Radio
                    id='all_projects'
                    checked={this.state.selectedFolder._id === "all_projects"}
                    onChange={() =>
                      this.setState({
                        selectedFolder: {
                          _id: "all_projects",
                          title: "All Projects"
                        }
                      })
                    }/>

                  <label
                    htmlFor='all_projects'
                    className='cs-label ml1'
                    onClick={() =>
                      this.setState({
                        selectedFolder: {
                          _id: "all_projects",
                          title: "All Projects"
                        }
                      })
                    }>
                    All Projects
                  </label>
                </div>

                {renderTree(branches)}

                <div className='pt4 right-align'>
                  <button
                    className='cs-button ml1'
                    disabled={deleting}
                    onClick={() => {
                      deleteFolderAndMoveContents(folderToDelete, this.state.selectedFolder)
                    }}>
                    {deleting ? "Deleting..." : "Delete"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    loadingTree: state.projectFolders.loadingTree,
    branches: state.projectFolders.tree,
    folderToDelete: state.projectFolders.folderToDelete,
    deleting: state.projectFolders.deleting
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectFolderTree: bindActionCreators(getProjectFolderTree, dispatch),
    hideDeleteFolder: bindActionCreators(hideDeleteFolder, dispatch),
    deleteFolderAndMoveContents: bindActionCreators(deleteFolderAndMoveContents, dispatch)
  }
}

DeleteProjectFolder.propTypes = {
  getProjectFolderTree: PropTypes.func.isRequired,
  loadingTree: PropTypes.bool.isRequired,
  branches: PropTypes.array.isRequired,
  hideDeleteFolder: PropTypes.func.isRequired,
  folderToDelete: PropTypes.object.isRequired,
  deleteFolderAndMoveContents: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteProjectFolder)
