import React from "react"
import PropTypes from "prop-types"
import { DEFAULTS } from "@constants"
import { Status, Table } from "@bitcine/cs-theme"
import Breadcrumb from "@components/breadcrumb"
import Icon from "@components/files/icon/"
import { GLOBALS } from "@src/config"

class SelectFolder extends React.Component {
  constructor(props) {
    super(props)
    this.get = `${GLOBALS.API_URL}/projects/${props.projectID}/files`
    this.state = {
      status: "PENDING",
      files: []
    }
  }
  componentDidMount() {
    this.getFiles(null)
  }
  getFiles(folderID) {
    fetch(`${this.get}${this.query(folderID)}`, DEFAULTS)
      .then(res => res.json())
      .then(res => this.setState({ status: "READY", files: res.files, breadcrumb: res.breadcrumb }))
      .catch(error => this.setState({ status: "ERROR", error }))
  }
  query(folderID) {
    return folderID ? `/folder/${folderID}` : ""
  }
  onRowClick(data) {
    this.setState({ status: "PENDING", active_folder_id: data ? data._id : null }, () => {
      this.getFiles(data ? data._id : null)
    })
  }
  selectFolder = () => {
    this.props.setFolder(this.state.active_folder_id)
  }
  back = () => {
    this.props.clearFolder()
  }
  render() {
    const { status, files, breadcrumb } = this.state
    return (
      <Status pending={status === "PENDING"} error={status === "ERROR"}>
        <div>
          <a onClick={this.back} className='muted block mb2'>
            Back to projects
          </a>
          <Breadcrumb
            breadcrumb={breadcrumb}
            onAllClick={() => this.onRowClick(null)}
            onCrumbClick={crumb => this.onRowClick(crumb)}/>
          <Table
            className='my3'
            widths={[55, "auto"]}
            header={{
              columns: [{ text: "File" }, { text: "" }]
            }}
            body={{
              data: files.filter(file => file.type === "folder"),
              empty: {
                icon: "folder",
                title: "No Folders"
              },
              row: {
                onClick: (event, file) => this.onRowClick(file),
                render: [file => <Icon data={file}/>, file => file.name]
              }
            }}/>
          <div className='right-align mt3'>
            <button onClick={this.selectFolder} className='cs-button'>
              Select Here
            </button>
          </div>
        </div>
      </Status>
    )
  }
}

SelectFolder.propTypes = {
  projectID: PropTypes.string.isRequired,
  setFolder: PropTypes.func.isRequired,
  clearFolder: PropTypes.func.isRequired
}

export default SelectFolder
