import React from "react"
import PropTypes from "prop-types"
import { Modal } from "@bitcine/cs-theme"
import dayjs from "dayjs"
import DayPicker from "react-day-picker"

class DayPickerModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = { date: null }
  }
  getDate() {
    return this.state.date
      ? dayjs(this.state.date).toDate()
      : this.props.expiry && dayjs(this.props.expiry).isAfter(dayjs())
        ? dayjs(this.props.expiry).toDate()
        : null
  }
  save() {
    this.props.onSave(dayjs(this.state.date))
  }
  render() {
    const { showCancel, saveButtonText } = this.props
    return (
      <Modal title={this.props.title} open={true} onClose={() => this.props.onClose()}>
        <div>
          <div className='center'>
            <DayPicker
              disabledDays={{ before: dayjs().toDate() }}
              selectedDays={this.getDate()}
              onDayClick={day => {
                this.setState({ date: day })
              }}/>
          </div>
          <div className={`mt3 ${showCancel ? "flex" : "right-align"}`}>
            {showCancel && (
              <div className='flex-auto'>
                <button onClose={() => this.props.onClose()} className='cs-button white'>
                  Cancel
                </button>
              </div>
            )}

            <button
              className='cs-button'
              disabled={!this.state.date}
              onClick={() => {
                this.save()
              }}>
              {saveButtonText}
            </button>
          </div>
        </div>
      </Modal>
    )
  }
}

DayPickerModal.propTypes = {
  title: PropTypes.string.isRequired,
  expiry: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  showCancel: PropTypes.bool.isRequired,
  saveButtonText: PropTypes.string.isRequired
}

DayPickerModal.defaultprops = {
  showCancel: false,
  saveButtonText: "Save"
}

export default DayPickerModal
