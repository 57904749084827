import React from "react"
import PropTypes from "prop-types"
import Wrap from "../components/wrap"
import { Status } from "@bitcine/cs-theme"
import { DEFAULTS } from "@constants"
import { GLOBALS } from "@src/config"

class Listen extends React.Component {
  constructor(props) {
    super(props)
    this.s3_url = `${GLOBALS.API_URL}/s3/download?url=${props.file.url}&projectID=${props.projectID}&fileID=${
      props.file._id
    }&fileType=audio_track`
    this.state = {
      status: "PENDING"
    }
  }
  componentDidMount() {
    fetch(this.s3_url, DEFAULTS)
      .then(res => res.json())
      .then(({ token }) => this.setState({ status: "READY", url: token }))
      .catch(err => this.setState({ status: "ERROR" }))
  }
  render() {
    return (
      <Wrap title='Preview' defaultOpen={false}>
        <Status pending={this.state.status === "PENDING"} error={this.state.status === "ERROR"} height={140}>
          <div>
            <audio controls className='col-12 my2' src={this.state.url}/>
          </div>
        </Status>
      </Wrap>
    )
  }
}

Listen.propTypes = {
  file: PropTypes.object.isRequired,
  projectID: PropTypes.string.isRequired
}

export default Listen
