import React from "react"
import "../../styles.scss"

const Encoding = () => (
  <div className='center white'>
    <span className={`material-icons block large-icon rotating`}>cached</span>
    <h4>Your Secure Screener is Encoding!</h4>
    <div className='flex'>
      <div className='col-2'/>
      <div className='col-8'>
        <p>
          To keep your content secure, your source file is being encoded with studio-grade DRM technology that prevents
          malicious activity across all platforms. You will receive an email when the encoding is complete.
        </p>
      </div>
    </div>
  </div>
)

export default Encoding
