import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { push } from "connected-react-router"
import Breadcrumb from "@components/breadcrumb"
import Search from "./search"
import Filter from "./filter"
import New from "./new"
import Actions from "./actions"

class Header extends React.Component {
  render() {
    const { folderID, showActions, projectID, breadcrumb, searchTerm, push } = this.props
    return (
      <div className='flex items-center'>
        {searchTerm ? (
          <h4 className='flex-auto'>Search Results</h4>
        ) : (
          <Breadcrumb
            breadcrumb={breadcrumb}
            onAllClick={() => push(`/projects/${this.props.projectID}/files`)}
            onCrumbClick={crumb => push(`/projects/${this.props.projectID}/files/folder/${crumb._id}`)}/>
        )}
        {showActions ? (
          <Actions projectID={projectID} folderID={folderID}/>
        ) : (
          <div className='flex items-center'>
            <Search/>
            <Filter/>
            <New projectID={projectID} folderID={folderID}/>
          </div>
        )}
      </div>
    )
  }
}

Header.propTypes = {
  projectID: PropTypes.string.isRequired,
  folderID: PropTypes.string,
  searchTerm: PropTypes.string,
  showActions: PropTypes.bool.isRequired,
  breadcrumb: PropTypes.array.isRequired,
  push: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  showActions: state.project.files.items.checked.length > 0,
  breadcrumb: state.project.files.items.breadcrumb,
  searchTerm: state.project.files.items.searchTerm
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
