import {
  GET_SCREENER,
  SET_SCREENER,
  CLEAR_SCREENER,
  UPDATE_SCREENER,
  SAVE_SCREENER,
  GET_SCREENER_VIEWS,
  GET_SCREENER_ANALYTICS,
  ACTIVATE_SCREENER,
  RESEND_REVIEWER_EMAIL,
  SAVE_REVIEWER,
  UPDATE_REVIEWER,
  POLL_REVIEWERS,
  GET_REVIEWERS,
  UPDATE_SHARED_SCREENER_PASSWORD
} from "@actions/project/screener"

import { post, get, put } from "@api/fetch"

import { addGlobalMessage } from "@api/utils/"

import { DEFAULTS } from "@constants"

import { GLOBALS } from "@src/config"

/**
 * Activates a screener to encode
 *
 * @param {screenerID} - string - the screener to encode
 * @param {fileID} - string -
 *
 * @returns dispatch
 */

export const activateScreener = screenerID => (dispatch, getState) => {
  dispatch(
    post(
      ACTIVATE_SCREENER,
      `projects/${getState().project.details._id}/screeners/${screenerID}/activate`,
      null,
      null
    )
  )
}

/**
 * Get Screener
 * Retrieves a screener from the server and parses the bitmovin config object
 *
 * @param {String} screenerID
 * @returns Promise
 */

export const getScreener = screenerID => (dispatch, getState) => {
  dispatch(get(GET_SCREENER, `projects/${getState().project.details._id}/screeners/${screenerID}`))
}

/**
 * Get screener endpoints
 * Retrieves a screener from the server and parses the bitmovin config object
 *
 * @param {String} screenerID
 *
 * Note: If a screener encoding has not started, it will begin encoding instead
 * Note: Both Elemental and AWS screener endpoints need to be handled here
 *
 * @returns Promise
 */

export const getScreenerEndpoints = (screenerID, streamType, cb) => (dispatch, getState) => {
  let url = `${GLOBALS.API_URL}/projects/${getState().project.details._id}/screeners/${screenerID}/endpoints/${streamType}`
  if (getState().download_link.access_device) {
    url += '?accessDeviceID=' + getState().download_link.access_device._id
  }
  fetch(url, DEFAULTS)
    .then(res => res.json())
    .then(cb)
}

/**
 * Get the reviewers of a screener
 *
 * @param {String} $screenerID
 * @param {String} $search
 * @param {String} $skip
 * @param {String} $take
 * @param {Boolean} $asPoll if it should poll for new reviewers
 *
 * @returns dispatch
 */

export const getReviewers = (screenerID, search, skip, take, asPoll = false) => (dispatch, getState) => {
  const route = `projects/${getState().project.details._id}/screeners/${screenerID}`
  const params = `?search=${search}&take=${take}&skip=${skip}`
  if (asPoll) {
    dispatch(get(POLL_REVIEWERS, `${route}/reviewers${params}`))
  } else {
    dispatch(get(GET_REVIEWERS, `${route}/reviewers${params}`))
  }
}

/**
 * Save new Password
 *
 * @param {String} $screenerID
 * @param {String} $password
 * @returns dispatch
 */
export const updateSharedScreenerPassword = (sharedScreenerID, screenerID, password) => dispatch => {
  dispatch(
    put(
      UPDATE_SHARED_SCREENER_PASSWORD,
      `sharedScreeners/${sharedScreenerID}/password`,
      { password, screenerID },
      () => dispatch(addGlobalMessage(`Saved!`, "success"))
    )
  )
}

/**
 * Clear the active screener
 *
 * @param {path} - the path to return to
 *
 * @returns dispatch
 */

export const clearScreener = () => dispatch => {
  dispatch({ type: CLEAR_SCREENER })
}

/**
 * Update screener state
 *
 * @param {key} - string
 * @param {value} - string, int, array or obj
 *
 * @returns dispatch
 */

export const updateScreener = (key, value) => dispatch => dispatch({ type: UPDATE_SCREENER, key, value })

/**
 * Save screener on server
 *
 * @returns dispatch
 */

export const saveScreener = (key, value) => (dispatch, getState) => {
  const { _id } = getState().project.files.screener
  const projectID = getState().project.details._id
  dispatch(
    put(SAVE_SCREENER, `projects/${projectID}/screeners/${_id}`, { key, value }, () =>
      dispatch(addGlobalMessage("Preview link name updated!", "success"))
    )
  )
}

/**
 * Get analytics for a screener
 *
 * @returns dispatch
 */

export const getAnalytics = (screenerID, startDate, endDate) => (dispatch, getState) => {
  const { _id } = getState().project.details
  const params = `?startDate=${startDate}&endDate=${endDate}`
  dispatch(get(GET_SCREENER_VIEWS, `projects/${_id}/screeners/${screenerID}/watched${params}`))
  dispatch(get(GET_SCREENER_ANALYTICS, `projects/${_id}/screeners/${screenerID}/analytics${params}`))
}

/**
 * Resend an email to a reviewer
 *
 * @returns dispatch
 */

export const resendReviewerEmail = reviewerID => (dispatch, getState) => {
  const { _id } = getState().project.details
  dispatch(
    post(RESEND_REVIEWER_EMAIL, `projects/${_id}/sharedScreeners/${reviewerID}/resend`, null, () =>
      dispatch(addGlobalMessage("Email re-sent!", "success"))
    )
  )
}

/**
 * Update and save a property on reviewer
 *
 * @returns dispatch
 */

export const saveReviewer = (reviewerID, key, value) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_REVIEWER,
    reviewerID,
    key,
    value
  })

  dispatch(
    put(SAVE_REVIEWER, `sharedScreeners/${reviewerID}`, { key, value }, () =>
      dispatch(addGlobalMessage("Reviewer updated!", "success"))
    )
  )
}
