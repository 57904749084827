import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getFiles, resetPagination, updateSearch } from "@api/project/files/"
import _ from "lodash"
import { withRouter } from "react-router-dom"

class Search extends React.Component {
  constructor() {
    super()
    this.search = _.debounce(this.search.bind(this), 500)
  }
  search = () => {
    const { match, getFiles, resetPagination, currentFolderID, searchTerm } = this.props

    resetPagination(searchTerm.length >= 3)
    getFiles(match.params.projectID, currentFolderID)
  }
  render() {
    const { searchTerm, updateSearch } = this.props
    return (
      <input
        value={searchTerm}
        style={{ height: "34px" }}
        placeholder={`Search files...`}
        className='cs-input ml2'
        onChange={e => {
          updateSearch(e.target.value)
          this.search()
        }}/>
    )
  }
}

Search.propTypes = {
  getFiles: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  resetPagination: PropTypes.func.isRequired,
  currentFolderID: PropTypes.string,
  searchTerm: PropTypes.string.isRequired,
  updateSearch: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  searchTerm: state.project.files.items.searchTerm
})

const mapDispatchToProps = dispatch => ({
  getFiles: bindActionCreators(getFiles, dispatch),
  resetPagination: bindActionCreators(resetPagination, dispatch),
  updateSearch: bindActionCreators(updateSearch, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Search))
