import {
  UPDATE_FILES_MODAL,
  TOGGLE_MODAL_VISIBILITY,
  VIEW_FILE_INFO,
  TOGGLE_FOLDER_CONTENT_VISIBILITY,
  GET_FOLDER_CONTENT_PENDING,
  GET_FOLDER_CONTENT_FAILED,
  GET_FOLDER_CONTENT_FULFILLED,
  CREATE_PACKAGE_PENDING,
  CREATE_PACKAGE_FAILED,
  CREATE_PACKAGE_FULFILLED,
  CLOSE_FILE_SHARING_MODAL,
  UPDATE_SHARE_LABEL,
  DELETE_FILE_PENDING,
  DELETE_FILE_FULFILLED,
  DELETE_FILE_FAILED,
  MOVE_FILES_TO_PROJECT_PENDING,
  MOVE_FILES_TO_PROJECT_FULFILLED
} from "@actions/project/files"
import { INIT_DEFROST_FULFILLED } from "@actions/project/"

const initialState = {
  share: {
    visible: false,
    files: [],
    emails: [],
    message: "",
    download_once: true,
    status: "READY",
    label: "",
    password: "",
    passwordStatus: "READY"
  },
  tag: {
    visible: false,
    files: [],
    tags: [],
    status: "READY"
  },
  info: {
    visible: false,
    fileID: null,
    ref_to_focus: null
  },
  folderContent: {
    visible: false,
    files: [],
    audio: {},
    storageType: null,
    status: "PENDING"
  },
  delete: {
    visible: false,
    files: [],
    status: "READY"
  },
  defrost: {
    visible: false,
    files: []
  },
  moveFilesToProject: {
    visible: false,
    status: "READY"
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case VIEW_FILE_INFO:
      return {
        ...state,
        info: {
          visible: true,
          ref_to_focus: action.refToFocus,
          file: {
            _id: action.file._id,
            type: action.file.type
          }
        }
      }

    case UPDATE_FILES_MODAL:
      return {
        ...state,
        [action.modal]: {
          ...state[action.modal],
          [action.key]: action.value,
          ...(action.key !== "name" ? { dropData: [] } : {})
        }
      }

    case DELETE_FILE_PENDING:
      return {
        ...state,
        delete: {
          ...state.delete,
          status: "PENDING"
        }
      }

    case DELETE_FILE_FAILED:
      return {
        ...state,
        delete: {
          ...state.delete,
          status: "ERROR"
        }
      }

    case DELETE_FILE_FULFILLED:
      return {
        ...state,
        delete: initialState.delete
      }

    case TOGGLE_MODAL_VISIBILITY:
      return {
        ...state,
        [action.modal]: {
          ...state[action.modal],
          visible: !state[action.modal].visible,
          files: action.files,
          status: initialState[action.modal].status
        }
      }

    case CLOSE_FILE_SHARING_MODAL:
      return {
        ...state,
        share: initialState.share
      }

    case UPDATE_SHARE_LABEL:
      return {
        ...state,
        share: {
          ...state.share,
          label: action.payload.label
        }
      }

    case CREATE_PACKAGE_PENDING:
      return {
        ...state,
        share: {
          ...state.share,
          status: "PENDING"
        }
      }

    case CREATE_PACKAGE_FAILED:
      return {
        ...state,
        share: {
          ...state.share,
          status: "ERROR",
          errorMessage: action.payload.message
        }
      }

    case CREATE_PACKAGE_FULFILLED:
      return {
        ...state,
        share: {
          ...state.share,
          status: "COMPLETE",
          url: action.payload.package_url
        }
      }

    case TOGGLE_FOLDER_CONTENT_VISIBILITY:
      return {
        ...state,
        folderContent: {
          ...state.folderContent,
          visible: !state.folderContent.visible,
          file: action.file
        }
      }

    case GET_FOLDER_CONTENT_PENDING:
      return {
        ...state,
        folderContent: {
          ...state.folderContent,
          audio: {},
          status: "PENDING"
        }
      }

    case GET_FOLDER_CONTENT_FAILED:
      return {
        ...state,
        folderContent: {
          ...state.folderContent,
          status: "ERROR"
        }
      }

    case GET_FOLDER_CONTENT_FULFILLED:
      return {
        ...state,
        folderContent: {
          ...state.folderContent,
          status: "COMPLETE",

          /**
           * Folders uploaded with aspera will include a .aspera.dir
           * We need to filter these out of the folder content UI
           * Error handling is set up incase the server response is not an object
           */

          files: action.payload ? action.payload.filter(r => r.name && !r.name.startsWith(".")) : []
        }
      }

    case INIT_DEFROST_FULFILLED:
      return {
        ...state,
        defrost: {
          ...state.defrost,
          files: [
            {
              ...state.defrost.files[0],
              started_defrosting_at: action.payload.started_defrosting_at
            }
          ]
        }
      }

    case MOVE_FILES_TO_PROJECT_PENDING:
      return {
        ...state,
        moveFilesToProject: {
          ...state.moveFilesToProject,
          status: "PENDING"
        }
      }

    case MOVE_FILES_TO_PROJECT_FULFILLED:
      return {
        ...state,
        moveFilesToProject: {
          ...state.moveFilesToProject,
          status: "COMPLETE",
          files: []
        }
      }

    default:
      return state
  }
}
