import React from "react"
import ErrorBoundary from "@components/error_boundaries/"
import FileRequest from "./request"

const FileRequestWrap = props => (
  <ErrorBoundary>
    <FileRequest {...props}/>
  </ErrorBoundary>
)

export default FileRequestWrap
