import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { filterFiles } from "@src/components/project/files/functions/filter"
import { Checkbox, Empty } from "@bitcine/cs-theme"
import Pusher from "@components/pusher"
import { replaceFile, updateProjectPreviewImage } from "@api/project/files"
import { bindActionCreators } from "redux"
import { updateAllFilters } from "@api/project/filter"
import EmptyProjectIcon from "@src/public/icons/empty/project.inline.svg"
import SearchIcon from "@src/public/icons/empty/search.inline.svg"
import FileViewToggle from "../toggle"
import File from "./file"

class GridView extends React.Component {
  constructor() {
    super()

    this.state = {
      openFileMenuID: null
    }
  }
  componentDidMount() {
    if (localStorage.getItem("file_filters")) {
      this.props.updateAllFilters(JSON.parse(localStorage.getItem("file_filters")))
    }
  }
  render() {
    const { files, filter } = this.props
    const { openFileMenuID } = this.state
    const filtered = filterFiles(files.list, filter)
    return (
      <div className='my3'>
        <div className='px2 flex items-center mb1'>
          <Checkbox
            small
            checked={files.checked.length > 0}
            intermediate={files.checked.length !== files.list.length}
            onChange={() => this.props.onHeaderCheck()}/>
          <div
            style={{
              color: "#92999F",
              fontSize: "12px"
            }}
            className='ml2 flex-auto'>
            File
          </div>
          <FileViewToggle/>
        </div>
        {filtered.length === 0 ? (
          <Empty
            icon={filter.search ? <SearchIcon/> : <EmptyProjectIcon/>}
            title={
              filter.search
                ? "No results found"
                : this.props.folderID
                  ? "This folder is empty"
                  : "This project is empty"
            }
            text={
              filter.search
                ? "Try your search again"
                : `Drag files here or click "New" above to browse files from your computer`
            }/>
        ) : (
          <div className='flex flex-wrap'>
            {filtered.map(file => (
              <React.Fragment key={file._id}>
                <File
                  file={file}
                  dialogOpen={this.props.dialogOpen}
                  projectID={this.props.projectID}
                  checked={this.props.files.checked.includes(file._id)}
                  onRowClick={e => this.props.onRowClick(e, file)}
                  getMenuOptions={() => this.props.getMenuOptions(file)}
                  onRowCheck={() => this.props.onRowCheck(file)}
                  disableContextMenu={openFileMenuID && openFileMenuID === file._id}
                  onMenuOpen={() => this.setState({ openFileMenuID: file._id })}
                  onMenuClose={() => this.setState({ openFileMenuID: null })}/>

                {file.type === "document" && (
                  <Pusher
                    channel={`private-App.File.Document.${file._id}`}
                    event='App\Events\DocumentUpdated'
                    onUpdate={e => {
                      this.props.updateProjectPreviewImage(e.id, e.status, e.url, e.url_sm, e.url_md, e.url_lg, e.key)
                    }}/>
                )}

                {file.type === "source_file" && file.screener && (
                  <Pusher
                    channel={`private-App.Screener.${file.screener._id}`}
                    event='App\Events\ScreenerUpdated'
                    onUpdate={e => {
                      this.props.replaceFile(e.file)
                    }}/>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    )
  }
}

GridView.propTypes = {
  projectID: PropTypes.string.isRequired,
  files: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  onRowCheck: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onHeaderCheck: PropTypes.func.isRequired,
  getMenuOptions: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  replaceFile: PropTypes.func.isRequired,
  updateAllFilters: PropTypes.func.isRequired,
  folderID: PropTypes.string,
  updateProjectPreviewImage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  files: state.project.files.items,
  filter: state.project.files.filter,
  dialogOpen: state.utils.dialogOpen,
  // folderID: state.routerParams.location ? state.routerParams.location.query.folderID : null
})

const mapDispatchToProps = dispatch => ({
  replaceFile: bindActionCreators(replaceFile, dispatch),
  updateAllFilters: bindActionCreators(updateAllFilters, dispatch),
  updateProjectPreviewImage: bindActionCreators(updateProjectPreviewImage, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GridView)
