import React from "react"
import PropTypes from "prop-types"
import AsperaWhite from "@src/public/aspera_white.png"
import "./styles.scss"

const PackageShareType = ({ method }) => (
  <div
    className={`icon ${method == "aspera" ? "aspera" : "http"} 
    relative circle flex items-center justify-center box-shadow`}>
    <span className='material-icons' style={{ fontSize: "14px" }}>
      <img src={AsperaWhite} className='cs-img' style={{ width: "16px" }}/>
    </span>
  </div>
)

PackageShareType.propTypes = {
  method: PropTypes.string
}

export default PackageShareType
