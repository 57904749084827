import {
  SHOW_CREATE_PROJECT_FOLDER_MODAL,
  HIDE_CREATE_PROJECT_FOLDER_MODAL,
  CREATE_PROJECT_FOLDER,
  MOVE_PROJECTS_TO_FOLDER,
  REMOVE_PROJECTS_FROM_VIEW,
  UPDATE_PROJECT_FOLDER_TITLE,
  GET_PROJECT_FOLDER_TREE,
  SHOW_DELETE_FOLDER,
  REMOVE_FOLDER_FROM_VIEW,
  HIDE_DELETE_FOLDER,
  DELETE_PROJECT_FOLDER,
  FOLDER_HOVERED
} from "@actions/project_folders"

import { addGlobalMessage } from "@api/utils/"
import { get, post, del } from "@api/fetch"

export const setFolderHovered = folderID => dispatch => dispatch({ type: FOLDER_HOVERED, payload: { folderID } })

export const saveProjectFolder = (name, projectIds = []) => (dispatch, getState) => {
  const folder = getState().projects.breadcrumb.length
    ? getState().projects.breadcrumb[getState().projects.breadcrumb.length - 1].slug
    : null

  dispatch({ type: REMOVE_PROJECTS_FROM_VIEW, payload: { projectIds } })

  return dispatch(
    post(CREATE_PROJECT_FOLDER, `project_folders`, {
      name,
      folder,
      projectIds
    })
  )
}

export const openProjectFolderModal = () => dispatch => dispatch({ type: SHOW_CREATE_PROJECT_FOLDER_MODAL })
export const closeProjectFolderModal = () => dispatch => dispatch({ type: HIDE_CREATE_PROJECT_FOLDER_MODAL })

export const moveProjectsToFolder = (projectIds = [], folder) => (dispatch, getState) => {
  const currentFolderSlug = getState().projects.breadcrumb.length
    ? getState().projects.breadcrumb[getState().projects.breadcrumb.length - 1].slug
    : null

  dispatch({ type: REMOVE_PROJECTS_FROM_VIEW, payload: { projectIds } })

  return dispatch(
    post(MOVE_PROJECTS_TO_FOLDER, `project_folders/${folder._id}`, { projectIds, currentFolderSlug }, () =>
      dispatch(addGlobalMessage(`Project${projectIds.length > 1 ? "s" : ""} moved to ${folder.title}`, "check_circle"))
    )
  )
}

export const updateFolderTitle = (folder, title) => dispatch => {
  dispatch({ type: UPDATE_PROJECT_FOLDER_TITLE, payload: { folder, title } })
  dispatch(post("__NO_ACTION", `project_folders/${folder._id}/rename`, { title }))
}

export const getProjectFolderTree = () => dispatch => dispatch(get(GET_PROJECT_FOLDER_TREE, `project_folders`))

export const showDeleteFolder = folder => (dispatch, getState) => {
  const currentFolderSlug = getState().projects.breadcrumb.length
    ? getState().projects.breadcrumb[getState().projects.breadcrumb.length - 1].slug
    : null

  if (!folder.posters.length) {
    // This folder is empty.. no harm in just deleting it.
    dispatch({ type: REMOVE_FOLDER_FROM_VIEW, payload: { folder } })
    dispatch(
      del("__NO_ACTION", `project_folders/${folder._id}`, { currentFolderSlug }, () => {
        dispatch(addGlobalMessage(`${folder.title} was deleted!`, "success"))
      })
    )
  } else {
    dispatch({ type: SHOW_DELETE_FOLDER, payload: { folder } })
  }
}

export const hideDeleteFolder = folder => dispatch => dispatch({ type: HIDE_DELETE_FOLDER })

export const deleteFolderAndMoveContents = (folder, destinationFolder) => (dispatch, getState) => {
  const currentFolderSlug = getState().projects.breadcrumb.length
    ? getState().projects.breadcrumb[getState().projects.breadcrumb.length - 1].slug
    : null

  dispatch(
    del(
      DELETE_PROJECT_FOLDER,
      `project_folders/${folder._id}`,
      { folder, currentFolderSlug, destinationFolder: destinationFolder._id },
      () => {
        dispatch(
          addGlobalMessage(
            `${folder.title} was deleted and contents moved to ${destinationFolder.title}!`,
            "check_circle"
          )
        )
      }
    )
  )
}
