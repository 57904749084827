import React from "react"
import PropTypes from "prop-types"
import "./styles.scss"

const MemberIcon = ({ user, zindex }) => {
  return (
    <button
      className={`user tooltip`}
      // style={ { zIndex: zindex } }
      data-tooltip={user.full_name}>
      <span>{user.full_name ? getInitials(user.full_name) : <span>&nbsp;</span>}</span>
    </button>
  )
}

const getInitials = name => {
  const split = name.split(" ")
  return split[0].charAt(0) + (typeof split[1] === "string" ? split[1].charAt(0) : "")
}

MemberIcon.defaultProps = {
  zindex: 1
}

MemberIcon.propTypes = {
  user: PropTypes.object.isRequired,
  zindex: PropTypes.number
}

export default MemberIcon
