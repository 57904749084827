import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Status, Table } from "@bitcine/cs-theme"
import { getScreenerAttachments, updateList, deleteAttachment, importFiles } from "@api/project/screener_attachments"
import Dropzone from "react-dropzone"
import { initializeHttpUpload } from "@api/transfer/http/upload"
import SelectExisting from "./components/select_existing"

class ScreenerAttachments extends React.Component {
  constructor() {
    super()
    this.state = {
      openSelectModal: false
    }
  }
  componentDidMount() {
    this.props.getScreenerAttachments()
  }
  onFileSelected = (files, project_id) => {
    this.setState({ openSelectModal: false }, () => {
      this.props.importFiles(files, project_id)
    })
  }
  render() {
    const { screenerAttachments, project, initializeHttpUpload, updateList, deleteAttachment } = this.props
    return (
      <div>
        {this.state.openSelectModal && (
          <SelectExisting selectFiles={this.onFileSelected} onClose={() => this.setState({ openSelectModal: false })}/>
        )}
        <div className='flex items-center mb3'>
          <div className='flex-auto'>
            <h3>Screener Attachments</h3>
          </div>
          <div className='flex items-center'>
            <button className='cs-button white mr1' onClick={() => this.setState({ openSelectModal: true })}>
              Attach Existing File
            </button>
            <Dropzone
              multiple={false}
              className='_dropzone'
              onDrop={accepted => {
                initializeHttpUpload(
                  accepted[0],
                  {
                    is_screener_attachment: true,
                    project_id: project._id
                  },
                  result => {
                    updateList(result)
                  }
                )
              }}>
              <button className='cs-button white'>Upload Attachment</button>
            </Dropzone>
          </div>
        </div>
        <Status pending={screenerAttachments.status === "PENDING"} error={screenerAttachments.status === "FAILED"}>
          <div>
            <Table
              className='my3'
              widths={["auto", 60]}
              header={{
                columns: [{ text: "File" }, { text: "" }]
              }}
              body={{
                data: screenerAttachments.items,
                empty: {
                  icon: "close",
                  title: "No Files"
                },
                row: {
                  onClick: () => {
                    /** Silence is golden */
                  },
                  render: [
                    file => file.name,
                    file => (
                      <button
                        className='cs-button link'
                        onClick={() => {
                          if (confirm(`Are you sure you want to remove ${file.name}?`)) {
                            deleteAttachment(file)
                          }
                        }}>
                        <i className='material-icons'>delete</i>
                      </button>
                    )
                  ]
                }
              }}/>
          </div>
        </Status>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getScreenerAttachments: bindActionCreators(getScreenerAttachments, dispatch),
  initializeHttpUpload: bindActionCreators(initializeHttpUpload, dispatch),
  updateList: bindActionCreators(updateList, dispatch),
  deleteAttachment: bindActionCreators(deleteAttachment, dispatch),
  importFiles: bindActionCreators(importFiles, dispatch)
})

const mapStateToProps = state => ({
  screenerAttachments: state.project.screenerAttachments,
  project: state.project.details
})

ScreenerAttachments.propTypes = {
  getScreenerAttachments: PropTypes.func.isRequired,
  initializeHttpUpload: PropTypes.func.isRequired,
  updateList: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  screenerAttachments: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  importFiles: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenerAttachments)
