import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { showGlobalAuthModal } from "@api/account/auth"
import { ButtonDropdown } from "@bitcine/cs-theme"
import AsperaLogo from "@src/public/aspera.png"
import UploadFolderIcon from "@src/public/icons/material/upload_folder.inline.svg"
import UploadDCPIcon from "@src/public/icons/material/upload_dcp.inline.svg"
import Dropzone from "react-dropzone"
import sortFiles from "@helpers/file_sorter"
import UploadFileIcon from "@src/public/icons/material/upload_file.inline.svg"
import { updateFileRequest, getFileRequestSilent, initializeHttpFolderUpload } from "@api/file_request/"
import { initializeAsperaUpload } from "@api/transfer/aspera/upload"
import { initializeHttpUpload } from "@api/transfer/http/upload"
import { ReactComponent as DropboxIcon } from "@src/public/icons/dropbox.svg"
import { ReactComponent as GoogleDriveIcon } from "@src/public/icons/google-drive.svg"
import CineSendIcon from "@src/public/favicon.png"
import ImportModal from "../import-modal"
import 'styled-components/macro'

class Info extends React.Component {
  constructor(props) {
    super(props)

    this.timeout = null

    this.state = {
      select_existing_cinesend_file_open: false,
      folder_type: "dcp",
      import_dropbox_modal: false,
      import_google_modal: false
    }
  }
  getSpecs(isDCP = false) {
    let specs = {}
    const request = this.props.request
    if (request.type === "file-request") specs.file_request_id = this.props.request._id
    if (request.type === "cinerequest") specs.cinerequest_id = this.props.request._id
    if (request.project_id) specs.project_id = this.props.request.project_id
    if (request.destination_folder_id) specs.folder_id = this.props.request.destination_folder_id
    if (isDCP) specs.is_dcp = true
    return specs
  }
  addAsperaTransfer = type => {
    const isDCP = type === "folder" // since you can't use aspera to upload folders with files, any folder upload must be a DCP.
    const specs = this.getSpecs(isDCP)
    this.props.initializeAsperaUpload(specs)
  }
  selectExisting = () => {
    if (this.props.authenticated) {
      this.props.updateFileRequest("select_existing_cinesend_file_open", true)
    } else {
      this.props.showGlobalAuthModal(() => {
        this.props.updateFileRequest("select_existing_cinesend_file_open", true)
      })
    }
  }
  startImportDropbox() {
    this.setState({ ...this.state, import_dropbox_modal: true })
  }
  endDropboxImport() {
    this.setState({ ...this.state, import_dropbox_modal: false })
    this.updateRequestData()
  }
  startImportGoogle() {
    this.setState({ ...this.state, import_google_modal: true })
  }
  endGoogleImport() {
    this.setState({ ...this.state, import_google_modal: false })
    this.updateRequestData()
  }
  onDropFiles = files => {
    const sortedFiles = sortFiles(files)
    sortedFiles.map(file => {
      const specs = this.getSpecs()
      this.props.initializeHttpUpload(file, specs)
    })
  }
  onDropFolder = folder => {
    const isDCP = this.state.folder_type === "dcp"
    if (isDCP) {
      const specs = this.getSpecs(isDCP)
      this.props.initializeHttpUpload(folder, specs)
    } else {
      this.props.initializeHttpFolderUpload(this.props.request, folder)
    }
  }
  updateRequestData(forceTimeout) {
    // update every 5 seconds if there's something in file_import[]
    clearTimeout(this.timeout)

    this.props.getFileRequestSilent(this.props.request._id, this.props.location.pathname)

    if (forceTimeout || this.props.request.file_imports.length > 0) {
      this.timeout = setTimeout(() => {
        this.updateRequestData(false)
      }, 5000)
    }
  }
  render() {
    const { request } = this.props
    return (
      <div className='bg-white rounded box-shadow p3 mb2 justify-between max-width-3 mx-auto'>
        <div className='flex items-center'>
          <div className='col-6 pr2'>
            <p className='mb0'>Name of Request:</p>
            <h4 className='truncate'>{request.film_name || request.title}</h4>
          </div>
          <div className='col-6'>
            <div className='flex items-center justify-end'>
              <ButtonDropdown
                button={{
                  className: "white small ml2",
                  text: "Select",
                  disabled:
                    request.project &&
                    request.project.organization &&
                    request.project.organization.subscription &&
                    request.project.organization.subscription.storage.is_over_storage_limit // disabled if from Files (project) TODO: Send this flag from teh backend...
                }}
                dropdown={{
                  clickCloses: true,
                  content: [
                    {
                      text: "Upload file",
                      icon: (
                        <UploadFileIcon
                          css={`
                            width: 22px;
                          `}/>
                      ),
                      children: [
                        {
                          text: {
                            title: "Upload file",
                            description: "Standard upload speeds"
                          },
                          icon: "file_upload",
                          onClick: () => this.fileDropzone.open()
                        },
                        {
                          text: {
                            title: "Upload file with Aspera Connect",
                            description: "Faster, more reliable uploads with Aspera Connect"
                          },
                          icon: (
                            <img
                              className='cs-img'
                              src={AsperaLogo}
                              css={`
                                width: 20px;
                                height: 20px;
                                filter: grayscale(100%);
                              `}/>
                          ),
                          onClick: () => this.addAsperaTransfer("file", null)
                        }
                      ].filter(opt => !opt.hide)
                    },
                    {
                      text: "Upload folder",
                      icon: (
                        <UploadFolderIcon
                          css={`
                            width: 22px;
                          `}/>
                      ),
                      onClick: () => {
                        this.setState({ folder_type: "directory" }, () => {
                          this.folderDropzone.open()
                        })
                      },
                      hide: request.type !== "file-request"
                    },
                    {
                      text: "Upload DCP",
                      icon: (
                        <UploadDCPIcon
                          css={`
                            width: 22px;
                          `}/>
                      ),
                      children: [
                        {
                          text: {
                            title: "Upload DCP",
                            description: "Standard upload speeds"
                          },
                          icon: (
                            <UploadDCPIcon
                              css={`
                                width: 22px;
                              `}/>
                          ),
                          onClick: () => {
                            this.setState({ folder_type: "dcp" }, () => {
                              this.folderDropzone.open()
                            })
                          }
                        },
                        {
                          text: {
                            title: "Upload DCP with Aspera Connect",
                            description: "Faster, more reliable uploads with Aspera Connect"
                          },
                          icon: (
                            <img
                              className='cs-img'
                              src={AsperaLogo}
                              css={`
                                width: 20px;
                                height: 20px;
                                filter: grayscale(100%);
                              `}/>
                          ),
                          onClick: () => this.addAsperaTransfer("folder", null)
                        }
                      ].filter(opt => !opt.hide)
                    },
                    {
                      text: "Import from...",
                      icon: "upload",
                      children: [
                        {
                          text: "CineSend Files",
                          icon: (
                            <img
                              src={CineSendIcon}
                              css={`
                                width: 20px;
                                height: 20px;
                                filter: grayscale(100%);
                              `}/>
                          ),
                          onClick: () => this.selectExisting(),
                          show: true
                        },
                        {
                          text: "Dropbox",
                          icon: (
                            <DropboxIcon
                              css={`
                                width: 20px;
                                height: 20px;
                                filter: grayscale(100%);
                              `}/>
                          ),
                          onClick: () => this.startImportDropbox(),
                          show: request.type === "file-request"
                        },
                        {
                          text: "Google Drive",
                          icon: (
                            <GoogleDriveIcon
                              css={`
                                width: 20px;
                                height: 20px;
                                filter: grayscale(100%);
                              `}/>
                          ),
                          onClick: () => this.startImportGoogle(),
                          show: request.type === "file-request"
                        }
                      ].filter(opt => opt.show)
                    }
                  ].filter(opt => !opt.hide)
                }}/>
            </div>
            {request.intializing_aspera && (
              <small className='block right-align muted italic mt1'>Initializing Aspera...</small>
            )}
          </div>
        </div>

        <ImportModal
          importType='dropbox'
          active={this.state.import_dropbox_modal}
          closeImportModal={this.endDropboxImport.bind(this)}
          updateFileRequest={this.updateRequestData.bind(this)}
          showStatus={false}
          requestID={request.type === "file-request" && this.props.request._id}
          cinerequestID={request.type === "cinerequest" && this.props.request._id}
          folderID={this.props.request.destination_folder_id}
          projectID={this.props.request.project_id}/>
        <ImportModal
          importType='google_drive'
          active={this.state.import_google_modal}
          closeImportModal={this.endGoogleImport.bind(this)}
          updateFileRequest={this.updateRequestData.bind(this)}
          showStatus={false}
          requestID={request.type === "file-request" && this.props.request._id}
          cinerequestID={request.type === "cinerequest" && this.props.request._id}
          folderID={this.props.request.destination_folder_id}
          projectID={this.props.request.project_id}/>

        <Dropzone
          className='dropzone-'
          multiple={true}
          ref={ref => (this.fileDropzone = ref)}
          onDrop={(accepted, rejected) => this.onDropFiles(accepted)}/>
        <Dropzone
          className='dropzone-'
          multiple={true}
          inputProps={{
            webkitdirectory: "true",
            mozdirectory: "true",
            odirectory: "true",
            msdirectory: "true",
            directory: "true"
          }}
          ref={ref => (this.folderDropzone = ref)}
          onDrop={(accepted, rejected) => this.onDropFolder(accepted)}/>
      </div>
    )
  }
}

Info.propTypes = {
  request: PropTypes.object.isRequired,
  updateFileRequest: PropTypes.func.isRequired,
  showGlobalAuthModal: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  initializeHttpFolderUpload: PropTypes.func.isRequired,
  initializeHttpUpload: PropTypes.func.isRequired,
  initializeAsperaUpload: PropTypes.func.isRequired,
  getFileRequestSilent: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  request: state.file_request,
  authenticated: state.user.auth.status === "AUTHENTICATED"
})

const mapDispatchToProps = dispatch => ({
  updateFileRequest: bindActionCreators(updateFileRequest, dispatch),
  showGlobalAuthModal: bindActionCreators(showGlobalAuthModal, dispatch),
  initializeHttpFolderUpload: bindActionCreators(initializeHttpFolderUpload, dispatch),
  initializeHttpUpload: bindActionCreators(initializeHttpUpload, dispatch),
  initializeAsperaUpload: bindActionCreators(initializeAsperaUpload, dispatch),
  getFileRequestSilent: bindActionCreators(getFileRequestSilent, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Info)
