import {
  //GET_CUSTOM_FIELDS_PENDING,
  GET_CUSTOM_FIELDS_FULFILLED,
  GET_CUSTOM_FIELDS_FAILED,
  //CREATE_CUSTOM_FIELD_PENDING,
  CREATE_CUSTOM_FIELD_FULFILLED,
  UPDATE_CUSTOM_FIELD,
  //SAVE_CUSTOM_FIELD_PENDING,
  SAVE_CUSTOM_FIELD_FULFILLED,
  //DELETE_CUSTOM_FIELD_PENDING,
  DELETE_CUSTOM_FIELD_FULFILLED
} from "@actions/project/custom_fields"

const initialState = {
  status: "PENDING",
  list: []
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CUSTOM_FIELDS_FULFILLED:
      return {
        status: "READY",
        list: action.payload
      }

    case GET_CUSTOM_FIELDS_FAILED:
      return {
        status: "ERROR"
      }

    case CREATE_CUSTOM_FIELD_FULFILLED:
      return {
        status: "READY",
        list: action.payload
      }

    case UPDATE_CUSTOM_FIELD:
      return {
        status: "READY",
        list: state.list.map(item =>
          item._id === action.customFieldID
            ? {
              ...item,
              [action.key]: action.value
            }
            : item
        )
      }

    case SAVE_CUSTOM_FIELD_FULFILLED:
      return {
        status: "READY",
        list: action.payload
      }

    case DELETE_CUSTOM_FIELD_FULFILLED:
      return {
        status: "READY",
        list: action.payload
      }

    default:
      return state
  }
}
