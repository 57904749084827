import React from "react"
import PropTypes from "prop-types"
import "./styles.scss"
import { CART_ICONS } from "@constants"

class Order extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }
  getIcon(type = "") {
    return CART_ICONS[
      type
        .split(" ")
        .join("_")
        .toLowerCase()
    ]
  }
  render() {
    const { removeCartItem, order, index } = this.props
    // WTF is going on?
    const lineItems = order.lineItems || []
    const lines = [...lineItems, ...order.suborders.reduce((a, b) => [...a, ...b.lineItems], [])]

    return (
      <div className={`${order.deleting ? "muted not-allowed" : ""} ${index > 0 ? "border-top" : ""} border-gray-5`}>
        <div className='flex items-center py2'>
          <div className='pr2'>
            <span style={{ fontSize: "38px" }} className='material-icons'>
              {this.getIcon(order.type)}
            </span>
          </div>
          <div className='flex-auto'>
            <div className='flex items-center'>
              <h4 className='mb0'>{order.title}</h4>
              <span className='muted ml2'>
                {order.project
                  ? order.project.length > 18
                    ? order.project.substring(0, 18) + "..."
                    : order.project
                  : ""}
              </span>
            </div>
            <div className='muted'>
              This product {!order.includes_shipment ? "does not require" : "requires"} shipping.
            </div>
          </div>
          <div className='pl3'>
            <button onClick={() => this.setState({ open: !this.state.open })} className='cs-button link mr2'>
              <span className='regular px2 flex items-center'>
                Details&nbsp;
                <span className='caption material-icons'>
                  {this.state.open ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                </span>
              </span>
            </button>
            <button
              onClick={() => {
                const confirm = window.confirm("Are you sure you want to delete this cart item?")
                if (confirm) removeCartItem()
              }}
              className='cs-button link'>
              <span className='underline regular'>Delete</span>
            </button>
          </div>
        </div>
        <div className={`overflow-hidden details ${this.state.open ? `${"detailsOpen"}` : ""}`}>
          {lines.map((item, i) => (
            <div key={i} className='pb2 flex items-center'>
              <div className='flex-auto pr2'>
                {item.name}
                &nbsp;
                {item.type === "discount" && (
                  <div className='inline-block px1 bold bg-red white rounded'>{item.promo}</div>
                )}
                {item.quantity > 1 && (
                  <span>
                    {item.quantity} x {item.currency_symbol}
                    {parseFloat(item.price).toFixed(2)}
                  </span>
                )}
              </div>
              <div>
                {parseFloat(item.price).toFixed(2) >= 0 ? null : "- "}
                {item.currency_symbol}
                {parseFloat(item.price).toFixed(2) >= 0 ? (
                  <span>{(parseFloat(item.price) * item.quantity).toFixed(2)}</span>
                ) : (
                  <span>{(parseFloat(Math.abs(item.price)) * item.quantity).toFixed(2)}</span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

Order.propTypes = {
  removeCartItem: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}

export default Order
