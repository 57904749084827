import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal, Status } from "@bitcine/cs-theme"
import { toggleNewReviewers } from "@api/preview/send"
import { updateReviewersTopUp } from "@api/account/reviewers"
import { updateSupport } from "@api/account/support"
import Email from "./email/index"
import URL from "./url/index"

const SendPreviewLinks = ({
  toggleNewReviewers,
  remainingReviewers,
  remainingForensicWatermarkReviewers,
  totalForensicWatermarkReviewers,
  newReviewers = {},
  mode,
  shareType,
  visible,
  pending,
  updateReviewersTopUp,
  canUseForensicWatermark,
  updateSupport
}) => (
  <Modal open={visible} width={3} title={newReviewers.title} onClose={() => toggleNewReviewers("", "")}>
    <Status pending={pending}>
      {remainingReviewers < 5 && !canUseForensicWatermark && (
        <div className='my2 bg-gray-2 white p2 rounded flex items-center justify-between'>
          <div className='flex items-center'>
            <span className='material-icons mr2'>movie</span>
            <span>
              You have <strong>{remainingReviewers}</strong> regular screener links left to use this month.
            </span>
          </div>
          <button
            onClick={() => {
              toggleNewReviewers("", "")
              updateReviewersTopUp("visible", true)
            }}
            className='cs-button white small'>
            Purchase More Preview Links
          </button>
        </div>
      )}
      {totalForensicWatermarkReviewers > 0 && remainingForensicWatermarkReviewers < 5 && canUseForensicWatermark && (
        <div className='my2 bg-gray-2 white p2 rounded flex items-center justify-between'>
          <div className='flex items-center'>
            <span className='material-icons mr2'>policy</span>
            <span>
              You have <strong>{remainingForensicWatermarkReviewers}</strong> screener links with forensic watermarks
              left to use this month.
            </span>
          </div>
          <button
            onClick={() => {
              toggleNewReviewers("", "")
              updateSupport("visible", true)
            }}
            className='cs-button white small'>
            Contact Support to Purchase More
          </button>
        </div>
      )}
      {(remainingReviewers > 0 || remainingForensicWatermarkReviewers > 0) && (
        <div>{mode === "email" ? <Email/> : <URL/>}</div>
      )}
    </Status>
  </Modal>
)

const mapStateToProps = (state, context) => ({
  newReviewers: state.preview.send[state.preview.send.mode],
  mode: state.preview.send.mode,
  shareType: state.preview.send.shareType,
  remainingReviewers: state.user.auth.subscription.reviewers.remaining,
  remainingForensicWatermarkReviewers: state.user.auth.subscription.forensic_watermark_reviewers.remaining,
  totalForensicWatermarkReviewers: state.user.auth.subscription.forensic_watermark_reviewers.total,
  visible: state.preview.send.visible,
  pending: state.preview.send.email.status === "PENDING" || state.preview.send.link.status === "PENDING",
  canUseForensicWatermark: state.preview.send.can_use_forensic_watermark
})

const mapDispatchToProps = dispatch => ({
  toggleNewReviewers: bindActionCreators(toggleNewReviewers, dispatch),
  updateReviewersTopUp: bindActionCreators(updateReviewersTopUp, dispatch),
  updateSupport: bindActionCreators(updateSupport, dispatch)
})

SendPreviewLinks.propTypes = {
  newReviewers: PropTypes.object,
  remainingReviewers: PropTypes.number,
  remainingForensicWatermarkReviewers: PropTypes.number,
  totalForensicWatermarkReviewers: PropTypes.number,
  toggleNewReviewers: PropTypes.func.isRequired,
  mode: PropTypes.string,
  updateReviewersTopUp: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  canUseForensicWatermark: PropTypes.bool,//.isRequired,
  updateSupport: PropTypes.func.isRequired,
  shareType: PropTypes.string,//.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(SendPreviewLinks)
