import {
  GET_PROJECT_FILE_REQUESTS_PENDING,
  GET_PROJECT_FILE_REQUESTS_FULFILLED,
  GET_PROJECT_FILE_REQUESTS_FAILED,
  CREATE_PROJECT_FILE_REQUEST_PENDING,
  CREATE_PROJECT_FILE_REQUEST_FULFILLED,
  CREATE_PROJECT_FILE_REQUEST_FAILED,
  TOGGLE_PROJECT_FILE_REQUEST_MODAL,
  SEND_PROJECT_FILE_REQUEST_PENDING,
  SEND_PROJECT_FILE_REQUEST_FULFILLED,
  SEND_PROJECT_FILE_REQUEST_FAILED,
  UPDATE_PROJECT_FILE_REQUEST,
  SAVE_PROJECT_FILE_REQUEST_FULFILLED
} from "@actions/project/requests"

const initialState = {
  status: "PENDING",
  items: [],
  creation_status: "READY",
  show_create: false,
  show_share: false,
  share_status: "READY",
  show_edit: false,
  edit_status: "READY"
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PROJECT_FILE_REQUESTS_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_PROJECT_FILE_REQUESTS_FULFILLED:
      return {
        ...state,
        status: "COMPLETE",
        items: action.payload
      }

    case GET_PROJECT_FILE_REQUESTS_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case CREATE_PROJECT_FILE_REQUEST_PENDING:
      return {
        ...state,
        creation_status: "PENDING"
      }

    case CREATE_PROJECT_FILE_REQUEST_FULFILLED:
      return {
        ...state,
        creation_status: "READY",
        items: action.payload,
        show_create: false
      }

    case CREATE_PROJECT_FILE_REQUEST_FAILED:
      return {
        ...state,
        creation_status: "ERROR"
      }

    case TOGGLE_PROJECT_FILE_REQUEST_MODAL:
      return {
        ...state,
        [action.modal]: !state[action.modal],
        ...action.payload
      }

    case SEND_PROJECT_FILE_REQUEST_PENDING:
      return {
        ...state,
        share_status: "PENDING"
      }

    case SEND_PROJECT_FILE_REQUEST_FAILED:
      return {
        ...state,
        share_status: "ERROR"
      }

    case SEND_PROJECT_FILE_REQUEST_FULFILLED:
      return {
        ...state,
        share_status: "READY",
        show_share: false
      }

    case UPDATE_PROJECT_FILE_REQUEST:
      return {
        ...state,
        items: state.items.map(item =>
          item._id === action.requestID
            ? {
              ...item,
              [action.key]: action.value
            }
            : item
        )
      }

    case SAVE_PROJECT_FILE_REQUEST_FULFILLED:
      return {
        ...state,
        items: action.payload
      }

    default:
      return state
  }
}
