import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toggleModalVisibility, showAsperaSharing } from "@api/project/files/modals"
import { closeDocumentPreview } from "@api/project/files/preview"
import Aspera from "@src/public/aspera.png"
import { push } from "connected-react-router"
import { ButtonDropdown } from "@bitcine/cs-theme"
import { can, MANAGE_SCREENERS, SHARE_FILES } from "@helpers/authorization"
import 'styled-components/macro'

class ShareButtonDropdown extends React.Component {
  sendDownloadLink() {
    const file = this.props.file
    const args = [
      {
        _id: file._id,
        type: file.type,
        name: file.name
      }
    ]
    this.props.toggleModalVisibility("share", args)
  }
  render() {
    const { user, file, showAsperaSharing, push, closeDocumentPreview } = this.props
    if (!!file.frozen_at && file.screener && file.screener.status !== "Published") return null
    return (
      <ButtonDropdown
        button={{
          text: "Share",
          className: "white small ml1 z4"
        }}
        dropdown={{
          clickCloses: true,
          content: [
            {
              text: {
                title: "Share as a streaming screener",
                description: "Watchable streaming link for reviewers"
              },
              icon: "play_circle_filled",
              onClick: e => {
                closeDocumentPreview()
                push(`/projects/${file.screener.project_id}/files/preview-link/${file.screener._id}`)
              },
              showIfFrozen: true,
              show: file.screener && can(user, [MANAGE_SCREENERS]),
              breakAfter: !file.frozen_at
            },
            {
              text: {
                title: "Share file",
                description: "Recipients download through their browser"
              },
              icon: "exit_to_app",
              onClick: e => this.sendDownloadLink(),
              show: can(user, [SHARE_FILES])
            },
            {
              text: {
                title: "Share file with Aspera Connect",
                description: "Recipients download faster with Aspera Connect"
              },
              icon: (
                <img
                  className='cs-img'
                  src={Aspera}
                  css={`
                    width: 20px;
                    height: 20px;
                    filter: grayscale(100%);
                  `}/>
              ),
              onClick: e => showAsperaSharing([file]),
              show: can(user, [SHARE_FILES])
            }
          ].filter(opt => (!file.frozen_at ? opt.show : opt.show && opt.showIfFrozen))
        }}/>
    )
  }
}

ShareButtonDropdown.propTypes = {
  toggleModalVisibility: PropTypes.func.isRequired,
  showAsperaSharing: PropTypes.func.isRequired,
  closeDocumentPreview: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  file: state.documentPreviewer.document,
  user: state.user.auth
})

const mapDispatchToProps = dispatch => ({
  toggleModalVisibility: bindActionCreators(toggleModalVisibility, dispatch),
  closeDocumentPreview: bindActionCreators(closeDocumentPreview, dispatch),
  showAsperaSharing: bindActionCreators(showAsperaSharing, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ShareButtonDropdown)
