import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateProjectDetails, saveProject } from "@api/project"
import Dropzone from "react-dropzone"
import { initializeHttpUpload } from "@api/transfer/http/upload"
import Select from "react-select"
import { getGenres } from "@api/utils/"
import Poster from "@components/projects/components/project/poster/"
import { GENERAL_INPUTS } from "./constants"
import CustomFields from "./custom_fields"

class General extends React.Component {
  componentDidMount() {
    this.props.getGenres()
  }
  parseGenreOptions(genres, general_genres) {
    if (genres && general_genres) {
      return general_genres.concat(
        parseGenres(genres).map(option => ({
          value: option,
          label: option
        }))
      )
    }

    return general_genres
  }
  render() {
    const { project, updateProjectDetails, saveProject, initializeHttpUpload } = this.props
    return (
      <div className='max-width-4'>
        <h4 className='pb3'>Project Details</h4>
        <div className='flex'>
          <div className='col-2 pr3'>
            <Dropzone
              multiple={false}
              accept='image/*'
              className='_dropzone'
              onDrop={(accepted, rejected) => {
                initializeHttpUpload(
                  accepted[0],
                  {
                    is_project_poster: true,
                    project_id: project._id
                  },
                  result => {
                    const reader = new FileReader()
                    reader.readAsDataURL(accepted[0])
                    reader.onload = () => {
                      updateProjectDetails(project._id, "active_poster_url", reader.result)
                    }
                  }
                )
              }}>
              <Poster src={project.active_poster_url}/>
            </Dropzone>
          </div>
          <div className='col-10 flex flex-wrap'>
            {GENERAL_INPUTS.map((input, k) => (
              <div key={k} className={input.className}>
                <label className='cs-label' htmlFor={`__${input.key}`}>
                  {input.label}
                </label>
                {input.type === "textarea" ? (
                  <textarea
                    id={`__${input.key}`}
                    value={project[input.key] || ""}
                    className='cs-textarea col-12'
                    onBlur={e => saveProject(project._id, input.key, project[input.key])}
                    onChange={({ target: { value } }) => updateProjectDetails(project._id, input.key, value)}/>
                ) : input.type === "text" ? (
                  <input
                    id={`__${input.key}`}
                    value={project[input.key] || ""}
                    className='cs-input col-12'
                    onBlur={e => saveProject(project._id, input.key, project[input.key])}
                    onChange={({ target: { value } }) => updateProjectDetails(project._id, input.key, value)}/>
                ) : input.type === "multi" ? (
                  <Select.Creatable
                    id={`__${input.key}`}
                    multi={true}
                    className='col-12'
                    isLoading={!this.props[input.options]}
                    options={this.parseGenreOptions(project.genres, this.props[input.options])}
                    value={parseGenres(project.genres)}
                    simpleValue={true}
                    onChange={value => {
                      updateProjectDetails(project._id, input.key, value)
                      saveProject(project._id, input.key, value)
                    }}/>
                ) : null}
              </div>
            ))}
          </div>
        </div>
        <div className='border-bottom border-gray-5 my4'/>
        <CustomFields/>
      </div>
    )
  }
}

const parseGenres = (genres = "") => (typeof genres === "string" ? genres.split(",") : [])

const mapStateToProps = (state, context) => ({
  project: state.project.details,
  genres: state.utils.genres
})

const mapDispatchToProps = dispatch => ({
  updateProjectDetails: bindActionCreators(updateProjectDetails, dispatch),
  saveProject: bindActionCreators(saveProject, dispatch),
  initializeHttpUpload: bindActionCreators(initializeHttpUpload, dispatch),
  getGenres: bindActionCreators(getGenres, dispatch)
})

General.propTypes = {
  project: PropTypes.object.isRequired,
  updateProjectDetails: PropTypes.func.isRequired,
  saveProject: PropTypes.func.isRequired,
  initializeHttpUpload: PropTypes.func.isRequired,
  getGenres: PropTypes.func.isRequired,
  genres: PropTypes.array
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(General)
