import React from "react"
import PropTypes from "prop-types"
import Icon from "@public/icons/watermark.inline.svg"
import "./styles.scss"

const Watermark = ({ active }) => (
  <div
    className={`icon watermark ${active ? "activewatermark" : ""}
        relative circle flex items-center justify-center box-shadow`}>
    <Icon style={{ height: "12px", width: "12px" }}/>
  </div>
)

Watermark.propTypes = {
  active: PropTypes.bool.isRequired
}

export default Watermark
