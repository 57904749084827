import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import DropdownActions from "./dropdown_actions"
import AsperaTransfer from "./aspera_transfer"

const Actions = ({ download, file, activeTransfer }) =>
  download.is_expired ? (
    ""
  ) : (
    <div className='flex items-center px2' data-testid='download-link-item-actions'>
      {activeTransfer ? <AsperaTransfer transfer={activeTransfer}/> : <DropdownActions file={file}/>}
    </div>
  )

Actions.propTypes = {
  file: PropTypes.object.isRequired,
  download: PropTypes.object.isRequired,
  activeTransfer: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  activeTransfer: state.download_link.aspera_downloads[ownProps.file._id] || null
})

export default connect(mapStateToProps)(Actions)
