import React from "react"
import PropTypes from "prop-types"
import "./index.scss"

class Title extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      editableTitle: props.title || ""
    }
  }
  submitNewTitle({ target: { value } }) {
    this.props.onComplete(value)
    this.setState({ editing: false })
  }
  onKeyPress({ keyCode }) {
    if (keyCode === 13) {
      this.setState({
        editing: false
      })
      this.props.onComplete(this.state.editableTitle)
    } else if (keyCode === 27) {
      this.setState({
        editableTitle: this.props.title || "",
        editing: false
      })
    }
  }
  render() {
    return this.state.editing ? (
      <input
        type='text'
        autoFocus
        data-testid='projects-project-title-input'
        value={this.state.editableTitle}
        style={{ minWidth: "0px", height: "23px" }}
        className='cs-input col-12 mt1'
        onBlur={e => this.submitNewTitle(e)}
        onFocus={e => this.props.onFocus()}
        onKeyDown={e => this.onKeyPress(e)}
        onChange={({ target: { value } }) => this.setState({ editableTitle: value })}/>
    ) : (
      <strong
        data-testid='projects-project-title-msg'
        className={`pt1 center truncate flex items-center projectTitle`}
        onClick={() => this.setState({ editing: true })}>
        {this.props.folder && (
          <span className='material-icons mr1' style={{ fontSize: "1.2em" }}>
            folder
          </span>
        )}
        {this.state.editableTitle}
        <span className={`material-icons ml1 materialIcon`} style={{ fontSize: "0.8em" }}>
          edit
        </span>
      </strong>
    )
  }
}

Title.propTypes = {
  title: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  folder: PropTypes.bool,
  onFocus: PropTypes.func
}

export default Title
