import React from "react"
import PropTypes from "prop-types"
import { ReactComponent as Icon } from "@public/icons/material/policy_white.svg"
import "./styles.scss"

const Watermark = ({ active }) => (
  <div
    className={`icon forensicWatermark ${active ? "activeforensicwatermark" : ""}
        relative circle flex items-center justify-center box-shadow`}>
    <Icon style={{ height: "12px", width: "12px" }}/>
  </div>
)

Watermark.propTypes = {
  active: PropTypes.bool.isRequired
}

export default Watermark
