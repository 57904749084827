import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal, Status } from "@bitcine/cs-theme"
import { togglePermissionsModal } from "@api/screener_rooms/"
import { getTeamMembers } from "@api/settings/organizations/members"
import Permissions from "./permissions"

class ScreenerPermissions extends React.Component {
  componentDidMount() {
    this.props.getTeamMembers()
  }
  render() {
    return (
      <Modal open title='Access' width={3} onClose={this.props.togglePermissionsModal}>
        <Status
          pending={this.props.membersStatus === "PENDING" || this.props.isSavingAccess}
          error={this.props.membersStatus === "ERROR"}>
          <Permissions/>
        </Status>
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => ({
  membersStatus: state.settings.organizations.members.status,
  isSavingAccess: state.screener_rooms.isSavingAccess
})

const mapDispatchToProps = dispatch => ({
  togglePermissionsModal: bindActionCreators(togglePermissionsModal, dispatch),
  getTeamMembers: bindActionCreators(getTeamMembers, dispatch)
})

ScreenerPermissions.propTypes = {
  togglePermissionsModal: PropTypes.func.isRequired,
  membersStatus: PropTypes.string.isRequired,
  getTeamMembers: PropTypes.func.isRequired,
  isSavingAccess: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenerPermissions)
