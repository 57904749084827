import React from "react"
import PropTypes from "prop-types"
import "./styles.scss"

const SharedScreenerType = ({ sharedScreener }) => (
  <div
    className={`icon 
      sharedScreener.type == "screener_group" ? "screenergroup" : "singlescreener"
    } 
    relative circle flex items-center justify-center box-shadow`}>
    <span className='material-icons white' style={{ fontSize: "14px" }}>
      {sharedScreener.type === "screener_group" ? "view_module" : "play_circle_outline"}
    </span>
  </div>
)

SharedScreenerType.propTypes = {
  sharedScreener: PropTypes.object.isRequired
}

export default SharedScreenerType
