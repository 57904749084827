import React from "react"
import PropTypes from "prop-types"

const Poster = ({ poster }) => (
  <div
    style={{
      height: "44px",
      width: "32px",
      backgroundImage: poster ? `url(${poster})` : ""
    }}
    className='bg-gray-5 rounded box-shadow bg-cover bg-center'/>
)

Poster.propTypes = {
  poster: PropTypes.string
}

export default Poster
