import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import formatDate from "@src/helpers/formatDate"

const Imports = ({ imports }) =>
  imports.length > 0 && (
    <div className='max-width-3 mx-auto mt2'>
      <strong className='block muted mb1'>Importing Files & Folders ({imports.length})</strong>
      {imports.map(file => (
        <div key={file._id} className='p2 bg-white rounded box-shadow flex items-center mb1'>
          <div className='col-8 flex items-center'>
            <span style={{ fontSize: "16px" }} className='material-icons green mr1'>
              check_circle
            </span>
            <strong className='truncate'>{file.origin_name}</strong>
          </div>
          <div className='col-4'>
            <div className='flex items-center justify-end'>
              <strong className='truncate'>{formatDate(file.created_at)}</strong>
            </div>
          </div>
        </div>
      ))}
    </div>
  )

Imports.propTypes = {
  imports: PropTypes.array
}

const mapStateToProps = ({ file_request }) => ({
  imports: file_request.file_imports
})

export default connect(mapStateToProps, null)(Imports)
