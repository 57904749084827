import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Button } from "@bitcine/cs-theme"
import TrailerUpload from "./components/trailer_upload"
import VideoSubmission from "./components/video_submission"
import Artwork from "./components/artwork"
import Details from "./components/details"
import DCPUpload from "./components/dcp_upload"

const Video = ({ video, logoUrl, videoRequestText, orgName, settings }) => {
  const [showGate, setShowGate] = useState(videoRequestText && !video.is_insertion)
  return (
    <div>
      {logoUrl && (
        <div className='center pb2'>
          <img style={{ maxHeight: "56px" }} src={logoUrl}/>
        </div>
      )}
      {showGate ? (
        <div className='py3 center'>
          <div dangerouslySetInnerHTML={{ __html: videoRequestText }}/>
          <Button small className='mt2' onClick={() => setShowGate(false)}>
            Continue
          </Button>
        </div>
      ) : (
        <div className='py2 border-gray-5 border-top'>
          <h3>{video.title}</h3>
          <p>
            <b>{orgName}</b> has requested that you upload content directly to their CineSend account. Video content
            uploaded on this page is immediately encrypted with Digital Rights Management (DRM).
          </p>
          <div className='flex mt2'>
            <div className='col-6'>
              {!video.is_insertion && settings.can_upload_dcp && <DCPUpload/>}
              {settings.can_upload_video && <VideoSubmission/>}
              {!video.is_insertion && (
                <>
                  {settings.can_upload_trailer && <TrailerUpload/>}
                  {(settings.can_upload_thumbnail || settings.can_upload_poster) && <Artwork/>}
                </>
              )}
            </div>
            {!video.is_insertion && settings.can_edit_metadata && 
              <div className='col-6'><Details/></div>}
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  video: state.ondemand_videos.video,
  logoUrl: state.ondemand_videos.logoUrl,
  videoRequestText: state.ondemand_videos.videoRequestText,
  settings: state.ondemand_videos.settings,
  orgName: state.ondemand_videos.organizationName
})

Video.propTypes = {
  video: PropTypes.object.isRequired,
  logoUrl: PropTypes.string,
  videoRequestText: PropTypes.string,
  settings: PropTypes.object.isRequired,
  orgName: PropTypes.string.isRequired
}

export default connect(mapStateToProps)(Video)
