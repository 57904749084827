import React, { useEffect, useState } from "react"
import { Table, Modal, Status } from "@bitcine/cs-theme"
import formatDate from "@helpers/formatDate"
import humanFileSize from "@helpers/humanFileSize"
import { DEFAULTS } from "@constants"
import { GLOBALS } from "@src/config"

export default function Submissions({ fileRequestID, onClose }) {
  const [files, setFiles] = useState(null)
  useEffect(() => {
    fetch(`${GLOBALS.API_URL}/fileRequests/${fileRequestID}/files`, DEFAULTS)
      .then(res => res.json())
      .then(res => setFiles(res))
  }, [])
  return (
    <Modal open={true} width={3} onClose={onClose} title='Uploads'>
      <Status pending={files === null}>
        <Table
          className='my3'
          widths={["auto", "140", "220"]}
          header={{
            columns: ["File", "Size", "Date"].reduce((all, text) => [...all, { text }], [])
          }}
          body={{
            data: files || [],
            row: {
              render: [
                data => data.name || data.file_name || "",
                data => humanFileSize(data.size),
                data => formatDate(data.created_at)
              ]
            },
            empty: {
              icon: "cloud_upload",
              title: "No Submissions",
              text: "No files have been uploaded for this request"
            }
          }}/>
      </Status>
    </Modal>
  )
}