import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setParent } from "@api/shared_screeners"
import ReviewersTable from "./components/table"
import Search from "./components/search"
import ScreenerRoomViewersModal from "./modals/screener_room_viewers"
import PasswordModal from "./modals/password"
import Sessions from "./modals/sessions"
import ExpiryDateModal from "./modals/expiry_date"
import ViewCountModal from "./modals/screener_view_count"
import Export from "./components/export"

class SharedScreeners extends React.Component {
  componentDidMount() {
    this.props.setParent(this.props.parentType, this.props.parentID)
  }
  render() {
    let { modals, headerComponents } = this.props
    return (
      <div>
        <div className='flex items-center'>
          <h4 className='flex-auto flex items-center'>Reviewers</h4>
          <div className='flex'>
            <Search/>
            <Export/>
            {headerComponents}
          </div>
        </div>
        <ReviewersTable/>
        {modals.password.visible && <PasswordModal/>}
        {modals.expiryDate.visible && <ExpiryDateModal/>}
        {modals.viewCountLimit.visible && <ViewCountModal/>}
        {modals.screenerRoomViewers.visible && <ScreenerRoomViewersModal/>}
        {modals.sessions.visible && <Sessions/>}
      </div>
    )
  }
}

SharedScreeners.propTypes = {
  parentType: PropTypes.string.isRequired,
  parentID: PropTypes.string.isRequired,
  setParent: PropTypes.func.isRequired,
  modals: PropTypes.object.isRequired,
  headerComponents: PropTypes.object,
}

const mapStateToProps = ({ shared_screeners }) => ({
  modals: shared_screeners.modals
})

const mapDispatchToProps = dispatch => ({
  setParent: bindActionCreators(setParent, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SharedScreeners)
