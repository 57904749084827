import React from "react"
import styled from "react-emotion"
import NoPreview from "./no_preview.inline.svg"
import DownloadButtonDropdown from "./download_button_dropdown"

const Wrap = styled("div")`
  background: rgba(56, 62, 67, 0.43);
`

const Icon = styled(NoPreview)`
  height: 100px;
`

const InvalidDocument = () => (
  <Wrap className='rounded p4 center'>
    <Icon/>
    <h3 className='mb2'>No Preview Available</h3>
    <DownloadButtonDropdown/>
  </Wrap>
)

export default InvalidDocument
