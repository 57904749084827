import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"
import { Modal } from "@bitcine/cs-theme"
import { DEFAULTS } from "@constants"
import { showGlobalAuthModal, logout } from "@api/account/auth"
import debounce from "debounce"

class Idle extends Component {
  constructor(props) {
    super(props)
    this.events = ["mousemove", "mousedown", "keydown", "touchstart", "scroll", "uploadingprogress"]
    this.timeoutDuration = 1000 * 60 * 60 * 2 // 2 hours = (1000 ms * 60 minutes * 60 seconds) * 2
    this.timer = null
    this.signoutTimer = null
    this.state = {
      isIdle: false,
      signoutTimerCount: 30,
      authStatus: "AUTHED",
      currentTitle: ""
    }
    this.setTimer = this.setTimer.bind(this)
    this.eventTriggered = debounce(this.eventTriggered.bind(this), 50)
    this.setSignoutTimer = this.setSignoutTimer.bind(this)
    this.resetSignoutTimer = this.resetSignoutTimer.bind(this)
  }
  componentDidMount() {
    window.uploadingEvent = document.createEvent("Event")
    window.uploadingEvent.initEvent("uploadingprogress", true, true)

    this.events.forEach(event => {
      window.addEventListener(event, this.eventTriggered, true)
    })

    this.setTimer()
  }
  componentWillUnmount() {
    this.events.forEach(event => {
      window.removeEventListener(event, this.eventTriggered, true)
    })
  }
  eventTriggered(e) {
    if (this.state.isIdle && this.state.authStatus !== "CHECKING") {
      this.setState(
        {
          authStatus: "CHECKING"
        },
        () => {
          fetch(`${GLOBALS.API_URL}/auth/check`, DEFAULTS).then(res => {
            clearTimeout(this.timer)
            this.resetSignoutTimer()
            document.title = this.state.currentTitle
            if (res.ok) {
              this.setTimer()
              this.setState({ isIdle: false, authStatus: "AUTHED" })
            } else {
              this.props.showLoginModal(() => {
                this.setTimer()
              }, false)
              this.setState({ isIdle: false, authStatus: "NOT_AUTHED" })
            }
          })
        }
      )
    } else if (this.state.authStatus === "NOT_AUTHED") {
      document.title = this.state.currentTitle
      clearTimeout(this.timer)
      this.resetSignoutTimer()
    } else {
      document.title = this.state.currentTitle || document.title
      clearTimeout(this.timer)
      this.resetSignoutTimer()
      this.setTimer()
    }
  }
  setTimer() {
    this.timer = setTimeout(() => {
      this.setState({
        isIdle: true,
        authStatus: "AUTHED",
        currentTitle: document.title
      })
      this.setSignoutTimer()
    }, this.timeoutDuration)
  }
  setSignoutTimer() {
    this.signoutTimer = setTimeout(() => {
      if (this.state.signoutTimerCount <= 1) {
        document.title = this.state.currentTitle
        this.props.logout()
      } else {
        this.setState(
          prevState => {
            return { signoutTimerCount: prevState.signoutTimerCount - 1 }
          },
          () => {
            document.title = `🔴 Signing out in ${this.state.signoutTimerCount} seconds...`
            this.setSignoutTimer()
          }
        )
      }
    }, 1000)
  }
  resetSignoutTimer() {
    clearTimeout(this.signoutTimer)
    this.setState({ signoutTimerCount: 30 })
  }
  render() {
    const { isIdle, signoutTimerCount, authStatus } = this.state

    return (
      <div>
        <Modal open={isIdle} onClose={() => {}} clickOutsideCloses={true} title='Inactive' isDismissabled={false}>
          {authStatus === "CHECKING" ? (
            <div className='py4 center'>
              <div className='spinner'/>
            </div>
          ) : (
            <div className='py4 center'>
              <i className='material-icons'>warning</i>
              <div>
                <h3>Inactive</h3>
                <p>
                  We will sign you out in <strong>{signoutTimerCount}</strong> seconds unless you interact with the app.
                </p>
              </div>
            </div>
          )}
        </Modal>

        <div>{this.props.children}</div>
      </div>
    )
  }
}

Idle.propTypes = {
  children: PropTypes.node.isRequired,
  showLoginModal: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
}

const bindDispatchToProps = dispatch => ({
  showLoginModal: bindActionCreators(showGlobalAuthModal, dispatch),
  logout: bindActionCreators(logout, dispatch)
})

export default connect(null, bindDispatchToProps)(Idle)
