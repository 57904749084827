import React from "react"
import PropTypes from "prop-types"

const Title = ({ main, small }) => (
  <div className='inline-block truncate mr2'>
    <small className='block muted'>{small}</small>
    <h4 title={main} className='m0 truncate'>
      {main}
    </h4>
  </div>
)

Title.propTypes = {
  small: PropTypes.string.isRequired,
  main: PropTypes.string.isRequired
}

export default Title
