import React from "react"
import PropTypes from "prop-types"

const Address = ({ address }) =>
  address ? (
    <div>
      <strong>{address.organization}</strong>
      <div>{address.address_line1}</div>
      {address.address_line2 && <div>{address.address_line2},</div>}
      <div>
        {address.city}, {address.state_or_province_code && <span>{address.state_or_province_code}, </span>}
        {address.postal_code}
      </div>
      <div>{address.country_code}</div>
    </div>
  ) : null

Address.propTypes = {
  address: PropTypes.object.isRequired
}

export default Address
