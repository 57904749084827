import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateNewReviewers } from "@api/preview/send"
import { Checkbox } from "@bitcine/cs-theme"
import 'styled-components/macro'

const Message = ({ message, updateNewReviewers }) => {
  const [hasCustomMessage, toggleCustomMessage] = useState(false)
  return (
    <div className='py2 border-bottom border-gray-5'>
      <div className='flex items-center'>
        <Checkbox checked={hasCustomMessage} onChange={e => toggleCustomMessage(!hasCustomMessage)}/>
        <div className='pl1'>
          <h4>Add Custom Email Message</h4>
          <p className='mb0'>Add a message in the email sent to reviewers.</p>
        </div>
      </div>
      {hasCustomMessage && (
        <div
          className='mt1'
          css={`
            padding-left: 2.8em;
          `}>
          <label className='cs-label' htmlFor='message'>
            Message
          </label>
          <textarea
            value={message}
            id='message'
            className='cs-textarea col-12 block'
            placeholder='This message will be sent with the email to your recipients.'
            onChange={e => updateNewReviewers("email", "message", e.target.value)}/>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  message: state.preview.send.email.message
})

const mapDispatchToProps = dispatch => ({
  updateNewReviewers: bindActionCreators(updateNewReviewers, dispatch)
})

Message.propTypes = {
  updateNewReviewers: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Message)
