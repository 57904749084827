import { DISMISS_TOUR_STEP, SET_TOUR_STEPS, START_TOUR, TRIGGER_TOUR_STEP } from "@src/redux/actions/tour"
import { POST_DEFAULTS } from "@constants"

export const setTourSteps = steps => dispatch =>
  dispatch({
    type: SET_TOUR_STEPS,
    steps
  })

export const startTour = () => dispatch =>
  dispatch({
    type: START_TOUR
  })

export const dismissStep = key => (dispatch, getState) => {
  addStepToViewed(key, getState().user.auth)
  return dispatch({ type: DISMISS_TOUR_STEP, key })
}

export const triggerTourStep = (key, force = false) => (dispatch, getState) => {
  if (force || !getViewedSteps(getState().user.auth).includes(key)) {
    dispatch({
      type: TRIGGER_TOUR_STEP,
      key
    })
  }
}

const getViewedSteps = user => {
  if (!localStorage.getItem("cinesend:viewed_tour_steps")) {
    localStorage.setItem("cinesend:viewed_tour_steps", JSON.stringify([]))
  }

  return [...JSON.parse(localStorage.getItem("cinesend:viewed_tour_steps")), ...user.viewed_tour_steps]
}

const addStepToViewed = (step, user) => {
  const viewedSteps = getViewedSteps(user)
  viewedSteps.push(step)
  localStorage.setItem("cinesend:viewed_tour_steps", JSON.stringify(viewedSteps))

  // Persist to the database
  fetch(`users/${user._id}/tour-steps/add`, {
    ...POST_DEFAULTS,
    body: JSON.stringify({ step })
  })
}
