import React from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import DateTime from "./datetime"

const ReviewerExpiry = ({ expiry }) => (
  <span className='caption'>
    {expiry ? (
      !dayjs(expiry).isAfter() ? (
        <span className='red'>Expired</span>
      ) : (
        <DateTime date={expiry}/>
      )
    ) : (
      <span className='muted'>No Expiry</span>
    )}
  </span>
)

ReviewerExpiry.propTypes = {
  expiry: PropTypes.string
}

export default ReviewerExpiry
