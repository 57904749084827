import React from "react"
import ErrorBoundary from "@components/error_boundaries/"
import Video from "./video"

const RequestWrap = props => (
  <ErrorBoundary>
    <Video {...props}/>
  </ErrorBoundary>
)

export default RequestWrap
