import React, { Component } from "react"
import PropTypes from "prop-types"
import { AsyncCreatable } from "react-select"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import uniqBy from "lodash.uniqby"
import { Tag, Status } from "@bitcine/cs-theme"
import { saveTags, getRecentTags } from "@api/project/files/tags"
import { DEFAULTS } from "@constants"
import { GLOBALS } from "@src/config"

class Tagger extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: null
    }

    this.searchTags = this.searchTags.bind(this)
  }
  componentDidMount() {
    this.props.getRecentTags(this.props.organizationId)
  }
  searchTags(input) {
    if (!input) return Promise.resolve({ options: [] })
    return fetch(
      `${GLOBALS.API_URL}/tags?q=${input}${this.props.organizationId ? "&organization_id=" + this.props.organizationId : ""}`,
      DEFAULTS
    )
      .then(res => res.json())
      .then(options => ({ options }))
  }
  render() {
    const { files, tags, saveTags, recentTags, organizationId, recentStatus } = this.props
    const recent = recentTags.filter(recentTag => tags.filter(tag => tag._id === recentTag._id).length === 0)
    return (
      <div className='p3' style={{ width: "420px" }}>
        <div className='mb2'>
          <h4>Tags</h4>
          <AsyncCreatable
            multi={true}
            value={tags}
            placeholder='Search or add tags...'
            onChange={value => saveTags(files, value, organizationId)}
            backspaceRemoves={true}
            loadOptions={this.searchTags}
            valueKey='name'
            labelKey='name'/>
          {files.length > 1 && <small>The above tags will be synced across all selected files</small>}
        </div>
        <h4>Recent Tags</h4>
        <Status
          pending={recentStatus === "PENDING"}
          error={recentStatus === "ERROR"}
          height={150}
          empty={recent.length === 0}
          emptyMessage={{
            icon: "label",
            title: "No Tags"
          }}>
          <div className='flex items-center flex-wrap mt1'>
            {recent.map(tag => (
              <Tag
                key={tag._id}
                label={tag.name}
                icon='add'
                onClick={() => saveTags(files, [...tags, tag], organizationId)}/>
            ))}
          </div>
        </Status>
      </div>
    )
  }
}

Tagger.propTypes = {
  files: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  saveTags: PropTypes.func.isRequired,
  getRecentTags: PropTypes.func.isRequired,
  recentTags: PropTypes.array.isRequired,
  organizationId: PropTypes.string,
  recentStatus: PropTypes.string.isRequired
}

const mapStateToProps = (state, ownProps) => {
  // const checkedFiles = state.project.files.items.list.filter(file => {
  // 	if (state.project.files.modals.tag.visible) {
  // 		return file._id === state.project.files.modals.tag.files[0]._id
  // 	} else if (state.project.files.modals.info.visible) {
  // 		return file._id === state.project.files.modals.info.fileID
  // 	} else {
  // 		return file.checked
  // 	}
  // })

  const tags = []

  ownProps.files.forEach(file => {
    file.tags.forEach(tag => {
      tags.push(tag)
    })
  })

  return {
    tags: uniqBy(tags, tag => tag._id),
    recentTags: state.project.files.tags.recent,
    recentStatus: state.project.files.tags.recentStatus,
    organizationId: state.user.auth.organization_id
  }
}

const mapDispatchToProps = dispatch => ({
  saveTags: bindActionCreators(saveTags, dispatch),
  getRecentTags: bindActionCreators(getRecentTags, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tagger)
