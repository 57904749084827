import React from "react"
import PropTypes from "prop-types"
import "./styles.scss"

const Search = ({ visible, disabled, onChange, onCancel, onOpen, placeholder = "Search Files", value }) => (
  <div className={`${disabled ? "display-none" : ""}`}>
    {visible ? (
      <div className='relative'>
        <input
          value={value}
          autoFocus
          className={`cs-input col-12 ${disabled ? "display-none" : ""}`}
          placeholder={placeholder || "Search..."}
          onChange={onChange}/>
        <button
          onClick={onCancel}
          data-tooltip='Cancel Search'
          className='cs-button link material-icons absolute right-0 top-0 mr2 tooltip'>
          <span className='light'>close</span>
        </button>
      </div>
    ) : (
      <button
        onClick={onOpen}
        data-tooltip={placeholder}
        className={`cs-button link material-icons tooltip actionButton`}>
        search
      </button>
    )}
  </div>
)

Search.propTypes = {
  visible: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string
}

export default Search
