import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal } from "@bitcine/cs-theme"
import { toggleCreateRoomModal, updateNewScreenerRoom, createScreenerRoom } from "@api/screener_rooms/"

const CreateScreenerRoom = ({
  toggleCreateRoomModal,
  newScreenerRoom,
  updateNewScreenerRoom,
  createScreenerRoom,
  organizationId
}) => (
  <Modal
    open={true}
    onClose={toggleCreateRoomModal}
    showConfirmationOnClose={newScreenerRoom.length > 0}
    clickOutsideCloses={newScreenerRoom.length === 0}
    title='Create Screener Room'>
    <div>
      <input
        type='text'
        className='cs-input col-12 mb2'
        placeholder='Screener room name...'
        value={newScreenerRoom}
        onChange={({ target }) => updateNewScreenerRoom(target.value)}
        data-testid='screener_rooms_create_input'/>
      <div className='right-align mt3'>
        <button
          className='cs-button'
          disabled={!newScreenerRoom.length}
          onClick={() => createScreenerRoom(organizationId, newScreenerRoom)}
          data-testid='screener_rooms_save_room_btn'>
          Create
        </button>
      </div>
    </div>
  </Modal>
)

CreateScreenerRoom.propTypes = {
  organizationId: PropTypes.string.isRequired,
  updateNewScreenerRoom: PropTypes.func.isRequired,
  toggleCreateRoomModal: PropTypes.func.isRequired,
  newScreenerRoom: PropTypes.string.isRequired,
  createScreenerRoom: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  newScreenerRoom: state.screener_rooms.newScreenerRoom,
  organizationId: state.user.auth.organization_id
})

const mapDispatchToProps = dispatch => ({
  updateNewScreenerRoom: bindActionCreators(updateNewScreenerRoom, dispatch),
  toggleCreateRoomModal: bindActionCreators(toggleCreateRoomModal, dispatch),
  createScreenerRoom: bindActionCreators(createScreenerRoom, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateScreenerRoom)
