import { GET_PACKAGES, UPDATE_PACKAGE, TOGGLE_UPDATE_MODAL } from "@actions/project/packages"
import { addGlobalMessage } from "@api/utils/"
import { get, put } from "@api/fetch"

export const getPackages = (fileID = null) => (dispatch, getState) => {
  let route = `projects/${getState().project.details._id}/packages`
  if (fileID) route += `?fileID=${fileID}`
  dispatch(get(GET_PACKAGES.REQUEST, route))
}

export const updatePackage = (packageID, key, value) => (dispatch, getState) =>
  dispatch(
    put(
      UPDATE_PACKAGE.REQUEST,
      `projects/${getState().project.details._id}/packages/${packageID}`,
      { key, value },
      () => dispatch(addGlobalMessage("Package updated!", "success"))
    )
  )

export const toggleModal = (modal = null, selectedPackage = null) => dispatch => {
  dispatch({ type: TOGGLE_UPDATE_MODAL, modal, selectedPackage })
}
