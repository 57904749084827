import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateNewReviewers } from "@api/preview/send"
import Watermarking from "../shared/watermarking"
import Password from "../shared/password"
import Notification from "../shared/notification"
import SingleIPProtection from "../shared/single_ip_protection"
import Geoblocking from "../shared/geoblocking"
import DateRestrictions from "../shared/date_restrictions"
import ViewRestrictions from "../shared/view_restrictions"
import ForensicWatermark from "../shared/forensic_watermark"
import Message from "./message"
import SharingOptionsFooter from "./sharing_footer"
import EmailsFooter from "./emails_footer"
import AddReviewer from "./add_reviewer"
import Reviewers from "./reviewers"
import Commenting from "../shared/commenting"

class Email extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  checkIfNextIsDisabled = newReviewers => {
    return (
      Object.keys(newReviewers.list).filter(key => !newReviewers.list[key].email || !newReviewers.list[key].full_name)
        .length > 0
    )
  }
  render() {
    const { newReviewers, updateNewReviewers, canUseForensicWatermark, checked } = this.props
    const isNextDisabled = this.checkIfNextIsDisabled(newReviewers)
    if (newReviewers.step === "emails") {
      return (
        <React.Fragment>
          <div className='py2'>
            <p>Add name and email addresses to share your preview links.</p>
            <Reviewers/>
            <AddReviewer/>
            <EmailsFooter isNextDisabled={isNextDisabled}/>
          </div>
        </React.Fragment>
      )
    } else
      return (
        <React.Fragment>
          <DateRestrictions stateKey='email' newReviewers={newReviewers}/>
          <Password stateKey='email'/>
          <ViewRestrictions stateKey='email' newReviewers={newReviewers}/>
          <SingleIPProtection
            onChange={e => updateNewReviewers("email", "ip_protection_enabled", !newReviewers.ip_protection_enabled)}
            checked={newReviewers.ip_protection_enabled}/>
          <Watermarking
            newReviewers={newReviewers}
            onUpdate={(key, value) => updateNewReviewers("email", key, value)}/>
          {canUseForensicWatermark && (
            <ForensicWatermark
              newReviewers={newReviewers}
              onUpdate={(key, value) => updateNewReviewers("email", key, value)}/>
          )}
          <Notification
            onChange={e => updateNewReviewers("email", "send_email_notification", !checked)}
            checked={checked}/>
          <Message/>
          <Geoblocking newReviewers={newReviewers} onUpdate={(key, value) => updateNewReviewers("email", key, value)}/>
          <Commenting 
            onChange={e => updateNewReviewers("email", "commenting_enabled", !newReviewers.commenting_enabled)}
            checked={newReviewers.commenting_enabled}/>
          <SharingOptionsFooter/>
        </React.Fragment>
      )
  }
}

const mapStateToProps = state => ({
  newReviewers: state.preview.send.email,
  checked: state.preview.send.email.send_email_notification,
  canUseForensicWatermark: state.preview.send.can_use_forensic_watermark
})

const mapDispatchToProps = dispatch => ({
  updateNewReviewers: bindActionCreators(updateNewReviewers, dispatch)
})

Email.propTypes = {
  newReviewers: PropTypes.object.isRequired,
  updateNewReviewers: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  canUseForensicWatermark: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Email)
