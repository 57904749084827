import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getDrives, nextStep } from "@api/project/orders"
import "../styles.scss"
import DriveSelection from "../drives/selection"

class Drive extends React.Component {
  componentDidMount() {
    const { file } = this.props
    this.props.getDrives(file._id, file.type)
  }
  render() {
    const { nextStep, file, order } = this.props
    return (
      <div className='col-12'>
        <div className={"orderInnerContainer"}>
          <p>Purchase or rent hard drives for {file.name}.</p>
          <DriveSelection/>
        </div>
        <div className='flex justify-end col-12 pt3'>
          <button className='cs-button' disabled={!order.drive_type} onClick={nextStep}>
            Add to Cart
          </button>
        </div>
      </div>
    )
  }
}

Drive.propTypes = {
  nextStep: PropTypes.func.isRequired,
  getDrives: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  const order = state.orders.active_order
  return {
    file: order.video_file,
    order
  }
}

const mapDispatchToProps = dispatch => ({
  nextStep: bindActionCreators(nextStep, dispatch),
  getDrives: bindActionCreators(getDrives, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Drive)
