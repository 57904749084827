import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateFiles } from "@api/project/files/"
import { updateNewFolder } from "@api/project/folders/"
import { initFolderUpload } from "@api/project/folder_upload/"
import { initializeHttpUpload } from "@api/transfer/http/upload"
import { initializeAsperaUpload } from "@api/transfer/aspera/upload"
import { toggleOrderVisibility } from "@api/project/orders"
import { can, MAKE_PAYMENTS, MODIFY_PROJECTS } from "@src/helpers/authorization"
import { push } from "connected-react-router"
import { ButtonDropdown } from "@bitcine/cs-theme"
import Dropzone from "react-dropzone"
import AsperaLogo from "@src/public/aspera.png"
import sortFiles from "@helpers/file_sorter"
import TourTip from "@components/tour/tip"
import OverLimitPopOver from "@components/project/files/components/over_limit_pop_over"
import { ReactComponent as DropboxIcon } from "@src/public/icons/dropbox.svg"
import { ReactComponent as GoogleDriveIcon } from "@src/public/icons/google-drive.svg"
import UploadDCPIcon from "@src/public/icons/material/upload_dcp.inline.svg"
import UploadFileIcon from "@src/public/icons/material/upload_file.inline.svg"
import UploadFolderIcon from "@src/public/icons/material/upload_folder.inline.svg"
import ImportModal from "../../../../import-modal"
import 'styled-components/macro'

class New extends React.Component {
  constructor(props) {
    super(props)
    this.fileDropzone = {}
    ;(this.folderDropzone = {}),
    (this.state = {
      folder_type: "dcp",
      import_dropbox_modal: false,
      import_google_modal: false
    })
  }
  onDropFiles(files) {
    sortFiles(files).map(file =>
      this.props.initializeHttpUpload(file, {
        project_id: this.props.projectID,
        folder_id: this.props.folderID
      })
    )
  }
  onDropFolder(files) {
    if (this.state.folder_type === "dcp") {
      this.props.initializeHttpUpload(files, {
        is_dcp: true,
        project_id: this.props.projectID,
        folder_id: this.props.folderID
      })
    } else if (this.state.folder_type === "directory") {
      this.props.initFolderUpload(files, this.props.projectID, this.props.folderID)
    }
  }
  onStartAsperaTransfer(isFolderTransfer) {
    this.props.initializeAsperaUpload({
      is_dcp: isFolderTransfer,
      folder_id: this.props.folderID,
      project_id: this.props.projectID
    })
  }
  startOrder(type) {
    this.props.toggleOrderVisibility(type)
  }
  startImportDropbox() {
    this.setState({ ...this.state, import_dropbox_modal: true })
  }
  endDropboxImport() {
    this.setState({ ...this.state, import_dropbox_modal: false })
  }
  startImportGoogle() {
    this.setState({ ...this.state, import_google_modal: true })
  }
  endGoogleImport() {
    this.setState({ ...this.state, import_google_modal: false })
  }
  render() {
    return (
      <span>
        <TourTip tipId='upload_files'>
          <OverLimitPopOver isOverLimit={this.props.isOverLimit}>
            <ButtonDropdown
              button={{
                className: "ml1 small",
                text: (
                  <div className='flex items-center'>
                    <span style={{ fontSize: "16px" }} className='material-icons mr1'>
                      add_circle
                    </span>
                    New
                  </div>
                )
              }}
              dropdown={{
                clickCloses: true,
                arrow: true,
                content: [
                  {
                    text: "Upload file",
                    icon: (
                      <UploadFileIcon
                        css={`
                          width: 22px;
                        `}/>
                    ),
                    children: [
                      {
                        disabled: this.props.isOverLimit,
                        text: {
                          title: "Upload file",
                          description: "Standard upload speeds"
                        },
                        icon: "file_upload",
                        onClick: () => this.fileDropzone.open()
                      },
                      {
                        disabled: this.props.isOverLimit,
                        text: {
                          title: "Upload file with Aspera Connect",
                          description: "Faster, more reliable uploads with Aspera Connect"
                        },
                        icon: (
                          <img
                            className='cs-img'
                            src={AsperaLogo}
                            css={`
                              width: 20px;
                              height: 20px;
                              filter: grayscale(100%);
                            `}/>
                        ),
                        onClick: () => this.onStartAsperaTransfer(false)
                      }
                    ],
                    show: this.props.canEdit
                  },
                  {
                    disabled: this.props.isOverLimit,
                    text: "Upload folder",
                    icon: (
                      <UploadFolderIcon
                        css={`
                          width: 22px;
                        `}/>
                    ),
                    onClick: () => {
                      this.setState({ folder_type: "directory" }, () => {
                        this.folderDropzone.open()
                      })
                    },
                    show: this.props.canEdit
                  },
                  {
                    text: "Upload DCP",
                    icon: (
                      <UploadDCPIcon
                        css={`
                          width: 22px;
                        `}/>
                    ),
                    breakAfter: true,
                    children: [
                      {
                        disabled: this.props.isOverLimit,
                        text: {
                          title: "Upload DCP",
                          description: "Standard upload speeds"
                        },
                        icon: "file_upload",
                        onClick: () => {
                          this.setState({ folder_type: "dcp" }, () => {
                            this.folderDropzone.open()
                          })
                        }
                      },
                      {
                        disabled: this.props.isOverLimit,
                        text: {
                          title: "Upload DCP with Aspera Connect",
                          description: "Faster, more reliable uploads with Aspera Connect"
                        },
                        icon: (
                          <img
                            className='cs-img'
                            src={AsperaLogo}
                            css={`
                              width: 20px;
                              height: 20px;
                              filter: grayscale(100%);
                            `}/>
                        ),
                        onClick: () => this.onStartAsperaTransfer(true)
                      }
                    ],
                    show: this.props.canEdit
                  },
                  {
                    text: "New folder",
                    icon: "create_new_folder",
                    onClick: () => this.props.updateNewFolder("show", true),
                    show: this.props.canEdit
                  },
                  {
                    text: "New order",
                    icon: "add_shopping_cart",
                    show: this.props.canOrder,
                    children: [
                      {
                        text: "Order DCP",
                        icon: "movie",
                        onClick: () => this.startOrder("dcp"),
                        show: true
                      },
                      {
                        text: "Order closed captioning",
                        icon: "closed_caption",
                        onClick: () => this.startOrder("closed-captions"),
                        show: true
                      },
                      {
                        text: "Order format conversion",
                        icon: "swap_horizontal_circle",
                        onClick: () => this.startOrder("conversion"),
                        show: this.props.canOrder
                      },
                      {
                        text: "Order quality inspection",
                        icon: "verified_user",
                        onClick: () => this.startOrder("inspection"),
                        show: this.props.canOrder
                      },
                      {
                        text: "Order Blu-ray / DVD",
                        icon: "album",
                        onClick: () => this.startOrder("disc"),
                        show: true
                      }
                    ].filter(item => item.show)
                  },
                  {
                    text: "Import from...",
                    icon: "upload",
                    show: this.props.canEdit,
                    children: [
                      {
                        text: "Dropbox",
                        icon: (
                          <DropboxIcon
                            css={`
                              width: 20px;
                              height: 20px;
                              filter: grayscale(100%);
                            `}/>
                        ),
                        onClick: () => this.startImportDropbox(),
                        show: true
                      },
                      {
                        text: "Google Drive",
                        icon: (
                          <GoogleDriveIcon
                            css={`
                              width: 20px;
                              height: 20px;
                              filter: grayscale(100%);
                            `}/>
                        ),
                        onClick: () => this.startImportGoogle(),
                        show: true
                      }
                    ]
                  }
                ].filter(item => item.show)
              }}/>
          </OverLimitPopOver>
        </TourTip>
        <ImportModal
          importType='dropbox'
          active={this.state.import_dropbox_modal}
          showStatus={true}
          closeImportModal={this.endDropboxImport.bind(this)}
          folderID={this.props.folderID}
          projectID={this.props.projectID}/>
        <ImportModal
          importType='google_drive'
          active={this.state.import_google_modal}
          showStatus={true}
          closeImportModal={this.endGoogleImport.bind(this)}
          folderID={this.props.folderID}
          projectID={this.props.projectID}/>

        <Dropzone
          className='dropzone-'
          multiple={true}
          ref={ref => (this.fileDropzone = ref)}
          onDrop={(accepted, rejected) => this.onDropFiles(accepted)}/>
        <Dropzone
          className='dropzone-'
          multiple={true}
          inputProps={{
            webkitdirectory: "true",
            mozdirectory: "true",
            odirectory: "true",
            msdirectory: "true",
            directory: "true"
          }}
          ref={ref => (this.folderDropzone = ref)}
          onDrop={(accepted, rejected) => this.onDropFolder(accepted)}/>
      </span>
    )
  }
}

New.propTypes = {
  projectID: PropTypes.string.isRequired,
  updateFiles: PropTypes.func.isRequired,
  files: PropTypes.object.isRequired,
  updateNewFolder: PropTypes.func.isRequired,
  folderID: PropTypes.string,
  initializeHttpUpload: PropTypes.func.isRequired,
  initializeAsperaUpload: PropTypes.func.isRequired,
  toggleOrderVisibility: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  canOrder: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  initFolderUpload: PropTypes.func.isRequired,
  isOverLimit: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  files: state.project.files.items,
  canOrder: can(state.user.auth, [MAKE_PAYMENTS]),
  canEdit: can(state.user.auth, [MODIFY_PROJECTS]),
  user: state.user.auth,
  isOverLimit: state.user.auth.subscription.storage.is_over_storage_limit
})

const mapDispatchToProps = dispatch => ({
  updateFiles: bindActionCreators(updateFiles, dispatch),
  updateNewFolder: bindActionCreators(updateNewFolder, dispatch),
  initializeHttpUpload: bindActionCreators(initializeHttpUpload, dispatch),
  initializeAsperaUpload: bindActionCreators(initializeAsperaUpload, dispatch),
  toggleOrderVisibility: bindActionCreators(toggleOrderVisibility, dispatch),
  push: bindActionCreators(push, dispatch),
  initFolderUpload: bindActionCreators(initFolderUpload, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(New)
