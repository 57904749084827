import React from "react"
import PropTypes from "prop-types"
import "./styles.scss"

const Comment = ({ enabled }) => (
  <div
  className={`icon comment
      ${enabled ? "commentingenabled" : ""}
      relative circle flex items-center justify-center box-shadow`}>
  <span className='material-icons white' style={{ fontSize: "14px" }}>
    comment
  </span>
</div>
)

Comment.propTypes = {
  enabled: PropTypes.bool.isRequired
}

export default Comment
