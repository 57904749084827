import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toggleNewReviewers } from "@api/preview/send"
import { ButtonDropdown } from "@bitcine/cs-theme"
import { canShareScreenerRoom } from "@src/helpers/authorization"

class ReviewerButtons extends React.Component {
  render() {
    let { canShareScreenerRoom, preview, toggleNewReviewers, room } = this.props
    return (
      <div>
        <button
          className='cs-button small ml1 white'
          onClick={e => window.open(preview.data.url, "_blank")}
          disabled={preview.status !== "COMPLETE"}>
          Preview Room
        </button>
        {canShareScreenerRoom && (
          <ButtonDropdown
            button={{
              className: "ml1 small",
              text: "Share Screener Room"
            }}
            dropdown={{
              clickCloses: true,
              content: [
                {
                  text: {
                    title: "Share by email",
                    description: "Send an email containing a link to reviewers"
                  },
                  onClick: () => toggleNewReviewers("email", "screenerRoom", room),
                  icon: "email"
                },
                {
                  text: {
                    title: "Create a link",
                    description: "Create a link that can be copied and sent to reviewers"
                  },
                  onClick: () => toggleNewReviewers("link", "screenerRoom", room),
                  icon: "link"
                }
              ]
            }}/>
        )}
      </div>
    )
  }
}

ReviewerButtons.propTypes = {
  screenerGroupID: PropTypes.string.isRequired,
  toggleNewReviewers: PropTypes.func.isRequired,
  preview: PropTypes.object.isRequired,
  canShareScreenerRoom: PropTypes.bool.isRequired,
  room: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  screenerGroupID: state.screener_rooms.screenerRoom.data._id,
  preview: state.screener_rooms.preview,
  room: state.screener_rooms.screenerRoom.data,
  canShareScreenerRoom: canShareScreenerRoom(state.user.auth, state.screener_rooms.screenerRoom.data)
})

const mapDispatchToProps = dispatch => ({
  toggleNewReviewers: bindActionCreators(toggleNewReviewers, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewerButtons)
