import { INIT_DEFROST } from "@actions/project/"
import { post } from "@api/fetch"

export const defrost = file => dispatch =>
  dispatch(
    post(INIT_DEFROST, `defroster`, {
      id: file._id,
      expedited: false
    })
  )
