import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import ScreenerRoom from "@components/screener_rooms/screener_room/"
import Projects from "@components/screener_rooms/screener_room/pages/projects"
import Reviewers from "@components/screener_rooms/screener_room/pages/reviewers"

const ScreenerRoomRoutes = props => {
  const { path } = useRouteMatch()
  return (
    <ScreenerRoom {...props}>
      <Switch>
        <Redirect exact from={path} to={`${path}/screeners`}/>
        <Route path={`${path}/screeners`} component={Projects}/>
        <Route path={`${path}/reviewers`} component={Reviewers}/>
      </Switch>
    </ScreenerRoom>
  )
}

export default ScreenerRoomRoutes
