import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { updateVideo, initDCPRequest } from "@api/ondemand_videos/"
import { Button } from "@bitcine/cs-theme"
import Dropzone from "react-dropzone"
import { initializeAsperaUpload } from "@api/transfer/aspera/upload"
import { initializeHttpUpload } from "@api/transfer/http/upload"
import { getFileRequest } from "@api/file_request"
import Transfers from "../../file_request/transfers"
import Files from "./files"
import Card from "./card"

const DCPUpload = ({
  video,
  files,
  transfers,
  updateVideo,
  getFileRequest,
  initializeAsperaUpload,
  initializeHttpUpload,
  initDCPRequest,
  pending
}) => {
  useEffect(() => {
    getFileRequest(video.cinerequest_id, "/cinerequest/" + video.cinerequest_id)
  }, [])
  const [folderDropzone, setFolderDropzone] = useState(false)
  return (
    <Card
      title='DCP Upload - In-Cinema Presentation'
      isUpload={true}
      className='mr2'
      submitted={video.is_dcp_submitted}
      submitButtonDisabled={!files.length > 0}
      onSubmit={() => {
        updateVideo(video._id, { is_dcp_submitted: true })
      }}
      isSubmitting={pending}>
      <>
        {!video.cinerequest_id && (
          <>
            <Button
              className='cs-button outline small'
              onClick={() => {
                if (!video.cinerequest_id) {
                  initDCPRequest(video._id)
                }
              }}>
              Include DCP
            </Button>
          </>
        )}
        {!video.is_dcp_submitted && video.cinerequest_id && (
          <>
            <p>You have been requested to upload a DCP.</p>
            {Object.keys(transfers).length === 0 && (
              <>
                <div className='flex flex-column' style={{ gap: "0.5em" }}>
                  <Button
                    className={`cs-button col-12 outline small nowrap`}
                    onClick={() => {
                      let spec = { is_dcp: true, cinerequest_id: video.cinerequest_id }
                      initializeAsperaUpload(spec)
                    }}>
                    Upload with Aspera
                  </Button>
                  <Button
                    className={`cs-button col-12 outline small nowrap`}
                    onClick={() => {
                      folderDropzone.open()
                    }}>
                    Upload with your browser
                  </Button>
                </div>
                <Dropzone
                  className='dropzone-'
                  multiple={true}
                  inputProps={{
                    webkitdirectory: "true",
                    mozdirectory: "true",
                    odirectory: "true",
                    msdirectory: "true",
                    directory: "true"
                  }}
                  ref={ref => setFolderDropzone(ref)}
                  onDrop={(accepted, rejected) => {
                    let spec = { is_dcp: true, cinerequest_id: video.cinerequest_id }
                    initializeHttpUpload(accepted, spec)
                  }}/>
              </>
            )}
          </>
        )}
        <Transfers/>
        <Files files={files}/>
      </>
    </Card>
  )
}

const mapStateToProps = state => ({
  video: state.ondemand_videos.video,
  files: state.file_request.files,
  pending: state.file_request.status === 'PENDING',
  transfers: state.transfers,
})

const mapDispatchToProps = dispatch => ({
  initializeAsperaUpload: bindActionCreators(initializeAsperaUpload, dispatch),
  initializeHttpUpload: bindActionCreators(initializeHttpUpload, dispatch),
  updateVideo: bindActionCreators(updateVideo, dispatch),
  getFileRequest: bindActionCreators(getFileRequest, dispatch),
  initDCPRequest: bindActionCreators(initDCPRequest, dispatch)
})

DCPUpload.propTypes = {
  video: PropTypes.object.isRequired,
  files: PropTypes.array,
  transfers: PropTypes.object,
  initializeAsperaUpload: PropTypes.func,
  initializeHttpUpload: PropTypes.func,
  updateVideo: PropTypes.func.isRequired,
  getFileRequest: PropTypes.func.isRequired,
  initDCPRequest: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(DCPUpload)
