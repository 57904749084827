import React from "react"
import PropTypes from "prop-types"
import { DropTarget } from "react-dnd"
import flow from "lodash.flow"
import { args, dragTarget, fileTarget } from "../constants"

class Drop extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { connectDropTarget, requiredFile, canDrop, isOver } = this.props
    return connectDropTarget(
      <div
        style={{
          height: args[requiredFile.type].tall ? "140px" : "100px"
        }}
        className={`mb1 relative bg-gray-5 border border-dashed items-center flex rounded border-width-3
        ${
  isOver ? (canDrop ? "border-green" : "border-red not-allowed") : "border-gray-4"
} justify-center center muted`}>
        <div>
          <span className='block material-icons'>{args[requiredFile.type].icon}</span>
          <strong>{args[requiredFile.type].text}</strong>
        </div>
        {isOver && (
          <small className={`absolute ${canDrop ? "green" : "red"} bottom-0 right-0 mr1 mb0 bold`}>
            {canDrop
              ? `This file can be used`
              : `Only ${requiredFile.acceptedCineSendFileTypes.map((f, i) => `${filesFormat[f]}`)} are accepted.`}
          </small>
        )}
      </div>
    )
  }
}

const filesFormat = {
  source_file: "source files",
  audio_track: "audio files",
  closed_caption: "closed caption files",
  subtitle_track: "subtitle files",
  dcp: "DCP files",
  disc_output: "discs"
}

Drop.propTypes = {
  updateOrder: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  requiredFile: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  setActiveError: PropTypes.func.isRequired,
  canDrop: PropTypes.bool,
  isOver: PropTypes.bool
}

export default flow(DropTarget("FILE", fileTarget, dragTarget))(Drop)
