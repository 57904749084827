import React from "react"
import PropTypes from "prop-types"
import DateTime from "./datetime"

const LastViewed = ({ sharedScreener }) => (
  <div className='caption'>
    {sharedScreener.last_viewed_at ? <DateTime date={sharedScreener.last_viewed_at}/> : "N/A"}
  </div>
)

LastViewed.propTypes = {
  sharedScreener: PropTypes.object.isRequired
}

export default LastViewed
