import React from "react"
import PropTypes from "prop-types"
import Wrap from "./wrap"

class Track extends React.Component {
  render() {
    const { file, type, title } = this.props
    return (
      <Wrap title={title} defaultOpen={false}>
        <div>
          {file[type].map((stream, index) => (
            <div className='mb1' key={index}>
              {file[type].length > 1 && <strong className='block'>Stream {index + 1}</strong>}
              {Object.keys(stream)
                .filter(key => typeof stream[key] === "string")
                .map(key => (
                  <div key={key} className='flex items-center mb1'>
                    <div className='col-6 capitalize'>{key.split("_").join(" ")}:</div>
                    <div className='col-6 bold'>{stream[key] || <span className='muted'>N/A</span>}</div>
                  </div>
                ))}
            </div>
          ))}
        </div>
      </Wrap>
    )
  }
}

Track.propTypes = {
  file: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["video_info", "audio_info"]).isRequired,
  title: PropTypes.string.isRequired
}

export default Track
