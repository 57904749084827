import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Members from "@components/project/members"
import { toggleModalVisibility } from "@api/project/files/modals"
import Wrap from "../components/secondary/wrap"
import Back from "../components/secondary/back"
import Title from "../components/secondary/title"
import Tabs from "../components/secondary/tabs"
import 'styled-components/macro'

const ProjectNav = ({ title, _id, projectLoading, canEdit, folder, infoFileOpen, toggleModalVisibility }) => (
  <Wrap>
    <React.Fragment>
      <Back
        to={folder ? `/projects/folder/${folder.slug}` : `/projects`}
        tooltip={folder ? `Back to ${folder.title}` : "Back to all projects"}/>
      <Title small='Project' main={title && title.length > 30 ? title.substring(0, 30) + "..." : title}/>
      <Tabs
        tabs={[
          { to: "/projects/" + _id + "/files", label: "Project Files", visible: true },
          { to: "/projects/" + _id + "/requests", label: "File Requests", visible: true },
          { to: "/projects/" + _id + "/details", label: "Details", visible: canEdit }
        ].filter(({ visible }) => visible)}/>
      <div className='flex flex-auto items-center justify-end'>
        <Members/>
      </div>
    </React.Fragment>
  </Wrap>
)

const mapStateToProps = state => ({
  infoFileOpen: state.project.files.modals.info.visible
})

const mapDispatchToProps = dispatch => ({
  toggleModalVisibility: bindActionCreators(toggleModalVisibility, dispatch)
})

ProjectNav.propTypes = {
  infoFileOpen: PropTypes.bool,
  toggleModalVisibility: PropTypes.func,
  _id: PropTypes.string,
  title: PropTypes.string,
  projectLoading: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  folder: PropTypes.any
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectNav)
