import React from "react"
import { Switch, Redirect, Route } from "react-router-dom"
import Cart from "@containers/cart"
import Shipping from "@components/cart/shipping"
import Payment from "@components/cart/payment"
import Confirmation from "@components/cart/confirmation"

const CartRoutes = props => 
  <Cart {...props}>
    <Switch>
      <Redirect exact from='/cart' to='/cart/shipping'/>
      <Route path='/cart/shipping' component={Shipping}/>
      <Route path='/cart/payment' component={Payment}/>
      <Route path='/cart/confirmation' component={Confirmation}/>
    </Switch>
  </Cart>

export default CartRoutes
