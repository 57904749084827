import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { prevStep, nextStep, updateOrder } from "@api/project/orders"
import dayjs from "dayjs"
import { Radio } from "@bitcine/cs-theme"
import "../styles.scss"

const args = [
  { key: "captions_per_minute_2_days", label: "Web Grade" },
  { key: "captions_per_minute_5_days", label: "Broadcast Grade" },
  { key: "captions_per_minute_10_days", label: "SVOD Grade" }
]

const Turnaround = ({ prevStep, nextStep, items, order, updateOrder, lengthInMinutes }) => (
  <div className='col-12'>
    {!items ? (
      <div className={"orderInnerContainer"}>
        <div className='center py2'>
          <div className='spinner'/>
          <small className='block'>Fetching Prices...</small>
        </div>
      </div>
    ) : (
      <div className={"orderInnerContainer"}>
        <p>
          Caption accuracy standards vary greatly between different uses. Select the intended use for your captions to
          make sure they adhere to accuracy requirements. SVODs include Netflix, iTunes, Hulu, etc.
        </p>
        {args.map((arg, i) => (
          <div key={arg.key} className={`flex items-center py2 ${i > 0 ? "border-top border-gray-4" : ""}`}>
            <Radio
              id={arg.key}
              checked={order.turnaround_time === items[arg.key].code}
              onChange={() => updateOrder("turnaround_time", items[arg.key].code)}/>
            <label htmlFor={arg.key} className='cs-label pl2 flex-auto'>
              <strong>{arg.label}</strong>
              <span className='block small muted'>
                Ready on {dayjs(items[arg.key].completed_date).format("dddd MMMM Do")}
              </span>
            </label>
            <strong>
              {items["captions_base"].currency_symbol}
              {(items["captions_base"].price + items[arg.key].price * parseFloat(lengthInMinutes)).toFixed(2)}
            </strong>
          </div>
        ))}
      </div>
    )}
    <div className='flex justify-between pt3 col-12'>
      <button className='cs-button white' onClick={prevStep}>
        Back
      </button>
      <button className='cs-button' disabled={!order.turnaround_time} onClick={nextStep}>
        Add to Cart
      </button>
    </div>
  </div>
)

Turnaround.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  items: PropTypes.object,
  order: PropTypes.object.isRequired,
  updateOrder: PropTypes.func.isRequired,
  lengthInMinutes: PropTypes.number.isRequired
}

const mapStateToProps = state => {
  const order = state.orders.active_order
  return {
    items: state.utils.captions_items,
    order,
    lengthInMinutes: order.video_file.length_in_minutes
  }
}

const mapDispatchToProps = dispatch => ({
  prevStep: bindActionCreators(prevStep, dispatch),
  nextStep: bindActionCreators(nextStep, dispatch),
  updateOrder: bindActionCreators(updateOrder, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Turnaround)
