import {
  GET_SCREENER_ROOMS,
  GET_SCREENER_ROOM_AVAILABLE_SCREENERS,
  GET_SCREENER_ROOM_AVAILABLE_PROJECTS,
  UPDATE_NEW_SCREENER_ROOM,
  TOGGLE_CREATE_ROOM_MODAL,
  CREATE_SCREENER_ROOM,
  GET_SCREENER_ROOM,
  ADD_SCREENERS_TO_ROOM,
  ADD_PROJECTS_TO_ROOM,
  GET_SCREENER_ANALYTICS,
  TOGGLE_ANALYTICS_MODAL,
  REMOVE_SCREENER_FROM_ROOM,
  REMOVE_PROJECT_FROM_ROOM,
  UPDATE_SCREENER_ROOM,
  //EXPIRE_ALL_VIEWERS,
  DELETE_SCREENER_ROOM,
  GENERATE_PREVIEW,
  TOGGLE_ADD_VIEWERS_MODAL,
  SET_SCREENER_ROOM,
  TOGGLE_SCREENER_ROOM_PERMISSIONS_MODAL,
  SAVE_SCREENER_GROUP_ACCESS,
  TOGGLE_SCREENER_GROUP_DELETE_MODAL,
  UPDATE_SCREENER,
  DUPLICATE_SCREENER_ROOM
} from "@actions/screener_rooms/"

import { get, post, put, del } from "@api/fetch"
import { push } from "connected-react-router"
//import moment from 'moment'
import { addGlobalMessage } from "@api/utils/"

/**
 * Get all of the screener rooms belonging to an organization
 *
 * @param  string  organizationId
 *
 * @return dispatch
 */
export const getScreenerRooms = (organizationId, take, skip, search) => dispatch =>
  dispatch(
    get(
      GET_SCREENER_ROOMS,
      `organizations/${organizationId}/screener-groups?take=${take}&skip=${skip}&search=${search}`
    )
  )

/**
 * Get a single screener room
 *
 * @param  string  organizationId
 * @param  string  screenerRoomId
 *
 * @return dispatch
 */
export const getScreenerRoom = (organizationId, screenerRoomId) => dispatch =>
  dispatch(get(GET_SCREENER_ROOM, `organizations/${organizationId}/screener-groups/${screenerRoomId}`))

/**
 * Get all of the projects that are not currently in the screener room
 *
 * @param  string  organizationId
 * @param  string  screenerRoomId
 *
 * @return dispatch
 */
export const getAvailableProjects = (organizationId, screenerRoomId) => dispatch =>
  dispatch(
    get(
      GET_SCREENER_ROOM_AVAILABLE_PROJECTS,
      `organizations/${organizationId}/screener-groups/${screenerRoomId}/available-projects`
    )
  )

/**
 * Get all of the screeners in a specified project that are not currently in the screener room
 *
 * @param  string  organizationId
 * @param  string  screenerRoomId
 * @param  string  projectId
 *
 * @return dispatch
 */
export const getAvailableScreeners = (organizationId, screenerRoomId, projectId) => dispatch =>
  dispatch(
    get(
      GET_SCREENER_ROOM_AVAILABLE_SCREENERS,
      `organizations/${organizationId}/screener-groups/${screenerRoomId}/projects/${projectId}/available-screeners`
    )
  )

/**
 * Updates the state of the text in the create screener input field
 *
 * @param  string  text
 *
 * @return dispatch
 */
export const updateNewScreenerRoom = text => dispatch => dispatch({ type: UPDATE_NEW_SCREENER_ROOM, payload: { text } })

/**
 * Toggles the shown/hidden state of the create room modal
 *
 * @return dispatch
 */
export const toggleCreateRoomModal = () => dispatch => dispatch({ type: TOGGLE_CREATE_ROOM_MODAL })

/**
 * Saves the new screener room and redirects to it
 *
 * @param  string  organizationId
 * @param  string  screenerGroupName
 *
 * @return dispatch
 */
export const createScreenerRoom = (organizationId, screenerGroupName) => dispatch =>
  dispatch(
    post(CREATE_SCREENER_ROOM, `organizations/${organizationId}/screener-groups`, { screenerGroupName }, res => {
      dispatch(push(`/screener-rooms/${res._id}`))
    })
  )

/**
 * Add projects to a screener room
 *
 * @param  string  organizationID
 * @param  string  screenerRoomID
 * @param  array   projectIDs
 *
 * @return dispatch
 */
export const addProjectsToRoom = (organizationID, screenerRoomID, projectIDs) => dispatch =>
  dispatch(
    put(
      ADD_PROJECTS_TO_ROOM,
      `organizations/${organizationID}/screener-groups/${screenerRoomID}/projects`,
      { projectIDs },
      res => {
        dispatch(addGlobalMessage(`Added project${projectIDs.length > 1 ? "s" : ""} to room!`, "success"))
      }
    )
  )

/**
 * Add screeners to a screener room
 *
 * @param  string  organizationID
 * @param  string  screenerRoomID
 * @param  string  projectID
 * @param  array   screenerIDs
 *
 * @return dispatch
 */
export const addScreenersToRoom = (
  organizationID,
  screenerRoomID,
  projectID,
  screenerIDs,
  notifyReviewers
) => dispatch =>
  dispatch(
    put(
      ADD_SCREENERS_TO_ROOM,
      `organizations/${organizationID}/screener-groups/${screenerRoomID}/projects/${projectID}/screeners`,
      { screenerIDs, notifyReviewers },
      res => {
        dispatch(addGlobalMessage(`Added screener${screenerIDs.length > 1 ? "s" : ""} to room!`, "success"))
      }
    )
  )

/**
 * Remove screener from room
 *
 * @param  string  organizationId
 * @param  string  screenerRoomId
 * @param  array   screenerId
 *
 * @return dispatch
 */
export const removeScreenerFromRoom = (
  organizationId,
  screenerRoomId,
  projectId,
  screenerId,
  notifyReviewers
) => dispatch =>
  dispatch(
    del(
      REMOVE_SCREENER_FROM_ROOM,
      `organizations/${organizationId}/screener-groups/${screenerRoomId}/projects/${projectId}/screeners/${screenerId}`,
      { notifyReviewers },
      res => {
        dispatch(addGlobalMessage("Removed screener from room!", "success"))
      }
    )
  )

/**
 * Remove project from room
 *
 * @param  string  organizationId
 * @param  string  screenerRoomId
 * @param  array   projectId
 *
 * @return dispatch
 */
export const removeProjectFromRoom = (organizationId, screenerRoomId, projectId, notifyReviewers) => dispatch =>
  dispatch(
    del(
      REMOVE_PROJECT_FROM_ROOM,
      `organizations/${organizationId}/screener-groups/${screenerRoomId}/projects/${projectId}`,
      { notifyReviewers },
      res => {
        dispatch(addGlobalMessage("Removed project from room!", "success"))
      }
    )
  )

/**
 * Update a screener room
 *
 * @param  string  organizationId
 * @param  string  screenerRoomId
 * @param  object  data | example: { key: 'name', value: 'New Screener Name' }
 *
 * @return dispatch
 */
export const updateScreenerRoom = (organizationId, screenerRoomId, data) => dispatch =>
  dispatch(put(UPDATE_SCREENER_ROOM, `organizations/${organizationId}/screener-groups/${screenerRoomId}`, data))

/**
 * Swap project positions in a screener room
 *
 * @param  string  organizationId
 * @param  string  screenerRoomId
 * @param  string  indexA
 * @param  string  indexB
 *
 * @return dispatch
 */
export const swapProjectPositions = (organizationId, screenerRoomId, indexA, indexB) => dispatch =>
  dispatch(
    put(UPDATE_SCREENER_ROOM, `organizations/${organizationId}/screener-groups/${screenerRoomId}/swap-projects`, {
      indexA,
      indexB
    })
  )

/**
 * Swap screener positions in a screener room
 *
 * @param  string  organizationId
 * @param  string  screenerRoomId
 * @param  string  projectId
 * @param  string  indexA
 * @param  string  indexB
 *
 * @return dispatch
 */
export const swapScreenerPositions = (organizationId, screenerRoomId, projectId, indexA, indexB) => dispatch =>
  dispatch(
    put(
      UPDATE_SCREENER_ROOM,
      `organizations/${organizationId}/screener-groups/${screenerRoomId}/projects/${projectId}/swap-screeners`,
      { indexA, indexB }
    )
  )

/**
 * Update a screener from the screener room
 *
 * @param  string  organizationId
 * @param  string  screenerRoomId
 * @param  string  screenerId
 * @param  object  data | example: { key: 'name', value: 'New Screener Name' }
 *
 * @return dispatch
 */
export const updateScreener = (organizationId, screenerRoomId, projectId, screenerId, data) => dispatch =>
  dispatch(
    put(
      UPDATE_SCREENER,
      `organizations/${organizationId}/screener-groups/${screenerRoomId}/projects/${projectId}/screeners/${screenerId}`,
      data,
      () => dispatch(addGlobalMessage("Screener updated!", "success"))
    )
  )

/**
 * Get the analytics for the screener
 *
 * @param  string  screenerRoomId
 * @param  string  screenerId
 *
 * @return dispatch
 */
export const getScreenerAnalytics = (screenerRoomId, screenerId) => dispatch =>
  dispatch(get(GET_SCREENER_ANALYTICS, `screener-groups/${screenerRoomId}/screener-analytics/${screenerId}`))

/**
 * Toggles the shown/hidden state of the analytics modal
 *
 * @return dispatch
 */
export const toggleAnalyticsModal = (screener = null) => dispatch =>
  dispatch({
    type: TOGGLE_ANALYTICS_MODAL,
    payload: { screenerId: screener ? screener._id : null }
  })

/**
 * Delete a screener room
 *
 * @param  string  organizationId
 * @param  string  screenerRoomId
 *
 * @return dispatch
 */
export const deleteScreenerRoom = (organizationId, screenerRoomId) => dispatch =>
  dispatch(
    del(DELETE_SCREENER_ROOM, `organizations/${organizationId}/screener-groups/${screenerRoomId}`, {}, () => {
      dispatch(push(`/screener-rooms`))
      dispatch(addGlobalMessage(`Screener room deleted.`, "success"))
    })
  )

/**
 * Generate the screener room preview
 *
 * @param  string  screenerRoomId
 *
 * @return dispatch
 */
export const generatePreview = screenerRoomId => dispatch =>
  dispatch(post(GENERATE_PREVIEW, `screener-groups/${screenerRoomId}/preview`))

/**
 * Toggle the shown / hidden state of modal
 *
 * @return dispatch
 */
export const toggleAddViewersModal = addType => dispatch => dispatch({ type: TOGGLE_ADD_VIEWERS_MODAL, addType })

export const setScreenerRoom = screenerRoom => dispatch =>
  dispatch({ type: SET_SCREENER_ROOM, payload: { screenerRoom } })

export const togglePermissionsModal = _id => dispatch =>
  dispatch({
    type: TOGGLE_SCREENER_ROOM_PERMISSIONS_MODAL,
    payload: { screenerRoomId: _id }
  })

export const saveAccess = (roomId, ids) => (dispatch, getState) => {
  return dispatch(
    post(
      SAVE_SCREENER_GROUP_ACCESS,
      `organizations/${getState().user.auth.organization_id}/screener-groups/${roomId}/access`,
      { ids },
      () => dispatch(addGlobalMessage(`Saved!`, "success"))
    )
  )
}

export const duplicateScreenerRoom = (room) => (dispatch, getState) => {
  return dispatch(
    post(
      DUPLICATE_SCREENER_ROOM,
      `organizations/${getState().user.auth.organization_id}/screener-groups/${room._id}/duplicate`,
      null,
      () => dispatch(addGlobalMessage(`Duplicated! Pleaase refresh.`, "success"))
    )
  )
}

export const toggleScreenerDeleteModal = (screenerRoom = null) => dispatch =>
  dispatch({
    type: TOGGLE_SCREENER_GROUP_DELETE_MODAL,
    payload: { screenerRoom }
  })
