import React from "react"
import NewProjectImg from "../images/new_project.png"
import BaseSlide from "./components/base"
import Footer from "./components/footer"
import 'styled-components/macro'

const ProjectsSlide = () => (
  <BaseSlide>
    <div
      css={`
        padding: 60px 100px 0 100px;
      `}>
      <img className='cs-img' src={NewProjectImg} alt='New Project'/>
    </div>
    <Footer
      bg='red'
      header='Create your first project'
      content='To get started, create a project to contain your files. As you create more content, you can create additional projects to keep things organized.'/>
  </BaseSlide>
)

export default ProjectsSlide
