import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { push } from "connected-react-router"
import { getProject } from "@api/project"
import Share from "@components/project/files/modals/share"
import DefrostFile from "@components/project/files/modals/defrost"
import Info from "@components/project/files/components/info/"
import FolderContent from "@components/project/files/modals/folder"
import { toggleModalVisibility } from "@api/project/files/modals"
import Orders from "@components/project/orders/"
import { Helmet } from "react-helmet"
import AsperaSharing from "@components/project/files/modals/aspera_share"
import EditFolder from "@components/project/files/components/folder/edit"
import MoveFolder from "@components/project/files/components/folder/move"
import MoveFilesToProject from "@components/project/files/modals/move_files_to_project"
import { canAccessProject } from "@src/helpers/authorization"
import ProjectNav from "@components/nav/containers/project"
import AccessModal from "@components/projects/components/project/access/"
import { Status } from "@bitcine/cs-theme"
//import FileDefroster from "@components/file_defroster"
import 'styled-components/macro'

class Project extends React.Component {
  componentDidMount() {
    this.props.getProject(this.props.match.params.projectID)
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.projectID !== this.props.match.params.projectID) {
      this.props.getProject(this.props.match.params.projectID)
    }
  }
  componentWillUnmount() {
    if (this.props.modals.info.visible) {
      this.props.toggleModalVisibility("info")
    }
  }
  render() {
    const {
      project,
      accessModal,
      children,
      modals,
      activeOrder,
      asperaSharingVisible,
      user,
      error,
      showEditFolder,
      showMoveFolder,
      showMoveFilesToProject
    } = this.props
    const projectLoading = !project || !project._id

    if (!projectLoading) {
      if (!canAccessProject(user, project)) {
        push("/401")
      }
    }

    return (
      <Status error={error} pending={projectLoading}>
        <div
          css={`
            margin-top: 104px;
          `}>
          <Helmet title={`CineSend | ${project.title}`}/>
          <ProjectNav
            _id={project._id}
            title={project.title}
            projectLoading={projectLoading}
            canEdit={true}
            folder={project.folder}/>

          {/* {!!project.frozen_at ? <FileDefroster project={project}/> : <>{children}</>} */}

          {children}

          {modals.defrost.visible && <DefrostFile/>}
          {modals.info.visible && <Info/>}
          {modals.share.visible && <Share/>}
          {modals.folderContent.visible && <FolderContent/>}
          {asperaSharingVisible && <AsperaSharing/>}
          {activeOrder && <Orders/>}
          {accessModal && <AccessModal/>}
          {showEditFolder && <EditFolder/>}
          {showMoveFolder && <MoveFolder/>}
          {showMoveFilesToProject && <MoveFilesToProject/>}
        </div>
      </Status>
    )
  }
}

const mapStateToProps = (state, context) => ({
  project: state.project.details,// && state.project.details._id === context.params.projectID ? state.project.details : {},
  error: state.project.details.status === "ERROR",
  modals: state.project.files.modals,
  activeOrder: state.orders.active_order,
  asperaSharingVisible: state.project.files.asperaSharing.visible,
  user: state.user.auth,
  accessModal: state.project.access.modalOpen,
  showEditFolder: state.project.files.folders.editFolder.show,
  showMoveFolder: state.project.files.folders.moveFolder.show,
  showMoveFilesToProject: state.project.files.modals.moveFilesToProject.visible
})

const mapDispatchToProps = dispatch => ({
  getProject: bindActionCreators(getProject, dispatch),
  toggleModalVisibility: bindActionCreators(toggleModalVisibility, dispatch)
})

Project.propTypes = {
  project: PropTypes.object,
  getProject: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
  activeOrder: PropTypes.object,
  asperaSharingVisible: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  user: PropTypes.object.isRequired,
  accessModal: PropTypes.bool.isRequired,
  showEditFolder: PropTypes.bool.isRequired,
  showMoveFolder: PropTypes.bool.isRequired,
  showMoveFilesToProject: PropTypes.bool.isRequired,
  toggleModalVisibility: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Project)
