export const GET_DOWNLOAD_LINK = "GET_DOWNLOAD_LINK"
export const GET_DOWNLOAD_LINK_PENDING = "GET_DOWNLOAD_LINK_PENDING"
export const GET_DOWNLOAD_LINK_FULFILLED = "GET_DOWNLOAD_LINK_FULFILLED"
export const GET_DOWNLOAD_LINK_FAILED = "GET_DOWNLOAD_LINK_REJECTED"

export const TOGGLE_FILE_IMPORT_MODAL = "TOGGLE_FILE_IMPORT_MODAL"

export const ADD_ASPERA_DOWNLOAD_LINK_TRANSFER = "ADD_ASPERA_DOWNLOAD_LINK_TRANSFER"
export const UPDATE_ASPERA_DOWNLOAD_LINK_TRANSFER = "UPDATE_ASPERA_DOWNLOAD_LINK_TRANSFER"
export const REMOVE_ASPERA_DOWNLOAD_LINK_TRANSFER = "REMOVE_ASPERA_DOWNLOAD_LINK_TRANSFER"

export const IMPORT_FILE_INTO_ACCOUNT = "IMPORT_FILE_INTO_ACCOUNT"
export const IMPORT_FILE_INTO_ACCOUNT_PENDING = "IMPORT_FILE_INTO_ACCOUNT_PENDING"
export const IMPORT_FILE_INTO_ACCOUNT_FULFILLED = "IMPORT_FILE_INTO_ACCOUNT_FULFILLED"
export const IMPORT_FILE_INTO_ACCOUNT_FAILED = "IMPORT_FILE_INTO_ACCOUNT_REJECTED"

export const UPDATE_FOLDER_ID = "UPDATE_FOLDER_ID"

export const GET_PACKAGE_FILES = {
  REQUEST: "GET_PACKAGE_FILES",
  PENDING: "GET_PACKAGE_FILES_PENDING",
  COMPLETE: "GET_PACKAGE_FILES_FULFILLED",
  FAILED: "GET_PACKAGE_FILES_REJECTED"
}

export const PACKAGE_AND_ASPERA_DOWNLOAD = "PACKAGE_AND_ASPERA_DOWNLOAD"
