import {
  GET_NOTIFICATIONS_PENDING,
  GET_NOTIFICATIONS_FULFILLED,
  UPDATE_NOTIFICATION
} from "@actions/account/notifications"

import update from "immutability-helper"

const initialState = {
  notifications: {},
  status: ""
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_NOTIFICATIONS_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_NOTIFICATIONS_FULFILLED:
      return {
        ...state,
        status: "COMPLETE",
        notifications: action.payload
      }

    case UPDATE_NOTIFICATION:
      const { flag } = action.payload.notification
      const { key } = action.payload

      return update(state, {
        notifications: {
          [key]: {
            [flag]: {
              $set: !state.notifications[key][flag]
            }
          }
        }
      })

    default:
      return state
  }
}
