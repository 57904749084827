import React from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "react-emotion"
import Empty from "../empty"

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const StyledPoster = styled("div")`
  padding: 145% 0 0;
  box-shadow: ${props => (props.checked ? "0 0 2px 2px #e30a33" : "0 3px 5px 0 #e0e3e4")};
  transition: all 200ms;
  background-image: ${props => (props.imageSrc && props.imageLoaded ? `url(${props.imageSrc})` : "")};
  > div:nth-child(1) {
    opacity: ${props => (props.checked ? 1 : 0)};
    transition: opacity 0.2s ease-in-out;
  }
  :hover {
    > div:nth-child(1) {
      opacity: 1;
    }
    box-shadow: ${props => (props.checked ? "" : "0 7px 14px 0 rgba(148, 149, 149, 0.82)")};
  }
  .img {
    div {
      opacity: 0;
    }
    animation: ${fade} backgroundLoaderCSS 0.6s linear forwards;
  }
`

class Poster extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      error: false
    }
  }
  componentDidMount() {
    if (this.props.src) {
      this.image = new Image()
      this.image.src = this.props.src
      this.image.onload = () => this.setState({ loaded: true, source: this.props.src })
      this.image.onerror = () => this.setState({ error: true })
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      if (this.isImageLocal(this.props.src)) {
        this.loadLocalImage(this.props.src)
      } else {
        this.image = null
        this.image = new Image()
        this.image.src = this.props.src
        this.image.onload = () => this.setState({ loaded: true, source: this.props.src })
        this.image.onerror = () => this.setState({ error: true })
      }
    }
  }
  isImageLocal(src) {
    return typeof src === "object"
  }
  loadLocalImage(file) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.setState({ loaded: true, source: reader.result })
    }
  }
  componentWillUnmount() {
    if (this.image) {
      this.image.onerror = null
      this.image.onload = null
      this.image = null
    }
  }
  render() {
    const { src, children, checked, convertingToFolder, className } = this.props
    return (
      <StyledPoster
        checked={checked}
        imageSrc={this.state.source}
        imageLoaded={this.state.loaded}
        shrink={convertingToFolder}
        className={`${className} px2 relative bg-cover bg-center bg-no-repeat z0 bg-white rounded`}>
        {children}
        {!src && <Empty/>}
      </StyledPoster>
    )
  }
}

Poster.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  shrink: PropTypes.bool,
  checked: PropTypes.bool,
  convertingToFolder: PropTypes.bool
}

export default Poster
