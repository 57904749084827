import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Checkbox } from "@bitcine/cs-theme"

const Notification = ({ email, onChange, checked }) => {
  return (
    <div className='py2 border-bottom border-gray-5 flex items-center'>
      <Checkbox checked={checked} onChange={onChange}/>
      <div className='pl1'>
        <h4>Notify Me When Viewed</h4>
        <p className='mb0'>
          An email will be sent to <strong>{email}</strong> the first time screener playback is started.
        </p>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  email: state.user.auth.email
})

Notification.propTypes = {
  onChange: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(Notification)
