import {
  SET_PARENT_VALUE,
  SET_QUERY_VALUE,
  GET_SHARED_SCREENERS,
  UPDATE_SHARED_SCREENER
} from "@actions/shared_screeners/"

import { get, put } from "@api/fetch"
import { addGlobalMessage } from "@api/utils/"

export const setParent = (parentType, parentID) => (dispatch, getState) => {
  dispatch({
    type: SET_PARENT_VALUE,
    payload: { parentID, parentType }
  })
}

export const setQueryValue = (key, value) => (dispatch, getState) => {
  dispatch({
    type: SET_QUERY_VALUE,
    payload: { key, value }
  })
  let query = getState().shared_screeners.query
  let obj = {
    parentID: query.parentID,
    parentType: query.parentType,
    search: query.search,
    skip: query.skip,
    take: query.take,
    sortBy: query.sortBy,
    sortDirection: query.sortDirection
  }
  Object.keys(obj).forEach(key => obj[key] == null && delete obj[key])
  let params = new URLSearchParams(obj)
  dispatch(get(GET_SHARED_SCREENERS.REQUEST, `shared-screeners?${params.toString()}`))
}

export const updateSharedScreener = (sharedScreenerID, key, value) => dispatch =>
  dispatch(
    put(UPDATE_SHARED_SCREENER.REQUEST, `shared-screeners/${sharedScreenerID}`, { key, value }, () =>
      dispatch(addGlobalMessage("Saved!", "success"))
    )
  )

export const resendEmail = sharedScreenerID => dispatch =>
  dispatch(
    put(UPDATE_SHARED_SCREENER.REQUEST, `shared-screeners/${sharedScreenerID}/resend`, {}, () => {
      dispatch(addGlobalMessage("Email sent!", "success"))
    })
  )

export const updateLastSent = sharedScreenerID => dispatch =>
  dispatch(
    put(UPDATE_SHARED_SCREENER.REQUEST, `shared-screeners/${sharedScreenerID}/update-last-sent`, {}, () => {
      dispatch(addGlobalMessage("Link copied!", "success"))
    })
  )