import React from "react"
import PropTypes from "prop-types"
import tinycolor from "tinycolor2"
import dayjs from "dayjs"

const HeatMap = ({ data, showLive = false, height = "12px" }) => {
  const heatmap = data.watched
    ? data.watched
    : data.heatmap
      ? data.heatmap
      : [{ start_percentage: 0, end_percentage: 100, weight: 0 }]
  const isActive = data.last_viewed_at ? dayjs.utc().diff(dayjs.utc(data.last_viewed_at), "minute") < 2 : false
  return (
    <div className='flex items-center col-12 pr1'>
      <div className='rounded overflow-hidden'>
        <svg width='100%' className='cs-svg rounded col-12' height={height}>
          {heatmap.map((data, i) => (
            <rect
              key={i}
              x={data.start_percentage + "%"}
              y={0}
              width={data.end_percentage - data.start_percentage + "%"}
              height={height}
              fill={
                data.weight === 0
                  ? "#ededed"
                  : tinycolor("#b4afca")
                    .darken(data.weight * 5)
                    .toString()
              }/>
          ))}
        </svg>
      </div>
      <div style={{ minWidth: "24px" }} className='muted mx1'>
        {data.percentage_watched || 0}%
      </div>
      {showLive && (
        <button
          className='mr1 cs-button link tooltip flex items-center'
          data-tooltip={isActive ? "This session is live!" : "This session has ended."}
          onClick={() => null}>
          <i
            className='material-icons'
            style={{ color: isActive ? "#009e06" : "lightgray", fontSize: "8px", marginBottom: "2px" }}>
            circle
          </i>
        </button>
      )}
    </div>
  )
}

HeatMap.propTypes = {
  data: PropTypes.array.isRequired,
  percent: PropTypes.string,
  height: PropTypes.string,
  showLive: PropTypes.bool
}

export default HeatMap
