import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toggleGiftCard } from "@api/account/cart"
import { getScreenerTopUpItems } from "@api/project/orders"
import { updateReviewersTopUp, purchaseReviewers } from "@api/account/reviewers"
import CreditCardLine from "@components/payment/credit_card_line"
import GiftCardLine from "@components/payment/gift_card_line"
import PostPayLine from "@components/payment/post_pay_line"
import { getPaymentMethods, updateNewPaymentMethod, updatePaymentMethod } from "@api/account/billing"
import { Modal } from "@bitcine/cs-theme"
import { GLOBALS } from "@src/config"
import NewPaymentMethod from "@components/settings/billing/new"

class Reviewer extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.props.getScreenerTopUpItems()
    this.props.getPaymentMethods()
  }
  render() {
    const {
      items,
      topUp,
      updateReviewersTopUp,
      reviewers,
      paymentMethods: {
        list: { can_post_pay, using_post_pay },
        list,
        status,
        activeGiftCards,
        giftCardTotal,
        giftCardsBeingUsed
      },
      toggleGiftCard,
      purchaseReviewers,
      newPaymentMethodVisible,
      updateNewPaymentMethod,
      updatePaymentMethod
    } = this.props

    if (!topUp.visible) return null
    if (newPaymentMethodVisible) return <NewPaymentMethod disableGiftCards={true}/>
    else
      return (
        <Modal
          open={topUp.visible}
          title='Purchase Screeners'
          width={3}
          onClose={() => updateReviewersTopUp("visible", false)}>
          <div>
            {!items ? (
              <div className='py4 center'>
                <div className='spinner'/>
              </div>
            ) : topUp.status === "COMPLETE" ? (
              <div className='py4 center'>
                <span className='large-icon material-icons green'>check_circle</span>
                <h4>Success</h4>
                <p>Reviewers have been added to your account</p>
                <a target='_blank' rel='noopener noreferrer' href={`${GLOBALS.API_URL}/settings/invoices/${topUp.invoice_id}`}>
                  <button className='cs-button mt1'>Print Invoice</button>
                </a>
              </div>
            ) : (
              <div className='mt3'>
                <div className='flex items-start flex-auto pb3 border-bottom border-gray-4'>
                  <div className='col-7 '>
                    <div>
                      <strong>Current plan allows for {reviewers.total} preview links / month</strong>
                      <p className='mt2'>
                        Below you can purchase additional preview links. You have {reviewers.remaining} remaining
                        preview links this month.
                      </p>
                    </div>
                    <div className='flex items-center'>
                      <div className='flex-auto flex items-center'>
                        <h4 className='mb0'>
                          {items.price_formatted}
                          &nbsp;&nbsp;
                        </h4>
                        <small className='muted'>Per preview link</small>
                      </div>
                      <div className='flex items-center'>
                        <button
                          onClick={() => updateReviewersTopUp("quantity", topUp.quantity - 1)}
                          disabled={topUp.quantity < 2}
                          className='cs-button link material-icons mr1'>
                          remove_circle
                        </button>
                        <input
                          className='cs-input no-min-width'
                          style={{ width: "80px" }}
                          value={topUp.quantity}
                          onChange={({ target: { value } }) => {
                            updateReviewersTopUp("quantity", value === "" ? 1 : parseFloat(value.replace(/\D/, "")))
                          }}/>
                        <button
                          onClick={() => updateReviewersTopUp("quantity", topUp.quantity + 1)}
                          className='cs-button link material-icons ml1'>
                          add_circle
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='col-5 ml3 p2 border rounded border-gray-4'>
                    <div className='flex items-center justify-between'>
                      <div>
                        Preview Link <small className='muted'>{items.price_formatted} each</small>
                      </div>
                      <strong className='pl3'>
                        {topUp.quantity} x {items.price_formatted}
                      </strong>
                    </div>
                    <div className='flex items-center justify-between'>
                      Subtotal:
                      <div className='pl3'>
                        {items.currency_symbold}
                        {(topUp.quantity * items.price).toFixed(2)}
                      </div>
                    </div>
                    <div className='border-top border-gray-4 pt2 mt2 flex items-center justify-between'>
                      <strong>Order Total:</strong>
                      <strong className='pl3'>
                        {items.currency_symbol}
                        {(topUp.quantity * items.price).toFixed(2)} {items.currency_code}
                      </strong>
                    </div>
                  </div>
                </div>
                <div className='pt3'>
                  <h4>Payment Method</h4>
                  <div className='pt3'>
                    <div className='flex items-start justify-between'>
                      <div>
                        <strong className='block'>Payment Method</strong>
                        <p>Saved payment methods</p>
                      </div>
                      <button onClick={() => updateNewPaymentMethod("visible", true)} className='cs-button white small'>
                        Add Payment Method
                      </button>
                    </div>
                    {status === "PENDING" ? (
                      <div className='my4 py2 center'>
                        <div className='spinner'/>
                      </div>
                    ) : (
                      <span>
                        {list.credit_cards.map(card => (
                          <CreditCardLine
                            key={card._id}
                            card={card}
                            postPay={using_post_pay}
                            checked={topUp.credit_card_id === card._id}
                            onSelect={() => {
                              if (topUp.credit_card_id === card._id) {
                                updateReviewersTopUp("credit_card_id", "")
                              } else {
                                updateReviewersTopUp("credit_card_id", card._id)
                              }
                            }}/>
                        ))}
                        {list.gift_cards.map(card => (
                          <GiftCardLine
                            key={card._id}
                            postPay={using_post_pay}
                            card={card}
                            checked={card.is_payment_active}
                            onSelect={() => toggleGiftCard(card._id)}/>
                        ))}
                        {can_post_pay && (
                          <PostPayLine
                            checked={using_post_pay}
                            onSelect={() => {
                              updatePaymentMethod("using_post_pay", !using_post_pay)
                              updateReviewersTopUp("credit_card_id", "")
                              {
                                activeGiftCards.map(card => toggleGiftCard(card._id))
                              }
                            }}/>
                        )}
                      </span>
                    )}
                  </div>
                </div>
                {topUp.status === "ERROR" && (
                  <div className='red right-align mt2'>
                    {topUp.error_message || "An error occured. Please contact support."}
                  </div>
                )}

                {!topUp.credit_card_id && giftCardsBeingUsed && giftCardTotal < items.price * topUp.quantity && (
                  <div className='red bold flex items-center ml2'>
                    <span className='material-icons mr1'>error</span>A credit card is required, not enough left on the
                    gift Card.
                  </div>
                )}

                <div className='mt3 right-align'>
                  <button
                    onClick={purchaseReviewers}
                    className={`cs-button ${topUp.status === "PENDING" ? "pending" : ""}`}
                    disabled={
                      (!topUp.credit_card_id && !giftCardsBeingUsed && !using_post_pay) ||
                      topUp.status === "PENDING" ||
                      (!topUp.credit_card_id && giftCardsBeingUsed && giftCardTotal < items.price * topUp.quantity)
                    }>
                    Purchase
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal>
      )
  }
}

const mapStateToProps = (state, context) => ({
  topUp: state.user.reviewers,
  items: state.utils.screeners_top_up_items,
  reviewers: state.user.auth.subscription.reviewers,
  paymentMethods: state.user.billing.paymentMethods,
  newPaymentMethodVisible: state.user.billing.newPaymentMethod.visible
})

const mapDispatchToProps = dispatch => ({
  getScreenerTopUpItems: bindActionCreators(getScreenerTopUpItems, dispatch),
  updateReviewersTopUp: bindActionCreators(updateReviewersTopUp, dispatch),
  getPaymentMethods: bindActionCreators(getPaymentMethods, dispatch),
  toggleGiftCard: bindActionCreators(toggleGiftCard, dispatch),
  updateNewPaymentMethod: bindActionCreators(updateNewPaymentMethod, dispatch),
  purchaseReviewers: bindActionCreators(purchaseReviewers, dispatch),
  updatePaymentMethod: bindActionCreators(updatePaymentMethod, dispatch)
})

Reviewer.propTypes = {
  getScreenerTopUpItems: PropTypes.func.isRequired,
  topUp: PropTypes.object.isRequired,
  items: PropTypes.object,
  activeGiftCards: PropTypes.array.isRequired,
  toggleGiftCard: PropTypes.func.isRequired,
  updateReviewersTopUp: PropTypes.func.isRequired,
  reviewers: PropTypes.object.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  paymentMethods: PropTypes.object.isRequired,
  newPaymentMethodVisible: PropTypes.bool.isRequired,
  updateNewPaymentMethod: PropTypes.func.isRequired,
  purchaseReviewers: PropTypes.func.isRequired,
  updatePaymentMethod: PropTypes.func.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reviewer)
