import React from "react"
import PropTypes from "prop-types"

const Warning = ({ password = false, watermark = false }) => (
  <div className='border border-gray-5 rounded p2 my2 flex items-center'>
    <span className='material-icons orange mr1'>warning</span>
    {password && "Your role requires you to provide a password on shared screeners."}
    {watermark && "Your role requires watermarks on shared screeners."}
  </div>
)

Warning.propTypes = {
  password: PropTypes.bool,
  watermark: PropTypes.bool
}

export default Warning
