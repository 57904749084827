import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { updateUtils } from "@api/utils/"
import { bindActionCreators } from "redux"
import { updateOrder, toggleOrderVisibility } from "@api/project/orders"
import { Dialog } from "@bitcine/cs-theme"
import { fileDrawerStyles } from "@components/project/constants"
import { subtitles } from "./constants"
import Turnaround from "./cc/turnaround"
import Files from "./file/add"
import FileInfo from "./file/info"
import DCPConfirmation from "./dcp/confirmation"
import Complete from "./ui/complete"
import ErrorUI from "./ui/error"
import ConfirmClose from "./ui/close"
import DCPAddons from "./dcp/addons"
import DCPOptions from "./dcp/options"
import DCPTurnaround from "./dcp/turnaround"
import DiscQuantity from "./disc/quantity"
import DiscOptions from "./disc/options"
import Drive from "./send/drive"
import Disc from "./send/disc"
import SelectInspectionType from "./inspection"
import SelectConversionFormat from "./conversion"
import ConfirmConversionDetails from "./conversion/confirmation"

class Orders extends React.Component {
  componentDidMount() {
    this.props.updateUtils("dialogOpen", true)
  }
  componentWillUnmount() {
    this.props.toggleOrderVisibility()
    this.props.updateUtils("dialogOpen", false)
  }
  render() {
    const {
      activeOrder: { title, step, error, returnStep },
      updateOrder,
      toggleOrderVisibility
    } = this.props
    return (
      <Dialog open={true} style={fileDrawerStyles}>
        <div className='p2'>
          <div className='flex items-center pb2 col-12'>
            <div className='flex-auto pr2'>
              <small className='block'>{title}</small>
              <h4 className='truncate'>{error ? "Error" : subtitles[step]}</h4>
            </div>
            {step !== "confirm-close" && (
              <button
                disabled={!error && step === "adding-to-cart"}
                onClick={() => {
                  if (error || step === "complete") toggleOrderVisibility()
                  else {
                    updateOrder("returnStep", step)
                    updateOrder("step", "confirm-close")
                  }
                }}
                data-tooltip='Cancel Order'
                className='cs-button link material-icons ml2 '>
                close
              </button>
            )}
          </div>
          {/* EWWWWWW */}
          {error ? (
            <ErrorUI/>
          ) : step === "file-info" ? (
            <FileInfo/>
          ) : step === "file-picker" ? (
            <Files/>
          ) : step === "turnaround-time" ? (
            <Turnaround/>
          ) : step === "dcp-options" ? (
            <DCPOptions/>
          ) : step === "dcp-addons" ? (
            <DCPAddons/>
          ) : step === "dcp-turnaround" ? (
            <DCPTurnaround/>
          ) : step === "dcp-confirmation" ? (
            <DCPConfirmation/>
          ) : step === "adding-to-cart" ? (
            <div className='col-12 py3 center'>
              <div className='spinner'/>
              <small className='block'>Adding to cart...</small>
            </div>
          ) : step === "complete" ? (
            <Complete/>
          ) : step === "confirm-close" ? (
            <ConfirmClose returnStep={returnStep}/>
          ) : step === "disc-quantity" ? (
            <DiscQuantity/>
          ) : step === "disc-options" ? (
            <DiscOptions/>
          ) : step === "send-physical-copy-drives" ? (
            <Drive/>
          ) : step === "send-discs" ? (
            <Disc/>
          ) : step === "select-inspection-type" ? (
            <SelectInspectionType/>
          ) : step === "select-conversion-format" ? (
            <SelectConversionFormat/>
          ) : step === "confirm-conversion-details" ? (
            <ConfirmConversionDetails/>
          ) : null}
        </div>
      </Dialog>
    )
  }
}

Orders.propTypes = {
  activeOrder: PropTypes.object.isRequired,
  updateOrder: PropTypes.func.isRequired,
  toggleOrderVisibility: PropTypes.func.isRequired,
  projectID: PropTypes.string,
  updateUtils: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  activeOrder: state.orders.active_order,
  projectID: state.project.details._id
})

const mapDispatchToProps = dispatch => ({
  updateOrder: bindActionCreators(updateOrder, dispatch),
  toggleOrderVisibility: bindActionCreators(toggleOrderVisibility, dispatch),
  updateUtils: bindActionCreators(updateUtils, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Orders)
