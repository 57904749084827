import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { getCountries } from "@api/utils/"
import { Status } from "@bitcine/cs-theme"
import styled from "react-emotion"
import { injectGlobal } from "emotion"
import flags from "@src/public/flags/flags32.png"

injectGlobal`
	.flag {
		background:url(${flags}) no-repeat;
	}
`

const ListItem = styled("li")`
  height: 28px;
  &:hover {
    background: #ededed;
  }
`

const List = styled("ul")`
  max-height: 280px;
  overflow: auto;
`

const Button = styled("button")`
  height: auto;
`

const BaseLine = styled("div")`
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
`

const Flag = styled("span")`
  display: inline-block;
  width: 32px;
  height: 32px;
  transform: scale(0.6);
  transform-origin: center;
`

const Dropdown = styled("div")`
  min-height: 32px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
`

class CanadaPost extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      search: "",
      status: "IDLE",
      dropdown_status: "CLOSED",
      list: [],
      country: "ca"
    }
    this.key = encodeURIComponent("UU41-JA92-YK51-WZ56")
    this.timeout = null
    this.fetch_url = `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws?Key=${
      this.key
    }`
    this.retrieve_url = `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.10/json3.ws?Key=${
      this.key
    }`
  }
  componentDidMount() {
    this.props.getCountries()
    document.addEventListener("mousedown", this.onMouseDown)
    window.addEventListener("keydown", this.onKeyDown)
    this.props.updateAddress({ ready: false })
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onMouseDown)
    window.removeEventListener("keydown", this.onKeyDown)
  }
  onMouseDown = e => {
    if (this.wrap && !this.wrap.contains(e.target) && this.state.dropdown_status === "OPEN") {
      this.setState({
        dropdown_status: "CLOSED"
      })
    }
  }
  onKeyDown = e => {
    if (e.keyCode === 27 && this.state.dropdown_status === "OPEN") {
      this.setState({
        dropdown_status: "CLOSED"
      })
    }
  }
  fetch(params) {
    this.setState(
      {
        status: "PENDING",
        dropdown_status: "OPEN"
      },
      () => {
        fetch(`${this.fetch_url}&${params}`)
          .then(res => res.json())
          .then(({ Items }) => {
            this.setState({
              status: "LOADED",
              list: Items
            })
          })
      }
    )
  }
  retrieve(Id) {
    this.setState({ status: "PENDING" }, () => {
      fetch(`${this.retrieve_url}&Id=${encodeURIComponent(Id)}`)
        .then(res => res.json())
        .then(({ Items }) => {
          this.setState({
            status: "IDLE",
            search: Items[0].Label.replace(/\n/g, " "),
            dropdown_status: "CLOSED"
          })
          this.props.updateAddress({
            address_line1: Items[0].Line1,
            address_line2: Items[0].Line2,
            city: Items[0].City,
            state_or_province_code: Items[0].Province,
            country_code: Items[0].CountryIso2,
            postal_code: Items[0].PostalCode,
            ready: true
          })
        })
    })
  }
  onClick(address) {
    if (address.Next === "Retrieve") {
      this.retrieve(address.Id)
    } else if (address.Next === "Find") {
      this.fetch(`LastId=${address.Id}&Country=${this.state.country}&MaxSuggestions=${250}&MaxResults=${1000}`)
    }
  }
  onChange = e => {
    this.setState({ search: e.target.value })
    if (this.state.status !== "COUNTRY_SELECT") {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        if (this.state.search.length) {
          this.fetch(
            `SearchTerm=${this.state.search}&Country=${this.state.country}&MaxSuggestions=${250}&MaxResults=${1000}`
          )
        } else {
          this.setState({
            list: []
          })
        }
      }, 500)
    }
  }
  openCountries = e => {
    e.preventDefault()
    clearTimeout(this.timeout)
    this.setState({
      status: "COUNTRY_SELECT",
      search: ""
    })
  }
  onCountryChange(country) {
    this.setState({
      country: country.country_code.toLowerCase(),
      status: "LOADED",
      search: "",
      list: []
    })
  }
  render() {
    return (
      <React.Fragment>
        <label className='cs-label mt2 block' htmlFor='address'>
          Address
          <sup className='red'>*</sup>
        </label>
        <input type='text' className='cs-input display-none' id='address' placeholder='Address'/>
        <label className='cs-label' htmlFor='school'/>
        <div ref={ref => (this.wrap = ref)} className='relative'>
          <input
            id='school'
            value={this.state.search}
            className='cs-input col-12'
            autoComplete='school'
            onFocus={() => this.setState({ dropdown_status: "OPEN" })}
            placeholder={`Search for a${this.state.status === "COUNTRY_SELECT" ? " country" : "n address"}...`}
            onChange={this.onChange}/>
          {this.state.dropdown_status === "OPEN" && (
            <Dropdown className='absolute top-100 left-0 right-0 pb3 bg-white rounded box-shadow z4'>
              <Status
                height={200}
                empty={this.state.status === "LOADED" && this.state.list.length === 0}
                emptyMessage={{
                  title: "No Matches Found",
                  text: "Try your search again or change countries",
                  icon: "location_on"
                }}
                pending={this.state.status === "PENDING"}>
                <List className='p0 m0'>
                  {this.state.status === "LOADED" &&
                    this.state.list.map(address => (
                      <ListItem
                        key={address.Id}
                        role='button'
                        onClick={() => this.onClick(address)}
                        className='px1 col-12 flex items-center pointer'>
                        <div className='flex-auto flex items-center'>
                          <strong>{address.Text}</strong>
                          <span className='muted italic ml1'>{address.Description}</span>
                        </div>
                        {address.Next === "Find" && (
                          <span className='material-icons flex-shrink'>keyboard_arrow_right</span>
                        )}
                      </ListItem>
                    ))}
                  {this.state.status === "COUNTRY_SELECT" &&
                    this.props.countries
                      .filter(country => country.label.toLowerCase().includes(this.state.search.toLowerCase()))
                      .map(country => (
                        <ListItem
                          key={country._id}
                          role='button'
                          onClick={() => this.onCountryChange(country)}
                          className='px1 col-12 f32 flex items-center pointer'>
                          <Flag className={`flag mr1 ${country.country_code.toLowerCase()}`}/>
                          {country.label}
                        </ListItem>
                      ))}
                </List>
              </Status>
              <BaseLine className='absolute bottom-0 left-0 right-0 border-top border-gray-5 px1 bg-white flex items-center justify-end'>
                <Button onClick={this.openCountries} className='link f32 flex items-center pointer regular'>
                  <span className='small'>Change Country</span>
                  <Flag className={`flag ml1 ${this.state.country}`}/>
                </Button>
              </BaseLine>
            </Dropdown>
          )}
        </div>
      </React.Fragment>
    )
  }
}

CanadaPost.defaultProps = {
  countries: []
}

CanadaPost.propTypes = {
  onSelect: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  countries: PropTypes.array,
  updateAddress: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  countries: state.utils.countries
})

const mapDispatchToProps = dispatch => ({
  getCountries: bindActionCreators(getCountries, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CanadaPost)
