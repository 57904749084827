export const CREATE_SHARED_SCREENERS = {
  REQUEST: "CREATE_SHARED_SCREENERS",
  PENDING: "CREATE_SHARED_SCREENERS_PENDING",
  FAILED: "CREATE_SHARED_SCREENERS_REJECTED",
  COMPLETE: "CREATE_SHARED_SCREENERS_FULFILLED"
}

export const GET_SHARED_SCREENERS = {
  REQUEST: "GET_SHARED_SCREENERS",
  PENDING: "GET_SHARED_SCREENERS_PENDING",
  COMPLETE: "GET_SHARED_SCREENERS_FULFILLED",
  FAILED: "GET_SHARED_SCREENERS_REJECTED"
}

export const GET_SHARED_SCREENER_STATS = {
  REQUEST: "GET_SHARED_SCREENER_STATS",
  PENDING: "GET_SHARED_SCREENER_STATS_PENDING",
  COMPLETE: "GET_SHARED_SCREENER_STATS_FULFILLED",
  FAILED: "GET_SHARED_SCREENER_STATS_REJECTED"
}

export const UPDATE_SHARED_SCREENER = {
  REQUEST: "UPDATE_SHARED_SCREENER",
  PENDING: "UPDATE_SHARED_SCREENER_PENDING",
  COMPLETE: "UPDATE_SHARED_SCREENER_FULFILLED",
  FAILED: "UPDATE_SHARED_SCREENER_REJECTED"
}

export const GET_SHARED_SCREENER_SESSIONS = {
  REQUEST: "GET_SHARED_SCREENER_SESSIONS",
  PENDING: "GET_SHARED_SCREENER_SESSIONS_PENDING",
  COMPLETE: "GET_SHARED_SCREENER_SESSIONS_FULFILLED",
  FAILED: "GET_SHARED_SCREENER_SESSIONS_REJECTED"
}

export const SET_PARENT_VALUE = "SET_PARENT_VALUE"
export const SET_QUERY_VALUE = "SET_QUERY_VALUE"
export const TOGGLE_MODAL = "TOGGLE_MODAL"
