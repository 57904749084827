import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toggleMoveFolder, getFolders, moveIntoFolder, createAndMoveIntoFolder } from "@api/project/folders/"
import { Modal, Status, Table } from "@bitcine/cs-theme"
import Icon from "@components/files/icon/"
import Breadcrumb from "@components/breadcrumb"

class MoveIntoFolder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: "",
      currentFolderID: props.currentFolderID,
      showCreate: false,
      new: ""
    }
  }
  componentDidMount() {
    this.props.getFolders(this.state.currentFolderID)
  }
  onRowCheck(_id) {
    this.setState(state => ({ checked: state.checked === _id ? "" : _id }))
  }
  onRowClick(_id) {
    this.setState({ checked: "", currentFolderID: _id }, () => {
      this.props.getFolders(_id)
    })
  }
  getButtonText() {
    const activeFolder = this.props.folders.list.find(({ _id }) => _id === this.state.checked)
    return activeFolder ? `to ${activeFolder.name}` : "Here"
  }
  move() {
    this.props.moveIntoFolder(this.props.filesToMove, this.state.checked)
  }
  create() {
    this.props.createAndMoveIntoFolder(this.state.currentFolderID, this.state.new, this.props.filesToMove)
  }
  render() {
    const { moveFolder, folders, filesToMove, currentFolderID } = this.props
    return (
      <Modal title='Move Into Folder' onClose={() => this.props.toggleMoveFolder()} open={true}>
        <Status pending={moveFolder.status === "PENDING"} error={moveFolder.status === "ERROR"}>
          <div>
            <Breadcrumb
              breadcrumb={folders.breadcrumb || []}
              onAllClick={() => this.onRowClick(null)}
              onCrumbClick={crumb => this.onRowClick(crumb._id)}/>
            <Status pending={folders.status === "PENDING"} error={folders.status === "ERROR"}>
              <Table
                className='my3'
                widths={[55, "auto", 75]}
                header={{
                  columns: []
                }}
                body={{
                  data: folders.list.filter(f => !filesToMove.includes(f._id) && currentFolderID !== f._id),
                  empty: {
                    icon: "folder",
                    title: "No Folders Found",
                    text: ``
                  },
                  row: {
                    customRender: (children, data) => (
                      <div
                        style={{
                          opacity: this.state.showCreate ? "0.2" : "1"
                        }}>
                        {children}
                      </div>
                    ),
                    checkbox: {
                      checked: data => this.state.checked === data._id && !this.state.showCreate,
                      onChange: (data, index) => this.onRowCheck(data._id),
                      disabled: () => this.state.showCreate
                    },
                    onClick: (e, data, index) => this.onRowCheck(data._id),
                    render: [
                      data => <Icon data={data}/>,
                      data => data.name,
                      data => (
                        <button
                          data-tooltip={`Go to ${data.name}`}
                          disabled={this.state.showCreate}
                          onClick={() => this.onRowClick(data._id)}
                          className='cs-button link material-icons tooltip'>
                          keyboard_arrow_right
                        </button>
                      )
                    ]
                  }
                }}/>
            </Status>
            <div className='flex justify-between items-end'>
              {this.state.showCreate ? (
                <div className='flex items-end relative flex-auto mr3'>
                  <div className='col-12'>
                    <label className='cs-label' htmlFor='new'>
                      New Folder Name
                    </label>
                    <input
                      value={this.state.new}
                      className='cs-input col-12'
                      id='new'
                      autoFocus
                      onChange={e => this.setState({ new: e.target.value })}
                      placeholder='Folder name'/>
                  </div>
                  <button
                    onClick={() => this.setState({ showCreate: false })}
                    className='cs-button link material-icons absolute right-0 mr2'>
                    close
                  </button>
                </div>
              ) : (
                <button className='cs-button white' onClick={() => this.setState({ showCreate: true })}>
                  Create Folder
                </button>
              )}
              {this.state.showCreate ? (
                <button className='cs-button' onClick={() => this.create()} disabled={!this.state.new}>
                  Create Folder and Move Files
                </button>
              ) : (
                <button className='cs-button' onClick={() => this.move()}>
                  Move {this.getButtonText()}
                </button>
              )}
            </div>
          </div>
        </Status>
      </Modal>
    )
  }
}

MoveIntoFolder.propTypes = {
  moveFolder: PropTypes.object.isRequired,
  toggleMoveFolder: PropTypes.func.isRequired,
  getFolders: PropTypes.func.isRequired,
  folders: PropTypes.object.isRequired,
  currentFolderID: PropTypes.string,
  moveIntoFolder: PropTypes.func.isRequired,
  filesToMove: PropTypes.array,
  createAndMoveIntoFolder: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  moveFolder: state.project.files.folders.moveFolder,
  folders: state.project.files.folders.folders,
  // currentFolderID: state.routerParams.location.query.folderID,
  filesToMove: state.project.files.items.checked
})

const mapDispatchToProps = dispatch => ({
  toggleMoveFolder: bindActionCreators(toggleMoveFolder, dispatch),
  getFolders: bindActionCreators(getFolders, dispatch),
  moveIntoFolder: bindActionCreators(moveIntoFolder, dispatch),
  createAndMoveIntoFolder: bindActionCreators(createAndMoveIntoFolder, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveIntoFolder)
