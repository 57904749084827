import { get, put, post } from "@api/fetch"

import {
  AUTH_CHECK,
  UPDATE_AUTH_USER,
  SAVE_USER,
  SHOW_GLOBAL_AUTH,
  CLOSE_GLOBAL_AUTH,
  SET_AUTH_USER,
  LOG_OUT
} from "@actions/account/auth"
import { GLOBALS } from "@src/config"

import Tracking from "@helpers/tracking"

/**
 * Auth check
 * - Check if the user is logged in at page load
 * @returns dispatch
 */

 export const authCheck = (redirectOnFail = false) => dispatch =>
  dispatch(
    get(
      AUTH_CHECK,
      `auth/check`,
      null,
      () => {
        // We good
      },
      res => {
        if (redirectOnFail) {
          window.location.replace(`${GLOBALS.ACCOUNTS_URL}/login?redirect=${window.location.href}`)
        }
      }
    )
  )

/**
 * Logout
 * @returns dispatch
 */

export const logout = () => dispatch => {
  Tracking.reset()
  return dispatch(
    post(LOG_OUT, `auth/logout`, null, () =>
      window.location.replace(GLOBALS.ACCOUNTS_URL + "/login?redirect=" + GLOBALS.URL)
    )
  )
}

/**
 * Update user
 * - updates the state of the logged in user
 * @param {key} - string
 * @param {value} - one of string, object, array
 * @returns dispatch
 */

export const updateAuthUser = (key, value) => dispatch => dispatch({ type: UPDATE_AUTH_USER, key, value })

/**
 * Save user
 * - Saves the user in the db
 * @param {key} - string
 * @param {value} - one of string, object, array
 * @returns dispatch
 */

export const saveAuthUser = (key, value) => (dispatch, getState) =>
  dispatch(put(SAVE_USER, `users/${getState().user.auth._id}`, { [key]: value }))

/**
 * Show global auth
 * - shows a modal with login
 * @param {function} - callback
 * @param {boolean} - allowDismiss
 * @returns dispatch
 */

export const showGlobalAuthModal = (callback, allowDismiss) => dispatch =>
  dispatch({ type: SHOW_GLOBAL_AUTH, callback, allowDismiss })

export const closeGlobalAuth = () => dispatch => dispatch({ type: CLOSE_GLOBAL_AUTH })

/**
 * Sets the auth user
 * - used after global auth modal is used to login in
 * @param {user} - object
 * @returns dispatch
 */

export const setAuthUser = user => dispatch => dispatch({ type: SET_AUTH_USER, user })
