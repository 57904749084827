import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getCustomFields,
  createCustomField,
  updateCustomField,
  saveCustomField,
  deleteCustomField
} from "@api/project/custom_fields"
import { Status, Checkbox } from "@bitcine/cs-theme"
import EmptyCustomFieldsIcon from "@src/public/icons/empty/custom-fields.inline.svg"

class CustomFields extends React.Component {
  componentDidMount() {
    this.props.getCustomFields(this.props.project._id)
  }
  render() {
    const {
      project,
      customFields,
      status,
      createCustomField,
      updateCustomField,
      saveCustomField,
      deleteCustomField
    } = this.props
    return (
      <div className='mt3 py3'>
        <div className='flex items-center justify-between mb3'>
          <h4>Custom Fields</h4>
          <button onClick={() => createCustomField(project._id)} className='cs-button small'>
            Add Custom Field
          </button>
        </div>
        <Status
          pending={status === "PENDING"}
          error={status === "ERROR"}
          empty={customFields.length === 0}
          emptyMessage={{
            icon: <EmptyCustomFieldsIcon/>,
            title: "No custom fields",
            text: 'Click "Add Custom Field" above to add custom fields to your project'
          }}>
          {customFields.map(customField => (
            <div className='rounded box-shadow flex items-center mt2 p2 bg-white' key={customField._id}>
              <div className='col-3 pr2'>
                <label className='cs-label' htmlFor={customField._id + "title"}>
                  Title
                </label>
                <input
                  id={customField._id + "title"}
                  value={customField.title || ""}
                  className='cs-input col-12'
                  onBlur={e => saveCustomField(project._id, customField._id, "title", customField.title)}
                  onChange={({ target: { value } }) => updateCustomField(customField._id, "title", value)}/>
              </div>
              <div className='col-9 pr2'>
                <label className='cs-label' htmlFor={customField._id + "value"}>
                  Value
                </label>
                <input
                  id={customField._id + "value"}
                  value={customField.value || ""}
                  className='cs-input col-12'
                  onBlur={e => saveCustomField(project._id, customField._id, "value", customField.value)}
                  onChange={({ target: { value } }) => updateCustomField(customField._id, "value", value)}/>
              </div>
              {project.organization_id && (
                <div className='pr3'>
                  <Checkbox
                    checked={customField.is_displayed}
                    onChange={() => {
                      updateCustomField(customField._id, "is_displayed", !customField.is_displayed)
                      saveCustomField(project._id, customField._id, "is_displayed", !customField.is_displayed)
                    }}
                    label='Display'/>
                  <button
                    data-tooltip='Display this information in screener rooms.'
                    className='cs-button material-icons tooltip small link ml1'
                    style={{
                      fontSize: "14px"
                    }}>
                    help
                  </button>
                </div>
              )}
              <button
                onClick={() => {
                  deleteCustomField(project._id, customField._id)
                }}
                className='cs-button link regular underline'>
                Remove
              </button>
            </div>
          ))}
        </Status>
      </div>
    )
  }
}

const mapStateToProps = (state, context) => ({
  project: state.project.details,
  customFields: state.project.customFields.list,
  status: state.project.customFields.status
})

const mapDispatchToProps = dispatch => ({
  createCustomField: bindActionCreators(createCustomField, dispatch),
  getCustomFields: bindActionCreators(getCustomFields, dispatch),
  updateCustomField: bindActionCreators(updateCustomField, dispatch),
  saveCustomField: bindActionCreators(saveCustomField, dispatch),
  deleteCustomField: bindActionCreators(deleteCustomField, dispatch)
})

CustomFields.propTypes = {
  project: PropTypes.object.isRequired,
  customFields: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  createCustomField: PropTypes.func.isRequired,
  getCustomFields: PropTypes.func.isRequired,
  updateCustomField: PropTypes.func.isRequired,
  saveCustomField: PropTypes.func.isRequired,
  deleteCustomField: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomFields)
