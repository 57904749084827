import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Status, Checkbox } from "@bitcine/cs-theme"
import Select from "react-select"
import { getCountries } from "@api/utils/"
import RegionalGeoblocking from "./regions"

const Geoblocking = ({ getCountries, countries, newReviewers, onUpdate }) => {
  useEffect(() => {
    getCountries()
  }, [])

  return (
    <div className='py2 border-bottom border-gray-5'>
      <div className='flex items-center'>
        <div className='flex-auto'>
          <div className='flex items-center'>
            <Checkbox
              id='enableGeoIP'
              checked={newReviewers.geoblockingEnabled || false}
              onChange={e => onUpdate("geoblockingEnabled", !newReviewers.geoblockingEnabled)}/>
            <div className='pl1'>
              <h4 className='mb0 flex items-center'>Enable Geoblocking</h4>
              <p className='mb0'>Define the rules that determine where your screener can be accessed from.</p>
            </div>
          </div>
        </div>
      </div>
      {newReviewers.geoblockingEnabled && (
        <Status pending={!countries}>
          <div className='col-12 mt2'>
            <label className='cs-label'>
              Select which countries are allowed to view videos. If none are selected, all countries are allowed.
            </label>
            <Select
              multi={true}
              options={countries}
              isLoading={!countries}
              value={newReviewers.geoblock_country_codes}
              simpleValue={true}
              onChange={countries => onUpdate("geoblock_country_codes", countries)}/>
          </div>
          {!!newReviewers.geoblock_country_codes.includes("CA") && (
            <RegionalGeoblocking newReviewers={newReviewers} onUpdate={onUpdate} countries={countries} region={"CA"}/>
          )}
          {!!newReviewers.geoblock_country_codes.includes("US") && (
            <RegionalGeoblocking newReviewers={newReviewers} onUpdate={onUpdate} countries={countries} region={"US"}/>
          )}
        </Status>
      )}
    </div>
  )
}

Geoblocking.propTypes = {
  getCountries: PropTypes.func.isRequired,
  countries: PropTypes.array,
  newReviewers: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  countries: state.utils.countries
})

const mapDispatchToProps = dispatch => ({
  getCountries: bindActionCreators(getCountries, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Geoblocking)
