import React from "react"
import PropTypes from "prop-types"

const Audio = ({ url }) => <audio controls src={url}/>

Audio.propTypes = {
  url: PropTypes.string.isRequired
}

export default Audio
