import { ADD_TRANSFER, ASPERA_PROGRESS } from "@actions/transfers/"
import { DEFAULTS, PUT_DEFAULTS, POST_DEFAULTS } from "@constants"
import { removeTransfer, updateTransfer } from "@api/transfer/"
import { addGlobalMessage, removeGlobalMessage } from "@api/utils/"
import { GLOBALS } from "@src/config"
import dayjs from "dayjs"
import uuidv4 from "uuid/v4"

export const initializeAsperaDownload = file => (dispatch, getState) => {
  const msgID = dispatch(addGlobalMessage("Initializing Aspera...", "info", "cloud_download", 500000))
  const uuid = uuidv4()

  fetch(`${GLOBALS.API_URL}/downloads?fileID=${file._id}&fileType=${file.type}`, DEFAULTS)
    .then(res => res.json())
    .then(({ asperaObject }) => {
      const sdkLocation = "//d3gcli72yxqn2z.cloudfront.net/connect/v4"

      const asperaWeb = new AW4.Connect({
        sdkLocation,
        minVersion: "3.6.0",
        id: uuid,
        dragDropEnabled: false
      })

      const asperaInstaller = new AW4.ConnectInstaller({ sdkLocation })

      asperaWeb.initSession(`nodeConnect-${uuid}`)

      asperaWeb.addEventListener(AW4.Connect.EVENT.STATUS, (eventType, status) =>
        dispatch(asperaWebEventListener(eventType, status, asperaInstaller))
      )

      const opts = {
        title: `Select Save Location`,
        suggestedName: file.name
      }
      const callBacks = {
        success: path => {
          if (!path.dataTransfer.files.length) asperaWeb.stop()
          else {
            const transferSpec = {
              ...asperaObject.transfer_specs[0].transfer_spec,
              destination_root: path.dataTransfer.files[0].name,
              authentication: "token"
            }

            const connectSettings = {
              allow_dialogs: "no",
              use_absolute_destination_path: true
            }

            asperaWeb.addEventListener("transfer", (e, transfers) =>
              dispatch(asperaTransferEvents(e, transfers, file, uuid))
            )

            asperaWeb.startTransfer(transferSpec, connectSettings)

            dispatch(removeGlobalMessage(msgID))
          }
        },
        error: err => {
          dispatch(removeGlobalMessage(msgID))
          dispatch(setError({ message: err.user_message, code: err.code }))
        }
      }

      asperaWeb.showSaveFileDialog(callBacks, opts)

      if (!window.asperaWeb) window.asperaWeb = asperaWeb
    })
}

/**
 * Aspera Web Callback
 * - Handles events around the aspera web events
 *
 * @param eventType (string)
 * @param dataStatus (string)
 *
 * @returns dispatch
 */

export const asperaWebEventListener = (eventType, dataStatus, asperaInstaller) => (dispatch, getState) => {
  const status = AW4.Connect.STATUS
  if (eventType === AW4.Connect.EVENT.STATUS) {
    switch (dataStatus) {
      case status.INITIALIZING:
        asperaInstaller.showLaunching()
        break
      case status.FAILED:
        asperaInstaller.showDownload()
        break
      case status.OUTDATED:
        asperaInstaller.showUpdate()
        break
      case status.RUNNING:
        asperaInstaller.connected()
        break
      case status.RETRYING:
        break
    }
  }
}

const asperaTransferEvents = (event, transfers, file, uuid) => (dispatch, getState) => {
  transfers.transfers.forEach(transfer => {
    if (transfer.aspera_connect_settings.app_id === `nodeConnect-${uuid}`) {
      if (!getState().transfers[transfer.uuid]) {
        dispatch(add(transfer, getState().project.details._id, file))
      } else {
        switch (transfer.status) {
          case "initiating":
            if (!getState().transfers[transfer.uuid]) {
              dispatch(add(transfer, getState().project.details._id, file))
            }
            break
          case "queued":
            dispatch(updateTransfer("status", "Queued", transfer.uuid))
            break
          case "running":
            let currentSize = 0
            transfer.files.forEach(f => {
              currentSize += f.bytes_written
            })
            const totalSize = file.size
            const speed = Math.ceil(transfer.calculated_rate_kbps / 1024)
            const percentage = `${Math.round((currentSize / totalSize) * 100)}%`
            const timeRemaining = `Will be finished ${dayjs().to(
              dayjs().add(transfer.remaining_usec / 1000, "milliseconds")
            )}`

            dispatch({
              type: ASPERA_PROGRESS,
              uuid: transfer.uuid,
              current_size: currentSize,
              total_size: totalSize,
              timeRemaining,
              speed: `${speed.toFixed(0)} Mpbs`,
              percentage,
              file_name: file.name
            })
            if (getState().transfers[transfer.uuid].transfer_iteration_token % 30 === 0) {
              fetch(`${GLOBALS.API_URL}/downloads/`, {
                ...PUT_DEFAULTS,
                body: JSON.stringify({
                  transferUUID: transfer.uuid,
                  total_size: totalSize,
                  current_size: currentSize,
                  status: "Transferring"
                })
              })
            }
            break
          case "completed":
            const t = getState().transfers[transfer.uuid]

            if (t.status !== "Complete") dispatch(completed(transfer, file.name))

            break
          case "removed":
            dispatch(removeTransfer(transfer.uuid))
            break
          case "cancelled":
            dispatch(updateTransfer("status", "Paused", transfer.uuid))
            break
          case "failed":
            dispatch(updateTransfer("status", "Error", transfer.uuid))
            dispatch(updateTransfer("errorMsg", "Download failed", transfer.uuid))
            break
          default:
            break
        }
      }
    }
  })
}

const add = (transfer, projectID, file) => dispatch => {
  let size = 0
  dispatch({
    type: ADD_TRANSFER,
    uuid: transfer.uuid,
    transfer: {
      type: "download",
      method: "aspera",
      project_id: projectID,
      file_name: file.file_name,
      status: "Preparing",
      uuid: transfer.uuid,
      current_size: 0,
      total_size: transfer.files ? transfer.files.forEach(f => (size += f.bytes_expected)) : 0
    }
  })
  fetch(`${GLOBALS.API_URL}/downloads/`, {
    ...POST_DEFAULTS,
    body: JSON.stringify({
      transferUUID: transfer.uuid,
      fileName: file.file_name,
      fileID: file._id,
      fileType: file.type
    })
  })
}

const completed = (transfer, fileName) => (dispatch, getState) => {
  dispatch(updateTransfer("status", "Complete", transfer.uuid))
  dispatch(addGlobalMessage(`${fileName} download is complete`, "check_circle"))

  const OPTS = {
    ...PUT_DEFAULTS,
    body: JSON.stringify({
      total_size: transfer.bytes_expected,
      status: "Complete",
      transferUUID: transfer.uuid
    })
  }

  fetch(`${GLOBALS.API_URL}/downloads/`, OPTS)
    .then(res => res.json())
    .then(res => {
      // nothing to do
    })
    .catch(err => {
      throw new Error(err)
    })

  setTimeout(() => {
    dispatch(removeTransfer(transfer.uuid))
  }, 3000)
}

const setError = ({ message, code }) => dispatch => {}
