import { PREVIEW_DOCUMENT, CLOSE_DOCUMENT_PREVIEW } from "@actions/preview/documents"

export const previewDocument = document => dispatch => {
  dispatch({
    type: PREVIEW_DOCUMENT,
    payload: { document }
  })
}

export const closeDocumentPreview = () => dispatch => dispatch({ type: CLOSE_DOCUMENT_PREVIEW })
