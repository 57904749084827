import { get, post } from "@api/fetch"
import {
  GET_DOWNLOAD_LINK,
  TOGGLE_FILE_IMPORT_MODAL,
  IMPORT_FILE_INTO_ACCOUNT,
  GET_PACKAGE_FILES
} from "@actions/download_link/"
import { addGlobalMessage } from "@api/utils"

/**
 * Gets a download link with an array of download items
 *
 * @param {String} packageID
 * @returns {Function}
 */
export const getDownloadLink = packageID => dispatch => dispatch(get(GET_DOWNLOAD_LINK, `packages/${packageID}`))

/**
 * Gets a download link and passes back a password
 *
 * @param {String} packageID
 * @returns {Function}
 */
export const getDownloadLinkWithPassword = (packageID, password) => dispatch =>
  dispatch(
    post(GET_DOWNLOAD_LINK, `packages/${packageID}`, {
      password
    })
  )

/**
 * Gets the files related to a package
 *
 * @param {String} packageID
 * @param {String} folderID
 * @returns {Function}
 */
export const getPackageFiles = (packageID, folderID, skip, take) => dispatch =>
  dispatch(
    get(
      GET_PACKAGE_FILES.REQUEST,
      `packages/${packageID}/files?skip=${skip}&take=${take}${folderID ? "&folderID=" + folderID : ""}`
    )
  )

/**
 * Toggles the visibility of the modal used to import files into an account
 *
 * @param {Object} file
 * @returns {Function}
 */
export const toggleImportModal = file => dispatch => dispatch({ type: TOGGLE_FILE_IMPORT_MODAL, file })

/**
 * Imports a file into an account
 *
 * @param {Object} file
 * @param {String} projectID the project to import to
 * @param {String/Null} folderID the folder to place the file into
 * @returns {Function}
 */
export const importFileIntoAccount = (file, projectID, folderID) => (dispatch, getState) => {
  let packageID = getState().download_link._id
  dispatch(
    post(
      IMPORT_FILE_INTO_ACCOUNT,
      `packages/${packageID}/import/${projectID}`,
      {
        fileID: file._id,
        fileType: file.type,
        projectID,
        folderID
      },
      () => dispatch(addGlobalMessage("File Imported!", "success"))
    )
  )
}
