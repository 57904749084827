import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { updateFileRequest, selectFiles } from "@api/file_request/"
import Project from "@components/file_request/options/project"
import File from "@components/file_request/options/file"
import { Modal, Status } from "@bitcine/cs-theme"

class SelectExisting extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active_project_id: "",
      open: "project"
    }
  }
  close = () => {
    this.props.updateFileRequest("select_existing_cinesend_file_open", false)
  }
  render() {
    const { request } = this.props
    const { open } = this.state
    return (
      <Modal width={3} title={`Select ${open}`} open={request.select_existing_cinesend_file_open} onClose={this.close}>
        <Status pending={request.import_status === "PENDING"} error={request.import_status === "ERROR"}>
          {open === "file" ? (
            <File
              close={() => this.setState({ active_project_id: "", open: "project" })}
              selectFiles={(files, project_id) => this.props.selectFiles(files, project_id)}
              projectID={this.state.active_project_id}/>
          ) : (
            <Project setProject={_id => this.setState({ active_project_id: _id, open: "file" })}/>
          )}
        </Status>
      </Modal>
    )
  }
}

SelectExisting.propTypes = {
  request: PropTypes.object.isRequired,
  updateFileRequest: PropTypes.func.isRequired,
  selectFiles: PropTypes.func.isRequired
}

const mapStateToProps = ({ file_request }) => ({
  request: file_request
})

const mapDispatchToProps = dispatch => ({
  updateFileRequest: bindActionCreators(updateFileRequest, dispatch),
  selectFiles: bindActionCreators(selectFiles, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectExisting)
