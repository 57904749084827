import {
  GET_STORAGE_PLANS_FULFILLED,
  GET_STORAGE_PLAN_DETAILS_FULFILLED,
  UPDATE_NEW_PLAN,
  CHANGE_PLAN_FULFILLED,
  CHANGE_PLAN_PENDING,
  CHANGE_PLAN_FAILED
} from "@actions/account/plan"

const initialState = {
  storage_plans: null,
  details: null,
  newPlan: {
    selected_plan: "",
    credit_card_id: "",
    visible: false,
    status: "READY",
    billing_cycle: "yearly",
    organization_name: ""
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_STORAGE_PLANS_FULFILLED:
      return {
        ...state,
        storage_plans: action.payload
      }

    case GET_STORAGE_PLAN_DETAILS_FULFILLED:
      return {
        ...state,
        details: action.payload
      }

    case UPDATE_NEW_PLAN:
      return {
        ...state,
        newPlan: {
          ...state.newPlan,
          [action.key]: action.value
        }
      }

    case CHANGE_PLAN_FULFILLED:
      return {
        ...state,
        newPlan: initialState.newPlan
      }

    case CHANGE_PLAN_FAILED:
      return {
        ...state,
        newPlan: {
          ...state.newPlan,
          status: "ERROR"
        }
      }

    case CHANGE_PLAN_PENDING:
      return {
        ...state,
        newPlan: {
          ...state.newPlan,
          status: "PENDING"
        }
      }

    default:
      return state
  }
}
