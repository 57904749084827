import React from "react"
import ErrorBoundary from "@components/error_boundaries/"
import DownloadLink from "./link"

class DownloadLinkWrap extends React.Component {
  render() {
    return (
      <div>
        <ErrorBoundary>
          <DownloadLink {...this.props}/>
        </ErrorBoundary>
      </div>
    )
  }
}

export default DownloadLinkWrap
