import { POST_DEFAULTS } from "@constants"
import { initializeHttpUpload } from "@api/transfer/http/upload"
import { GET_FILES_FULFILLED } from "@actions/project/files"
import { GLOBALS } from "@src/config"

/**
 * Steps:
 *
 * 		- Sort and merge the files relative paths
 * 			into a directory tree structure that saves
 * 			a reference to the files index in the original
 * 			file array
 *
 * 		- Send the structure to the server and recursively
 * 			create all the folders in the database. Return an
 * 			associative array of fileIndex => FolderId. ie:
 * 				[
 * 					{ fileIndex: 0, folderId: <id1> },
 * 					{ fileIndex: 1, folderId: <id1> },
 * 					{ fileIndex: 2, folderId: <id2> },
 * 					{ fileIndex: 3, folderId: <id2> },
 * 					{ fileIndex: 4, folderId: <id2> },
 * 					{ fileIndex: 5, folderId: <id3> },
 * 					...
 * 				]
 *
 * 		- Loop through the response (from above) and
 * 			dispatch our fineUploader function with each
 * 			response items index + folderId
 */

export const initFolderUpload = (files, projectID, folderID = null) => dispatch => {
  const buildFolderTree = buildFolderStructureOnServer(sortAndMerge(files), projectID, folderID)

  buildFolderTree.then(res => {
    dispatch({
      type: GET_FILES_FULFILLED,
      payload: {
        files: res.project_files.files,
        breadcrumb: res.project_files.breadcrumb
      }
    })

    for (let i = 0; i < res.files.length; i++) {
      dispatch(
        initializeHttpUpload(files[res.files[i].file_index], {
          project_id: projectID,
          folder_id: res.files[i].folder_id
        })
      )
    }
  })
}

/**
 * Sorts and merges an array of files into their respective folder tree
 *
 * 	example input: [
 * 		0: '/uploads/files/dir1/file.jpg'
 * 		1: '/uploads/files/dir1/file2.jpg'
 * 		2: '/uploads/files/file3.jpg'
 * 	  3: '/uploads/files/file4.jpg'
 * 		4: '/uploads/files/file5.jpg'
 * 		5: '/uploads/file6.jpg'
 * 		6: '/uploads/file7.jpg'
 * 	]
 *
 * 	output:
 * 		{
 * 			name: 'uploads',
 * 			children: [
 * 				{
 * 					name: 'files',
 * 					children: [
 * 						{
 * 							name: 'dir1',
 * 							children: [],
 * 							files: [0, 1]
 * 						}
 * 					],
 * 					files: [2, 3, 4]
 * 				}
 * 			],
 * 			files: [5,6]
 * 		}
 */

export const sortAndMerge = files => {
  const hierarchy = {}

  // Loop through all the input files
  files.map((file, i) => {
    /**
     * Extract the path into it's parts
     *
     * /some/path/within/file.jpg => ['some', 'path', 'within']
     */
    const relPath = !file.webkitRelativePath.length
      ? file.fullPath.charAt(0) === "/"
        ? file.fullPath.substring(1)
        : file.fullPath
      : file.webkitRelativePath

    const paths = relPath.split("/").slice(0, -1)

    /**
     * Init the parent folder
     */
    let parentFolder = null

    /**
     * Loop through the paths parts and sort/merge
     *
     * 1: Check if we're in a sub dir
     * 		Yes:
     * 				- Check if the cur path is in the hierarchy
     * 						Yes: Set it as parent folder
     * 						No: Create it in the heirarchy
     * 				- Push cur files to parent files array
     * 		No:
     * 				- Check if child path exists in heirarchy
     * 						Yes: Set the parent folder as the child
     * 						No: Create the child and set
     * 				- Push cur files to parent files array
     */
    paths.map(path => {
      if (!parentFolder) {
        if (!hierarchy[path]) {
          hierarchy[path] = {
            name: path,
            children: {},
            files: []
          }
        }
        parentFolder = hierarchy[path]
      } else {
        if (!parentFolder.children[path]) {
          parentFolder.children[path] = {
            name: path,
            children: {},
            files: []
          }
        }
        parentFolder = parentFolder.children[path]
      }
    })
    parentFolder.files.push(i)
  })

  return hierarchy
}

/**
 * Sends the structure to the server and returns a promise
 *
 * @param {*} structure
 * @param {*} projectID
 * @param {*} folderID
 */
export const buildFolderStructureOnServer = (structure, projectID, folderID) => {
  return new Promise((resolve, reject) => {
    fetch(`${GLOBALS.API_URL}/projects/${projectID}/build_folder_tree`, {
      ...POST_DEFAULTS,
      body: JSON.stringify({
        folder_id: folderID,
        structure
      })
    })
      .then(res => {
        if (res.ok) return res.json()
        else reject(res.status)
      })
      .then(res => resolve(res))
  })
}
