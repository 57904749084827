import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Reviewer from "./reviewer"

class Reviewers extends React.Component {
  render() {
    return (
      <React.Fragment>
        {Object.keys(this.props.list).map(key => (
          <Reviewer key={key} reviewer={this.props.list[key]} reviewerKey={key}/>
        ))}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  list: state.preview.send.email.list
})

Reviewers.propTypes = {
  list: PropTypes.object.isRequired
}

export default connect(
  mapStateToProps,
  null
)(Reviewers)
