import { GLOBALS } from '../../config'

export const DEFAULTS = {
  credentials: "include",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
}

const POST_DEFAULTS = {
  method: "POST",
  ...DEFAULTS
}

const DELETE_DEFAULTS = {
  method: "DELETE",
  ...DEFAULTS
}

const PUT_DEFAULTS = {
  method: "PUT",
  ...DEFAULTS
}

export const post = (type, route, payload, callback, errorCb) => dispatch =>
  dispatch(api(type, route, getPayload(POST_DEFAULTS, payload, "post"), callback, errorCb, payload))
export const get = (type, route, payload, callback, errorCb) => dispatch =>
  dispatch(api(type, route, getPayload(DEFAULTS, null, "get"), callback, errorCb, payload))
export const put = (type, route, payload, callback, errorCb) => dispatch =>
  dispatch(api(type, route, getPayload(PUT_DEFAULTS, payload, "put"), callback, errorCb, payload))
export const del = (type, route, payload, callback, errorCb) => dispatch =>
  dispatch(api(type, route, getPayload(DELETE_DEFAULTS, payload, "delete"), callback, errorCb, payload))

const getPayload = (defaults, payload, type) => ({
  ...defaults,
  ...getBody(payload, type)
})

const getBody = (payload = {}, type) => {
  let stripped = {}
  if (payload && type !== "get") {
    stripped = Object.keys(payload)
      .filter(key => !key.includes("__REDUX_"))
      .reduce((all, key) => ({ ...all, [key]: payload[key] }), {})
  }
  return type === "get" ? {} : { body: JSON.stringify(stripped) }
}

/**
 * API global function
 * - Handles all promise-based-redux fetch() calls throughout the app
 * - Note: This cant be called directly, one of post, get, put or del above should be called
 *
 * @param {String} - a redux action (eg UPDATE_USER)
 *  - the promise middleware will automatically add UPDATE_USER_PENDING, UPDATE_USER_FULFILLED and UPDATE_USER_FAILED during the promise
 * @param {String} - the api route to call
 * @param {Object} - the fetch() payload
 * @param {Func} - a callback function to call if the promise is resolved
* @param {Func} - an error callback function to call if the promise is rejected
 * @returns {Promise}
 */

const api = (type, route, payload, callback, errorCb, json) => dispatch =>
  dispatch({
    type,
    meta: json,
    payload: new Promise((resolve, reject) =>
      fetch(GLOBALS.API_URL + '/' + route, payload).then(res => {
        const ok = res.ok
        const status = res.status
        res.json().then(json => {
          if (ok) {
            if (callback && typeof callback === 'function') {
              callback(json)
            }
            resolve(json)
          }
          else {
            if (errorCb && typeof errorCb === 'function') {
              errorCb(json)
            }
            reject({
              ...json,
              status
            })
          }
        })
      }).catch(error => {
        if (errorCb && typeof errorCb === 'function') {
          errorCb(error)
        }
        reject(error)
      })
    )
  })