const config = {
  borderRadius: 3,
  height: 490,
  width: 670,

  slickSettings: {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }
}

export default config
