/**
 *  Credit Card Input Component
 *
 *  Props:
 *     testid               |  String  |  The id used for cypress tests
 *     value                |  String  |  The <input> value
 *     id                   |  String  |  The HTML id
 *     extraClasses         |  String  |  Any extra css classes to apply to the <input>
 *     onChange             |  Func    |  Fires when <input> value changes. Returns actual value
 *     onCardStatusChange   |  Func    |  Fires on card status change. (incomplete | unsupported | invalid | valid)
 *     isGiftCard           |  Bool    |  Set to true if using as a gift card field
 *
 *  Example:
 *   <CreditCardInput
 *     testid='example_testing_id'
 *     id='example_id'
 *     value={this.state.cc}
 *     extraClasses={hasError ? 'error' : ''}
 *     onChange={value => this.setState({ cc: value })}
 *     onCardStatusChange={status => status === 'valid' ? doSomething() : doNothing()}
 *     isGiftCard={true | false}
 *   />
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { ReactComponent as AmexSVG } from "@public/payment/svgs/amex-accepted.svg"
import { ReactComponent as MastercardSVG } from "@public/payment/svgs/mastercard-accepted.svg"
import { ReactComponent as VisaSVG } from "@public/payment/svgs/visa-accepted.svg"
import giftcardSVG from "@public/payment/svgs/gift-card-accepted.svg"
import FormError from "@components/formerror"
import "./styles.scss"
import { cardValidator, cardTypes, cardErrorMessages, cardStatus } from "./validator"

class CreditCardInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      number: "",
      card: null,
      isPotentiallyValid: false,
      isValid: false,
      maxLength: 16,
      cardIsSupported: true,
      isFilledAndInvalid: false
    }

    this.formattedNumber = this.formattedNumber.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
  }
  componentDidUpdate(nextProps) {
    if (nextProps.isGiftCard !== this.props.isGiftCard && this.input) {
      this.input.focus()
    }
  }
  formattedNumber() {
    if (this.state.card && this.state.card.type === cardTypes.AMERICAN_EXPRESS) {
      const n = this.state.number
      return n.length > 10
        ? [n.substr(0, 4), n.slice(4, 10), n.substr(10)].join(" ")
        : n.length > 4
          ? [n.substr(0, 4), n.slice(4)].join(" ")
          : n
    }

    const matches = this.state.number.match(/\d{4,16}/g)
    const match = (matches && matches[0]) || ""
    const parts = []

    for (var i = 0; i < match.length; i += 4) {
      parts.push(match.substring(i, i + 4))
    }

    return parts.length ? parts.join(" ") : this.state.number
  }
  handleOnChange(e) {
    const value = e.target.value.replace(/\s+/g, "").replace(/[^0-9]/gi, "")
    const l = value.length
    const ml = this.state.maxLength

    if (l - 1 < ml) {
      this.props.onChange(value)
    }

    if (l < ml) {
      this.props.onCardStatusChange(cardStatus.INCOMPLETE)
    } else if (!this.state.cardIsSupported) {
      this.props.onCardStatusChange(cardStatus.UNSUPPORTED)
    } else if (this.state.isFilledAndInvalid) {
      this.props.onCardStatusChange(cardStatus.INVALID)
    } else if (this.state.isValid) {
      this.props.onCardStatusChange(cardStatus.VALID)
    }
  }
  render() {
    const { testid, id, extraClasses, isGiftCard } = this.props
    const { card, number, cardIsSupported, isFilledAndInvalid } = this.state

    const isAmex = card && card.type === cardTypes.AMERICAN_EXPRESS
    const isVisa = card && card.type === cardTypes.VISA
    const isMC = card && card.type === cardTypes.MASTERCARD
    const isGC = card && card.type === cardTypes.CINESEND_GIFT_CARD

    const calcIdx = (groupIdx, rowIdx, amex = false) => {
      return groupIdx * (amex ? (!groupIdx ? 0 : groupIdx === 1 ? 4 : 5) : 4) + (rowIdx + 1)
    }

    return (
      <div>
        <div className='relative'>
          <div className={`type ${cardIsSupported && isAmex ? "show" : ""}`}>
            <AmexSVG alt='Amex'/>
          </div>

          <div className={`type ${cardIsSupported && isVisa ? "show" : ""}`}>
            <VisaSVG alt='Visa'/>
          </div>

          <div className={`type ${cardIsSupported && isMC ? "show" : ""}`}>
            <MastercardSVG alt='MasterCard'/>
          </div>

          <div className={`type ${cardIsSupported && isGC ? "show" : ""}`}>
            <img className='cs-img' src={giftcardSVG} alt='Gift Card'/>
          </div>

          <div className={"underlines"}>
            {(isAmex ? [4, 6, 5] : [4, 4, 4, 4]).map((groupCount, gci) => (
              <div
                key={gci}
                className={`
								  underlineGroup 
									${isAmex && gci === 1 ? "underlineGroupAmex2" : ""}
									${isAmex && gci === 2 ? "underlineGroupAmex3" : ""}
								`}>
                {[...Array(groupCount)].map((e, ri) => (
                  <div
                    key={ri}
                    className={`
											underline
											${calcIdx(gci, ri, isAmex) <= number.length ? "hideUnderline" : ""}
										`}/>
                ))}
              </div>
            ))}
          </div>

          <input
            type='text'
            data-testid={testid}
            id={id}
            ref={ref => (this.input = ref)}
            autoFocus
            value={this.formattedNumber()}
            className={`
              cs-input
							col-12 no-min-width
							input 
							${extraClasses}
							${isGiftCard ? "" : isFilledAndInvalid || !cardIsSupported ? "error" : ""}
						`}
            onChange={this.handleOnChange}/>
        </div>
        {!isGiftCard && (
          <FormError
            visible={isFilledAndInvalid || !cardIsSupported}
            msg={!cardIsSupported ? cardErrorMessages.UNSUPPORTED_CARD : cardErrorMessages.INVALID_NUMBER}/>
        )}
      </div>
    )
  }
}

CreditCardInput.defaultProps = {
  isGiftCard: false
}

CreditCardInput.getDerivedStateFromProps = (nextProps, prevState) => {
  if (typeof nextProps.value === "undefined") {
    return null
  }

  try {
    const allowedCards = nextProps.isGiftCard
      ? [cardTypes.CINESEND_GIFT_CARD]
      : [cardTypes.VISA, cardTypes.MASTERCARD, cardTypes.AMERICAN_EXPRESS]
    const parsed = cardValidator.number(nextProps.value)
    const { card } = parsed
    const maxLength = card ? (card.type === cardTypes.AMERICAN_EXPRESS ? 15 : 16) : 16
    const number = nextProps.value.replace(/\s+/g, "").replace(/[^0-9]/gi, "")

    return {
      number,
      card,
      maxLength,
      isPotentiallyValid: card ? parsed.isPotentiallyValid : false,
      isValid: nextProps.isGiftCard || parsed.isValid,
      cardIsSupported: !card || allowedCards.includes(card.type),
      isFilledAndInvalid: nextProps.isGiftCard ? false : number.length >= maxLength && !parsed.isValid
    }
  }
  catch (e) {
    console.log(e)
    return null
  }
}

CreditCardInput.propTypes = {
  testid: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  extraClasses: PropTypes.string,
  onChange: PropTypes.func,
  onCardStatusChange: PropTypes.func,
  isGiftCard: PropTypes.bool
}

export default CreditCardInput
