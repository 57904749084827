import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "react-emotion"
import { bindActionCreators } from "redux"
import { closeDocumentPreview } from "@api/project/files/preview"
import { can, DOWNLOAD_FILES, SHARE_FILES, MANAGE_SCREENERS } from "@src/helpers/authorization"
import DownloadButtonDropdown from "./download_button_dropdown"
import ShareButtonDropdown from "./share_button_dropdown"

const StyledHeader = styled("header")`
  height: 5em;
`

class Header extends React.Component {
  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyBind)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyBind)
  }
  handleKeyBind = e => {
    if (e.key === "Escape") {
      this.props.closeDocumentPreview()
      return false
    }
  }
  render() {
    const { closeDocumentPreview, hideActions } = this.props
    return (
      <StyledHeader className='white px3 flex items-center'>
        <span className='material-icons white mr2'>image</span>
        <h4 className='flex-auto'>{this.props.document.name}</h4>
        {this.props.document.url && this.props.document.key && !hideActions && (
          <div>
            {(this.props.canShare || (this.props.canSendScreener && this.props.document.screener)) && (
              <ShareButtonDropdown/>
            )}
            {this.props.canDownload && <DownloadButtonDropdown/>}
          </div>
        )}
        <button
          onClick={() => closeDocumentPreview()}
          className='cs-button link flex items-center material-icons ml2'
          style={{ color: "#fff" }}>
          close
        </button>
      </StyledHeader>
    )
  }
}

Header.propTypes = {
  closeDocumentPreview: PropTypes.func.isRequired,
  document: PropTypes.object.isRequired,
  canDownload: PropTypes.bool.isRequired,
  canShare: PropTypes.bool.isRequired,
  canSendScreener: PropTypes.bool.isRequired,
  hideActions: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  document: state.documentPreviewer.document,
  canDownload: can(state.user.auth, [DOWNLOAD_FILES]),
  canShare: can(state.user.auth, [SHARE_FILES]),
  canSendScreener: can(state.user.auth, [MANAGE_SCREENERS])
})

const mapDispatchToProps = dispatch => ({
  closeDocumentPreview: bindActionCreators(closeDocumentPreview, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
