export const GET_FILES = "GET_FILES"
export const GET_FILES_PENDING = "GET_FILES_PENDING"
export const GET_FILES_FAILED = "GET_FILES_REJECTED"
export const GET_FILES_FULFILLED = "GET_FILES_FULFILLED"

export const SET_FILES = "SET_FILES"

export const UPDATE_FILES = "UPDATE_FILES"

export const UPDATE_FILE = "UPDATE_FILE"

export const UPDATE_FILES_MODAL = "UPDATE_FILES_MODAL"

export const TOGGLE_MODAL_VISIBILITY = "TOGGLE_MODAL_VISIBILITY"

export const DELETE_FILE = "DELETE_FILE"
export const DELETE_FILE_PENDING = "DELETE_FILE_PENDING"
export const DELETE_FILE_FAILED = "DELETE_FILE_REJECTED"
export const DELETE_FILE_FULFILLED = "DELETE_FILE_FULFILLED"

export const SAVE_FILE = "SAVE_FILE"
export const SAVE_FILE_PENDING = "SAVE_FILE_PENDING"
export const SAVE_FILE_FULFILLED = "SAVE_FILE_FULFILLED"
export const SAVE_FILE_FAILED = "SAVE_FILE_REJECTED"

export const GET_DOWNLOAD_FILE_TOKEN = "GET_DOWNLOAD_FILE_TOKEN"

export const UPDATE_SHARED_EMAIL = "UPDATE_SHARED_EMAIL"
export const ADD_SHARED_EMAIL = "ADD_SHARED_EMAIL"
export const REMOVE_SHARED_EMAIL = "REMOVE_SHARED_EMAIL"
export const UPDATE_SHARE_LABEL = "UPDATE_SHARE_LABEL"
export const UPDATE_ASPERA_SHARE_LABEL = "UPDATE_ASPERA_SHARE_LABEL"

export const CREATE_PACKAGE = "CREATE_PACKAGE"
export const CREATE_PACKAGE_PENDING = "CREATE_PACKAGE_PENDING"
export const CREATE_PACKAGE_FULFILLED = "CREATE_PACKAGE_FULFILLED"
export const CREATE_PACKAGE_FAILED = "CREATE_PACKAGE_REJECTED"

export const VIEW_FILE_INFO = "VIEW_FILE_INFO"

export const GET_FOLDER_CONTENT = "GET_FOLDER_CONTENT"
export const GET_FOLDER_CONTENT_PENDING = "GET_FOLDER_CONTENT_PENDING"
export const GET_FOLDER_CONTENT_FAILED = "GET_FOLDER_CONTENT_REJECTED"
export const GET_FOLDER_CONTENT_FULFILLED = "GET_FOLDER_CONTENT_FULFILLED"

export const TOGGLE_FOLDER_CONTENT_VISIBILITY = "TOGGLE_FOLDER_CONTENT_VISIBILITY"

export const CLOSE_FILE_SHARING_MODAL = "CLOSE_FILE_SHARING_MODAL"

export const UPDATE_ASPERA_SHARING = "UPDATE_ASPERA_SHARING"
export const GET_ASPERA_ORDER = "GET_ASPERA_ORDER"
export const GET_ASPERA_ORDER_PENDING = "GET_ASPERA_ORDER_PENDING"
export const GET_ASPERA_ORDER_FAILED = "GET_ASPERA_ORDER_REJECTED"
export const GET_ASPERA_ORDER_FULFILLED = "GET_ASPERA_ORDER_FULFILLED"

export const GET_ASPERA_SHARING_PRICES = "GET_ASPERA_SHARING_PRICES"
export const GET_ASPERA_SHARING_PRICES_PENDING = "GET_ASPERA_SHARING_PRICES_PENDING"
export const GET_ASPERA_SHARING_PRICES_FAILED = "GET_ASPERA_SHARING_PRICES_REJECTED"
export const GET_ASPERA_SHARING_PRICES_FULFILLED = "GET_ASPERA_SHARING_PRICES_FULFILLED"

export const REPLACE_FILE = "REPLACE_FILE"
export const UPDATE_FILE_PREVIEW_IMAGE = "UPDATE_FILE_PREVIEW_IMAGE"

export const RESET_PAGINATION = "RESET_PAGINATION"
export const UPDATE_SEARCH = "UPDATE_SEARCH"

export const MOVE_FILES_TO_PROJECT = "MOVE_FILES_TO_PROJECT"
export const MOVE_FILES_TO_PROJECT_PENDING = "MOVE_FILES_TO_PROJECT_PENDING"
export const MOVE_FILES_TO_PROJECT_FAILED = "MOVE_FILES_TO_PROJECT_REJECTED"
export const MOVE_FILES_TO_PROJECT_FULFILLED = "MOVE_FILES_TO_PROJECT_FULFILLED"
