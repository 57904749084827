import React from "react"
import PropTypes from "prop-types"
import { DragLayer } from "react-dnd"
import shouldPureComponentUpdate from "@src/helpers/shouldPureComponentUpdate"
import Icon from "@components/files/icon/"

const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%"
}

class CustomDragger extends React.Component {
  shouldComponentUpdate = shouldPureComponentUpdate
  getItemStyles() {
    if (!this.props.clientOffset) {
      return {
        display: "none"
      }
    } else {
      const transform = `translate(${this.props.clientOffset.x}px, ${this.props.clientOffset.y}px)`
      return {
        transform,
        WebkitTransform: transform,
        boxShadow: "inset 0 -1px 0 0 #ebeced, 0 2px 7px 0 #c7cdcf"
      }
    }
  }
  render() {
    if (this.props.itemType === "__NATIVE_FILE__") {
      return null
    }
    if (this.props.item && !this.props.item.type) {
      return null
    }
    return !this.props.isDragging ? null : (
      <div style={layerStyles}>
        <div className='bg-white rounded p2 flex items-center' style={{ ...this.getItemStyles(), width: "340px" }}>
          <Icon data={this.props.item} folderID={this.props.folderID} projectID={this.props.projectID}/>
          <div className='pl2 truncate'>{this.props.item.name}</div>
        </div>
      </div>
    )
  }
}

CustomDragger.propTypes = {
  item: PropTypes.object,
  itemType: PropTypes.string,
  currentOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }),
  isDragging: PropTypes.bool.isRequired,
  clientOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }),
  projectID: PropTypes.string.isRequired,
  folderID: PropTypes.string
}

const collect = monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  isDragging: monitor.isDragging(),
  clientOffset: monitor.getClientOffset()
})

export default DragLayer(collect)(CustomDragger)
