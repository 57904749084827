/**
 * Sorts an array of files from smallest to largest
 *
 * @param {files} array
 */

const sortFiles = files => {
  function compare(a, b) {
    if (a.size < b.size) return -1
    if (a.size > b.size) return 1

    return 0
  }

  return files.sort(compare)
}

export default sortFiles
