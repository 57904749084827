export const GET_SCREENER_ROOMS = "GET_SCREENER_ROOMS"
export const GET_SCREENER_ROOMS_PENDING = "GET_SCREENER_ROOMS_PENDING"
export const GET_SCREENER_ROOMS_FULFILLED = "GET_SCREENER_ROOMS_FULFILLED"

export const GET_SCREENER_ROOM = "GET_SCREENER_ROOM"
export const GET_SCREENER_ROOM_PENDING = "GET_SCREENER_ROOM_PENDING"
export const GET_SCREENER_ROOM_FULFILLED = "GET_SCREENER_ROOM_FULFILLED"

export const GET_SCREENER_ROOM_AVAILABLE_SCREENERS = "GET_SCREENER_ROOM_AVAILABLE_SCREENERS"
export const GET_SCREENER_ROOM_AVAILABLE_SCREENERS_PENDING = "GET_SCREENER_ROOM_AVAILABLE_SCREENERS_PENDING"
export const GET_SCREENER_ROOM_AVAILABLE_SCREENERS_FULFILLED = "GET_SCREENER_ROOM_AVAILABLE_SCREENERS_FULFILLED"

export const GET_SCREENER_ROOM_AVAILABLE_PROJECTS = "GET_SCREENER_ROOM_AVAILABLE_PROJECTS"
export const GET_SCREENER_ROOM_AVAILABLE_PROJECTS_PENDING = "GET_SCREENER_ROOM_AVAILABLE_PROJECTS_PENDING"
export const GET_SCREENER_ROOM_AVAILABLE_PROJECTS_FULFILLED = "GET_SCREENER_ROOM_AVAILABLE_PROJECTS_FULFILLED"

export const GET_ALL_SCREENERS = "GET_ALL_SCREENERS"
export const GET_ALL_SCREENERS_PENDING = "GET_ALL_SCREENERS_PENDING"
export const GET_ALL_SCREENERS_FULFILLED = "GET_ALL_SCREENERS_FULFILLED"

export const UPDATE_SEARCH_STRING = "UPDATE_SEARCH_STRING"
export const UPDATE_NEW_SCREENER_ROOM = "UPDATE_NEW_SCREENER_ROOM"

export const CREATE_SCREENER_ROOM = "CREATE_SCREENER_ROOM"
export const CREATE_SCREENER_ROOM_PENDING = "CREATE_SCREENER_ROOM_PENDING"
export const CREATE_SCREENER_ROOM_FULFILLED = "CREATE_SCREENER_ROOM_FULFILLED"

export const TOGGLE_CREATE_ROOM_MODAL = "TOGGLE_CREATE_ROOM_MODAL"
export const TOGGLE_ANALYTICS_MODAL = "TOGGLE_ANALYTICS_MODAL"

export const ADD_SCREENERS_TO_ROOM = "ADD_SCREENERS_TO_ROOM"
export const ADD_SCREENERS_TO_ROOM_PENDING = "ADD_SCREENERS_TO_ROOM_PENDING"
export const ADD_SCREENERS_TO_ROOM_FULFILLED = "ADD_SCREENERS_TO_ROOM_FULFILLED"

export const ADD_PROJECTS_TO_ROOM = "ADD_PROJECTS_TO_ROOM"
export const ADD_PROJECTS_TO_ROOM_PENDING = "ADD_PROJECTS_TO_ROOM_PENDING"
export const ADD_PROJECTS_TO_ROOM_FULFILLED = "ADD_PROJECTS_TO_ROOM_FULFILLED"

export const GET_SCREENER_ANALYTICS = "GET_SCREENER_ANALYTICS"
export const GET_SCREENER_ANALYTICS_PENDING = "GET_SCREENER_ANALYTICS_PENDING"
export const GET_SCREENER_ANALYTICS_FULFILLED = "GET_SCREENER_ANALYTICS_FULFILLED"

export const REMOVE_SCREENER_FROM_ROOM = "REMOVE_SCREENER_FROM_ROOM"
export const REMOVE_SCREENER_FROM_ROOM_PENDING = "REMOVE_SCREENER_FROM_ROOM_PENDING"
export const REMOVE_SCREENER_FROM_ROOM_FULFILLED = "REMOVE_SCREENER_FROM_ROOM_FULFILLED"

export const REMOVE_PROJECT_FROM_ROOM = "REMOVE_PROJECT_FROM_ROOM"
export const REMOVE_PROJECT_FROM_ROOM_PENDING = "REMOVE_PROJECT_FROM_ROOM_PENDING"
export const REMOVE_PROJECT_FROM_ROOM_FULFILLED = "REMOVE_PROJECT_FROM_ROOM_FULFILLED"

export const UPDATE_SCREENER_ROOM = "UPDATE_SCREENER_ROOM"
export const UPDATE_SCREENER_ROOM_PENDING = "UPDATE_SCREENER_ROOM_PENDING"
export const UPDATE_SCREENER_ROOM_FULFILLED = "UPDATE_SCREENER_ROOM_FULFILLED"

export const EXPIRE_ALL_VIEWERS = "EXPIRE_ALL_VIEWERS"

export const DELETE_SCREENER_ROOM = "DELETE_SCREENER_ROOM"
export const DELETE_SCREENER_ROOM_PENDING = "DELETE_SCREENER_ROOM_PENDING"
export const DELETE_SCREENER_ROOM_FULFILLED = "DELETE_SCREENER_ROOM_FULFILLED"

export const GENERATE_PREVIEW = "GENERATE_PREVIEW"
export const GENERATE_PREVIEW_PENDING = "GENERATE_PREVIEW_PENDING"
export const GENERATE_PREVIEW_FULFILLED = "GENERATE_PREVIEW_FULFILLED"

export const TOGGLE_ADD_VIEWERS_MODAL = "TOGGLE_ADD_VIEWERS_MODAL"

export const SET_SCREENER_ROOM = "SET_SCREENER_ROOM"

export const TOGGLE_SCREENER_ROOM_PERMISSIONS_MODAL = "TOGGLE_SCREENER_ROOM_PERMISSIONS_MODAL"

export const SAVE_SCREENER_GROUP_ACCESS = "SAVE_SCREENER_GROUP_ACCESS"
export const SAVE_SCREENER_GROUP_ACCESS_PENDING = "SAVE_SCREENER_GROUP_ACCESS_PENDING"
export const SAVE_SCREENER_GROUP_ACCESS_FULFILLED = "SAVE_SCREENER_GROUP_ACCESS_FULFILLED"

export const UPDATE_SCREENER_PASSWORD = "UPDATE_SCREENER_PASSWORD"
export const UPDATE_SCREENER_PASSWORD_PENDING = "UPDATE_SCREENER_PASSWORD_PENDING"
export const UPDATE_SCREENER_PASSWORD_FULFILLED = "UPDATE_SCREENER_PASSWORD_FULFILLED"

export const TOGGLE_SCREENER_GROUP_DELETE_MODAL = "TOGGLE_SCREENER_GROUP_DELETE_MODAL"

export const UPDATE_SCREENER = "UPDATE_SCREENER"
export const UPDATE_SCREENER_FULFILLED = "UPDATE_SCREENER_FULFILLED"

export const DUPLICATE_SCREENER_ROOM = "DUPLICATE_SCREENER_ROOM"
export const DUPLICATE_SCREENER_ROOM_FULFILLED = "DUPLICATE_SCREENER_ROOM_FULFILLED"