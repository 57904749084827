import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Slider from "react-slick"
import load_styles from "@helpers/load_styles"
import unload_styles from "@helpers/unload_styles"
import { toggleOnboardingModal } from "@api/modals"
import { saveAuthUser } from "@api/account/auth"
import WelcomeSlide from "./slides/welcome"
import ProjectsSlide from "./slides/projects"
import FilesSlide from "./slides/files"
import AsperaSlide from "./slides/aspera"
import TeamSlide from "./slides/team"
import OrderSlide from "./slides/order"
import ScreenersSlide from "./slides/screeners"
import config from "./config"
import 'styled-components/macro'

class OnboardingSlideshow extends React.Component {
  constructor() {
    super()
    this.state = {
      stylesLoaded: false,
      onLastSlide: false,
      onFirstSlide: true
    }
  }
  componentDidMount() {
    document.body.style = "overflow: hidden;"

    load_styles("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css", "slick_main_style", () => {
      load_styles(
        "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css",
        "slick_theme_style",
        () => {
          this.setState({ stylesLoaded: true })
        }
      )
    })
  }
  componentWillUnmount() {
    document.body.style = ""
    unload_styles("slick_main_style")
    unload_styles("slick_theme_style")
  }
  handleSlideChange = (cIdx, idx) => {
    const slideCount = this.props.isOrganization ? 7 : 6
    const currentSlide = idx + 1

    this.setState({
      onLastSlide: slideCount === currentSlide,
      onFirstSlide: idx === 0
    })
  }
  handleClose = () => {
    this.props.toggleOnboardingModal()
    this.props.saveAuthUser("onboarded", true)
  }
  render() {
    return (
      this.state.stylesLoaded && (
        <div
          css={`
            z-index: 1000000;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: -moz-radial-gradient(
              center,
              ellipse cover,
              rgba(124, 126, 127, 1) 0%,
              rgba(0, 0, 0, 0.86) 100%
            );
            background: -webkit-radial-gradient(
              center,
              ellipse cover,
              rgba(124, 126, 127, 1) 0%,
              rgba(0, 0, 0, 0.86) 100%
            );
            background: radial-gradient(ellipse at center, rgba(124, 126, 127, 1) 0%, rgba(0, 0, 0, 0.86) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7c7e7f', endColorstr='#db000000',GradientType=1 );
          `}>
          <div
            css={`
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: ${config.width + 150}px;
              height: ${config.height}px;
            `}>
            <Slider
              {...config.slickSettings}
              nextArrow={
                <button type='button' data-role='none' className={`cs-button slick-arrow slick-next`}>
                  <span className='material-icons'>keyboard_arrow_right</span>
                </button>
              }
              prevArrow={
                <button
                  type='button'
                  data-role='none'
                  className={`cs-button slick-arrow slick-prev`}
                  disabled={this.state.onFirstSlide}>
                  <span className='material-icons'>keyboard_arrow_left</span>
                </button>
              }
              beforeChange={this.handleSlideChange}>
              <WelcomeSlide/>
              <ProjectsSlide/>
              <FilesSlide/>
              <AsperaSlide/>
              {this.props.isOrganization && <TeamSlide/>}
              <OrderSlide/>
              <ScreenersSlide/>
            </Slider>

            <div
              css={`
                position: absolute;
                bottom: -38px;
                right: 75px;
                color: #fff;
              `}>
              <button
                className='cs-button link'
                css={`
                  color: #fff !important;
                  opacity: 0.6;
                `}
                onClick={this.handleClose}>
                {this.state.onLastSlide ? "Close" : "Skip"}
              </button>
            </div>

            {this.state.onLastSlide && (
              <button
                className='cs-button'
                css={`
                  z-index: 1001;
                  position: absolute;
                  top: 50%;
                  right: 0px;
                  height: 40px !important;
                  width: 40px !important;
                  min-height: 40px !important;
                  min-width: 40px !important;
                  transform: translateY(-50%);
                  padding: 0;
                  border-radius: 20px;
                `}
                onClick={this.handleClose}>
                <i className='material-icons'>check</i>
              </button>
            )}
          </div>
        </div>
      )
    )
  }
}

OnboardingSlideshow.propTypes = {
  toggleOnboardingModal: PropTypes.func.isRequired,
  isOrganization: PropTypes.bool.isRequired,
  saveAuthUser: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  user: state.user,
  isOrganization: state.user.auth.organization_id !== null
})

const mapDispatchToProps = dispatch => ({
  toggleOnboardingModal: bindActionCreators(toggleOnboardingModal, dispatch),
  saveAuthUser: bindActionCreators(saveAuthUser, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingSlideshow)
