import React from "react"
import PropTypes from "prop-types"
import { ButtonDropdown } from "@bitcine/cs-theme"

class BreadCrumb extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: window.innerWidth,
      hidden: false
    }
    this.expectedHeight = 35
    this.handleResize = this.handleResize.bind(this)
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }
  handleResize() {
    this.setState({ width: window.innerWidth })
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      Array.isArray(this.props.breadcrumb) &&
      (this.props.breadcrumb.length !== prevProps.breadcrumb.length || this.state.width !== prevState.width)
    ) {
      this.setState(
        {
          transitioning: true,
          hidden: false
        },
        () => {
          this.setState({
            hidden: this.getLinkHeights().filter(link => link > this.expectedHeight).length > 0,
            transitioning: false
          })
        }
      )
    }
  }
  getLinkHeights() {
    return this.props.breadcrumb.map((crumb, index) => {
      const el = document.getElementById(`crumb-${index}`)
      return el ? el.clientHeight : null
    })
  }
  onCrumbClick(crumb) {
    if (this.props.onCrumbClick && typeof this.props.onCrumbClick === "function") {
      this.props.onCrumbClick(crumb)
    }
  }
  onAllClick() {
    if (this.props.onAllClick && typeof this.props.onAllClick === "function") {
      this.props.onAllClick()
    }
  }
  render() {
    return (
      <h4 className='flex items-center flex-auto'>
        <div
          className={`${this.props.breadcrumb.length ? "muted pointer" : ""} pr1`}
          onClick={() => this.onAllClick()}>
          All Files
        </div>
        <div className={`flex items-center ${this.state.transitioning ? "opacity-0" : ""}`}>
          {this.state.hidden && this.props.breadcrumb && (
            <div className='flex items-center'>
              <div className='material-icons muted'>keyboard_arrow_right</div>
              <ButtonDropdown
                button={{
                  className: "link material-icons muted flex items-center justify-center",
                  style: { width: "48px", height: "24px" },
                  text: "more_horiz"
                }}
                dropdown={{
                  clickCloses: true,
                  anchor: "left",
                  content: this.props.breadcrumb
                    .map(crumb => ({
                      text: crumb.name,
                      onClick: () => this.onCrumbClick(crumb)
                    }))
                    .filter((crumb, index) => index !== this.props.breadcrumb.length - 1)
                }}/>
              <div className='material-icons mr1 muted'>keyboard_arrow_right</div>
              {this.props.breadcrumb[this.props.breadcrumb.length - 1]
                ? this.props.breadcrumb[this.props.breadcrumb.length - 1].name
                : ""}
            </div>
          )}
          {!this.state.hidden &&
            this.props.breadcrumb.map((crumb, index) => (
              <div className={`flex items-center pr1`} key={crumb._id}>
                <div className='material-icons mr1 muted'>keyboard_arrow_right</div>
                {this.props.breadcrumb.length === index + 1 ? (
                  <div id={`crumb-${index}`}>{crumb.name}</div>
                ) : (
                  <div className='muted pointer' id={`crumb-${index}`} onClick={() => this.onCrumbClick(crumb)}>
                    {crumb.name}
                  </div>
                )}
              </div>
            ))}
        </div>
      </h4>
    )
  }
}

BreadCrumb.propTypes = {
  breadcrumb: PropTypes.array,
  onCrumbClick: PropTypes.func,
  onAllClick: PropTypes.func
}

export default BreadCrumb
