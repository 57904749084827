import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal, Status } from "@bitcine/cs-theme"
import { toggleModal } from "@api/shared_screeners/modals"
import { updateSharedScreener } from "@api/shared_screeners"

const Password = ({ sharedScreener, status, updateSharedScreener, toggleModal }) => {
  const [password, setPassword] = useState("")
  return (
    <Modal open title={"Update Password for " + sharedScreener.shared_name} onClose={() => toggleModal("password")}>
      <Status pending={status === "PENDING"} error={status === "ERROR"}>
        <div>
          <input
            type='password'
            autoComplete='new-password'
            name='new-password'
            id='new-password'
            className='cs-input col-12 mb2'
            placeholder={sharedScreener.has_password ? "Change Password..." : "Set Password..."}
            value={password}
            onChange={e => setPassword(e.target.value)}/>
          <div className='flex pt2 items-start justify-end'>
            {sharedScreener.has_password && (
              <button
                onClick={() => updateSharedScreener(sharedScreener._id, "password", null)}
                disabled={status === "PENDING"}
                className={`cs-button white small`}>
                Remove Password
              </button>
            )}
            <button
              onClick={() => updateSharedScreener(sharedScreener._id, "password", password)}
              className='cs-button ml1 small'
              disabled={!password.length || status === "PENDING"}>
              Save
            </button>
          </div>
        </div>
      </Status>
    </Modal>
  )
}

Password.propTypes = {
  sharedScreener: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  updateSharedScreener: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired
}

const mapStateToProps = ({ shared_screeners }) => ({
  sharedScreener: shared_screeners.modals.password.sharedScreener,
  status: shared_screeners.modals.password.status
})

const mapDispatchToProps = dispatch => ({
  updateSharedScreener: bindActionCreators(updateSharedScreener, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Password)
