import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { dismissStep } from "@api/tour"
import { Tooltip } from "@bitcine/cs-theme"
import tourConfig from "./config"
import 'styled-components/macro'

class TourTip extends React.Component {
  render() {
    const settings = tourConfig[this.props.tipId]
    const activeStep = this.props.tour.steps.filter(step => step.active)[0]
    const shouldShow = activeStep && activeStep.key === this.props.tipId && !this.props.hidden

    const InnerTip = () => (
      <React.Fragment>
        <div
          className='flex items-center'
          css={`
            margin: 0 0 15px 0;
          `}>
          <h4 className='flex-auto m0'>{settings.header}</h4>
          <button
            className='cs-button link'
            css={`
              height: 20px !important;
              color: #fff !important;
            `}
            onClick={() => {
              this.props.dismissStep(this.props.tipId)
            }}>
            <i className='material-icons'>close</i>
          </button>
        </div>

        <p
          css={`
            margin: 0;
            max-width: 340px;
          `}>
          {settings.content}
        </p>
      </React.Fragment>
    )

    return (
      <Tooltip
        hide={!shouldShow}
        horizontal={settings.alignment}
        type={`error`}
        offset={settings.offset || "-15px 0"}
        setTooltipInnerRef={this.props.setRef}
        constraints={[
          {
            to: "scrollParent",
            attachment: "together"
          }
        ]}
        render={<InnerTip/>}>
        <React.Fragment>{this.props.children}</React.Fragment>
      </Tooltip>
    )
  }
}

TourTip.propTypes = {
  tipId: PropTypes.string.isRequired,
  tour: PropTypes.object.isRequired,
  hidden: PropTypes.bool.isRequired,
  setRef: PropTypes.func,
  dismissStep: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired
}

TourTip.defaultProps = {
  hidden: false,
  setRef: () => null
}

const mapStateToProps = state => ({
  tour: state.tour
})

const mapDispatchToProps = dispatch => ({
  dismissStep: bindActionCreators(dismissStep, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TourTip)
