export const GET_USER_ORGANIZATION = "GET_USER_ORGANIZATION"
export const GET_USER_ORGANIZATION_PENDING = "GET_USER_ORGANIZATION_PENDING"
export const GET_USER_ORGANIZATION_FAILED = "GET_USER_ORGANIZATION_REJECTED"
export const GET_USER_ORGANIZATION_FULFILLED = "GET_USER_ORGANIZATION_FULFILLED"

export const UPDATE_USER_ORGANIZATION = "UPDATE_USER_ORGANIZATION"
export const SAVE_USER_ORGANIZATION = "SAVE_USER_ORGANIZATION"

export const ACTIVATE_APPLICATION = "ACTIVATE_APPLICATION"
export const ACTIVATE_APPLICATION_PENDING = "ACTIVATE_APPLICATION_PENDING"
export const ACTIVATE_APPLICATION_FAILED = "ACTIVATE_APPLICATION_REJECTED"
export const ACTIVATE_APPLICATION_FULFILLED = "ACTIVATE_APPLICATION_FULFILLED"
