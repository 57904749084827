import React from "react"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"
import { css } from "emotion"
import 'styled-components/macro'

const Tabs = ({ tabs }) => (
  <div className='flex'>
    {tabs.map((tab, index) => (
      <NavLink
        key={index}
        css={`
          height: 48px;
          border-bottom-width: 3px;
          :hover {
            border-bottom-color: #ededed;
          }
        `}
        className='px2 flex items-center border-bottom border-top border-white'
        activeClassName={css`
          border-bottom-color: #fb0f3b !important;
          font-weight: bold;
          color: #fb0f3b !important;
        `}
        to={tab.to}>
        {tab.label}
      </NavLink>
    ))}
  </div>
)

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired
}

export default Tabs
