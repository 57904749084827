import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { updateUtils } from "@api/utils/"
import { DEFAULTS } from "@constants"
import { GLOBALS } from "@src/config"
import { Status, Dialog } from "@bitcine/cs-theme"
import { fileDrawerStyles } from "@components/project/constants"
import Header from "./components/header"
import General from "./components/general"
import Description from "./components/description"
import Advanced from "./components/advanced"
import Track from "./components/track"
import Preview from "./media/preview"
import Listen from "./media/listen"
import Name from "./components/name"

class Info extends React.Component {
  constructor(props) {
    super(props)
    this.url = `${GLOBALS.API_URL}/projects/${props.projectID}/file?fileID=${props.file._id}&fileType=${props.file.type}`
    this.state = {
      status: "PENDING",
      file: {}
    }
  }
  componentDidMount() {
    this.props.updateUtils("dialogOpen", true)
    fetch(this.url, DEFAULTS)
      .then(res => res.json())
      .then(file => this.setState({ status: "READY", file }))
      .catch(err => this.setState({ status: "ERROR" }))
  }
  componentWillUnmount() {
    this.props.updateUtils("dialogOpen", false)
  }
  render() {
    const { status, file } = this.state

    return (
      <Dialog open={true} style={fileDrawerStyles}>
        <Status pending={status === "PENDING"} error={status === "ERROR"}>
          <div>
            <Header file={file}/>
            <Name
              file={file}
              onUpdate={file => this.setState({ file })}
              focusOnMount={this.props.focusRef && this.props.focusRef === "name"}/>
            <General file={file}/>
            {file.media_info_status === "READY" && file.general_info && <Advanced file={file} title='Advanced Info'/>}
            {file.media_info_status === "READY" && file.video_info && (
              <Track file={file} type='video_info' title='Video Stream'/>
            )}
            {file.media_info_status === "READY" && file.audio_info && (
              <Track file={file} type='audio_info' title='Audio Stream'/>
            )}
            {file.media_info_status === "READY" && file.text_info && (
              <Track file={file} type='text_info' title='Text Stream'/>
            )}
            {file.screener_id && <Preview projectID={this.props.projectID} screenerID={file.screener_id}/>}
            {file.composition_playlists && (
              <div>
                {file.composition_playlists
                  .filter(cpl => cpl.general_info)
                  .map((cpl, i) => (
                    <Advanced key={i} file={cpl} title={`Composition Playlist #${i + 1}`}/>
                  ))}
              </div>
            )}
            {this.props.file.type === "audio_track" && !file.is_multi_thread && (
              <Listen projectID={this.props.projectID} file={file}/>
            )}
            <Description file={file} fileType={this.props.file.type}/>
          </div>
        </Status>
      </Dialog>
    )
  }
}

Info.propTypes = {
  file: PropTypes.object.isRequired,
  projectID: PropTypes.string.isRequired,
  updateUtils: PropTypes.func.isRequired,
  focusRef: PropTypes.any
}

const mapStateToProps = (state, context) => ({
  file: state.project.files.modals.info.file,
  projectID: state.project.details._id,
  focusRef: state.project.files.modals.info.ref_to_focus
})

const mapDispatchToProps = dispatch => ({
  updateUtils: bindActionCreators(updateUtils, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Info)
