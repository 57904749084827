import {
  ADD_NEW_FOLDER_PENDING,
  ADD_NEW_FOLDER_FULFILLED,
  ADD_NEW_FOLDER_FAILED,
  TOGGLE_NEW_FOLDER,
  UPDATE_NEW_FOLDER,
  TOGGLE_EDIT_FOLDER,
  EDIT_FOLDER_PENDING,
  EDIT_FOLDER_FULFILLED,
  EDIT_FOLDER_FAILED,
  TOGGLE_MOVE_FOLDER,
  GET_FOLDERS_PENDING,
  GET_FOLDERS_FULFILLED,
  GET_FOLDERS_FAILED,
  MOVE_INTO_FOLDER_PENDING,
  MOVE_INTO_FOLDER_FULFILLED,
  MOVE_INTO_FOLDER_FAILED
} from "@actions/project/folders/"

const initialState = {
  moveFolder: {
    status: "READY",
    show: false
  },
  newFolder: {
    name: "",
    status: "",
    show: false
  },
  editFolder: {
    status: "READY",
    show: false,
    folder: null
  },
  folders: {
    status: "PENDING",
    list: [],
    breadcrumb: []
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_NEW_FOLDER_PENDING:
      return {
        ...state,
        newFolder: {
          ...state.newFolder,
          status: "PENDING"
        }
      }

    case ADD_NEW_FOLDER_FULFILLED:
      return {
        ...state,
        newFolder: initialState.newFolder
      }

    case ADD_NEW_FOLDER_FAILED:
      return {
        ...state,
        newFolder: {
          ...state.newFolder,
          status: "ERROR"
        }
      }

    case TOGGLE_NEW_FOLDER:
      return {
        ...state,
        newFolder: {
          ...initialState.newFolder,
          show: !state.newFolder.show
        }
      }

    case UPDATE_NEW_FOLDER:
      return {
        ...state,
        newFolder: {
          ...state.newFolder,
          [action.key]: action.value
        }
      }

    case TOGGLE_EDIT_FOLDER:
      return {
        ...state,
        editFolder: {
          ...state.editFolder,
          show: !state.editFolder.show,
          folder: action.folder
        }
      }

    case EDIT_FOLDER_PENDING:
      return {
        ...state,
        editFolder: {
          ...state.editFolder,
          status: "PENDING"
        }
      }

    case EDIT_FOLDER_FULFILLED:
      return {
        ...state,
        editFolder: initialState.editFolder
      }

    case EDIT_FOLDER_FAILED:
      return {
        ...state,
        editFolder: {
          ...state.editFolder,
          status: "ERROR"
        }
      }

    case TOGGLE_MOVE_FOLDER:
      return {
        ...state,
        moveFolder: {
          ...initialState.moveFolder,
          show: !state.moveFolder.show
        }
      }

    case GET_FOLDERS_PENDING:
      return {
        ...state,
        folders: initialState.folders
      }

    case GET_FOLDERS_FULFILLED:
      return {
        ...state,
        folders: {
          ...action.payload,
          status: "READY"
        }
      }

    case GET_FOLDERS_FAILED:
      return {
        ...state,
        folders: {
          ...initialState.folders,
          status: "ERROR"
        }
      }

    case MOVE_INTO_FOLDER_PENDING:
      return {
        ...state,
        moveFolder: {
          ...state.moveFolder,
          status: "PENDING"
        }
      }

    case MOVE_INTO_FOLDER_FULFILLED:
      return {
        ...state,
        moveFolder: initialState.moveFolder
      }

    case MOVE_INTO_FOLDER_FAILED:
      return {
        ...state,
        moveFolder: {
          ...state.moveFolder,
          status: "ERROR"
        }
      }

    default:
      return state
  }
}
