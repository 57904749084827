import React from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"
import MediaPreparing from "./media_preparing"

const GRID_WIDTH = 300
const GRID_HEIGHT = 169

const Icon = styled("div")`
  span {
    font-size: 44px;
    color: ${props => props.color};
    transition: transform 0.2s linear;
  }
  &:hover {
    span {
      transform: scale(1.1);
    }
  }
`

class Video extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      image_status: "PENDING",
      thumbnail_status: "PENDING",
      mouse_status: "IDLE",
      line_position: 0,
      image_position: {
        x: 0,
        y: 0,
        scale: 1
      }
    }
    this.image = null
    this.thumbnail = null
  }
  componentDidMount() {
    if (this.props.scrubber) {
      this.loadImage()
    }
    if (this.props.thumbnail) {
      this.loadThumbnail()
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.scrubber && prevProps.scrubber.split("?")[0] !== this.props.scrubber.split("?")[0]) {
      this.loadImage()
    }
    if (this.props.thumbnail && prevProps.thumbnail.split("?")[0] !== this.props.thumbnail.split("?")[0]) {
      this.loadThumbnail()
    }
  }
  loadImage = () => {
    this.image = new Image()
    this.image.src = this.props.scrubber
    this.image.onload = event => {
      this.setState({
        image_width: event.target.naturalWidth,
        image_height: event.target.naturalHeight,
        image_status: "READY"
      })
    }
  }
  loadThumbnail = () => {
    this.thumbnail = new Image()
    this.thumbnail.src = this.props.thumbnail
    this.thumbnail.onload = event => {
      this.setState({
        thumbnail_status: "READY"
      })
    }
  }
  onMouseMove = e => {
    if (this.el && this.props.scrubber) {
      const bounds = this.el.getBoundingClientRect()
      const percent = ((e.clientX - bounds.x) / bounds.width) * 100
      const scale = bounds.width / GRID_WIDTH
      this.setState({
        line_position: Math.round(bounds.width * (percent / 100)),
        mouse_status: "ACTIVE",
        image_position: {
          ...this.calculateTransform(bounds, scale, percent),
          scale
        }
      })
    }
  }
  calculateTransform(bounds, scale, percent) {
    let x = 0
    let y = 0

    // Calculate the total number of rows in the image
    const rows = Math.round(this.state.image_height / GRID_HEIGHT)

    // [][][][][][][][][][]
    // [][][][][][][][][][]
    // [][][][][][][][][][]
    // [][][][][][][][][][]

    // We have a 10x4 grid
    // Each row is worth 10 / 4 = 25% of horizontal scrolling
    // When we hit a multiple of 25% scrolling, we need to set it back to 0
    // Grids can be anywhere from 10x1 up to 10x60000
    // Grids will always have 10 columns

    // Calculate the breakpoint of where the percentage should be calculated to
    const breakpoint = (10 / rows) * 10

    // Calculate the next percentage to the nearest breakpoint-nth
    const nextPercent = Math.ceil(percent / breakpoint) * breakpoint

    // Calculate how much % of the breakpoint the mouse is scrolled
    const calculatedNextPercent = (breakpoint - (nextPercent - percent)) / breakpoint

    x = GRID_WIDTH * (calculatedNextPercent * 10)
    x = Math.floor(x / GRID_WIDTH) * GRID_WIDTH
    x = x * scale

    // Calculate the previous percentage to the nearest breakpoint-nth
    const prevPercent = Math.floor(percent / breakpoint) * breakpoint

    // Calculate how much % of the breakpoint the mouse is scrolled
    const calculatedPrevPercent = prevPercent / breakpoint

    y = GRID_HEIGHT * calculatedPrevPercent
    y = Math.floor(y / GRID_HEIGHT) * GRID_HEIGHT
    y = y * scale

    return { x, y }
  }
  onMouseLeave = () => this.setState({ mouse_status: "IDLE", line_positon: 0 })
  render() {
    return (
      <div
        ref={ref => (this.el = ref)}
        className='absolute top-0 left-0 right-0 bottom-0 overflow-hidden'
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.onMouseLeave}>
        {this.props.thumbnail === "" && (
          <span>
            {this.props.isThumbnailPending || this.props.isEncodingProxy ? (
              <div className='absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center'>
                <MediaPreparing text={this.props.isEncodingProxy ? "Preparing Media" : "Processing Thumbnail"}/>
              </div>
            ) : (
              <Icon
                color={this.props.scrubber ? "#ffffff" : "#92999f"}
                className='absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center'>
                <span className='material-icons'>play_circle_filled</span>
              </Icon>
            )}
          </span>
        )}
        {this.state.mouse_status === "ACTIVE" ? (
          <span>
            <div
              style={{
                transform: `translateX(${this.state.line_position}px)`,
                width: "2px"
              }}
              className='absolute bg-red top-0 bottom-0 z2 left-0'/>
            {this.state.image_status === "READY" && (
              <img
                className='cs-img'
                style={{
                  transform: `
										translate(-${this.state.image_position.x}px, -${this.state.image_position.y}px)
										scale(${this.state.image_position.scale})`,
                  maxWidth: "none",
                  borderTopLeftRadius: "3px",
                  borderTopRightRadius: "3px",
                  transformOrigin: "left top"
                }}
                src={this.image.src}/>
            )}
          </span>
        ) : (
          <div
            className='absolute top-0 left-0 right-0 bottom-0 bg-cover bg-center'
            style={{ backgroundImage: this.state.thumbnail_status === "READY" ? `url(${this.thumbnail.src})` : "" }}/>
        )}
      </div>
    )
  }
}

Video.defaultProps = {
  scrubber: "",
  thumbnail: "",
  isThumbnailPending: false,
  isEncodingProxy: false
}

Video.propTypes = {
  scrubber: PropTypes.string,
  thumbnail: PropTypes.string,
  isThumbnailPending: PropTypes.bool,
  isEncodingProxy: PropTypes.bool
}

export default Video
