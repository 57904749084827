import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ButtonDropdown, Modal, Button, Checkbox } from "@bitcine/cs-theme"
import { can, VIEW_SCREENER_ANALYTICS } from "@src/helpers/authorization"
import { push } from "connected-react-router"
import {
  toggleAnalyticsModal,
  removeScreenerFromRoom,
  swapScreenerPositions,
  updateScreener
} from "@api/screener_rooms/"
import "../styles.scss"

const OverlayActions = ({
  screener,
  project,
  organizationId,
  screenerRoom,
  updateThumbnail,
  canModify,
  toggleAnalyticsModal,
  removeScreenerFromRoom,
  push,
  canViewScreenerAnalytics,
  swapScreenerPositions,
  updateScreener
}) => {
  const [editingTitle, setEditingTitle] = useState(false)
  const [newName, setNewName] = useState("")
  const [screenerToRemove, setScreenerToRemove] = useState(false)
  const [notifyReviewers, setNotifyReviewers] = useState(false)
  return (
    <div
      onClick={e => e.stopPropagation() && e.preventDefault()}
      className={`flex items-center absolute top-0 left-0 right-0 mx1 mt1 ${
        canModify ? "justify-between" : "justify-end"
      }`}>
      <div className='flex items-center'>
        {canModify && screener.order !== 0 && (
          <button
            data-tooltip='Move up in room'
            className={`cs-button tooltip link material-icons mr1 arrowIcon`}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              swapScreenerPositions(
                organizationId,
                screenerRoom._id,
                screener.project_id,
                screener.order,
                screener.order - 1
              )
            }}>
            keyboard_arrow_left
          </button>
        )}
        {canModify && screener.order !== project.screeners.length - 1 && (
          <button
            data-tooltip='Move down in room'
            className={`cs-button tooltip link material-icons mr1 arrowIcon`}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              swapScreenerPositions(
                organizationId,
                screenerRoom._id,
                screener.project_id,
                screener.order,
                screener.order + 1
              )
            }}>
            keyboard_arrow_right
          </button>
        )}
      </div>
      {(canModify || canViewScreenerAnalytics) && (
        <ButtonDropdown
          button={{
            className: "link material-icons",
            menu: true,
            text: <span className={"option"}>more_vert</span>
          }}
          dropdown={{
            clickCloses: true,
            content: [
              {
                text: "View screener",
                icon: "ondemand_video",
                onClick: () => push(`/projects/${screener.project_id}/files/preview-link/${screener._id}`),
                show: canViewScreenerAnalytics
              },
              {
                text: "View analytics",
                icon: "bar_chart",
                onClick: () => toggleAnalyticsModal(screener),
                show: canViewScreenerAnalytics
              },
              {
                text: "Update thumbnail",
                icon: "image",
                onClick: () => updateThumbnail(),
                show: canModify
              },
              {
                text: "Rename",
                icon: "edit",
                onClick: () => setEditingTitle(true),
                show: canModify
              },
              {
                text: "Remove from room",
                icon: "remove_circle_outline",
                onClick: () => setScreenerToRemove(screener),
                show: canModify
              }
            ].filter(opt => opt.show)
          }}/>
      )}
      {editingTitle && (
        <Modal open onClose={() => setEditingTitle(false)} title='Edit Screener Name'>
          <p>Enter the new name for your screener.</p>
          <input
            className='cs-input col-12'
            value={newName}
            autoFocus
            onChange={({ target: { value } }) => setNewName(value)}/>
          <div className='flex justify-end mt2'>
            <Button
              onClick={() => {
                if (newName !== screener.name) {
                  updateScreener(organizationId, screenerRoom._id, screener.project_id, screener._id, {
                    key: "name",
                    value: newName
                  })
                }
                setEditingTitle(false)
              }}>
              Save Name
            </Button>
          </div>
        </Modal>
      )}
      {screenerToRemove && (
        <Modal open onClose={() => setScreenerToRemove(null)} title='Remove Screener'>
          <p>
            Are you sure you want to remove <b>{screenerToRemove.name}</b> from your screener room?
          </p>
          <div className='flex justify-end mt2'>
            <div className='flex items-center'>
              <Checkbox
                label='Notify reviewers of changes'
                checked={notifyReviewers}
                onChange={() => setNotifyReviewers(!notifyReviewers)}/>
              <Button
                className='ml2'
                onClick={() => {
                  removeScreenerFromRoom(
                    organizationId,
                    screenerRoom._id,
                    screenerToRemove.project_id,
                    [screenerToRemove._id],
                    notifyReviewers
                  ),
                  setScreenerToRemove(null)
                }}>
                Remove Screener
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

OverlayActions.propTypes = {
  screener: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  toggleAnalyticsModal: PropTypes.func.isRequired,
  removeScreenerFromRoom: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  screenerRoom: PropTypes.object.isRequired,
  canModify: PropTypes.bool.isRequired,
  canViewScreenerAnalytics: PropTypes.bool.isRequired,
  push: PropTypes.func.isRequired,
  swapScreenerPositions: PropTypes.func.isRequired,
  updateScreener: PropTypes.func.isRequired,
  updateThumbnail: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  organizationId: state.user.auth.organization_id,
  screenerRoom: state.screener_rooms.screenerRoom.data,
  canViewScreenerAnalytics: can(state.user.auth, [VIEW_SCREENER_ANALYTICS])
})

const mapDispatchToProps = dispatch => ({
  toggleAnalyticsModal: bindActionCreators(toggleAnalyticsModal, dispatch),
  removeScreenerFromRoom: bindActionCreators(removeScreenerFromRoom, dispatch),
  push: bindActionCreators(push, dispatch),
  swapScreenerPositions: bindActionCreators(swapScreenerPositions, dispatch),
  updateScreener: bindActionCreators(updateScreener, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(OverlayActions)
