import { UPDATE_REVIEWERS_TOP_UP, PURCHASE_REVIEWERS } from "@actions/account/reviewers"
import { post } from "@api/fetch"

import Tracker from "@helpers/tracking"

export const updateReviewersTopUp = (key, value) => dispatch => dispatch({ type: UPDATE_REVIEWERS_TOP_UP, key, value })

export const purchaseReviewers = () => (dispatch, getState) => {
  const reviewers = getState().user.reviewers
  dispatch(
    post(
      PURCHASE_REVIEWERS,
      "billing/reviewers",
      {
        customerCardID: reviewers.credit_card_id,
        quantity: reviewers.quantity
      },
      res => {
        Tracker.inc("Screener Links Purchased", res.amount_purchased)
      }
    )
  )
}
