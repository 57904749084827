import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getHistory, updateHistory, expireLinks } from "@api/project/history"
import dayjs from "dayjs"
import formatDate from "@helpers/formatDate"
import Search from "@components/search/"
import Select from "react-select"
import ReactPaginate from "react-paginate"
import { Modal, Status, Table } from "@bitcine/cs-theme"
import { OPTS } from "./constants"

class History extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      confirmation: false,
      search: "",
      skip: 0,
      take: 25,
      sorting: {
        direction: "",
        key: ""
      },
      expiry_date: "",
      searchVisible: false,
      currentPage: 0,
      shared_screener_id: ""
    }
  }
  componentDidMount() {
    this.props.getHistory(
      this.state.search,
      this.state.sorting.direction,
      this.state.sorting.key,
      this.state.take,
      this.state.skip
    )
  }
  render() {
    const {
      history: { list, status, filtered, count },
      updateHistory,
      expireLinks,
      getHistory,
      disabled
    } = this.props
    const filteredList = list.filter(item => item.action.includes(filtered))
    return (
      <div>
        <div className='flex items-center mb2 col-12'>
          <h3 className='flex-auto'>Project Activity</h3>
          <Search
            visible={this.state.searchVisible}
            disabled={disabled}
            onChange={({ target: { value } }) => {
              this.setState({ search: value })

              if (this.timeout) clearTimeout(this.timeout)
              this.timeout = setTimeout(() => {
                getHistory(
                  this.state.search,
                  this.state.sorting.direction,
                  this.state.sorting.key,
                  this.state.take,
                  this.state.skip
                )
              }, 500)
            }}
            onCancel={() => {
              this.setState({ search: "" })
              this.setState({ searchVisible: false })
              getHistory("", this.state.sorting.direction, this.state.sorting.key, this.state.take, this.state.skip)
            }}
            value={this.state.search}
            onOpen={() => this.setState({ searchVisible: true })}
            placeholder='Search...'/>
          <Select
            className='ml3'
            options={OPTS}
            onChange={({ value }) => updateHistory("filtered", value)}
            clearable={false}
            value={filtered}/>
        </div>
        <Status pending={status === "PENDING"} error={status === "ERROR"}>
          {!filteredList.length ? (
            <div className='center muted py4 my4'>
              <span className='material-icons block mb1 large-icon'>history</span>
              <h4>No Activity</h4>
              <p>When changes are made to this project, they will show here.</p>
            </div>
          ) : (
            <Table
              className='my3'
              widths={[220, "auto", 200, 165]}
              header={{
                columns: [
                  {
                    text: "Date",
                    key: "created_at"
                  },
                  {
                    text: "Event",
                    key: "message_plain"
                  },
                  {
                    text: "Type",
                    key: "type"
                  },
                  { text: "" }
                ],
                sorting: {
                  direction: this.state.sorting.direction,
                  key: this.state.sorting.key,
                  onSortChange: sorting => {
                    this.setState({ sorting: sorting }, function() {
                      getHistory(
                        "",
                        this.state.sorting.direction,
                        this.state.sorting.key,
                        this.state.take,
                        this.state.skip
                      )
                    })
                  }
                }
              }}
              body={{
                data: filteredList || [],
                row: {
                  render: [
                    data => formatDate(data.created_at),
                    data => <div dangerouslySetInnerHTML={{ __html: data.message }}/>,
                    data => data.type,
                    data =>
                      data.links_expired === false && (
                        <button
                          className='cs-button white small'
                          onClick={() =>
                            data.type === "File"
                              ? this.setState({
                                confirmation: true,
                                package_id: data.package_id,
                                id: data._id
                              })
                              : data.type === "Screener" &&
                                this.setState({
                                  confirmation: true,
                                  expiry_date: dayjs(0).format(),
                                  shared_screener_id: data.shared_screener_id,
                                  id: data._id
                                })
                          }>
                          Expire Links
                        </button>
                      )
                  ]
                }
              }}/>
          )}
          {count > this.state.take && (
            <ReactPaginate
              containerClassName='flex items-stretch justify-end py2 list-style-none mt2 cs-paginate'
              previousLabel='Back'
              forcePage={this.state.currentPage}
              pageCount={Math.ceil(count / this.state.take)}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={({ selected }) => {
                this.setState({ isLoading: true })
                this.setState({ currentPage: selected })
                this.setState({ skip: selected * this.state.take }, function() {
                  getHistory(
                    this.state.search,
                    this.state.sorting.direction,
                    this.state.sorting.key,
                    this.state.take,
                    this.state.skip
                  )
                })
              }}/>
          )}
        </Status>
        <Modal
          open={this.state.confirmation}
          title={"Expire Links Confirmation"}
          clickOutsideCloses={true}
          onClose={() => this.setState({ confirmation: false })}>
          <div>
            <p>Are you sure you wish to expire the links? This cannot be undone.</p>
            <div className='right-align'>
              <button
                onClick={() => {
                  expireLinks(
                    this.state.package_id,
                    this.state.expiry_date,
                    this.state.id,
                    this.state.shared_screener_id
                  )
                  this.setState({ confirmation: false })
                }}
                className='cs-button ceneter mt2 mr2'>
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  history: state.project.history,
  filter: state.project.history.filter,
  disabled: state.project.history.status === "PENDING"
})

const mapDispatchToProps = dispatch => ({
  getHistory: bindActionCreators(getHistory, dispatch),
  expireLinks: bindActionCreators(expireLinks, dispatch),
  updateHistory: bindActionCreators(updateHistory, dispatch)
})

History.propTypes = {
  history: PropTypes.object.isRequired,
  filter: PropTypes.object,
  disabled: PropTypes.bool,
  expireLinks: PropTypes.func.isRequired,
  getHistory: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  updateHistory: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(History)
