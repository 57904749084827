export const SET_PROJECT = "SET_PROJECT"
export const ADD_PROJECT_FULFILLED = "ADD_PROJECT_FULFILLED"
export const GET_PROJECT = "GET_PROJECT"
export const GET_PROJECT_PENDING = "GET_PROJECT_PENDING"
export const GET_PROJECT_FULFILLED = "GET_PROJECT_FULFILLED"
export const GET_PROJECT_FAILED = "GET_PROJECT_REJECTED"

export const SAVE_PROJECT = "SAVE_PROJECT"
export const SAVE_PROJECT_PENDING = "SAVE_PROJECT_PENDING"
export const SAVE_PROJECT_FAILED = "SAVE_PROJECT_REJECTED"
export const SAVE_PROJECT_FULFILLED = "SAVE_PROJECT_FULFILLED"

export const UPDATE_PROJECT_DETAILS = "UPDATE_PROJECT_DETAILS"

export const INIT_DEFROST = "INIT_DEFROST"
export const INIT_DEFROST_PENDING = "INIT_DEFROST_PENDING"
export const INIT_DEFROST_FAILED = "INIT_DEFROST_REJECTED"
export const INIT_DEFROST_FULFILLED = "INIT_DEFROST_FULFILLED"
