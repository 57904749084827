import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import Cookies from "js-cookie"
import HeatMap from "../components/heatmap"
import "./styles.scss"

class HeatMapColumn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show_upgrade: false
    }
  }
  componentDidMount() {
    const cookie = Cookies.get("upgrade_card_dismissed")
    if (!cookie && !this.props.canViewAnalytics) {
      this.setState({
        show_upgrade: true
      })
    }
  }
  dismissUpgradeCard() {
    this.setState(
      {
        show_upgrade: false
      },
      () => {
        Cookies.set("upgrade_card_dismissed", 1, { expires: 1 })
      }
    )
  }
  render() {
    let { data, canViewAnalytics, isFirstReviewer } = this.props
    return (
      <div className='block col-12 relative'>
        {canViewAnalytics ? (
          <HeatMap data={data}/>
        ) : (
          <div
            className={`bg-gray-5 relative z1 rounded col-12 muted flex overflow-hidden blur`}
            style={{ height: "16px" }}>
            <div className='top-0 left-0 bottom-0 col-3 bg-red'/>
          </div>
        )}
        {isFirstReviewer && this.state.show_upgrade && (
          <div className='absolute mx2 top-0 left-0 right-0 bg-white rounded box-shadow mt2 px2 py1 pb2 z2'>
            <h4 className='flex items-center'>Analytics Upgrade</h4>
            <p>Analytics will let you see total plays, impressions, engagement, and more.</p>
            <Link to='/settings/user/account/plan'>
              <button className='cs-button small col-12'>Upgrade Plan</button>
            </Link>
          </div>
        )}
      </div>
    )
  }
}

HeatMapColumn.defaultProps = {
  data: {}
}

HeatMapColumn.propTypes = {
  data: PropTypes.object.isRequired,
  canViewAnalytics: PropTypes.bool.isRequired,
  isFirstReviewer: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  canViewAnalytics: state.user.auth.can_view_screener_analytics
})

export default connect(mapStateToProps)(HeatMapColumn)
