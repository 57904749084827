import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toggleModalVisibility } from "@api/project/files/modals"

class Header extends React.Component {
  render() {
    return (
      <div className='flex items-start border-bottom border-gray-5 p2'>
        <div className='flex-auto'>
          <small className='block'>{`${this.props.file.type === "folder" ? "Folder" : "File"} Info`}</small>
          <h4 className='truncate'>{this.props.file.name}</h4>
        </div>
        <button
          onClick={() => this.props.toggleModalVisibility("info")}
          className='cs-button material-icons link ml2 small'>
          close
        </button>
      </div>
    )
  }
}

Header.propTypes = {
  file: PropTypes.object.isRequired,
  toggleModalVisibility: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  toggleModalVisibility: bindActionCreators(toggleModalVisibility, dispatch)
})

export default connect(
  null,
  mapDispatchToProps
)(Header)
