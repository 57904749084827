import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ButtonDropdown } from "@bitcine/cs-theme"
import { toggleModal } from "@api/shared_screeners/modals"
import { resendEmail, updateSharedScreener, updateLastSent } from "@api/shared_screeners"
import dayjs from "dayjs"
import copy from "copy-to-clipboard"
import { can, canShareScreenerRoom, MANAGE_SCREENERS, VIEW_SCREENER_ANALYTICS } from "@helpers/authorization"
import { GLOBALS } from "@src/config"

const isExpired = sharedScreener => sharedScreener.expiry && !dayjs(sharedScreener.expiry).isAfter(dayjs())

const canViewDropdown = (user, sharedScreener) => {
  if (sharedScreener.type === "screener_group") {
    if (!can(user, [VIEW_SCREENER_ANALYTICS]) && !canShareScreenerRoom(user, sharedScreener.screener_group))
      return false
  }
  return true
}
const SharedScreenerDropdown = ({
  user,
  sharedScreener,
  toggleModal,
  resendEmail,
  updateSharedScreener,
  updateLastSent
}) =>
  canViewDropdown(user, sharedScreener) ? (
    <ButtonDropdown
      button={{
        menu: true,
        className: "link material-icons",
        text: "more_vert",
        style: { width: "46px" }
      }}
      dropdown={{
        clickCloses: true,
        content: [
          //     {
          //       text: "Viewer History",
          //       icon: "history",
          //       onClick: () => toggleModal("screenerRoomViewers", sharedScreener),
          //       show: sharedScreener.type === "screener_group" && can(user, [VIEW_SCREENER_ANALYTICS])
          //     },
          {
            text: "Sessions",
            icon: "list_alt",
            onClick: () => toggleModal("sessions", sharedScreener),
            show: can(user, [VIEW_SCREENER_ANALYTICS])
          },
          {
            text: "Resend Email",
            icon: "email",
            onClick: () => resendEmail(sharedScreener._id),
            disabled: isExpired(sharedScreener),
            show:
              sharedScreener.share_type === "email" &&
              ((sharedScreener.type === "screener" && can(user, [MANAGE_SCREENERS])) ||
                (sharedScreener.type == "screener_group" && canShareScreenerRoom(user, sharedScreener.screener_group)))
          },
          {
            text: "Copy Link URL",
            icon: "link",
            onClick: () => {
              copy(sharedScreener.url, { format: "text/plain" })
              updateLastSent(sharedScreener._id)
            },
            disabled: isExpired(sharedScreener),
            show:
              sharedScreener.share_type === "link" &&
              ((sharedScreener.type === "screener" && can(user, [MANAGE_SCREENERS])) ||
                (sharedScreener.type == "screener_group" && canShareScreenerRoom(user, sharedScreener.screener_group)))
          },
          {
            text: "Change Expiry Date",
            icon: "date_range",
            onClick: () => toggleModal("expiryDate", sharedScreener),
            show:
              !isExpired(sharedScreener) &&
              ((sharedScreener.type === "screener" && can(user, [MANAGE_SCREENERS])) ||
                (sharedScreener.type == "screener_group" && canShareScreenerRoom(user, sharedScreener.screener_group)))
          },
          {
            text: "Change View Count Limit",
            icon: "list_alt",
            onClick: () => toggleModal("viewCountLimit", sharedScreener),
            show:
              !isExpired(sharedScreener) &&
              ((sharedScreener.type === "screener" && can(user, [MANAGE_SCREENERS])) ||
                (sharedScreener.type == "screener_group" && canShareScreenerRoom(user, sharedScreener.screener_group)))
          },
          {
            text: isExpired(sharedScreener) ? "Activate" : "Expire",
            icon: isExpired(sharedScreener) ? "check" : "block",
            onClick: () => {
              updateSharedScreener(
                sharedScreener._id,
                "expiry",
                isExpired(sharedScreener) ? null : dayjs(new Date(1970, 0, 0)).format()
              )
            },
            show:
              (sharedScreener.type === "screener" && can(user, [MANAGE_SCREENERS])) ||
              (sharedScreener.type == "screener_group" && canShareScreenerRoom(user, sharedScreener.screener_group))
          },
          {
            text: "Password Settings",
            icon: "lock",
            onClick: () => toggleModal("password", sharedScreener),
            show: true
          },
          {
            text: "Export Comments",
            icon: "comment",
            onClick: () => window.open(`${GLOBALS.API_URL}/shared-screeners/${sharedScreener._id}/comments`, "_blank")
            ,
            show: sharedScreener.has_comments
          },
        ].filter(opt => opt.show)
      }}/>
  ) : null

SharedScreenerDropdown.propTypes = {
  sharedScreener: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  resendEmail: PropTypes.func.isRequired,
  updateSharedScreener: PropTypes.func.isRequired,
  updateLastSent: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  user: state.user.auth
})

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  resendEmail: bindActionCreators(resendEmail, dispatch),
  updateSharedScreener: bindActionCreators(updateSharedScreener, dispatch),
  updateLastSent: bindActionCreators(updateLastSent, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SharedScreenerDropdown)
