import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Logo from "@src/public/logo.inline.svg"
import { toggleGiftCard } from "@api/account/cart"
import { Checkbox } from "@bitcine/cs-theme"
import "./styles.scss"

const GiftCard = ({ card, cart, toggleGiftCard, usingPostPay }) => (
  <div
    onClick={() => {
      if (cart && !card.applying && !usingPostPay) toggleGiftCard(card._id)
    }}
    className={`${cart ? "lg-col-4 xl-col-3" : "lg-col-3 xl-col-2"} col-12 sm-col-6 md-col-4 px1 pb2`}>
    <div className={`card relative rounded box-shadow`}>
      <div className='absolute left-0 right-0 bottom-0 top-0 flex flex-column'>
        <div className={`relative flex-auto flex items-center justify-center bg-gray-6 giftCardHeader`}>
          {cart && (
            <span className='absolute top-0 left-0 ml1 mt1'>
              {card.applying ? (
                <div className='spinner spinner-small'/>
              ) : (
                <Checkbox disabled={usingPostPay} checked={card.is_payment_active} onChange={() => null}/>
              )}
            </span>
          )}
          <div>
            <Logo className={"logo"}/>
          </div>
        </div>
        <div className='flex items-center justify-start px2 py1'>
          <div className='mr2'>
            <small className='block'>Amount</small>
            <strong className='block'>
              {card.currency_symbol}
              {card.original_amount}
            </strong>
          </div>
          <div>
            <small className='block'>Remaining</small>
            <strong className='block red'>
              {card.currency_symbol}
              {parseFloat(card.current_amount).toFixed(2)}
            </strong>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const mapStateToProps = state => ({
  usingPostPay: state.user.billing.paymentMethods.list.using_post_pay === true
})

const mapDispatchToProps = dispatch => ({
  toggleGiftCard: bindActionCreators(toggleGiftCard, dispatch)
})

GiftCard.propTypes = {
  card: PropTypes.object.isRequired,
  cart: PropTypes.bool.isRequired,
  toggleGiftCard: PropTypes.func.isRequired,
  usingPostPay: PropTypes.bool.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftCard)
