import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import humanFileSize from "@src/helpers/humanFileSize"
import { connect } from "react-redux"
import { Status, Table } from "@bitcine/cs-theme"
import { previewDocument } from "@api/project/files/preview"
import { getPackageFiles } from "@api/download_link/"
import Breadcrumb from "@components/breadcrumb"
import Icon from "@components/files/icon/"
import formatDate from "@helpers/formatDate"
import Actions from "../actions"

class FilesTable extends React.Component {
  constructor() {
    super()
    this.state = {
      folderID: null,
      page: 0,
      skip: 0,
      take: 25
    }
  }
  componentDidMount() {
    this.getFiles()
  }
  getFiles() {
    this.props.getPackageFiles(this.props.download._id, this.state.folderID, this.state.skip, this.state.take)
  }
  onPageChange(page) {
    this.setState({ page, skip: page * this.state.take }, () => this.getFiles())
  }
  onRowClick(file) {
    if (file.type === "folder") {
      this.setState({ folderID: file._id }, () => this.getFiles())
      return
    }
    this.props.previewDocument(file)
  }
  clearFolder() {
    this.setState({ folderID: null }, () => this.getFiles())
  }
  render() {
    const { page, take } = this.state
    const { download, list, size, status } = this.props
    return (
      <Status pending={status === "PENDING"} error={status === "ERROR"} className='mt2'>
        <Breadcrumb
          breadcrumb={download.breadcrumb}
          onAllClick={() => this.clearFolder()}
          onCrumbClick={crumb => this.onRowClick(crumb)}/>
        <Table
          className='mt1 pointer'
          widths={[55, "auto", 145, 145, 280]}
          header={{
            columns: ["", "File", "Size", "Created", ""].map(opt => ({ text: opt }))
          }}
          paginate={{
            currentPage: page,
            pageCount: Math.ceil(size / take),
            onPageChange: page => this.onPageChange(page)
          }}
          body={{
            data: list.filter(file => file.extension !== 'html'),
            row: {
              onClick: (e, data, index) => this.onRowClick(data),
              className: "pointer",
              render: [
                file => <Icon data={file}/>,
                file => (
                  <div>
                    {file.name}
                    <span className='small light block'>{file.kind}</span>
                  </div>
                ),
                file => (file.size ? humanFileSize(file.size) : "--"),
                file => formatDate(file.created_at, false),
                file => <Actions download={download} file={file}/>
              ]
            }
          }}/>
      </Status>
    )
  }
}

FilesTable.propTypes = {
  download: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  previewDocument: PropTypes.func.isRequired,
  getPackageFiles: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  download: state.download_link,
  list: state.download_link.files.list,
  size: state.download_link.files.size,
  status: state.download_link.files.status
})

const mapDispatchToProps = dispatch => ({
  previewDocument: bindActionCreators(previewDocument, dispatch),
  getPackageFiles: bindActionCreators(getPackageFiles, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilesTable)
