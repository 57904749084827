import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { activateScreener } from "@api/project/files/screeners"

const Unencoded = ({ activateScreener, screener }) => (
  <div className='center white'>
    <span className={`material-icons block large-icon`}>lock</span>
    <h4>Create a Secure Screener!</h4>
    <div className='flex'>
      <div className='col-2'/>
      <div className='col-8'>
        <p>
          Before you can begin sending screener links, your source file must be encoded with studio-grade DRM technology
          that prevents malicious activity across all platforms.
        </p>
        <button className='cs-button' onClick={e => activateScreener(screener._id)}>
          <span className='flex items-center'>
            Encode Content <i className='material-icons ml1'>hd</i>
          </span>
        </button>
      </div>
    </div>
  </div>
)

const mapDispatchToProps = dispatch => ({
  activateScreener: bindActionCreators(activateScreener, dispatch)
})

Unencoded.propTypes = {
  activateScreener: PropTypes.func.isRequired,
  screener: PropTypes.object.isRequired
}

export default connect(null, mapDispatchToProps)(Unencoded)
