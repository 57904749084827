import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  updateCreditCategoryName,
  saveCreditCategoryName,
  toggleRoles,
  archiveCreditCategory,
  deleteCreditCategory,
  addCreditRole,
  saveCreditRoleOrder,
  reorderCreditRoles
} from "@api/credits"
import CreditCategoryRole from "./credit-category-role"
import "./credits.scss"

class CreditCategory extends Component {
  constructor(props) {
    super(props)

    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleOnBlur = this.handleOnBlur.bind(this)
    this.handleArchive = this.handleArchive.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleAddCredit = this.handleAddCredit.bind(this)
    this.handleRoleOrderChange = this.handleRoleOrderChange.bind(this)
  }
  handleNameChange(e) {
    this.props.updateCreditCategoryName(this.props.index, e.target.value)
  }
  handleOnBlur(name) {
    this.props.saveCreditCategoryName(this.props.category, name)
  }
  handleArchive() {
    if (
      confirm(
        `Are you sure you want to archive ${
          this.props.category.name.length ? this.props.category.name : "unnamed"
        } category?`
      )
    ) {
      this.props.archiveCreditCategory(this.props.index, this.props.category)
    }
  }
  handleDelete() {
    if (
      confirm(
        `Are you sure you want to delete ${
          this.props.category.name.length ? this.props.category.name : "unnamed"
        } category?`
      )
    ) {
      this.props.deleteCreditCategory(this.props.index, this.props.category)
    }
  }
  handleAddCredit() {
    this.props.addCreditRole(this.props.index, this.props.category)
  }
  handleRoleOrderChange(dragIndex, hoverIndex) {
    this.props.reorderCreditRoles(this.props.index, dragIndex, hoverIndex)
  }
  render() {
    const container = "rounded flex items-stretch col-12 p2"
    const {
      category,
      toggleRoles,
      index,
      total,
      saveCreditRoleOrder
    } = this.props

    return (<div
      className={`${container} bg-white box-shadow mb2 relative`}
      ref={elm => (this.creditCategoryElm = elm)}>
        <div className='flex items-center col-12'>
          <div className='mr2'>
            {index !== 0 && <span 
              className={`flex items-center pointer`}
              onClick={() => {
                this.props.moveCreditCategory(index - 1, index)
                this.props.saveCreditCategory(index - 1, index, this.props.projectID)
              }}>
              <i className='material-icons'>keyboard_arrow_up</i>
            </span>}
            {index + 1 !== total && <span 
              className={`flex items-center pointer`}
              onClick={() => {
                this.props.moveCreditCategory(index, index + 1)
                this.props.saveCreditCategory(index, index + 1, this.props.projectID)
              }}>
              <i className='material-icons'>keyboard_arrow_down</i>
            </span>}
          </div>
          <div className='col-12'>
            <div>
              <div className='flex'>
                <div className='flex-auto'>
                  <input
                    className='cs-input'
                    value={category.name}
                    onChange={this.handleNameChange}
                    onBlur={() => this.handleOnBlur(category.name)}
                    placeholder='Credit Category Name'/>
                </div>
                <div className='flex items-center'>
                  <button
                    data-tooltip={`${category.showRoles ? "Hide" : "Show"} Credits`}
                    className='cs-button mr3 link flex items-center justify-center tooltip'
                    onClick={() => toggleRoles(index)}>
                    {category.showRoles ? (
                      <i className='material-icons'>keyboard_arrow_down</i>
                    ) : (
                      <i className='material-icons'>keyboard_arrow_up</i>
                    )}
                  </button>
                  <button
                    className={`cs-button link flex items-center justify-center`}
                    onClick={this.handleDelete}>
                    <i className='material-icons'>delete_forever</i>
                  </button>
                </div>
              </div>
            </div>

            {category.showRoles && (
              <div>
                <div className='mt2'>
                  {this.props.category.credits.map((person, i) => (
                    <CreditCategoryRole
                      person={person}
                      key={i}
                      index={i}
                      total={this.props.category.credits.length}
                      categoryIndex={this.props.index}
                      category={this.props.category}
                      moveCreditRole={this.handleRoleOrderChange}
                      saveCreditRole={saveCreditRoleOrder}
                      projectID={this.props.projectID}/>
                  ))}
                </div>
                <div
                  onClick={this.handleAddCredit}
                  className={`${container} mb2 cursor role roleClickable`}>
                  <i className='material-icons mr2'>add_circle</i>{" "}
                  {this.props.category.credits.length > 0 ? "Add Another Role" : "Add Role"}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

CreditCategory.propTypes = {
  category: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  moveCreditCategory: PropTypes.func.isRequired,
  saveCreditCategory: PropTypes.func.isRequired,
  reorderCreditRoles: PropTypes.func.isRequired,
  updateCreditCategoryName: PropTypes.func.isRequired,
  saveCreditCategoryName: PropTypes.func.isRequired,
  toggleRoles: PropTypes.func.isRequired,
  archiveCreditCategory: PropTypes.func.isRequired,
  deleteCreditCategory: PropTypes.func.isRequired,
  addCreditRole: PropTypes.func.isRequired,
  saveCreditRoleOrder: PropTypes.func.isRequired
}

function mapDispatchToProps(dispatch) {
  return {
    updateCreditCategoryName: bindActionCreators(updateCreditCategoryName, dispatch),
    saveCreditCategoryName: bindActionCreators(saveCreditCategoryName, dispatch),
    toggleRoles: bindActionCreators(toggleRoles, dispatch),
    archiveCreditCategory: bindActionCreators(archiveCreditCategory, dispatch),
    deleteCreditCategory: bindActionCreators(deleteCreditCategory, dispatch),
    addCreditRole: bindActionCreators(addCreditRole, dispatch),
    saveCreditRoleOrder: bindActionCreators(saveCreditRoleOrder, dispatch),
    reorderCreditRoles: bindActionCreators(reorderCreditRoles, dispatch)
  }
}

export default connect(null, mapDispatchToProps)(CreditCategory)
