import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MobileNav from './mobile'
import DesktopNav from './desktop'
import useMobile from "@helpers/use_mobile"

const Nav = ({ user }) => {
  const isMobile = useMobile()
  if (isMobile) {
    return <MobileNav user={user}/>
  }
  return <DesktopNav user={user}/>
}

Nav.propTypes = {
  user: PropTypes.shape({
    organization_id: PropTypes.string,
    app_permissions: PropTypes.object.isRequired
  }).isRequired
}

const mapStateToProps = state => ({
  user: state.user.auth
})

export default connect(mapStateToProps)(Nav)
