export const GLOBALS = {
  TESTING: false,
  BUILD_ENV: "staging",
  NAME: "Files",
  URL: "https://staging-files.cinesend.com",
  API_URL: "https://staging-api.cinesend.com/api",
  FILES_URL: "https://staging-files.cinesend.com",
  ACCOUNTS_URL: "https://staging-accounts.cinesend.com",
  PUSHER_KEY: "0edd289b7933fc036740",
  PUSHER_DEBUG: false,
  AWS_ACCELERATED_ENDPOINT: "https://cinesendcontainer-beta.s3-accelerate.amazonaws.com"
}
