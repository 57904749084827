import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateProjectsList, setConvertProjectToFolder, toggleDeleteProjectModal, toggleChecked } from "@api/projects/"
import { saveProject } from "@api/project/"
import { initializeHttpUpload } from "@api/transfer/http/upload"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import { can, MODIFY_PROJECTS, MODIFY_USERS_AND_ROLES, DELETE_PROJECTS } from "@src/helpers/authorization"
import { ButtonDropdown, Checkbox } from "@bitcine/cs-theme"
import { TOGGLE_ACCESS_MODAL } from "@actions/project/access"
import { PROJECT_IS_DRAGGING, PROJECT_IS_FINISHED_DRAGGING } from "@actions/projects"
import { DragSource, DropTarget } from "react-dnd"
import flow from "lodash.flow"
import { saveProjectFolder, moveProjectsToFolder } from "@api/project_folders"
import { getEmptyImage } from "react-dnd-html5-backend"
import { push } from "connected-react-router"
import Title from "../shared/title"
import "./styles.scss"
import Poster from "./poster"

class Project extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editingTitle: false,
      editableTitle: props.project.title || "",
      isEditingTitle: false
    }
  }
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true
    })
  }
  onTitleChange(value) {
    this.props.updateProjectsList(this.props.project._id, "title", value)
    if (value !== this.props.project.title.trim()) {
      this.props.saveProject(this.props.project._id, "title", value)
    }
    this.setState({ isEditingTitle: false })
  }
  onDrop(accepted) {
    if (this.props.canEdit && accepted.length) {
      accepted.map(file =>
        this.props.initializeHttpUpload(
          file,
          {
            is_project_poster: true,
            project_id: this.props.project._id
          },
          result => {
            this.props.updateProjectsList(this.props.project._id, "active_poster_url", file)
          }
        )
      )
    }
  }
  render() {
    const {
      project,
      connectDragSource,
      projectIsDragging,
      connectDropTarget,
      checkedProjects,
      hoveredProject,
      canUseProjectFolders,
      canEdit
    } = this.props

    let dropRef
    const content = (
      <div>
        <Link className='block' to={`/projects/${project._id}/files`}>
          <Poster
            checked={checkedProjects.includes(project._id)}
            convertingToFolder={hoveredProject && hoveredProject === project._id}
            src={project.active_poster_url}>
            <div className={`absolute top-0 left-0 right-0 flex items-start justify-between col-12 p1 z3`}>
              <div
                data-testid='projects-project-poster-checkbox'
                className='bg-white rounded flex items-center justify-center'
                style={{ width: "22px", paddingLeft: ".5rem" }}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  this.props.toggleChecked(this.props.project)
                }}>
                <Checkbox checked={this.props.isChecked} disabled={false} onChange={() => null}/>
              </div>
              <ButtonDropdown
                button={{
                  className: "white",
                  style: {
                    width: "34px",
                    minWidth: "34px",
                    height: "34px",
                    padding: "0"
                  },
                  onClick: e => {
                    e.preventDefault()
                    e.stopPropagation()
                  },
                  text: <span className='material-icons'>more_vert</span>,
                  testId: "projects-project-options-btn"
                }}
                dropdown={{
                  clickCloses: true,
                  content: [
                    {
                      text: "View Project",
                      testId: "projects-project-options-go-to-btn",
                      onClick: e => {
                        this.props.push(`/projects/${this.props.project._id}/files`)
                      },
                      show: true
                    },
                    {
                      text: "Update Poster",
                      onClick: e => {
                        dropRef.open()
                      },
                      show: this.props.canEdit
                    },
                    {
                      text: "Update Access",
                      onClick: e => {
                        this.props.toggleAccessModal(this.props.project)
                      },
                      show: this.props.canEditAccess && this.props.hasOrganization
                    },
                    {
                      text: "Delete Project",
                      onClick: e => {
                        if (!this.props.checkedProjects.includes(this.props.project._id)) {
                          this.props.toggleChecked(this.props.project)
                        }
                        this.props.toggleDeleteProjectModal()
                      },
                      show: this.props.canDelete
                    }
                  ].filter(opt => opt.show)
                }}/>
            </div>
          </Poster>
        </Link>
        <Title
          title={project.title}
          onFocus={e => this.setState({ isEditingTitle: true })}
          onComplete={value => this.onTitleChange(value)}/>
      </div>
    )

    return (
      <Dropzone
        multiple={false}
        accept='image/*'
        ref={node => {
          dropRef = node
        }}
        disabled={!canEdit || projectIsDragging}
        activeClassName={"activeDropzone"}
        disableClick={true}
        className='__dropzone'
        onDrop={(accepted, rejected) => this.onDrop(accepted)}>
        {canUseProjectFolders && !this.state.isEditingTitle ? connectDropTarget(connectDragSource(content)) : content}
      </Dropzone>
    )
  }
}

const cardSource = {
  /**
   *  setProjectDragging() & setProjectFinishedDragging() are used to toggle the disabled prop on ALL project dropzones..
   *  If we're dragging a project, we don't want Dropzone to be active.
   */

  beginDrag(props) {
    props.setProjectDragging(props.project._id)

    return {
      ...props
    }
  },

  endDrag(props, monitor, component) {
    setTimeout(() => {
      props.setProjectFinishedDragging()
    }, 200)
  }
}

const dragTarget = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    draggedFile: monitor.getItem(),
    targetID: monitor.targetId
  }
}

let hasHoveredOut = false
let timeout = null
let lastHoveredProject = null

const fileTarget = {
  hover(props, monitor, component) {
    const draggingProjectID = props.draggingProjectID
    const targetProjectID = props.project._id

    clearTimeout(timeout)

    if (draggingProjectID !== targetProjectID) {
      if (lastHoveredProject !== targetProjectID) {
        props.setConvertProjectToFolder(targetProjectID)
        lastHoveredProject = targetProjectID
        hasHoveredOut = false
      }
    } else {
      if (!hasHoveredOut) {
        props.setConvertProjectToFolder(null)
        hasHoveredOut = true
      }
      lastHoveredProject = null
    }

    timeout = setTimeout(() => {
      props.setConvertProjectToFolder(null)
      hasHoveredOut = true
      lastHoveredProject = null
    }, 150)
  },

  drop(props, monitor, component) {
    const draggingProjectID = props.draggingProjectID
    const targetProjectID = props.project._id

    if (draggingProjectID !== targetProjectID) {
      props.saveProjectFolder("Unnamed", [draggingProjectID, targetProjectID])
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview()
  }
}

const mapStateToProps = (state, ownProps) => ({
  canEdit: can(state.user.auth, [MODIFY_PROJECTS]),
  canEditAccess: can(state.user.auth, [MODIFY_USERS_AND_ROLES]),
  canDelete: can(state.user.auth, [DELETE_PROJECTS]),
  hasOrganization: state.user.auth.organization_id !== null,
  projectIsDragging: state.projects.projectIsDragging,
  checkedProjects: state.projects.checkedProjects,
  draggingProjectID: state.projects.draggingProjectID,
  hoveredProject: state.projects.hoveredProject,
  canUseProjectFolders: state.user.auth.can_use_project_folders,
  isChecked: state.projects.checkedProjects.includes(ownProps.project._id)
})

const mapDispatchToProps = dispatch => ({
  initializeHttpUpload: bindActionCreators(initializeHttpUpload, dispatch),
  updateProjectsList: bindActionCreators(updateProjectsList, dispatch),
  saveProject: bindActionCreators(saveProject, dispatch),
  toggleDeleteProjectModal: bindActionCreators(toggleDeleteProjectModal, dispatch),
  toggleAccessModal: project => dispatch({ type: TOGGLE_ACCESS_MODAL, payload: { project } }),
  setProjectDragging: _id => dispatch({ type: PROJECT_IS_DRAGGING, _id }),
  toggleChecked: bindActionCreators(toggleChecked, dispatch),
  setProjectFinishedDragging: () => dispatch({ type: PROJECT_IS_FINISHED_DRAGGING }),
  saveProjectFolder: bindActionCreators(saveProjectFolder, dispatch),
  moveProjectsToFolder: bindActionCreators(moveProjectsToFolder, dispatch),
  setConvertProjectToFolder: bindActionCreators(setConvertProjectToFolder, dispatch),
  push: bindActionCreators(push, dispatch)
})

Project.propTypes = {
  project: PropTypes.object.isRequired,
  isChecked: PropTypes.bool.isRequired,
  toggleChecked: PropTypes.func.isRequired,
  toggleDeleteProjectModal: PropTypes.func.isRequired,
  initializeHttpUpload: PropTypes.func.isRequired,
  updateProjectsList: PropTypes.func.isRequired,
  saveProject: PropTypes.func.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canEditAccess: PropTypes.bool.isRequired,
  toggleAccessModal: PropTypes.func.isRequired,
  hasOrganization: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  projectIsDragging: PropTypes.bool.isRequired,
  setProjectDragging: PropTypes.func.isRequired,
  setProjectFinishedDragging: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  moveProjectsToFolder: PropTypes.func.isRequired,
  checkedProjects: PropTypes.array.isRequired,
  draggingProjectID: PropTypes.string,
  saveProjectFolder: PropTypes.func,
  hoveredProject: PropTypes.any,
  setConvertProjectToFolder: PropTypes.func.isRequired,
  isOver: PropTypes.bool,
  push: PropTypes.func.isRequired,
  targetID: PropTypes.string,
  canUseProjectFolders: PropTypes.bool.isRequired
}

export default flow(
  DragSource("PROJECT", cardSource, collect),
  DropTarget("PROJECT", fileTarget, dragTarget),
  connect(mapStateToProps, mapDispatchToProps)
)(Project)
