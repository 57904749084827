import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { nextStep } from "@api/account/cart"

const Stepper = ({ steps, currentStep, disabled, nextStep }) => (
  <div className='flex items-center justify-between relative mb2'>
    <div style={{ height: "2px", top: "calc(50% - 1px)" }} className='absolute left-0 right-0 bg-gray-5'/>

    {steps.map((step, i) => (
      <div
        key={step.to}
        onClick={e => {
          if (disabled) e.preventDefault()
          if (currentStep !== tmpSteps[2] && tmpSteps.indexOf(currentStep) < tmpSteps.indexOf(step.to)) {
            nextStep(currentStep)
          }
        }}
        style={{ color: disabled ? "#acacac" : "inherit" }}
        className={`bg-white z1`}
        to={tmpSteps.indexOf(currentStep) > tmpSteps.indexOf(step.to) ? step.to : null}>
        <h4
          className={`${step.complete ? "green" : ""} ${
            i === 0 ? "pr2" : i === 2 ? "pl2" : "px2"
          } mb0 flex items-center ${step.active || step.complete ? "" : "regular muted"}`}>
          <span className={`material-icons mr1`}>{step.complete ? "check_circle" : "radio_button_unchecked"}</span>
          <span>{step.label}</span>
        </h4>
      </div>
    ))}
  </div>
)

Stepper.propTypes = {
  steps: PropTypes.array.isRequired,
  nextStep: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
}

const tmpSteps = ["/cart/shipping", "/cart/payment", "/cart/confirmation"]

const mapStateToProps = (state, ownProps) => ({
  steps: state.user.cart.steps.map(currentStep => ({
    ...currentStep,
    active: currentStep.to === ownProps.currentStep,
    complete: tmpSteps.indexOf(ownProps.currentStep) > tmpSteps.indexOf(currentStep.to)
  })),
  disabled:
    state.user.cart.promo.status === "PENDING" ||
    state.user.cart.status === "PURCHASING" ||
    !state.user.cart.orders ||
    !state.user.cart.orders.length
})

const mapDispatchToProps = dispatch => ({
  nextStep: bindActionCreators(nextStep, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Stepper)
