import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { ButtonDropdown } from "@bitcine/cs-theme"
import { can, VIEW_SCREENER_ANALYTICS } from "@helpers/authorization"
import { GLOBALS } from "@src/config"

class Export extends React.Component {
  export(format) {
    let { parentType, parentID } = this.props
    window.open(
      `${GLOBALS.API_URL}/shared-screeners/export?parentType=${parentType}&parentID=${parentID}&format=${format}`,
      "_blank"
    )
  }
  exportComments() {
    const { orgID, screenerRoomID, parentType } = this.props
    if (parentType === "screener") {
      const { parentID, projectID } = this.props
      window.open(`${GLOBALS.API_URL}/projects/${projectID}/screeners/${parentID}/comments`, "_blank")
    } else if (parentType === "screener_group") {
      window.open(`${GLOBALS.API_URL}/organizations/${orgID}/screener-groups/${screenerRoomID}/comments`, "_blank")
    }
  }
  showComments() {
    const { parentType, groupHasComments, screenerHasComments } = this.props
    if (parentType === "organization") return false
    else if (parentType === "screener") return screenerHasComments
    else if (parentType === "screener_group") return groupHasComments
    else return false
  }
  render() {
    if (!this.props.canViewAnalytics) return null
    return (
      <ButtonDropdown
        button={{
          text: "Export",
          className: "white small ml1"
        }}
        dropdown={{
          clickCloses: true,
          content: [
            {
              text: "Export Reviewers as CSV",
              icon: "bar_chart",
              onClick: () => this.export("csv"),
              show: true
            },
            {
              text: "Export Reviewers as PDF",
              icon: "picture_as_pdf",
              onClick: () => this.export("pdf"),
              show: this.props.parentType === "screener"
            },
            {
              text: "Export Comments",
              icon: "comment",
              onClick: () => this.exportComments(),
              show: this.showComments()
            }
          ].filter(opt => opt.show)
        }}
      />
    )
  }
}

Export.propTypes = {
  parentType: PropTypes.string,
  parentID: PropTypes.string,
  canViewAnalytics: PropTypes.bool
}

const mapStateToProps = state => ({
  parentType: state.shared_screeners.query.parentType,
  parentID: state.shared_screeners.query.parentID,
  canViewAnalytics: can(state.user.auth, [VIEW_SCREENER_ANALYTICS]),
  orgID: state.user.auth.organization_id,
  screenerRoomID: state.screener_rooms.screenerRoom.data._id,
  projectID: state.project.details._id,
  groupHasComments: state.screener_rooms.screenerRoom.data?.has_comments,
  screenerHasComments: state.project.files.screener?.has_comments
})

export default connect(mapStateToProps)(Export)
