import React from "react"
import PropTypes from "prop-types"
import { UnmountClosed } from "react-collapse"

class Wrap extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: props.defaultOpen || false }
  }
  render() {
    return (
      <div className='border-bottom border-gray-5'>
        <button
          style={{ height: "55px" }}
          onClick={() => this.setState(state => ({ open: !state.open }))}
          className='cs-button col-12 flex items-center link'>
          <div className='px2 flex items-center justify-between col-12'>
            <span className={this.state.open ? "" : "regular"}>{this.props.title}</span>
            <span className='material-icons'>{this.state.open ? "keyboard_arrow_up" : "keyboard_arrow_down"}</span>
          </div>
        </button>
        <UnmountClosed isOpened={this.state.open}>
          <div className='px2 pb2'>{this.props.children}</div>
        </UnmountClosed>
      </div>
    )
  }
}

Wrap.propTypes = {
  defaultOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default Wrap
