export const GET_CREDIT_CATEGORIES = "GET_CREDIT_CATEGORIES"
export const GET_CREDIT_CATEGORIES_PENDING = "GET_CREDIT_CATEGORIES_PENDING"
export const GET_CREDIT_CATEGORIES_FULFILLED = "GET_CREDIT_CATEGORIES_FULFILLED"
export const REORDER_CREDIT_CATEGORIES = "REORDER_CREDIT_CATEGORIES"
export const ADD_CREDIT_CATEGORY_PENDING = "ADD_CREDIT_CATEGORY_PENDING"
export const ADD_CREDIT_CATEGORY_FULFILLED = "ADD_CREDIT_CATEGORY_FULFILLED"
export const ADD_CREDIT_CATEGORY = "ADD_CREDIT_CATEGORY"
export const REORDER_CREDIT_ROLES = "REORDER_CREDIT_ROLES"
export const REORDER_CREDIT_ROLES_FULFILLED = "REORDER_CREDIT_ROLES_FULFILLED"
export const ADD_CREDIT_ROLES = "ADD_CREDIT_ROLES"
export const UPDATE_CREDIT_CATEGORY_NAME = "UPDATE_CREDIT_CATEGORY_NAME"
export const TOGGLE_MENU = "TOGGLE_MENU"
export const TOGGLE_ROLES = "TOGGLE_ROLES"
export const REMOVE_CREDIT_CATEGORY = "REMOVE_CREDIT_CATEGORY"
export const SAVE_CREDIT_CATEGORY_NAME = "SAVE_CREDIT_CATEGORY_NAME"
export const SAVE_CREDIT_CATEGORY = "SAVE_CREDIT_CATEGORY"
export const ADD_CREDIT_ROLE = "ADD_CREDIT_ROLE"
export const ADD_CREDIT_ROLE_PENDING = "ADD_CREDIT_ROLE_PENDING"
export const ADD_CREDIT_ROLE_FULFILLED = "ADD_CREDIT_ROLE_FULFILLED"

export const UPDATE_CREDIT_ROLE_NAME = "UPDATE_CREDIT_ROLE_NAME"
export const REMOVE_CREDIT_ROLE = "REMOVE_CREDIT_ROLE"
export const REMOVE_CREDIT_ROLE_FULFILLED = "REMOVE_CREDIT_ROLE_FULFILLED"
export const SAVE_CREDIT_ROLE = "SAVE_CREDIT_ROLE"
export const UPDATE_CREDIT_ROLE = "UPDATE_CREDIT_ROLE"
export const UPDATE_CREDIT_ROLE_FULFILLED = "UPDATE_CREDIT_ROLE_FULFILLED"
export const SAVE_CREDIT_ROLE_NAME = "SAVE_CREDIT_ROLE_NAME"

export const GET_ARCHIVED_CREDIT_CATEGORIES = "GET_ARCHIVED_CREDIT_CATEGORIES"
export const GET_ARCHIVED_CREDIT_CATEGORIES_PENDING = "GET_ARCHIVED_CREDIT_CATEGORIES_PENDING"
export const GET_ARCHIVED_CREDIT_CATEGORIES_FULFILLED = "GET_ARCHIVED_CREDIT_CATEGORIES_FULFILLED"
export const TOGGLE_ARCHIVED_ROLES = "TOGGLE_ARCHIVED_ROLES"
export const UNARCHIVE_CREDIT_CATEGORY = "UNARCHIVE_CREDIT_CATEGORY"

export const GET_CREDIT_ROLES = "GET_CREDIT_ROLES"
export const GET_CREDIT_ROLES_PENDING = "GET_CREDIT_ROLES_PENDING"
export const GET_CREDIT_ROLES_FULFILLED = "GET_CREDIT_ROLES_FULFILLED"
