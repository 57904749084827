import React from "react"
import PropTypes from "prop-types"
import humanFileSize from "@src/helpers/humanFileSize"
import { ButtonDropdown } from "@bitcine/cs-theme"
import "../styles.scss"

class Transfer extends React.Component {
  toggle = () => {
    if (this.props.transfer.status === "RUNNING") {
      window.asperaWeb.stopTransfer(this.props.transfer.aspera_uuid)
    } else {
      window.asperaWeb.resumeTransfer(this.props.transfer.aspera_uuid)
    }
  }
  cancel = () => {
    if (confirm("Are you sure you want to cancel this transfer?")) {
      window.asperaWeb.stopTransfer(this.props.transfer.aspera_uuid)
    }
  }
  render() {
    const { transfer } = this.props
    return (
      <div className={`bg-white rounded box-shadow p2 mb1 max-width-3 mx-auto`}>
        <div className='flex items-center'>
          <div className='flex-auto'>
            <strong className='truncate block'>{transfer.name}</strong>
            <div className='bg-gray-5 rounded relative pt1 overflow-hidden my1 flex-auto'>
              <div
                style={{ width: transfer.progress }}
                className={`absolute top-0 left-0 bottom-0 overflow-hidden z4 col-12 bg-green progress`}/>
            </div>
            <div className='flex items-center justify-between'>
              <div className='capitalize'>
                {transfer.status.toLowerCase()} -&nbsp;
                {transfer.current_size &&
                  transfer.total_size &&
                  `${humanFileSize(transfer.current_size)} / ${humanFileSize(transfer.total_size)} - `}
                {transfer.progress}
              </div>
              {transfer.time_remaining && <div>{transfer.time_remaining}...</div>}
            </div>
          </div>
          {transfer.method.toLowerCase() === "aspera" && (
            <ButtonDropdown
              button={{
                className: "link material-icons ml2",
                text: "more_vert"
              }}
              dropdown={{
                clickCloses: true,
                content: [
                  {
                    text: transfer.status === "RUNNING" ? "Pause" : "Resume",
                    onClick: () => this.toggle()
                  },
                  {
                    text: "Cancel",
                    onClick: () => this.cancel()
                  }
                ]
              }}/>
          )}
        </div>
      </div>
    )
  }
}

Transfer.propTypes = {
  transfer: PropTypes.object.isRequired
}

export default Transfer
