import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateNewReviewers } from "@api/preview/send"
import dayjs from "dayjs"
import DatePicker from "react-datepicker"

const CustomDatePicker = ({ value, onClick }) => (
  <button className='cs-button white' style={{ minWidth: "100%", paddingLeft: "40px" }} onClick={onClick}>
    {value ? value : "None Selected"}
  </button>
)

CustomDatePicker.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func
}

const getDate = value => {
  if (value && dayjs(value).isValid()) {
    return dayjs
      .utc(value)
      .local()
      .toDate()
  }
  return null
}

const DateRestrictions = ({ stateKey, newReviewers, updateNewReviewers }) => {
  return (
    <div className='py2 border-bottom border-gray-5'>
      <div className='flex items-center'>
        <div className='mr3'>
          <label className='cs-label' htmlFor='expiry'>
            Available At (local time):
          </label>
          <DatePicker
            selected={getDate(newReviewers.available_at)}
            onChange={e => updateNewReviewers(stateKey, "available_at", e.toISOString())}
            minDate={new Date()}
            showTimeSelect
            timeFormat='HH:mm'
            timeIntervals={1}
            timeCaption='time'
            dateFormat='MMMM d, yyyy h:mm aa'
            customInput={<CustomDatePicker/>}/>
          <p className='italic mt1'>
            {newReviewers.available_at ? (
              <span>
                UTC: <b>{dayjs.utc(newReviewers.available_at).format("MMM D, YYYY [at] h:mma")}</b>.
              </span>
            ) : (
              <span>Available immediately.</span>
            )}
          </p>
        </div>
        <div>
          <label className='cs-label' htmlFor='expiry'>
            Expires At (local time):
          </label>
          <DatePicker
            selected={getDate(newReviewers.expiry)}
            onChange={e => updateNewReviewers(stateKey, "expiry", e.toISOString())}
            minDate={new Date()}
            showTimeSelect
            timeFormat='HH:mm'
            timeIntervals={1}
            timeCaption='time'
            dateFormat='MMMM d, yyyy h:mm aa'
            customInput={<CustomDatePicker/>}/>
          <p className='italic mt1'>
            {newReviewers.expiry ? (
              <span>
                UTC: <b>{dayjs.utc(newReviewers.expiry).format("MMM D, YYYY [at] h:mma")}</b>.
              </span>
            ) : (
              <span>Never expires.</span>
            )}
          </p>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = dispatch => ({
  updateNewReviewers: bindActionCreators(updateNewReviewers, dispatch)
})

DateRestrictions.propTypes = {
  newReviewers: PropTypes.object.isRequired,
  expiry: PropTypes.string,
  available_at: PropTypes.string,
  updateNewReviewers: PropTypes.func.isRequired,
  stateKey: PropTypes.oneOf(["email", "link"]).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(DateRestrictions)
