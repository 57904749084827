import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Modal, Status } from "@bitcine/cs-theme"
import { toggleImportModal, importFileIntoAccount } from "@api/download_link/"
import SelectProject from "./delivery/select_project"
import SelectFolder from "./delivery/select_folder"

class ImportFileModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active_project_id: null
    }
  }
  onClose = () => {
    this.props.toggleImportModal(null)
  }
  setFolder(folderID) {
    this.props.importFileIntoAccount(this.props.file, this.state.active_project_id, folderID)
  }
  render() {
    return (
      <Modal title='Import File to CineSend Account' onClose={this.onClose} open={this.props.open}>
        <Status pending={this.props.status === "PENDING"} error={this.props.status === "ERROR"}>
          {this.state.active_project_id ? (
            <SelectFolder
              projectID={this.state.active_project_id}
              clearFolder={() => this.setState({ active_project_id: null })}
              setFolder={folderID => this.setFolder(folderID)}/>
          ) : (
            <SelectProject setProject={projectID => this.setState({ active_project_id: projectID })}/>
          )}
        </Status>
      </Modal>
    )
  }
}

ImportFileModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleImportModal: PropTypes.func.isRequired,
  file: PropTypes.object,
  importFileIntoAccount: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  open: state.download_link.active_import_file !== null,
  file: state.download_link.active_import_file,
  status: state.download_link.import_status
})

const mapDispatchToProps = dispatch => ({
  toggleImportModal: bindActionCreators(toggleImportModal, dispatch),
  importFileIntoAccount: bindActionCreators(importFileIntoAccount, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportFileModal)
