import { UPDATE_TAGS, GET_RECENT_TAGS, GET_TAGS } from "@actions/project/tags"
import { POST_DEFAULTS } from "@constants"
import { get } from "@api/fetch"

/**
 * Get Tags
 *
 * @returns {Function} dispatch
 */

export const getTags = () => (dispatch, getState) => {
  const organizationId = getState().user.auth.organization_id
  dispatch(get(GET_TAGS, `tags${organizationId ? "?organization_id=" + organizationId : ""}`))
}

export const saveTags = (files, tags, organizationId) => dispatch => {
  fetch(`tags${organizationId ? "?organization_id=" + organizationId : ""}`, {
    ...POST_DEFAULTS,
    body: JSON.stringify({
      values: tags.map(tag => {
        return {
          _id: tag._id,
          name: tag.name
        }
      }),
      targetFiles: files.map(file => {
        return {
          _id: file._id,
          type: file.type
        }
      })
    })
  })

  dispatch({ type: UPDATE_TAGS, payload: { files, tags } })
}

export const getRecentTags = organizationId => dispatch =>
  dispatch(get(GET_RECENT_TAGS, `tags/recent${organizationId ? "?organization_id=" + organizationId : ""}`))
