import environment from "@helpers/environment"
import { GLOBALS } from "@src/config"

//////////////////////////////////////////
//////////// Fetch Defaults //////////////
//////////////////////////////////////////

export const DEFAULTS = {
  credentials: "include",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
}

export const POST_DEFAULTS = {
  method: "POST",
  ...DEFAULTS
}

export const DELETE_DEFAULTS = {
  method: "DELETE",
  ...DEFAULTS
}

export const PUT_DEFAULTS = {
  method: "PUT",
  ...DEFAULTS
}

export const VALIDATE_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const VALIDATE_PASSWORD = new RegExp(
  [
    `^`, // match the start of the string
    `(`, // first capture group
    `(?=.*[\\d]+.*)`, // must contain a digit
    `|`, // or...
    `(?=.*[\\W]+.*)`, // must contain a special char
    `)`, // end first capture group
    `(?=.*[\\w]+.*)`, // must contain letters
    `[\\d\\w~!@#$%^&*,\.?]`, // match any digit, word or special character
    `{8,}`, // match 8 or more of the preceding token
    `$` // match the end of the string
  ].join("")
)

export const PROJECT_NAV_ITEMS = [
  {
    text: "Files",
    icon: "folder",
    to: "files"
  },
  {
    text: "Details",
    icon: "info",
    to: "details"
  },
  {
    text: "History",
    icon: "history",
    to: "history"
  }
]

export const FILES_NAV_ITEMS = [
  {
    text: "All Files",
    icon: "folder",
    to: "all"
  },
  {
    text: "Video Files",
    icon: "movie",
    to: "videos"
  },
  {
    text: "Discs",
    icon: "folder",
    to: "discs"
  },
  {
    text: "DCPs",
    icon: "folder",
    to: "dcps"
  },
  {
    text: "Audio",
    icon: "audiotrack",
    to: "audio"
  },
  {
    text: "Captions",
    icon: "closed_caption",
    to: "captions"
  },
  {
    text: "Subtitles",
    icon: "subtitles",
    to: "subtitles"
  },
  {
    text: "Documents",
    icon: "insert_drive_file",
    to: "documents"
  }
]

export const S3_FINE_UPLOADER_DEFAULTS = {
  debug: false,
  signature: {
    endpoint: `${GLOBALS.API_URL}/s3/signature`
  },
  request: {
    endpoint: GLOBALS.AWS_ACCELERATED_ENDPOINT,
    accessKey: null
  },
  retry: {
    enableAuto: true
  },
  deleteFile: {
    enabled: true
  },
  cors: {
    expected: true
  },
  iframeSupport: {
    localBlankPagePath: "/blank.html"
  },
  chunking: {
    /**
     * Part Size
     * s3 only accepts chunked transfers with less than 10,000 chunks
     * Since we need to expect a max upload size of 200gb, our chunk size is 20MB
     */

    partSize: 20000000, // in bytes

    enabled: true,
    concurrent: {
      enabled: true
    }
  }
}

export const FILE_TABLE_HEADERS = [
  {
    className: "flex-auto px1 truncate small",
    label: "Name",
    key: "name",
    hasCheckBox: true
  },
  {
    className: "col-3 px1 small",
    label: "Created",
    key: "created_at",
    hasCheckBox: false
  },
  {
    className: "col-3 px1 small",
    label: "Size",
    key: "size",
    hasCheckBox: false
  }
]

export const MATCH_PARAMS_TO_FILE_TYPE = {
  videos: "source_file",
  source_files: "source_file",
  dcps: "dcp",
  documents: "document",
  posters: "project_poster",
  subtitles: "subtitle_track",
  captions: "closed_caption",
  audio: "audio_track",
  discs: "disc_output"
}

export const ORDER_TYPES = [
  {
    label: "Order DCP",
    key: "dcp"
  },
  {
    label: "Order Closed Captions",
    key: "closed-captions"
  },
  {
    label: "Order DVD / Blu-ray",
    key: "disc"
  }
  // {
  // 	label: 'Order QC Report',
  // 	key: 'qc-report',
  // 	lineBefore: true
  // },
  // {
  // 	label: 'Order QA Report',
  // 	key: 'qa-report'
  // }
]

export const NOTIFICATION_TYPES = [
  {
    type: "Account",
    key: "account",
    description: "Notify me about:",
    rows: [
      {
        flag: "storage_space",
        text: "Storage space",
        description: "When your account is running out of storage space."
      },
      // {
      // 	flag: "remaining_screener_credits",
      // 	text: "Remaining screener credits",
      // 	description: "When your account is running out screener credits."
      // },
      {
        flag: "payment_updates",
        text: "Payment updates",
        description: "When your payment has been changed or if there is a processing error."
      },
      {
        flag: "plan_updates",
        text: "Plan updates",
        description: "When your account plan has been upgraded or downgraded."
      }
    ]
  },
  {
    type: "Security",
    key: "security",
    description: "Notify me about:",
    rows: [
      {
        flag: "device_access",
        text: "Device access",
        description: "When your account is accessed by a new web browser."
      },
      {
        flag: "password_attempts",
        text: "Password attempts",
        description: "If 3 or more password attempts are used to log in."
      },
      {
        flag: "log_in_credential_updates",
        text: "Log in credential updates",
        description: "When your log in password is changed or two step verification is enabled or disabled."
      }
    ]
  },
  {
    type: "Projects",
    key: "projects",
    description: "Notify me about:",
    rows: [
      // {
      // 	flag: "project_file_activity",
      // 	text: "Project file activity",
      // 	description: "When project files are shared and received."
      // },
      {
        flag: "file_requests",
        text: "File requests",
        description: "When file requests are shared and files are uploaded to the project."
      },
      {
        flag: "order_updates",
        text: "Order updates",
        description: "When project orders are created, updated, and completed."
      }
    ]
  },
  {
    type: "Screeners and Screener Rooms",
    key: "screeners_and_screener_rooms",
    description: "Notify me about:",
    rows: [
      {
        flag: "screener_updates",
        text: "Screener updates",
        description: "When screener rooms or screeners are created, modified and shared."
      }
      // {
      // 	flag: "screener_security",
      // 	text: "Screener security",
      // 	description: "When screener active watermarking detects malicious activity."
      // },
      // {
      // 	flag: "reviewer_activity",
      // 	text: "Reviewer activity",
      // 	description: "When reviewers open and watch shared screener rooms or screeners."
      // }
    ]
  },
  {
    type: "Updates from CineSend",
    key: "updates_from_cinesend",
    description: "Notify me about:",
    rows: [
      // {
      // 	flag: "cinesend_news",
      // 	text: "Screener updates",
      // 	description: "News about CineSend product and feature updates."
      // },
      {
        flag: "cinesend_surveys",
        text: "CineSend surveys",
        description: "Participate in CineSend research surveys to help improve future updates."
      }
    ]
  }
]

export const CART_ICONS = {
  cc: "closed_caption",
  dcp: "movie",
  discs: "album",
  screeners: "album",
  ship_drive: "local_shipping",
  screeners_top_up: "movie",
  inspection: "verified_user",
  conversion: "swap_horizontal_circle"
}

export const STATUS_MAP = {
  None: {
    color: 'gray',
    text: 'N/A',
    icon: ''
  },
  Pending: {
    color: "gray",
    text: "Pending",
    icon: "remove_circle"
  },
  Suspended: {
    color: "gray",
    text: "Pending",
    icon: "remove_circle"
  },
  "In Progress": {
    color: "orange",
    text: "In Progress",
    icon: "remove_circle"
  },
  Error: {
    color: "red",
    text: "Error",
    icon: "error_outline"
  },
  Issue: {
    color: "red",
    text: "Error",
    icon: "error_outline"
  },
  Complete: {
    color: "green",
    text: "Complete",
    icon: "check_circle"
  },
  Shipped: {
    color: "green",
    text: "Shipped",
    icon: "check_circle"
  }
}