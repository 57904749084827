import { post } from "@api/fetch"

import { SEND_SUPPORT, UPDATE_SUPPORT } from "@actions/account/support"

import { addGlobalMessage } from "@api/utils/"

export const updateSupport = (key, value) => dispatch => dispatch({ type: UPDATE_SUPPORT, key, value })

export const sendSupport = () => (dispatch, getState) => {
  dispatch(
    post(
      SEND_SUPPORT,
      "users/support",
      {
        email: getState().user.auth.email,
        subject: getState().user.support.order,
        message: getState().user.support.message
      },
      () => dispatch(addGlobalMessage("Support Request Sent", "success"))
    )
  )
}
