import { push } from "connected-react-router"
import { previewDocument } from "@api/project/files/preview"
import { updateFiles, updateSearch } from "@api/project/files/"

export const onClick = (e, file, index) => dispatch => {
  if (!["BUTTON", "INPUT"].includes(e.target.tagName)) {
    if (file.type === "folder") {
      dispatch(updateSearch(""))
      dispatch(push(`/projects/${file.project_id}/files/folder/${file._id}`))
    } else {
      dispatch(previewDocument(file))
    }
  }
}

export const isFileDisabled = file => !file.is_ready || !!file.frozen_at

export const onHeaderCheck = () => (dispatch, getState) => {
  dispatch(
    updateFiles(
      "checked",
      getState().project.files.items.checked.length
        ? []
        : getState()
          .project.files.items.list.filter(file => !isFileDisabled(file))
          .reduce((_ids, file) => [..._ids, file._id], [])
    )
  )
}

export const onCheck = (file, keys) => (dispatch, getState) => {
  if (!isFileDisabled(file)) {
    let checked = getState().project.files.items.checked

    const isPriorChecked = checked.includes(file._id)
    // Shift key is used to select many at once
    if (keys.includes("shiftKey")) {
      // If there are no checked items, just make this row checked and avoid loops below
      if (!checked.length) {
        checked = [file._id]
      }
      // If this is already checked and no other files are, remove it and avoid loops
      else if (checked.length === 1 && checked[0] === file._id) {
        checked = []
      } else {
        // all of the files in state
        const fileIDs = getState().project.files.items.list.map(({ _id }) => _id)

        // The new checked state will be based off of the first checked file in checked array
        // Shift key can be used to go either up or down the list
        const firstChecked = checked[0]
        const selected = file._id

        // The selected file index is above the first selected file
        if (fileIDs.indexOf(selected) < fileIDs.indexOf(firstChecked)) {
          checked = fileIDs.filter(
            (_id, index) => index <= fileIDs.indexOf(firstChecked) && index >= fileIDs.indexOf(selected)
          )
        }
        // The selected file index is below the first selected file
        else {
          checked = fileIDs.filter(
            (_id, index) => index >= fileIDs.indexOf(firstChecked) && index <= fileIDs.indexOf(selected)
          )
        }
      }
    } else {
      // If its in the checked array, remove it
      // If not, add it to the checked array
      checked = isPriorChecked ? checked.filter(_id => _id !== file._id) : [...checked, file._id]
    }
    dispatch(
      updateFiles(
        "checked",
        checked.filter(_id => !isFileDisabled(getState().project.files.items.list.find(f => f._id === _id)))
      )
    )
  }
}
