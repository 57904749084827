export const GET_PROJECT_FILE_REQUESTS = "GET_PROJECT_FILE_REQUESTS"
export const GET_PROJECT_FILE_REQUESTS_PENDING = "GET_PROJECT_FILE_REQUESTS_PENDING"
export const GET_PROJECT_FILE_REQUESTS_FULFILLED = "GET_PROJECT_FILE_REQUESTS_FULFILLED"
export const GET_PROJECT_FILE_REQUESTS_FAILED = "GET_PROJECT_FILE_REQUESTS_REJECTED"

export const SAVE_PROJECT_FILE_REQUEST = "SAVE_PROJECT_FILE_REQUEST"
export const SAVE_PROJECT_FILE_REQUEST_PENDING = "SAVE_PROJECT_FILE_REQUEST_PENDING"
export const SAVE_PROJECT_FILE_REQUEST_FULFILLED = "SAVE_PROJECT_FILE_REQUEST_FULFILLED"
export const SAVE_PROJECT_FILE_REQUEST_FAILED = "SAVE_PROJECT_FILE_REQUEST_REJECTED"

export const CREATE_PROJECT_FILE_REQUEST = "CREATE_PROJECT_FILE_REQUEST"
export const CREATE_PROJECT_FILE_REQUEST_PENDING = "CREATE_PROJECT_FILE_REQUEST_PENDING"
export const CREATE_PROJECT_FILE_REQUEST_FULFILLED = "CREATE_PROJECT_FILE_REQUEST_FULFILLED"
export const CREATE_PROJECT_FILE_REQUEST_FAILED = "CREATE_PROJECT_FILE_REQUEST_REJECTED"

export const SEND_PROJECT_FILE_REQUEST = "SEND_PROJECT_FILE_REQUEST"
export const SEND_PROJECT_FILE_REQUEST_PENDING = "SEND_PROJECT_FILE_REQUEST_PENDING"
export const SEND_PROJECT_FILE_REQUEST_FULFILLED = "SEND_PROJECT_FILE_REQUEST_FULFILLED"
export const SEND_PROJECT_FILE_REQUEST_FAILED = "SEND_PROJECT_FILE_REQUEST_REJECTED"

export const UPDATE_PROJECT_FILE_REQUEST = "UPDATE_PROJECT_FILE_REQUEST"

export const TOGGLE_PROJECT_FILE_REQUEST_MODAL = "TOGGLE_PROJECT_FILE_REQUEST_MODAL"
