import { get, put } from "@api/fetch"
import {
  GET_USER_ORGANIZATION,
  UPDATE_USER_ORGANIZATION,
  SAVE_USER_ORGANIZATION,
  ACTIVATE_APPLICATION
} from "@actions/organizations/"
import { GLOBALS } from "@src/config"
import { addGlobalMessage } from "@api/utils/"

export const getUserOrganization = () => (dispatch, getState) =>
  dispatch(get(GET_USER_ORGANIZATION, `organizations/${getState().user.auth.organization_id}`))

export const updateUserOrganization = (key, value) => dispatch =>
  dispatch({ type: UPDATE_USER_ORGANIZATION, key, value })

export const saveUserOrganization = (key, value) => (dispatch, getState) => {
  dispatch(
    put(SAVE_USER_ORGANIZATION, `organizations/${getState().user.auth.organization_id}`, { key, value }, null)
  )
}

/**
 * Activates an app for an organization or navigates to plan page
 *
 * @param {String} [app]
 *
 * @returns {Promise/Function}
 */

export const activateApplication = app => (dispatch, getState) => {
  const can_upgrade = getState().user.auth.organization_id !== null
  if (can_upgrade) {
    dispatch(addApplication(app))
  } else {
    window.open(GLOBALS.ACCOUNTS_URL + '/settings/billing' + "?show_application_requires_team_account_warning=true", "_blank")
  }
}

/**
 * Activates an app for an organization or navigates to plan page
 *
 * @param {String} [app]
 *
 * @returns {Promise}
 */

const addApplication = app => (dispatch, getState) =>
  dispatch(
    put(
      ACTIVATE_APPLICATION,
      `organizations/${getState().user.auth.organization_id}/settings/apps/enable`,
      { value: app },
      () => {
        dispatch(addGlobalMessage("App activated!", "success"))
        window.open(getState().user.auth.app_permissions[app].url, "_blank")
      },
      () => dispatch(addGlobalMessage("An error occurred", "error"))
    )
  )
