export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT"

export const LOGIN = "LOGIN"
export const LOGIN_PENDING = "LOGIN_PENDING"
export const LOGIN_FULFILLED = "LOGIN_FULFILLED"
export const LOGIN_FAILED = "LOGIN_REJECTED"

export const VALIDATE_EMAIL_ADDRESS = "VALIDATE_EMAIL_ADDRESS"
export const VALIDATE_EMAIL_ADDRESS_PENDING = "VALIDATE_EMAIL_ADDRESS_PENDING"
export const VALIDATE_EMAIL_ADDRESS_FULFILLED = "VALIDATE_EMAIL_ADDRESS_FULFILLED"
export const VALIDATE_EMAIL_ADDRESS_FAILED = "VALIDATE_EMAIL_ADDRESS_REJECTED"

export const SIGNUP = "SIGNUP"
export const SIGNUP_FAILED = "SIGNUP_REJECTED"
export const SIGNUP_FULFILLED = "SIGNUP_FULFILLED"
export const SIGNUP_PENDING = "SIGNUP_PENDING"

export const SEND_RESET_LINK = "SEND_RESET_LINK"
export const SEND_RESET_LINK_PENDING = "SEND_RESET_LINK_PENDING"
export const SEND_RESET_LINK_FAILED = "SEND_RESET_LINK_REJECTED"
export const SEND_RESET_LINK_FULFILLED = "SEND_RESET_LINK_FULFILLED"

export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_PENDING = "RESET_PASSWORD_PENDING"
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_REJECTED"
export const RESET_PASSWORD_FULFILLED = "RESET_PASSWORD_FULFILLED"

export const TOGGLE_OPEN_AUTH_MODAL = "TOGGLE_OPEN_AUTH_MODAL"

export const GET_SIGNUP_INVITE = "GET_SIGNUP_INVITE"
export const GET_SIGNUP_INVITE_PENDING = "GET_SIGNUP_INVITE_PENDING"
export const GET_SIGNUP_INVITE_FULFILLED = "GET_SIGNUP_INVITE_FULFILLED"
export const GET_SIGNUP_INVITE_FAILED = "GET_SIGNUP_INVITE_REJECTED"
