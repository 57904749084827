import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Link } from "react-router-dom"
import { DropTarget } from "react-dnd"
import flow from "lodash.flow"
import { moveProjectsToFolder, updateFolderTitle, setFolderHovered } from "@api/project_folders"
import Title from "../shared/title"
import Posters from "./posters"

class Folder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditingTitle: false
    }
  }
  saveTitle(value) {
    this.props.updateFolderTitle(this.props.folder, value)
    this.setState({ isEditingTitle: false })
  }
  render() {
    const { folder, connectDropTarget } = this.props
    const content = (
      <div>
        <Link to={`/projects/folder/${folder.slug}`}>
          <Posters addingProjectToFolder={this.props.hoveredFolderID === folder._id} folder={folder}/>
        </Link>
        <Title
          folder
          title={folder.title}
          onFocus={e => this.setState({ isEditingTitle: true })}
          onComplete={value => this.saveTitle(value)}/>
      </div>
    )
    return content
    return connectDropTarget(content)
  }
}

const dragTarget = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  draggedFile: monitor.getItem()
})

let timeout = null
let hasHoveredOut = null

const folderTarget = {
  drop(props, monitor, component) {
    monitor.getItem().moveProjectsToFolder([monitor.getItem().project._id], props.folder)
  },
  hover(props, monitor, component) {
    // clearTimeout(timeout)
    // const currentFolder = component.store.getState().projects.hoveredFolderID
    // if (currentFolder !== props.folder._id) {
    //   component.store.dispatch(setFolderHovered(props.folder._id))
    // } else {
    //   if (!hasHoveredOut) {
    //     component.store.dispatch(setFolderHovered(null))
    //     hasHoveredOut = true
    //   }
    // }

    // timeout = setTimeout(() => {
    //   component.store.dispatch(setFolderHovered(null))
    //   hasHoveredOut = true
    // }, 150)
  }
}

const mapStateToProps = state => ({
  hoveredFolderID: state.projects.hoveredFolderID
})

const mapDispatchToProps = dispatch => ({
  moveProjectsToFolder: bindActionCreators(moveProjectsToFolder, dispatch),
  setFolderHovered: bindActionCreators(setFolderHovered, dispatch),
  updateFolderTitle: bindActionCreators(updateFolderTitle, dispatch)
})

Folder.propTypes = {
  folder: PropTypes.object.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  moveProjectsToFolder: PropTypes.func.isRequired,
  setFolderHovered: PropTypes.func.isRequired,
  updateFolderTitle: PropTypes.func.isRequired,
  hoveredFolderID: PropTypes.string
}

export default flow(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  DropTarget("PROJECT", folderTarget, dragTarget)
)(Folder)
