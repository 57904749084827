import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal, Status, Table } from "@bitcine/cs-theme"
import { toggleModal, getSharedScreenerSessions } from "@api/shared_screeners/modals"
import formatDate from "@helpers/formatDate"
import Heatmap from "../components/heatmap"

const Sessions = ({ toggleModal, status, list, getSharedScreenerSessions, sharedScreener }) => {
  useEffect(() => {
    getSharedScreenerSessions(sharedScreener._id)
  }, [])
  return (
    <Modal open title={"Reviewer Sessions"} onClose={() => toggleModal("sessions")} width={3}>
      <Status
        pending={status === "PENDING"}
        error={status === "FAILED"}
        empty={!list.length}
        emptyMessage={{
          icon: "show_chart",
          title: "No Views",
          text: "This reviewer has not yet viewed any content."
        }}>
        <Table
          className='my3'
          widths={sharedScreener.type === "screener" ? ["auto", 180, 180, 180] : [200, "auto", 180, 180, 180]}
          header={{
            columns: [
              { text: "Title", hide: sharedScreener.type === "screener" },
              { text: "Device" },
              { text: "Connection" },
              { text: "Dates" },
              { text: "Watched" }
            ].filter(opt => !opt.hide)
          }}
          body={{
            data: list,
            row: {
              compact: true,
              render: [
                ...(sharedScreener.type === "screener"
                  ? []
                  : [data => <div className='truncate'>{data.video_title}</div>]),
                ...[
                  data => (
                    <div className='truncate'>
                      {data.location && (
                        <div>
                          <span>
                            {data.location.city}, {data.location.state_or_province_code}, {data.location.country_code}
                          </span>
                          <span className='block light small'>{data.location.organization}</span>
                        </div>
                      )}
                    </div>
                  ),
                  data => (
                    <div className='truncate'>
                      {data.browser && (
                        <div>
                          <span>
                            {data.browser.os.name} {data.browser.os.version}
                          </span>
                          <span className='block light small'>
                            {data.browser.browser.name} {data.browser.browser.version}
                          </span>
                        </div>
                      )}
                    </div>
                  ),
                  data => (
                    <div>
                      {formatDate(data.last_viewed_at)}
                      <span className='block light small'>Created: {formatDate(data.created_at)}</span>
                    </div>
                  ),
                  data => <Heatmap data={data} showLive={true}/>
                ]
              ]
            }
          }}/>
      </Status>
    </Modal>
  )
}

Sessions.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  getSharedScreenerSessions: PropTypes.func.isRequired,
  sharedScreener: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  status: state.shared_screeners.modals.sessions.status,
  sharedScreener: state.shared_screeners.modals.sessions.sharedScreener,
  list: state.shared_screeners.modals.sessions.list
})

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  getSharedScreenerSessions: bindActionCreators(getSharedScreenerSessions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Sessions)
