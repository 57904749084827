import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateFilter, updateAllFilters } from "@api/project/filter"
import { Table, ButtonDropdown, ContextMenu } from "@bitcine/cs-theme"
import formatDate from "@helpers/formatDate"
import { filterFiles } from "@src/components/project/files/functions/filter"
import humanFileSize from "@src/helpers/humanFileSize"
import Icon from "@components/files/icon/"
import DragDropFile from "@src/components/project/files/components/dnd/file"
import TourTip from "@components/tour/tip"
import TourManager from "@components/tour/manager"
import EmptyProjectIcon from "@src/public/icons/empty/project.inline.svg"
import SearchIcon from "@src/public/icons/empty/search.inline.svg"
import { fileHeaders } from "@components/files/constants"
import FileViewToggle from "../toggle"
import Title from "./title"

class RowView extends React.Component {
  constructor() {
    super()

    this.state = {
      openFileMenuID: null
    }
  }
  componentDidMount() {
    if (localStorage.getItem("file_filters")) {
      this.props.updateAllFilters(JSON.parse(localStorage.getItem("file_filters")))
    }
  }
  updateSorting(sorting) {
    this.props.updateFilter("direction", sorting.direction)
    this.props.updateFilter("key", sorting.key)
  }
  setTourTipRef = ref => {
    this.tourTipRef = ref
  }
  render() {
    const { files, dialogOpen, filter } = this.props
    const filteredFiles = filterFiles(files.list, filter)
    const { openFileMenuID } = this.state

    return (
      <React.Fragment>
        <div className='relative'>
          <div className='absolute right-0 mr2'>
            <FileViewToggle/>
          </div>
          <Table
            className='my3'
            widths={dialogOpen ? [55, "auto", 140, 64] : [55, "auto", 140, 140, 145, 64]}
            header={{
              checkbox: {
                checked: files.checked.length > 0,
                intermediate: files.checked.length !== files.list.length,
                onChange: value => this.props.onHeaderCheck()
              },
              columns: fileHeaders.filter(header => !dialogOpen || !header.hideWithDialog),
              sorting: {
                direction: filter.direction,
                key: filter.key,
                onSortChange: sorting => this.updateSorting(sorting)
              }
            }}
            body={{
              data: filteredFiles,
              empty: {
                icon: filter.search ? <SearchIcon/> : <EmptyProjectIcon/>,
                title: filter.search
                  ? "No results found"
                  : this.props.folderID
                    ? "This folder is empty"
                    : "This project is empty",
                text: filter.search
                  ? "Try your search again"
                  : `Drag files here or click "New" above to browse files from your computer`
              },
              row: {
                customRender: (children, data) => (
                  <DragDropFile file={data}>
                    <ContextMenu
                      content={openFileMenuID && openFileMenuID === data._id ? [] : this.props.getMenuOptions(data)}>
                      {children}
                    </ContextMenu>
                  </DragDropFile>
                ),
                checkbox: {
                  checked: data => files.checked.includes(data._id),
                  onChange: (data, index) => this.props.onRowCheck(data),
                  disabled: data => !!data.frozen_at
                },
                onClick: (e, data, index) => {
                  if (this.tourTipRef && this.tourTipRef.contains(e.target)) {
                    e.preventDefault()
                  } else {
                    this.props.onRowClick(e, data, index)
                  }
                },
                render: [
                  {
                    hideWithDialog: false,
                    render: data => <Icon data={data} folderID={this.props.folderID} projectID={this.props.projectID}/>
                  },
                  {
                    hideWithDialog: false,
                    render: data => <Title file={data}/>
                  },
                  {
                    hideWithDialog: false,
                    render: data => data.kind
                  },
                  {
                    hideWithDialog: true,
                    render: data => (data.size ? humanFileSize(data.size) : "--")
                  },
                  {
                    hideWithDialog: true,
                    render: data => formatDate(data.created_at, false)
                  },
                  {
                    hideWithDialog: false,
                    render: data =>
                      dialogOpen ? (
                        ""
                      ) : (
                        <TourTip
                          tipId='file_row'
                          hidden={data._id !== filteredFiles[0]._id}
                          setRef={this.setTourTipRef}>
                          <ButtonDropdown
                            button={{
                              menu: true,
                              text: "more_vert",
                              onClick: () => this.setState({ openFileMenuID: data._id })
                            }}
                            dropdown={{
                              clickCloses: true,
                              content: this.props.getMenuOptions(data)
                            }}
                            onDropdownClose={() => this.setState({ openFileMenuID: null })}/>
                        </TourTip>
                      )
                  }
                ]
                  .filter(opt => !dialogOpen || !opt.hideWithDialog)
                  .reduce((allOpts, opt) => [...allOpts, opt.render], [])
              }
            }}/>
        </div>

        <TourManager
          disabled={files.list.length === 0}
          steps={[
            {
              key: "file_row"
            }
          ]}/>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  updateFilter: bindActionCreators(updateFilter, dispatch),
  updateAllFilters: bindActionCreators(updateAllFilters, dispatch)
})

const mapStateToProps = state => ({
  dialogOpen: state.utils.dialogOpen,
  files: state.project.files.items,
  filter: state.project.files.filter,
  // folderID: state.routerParams.location ? state.routerParams.location.query.folderID : null
})

RowView.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  projectID: PropTypes.string.isRequired,
  files: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  onHeaderCheck: PropTypes.func.isRequired,
  folderID: PropTypes.string,
  onRowCheck: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  getMenuOptions: PropTypes.func.isRequired,
  updateAllFilters: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(RowView)
