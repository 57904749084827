import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ScreenerRoomMembers from "@components/screener_rooms/screener_room/components/members"
import Wrap from "../components/secondary/wrap"
import Back from "../components/secondary/back"
import Title from "../components/secondary/title"
import Tabs from "../components/secondary/tabs"

const ScreenerRoomNav = ({ title, _id }) => (
  <Wrap>
    <React.Fragment>
      <Back to='/screener-rooms' tooltip='Back to screener rooms'/>
      <Title small='Screener room' main={title.length > 30 ? title.substring(0, 30) + "..." : title}/>
      <Tabs
        tabs={[
          {
            to: `/screener-rooms/${_id}/screeners`,
            label: "Build"
          },
          {
            to: `/screener-rooms/${_id}/reviewers`,
            label: "Share"
          }
        ]}/>

      <div className='flex flex-auto items-center justify-end'>
        <ScreenerRoomMembers/>
      </div>
    </React.Fragment>
  </Wrap>
)

ScreenerRoomNav.propTypes = {
  _id: PropTypes.string,
  title: PropTypes.string.isRequired
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps)(ScreenerRoomNav)
