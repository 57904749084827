import React from "react"
import PropTypes from "prop-types"
import Wrap from "./wrap"

class Advanced extends React.Component {
  render() {
    const { file, title } = this.props
    return (
      <Wrap title={title} defaultOpen={false}>
        <div>
          {Object.keys(file.general_info)
            .filter(key => typeof file.general_info[key] === "string")
            .map(key => (
              <div key={key} className='flex items-center mb1'>
                <div className='col-6 capitalize'>{key.split("_").join(" ")}:</div>
                <div className='col-6 bold'>
                  {key.toLowerCase() === "fileextension"
                    ? file.general_info[key]
                      ? file.general_info[key].split("?")[0]
                      : ""
                    : file.general_info[key]}
                  {key.toLowerCase() === "filesize" && " bytes"}
                </div>
              </div>
            ))}
        </div>
      </Wrap>
    )
  }
}

Advanced.propTypes = {
  file: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
}

export default Advanced
