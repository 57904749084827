import React from "react"
import { ReactComponent as Logo } from "@src/public/logo.svg"
import Pattern from "../images/welcome_pattern_no_shadow.png"
import BaseSlide from "./components/base"
import 'styled-components/macro'

const WelcomeSlide = () => (
  <BaseSlide>
    <div>
      <div
        css={`
          position: absolute;
          top: 50%;
          width: 100%;
          transform: translateY(-50%);
          text-align: center;
        `}>
        <div
          css={`
            width: 250px;
            margin: 0 auto 30px auto;
          `}>
          <Logo/>
        </div>
        <h2>Welcome to CineSend!</h2>
        <p>{`The world's leading encoding, storage, and delivery platform.`}</p>
      </div>
      <div
        css={`
          position: absolute;
          bottom: 0;
          width: 450px;
          left: 50%;
          transform: translateX(-50%);
        `}>
        <img
          className='cs-img'
          src={Pattern}
          css={`
            width: 100%;
          `}/>
      </div>
    </div>
  </BaseSlide>
)

export default WelcomeSlide
