import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Select from "react-select"
import { getCountries } from "@api/utils/"
import { updateVideo, getGenres } from "@api/ondemand_videos/"
import Multi from "./multi"
import CustomFields from "./custom_fields"
import Card from "./card"

const placeholders = {
  title: "Gone With The Wind",
  release_year: "2016",
  name: "John Smith",
  summary: "A short summary of your film..."
}

const fields = [
  "title",
  "release_year",
  "short_summary",
  "logline",
  "directors",
  "producers",
  "genre_ids",
  "countries",
  "subtitles",
  "languages",
]

const Details = ({
  video,
  genres,
  countries,
  languages,
  updateVideo,
  getCountries,
  getGenres,
  isLoadingCustomFields
}) => {
  let defaultState = {}
  fields.forEach(field => (defaultState[field] = video[field]))
  const [videoState, updateVideoState] = useState(defaultState)
  const [submitting, setSubmitting] = useState(false)
  useEffect(() => {
    getCountries()
  }, [getCountries])
  useEffect(() => {
    getGenres(video._id)
  }, [])
  return (
    <Card
      title='Metadata'
      submitted={video.is_metadata_submitted}
      isUpload={false}
      className='ml2'
      isSubmitting={submitting}
      onSubmit={() => {
        setSubmitting(true)
        updateVideo(video._id, { ...videoState, is_metadata_submitted: true }, () => setSubmitting(false))
      }}>
      <>
        <div className='flex flex-wrap items-center'>
          {["title", "release_year"].map((input, i) => (
            <div className={`col-6 mb2 ${i % 2 === 0 ? "pr1" : "pl1"}`} key={input}>
              <label className='cs-label capitalize' htmlFor={input}>
                {input.split("_").join(" ")}
              </label>
              <input
                disabled={video.is_metadata_submitted}
                id={input}
                data-testid={`video-request-${input}-input`}
                placeholder={placeholders[input]}
                value={videoState[input] || ""}
                className={`cs-input col-12`}
                onChange={e => updateVideoState({ ...videoState, [input]: e.target.value })}/>
            </div>
          ))}
        </div>
        <div className='flex'>
          <div className='col-6 pr1'>
            <Multi
              value={videoState.directors || ""}
              stateKey='director'
              disabled={video.is_metadata_submitted}
              update={(directors, save) => updateVideoState({ ...videoState, directors })}/>
          </div>
          <div className='col-6 pl1'>
            <Multi
              value={videoState.producers || ""}
              stateKey='producer'
              disabled={video.is_metadata_submitted}
              update={(producers, save) => updateVideoState({ ...videoState, producers })}/>
          </div>
        </div>
        <div className='flex mb2'>
          <div className='col-6 pr1'>
            <label className='cs-label'>Countries of Origin</label>
            <Select
              multi={true}
              disabled={video.is_metadata_submitted}
              options={countries}
              isLoading={!countries}
              value={videoState.countries}
              simpleValue={true}
              onChange={countries => updateVideoState({ ...videoState, countries })}/>
          </div>
          <div className='col-6 pl1'>
            {genres !== "" && (
              <>
                <label className='cs-label'>Genres</label>
                <Select
                  multi={true}
                  disabled={video.is_metadata_submitted}
                  options={genres}
                  value={genres.filter(opt => (videoState.genre_ids || []).includes(opt.value))}
                  simpleValue={true}
                  onChange={genreIDs => updateVideoState({ ...videoState, genre_ids: genreIDs })}/>
              </>
            )}
          </div>
        </div>
        <div className='flex mb2'>
          <div className='col-6 pr1'>
            <label className='cs-label'>Languages</label>
            <Select
              multi={true}
              disabled={video.is_metadata_submitted}
              options={languages}
              isLoading={!languages}
              value={videoState.languages}
              simpleValue={true}
              onChange={languages => updateVideoState({ ...videoState, languages })}/>
          </div>
          <div className='col-6 pl1'>
            <label className='cs-label'>Subtitles</label>
            <Select
              multi={true}
              disabled={video.is_metadata_submitted}
              options={languages}
              isLoading={!languages}
              value={videoState.subtitles}
              simpleValue={true}
              onChange={subtitles => updateVideoState({ ...videoState, subtitles })}/>
          </div>
        </div>
        <div className='flex mb2'>
          <div className='col-6 pr1'>
            <label className='cs-label' htmlFor='logline'>
              Logline
            </label>
            <textarea
              value={videoState.logline || ""}
              disabled={video.is_metadata_submitted}
              data-testid='video-request-logline-textarea'
              className={`cs-textarea col-12`}
              placeholder={placeholders.logline}
              onChange={e => updateVideoState({ ...videoState, logline: e.target.value })}/>
          </div>
          <div className='col-6 pl1'>
            <label className='cs-label' htmlFor='summary'>
              Short Summary
            </label>
            <textarea
              value={videoState.short_summary || ""}
              disabled={video.is_metadata_submitted}
              data-testid='video-request-summary-textarea'
              className={`cs-textarea col-12`}
              placeholder={placeholders.short_summary}
              onChange={e => updateVideoState({ ...videoState, short_summary: e.target.value })}/>
          </div>
        </div>
        <CustomFields/>
      </>
    </Card>
  )
}

Details.propTypes = {
  getCountries: PropTypes.func.isRequired,
  countries: PropTypes.array,
  languages: PropTypes.array,
  updateVideo: PropTypes.func.isRequired,
  video: PropTypes.object.isRequired,
  genres: PropTypes.string.isRequired,
  getGenres: PropTypes.func.isRequired,
  isLoadingCustomFields: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  countries: state.utils.countries,
  languages: state.ondemand_videos.settings.asset_languages.split(",").map(opt => ({ label: opt, value: opt })),
  video: state.ondemand_videos.video,
  genres: (state.ondemand_videos.genres || []).map(genre => ({ label: genre.label, value: genre._id })),
  isLoadingCustomFields: state.ondemand_videos.loadingCustomFields
})

const mapDispatchToProps = dispatch => ({
  getCountries: bindActionCreators(getCountries, dispatch),
  updateVideo: bindActionCreators(updateVideo, dispatch),
  getGenres: bindActionCreators(getGenres, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Details)
