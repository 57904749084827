import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateProjects, addProject } from "@api/projects/"
import { TOGGLE_ACCESS_MODAL } from "@actions/project/access"
import { Modal } from "@bitcine/cs-theme"

class AddProject extends React.Component {
  create = e => {
    e.preventDefault()
    e.stopPropagation()
    this.props.addProject(project => {
      this.close()
      if (this.props.hasOrganization) {
        this.props.toggleAccessModal(project)
      }
    })
  }
  close = e => {
    this.props.updateProjects("newProject", {
      ...this.props.newProject,
      status: "READY",
      visible: false,
      title: ""
    })
  }
  render() {
    const { newProject } = this.props
    return (
      <Modal
        testId='projects-add-new-modal'
        open
        title='Create a New Project'
        onClose={this.close}
        clickOutsideCloses={!newProject.title.length}>
        <form>
          <div>
            <label className='cs-label' htmlFor='__title'>
              Project Title
            </label>
            <input
              className='cs-input col-12'
              autoFocus
              data-testid='projects-add-new-modal-input'
              id='__title'
              value={newProject.title}
              onChange={({ target }) =>
                this.props.updateProjects("newProject", {
                  ...newProject,
                  title: target.value
                })
              }/>
          </div>
          <div className='flex justify-end mt3'>
            <button
              type='submit'
              data-testid='projects-add-new-modal-btn'
              className={`cs-button ${newProject.status === "PENDING" ? "pending" : ""}`}
              disabled={!newProject.title || newProject.status === "PENDING"}
              onClick={this.create}>
              Create Project
            </button>
          </div>
        </form>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  newProject: state.projects.newProject,
  hasOrganization: state.user.auth.organization_id !== null
})

const mapDispatchToProps = dispatch => ({
  updateProjects: bindActionCreators(updateProjects, dispatch),
  addProject: bindActionCreators(addProject, dispatch),
  toggleAccessModal: project => dispatch({ type: TOGGLE_ACCESS_MODAL, payload: { project } })
})

AddProject.propTypes = {
  newProject: PropTypes.object.isRequired,
  addProject: PropTypes.func.isRequired,
  updateProjects: PropTypes.func.isRequired,
  toggleAccessModal: PropTypes.func.isRequired,
  hasOrganization: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProject)
