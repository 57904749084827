import React from "react"
import PropTypes from "prop-types"
import SharedScreeners from "@components/shared_screeners"
import ReviewerButtons from "../components/reviewer_buttons"

const Reviewers = ({ screenerID }) => (
  <SharedScreeners parentID={screenerID} parentType='screener' headerComponents={<ReviewerButtons/>}/>
)

Reviewers.propTypes = {
  screenerID: PropTypes.string.isRequired
}

export default Reviewers
