import React from "react"
import PropTypes from "prop-types"
import { updateTrailer, updateVideo } from "@api/ondemand_videos/"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Status } from "@bitcine/cs-theme"
import { POST_DEFAULTS } from "@constants"
import { GLOBALS } from "@src/config"
import { addGlobalMessage } from "@api/utils/"

const initialState = {
  importing: false,
  verifying: false,
  link: "",
  filename: "",
  error: false,
  complete: false,
  message: "",
  formLocked: false
}

class CommonImporter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...initialState,
      link_to_confirm: {}
    }
  }
  isFresh() {
    return this.state.importing === false && this.state.verifying === false && this.state.error === false
  }
  isImporting() {
    return this.state.importing && this.state.verifying === false && this.state.error === false
  }
  isPending() {
    return this.state.importing && this.state.verifying && this.state.error === false
  }
  isError() {
    return this.state.error
  }
  isComplete() {
    return this.state.complete
  }
  activateImport() {
    this.setState({ ...this.state, error: false, importing: true })
  }
  importUrl() {
    this.setState({ ...this.state, error: false, formLocked: true })
    // based on the url decide if it's import-dropbox or import-google, then hti the appropriate endpoint
    // include the format_restriction value
    let payload = {}
    if (this.state.link.includes("www.dropbox.com/s/")) {
      payload = {
        url: this.state.link,
        type: "dropbox",
        video_id: this.props.target,
        format_restriction: "videos"
      }
    } else if (this.state.link.includes("drive.google.com/file/d/")) {
      payload = {
        url: this.state.link,
        type: "google_drive",
        video_id: this.props.target,
        format_restriction: "videos"
      }
    } else {
      // invalid url
      this.setState({
        error: true,
        message: "Your link looks to be invalid. Make sure it's a single file.",
        formLocked: false
      })
      return
    }
    // hit the back end, verify the URL, return the actual file name here (or error)
    fetch(`${GLOBALS.API_URL}/import-link`, {
      ...POST_DEFAULTS,
      body: JSON.stringify(payload)
    })
      .then(res => res.json())
      .then(res => {
        if (res.response) {
          this.setState({
            // trigger timeout to poll for pending/queued
            link_to_confirm: res.response,
            importing: true,
            verifying: true,
            filename: res.response.origin_name,
            formLocked: false
          })
        } else {
          this.setState({
            // trigger timeout to poll for pending/queued
            message: res.message,
            importing: true,
            verifying: false,
            error: true,
            formLocked: false
          })
        }
      })
      .catch(error => {
        this.setState({ error: true, message: `An error occurred: ${error}`, formLocked: false })
      })
  }
  doImport() {
    this.setState({ formLocked: true })
    this.props.setImporting()
    // hit the back end, and trigger the real job
    this.confirmlink = `${GLOBALS.API_URL}/confirm-import/${this.state.link_to_confirm._id}` // post
    fetch(this.confirmlink, { ...POST_DEFAULTS })
      .then(res => res.json())
      .then(res => {
        // trigger a video update here so the whole state changes...
        this.setState({ complete: true, importing: false, error: false, message: "", formLocked: false })
        this.props.addGlobalMessage("You have successfully submitted your shared link!", "success")
        this.props.setImportedName(this.state.filename)
      })
      .catch(error => {
        this.setState({ error: true, message: `An error occurred: ${error}`, formLocked: false })
      })
    // or error?
  }
  fullReset() {
    this.setState(initialState)
  }
  updateUrl(url) {
    this.setState({ link: url })
  }
  render() {
    return (
      <>
        {this.isFresh() ? (
          <button
            data-testid='video-import-select-image-btn'
            disabled={this.state.formLocked}
            className={`cs-button col-12 outline small nowrap`}
            onClick={() => {
              this.activateImport()
            }}>
            Import from Google Drive or Dropbox
          </button>
        ) : (
          <div className={`flex flex-column border border-gray-4 p2 round`} style={{ gap: "0.5em" }}>
            <Status pending={this.state.formLocked}>
              {this.isImporting() && (
                <>
                  <p>
                    Paste your Google Drive or Dropbox link here.
                    <br/>
                    Your link must be publicly readable and for a single video file (mp4, mov).
                  </p>
                  <input
                    className={`cs-input col-12 nowrap`}
                    disabled={this.state.formLocked}
                    placeholder={`URL`}
                    onChange={e => this.updateUrl(e.target.value)}
                    type='text'/>
                  <div className={`flex justify-end`}>
                    <button
                      className={`cs-button outline nowrap small mr2`}
                      disabled={this.state.formLocked}
                      onClick={() => {
                        this.fullReset()
                      }}>
                      Cancel Import
                    </button>
                    <button
                      className={`cs-button outline nowrap small`}
                      disabled={this.state.formLocked || !this.state.link}
                      onClick={() => {
                        this.importUrl()
                      }}>
                      Import URL
                    </button>
                  </div>
                </>
              )}
              {this.isPending() && (
                <>
                  <p>
                    Are you sure you would like to submit <b>{this.state.filename}</b>?
                  </p>
                  <div className={`flex justify-end`}>
                    <button
                      className={`cs-button small outline nowrap mr2`}
                      disabled={this.state.formLocked}
                      onClick={() => {
                        this.fullReset()
                      }}>
                      Cancel Import
                    </button>
                    <button
                      className={`cs-button small outline nowrap`}
                      disabled={this.state.formLocked}
                      onClick={() => {
                        this.doImport()
                      }}>
                      Yes, Import!
                    </button>
                  </div>
                </>
              )}
              {this.isError() && (
                <>
                  <p>
                    There was an error with your link: <b>{this.state.message}</b>
                  </p>
                  <div className='flex justify-end'>
                    <button
                      className={`cs-button outline nowrap small`}
                      onClick={() => {
                        this.fullReset()
                        this.activateImport()
                      }}>
                      Try again
                    </button>
                  </div>
                </>
              )}
            </Status>
          </div>
        )}
      </>
    )
  }
}

CommonImporter.propTypes = {
  video: PropTypes.object.isRequired,
  target: PropTypes.string,
  forceType: PropTypes.string,
  setImporting: PropTypes.func,
  setImportedName: PropTypes.func,
  addGlobalMessage: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  video: state.ondemand_videos.video
})

const mapDispatchToProps = dispatch => ({
  updateTrailer: bindActionCreators(updateTrailer, dispatch),
  updateVideo: bindActionCreators(updateVideo, dispatch),
  addGlobalMessage: bindActionCreators(addGlobalMessage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CommonImporter)
