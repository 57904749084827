import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { saveFile } from "@api/project/files/"
import Wrap from "./wrap"

class Description extends React.Component {
  constructor(props) {
    super(props)
    this.state = { desc: props.file.description || "" }
  }
  save(value) {
    if (this.state.desc !== this.props.file.description) {
      this.props.saveFile(this.props.file._id, this.props.fileType, "description", value)
    }
  }
  render() {
    return (
      <Wrap title='Description' defaultOpen={false}>
        <div className='pb2'>
          <label className='cs-label' htmlFor='description'>
            File Description
          </label>
          <textarea
            autoFocus
            placeholder='Enter a description about this file'
            value={this.state.desc}
            className='cs-textarea col-12'
            onChange={e => this.setState({ desc: e.target.value })}
            onBlur={e => this.save(e.target.value)}/>
        </div>
      </Wrap>
    )
  }
}

Description.propTypes = {
  file: PropTypes.object.isRequired,
  saveFile: PropTypes.func.isRequired,
  fileType: PropTypes.string.isRequired
}

const mapDispatchToProps = dispatch => ({
  saveFile: bindActionCreators(saveFile, dispatch)
})

export default connect(
  null,
  mapDispatchToProps
)(Description)
