import {
  GET_SHIPPING_LABELS_PENDING,
  GET_SHIPPING_LABELS_FAILED,
  GET_SHIPPING_LABELS_FULFILLED
} from "@actions/account/shipping_labels"

const initialState = {
  status: "PENDING",
  list: []
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SHIPPING_LABELS_PENDING:
      return {
        ...state,
        status: state.status === "READY" ? "UPDATING" : "PENDING"
      }

    case GET_SHIPPING_LABELS_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case GET_SHIPPING_LABELS_FULFILLED:
      return {
        ...state,
        status: "READY",
        list: action.payload
      }

    default:
      return state
  }
}
