import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import humanFileSize from "@src/helpers/humanFileSize"
import { initializeAsperaUpload } from "@api/transfer/aspera/upload"
import AsperaLogo from "@src/public/aspera.png"

class IncompleteUploads extends React.Component {
  continueUpload(upload) {
    const isDCP = upload.file && upload.file.type === "dcp"
    let specs = {
      upload_id: upload._id,
      is_dcp: isDCP
    }
    this.props.initializeAsperaUpload(specs)
  }
  render() {
    const { incompleteUploads } = this.props
    return incompleteUploads.length > 0 ? (
      <div className='mb3 max-width-3 mx-auto'>
        <strong className='block muted mb2'>Incomplete Uploads</strong>
        {incompleteUploads.map(upload => (
          <div key={upload._id} className='p2 bg-white rounded box-shadow flex items-center mb2'>
            <div className='flex-auto'>
              <strong className='truncate block'>{upload.file_name}</strong>
              <small className='muted'>
                <span className='flex items-center'>
                  {`${humanFileSize(upload.current_size)} ${
                    upload.total_size ? "of " + humanFileSize(upload.total_size) : ""
                  } uploaded`}
                </span>
              </small>
            </div>
            <button
              onClick={() => {
                this.continueUpload(upload)
                window.scroll(0, 0)
              }}
              className='cs-button white small flex items-center'>
              <img src={AsperaLogo} className='cs-img mr1' style={{ width: "16px" }}/>
              Continue Upload
            </button>
          </div>
        ))}
      </div>
    ) : null
  }
}

IncompleteUploads.propTypes = {
  incompleteUploads: PropTypes.array,
  initializeAsperaUpload: PropTypes.func.isRequired
}

const mapStateToProps = ({ file_request, transfers }) => {
  let activeUploadIDs = Object.keys(transfers).map(index => transfers[index].upload_id)
  return {
    incompleteUploads: file_request.uploads.filter(upload => {
      let uploadMethod = upload.upload_method || "HTTP"
      let transferring = activeUploadIDs.includes(upload._id)
      return upload.status !== "Complete" && uploadMethod.toLowerCase() === "aspera" && !transferring
    })
  }
}

const mapDispatchToProps = dispatch => ({
  initializeAsperaUpload: bindActionCreators(initializeAsperaUpload, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IncompleteUploads)
