import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateNewReviewers } from "@api/preview/send"
import { Checkbox } from "@bitcine/cs-theme"

const ViewRestrictions = ({ viewCount, updateNewReviewers, stateKey }) => {
  const [viewCountVisible, toggleViewCountVisible] = useState(false)
  useEffect(() => {
    if (!viewCountVisible) {
      updateNewReviewers(stateKey, "view_count_limit", null)
    }
  }, [viewCountVisible])
  return (
    <div className='py2 border-bottom border-gray-5'>
      <div className='flex items-center'>
        <div className='col-8 flex items-center'>
          <Checkbox checked={viewCountVisible} onChange={e => toggleViewCountVisible(!viewCountVisible)}/>
          <div className='pl1'>
            <h4>Enable View Count Limit</h4>
            <p className='mb0'>
              This will expire the link after X number of views. A session with at least 70% of the video watched is
              considered a view.
            </p>
          </div>
        </div>
        <div className='pl2 col-4'>
          {viewCountVisible && (
            <input
              id='viewCount'
              value={viewCount}
              type='number'
              autoFocus
              className='cs-input col-12 block'
              placeholder='Enter a number'
              onChange={e => updateNewReviewers(stateKey, "view_count_limit", e.target.value)}/>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  viewCount: state.preview.send[ownProps.stateKey].view_count_limit
})

const mapDispatchToProps = dispatch => ({
  updateNewReviewers: bindActionCreators(updateNewReviewers, dispatch)
})

ViewRestrictions.propTypes = {
  newReviewers: PropTypes.object.isRequired,
  updateNewReviewers: PropTypes.func.isRequired,
  viewCount: PropTypes.number,
  stateKey: PropTypes.oneOf(["email", "link"]).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewRestrictions)
