import {
  GET_CART_COUNT,
  GET_CART,
  UPDATE_CART_ITEM,
  DELETE_CART_ITEM,
  UPDATE_CART,
  TOGGLE_GIFT_CARD,
  APPLY_PROMO,
  PURCHASE_CART,
  GET_SHIPPING_RATES,
  SET_SHIPPING_RATE
} from "@actions/account/cart"

import { UPDATE_GIFT_CARD } from "@actions/account/billing"

import { push } from "connected-react-router"

import { get, del, post } from "@api/fetch"

import Tracking from "@helpers/tracking"

/**
 * Get cart count
 * - retrieves number of items in a user account
 * @returns dispatch
 */

export const getCartCount = () => dispatch => dispatch(get(GET_CART_COUNT, "billing/cart/count"))

/**
 * Get cart
 * - retrieves the cart of the user
 * @returns dispatch
 */

export const getCart = () => dispatch => dispatch(get(GET_CART, "billing/cart"))

/**
 * Delete cart items
 * - remove an order from the users cart
 * @returns dispatch
 */

export const removeCartItem = orderID => dispatch => {
  dispatch({ type: UPDATE_CART_ITEM, orderID, key: "deleting", value: true })
  dispatch(del(DELETE_CART_ITEM, `billing/cart/${orderID}`))
  dispatch(getCartCount())
}

/**
 * Update Cart
 *
 * @returns dispatch
 */

export const updateCart = (key, value) => dispatch => {
  dispatch({ type: UPDATE_CART, key, value })
}

/**
 * Toggle gift card on / off
 *
 * @returns dispatch
 */

export const toggleGiftCard = cardID => dispatch => {
  dispatch({ type: UPDATE_GIFT_CARD, cardID, key: "applying", value: true })
  dispatch(
    post(TOGGLE_GIFT_CARD, "settings/giftcards/toggle", { cardID }, () =>
      dispatch({
        type: UPDATE_GIFT_CARD,
        cardID,
        key: "applying",
        value: false
      })
    )
  )
}

/**
 * Apply a promo to the cart
 *
 * @returns dispatch
 */

export const applyPromo = () => (dispatch, getState) => {
  const promoCode = getState().user.cart.promo.value
  dispatch(post(APPLY_PROMO, "users/applyPromoCode", { promoCode }))
}

/**
 * Apply a promo by ID when the cart load pages
 *
 * @returns dispatch
 */

export const applyPromoByID = () => (dispatch, getState) => {
  const promoID = getState().user.auth.active_promo_id
  dispatch(post(APPLY_PROMO, "items/applyPromoCodeByID", { promoID }))
}

/**
 * Next step in the cart
 *
 * @returns dispatch
 */

export const nextStep = currentStep => (dispatch, getState) => {
  const cart = getState().user.cart
  const postPay = getState().user.billing.paymentMethods.list.using_post_pay === true
  switch (currentStep) {
    case "/cart/shipping":
      const shipment = getState().user.cart.shipment
      const willPickup = getState().user.cart.willPickup
      const requiresShipping = getState().user.cart.orders.filter(order => order.includes_shipment).length > 0
      if (!willPickup && requiresShipping && (!shipment.selected_rate || !shipment.destination_address)) {
        dispatch({
          type: UPDATE_CART,
          key: "addressError",
          value: true
        })
      } else {
        dispatch(push("/cart/payment"))
        dispatch({
          type: UPDATE_CART,
          key: "addressError",
          value: false
        })
      }
      break
    case "/cart/payment":
      if (!cart.credit_card_id && parseFloat(cart.payment_total) !== 0 && postPay === false) {
        dispatch({ type: UPDATE_CART, key: "error", value: "CREDIT_CARD" })
        window.scrollTo(0, 0)
      } else {
        dispatch(push("/cart/confirmation"))
        dispatch({ type: UPDATE_CART, key: "error", value: "" })
      }
      break
    case "/cart/confirmation":
      dispatch(purchaseCart())
      break
  }
}

/**
 * Purchase Cart
 *
 * @returns dispatch
 */

export const purchaseCart = () => (dispatch, getState) => {
  const customerCardID = getState().user.cart.credit_card_id || ""
  const willPickup = getState().user.cart.willPickup
  dispatch(
    post(PURCHASE_CART, "billing/cart", { customerCardID, willPickup }, r => {
      const shippingCost = r.new_invoice.shipping_total ? r.new_invoice.shipping_total : 0

      Tracking.record("Cart checkout complete", {
        "Cart size": r.orders.length,
        "Cart value": r.new_invoice.amount,
        "Payment method": r.new_invoice.payment_sources[0].type,
        "Cart items": r.orders.map(order => order.title),
        "Total charge": r.new_invoice.subtotal + shippingCost + r.new_invoice.tax_total,
        "Shipping cost": shippingCost
      })
    })
  )
}

/**
 * Get shipping rates for cart
 *
 * @returns dispatch
 */

export const getShippingRate = () => (dispatch, getState) => {
  const a = getState().user.cart.newAddress
  const shipmentID = getState().user.cart.shipment._id
  const params = `?shipmentID=${shipmentID}&address_line1=${a.address_line1}&address_line2=${a.address_line2}&city=${
    a.city
  }&postal_code=${a.postal_code}&state_or_province_code=${a.state_or_province_code}&country_code=${
    a.country_code
  }&phone_number=${a.mobile_number}&organization=${a.organization}` // eslint-disable-line max-len
  dispatch(get(GET_SHIPPING_RATES, `billing/cart/rates${params}`))
}

/**
 * Set shipping rates for cart
 *  - Note: this will eventually handle multiple shipments at once
 *
 * @returns dispatch
 */

export const setShippingRate = () => (dispatch, getState) => {
  const rate = getState().user.cart.newAddress.selected_rate
  const shipmentID = getState().user.cart.shipment._id
  dispatch(post(SET_SHIPPING_RATE, `billing/cart/rates`, { shipmentID, rate }))
}
