import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { push, goBack } from "connected-react-router"
import Dropzone from "react-dropzone"
import { initializeHttpUpload } from "@api/transfer/http/upload"
import Title from "../title"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { upload_status: "IDLE" }
  }
  render() {
    const { screener, file, goBack, initializeHttpUpload, encodeStatus } = this.props
    const isValid = (encodeStatus === "Published" || encodeStatus === "Ready")
    return (
      <div
        id='header'
        style={{ boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)", zIndex: 5 }}
        className='fixed top-0 left-0 right-0 bg-white bg-white'>
        <div style={{ height: "4rem" }} className='flex items-center py1 px3'>
          <div className='flex-auto'>
            <Title screener={screener} file={file}/>
          </div>
          {this.state.upload_status === "COMPLETE" && (
            <small className='flex items-center mr2'>
              <span className='material-icons green mr1'>check_circle</span>
              Thumbnail updated
            </small>
          )}
          {isValid && (
            <Dropzone
              multiple={false}
              className='dropzone'
              accept='image/*'
              onDrop={(accepted, rejected) => {
                this.setState({ upload_status: "UPLOADING" })
                initializeHttpUpload(
                  accepted[0],
                  {
                    screener_id: screener._id,
                    project_id: screener.project_id
                  },
                  result => {
                    const reader = new FileReader()
                    reader.readAsDataURL(accepted[0])
                    reader.onload = () => {
                      // this.bitmovinPlayer.setPosterImage(reader.result)
                    }
                    this.setState({ upload_status: "COMPLETE" })
                    setTimeout(() => {
                      this.setState({ upload_status: "IDLE" })
                    }, 4000)
                  }
                )
              }}>
              <button
                disabled={this.state.upload_status === "UPLOADING"}
                style={{ width: "180px" }}
                className={`cs-button small white mr2 ${this.state.upload_status === "UPLOADING" ? "pending" : ""}`}>
                {screener.thumbnail_url ? "Change " : "Upload "}
                Thumbnail
              </button>
            </Dropzone>
          )}
          <button onClick={goBack} data-tooltip={"Close Preview"} className='cs-button link material-icons tooltip'>
            close
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, context) => {
  const screener = state.project.files.screener
  const sourceFile = screener.source_file_id
  return {
    screener: screener,
    file: state.project.files.items.list.find(file => file._id === sourceFile) || {},
    encodeStatus: screener.encoding_status
  }
}

const mapDispatchToProps = dispatch => ({
  initializeHttpUpload: bindActionCreators(initializeHttpUpload, dispatch),
  push: bindActionCreators(push, dispatch),
  goBack: bindActionCreators(goBack, dispatch)
})

Header.propTypes = {
  params: PropTypes.object.isRequired,
  screener: PropTypes.object,
  router: PropTypes.object,
  file: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  initializeHttpUpload: PropTypes.func.isRequired,
  encodeStatus: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
