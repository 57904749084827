const tourConfig = {
  new_project: {
    alignment: "right",
    header: "Create your first project",
    content: "Projects let you group and organize files for a specific film within a fixed container."
  },

  upload_files: {
    alignment: "right",
    header: "Upload files",
    content: `Next, upload your first file. CineSend is format agnostic, 
							so you can upload just about anything. Common formats such as MOVs 
							and documents can be previewed within your account.`
  },

  project_members: {
    alignment: "right",
    header: "Add team members to your project",
    content: `Add or invite team members to your project. You can edit permissions for team 
							members through role assignment in your settings.`
  },

  file_row: {
    alignment: "right",
    header: "File options",
    content: `Access your file options to share, download, create a 
							secure screener, order a DCP, and much more!`,
    offset: "-5px -9px"
  },

  cart: {
    alignment: "right",
    header: "Checkout",
    content: `View your cart and complete payment to ensure that your orders are started right away.`,
    offset: "-12px -11px"
  },

  screeners_tab_analytics: {
    alignment: "left",
    header: "Screener analytics dashboard",
    content: `The screener analytics dashboard gives you a quick overview of 
							your reviewers' overall impressions, plays, engagement and more!`,
    offset: "-15px -40px"
  },

  // Left blank on purpose
  screeners_tab_reviewers: {
    alignment: "left",
    header: "",
    content: ``
  },

  send_screener_btn: {
    alignment: "right",
    header: "Send screener to reviewers",
    content: `Invite reviewers to watch this screener. Enable security measures to
							eliminate piracy and track engagement through insights and analytics. `
  }
}

export default tourConfig
