import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { ButtonDropdown } from "@bitcine/cs-theme"
import { showGlobalAuthModal } from "@api/account/auth"
import { downloadFileWithHTTP } from "@api/transfer/download"
import { addAsperaDownloadLinkTransfer } from "@api/download_link/aspera_downloads"
import { toggleImportModal } from "@api/download_link/"
import AsperaWhite from "@src/public/aspera_white.png"
import Aspera from "@src/public/aspera.png"
import 'styled-components/macro'

class DropdownActions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: "READY"
    }
  }
  onClick = () => {
    this.setState({ status: "DISABLED" }, () => {
      setTimeout(() => {
        this.setState({ status: "READY" })
      }, 2000)
    })
  }
  onHTTPDownload() {
    this.props.downloadFileWithHTTP(this.props.file, this.props.download._id)
    this.onClick()
  }
  onAsperaDownload() {
    this.props.addAsperaDownloadLinkTransfer(this.props.download._id, this.props.file)
    this.onClick()
  }
  onToggleImportModal() {
    if (this.props.authenticated) {
      this.props.toggleImportModal(this.props.file)
    } else {
      this.props.showGlobalAuthModal(() => {
        this.props.toggleImportModal(this.props.file)
      }, true)
    }
    this.onClick()
  }
  render() {
    const { download, file } = this.props
    if (download.method === "http" && file.type === "folder") return null
    let style = download.method === "aspera" ? { background: "#00B2EF", color: "white", border: "none" } : null
    return (
      <ButtonDropdown
        button={{
          style: style,
          className: "white small align-right",
          text: (
            <div className='flex items-center'>
              {download.method === "aspera" && (
                <img src={AsperaWhite} className='cs-img mr1' style={{ width: "16px" }}/>
              )}
              Download {file.type === "folder" ? "Folder" : "File"}
            </div>
          ),
          disabled: this.state.status === "DISABLED"
        }}
        dropdown={{
          clickCloses: true,
          content: [
            {
              text: "Download",
              icon: "cloud_download",
              onClick: () => this.onHTTPDownload(),
              show: download.method === "http"
            },
            {
              text: "Download with Aspera",
              icon: (
                <img
                  className='cs-img'
                  src={Aspera}
                  css={`
                    width: 20px;
                    height: 20px;
                    filter: grayscale(100%);
                  `}/>
              ),
              onClick: () => this.onAsperaDownload(),
              show: download.method === "aspera"
            },
            {
              text: "Save to my CineSend Account",
              icon: "file_copy",
              onClick: () => this.onToggleImportModal(),
              show: true
            }
          ].filter(opt => opt.show)
        }}/>
    )
  }
}

DropdownActions.propTypes = {
  file: PropTypes.object.isRequired,
  download: PropTypes.object.isRequired,
  authenticated: PropTypes.bool.isRequired,
  showGlobalAuthModal: PropTypes.func.isRequired,
  downloadFileWithHTTP: PropTypes.func.isRequired,
  toggleImportModal: PropTypes.func.isRequired,
  addAsperaDownloadLinkTransfer: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  showGlobalAuthModal: bindActionCreators(showGlobalAuthModal, dispatch),
  toggleImportModal: bindActionCreators(toggleImportModal, dispatch),
  downloadFileWithHTTP: bindActionCreators(downloadFileWithHTTP, dispatch),
  addAsperaDownloadLinkTransfer: bindActionCreators(addAsperaDownloadLinkTransfer, dispatch)
})

const mapStateToProps = state => ({
  authenticated: state.user.auth.status === "AUTHENTICATED",
  download: state.download_link
})

export default connect(mapStateToProps, mapDispatchToProps)(DropdownActions)
