import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Snackbar } from "@bitcine/cs-theme"

const Messages = ({ messages }) => <Snackbar items={messages} alignment='right'/>

Messages.propTypes = {
  messages: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  messages: state.messages
})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Messages)
