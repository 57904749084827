import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { prevStep, nextStep, updateOrder } from "@api/project/orders"
import { Status, Radio } from "@bitcine/cs-theme"
import "../styles.scss"

const args = [
  {
    name: "Quality Control Report",
    key: "qc",
    description: "A quality control report will include a full inspection of the specified file."
  },
  {
    name: "Quality Assurance Report",
    key: "qa",
    description: "A quality assurance report is a spot-check inspection of the specified file."
  }
]

const SelectInspectionType = ({ prevStep, nextStep, items, order, updateOrder, file, extension }) => (
  <div className='col-12'>
    <Status pending={Object.keys(items).length === 0}>
      {Object.keys(items).length > 0 && (
        <div className={"orderInnerContainer"}>
          <p>Select which type of quality inspection you would like to purchase.</p>
          {args.map((arg, i) => (
            <div key={arg.key} className={`flex items-center py2 ${i > 0 ? "border-top border-gray-4" : ""}`}>
              <Radio
                id={arg.key}
                checked={order.inspection_type === arg.key}
                onChange={() => updateOrder("inspection_type", arg.key)}/>
              <label htmlFor={arg.key} className='cs-label pl2 flex-auto'>
                <strong>{arg.name}</strong>
                <span className='block small'>{arg.description}</span>
              </label>
              <strong>
                {items[arg.key + "_" + extension + "_base"].currency_symbol}
                {(
                  items[arg.key + "_" + extension + "_base"].price +
                  items[arg.key + "_" + extension + "_per_minute"].price * parseFloat(file.length_in_minutes)
                ).toFixed(2)}
              </strong>
            </div>
          ))}
        </div>
      )}
    </Status>
    <div className='flex justify-between pt3 col-12'>
      <button className='cs-button white' onClick={prevStep}>
        Back
      </button>
      <button className='cs-button' disabled={!order.inspection_type} onClick={nextStep}>
        Add to Cart
      </button>
    </div>
  </div>
)

SelectInspectionType.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  items: PropTypes.object,
  order: PropTypes.object.isRequired,
  updateOrder: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  extension: PropTypes.string.isRequired
}

const mapStateToProps = state => {
  const order = state.orders.active_order
  const file = order.video_file
  const extension = file.type === "dcp" ? "dcp" : file.file_name.substring(file.file_name.lastIndexOf(".") + 1)
  return {
    items: state.utils.inspection_items,
    order,
    file,
    extension
  }
}

const mapDispatchToProps = dispatch => ({
  prevStep: bindActionCreators(prevStep, dispatch),
  nextStep: bindActionCreators(nextStep, dispatch),
  updateOrder: bindActionCreators(updateOrder, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectInspectionType)
