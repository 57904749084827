import React from "react"
import PropTypes from "prop-types"
import "./styles.scss"

const Password = ({ active }) => (
  <div
    className={`icon password
        ${active ? "activepassword" : ""}
        relative circle flex items-center justify-center box-shadow`}>
    <span className='material-icons white' style={{ fontSize: "14px" }}>
      lock
    </span>
  </div>
)

Password.propTypes = {
  active: PropTypes.bool
}

export default Password
