import React from "react"
import PropTypes from "prop-types"
import shouldPureComponentUpdate from "@src/helpers/shouldPureComponentUpdate"

const styles = {
  display: "inline-block",
  transform: "rotate(-7deg)",
  WebkitTransform: "rotate(-7deg)"
}

class ActiveRow extends React.Component {
  constructor(props) {
    super(props)
  }
  shouldComponentUpdate = shouldPureComponentUpdate
  render() {
    const { name } = this.props
    return (
      <div className='bg-white rounded box-shadow px3 py2' style={styles}>
        <div style={{ maxWidth: "280px" }} className='truncate'>
          {name}
        </div>
      </div>
    )
  }
}

ActiveRow.propTypes = {
  name: PropTypes.string.isRequired
}

export default ActiveRow
