import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateUtils } from "@api/utils/"
import Dropzone from "react-dropzone"
import { initializeHttpUpload } from "@api/transfer/http/upload"
import { initFolderUpload } from "@api/project/folder_upload"
import { addGlobalMessage } from "@api/utils"
import { detect } from "detect-browser"
import sortFiles from "@helpers/file_sorter"
import { style } from "./constants"
import "./styles.scss"
import SnackBar from "./snackbar"
import recursiveBuilder from "./builder"

class FileDND extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      style,
      active: false,
      active_folder_id: null
    }
    this.dragging = false
    this.mouseY = 0
    this.mouseX = 0
    this.drag = this.drag.bind(this)
    this.killDrag = this.killDrag.bind(this)
    this.onDrop = this.onDrop.bind(this)
  }
  onDrop(files) {
    this.killDrag()

    sortFiles(files)
      .filter(file => {
        return file.type !== "application/octet-stream"
      })
      .forEach(file => {
        if (file.__entry.isFile) {
          this.handleUpload("file", file)
        } else if (file.__entry.isDirectory) {
          const browser = detect()

          if (browser && ["chrome", "firefox"].includes(browser.name)) {
            this.props.addGlobalMessage("Parsing folder, please wait.", "info", null, 2500)

            const built = recursiveBuilder(file.__entry)

            setTimeout(() => {
              this.handleUpload("folder", built)
            }, 2500)
          } else {
            this.props.addGlobalMessage(
              "Drag and dropping folders is only supported by the Google Chrome and Firefox browsers",
              "error",
              "error"
            )
          }
        }
      })
  }
  handleUpload = (type, payload) => {
    if (type === "file") {
      this.props.initializeHttpUpload(payload, {
        project_id: this.props.projectID,
        folder_id: this.getFolderID()
      })
    } else if (type === "folder") {
      this.props.initFolderUpload(
        payload,
        this.props.projectID,
        this.getFolderID()
      )
    }
  }
  onDragLeave = e => {
    this.killDrag()
  }
  onDragEnter = e => {
    if (!this.dragging) {
      this.dragging = true
      this.interval = setInterval(this.drag, 250)
      this.props.updateUtils("active_dragging_type", "NATIVE_FILE")
      this.setState({ active: true })
    }
  }
  onDragOver = ({ clientX, clientY }) => {
    this.mouseX = clientX
    this.mouseY = clientY
  }
  killDrag() {
    clearInterval(this.interval)
    this.dragging = false
    this.props.updateUtils("active_dragging_type", null)
    this.setState({ active: false })
  }
  drag() {
    let has_active_folder = false
    document.querySelectorAll("[data-file-droppable=true]").forEach(element => {
      const { top, left, height, width } = element.getBoundingClientRect()
      if (this.mouseX > left && this.mouseX < left + width && this.mouseY > top && this.mouseY < top + height) {
        this.setState({
          style: {
            position: "fixed",
            top,
            left,
            width,
            height
          },
          active_folder_id: element.getAttribute("data-file-id")
        })
        has_active_folder = true
      }
    })
    if (!has_active_folder) {
      this.setState({ style, active_folder_id: null })
    }
  }
  getDataTransferItems = event => {
    const files = []

    if (event.dataTransfer) {
      const dt = event.dataTransfer

      if (dt.files && dt.files.length && dt.items && dt.items.length) {
        for (var i = 0, item; (item = dt.items[i]); ++i) {
          const fileCopy = dt.files[i]
          fileCopy.__kind = item.kind
          fileCopy.__entry = item.webkitGetAsEntry()
          files.push(fileCopy)
        }
      }
    }

    return files
  }
  getFolderID() {
    return this.state.active_folder_id || this.props.folderID || null
  }
  render() {
    return (
      <Dropzone
        disableClick={true}
        onDrop={this.onDrop}
        disabled={this.props.disabled || this.props.isOverLimit}
        onDragEnter={this.onDragEnter}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        getDataTransferItems={this.getDataTransferItems}
        style={{ minHeight: "calc(100vh - 250px)" }}
        className='relative'>
        {this.state.active && (
          <div
            style={this.state.style}
            className={`rounded border border-dashed border-red border-width-2 active`}/>
        )}
        <SnackBar open={this.state.active} folderID={this.getFolderID()}/>
        {this.props.children}
      </Dropzone>
    )
  }
}

FileDND.propTypes = {
  children: PropTypes.object.isRequired,
  initializeHttpUpload: PropTypes.func.isRequired,
  projectID: PropTypes.string.isRequired,
  folderID: PropTypes.string,
  updateUtils: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  initFolderUpload: PropTypes.func.isRequired,
  addGlobalMessage: PropTypes.func.isRequired,
  isOverLimit: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  // folderID: state.routerParams.location ? state.routerParams.location.query.folderID : null,
  // projectID: state.routerParams.params.projectID,
  disabled: state.utils.active_dragging_type === "FILE",
  active: state.utils.active_dragging_type === "NATIVE_FILE",
  isOverLimit: state.user.auth.subscription.storage.is_over_storage_limit
})

const mapDispatchToProps = dispatch => ({
  initializeHttpUpload: bindActionCreators(initializeHttpUpload, dispatch),
  initFolderUpload: bindActionCreators(initFolderUpload, dispatch),
  updateUtils: bindActionCreators(updateUtils, dispatch),
  addGlobalMessage: bindActionCreators(addGlobalMessage, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileDND)
