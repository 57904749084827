import {
  GET_VIDEO,
  GET_LANGUAGES,
  UPDATE_VIDEO,
  GET_GENRES,
  GET_CUSTOM_FIELDS,
  SET_CUSTOM_FIELD_VALUE,
  INIT_TRAILER,
  INIT_DCP_REQUEST,
  UPDATE_TRAILER,
  GET_SOURCE_FILES,
  SUBMIT_SOURCE_FILE,
  GET_TIMED_TEXTS,
  DELETE_TIMED_TEXT,
  UPLOAD_TIMED_TEXT
} from "@actions/ondemand_videos/"
import update from "immutability-helper"
import { UPDATE_TIMED_TEXT } from "../../actions/ondemand_videos"

const initialState = {
  status: "PENDING",
  video: {},
  genres: "",
  loadingCustomFields: true,
  availableCustomFields: [],
  customFieldValues: [],
  trailerIsInitializing: false,
  dcpIsInitializing: false,
  sourceFiles: {
    status: "",
    list: []
  },
  settings: {
    asset_languages: []
  },
  realLanguages: [],
  timedTexts: []
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_VIDEO.PENDING:
      return {
        ...state,
        status: "PENDING"
      }
    case UPDATE_VIDEO.FAILED:
    case GET_VIDEO.FAILED:
      return {
        ...state,
        status: "ERROR"
      }
    case GET_LANGUAGES.COMPLETE:
      return {
        ...state,
        realLanguages: action.payload
      }
    case GET_VIDEO.COMPLETE:
    case UPDATE_TRAILER.COMPLETE:
    case UPDATE_VIDEO.COMPLETE:
      return {
        ...state,
        status: "READY",
        video: action.payload.video,
        logoUrl: action.payload.organization_logo_url,
        organizationName: action.payload.organization_name,
        uploadLimitInGB: action.payload.upload_limit_in_gb,
        videoRequestText: action.payload.video_request_text,
        settings: action.payload.settings
      }
    case GET_GENRES.COMPLETE:
      return {
        ...state,
        genres: action.payload.genres
      }
    case GET_CUSTOM_FIELDS.PENDING:
      return {
        ...state,
        loadingCustomFields: true,
        availableCustomFields: [],
        customFieldValues: []
      }
    case GET_CUSTOM_FIELDS.COMPLETE:
      return {
        ...state,
        availableCustomFields: action.payload.available_custom_fields,
        customFieldValues: action.payload.custom_field_values,
        loadingCustomFields: false
      }
    case SET_CUSTOM_FIELD_VALUE:
      const index = state.customFieldValues.findIndex(v => {
        return v.fieldID === action.payload.fieldID
      })
      if (index === -1) {
        return {
          ...state,
          customFieldValues: [
            ...state.customFieldValues,
            {
              fieldID: action.payload.fieldID,
              value: action.payload.value
            }
          ]
        }
      } else {
        return update(state, {
          customFieldValues: {
            [index]: {
              value: {
                $set: action.payload.value
              }
            }
          }
        })
      }
    case INIT_TRAILER.PENDING:
      return {
        ...state,
        trailerIsInitializing: true
      }
    case INIT_TRAILER.COMPLETE:
      return {
        ...state,
        trailerIsInitializing: false,
        video: action.payload.video,
        logoUrl: action.payload.organization_logo_url,
        organizationName: action.payload.organization_name,
        uploadLimitInGB: action.payload.upload_limit_in_gb
      }
    case INIT_DCP_REQUEST.PENDING:
      return {
        ...state,
        dcpIsInitializing: true
      }
    case INIT_DCP_REQUEST.COMPLETE:
      return {
        ...state,
        dcpIsInitializing: false,
        video: action.payload.video,
        logoUrl: action.payload.organization_logo_url,
        organizationName: action.payload.organization_name,
        uploadLimitInGB: action.payload.upload_limit_in_gb
      }
    case GET_SOURCE_FILES.PENDING:
      return {
        ...state,
        sourceFiles: {
          status: "PENDING",
          list: []
        }
      }
    case GET_SOURCE_FILES.COMPLETE:
      return {
        ...state,
        sourceFiles: {
          status: "READY",
          list: action.payload
        }
      }
    case SUBMIT_SOURCE_FILE.COMPLETE:
      return {
        ...state,
        video: action.payload
      }
    case GET_TIMED_TEXTS.COMPLETE:
    case UPLOAD_TIMED_TEXT.COMPLETE:
    case DELETE_TIMED_TEXT.COMPLETE:
      return {
        ...state,
        timedTexts: action.payload.timedTexts
      }
    case UPDATE_TIMED_TEXT.COMPLETE:
      return {
        ...state,
        timedTexts: state.timedTexts.map(timedText =>
          timedText._id === action.payload.timedText._id ? action.payload.timedText : timedText
        )
      }
    default:
      return state
  }
}
