import { GET_PACKAGES, UPDATE_PACKAGE, TOGGLE_UPDATE_MODAL } from "@actions/project/packages"

const initialState = {
  status: "PENDING",
  list: [],
  size: 0,
  query: {
    fileID: null
  },
  modal: {
    visible: false,
    type: null,
    selectedPackage: null,
    status: "READY"
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PACKAGES.PENDING:
      return {
        ...state,
        status: "PENDING"
      }
    case GET_PACKAGES.FAILED:
      return {
        ...state,
        status: "ERROR"
      }
    case GET_PACKAGES.COMPLETE:
      return {
        ...state,
        status: "READY",
        list: action.payload.list,
        size: action.payload.size
      }
    case TOGGLE_UPDATE_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          visible: !state.modal.visible,
          type: action.modal,
          selectedPackage: action.selectedPackage
        }
      }
    case UPDATE_PACKAGE.PENDING:
      return {
        ...state,
        modal: {
          ...state.modal,
          status: "PENDING"
        }
      }
    case UPDATE_PACKAGE.FAILED:
      return {
        ...state,
        modal: {
          ...state.modal,
          status: "ERROR"
        }
      }
    case UPDATE_PACKAGE.COMPLETE:
      return {
        ...state,
        modal: {
          ...state.modal,
          visible: null,
          selectedPackage: null,
          type: null,
          status: "READY"
        },
        list: state.list.map(p => (p._id === action.payload._id ? action.payload : p))
      }
    default:
      return state
  }
}
