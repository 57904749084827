import {
  AUTH_CHECK_PENDING,
  AUTH_CHECK_FAILED,
  AUTH_CHECK_FULFILLED,
  LOG_OUT_PENDING,
  UPDATE_AUTH_USER,
  SAVE_USER_FULFILLED,
  SET_AUTH_USER
} from "@actions/account/auth"

import { PURCHASE_CART_FULFILLED } from "@actions/account/cart"

import { PURCHASE_REVIEWERS_FULFILLED } from "@actions/account/reviewers"

import { VALIDATE_MFA_CODE_FULFILLED, DISABLE_MFA_FULFILLED } from "@actions/account/mfa"

import { LOGIN_FULFILLED, SIGNUP_FULFILLED } from "@actions/account/"

import { CHANGE_PLAN_FULFILLED } from "@actions/account/plan"

import { ADD_CREDIT_CARD_FULFILLED, RETRY_PENDING_PAYMENT_FULFILLED } from "@actions/account/billing"
import { ACTIVATE_APPLICATION_FULFILLED } from "@actions/organizations/"
import { CREATE_SHARED_SCREENERS } from "@actions/shared_screeners"

const initialState = {
  status: "PENDING"
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case AUTH_CHECK_PENDING:
      return state

    case AUTH_CHECK_FAILED:
      return {
        ...state,
        status: "GUEST"
      }

    case PURCHASE_CART_FULFILLED:
      return {
        ...state,
        active_promo_id: null
      }

    case CHANGE_PLAN_FULFILLED:
      return parseAuthenticatedUser(action.payload.user)

    case SET_AUTH_USER:
      return parseAuthenticatedUser(action.user)

    case AUTH_CHECK_FULFILLED:
      return parseAuthenticatedUser(action.payload.user)

    case VALIDATE_MFA_CODE_FULFILLED:
      return {
        status: "AUTHENTICATED",
        ...action.payload.user
      }

    case DISABLE_MFA_FULFILLED:
      return {
        status: "AUTHENTICATED",
        ...action.payload
      }

    case LOGIN_FULFILLED:
      return parseAuthenticatedUser(action.payload.user)

    case SIGNUP_FULFILLED:
      return parseAuthenticatedUser(action.payload)

    case LOG_OUT_PENDING:
      return {
        status: "GUEST"
      }

    case UPDATE_AUTH_USER:
      return {
        ...state,
        [action.key]: action.value
      }

    case ACTIVATE_APPLICATION_FULFILLED:
    case SAVE_USER_FULFILLED:
      return {
        ...state,
        ...parseAuthenticatedUser(action.payload)
      }

    case PURCHASE_REVIEWERS_FULFILLED:
    case CREATE_SHARED_SCREENERS.COMPLETE:
      return {
        ...state,
        ...parseAuthenticatedUser(action.payload.user)
      }

    case ADD_CREDIT_CARD_FULFILLED:
      return {
        ...state,
        default_credit_card_id: state.default_credit_card_id
          ? state.default_credit_card_id
          : action.payload.new_credit_card_id
      }

    case RETRY_PENDING_PAYMENT_FULFILLED:
      return parseAuthenticatedUser(action.payload.user)

    default:
      return state
  }
}

const parseAuthenticatedUser = user => {
  const isOnFreePlan = user.subscription.type === "free"
  return {
    status: "AUTHENTICATED",
    ...user,
    can_password_protect_screener_groups: !isOnFreePlan,
    can_use_piracy_detector: !isOnFreePlan,
    can_view_screener_analytics: !isOnFreePlan,
    settings_link: user.organization_id ? "organization" : "user",
    can_use_udp_downloads: !isOnFreePlan,
    can_use_udp_uploads: !isOnFreePlan,
    can_request_files: !isOnFreePlan,
    can_use_project_folders: !isOnFreePlan
  }
}
