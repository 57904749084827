import React from "react"
import PropTypes from "prop-types"

const ReviewerName = ({ sharedScreener }) => (
  <div className='flex items-center col-12'>
    <div className='truncate'>
      {sharedScreener.shared_name}
      <div className='truncate block muted caption'>
        {sharedScreener.type === "screener_lab"
          ? sharedScreener.screener_application_name
          : sharedScreener.name
            ? sharedScreener.name
            : null}
      </div>
    </div>
  </div>
)

ReviewerName.propTypes = {
  sharedScreener: PropTypes.object.isRequired
}

export default ReviewerName
