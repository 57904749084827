export const videoStatusLabels = {
  REQUESTED: "Requested",
  UPLOADING: "Uploading",
  UPLOADED: "Uploaded",
  PENDING: "Pending Upload",
  PUBLISHED: "Published",
  UNPUBLISHED: "Unpublished",
  ENCODING: "Encoding",
  QUEUED: "Queued",
  READY: "Ready"
}
