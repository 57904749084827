import React from "react"
import PropTypes from "prop-types"
import { DropTarget } from "react-dnd"
import shouldPureComponentUpdate from "@src/helpers/shouldPureComponentUpdate"

class ScrollableDND extends React.Component {
  shouldComponentUpdate = shouldPureComponentUpdate
  render() {
    return this.props.connectDropTarget(<div ref={ref => (this.el = ref)}>{this.props.children}</div>)
  }
}

ScrollableDND.propTypes = {
  children: PropTypes.object.isRequired,
  connectDropTarget: PropTypes.func.isRequired
}

const dragTarget = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  canDrop: monitor.canDrop(),
  isOver: monitor.isOver()
})

const target = {
  canDrop(props, monitor) {
    return false
  },
  hover(props, monitor, component) {
    const cancelAnimationFrame = window.cancelAnimationFrame || (timeout => clearTimeout(timeout))
    const requestAnimationFrame = window.requestAnimationFrame || (func => setTimeout(func, 1000 / 60))

    if (this.lastScroll) {
      cancelAnimationFrame(this.lastScroll)
      this.lastScroll = null
      clearTimeout(this.removeTimeout)
    }

    const bottomBufferHeight = 120
    const topBufferHeight = 150
    const dragYOffset = monitor.getClientOffset().y
    const { top: containerTop, bottom: containerBottom } = component.el.getBoundingClientRect()
    const windowHeight = window.innerHeight
    let scrollDirection = 0
    let scrollMagnitude = 0
    const fromTop = dragYOffset - topBufferHeight - Math.max(containerTop, 0)

    if (fromTop <= 0) {
      // Move up
      scrollDirection = -1
      scrollMagnitude = Math.ceil(Math.sqrt(-1 * fromTop) * 2)
    } else {
      const fromBottom = dragYOffset + bottomBufferHeight - Math.min(containerBottom, windowHeight)
      if (fromBottom >= 0) {
        // Move down
        scrollDirection = 1
        scrollMagnitude = Math.ceil(Math.sqrt(fromBottom) * 2)
      } else {
        // If neither near the top nor bottom, skip calling the scrolling function
        return
      }
    }

    // Indefinitely scrolls the window down at a constant rate
    const doScroll = () => {
      scrollBy(0, scrollDirection * scrollMagnitude)
      this.lastScroll = requestAnimationFrame(doScroll)
    }

    // Stop the scroll loop after a period of inactivity
    this.removeTimeout = setTimeout(() => {
      cancelAnimationFrame(this.lastScroll)
      this.lastScroll = null
    }, 100)

    // Start the scroll loop
    this.lastScroll = requestAnimationFrame(doScroll)
  }
}

export default DropTarget("FILE", target, dragTarget)(ScrollableDND)
