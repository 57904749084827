import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal, Button, Checkbox } from "@bitcine/cs-theme"
import { canModifyScreenerRoom } from "@src/helpers/authorization"
import { push } from "connected-react-router"
import { removeProjectFromRoom, addScreenersToRoom, swapProjectPositions } from "@api/screener_rooms/"
import AddScreeners from "../../modals/add_screeners"
import "../styles.scss"
import Poster from "./poster"
import Screener from "./screener"
import AddScreenerButton from "./add_screener_button"
import 'styled-components/macro'

const isScrollable = elementID => {
  const element = document.getElementById(elementID)
  return element ? element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight : false
}

const Project = ({
  project,
  removeProjectFromRoom,
  addScreenersToRoom,
  swapProjectPositions,
  organizationId,
  screenerRoom,
  canModify,
  push
}) => {
  const [showAddScreenersModal, setShowAddScreenersModal] = useState(false)
  const [showScrollIcon, setShowScrollIcon] = useState(false)
  const [showRemoveProjectModal, setShowRemoveProjectModal] = useState(false)
  const [notifyReviewers, setNotifyReviewers] = useState(false)
  useEffect(() => {
    const scroll = isScrollable("project-logline-" + project._id)
    setShowScrollIcon(scroll)
  }, [project])
  return (
    <div className={`box-shadow bg-white rounded mt2 p2 relative ${"projectContainer"}`}>
      <div className='flex'>
        <div
          css={`
            width: 220px;
          `}>
          <Poster project={project}/>
        </div>
        <div className='flex-auto pl2 relative flex flex-wrap'>
          <div
            css={`
              align-self: flex-start;
            `}
            className='col-12'>
            <div className='flex items-center justify-between'>
              <h3 className='flex items-center'>
                {project.title}
                <button
                  data-tooltip='Update project metadata'
                  className={`cs-button tooltip link material-icons ml2 ${"editMetadata"}`}
                  onClick={() => push(`/projects/${project._id}/details/general`)}>
                  edit
                </button>
              </h3>
              <div>
                {canModify && project.order !== 0 && (
                  <button
                    data-tooltip='Move up in room'
                    className='cs-button tooltip link material-icons mr1'
                    onClick={() =>
                      swapProjectPositions(organizationId, screenerRoom._id, project.order, project.order - 1)
                    }>
                    keyboard_arrow_up
                  </button>
                )}
                {canModify && project.order !== screenerRoom.projects.length - 1 && (
                  <button
                    data-tooltip='Move down in room'
                    className='cs-button tooltip link material-icons mr1'
                    onClick={() =>
                      swapProjectPositions(organizationId, screenerRoom._id, project.order, project.order + 1)
                    }>
                    keyboard_arrow_down
                  </button>
                )}
                {canModify && (
                  <button
                    data-tooltip='Remove from room'
                    className='cs-button tooltip link material-icons'
                    onClick={() => setShowRemoveProjectModal(true)}>
                    delete_outline
                  </button>
                )}
              </div>
            </div>
            <div
              className='mr2 max-width-3'
              css={`
                line-height: 16px;
              `}>
              {project.project_logline && (
                <div className='my2 flex items-center'>
                  <p
                    id={`project-logline-${project._id}`}
                    css={`
                      max-height: 128px;
                      overflow-y: scroll;
                    `}>
                    {project.project_logline}
                  </p>
                  {showScrollIcon && <span className='material-icons muted ml1'>unfold_more</span>}
                </div>
              )}
              {project.project_custom_fields.map((field, i) => (
                <div key={i}>
                  <b>{field.title}</b>: {field.value}
                </div>
              ))}
            </div>
          </div>
          <div
            className='flex flex-wrap col-12 max-width-5'
            css={`
              align-self: flex-end;
            `}>
            {project.screeners.map((screener, index) => (
              <Screener key={index} screener={screener} project={project}/>
            ))}
            <AddScreenerButton empty={project.screeners.length === 0} onClick={() => setShowAddScreenersModal(true)}/>
          </div>
        </div>
      </div>
      {showAddScreenersModal && (
        <AddScreeners projectID={project._id} onClose={() => setShowAddScreenersModal(false)}/>
      )}
      {showRemoveProjectModal && (
        <Modal open onClose={() => setShowRemoveProjectModal(false)} title='Remove Project'>
          <p>
            Are you sure you want to remove <b>{project.title}</b> from your screener room?
          </p>
          <div className='flex justify-end mt2'>
            <div className='flex items-center'>
              <Checkbox
                label='Notify reviewers of changes'
                checked={notifyReviewers}
                onChange={() => setNotifyReviewers(!notifyReviewers)}/>
              <Button
                className='ml2'
                onClick={() => {
                  removeProjectFromRoom(organizationId, screenerRoom._id, [project._id], notifyReviewers)
                  setShowRemoveProjectModal(false)
                }}>
                Remove Project
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

Project.propTypes = {
  project: PropTypes.object.isRequired,
  removeProjectFromRoom: PropTypes.func.isRequired,
  swapProjectPositions: PropTypes.func.isRequired,
  addScreenersToRoom: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  screenerRoom: PropTypes.object.isRequired,
  canModify: PropTypes.bool.isRequired,
  push: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  organizationId: state.user.auth.organization_id,
  screenerRoom: state.screener_rooms.screenerRoom.data,
  canModify: canModifyScreenerRoom(state.user.auth, state.screener_rooms.screenerRoom.data)
})

const mapDispatchToProps = dispatch => ({
  removeProjectFromRoom: bindActionCreators(removeProjectFromRoom, dispatch),
  addScreenersToRoom: bindActionCreators(addScreenersToRoom, dispatch),
  swapProjectPositions: bindActionCreators(swapProjectPositions, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Project)
