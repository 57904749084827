export const GET_USER_TAGS = "GET_USER_TAGS"
export const GET_USER_TAGS_PENDING = "GET_USER_TAGS_PENDING"
export const GET_USER_TAGS_FULFILLED = "GET_USER_TAGS_FULFILLED"

export const GET_TAGS = "GET_TAGS"
export const GET_TAGS_PENDING = "GET_TAGS_PENDING"
export const GET_TAGS_FULFILLED = "GET_TAGS_FULFILLED"

export const UPDATE_TAGS = "UPDATE_TAGS"
export const UPDATE_TAGS_PENDING = "UPDATE_TAGS_PENDING"
export const UPDATE_TAGS_FULFILLED = "UPDATE_TAGS_FULFILLED"

export const GET_RECENT_TAGS = "GET_RECENT_TAGS"
export const GET_RECENT_TAGS_PENDING = "GET_RECENT_TAGS_PENDING"
export const GET_RECENT_TAGS_FULFILLED = "GET_RECENT_TAGS_FULFILLED"
