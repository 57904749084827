import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setQueryValue } from "@api/shared_screeners"

const Search = ({ setQueryValue }) => {
  const [search, setSearch] = useState("")
  useEffect(() => {
    setQueryValue("search", search)
  }, [search])
  return (
    <input
      className='cs-input'
      placeholder='Search for reviewers...'
      autoComplete='off'
      type='search'
      style={{ height: "34px" }}
      onChange={e => setSearch(e.target.value)}
      value={search}/>
  )
}

Search.propTypes = {
  setQueryValue: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  setQueryValue: bindActionCreators(setQueryValue, dispatch)
})

export default connect(null, mapDispatchToProps)(Search)
