export const dragTarget = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  canDrop: monitor.canDrop(),
  isOver: monitor.isOver()
})

export const fileTarget = {
  hover(props, monitor, component) {
    // if (!props.requiredFile.acceptedCineSendFileTypes.includes(monitor.getItem().type)) {
    // 	props.setActiveError(props.requiredFile.type)
    // }
    // else {
    // 	props.setActiveError('')
    // }
  },
  canDrop(props, monitor) {
    return props.requiredFile.acceptedCineSendFileTypes.includes(monitor.getItem().type)
  },
  drop(props, monitor, component) {
    const requiredFile = props.requiredFile
    const file = monitor.getItem()

    // Either drop adds to an array or it takes the selected file's spot:
    if (requiredFile.allow_multiple) {
      let files = props.order[props.requiredFile.type]
      let exists = files.indexOf(file) !== -1
      if (!exists) {
        files.push(file)
        props.updateOrder(requiredFile.type, files)
      }
    } else {
      props.updateOrder(requiredFile.type, file)
    }
  }
}

export const args = {
  source_file: {
    icon: "movie",
    text: "Drag and drop your source video file here!",
    label: "Movie File",
    tall: true
  },
  audio_track: {
    icon: "audiotrack",
    text: "Drag and drop your audio file here!",
    label: "Audio File",
    tall: false
  },
  audio_tracks: {
    icon: "audiotrack",
    text: "Drag and drop your audio file(s) here!",
    label: "Audio File",
    tall: false
  },
  closed_caption_track: {
    icon: "closed_caption",
    text: "Drag and drop your closed captions track here!",
    label: "Caption File",
    tall: false
  },
  subtitle_track: {
    icon: "subtitles",
    text: "Drag and drop your subtitles track here!",
    label: "Subtitle File",
    tall: false
  },
  video_file: {
    icon: "movie",
    text: "Drag and drop your video file here!",
    label: "Video File",
    tall: true
  }
}

export const subtitles = {
  "file-picker": "Select File",
  "file-info": "File Information",
  "turnaround-time": "Accuracy Level and Turnaround Time",
  "adding-to-cart": "Adding to Cart",
  complete: "Complete",
  "confirm-close": "Cancel Order",
  "tmp-dcp-options": "Film Length",
  "disc-options": "Disc Options",
  "disc-quantity": "Disc Quantity",
  "dcp-addons": "DCP Extras",
  "dcp-turnaround": "Turnaround Days",
  "dcp-options": "DCP Options",
  "dcp-confirmation": "Review DCP",
  confirmation: "Review",
  "send-physical-copy-drives": "Select Hard Drive",
  "send-discs": "Select Disc Quantity",
  "select-inspection-type": "Select Inspection Type",
  "select-conversion-format": "Specify Output Parameters",
  "confirm-conversion-details": "Confirm Conversion Details"
}
