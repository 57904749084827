export const GET_PACKAGES = {
  REQUEST: "GET_PACKAGES",
  PENDING: "GET_PACKAGES_PENDING",
  FAILED: "GET_PACKAGES_REJECTED",
  COMPLETE: "GET_PACKAGES_FULFILLED"
}

export const UPDATE_PACKAGE = {
  REQUEST: "UPDATE_PACKAGE",
  PENDING: "UPDATE_PACKAGE_PENDING",
  FAILED: "UPDATE_PACKAGE_REJECTED",
  COMPLETE: "UPDATE_PACKAGE_FULFILLED"
}

export const TOGGLE_UPDATE_MODAL = "TOGGLE_UPDATE_MODAL"
