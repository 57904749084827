import React from "react"
import ErrorBoundary from "@components/error_boundaries/"
import Cart from "./cart"

const CartWrap = props => (
  <ErrorBoundary>
    <Cart {...props}/>
  </ErrorBoundary>
)

export default CartWrap
