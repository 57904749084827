import React from "react"
import PropTypes from "prop-types"
import FolderIcon from "@src/public/icons/empty/project.inline.svg"
import PosterIcon from "@src/public/icons/empty/poster.inline.svg"
import Header from "../header"
import "../styles.scss"
import Empty from "./empty"
import 'styled-components/macro'

class Folders extends React.Component {
  getPosters() {
    return this.props.folder.posters.sort((a, b) => {
      var titleA = a.projectTitle.toUpperCase()
      var titleB = b.projectTitle.toUpperCase()

      return titleA < titleB ? -1 : titleA > titleB ? 1 : 0
    })
  }
  render() {
    const { folder, addingProjectToFolder } = this.props
    const posters = this.getPosters()
    return (
      <div
        className={`z0 bg-white rounded poster ${addingProjectToFolder ? "shrink" : ""} relative`}
        style={{ padding: "145% 0 0 0" }}>
        <Header folder={folder}/>
        {!folder.posters.length ? (
          <Empty/>
        ) : (
          <div className='absolute top-0 right-0 bottom-0 left-0 flex flex-wrap content-stretch p1'>
            {Array.from(Array(9)).map((x, i) => (
              <div className='col-4 p1' key={i}>
                {typeof posters[i] === "object" && (
                  <div
                    style={{
                      backgroundImage: posters[i].active_poster_url ? `url(${posters[i].active_poster_url})` : ""
                    }}
                    className={`folderPoster relative border border-gray-5 rounded bg-cover bg-no-repeat`}>
                    {!posters[i].active_poster_url && (
                      <span className='center muted material-icons block flex items-center justify-center absolute top-0 left-0 bottom-0 right-0'>
                        {posters[i].is_folder ? (
                          <FolderIcon
                            css={`
                              width: 50px;
                              height: 50px;
                            `}/>
                        ) : (
                          <PosterIcon
                            css={`
                              width: 50px;
                              height: 50px;
                            `}/>
                        )}
                      </span>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}

Folders.propTypes = {
  folder: PropTypes.object.isRequired,
  addingProjectToFolder: PropTypes.bool.isRequired
}

export default Folders
