import React from "react"
import PropTypes from "prop-types"

const Multi = ({ update, value, stateKey, disabled = false }) => (
  <div>
    <label className='cs-label'>
      <span className='capitalize'>{stateKey}</span>
      (s)
    </label>
    {value.split(",").map((val, i) => (
      <div key={i} className={`flex items-center ${i > 0 ? "mt1" : ""}`}>
        <input
          value={val}
          disabled={disabled}
          data-testid={`video-request-${stateKey}-input-${i}`}
          placeholder='John Smith'
          onChange={e => {
            const d = value.split(",")
            d[i] = e.target.value
            update(d.join(","), false)
          }}
          onBlur={e => {
            const d = value.split(",")
            d[i] = e.target.value
            update(d.join(","), true)
          }}
          className='cs-input col-12 block'/>
        {value.split(",").length > 1 && !disabled && (
          <button
            data-tooltip={`Remove ${stateKey}`}
            data-testid={`video-request-remove-${stateKey}-${i}`}
            onClick={() => {
              update(
                value
                  .split(",")
                  .filter((name, index) => i !== index)
                  .join(",")
              )
            }}
            className='cs-button link material-icons tooltip ml2'>
            <span className='regular'>close</span>
          </button>
        )}
      </div>
    ))}
    {!disabled && (
      <button
        onClick={() => update(`${value},`)}
        data-testid={`video-request-add-${stateKey}`}
        className='cs-button link mb1'>
        <small className='regular underline'>+ Add another {stateKey}</small>
      </button>
    )}
  </div>
)

Multi.propTypes = {
  update: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  stateKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

export default Multi
