import React from "react"
import PropTypes from "prop-types"
import { Table, Status } from "@bitcine/cs-theme"
import { DEFAULTS } from "@constants"
import dayjs from "dayjs"
import humanFileSize from "@src/helpers/humanFileSize"
import { GLOBALS } from "@src/config"
import 'styled-components/macro'

class ImportsTable extends React.Component {
  constructor(props) {
    super(props)

    this.timer = null

    this.state = {
      imports_status: "PENDING",
      imports: []
    }
  }
  componentWillUnmount() {
    clearTimeout(this.timer)
  }
  componentDidMount() {
    this.updateProgress()
  }
  updateProgress() {
    clearTimeout(this.timer)

    fetch(`${GLOBALS.API_URL}/imports`, DEFAULTS)
      .then(res => res.json())
      .then(res => {
        this.setState({
          imports: res.response,
          imports_status: "READY"
        })
      })
      .catch(error => this.setState({ ...this.state, imports_status: "ERROR", error }))

    if (this.props.active) {
      // have a timeout call this every 15-or-so-seconds?
      this.setTimer()
    }
  }
  setTimer() {
    this.timer = setTimeout(() => {
      this.updateProgress()
    }, 10000)
  }
  getStatusFromCode(code) {
    switch (code) {
      case 1:
        return "Pending"
      case 2:
        return "Queued"
      case 4:
        return "In Progress"
      case 72:
        return "Completed Successfully"
      case 128:
        return "Incomplete with Errors"
      case 136:
        return "Completed with Errors"
    }
  }
  render() {
    const { imports_status, imports } = this.state
    return (
      <Status pending={imports_status === "PENDING"} error={imports_status === "ERROR"}>
        <div className='flex items-center'>
          <button
            onClick={() => this.props.hideImports()}
            data-tooltip={"Back to import link"}
            css={`
              border-radius: 50%;
              width: 30px;
              height: 30px;
              &:hover {
                background: #ededed;
                &::after {
                  transform: translateX(-25px) !important;
                }
              }
            `}
            className='cs-button link tooltip material-icons flex items-center justify-center'>
            keyboard_arrow_left
          </button>
          <h4 className='ml1'>Recent Imports</h4>
        </div>
        <Table
          className='my3'
          widths={["auto", 200, 165]}
          header={{
            columns: [{ text: "Title" }, { text: "Status" }, { text: "Last Updated" }]
          }}
          body={{
            data: imports,
            empty: {
              icon: "file",
              title: "No Imports!"
            },
            row: {
              compact: true,
              render: [
                fileImport => fileImport.origin_name,
                fileImport => (
                  <>
                    {fileImport.status === 4 && fileImport.progress_status ? (
                      <div className='col-12'>
                        <progress
                          max={fileImport.progress_status.upload_total_size}
                          value={fileImport.progress_status.upload_sent_bytes}
                          className='cs-progress col-12 block my1'/>
                        <div className='flex small'>
                          {humanFileSize(fileImport.progress_status.upload_sent_bytes)}
                          {" / "}
                          {humanFileSize(fileImport.progress_status.upload_total_size)}
                        </div>
                      </div>
                    ) : (
                      this.getStatusFromCode(fileImport.status)
                    )}
                  </>
                ),
                fileImport =>
                  dayjs
                    .utc(fileImport.updated_at)
                    .local()
                    .format("MMM D, YYYY [at] h:mma")
              ]
            }
          }}/>
        <div className='flex justify-end mt2'>
          <button className='cs-button white' onClick={() => this.props.hideImports()}>
            Hide Imports
          </button>
        </div>
      </Status>
    )
  }
}

ImportsTable.propTypes = {
  hideImports: PropTypes.func,
  active: PropTypes.bool
}

export default ImportsTable
