import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { saveFile, updateFile } from "@api/project/files/"

class Name extends React.Component {
  save(value) {
    this.props.saveFile(this.props.file._id, this.props.file.type, "name", value)
    this.props.updateFile(this.props.file._id, "name", value)
  }
  componentDidMount() {
    if (this.props.focusOnMount) {
      setTimeout(() => {
        this.nameInput.select()
      }, 50)
    }
  }
  render() {
    return (
      <div className='p2'>
        <label className='cs-label' htmlFor='file-name'>{`${
          this.props.file.type === "folder" ? "Folder" : "File"
        } Name`}</label>
        <input
          placeholder={`Enter a ${this.props.file.type === "folder" ? "Folder" : "File"} name`}
          value={this.props.file.name || ""}
          className='cs-input col-12'
          onChange={e => this.props.onUpdate({ ...this.props.file, name: e.target.value })}
          onBlur={e => this.save(e.target.value)}
          ref={nameInput => (this.nameInput = nameInput)}/>
      </div>
    )
  }
}

Name.propTypes = {
  file: PropTypes.object.isRequired,
  saveFile: PropTypes.func.isRequired,
  fileType: PropTypes.string,
  updateFile: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  focusOnMount: PropTypes.bool
}

Name.defaultProps = {
  focusOnMount: false
}

const mapDispatchToProps = dispatch => ({
  saveFile: bindActionCreators(saveFile, dispatch),
  updateFile: bindActionCreators(updateFile, dispatch)
})

export default connect(
  null,
  mapDispatchToProps
)(Name)
