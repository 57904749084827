import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getDrives, updateOrder, nextStep } from "@api/project/orders"
import { getDiscPrice } from "@src/helpers/pricing"
import "../styles.scss"

class Disc extends React.Component {
  componentDidMount() {
    const { file } = this.props
    this.props.getDrives(file._id, file.type)
  }
  render() {
    const { nextStep, file, order, discItems, updateOrder, price, currencySymbol } = this.props
    return (
      <div className='col-12'>
        <div className={"orderInnerContainer"}>
          <p className='mb2'>
            Burn and send {file.disc_type}s for {file.name}
          </p>
          {!discItems ? (
            <div className='center py3'>
              <div className='spinner'/>
            </div>
          ) : (
            <div>
              <label className='cs-label'>Disc Quantity</label>
              <div className='flex items-center justify-between mt1'>
                <div className='flex items-center'>
                  <button
                    disabled={order.disc_quantity < 2}
                    onClick={() => updateOrder("disc_quantity", parseFloat(order.disc_quantity) - 1)}
                    className='cs-button link material-icons'>
                    remove_circle
                  </button>
                  <input
                    className='cs-input no-min-width mx1'
                    onPaste={e => e.preventDefault()}
                    value={order.disc_quantity}
                    style={{ width: "75px" }}
                    onChange={({ target: { value } }) => {
                      updateOrder("disc_quantity", value === "" ? 1 : parseFloat(value.replace(/\D/, "")))
                    }}/>
                  <button
                    onClick={() => updateOrder("disc_quantity", parseFloat(order.disc_quantity) + 1)}
                    className='cs-button link material-icons'>
                    add_circle
                  </button>
                </div>
                <div className='pl2 bold'>
                  {currencySymbol}
                  {(parseFloat(price.disc) * order.disc_quantity).toFixed(2)}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='flex justify-end col-12 pt3'>
          <button className='cs-button' onClick={nextStep}>
            Add to Cart
          </button>
        </div>
      </div>
    )
  }
}

Disc.propTypes = {
  nextStep: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
  getDrives: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  discItems: PropTypes.object,
  price: PropTypes.object,
  currencySymbol: PropTypes.string.isRequired
}

const mapStateToProps = state => {
  const order = state.orders.active_order
  const file = order.disc_output
  return {
    file,
    order,
    discItems: state.utils.disc_items,
    price: state.utils.disc_items
      ? getDiscPrice(state.utils.disc_items, 0, file.disc_type === "Blu-ray" ? "blu_ray" : "dvd", order.disc_quantity)
      : {},
    currencySymbol: state.user.auth.currency_symbol
  }
}

const mapDispatchToProps = dispatch => ({
  nextStep: bindActionCreators(nextStep, dispatch),
  getDrives: bindActionCreators(getDrives, dispatch),
  updateOrder: bindActionCreators(updateOrder, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Disc)
