import React from "react"
import Aspera from "@src/public/aspera.png"
import { toggleMoveFolder } from "@api/project/folders/"
import { toggleCheckedFile } from "@api/project/files/"
import { push } from "connected-react-router"
import { can, MODIFY_PROJECTS, DOWNLOAD_FILES, SHARE_FILES, MANAGE_SCREENERS } from "@src/helpers/authorization"
import { viewFileInfo, toggleModalVisibility, showAsperaSharing } from "@api/project/files/modals"
import MoveFolderIcon from "@src/public/icons/material/move_folder.inline.svg"
import { previewDocument } from "@api/project/files/preview"
import { toggleOrderVisibility, updateOrder } from "@api/project/orders"
import { downloadFileWithHTTP, downloadFileWithAspera } from "@api/transfer/download"
import HardDriveIcon from "@src/public/icons/material/hard_drive.inline.svg"
import { addAsperaDownloadLinkTransfer } from "@api/download_link/aspera_downloads"
import 'styled-components/macro'

export const getFileAndFolderDropdownOptions = file => (dispatch, getState) => {
  // If any dialog (order, file info) is open, there should be no file options
  if (getState().utils.dialogOpen) {
    return null
  } else if (file.type === "folder") {
    return dispatch(getFolderDropdownOptions(file))
  } else {
    return dispatch(getFileDropdownOptions(file))
  }
}

const getFolderDropdownOptions = folder => (dispatch, getState) =>
  [
    {
      text: "Open Folder",
      icon: "folder_open",
      onClick: () => dispatch(push(`/projects/${folder.project_id}/files/folder/${folder._id}`)),
      breakAfter: true,
      show: true
    },
    {
      text: "Share",
      icon: (
        <div
          css={`
            transform: scaleX(-1);
          `}>
          reply
        </div>
      ),
      show: can(getState().user.auth, [SHARE_FILES]),
      breakAfter: true,
      children: [
        {
          text: {
            title: "Share folder",
            description: "Recipients download through their browser"
          },
          icon: "exit_to_app",
          onClick: () => {
            const { _id, type, name } = folder
            dispatch(toggleModalVisibility("share", [{ _id, type, name }]))
          },
          show: true
        },
        {
          text: {
            title: "Share folder with Aspera Connect",
            description: "Recipients download faster with Aspera Connect"
          },
          icon: (
            <img
              className='cs-img'
              src={Aspera}
              css={`
                width: 20px;
                height: 20px;
                filter: grayscale(100%);
              `}/>
          ),
          onClick: () => dispatch(showAsperaSharing([folder])),
          show: true
        },
        {
          text: {
            title: "Folder sharing history",
            description: "View who you've shared this folder with"
          },
          icon: "history",
          onClick: () => dispatch(push(`/projects/${folder.project_id}/packages/${folder._id}`)),
          disabled: false,
          show: true
        }
      ].filter(opt => opt.show)
    },
    {
      text: "Download",
      icon: "cloud_download",
      show: can(getState().user.auth, [DOWNLOAD_FILES]),
      children: [
        {
          text: {
            title: "Download with Aspera",
            description: "Faster speeds, uses Aspera Entitlements"
          },
          icon: (
            <img
              className='cs-img'
              src={Aspera}
              css={`
                width: 20px;
                height: 20px;
                filter: grayscale(100%);
              `}/>
          ),
          onClick: () => dispatch(addAsperaDownloadLinkTransfer(null, folder)),
          show: true
        }
      ]
    },
    {
      text: "Move",
      icon: <MoveFolderIcon/>,
      show: can(getState().user.auth, [MODIFY_PROJECTS]),
      children: [
        {
          text: {
            title: "Move to Folder",
            description: "Move to another folder in this project"
          },
          icon: "folder",
          onClick: () => dispatch(move(folder))
        },
        {
          text: {
            title: "Move to Project",
            description: "Move to another project in your account"
          },
          icon: "movie",
          onClick: () => dispatch(moveToProject(folder))
        }
      ],
      breakAfter: true
    },
    {
      text: "Folder info",
      icon: "info",
      show: can(getState().user.auth, [MODIFY_PROJECTS]),
      onClick: () => dispatch(viewFileInfo(folder))
    },
    {
      text: "Rename",
      icon: "edit",
      show: can(getState().user.auth, [MODIFY_PROJECTS]),
      onClick: () => dispatch(viewFileInfo(folder, "name")),
      breakAfter: true
    },
    {
      text: "Delete",
      icon: "delete",
      show: can(getState().user.auth, [MODIFY_PROJECTS]),
      onClick: () => {
        const { _id, type, name } = folder
        dispatch(toggleModalVisibility("delete", [{ _id, type, name }]))
      }
    }
  ].filter(opt => opt.show)

const getFileDropdownOptions = file => (dispatch, getState) =>
  [
    {
      text: "Restore File",
      icon: "restore",
      onClick: () => dispatch(toggleModalVisibility("defrost", [file])),
      show: !!file.frozen_at,
      showIfFrozen: true,
      breakAfter: true
    },
    {
      text: "Preview",
      icon: "remove_red_eye",
      show: file.type === "document",
      breakAfter: true,
      onClick: () => dispatch(previewDocument(file))
    },
    {
      text: "Play audio file",
      icon: "audiotrack",
      show: file.type === "audio_track",
      breakAfter: true,
      onClick: () => dispatch(previewDocument(file))
    },
    {
      text: "Preview",
      icon: "play_circle_filled",
      show: file.type === "source_file",
      breakAfter: true,
      disabled: !file.screener,
      showIfFrozen: true,
      onClick: () => dispatch(previewDocument(file))
    },
    {
      text: "Order",
      icon: "add_shopping_cart",
      show: ["source_file", "dcp", "disc_output"].includes(file.type),
      breakAfter: true,
      children: [
        {
          icon: "movie",
          text: "Order DCP",
          show: file.type === "source_file",
          onClick: () => dispatch(order("dcp", file))
        },
        {
          icon: "closed_caption",
          text: "Order closed captions",
          show: file.type === "source_file" || file.type === "dcp",
          onClick: () => dispatch(order("closed-captions", file))
        },
        {
          icon: "album",
          text: "Order Blu-ray/DVD Discs",
          show: file.type === "source_file",
          onClick: () => dispatch(order("disc", file))
        },
        {
          icon: "swap_horizontal_circle",
          text: "Order format conversion",
          show: file.type !== "disc_output",
          onClick: () => dispatch(order("conversion", file))
        },
        {
          icon: "verified_user",
          text: "Order quality inspection",
          show: file.type !== "disc_output",
          onClick: () => dispatch(order("inspection", file))
        },
        {
          text: "Send on a hard drive",
          icon: (
            <HardDriveIcon
              css={`
                width: 22px;
              `}/>
          ),
          disabled: !file.key || !file.size,
          onClick: () => dispatch(send(file)),
          show: file.type !== "disc_output"
        },
        {
          text: `Burn and send ${file.disc_type}s`,
          icon: "album",
          disabled: !file.key || !file.size,
          onClick: () => dispatch(send(file)),
          show: file.type === "disc_output"
        }
      ].filter(opt => opt.show)
    },
    {
      text: "Share",
      icon: (
        <div
          css={`
            transform: scaleX(-1);
          `}>
          reply
        </div>
      ),
      show:
        can(getState().user.auth, [SHARE_FILES]) ||
        (file.type === "source_file" && can(getState().user.auth, [MANAGE_SCREENERS])),
      showIfFrozen: file.screener && file.screener.status === "Published",
      children: [
        {
          text: {
            title: "Share as a streaming screener",
            description: "Watchable streaming link for reviewers"
          },
          icon: "play_circle_filled",
          onClick: () => {
            dispatch(push(`/projects/${file.project_id}/files/preview-link/${file.screener._id}`))
          },
          showIfFrozen: file.screener && file.screener.status === "Published",
          show: file.type === "source_file" && can(getState().user.auth, [MANAGE_SCREENERS])
        },
        {
          text: {
            title: "Share file",
            description: "Recipients download through their browser"
          },
          icon: "exit_to_app",
          disabled: !file.key || !file.url,
          onClick: () => {
            const { _id, type, name } = file
            dispatch(toggleModalVisibility("share", [{ _id, type, name }]))
          },
          show: can(getState().user.auth, [SHARE_FILES])
        },
        {
          text: {
            title: "Share file with Aspera Connect",
            description: "Recipients download faster with Aspera Connect"
          },
          icon: (
            <img
              className='cs-img'
              src={Aspera}
              css={`
                width: 20px;
                height: 20px;
                filter: grayscale(100%);
              `}/>
          ),
          disabled: !file.key || !file.url,
          onClick: () => dispatch(showAsperaSharing([file])),
          show: can(getState().user.auth, [SHARE_FILES])
        },
        {
          text: {
            title: "Sharing history",
            description: "View who you've shared this file with"
          },
          icon: "history",
          onClick: () => {
            dispatch(push(`/projects/${file.project_id}/packages/${file._id}`))
          },
          disabled: false,
          show: true
        }
      ].filter(opt => (!file.frozen_at ? opt.show : opt.show && opt.showIfFrozen))
    },
    {
      text: "Download",
      icon: "cloud_download",
      show: can(getState().user.auth, [DOWNLOAD_FILES]),
      children: [
        {
          text: {
            title: "Download file",
            description: "Standard download speeds"
          },
          icon: "file_download",
          onClick: () => dispatch(download("http", file)),
          disabled: !file.url || !file.key
        },
        {
          text: {
            title: "Download with Aspera",
            description: "Faster speeds, uses Aspera Entitlements"
          },
          icon: (
            <img
              className='cs-img'
              src={Aspera}
              css={`
                width: 20px;
                height: 20px;
                filter: grayscale(100%);
              `}/>
          ),
          onClick: () => dispatch(addAsperaDownloadLinkTransfer(null, file)),
          show: download.method === "aspera"
        }
      ]
    },
    {
      text: "Move",
      icon: "arrow_right_alt",
      show: can(getState().user.auth, [MODIFY_PROJECTS]),
      showIfFrozen: true,
      children: [
        {
          text: {
            title: "Move to Folder",
            description: "Move to another folder in this project"
          },
          icon: "folder",
          onClick: () => dispatch(move(file))
        },
        {
          text: {
            title: "Move to Project",
            description: "Move to another project in your account"
          },
          icon: "movie",
          onClick: () => dispatch(moveToProject(file))
        }
      ],
      breakAfter: true
    },
    {
      text: "File info",
      icon: "info",
      onClick: () => dispatch(viewFileInfo(file)),
      show: can(getState().user.auth, [MODIFY_PROJECTS]),
      showIfFrozen: true
    },
    {
      text: "Rename",
      icon: "edit",
      show: can(getState().user.auth, [MODIFY_PROJECTS]),
      onClick: () => dispatch(viewFileInfo(file, "name")),
      showIfFrozen: true,
      breakAfter: true
    },
    {
      text: "Delete",
      icon: "delete",
      show: can(getState().user.auth, [MODIFY_PROJECTS]),
      showIfFrozen: true,
      onClick: () => dispatch(toggleModalVisibility("delete", [file]))
    }
  ].filter(opt => (!file.frozen_at ? opt.show : opt.show && opt.showIfFrozen))

const order = (type, file) => dispatch => {
  let genericVideoOrders = ["inspection", "conversion", "closed-captions"]
  let modelType = genericVideoOrders.includes(type) ? "video_file" : file.type
  dispatch(toggleOrderVisibility(type))
  dispatch(updateOrder(modelType, file))
}

const send = file => dispatch => {
  dispatch(toggleOrderVisibility(file.type === "disc_output" ? "send-discs" : "send-physical-copy"))
  let modelType = file.type === "disc_output" ? file.type : "video_file"
  dispatch(updateOrder(modelType, file))
}

const download = (type, file) => dispatch => {
  if (type === "http") {
    dispatch(downloadFileWithHTTP(file))
  } else if (type === "aspera") {
    dispatch(downloadFileWithAspera(file))
  }
}

const move = file => dispatch => {
  dispatch(toggleCheckedFile(file._id))
  dispatch(toggleMoveFolder())
}

const moveToProject = file => dispatch => {
  dispatch(toggleCheckedFile(file._id))
  dispatch(toggleModalVisibility("moveFilesToProject", [file]))
}
