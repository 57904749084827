import dayjs from "dayjs"

const sort = (list, key, direction) => {
  const SWAP = direction === 'ASC' ? 1 : -1
  return list.sort((a, b) => {
    let newA = a[key]
    let newB = b[key]
    if (dayjs(newA).isValid() && dayjs(newB).isValid()) {
      return (parseFloat(dayjs(newA).format("X")) - parseFloat(dayjs(newB).format("X"))) * SWAP
    }
    return newA.toLowerCase().localeCompare(newB.toLowerCase()) * SWAP
  })
}

export default sort