import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateOrder, nextStep } from "@api/project/orders"
import "../styles.scss"
import Drop from "./drop"
import File from "./file"

class Files extends React.Component {
  constructor(props) {
    super(props)
    this.state = { active_error: "" }
  }
  isDisabled() {
    let requiredFiles = this.props.order.files.filter(f => f.isRequired && !this.props.order[f.type])
    return requiredFiles.length > 0
  }
  render() {
    const { updateOrder, nextStep, order } = this.props
    return (
      <div className='col-12'>
        <div className={"orderInnerContainer"}>
          {order.files.map((requiredFile, index) => (
            <div key={index}>
              {requiredFile.description && <span dangerouslySetInnerHTML={{ __html: requiredFile.description }}/>}
              {!order[requiredFile.type] || requiredFile.allow_multiple ? (
                <Drop
                  updateOrder={(key, value) => updateOrder(key, value)}
                  requiredFile={requiredFile}
                  order={order}
                  setActiveError={error => this.setState({ active_error: error })}
                  activeError={this.state.active_error}/>
              ) : (
                <File
                  updateOrder={(key, value) => updateOrder(key, value)}
                  requiredFile={requiredFile}
                  selectedFile={order[requiredFile.type]}
                  order={order}
                  setActiveError={error => this.setState({ active_error: error })}
                  activeError={this.state.active_error}/>
              )}
              {requiredFile.allow_multiple && (
                <div>
                  {order[requiredFile.type].map((selectedFile, index) => (
                    <File
                      key={index}
                      updateOrder={(key, value) => updateOrder(key, value)}
                      requiredFile={requiredFile}
                      selectedFile={selectedFile}
                      order={order}
                      setActiveError={error => this.setState({ active_error: error })}
                      activeError={this.state.active_error}/>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className='flex justify-end col-12 pt3'>
          <button className='cs-button' disabled={this.isDisabled()} onClick={nextStep}>
            Next
          </button>
        </div>
      </div>
    )
  }
}

Files.propTypes = {
  updateOrder: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  nextStep: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const order = state.orders.active_order
  return { order }
}

const mapDispatchToProps = dispatch => ({
  updateOrder: bindActionCreators(updateOrder, dispatch),
  nextStep: bindActionCreators(nextStep, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Files)
