import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Nav from "@components/nav"
import Suspended from "@containers/account/suspended"
import TrackJS from "@containers/account/track_js"
import Support from "@components/support/"
import PurchaseReviewers from "@components/payment/reviewers"
import SendPreviewLinks from "@components/preview/send/index"
import SendSuccess from "@components/preview/send/success"
import DocumentPreviewer from "@components/document_previewer"
import Idle from "@components/idle"
import 'styled-components/macro'

const AppWrap = ({ children, topUp, showSendSuccessModal, showDocumentPreviewer }) => (
  <Suspended>
    <TrackJS>
      <Idle>
        <div
          css={`
            margin-top: 56px;
          `}>
          <Nav/>
          {children}
          {topUp.visible && <PurchaseReviewers/>}
          <SendPreviewLinks/>
          {showSendSuccessModal && <SendSuccess/>}
          {showDocumentPreviewer && <DocumentPreviewer/>}
          <Support/>
        </div>
      </Idle>
    </TrackJS>
  </Suspended>
)

AppWrap.propTypes = {
  children: PropTypes.node.isRequired,
  topUp: PropTypes.object,
  showSendSuccessModal: PropTypes.bool.isRequired,
  showDocumentPreviewer: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  topUp: state.user.reviewers,
  showSendSuccessModal: state.preview.send.showSuccessModal,
  showDocumentPreviewer: state.documentPreviewer.isOpen
})

export default connect(mapStateToProps)(AppWrap)
