export const ADD_NEW_FOLDER = "ADD_NEW_FOLDER"
export const ADD_NEW_FOLDER_PENDING = "ADD_NEW_FOLDER_PENDING"
export const ADD_NEW_FOLDER_FAILED = "ADD_NEW_FOLDER_REJECTED"
export const ADD_NEW_FOLDER_FULFILLED = "ADD_NEW_FOLDER_FULFILLED"

export const TOGGLE_NEW_FOLDER = "TOGGLE_NEW_FOLDER"
export const UPDATE_NEW_FOLDER = "UPDATE_NEW_FOLDER"

export const TOGGLE_EDIT_FOLDER = "TOGGLE_EDIT_FOLDER"

export const EDIT_FOLDER = "EDIT_FOLDER"
export const EDIT_FOLDER_PENDING = "EDIT_FOLDER_PENDING"
export const EDIT_FOLDER_FULFILLED = "EDIT_FOLDER_FULFILLED"
export const EDIT_FOLDER_FAILED = "EDIT_FOLDER_REJECTED"

export const TOGGLE_MOVE_FOLDER = "TOGGLE_MOVE_FOLDER"

export const GET_FOLDERS = "GET_FOLDERS"
export const GET_FOLDERS_PENDING = "GET_FOLDERS_PENDING"
export const GET_FOLDERS_FAILED = "GET_FOLDERS_REJECTED"
export const GET_FOLDERS_FULFILLED = "GET_FOLDERS_FULFILLED"

export const MOVE_INTO_FOLDER = "MOVE_INTO_FOLDER"
export const MOVE_INTO_FOLDER_PENDING = "MOVE_INTO_FOLDER_PENDING"
export const MOVE_INTO_FOLDER_FULFILLED = "MOVE_INTO_FOLDER_FULFILLED"
export const MOVE_INTO_FOLDER_FAILED = "MOVE_INTO_FOLDER_REJECTED"

export const DELETE_FOLDER = "DELETE_FOLDER"
export const DELETE_FOLDER_PENDING = "DELETE_FOLDER_PENDING"
export const DELETE_FOLDER_FULFILLED = "DELETE_FOLDER_FULFILLED"
export const DELETE_FOLDER_FAILED = "DELETE_FOLDER_REJECTED"
