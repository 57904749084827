import {
  ADD_ASPERA_DOWNLOAD_LINK_TRANSFER,
  UPDATE_ASPERA_DOWNLOAD_LINK_TRANSFER,
  REMOVE_ASPERA_DOWNLOAD_LINK_TRANSFER,
  PACKAGE_AND_ASPERA_DOWNLOAD
} from "@actions/download_link/"
import dayjs from "dayjs"
import uuidv4 from "uuid/v4"
import { POST_DEFAULTS, PUT_DEFAULTS } from "@constants"
import { TOGGLE_ASPERA_MODAL } from "@actions/modals"
import { GLOBALS } from "@src/config"

export const packageAndAsperaDownload = (projectID, files) => dispatch => {
  dispatch({
    type: PACKAGE_AND_ASPERA_DOWNLOAD,
    files
  })

  const sdkLocation = "//d3gcli72yxqn2z.cloudfront.net/connect/v4"

  // fetch the download details
  fetch(`${GLOBALS.API_URL}/projects/${projectID}/packages/download`, {
    ...POST_DEFAULTS,
    body: JSON.stringify({
      files: files
    })
  })
    .then(res => res.json())
    .then(res => {
      if (res.aspera_entitlements_empty) {
        dispatch({ type: TOGGLE_ASPERA_MODAL })
        return
      }

      const uuid = uuidv4()

      // Create the AW4 Connect instance
      const asperaWeb = new AW4.Connect({
        sdkLocation,
        minVersion: "3.6.0",
        id: uuid
      })

      // Initialize the AW4 Connect instance
      asperaWeb.initSession(uuid)

      const asperaInstaller = new AW4.ConnectInstaller({ sdkLocation })

      // Aspera client installer event listener
      asperaWeb.addEventListener(AW4.Connect.EVENT.STATUS, (event, status) => {
        if (event === AW4.Connect.EVENT.STATUS) {
          switch (status) {
            case "INITIALIZING":
              asperaInstaller.showLaunching()
              break
            case "FAILED":
              asperaInstaller.showDownload()
              break
            case "OUTDATED":
              asperaInstaller.showUpdate()
              break
            case "RUNNING":
              asperaInstaller.connected()
              break
            case "RETRYING":
              break
          }
        }
      })

      // Transfer event listener
      asperaWeb.addEventListener("transfer", (e, asperaObject) => {
        dispatch(asperaDownloadLinkTransferEvents(e, asperaObject, uuid))
      })

      const opts = {
        title: "Select Save Location",
        suggestedName: res.download_details.file_name
      }

      const callBacks = {
        success: path => {
          if (!path.dataTransfer.files.length) {
            asperaWeb.stop()
            dispatch({
              type: REMOVE_ASPERA_DOWNLOAD_LINK_TRANSFER,
              fileID: res.download_details._id
            })
            return
          }

          for (let i = 0; i < res.aspera_setup.transfer_specs.length; i++) {
            asperaWeb.startTransfer(
              {
                ...res.aspera_setup.transfer_specs[i].transfer_spec,
                destination_root: path.dataTransfer.files[0].name,
                authentication: "token",
                create_dir: true
              },
              {
                allow_dialogs: "no",
                use_absolute_destination_path: true,
                file_id: res.download_details._id,
                aspera_download_id: res.download_details._id
              }
            )
          }
        },
        error: () => {
          //internalError(dispatch, id, "There was an internal error")
        }
      }

      asperaWeb.showSaveFileDialog(callBacks, opts)
      window.asperaWeb = asperaWeb
    })
}

/**
 * Initializes Aspera download link setup
 *
 * @param {String} packageID
 * @param {Object} file
 * @returns {Function}
 */
export const addAsperaDownloadLinkTransfer = (packageID, file) => dispatch => {
  // Add the transfer to redux state
  dispatch({
    type: ADD_ASPERA_DOWNLOAD_LINK_TRANSFER,
    fileID: file._id
  })

  const sdkLocation = "//d3gcli72yxqn2z.cloudfront.net/connect/v4"

  // fetch the download details
  fetch(`${GLOBALS.API_URL}/downloads/`, {
    ...POST_DEFAULTS,
    body: JSON.stringify({
      method: "Aspera",
      fileID: file._id,
      fileType: file.type,
      specifications: {
        package_id: packageID
      }
    })
  })
    .then(res => res.json())
    .then(res => {
      if (res.aspera_entitlements_empty) {
        dispatch({ type: TOGGLE_ASPERA_MODAL })
        return
      }

      const uuid = uuidv4()

      // Create the AW4 Connect instance
      const asperaWeb = new AW4.Connect({
        sdkLocation,
        minVersion: "3.6.0",
        id: uuid
      })

      // Initialize the AW4 Connect instance
      asperaWeb.initSession(uuid)

      const asperaInstaller = new AW4.ConnectInstaller({ sdkLocation })

      // Aspera client installer event listener
      asperaWeb.addEventListener(AW4.Connect.EVENT.STATUS, (event, status) => {
        if (event === AW4.Connect.EVENT.STATUS) {
          switch (status) {
            case "INITIALIZING":
              asperaInstaller.showLaunching()
              break
            case "FAILED":
              asperaInstaller.showDownload()
              break
            case "OUTDATED":
              asperaInstaller.showUpdate()
              break
            case "RUNNING":
              asperaInstaller.connected()
              break
            case "RETRYING":
              break
          }
        }
      })

      // Transfer event listener
      asperaWeb.addEventListener("transfer", (e, asperaObject) => {
        dispatch(asperaDownloadLinkTransferEvents(e, asperaObject, uuid))
      })

      const opts = {
        title: "Select Save Location",
        suggestedName: res.download_details.file_name
      }

      const callBacks = {
        success: path => {
          if (!path.dataTransfer.files.length) {
            asperaWeb.stop()
            dispatch({
              type: REMOVE_ASPERA_DOWNLOAD_LINK_TRANSFER,
              fileID: file._id
            })
            return
          }

          asperaWeb.startTransfer(
            {
              ...res.aspera_setup.transfer_specs[0].transfer_spec,
              destination_root: path.dataTransfer.files[0].name,
              authentication: "token",
              create_dir: file.type === "folder" || file.type === "dcp"
            },
            {
              allow_dialogs: "no",
              use_absolute_destination_path: true,
              file_id: file._id,
              aspera_download_id: res.download_details._id
            }
          )
        },
        error: () => {
          //internalError(dispatch, id, "There was an internal error")
        }
      }

      asperaWeb.showSaveFileDialog(callBacks, opts)
      window.asperaWeb = asperaWeb
    })
}

/**
 * The transfer events supplied by Aspera client
 *
 * @param {Object} event The event supplied by Aspera
 * @param {Object} asperaObject Includes of the trasnfers currently in the Aspera Client.
 * There could be many transfers not included in this download link (ie. another website running Aspera)
 * We have to filter out to find the correct transfers
 * @param {String} uuid
 * @returns {Function}
 */

const asperaDownloadLinkTransferEvents = (event, asperaObject, uuid) => (dispatch, getState) => {
  asperaObject.transfers
    .filter(transfer => transfer.aspera_connect_settings.app_id === uuid)
    .forEach(transfer => {
      const fileID = transfer.aspera_connect_settings.file_id
      const asperaDownloadID = transfer.aspera_connect_settings.aspera_download_id
      switch (transfer.status) {
        case "failed":
          return dispatch({
            type: UPDATE_ASPERA_DOWNLOAD_LINK_TRANSFER,
            fileID,
            payload: {
              status: "ERROR",
              error_message: transfer.error_desc
            }
          })
        case "completed":
          fetch(`${GLOBALS.API_URL}/downloads/${asperaDownloadID}`, {
            ...PUT_DEFAULTS,
            body: JSON.stringify({
              transferUUID: transfer.uuid,
              total_size: transfer.bytes_expected,
              status: "Complete"
            })
          })
          return dispatch({
            type: UPDATE_ASPERA_DOWNLOAD_LINK_TRANSFER,
            fileID,
            payload: { status: "COMPLETE" }
          })

        case "initiating":
          fetch(`${GLOBALS.API_URL}/downloads/${asperaDownloadID}`, {
            ...PUT_DEFAULTS,
            body: JSON.stringify({
              transferUUID: transfer.uuid,
              status: "Initializing",
              fileID
            })
          })
          return dispatch({
            type: UPDATE_ASPERA_DOWNLOAD_LINK_TRANSFER,
            fileID,
            payload: {
              status: "INITIALIZING",
              aspera_uuid: transfer.uuid,
              transfer_iteration_token: 0
            }
          })
        case "running":
          if (getState().download_link.aspera_downloads[fileID].transfer_iteration_token % 30 === 0) {
            fetch(`${GLOBALS.API_URL}/downloads/${asperaDownloadID}`, {
              ...PUT_DEFAULTS,
              body: JSON.stringify({
                transferUUID: transfer.uuid,
                total_size: transfer.bytes_expected,
                current_size: transfer.bytes_written,
                status: "Transferring"
              })
            })
          }
          return dispatch({
            type: UPDATE_ASPERA_DOWNLOAD_LINK_TRANSFER,
            fileID,
            payload: {
              status: "RUNNING",
              total_size: transfer.bytes_expected,
              current_size: transfer.bytes_written,
              percentage: Math.round((transfer.bytes_written / transfer.bytes_expected) * 100),
              time_remaining: dayjs().to(dayjs().add(transfer.remaining_usec / 1000, "milliseconds")),
              speed: Math.ceil(transfer.calculated_rate_kbps / 1024),
              aspera_uuid: transfer.uuid
            }
          })

        case "cancelled":
          return dispatch({
            type: UPDATE_ASPERA_DOWNLOAD_LINK_TRANSFER,
            fileID,
            payload: { status: "PAUSED" }
          })

        case "removed":
          return dispatch({
            type: REMOVE_ASPERA_DOWNLOAD_LINK_TRANSFER,
            fileID
          })

        default: {
          break
        }
      }
    })
}
