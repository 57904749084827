import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateAsperaSharing, sendAsperaLinks, updateAsperaShareLabel, getAsperaOrder } from "@api/project/files/modals"
import { toggleGiftCard } from "@api/account/cart"
import Aspera from "@src/public/aspera.png"
import { Creatable } from "react-select"
import { validateEmail } from "@api/account/"
import CreditCardLine from "@components/payment/credit_card_line"
import GiftCardLine from "@components/payment/gift_card_line"
import PostPayLine from "@components/payment/post_pay_line"
import { getPaymentMethods, updateNewPaymentMethod, updatePaymentMethod } from "@api/account/billing"
import NewPaymentMethod from "@components/settings/billing/new"
import { Modal, Status } from "@bitcine/cs-theme"
import { GLOBALS } from "@src/config"

class AsperaSharing extends React.Component {
  componentDidMount() {
    this.props.getPaymentMethods()
  }
  render() {
    const {
      updateAsperaSharing,
      aspera,
      newPaymentMethodVisible,
      updateNewPaymentMethod,
      paymentMethods: {
        list: { can_post_pay, using_post_pay },
        list,
        status,
        activeGiftCards,
        giftCardTotal,
        giftCardsBeingUsed
      },
      getAsperaOrder,
      sendAsperaLinks,
      updateAsperaShareLabel,
      toggleGiftCard,
      updatePaymentMethod
    } = this.props
    let totalGB = 0
    aspera.files.forEach(f => (totalGB += f.size || 0))
    return newPaymentMethodVisible ? (
      <NewPaymentMethod disableGiftCards={true}/>
    ) : (
      <Modal
        title={
          <h4 className='flex items-center flex-auto mb0'>
            <img src={Aspera} alt='Aspera' style={{ width: "35px" }} className='cs-img mr1'/>
            Send an Aspera download link
          </h4>
        }
        open
        onClose={() => updateAsperaSharing("visible", false)}>
        <Status
          errorMessage={aspera.errorMessage}
          pending={!aspera.item || aspera.status === "PENDING" || status === "PENDING"}
          error={aspera.status === "ERROR"}>
          {aspera.status === "COMPLETE" ? (
            <div className='py4 center'>
              <span className='material-icons red large-icon'>check_circle</span>
              <h4>Success!</h4>
              <div className='pt2'>
                <div>Aspera links have been sent to the following email(s):</div>
                <div>
                  {aspera.emails.map((email, index) =>
                    <div key={index} className='bold'>
                      {email.label}
                    </div>)}
                </div>
              </div>
              <div className='py2'>
                <div>The following content is being shared:</div>
                <div>
                  {aspera.files.map((file, index) =>
                    <div key={index} className='bold'>
                      {file.name}
                    </div>)}
                </div>
              </div>
              <a target='_blank' rel='noopener noreferrer' href={`${GLOBALS.API_URL}/settings/invoices/${aspera.invoice_id}`}>
                <button className='cs-button mt1'>Print Invoice</button>
              </a>
            </div>
          ) : aspera.order === null ? (
            <div>
              <div className='pb2 border-bottom border-gray-5'>
                <p>
                  Send high-speed transfer links directly from your CineSend account to your customers with Aspera, the
                  industry standard in high-speed transfers.
                </p>
                <div>
                  {aspera.item ? (
                    <b>{aspera.item.price_formatted} per GB</b>
                  ) : (
                    <div className='spinner spinner-small my2'/>
                  )}
                </div>
              </div>
              <div className='py2'>
                {aspera.files.length > 1 && (
                  <div>
                    <h4 className='flex-auto'>Package Name</h4>
                    <div className='mb2 pb3 border-bottom border-gray-5'>
                      <label className='cs-label'>
                        Package name: <sup className='muted red'>* Required</sup>
                      </label>
                      <input
                        type='text'
                        className='cs-input col-12'
                        value={aspera.label}
                        onChange={({ target: { value } }) => updateAsperaShareLabel(value)}
                        placeholder='Citizen Kane - Source files'/>
                    </div>
                  </div>
                )}
                <div className='mb2 pb2 border-bottom border-gray-5'>
                  <p>Enter the email addresses you would like to send download links to.</p>
                  <label className='cs-label'>To:</label>
                  <Creatable
                    multi={true}
                    disabled={!aspera.item}
                    options={[]}
                    placeholder='Type an email address...'
                    promptTextCreator={string => `Add email: ${string}`}
                    isValidNewOption={({ label }) => validateEmail(label)}
                    onChange={val => {
                      updateAsperaSharing("emails", val)
                    }}
                    value={aspera.emails}/>
                </div>
                <div>
                  <label className='cs-label' htmlFor='password'>
                    Add a password (Optional):
                  </label>
                  <input
                    id='password'
                    className='cs-input col-12'
                    value={aspera.password}
                    onChange={e => updateAsperaSharing("password", e.target.value)}/>
                </div>
              </div>
              <div className='right-align'>
                <button
                  onClick={getAsperaOrder}
                  className={`cs-button ${aspera.status === "PENDING" ? "pending" : ""}`}
                  disabled={
                    aspera.status === "PENDING" ||
                    aspera.emails.length === 0 ||
                    (aspera.files.length > 1 && !aspera.label.length)
                  }>
                  Next
                </button>
              </div>
            </div>
          ) : aspera.order ? (
            <div>
              <div className='flex items-center justify-between'>
                <h4>Payment Method</h4>
                <button onClick={() => updateNewPaymentMethod("visible", true)} className='cs-button white small'>
                  Add payment method
                </button>
              </div>
              <div className='pt2'>
                {list.credit_cards.map(card => (
                  <CreditCardLine
                    key={card._id}
                    card={card}
                    postPay={using_post_pay}
                    checked={aspera.credit_card_id === card._id}
                    onSelect={() => {
                      if (aspera.credit_card_id === card._id) {
                        updateAsperaSharing("credit_card_id", "")
                      } else {
                        updateAsperaSharing("credit_card_id", card._id)
                      }
                    }}/>
                ))}
                {list.gift_cards.map((card, index) => (
                  <GiftCardLine
                    key={card._id}
                    card={card}
                    postPay={using_post_pay}
                    checked={card.is_payment_active}
                    onSelect={() => toggleGiftCard(card._id)}/>
                ))}
                {can_post_pay && (
                  <PostPayLine
                    checked={using_post_pay}
                    onSelect={() => {
                      updatePaymentMethod("using_post_pay", !using_post_pay)
                      updateAsperaSharing("credit_card_id", "")
                      {
                        activeGiftCards.map(card => toggleGiftCard(card._id))
                      }
                    }}/>
                )}
              </div>
              {!aspera.credit_card_id && giftCardsBeingUsed && giftCardTotal < aspera.total && (
                <div className='red bold flex items-center ml2'>
                  <span className='material-icons mr1'>error</span>
                  Your gift card has insufficient funds for this transaction. A credit card is also required.
                </div>
              )}
              <div className='my2 flex items-center'>
                <div className='col-8'/>
                <div className='col-4'>
                  <div className='flex items-center justify-between'>
                    <span>Subtotal:</span>
                    <span>{aspera.order.subtotal}</span>
                  </div>
                  <div className='flex items-center justify-between'>
                    <span>Tax:</span>
                    <span>{aspera.order.tax_total}</span>
                  </div>
                  <div className='flex items-center justify-between'>
                    <span>Total:</span>
                    <span>{aspera.order.total}</span>
                  </div>
                </div>
              </div>
              <div className='right-align'>
                <button onClick={() => updateAsperaSharing("order", null)} className='cs-button mr2'>
                  Back
                </button>
                <button
                  onClick={sendAsperaLinks}
                  className={`cs-button ${aspera.status === "PENDING" ? "pending" : ""}`}
                  disabled={
                    (!aspera.credit_card_id && !giftCardsBeingUsed && !using_post_pay) ||
                    (!aspera.credit_card_id && giftCardsBeingUsed && giftCardTotal < aspera.total) ||
                    aspera.status === "PENDING" ||
                    aspera.emails.length === 0 ||
                    (aspera.files.length > 1 && !aspera.label.length)
                  }>
                  Purchase and Send Links
                </button>
              </div>
            </div>
          ) : null}
        </Status>
      </Modal>
    )
  }
}

AsperaSharing.propTypes = {
  updateAsperaSharing: PropTypes.func.isRequired,
  aspera: PropTypes.object.isRequired,
  updateNewPaymentMethod: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  paymentMethods: PropTypes.object.isRequired,
  newPaymentMethodVisible: PropTypes.bool.isRequired,
  sendAsperaLinks: PropTypes.func.isRequired,
  getAsperaOrder: PropTypes.func.isRequired,
  updateAsperaShareLabel: PropTypes.func.isRequired,
  toggleGiftCard: PropTypes.func.isRequired,
  activeGiftCards: PropTypes.array.isRequired,
  updatePaymentMethod: PropTypes.func.isRequired
}

const mapStateToProps = (state, context) => ({
  aspera: state.project.files.asperaSharing,
  paymentMethods: state.user.billing.paymentMethods,
  newPaymentMethodVisible: state.user.billing.newPaymentMethod.visible
})

const mapDispatchToProps = dispatch => ({
  updateAsperaSharing: bindActionCreators(updateAsperaSharing, dispatch),
  toggleGiftCard: bindActionCreators(toggleGiftCard, dispatch),
  updatePaymentMethod: bindActionCreators(updatePaymentMethod, dispatch),
  updateNewPaymentMethod: bindActionCreators(updateNewPaymentMethod, dispatch),
  getPaymentMethods: bindActionCreators(getPaymentMethods, dispatch),
  sendAsperaLinks: bindActionCreators(sendAsperaLinks, dispatch),
  getAsperaOrder: bindActionCreators(getAsperaOrder, dispatch),
  updateAsperaShareLabel: bindActionCreators(updateAsperaShareLabel, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AsperaSharing)
