import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import "../styles.scss"
import Preview from "./components/preview"
import Encoding from "./components/encoding"
import Unencoded from "./components/unencoded"
import ForensicWatermark from "./components/forensic_watermark"

const Screener = ({ screener }) => (
  <div className={`videoWrap flex items-center justify-center`}>
    {screener.is_available && screener.use_forensic_watermark ? (
      <ForensicWatermark/>
    ) : screener.is_available ? (
      <Preview screener={screener}/>
    ) : screener.is_active ? (
      <Encoding screener={screener}/>
    ) : (
      <Unencoded screener={screener}/>
    )}
  </div>
)

const mapStateToProps = state => ({
  screener: state.project.files.screener
})

Screener.propTypes = {
  screener: PropTypes.object,
  encodeStatus: PropTypes.string
}

export default connect(mapStateToProps)(Screener)
