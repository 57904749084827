import {
  GET_MFA_QRCODE_PENDING,
  GET_MFA_QRCODE_FULFILLED,
  UPDATE_MFA_CODE,
  VALIDATE_MFA_CODE_PENDING,
  VALIDATE_MFA_CODE_FULFILLED,
  VALIDATE_MFA_CODE_FAILED
} from "@actions/account/mfa"

const initialState = {
  qrCodeImage: "",
  status: "",
  code: "",
  validationStatus: ""
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_MFA_QRCODE_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_MFA_QRCODE_FULFILLED:
      return {
        ...state,
        status: "SUCCESS",
        code: "",
        validationStatus: "",
        qrCodeImage: action.payload.mfa_qr_code
      }

    case UPDATE_MFA_CODE:
      return {
        ...state,
        code: action.payload.code
      }

    case VALIDATE_MFA_CODE_PENDING:
      return {
        ...state,
        validationStatus: "PENDING"
      }

    case VALIDATE_MFA_CODE_FULFILLED:
      return {
        ...state,
        validationStatus: action.payload.success ? "SUCCESS" : "FAILED"
      }

    case VALIDATE_MFA_CODE_FAILED:
      return {
        ...state,
        validationStatus: "FAILED"
      }

    default:
      return state
  }
}
