import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"

dayjs.extend(LocalizedFormat)

const formatDate = (date, includeTime = true) =>
  dayjs
    .utc(date)
    .local()
    .format(includeTime ? "LLL" : "LL")

export default formatDate
