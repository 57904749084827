import React from "react"
import { hot, setConfig } from "react-hot-loader"
import Pusher from "pusher-js"
import { setPusherClient } from "@components/pusher"
import DevTools from "@src/redux/tools/"
import Routes from "./routes"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import dayjsPluginUTC from "dayjs-plugin-utc"
import relativeTime from "dayjs/plugin/relativeTime"
import ReactHintFactory from "react-hint"
import { GLOBALS } from "./config"
import '@src/index.css'
import '@bitcine/cs-theme/dist/index.scss'
import './styles/index.scss'
import 'styled-components/macro'

const ReactHint = ReactHintFactory(React)

// This line is needed to allow react-hot-loader to work with React Hooks
setConfig({ pureSFC: true })

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)
dayjs.extend(dayjsPluginUTC)

Pusher.logToConsole = GLOBALS.PUSHER_DEBUG
const pusherClient = new Pusher(GLOBALS.PUSHER_KEY, {
  encrypted: true,
  cluster: "mt1",
  authEndpoint: `${GLOBALS.API_URL}/pusher/auth`,
  authTransport: "jsonp"
})

setPusherClient(pusherClient)

class Root extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Routes/>
        {process.env.NODE_ENV === "development" && (
          <span>
            <DevTools/>
          </span>
        )}
        <ReactHint autoPosition events/>
      </React.Fragment>
    )
  }
}

export default hot(module)(Root)
