import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { TOGGLE_ASPERA_UPGRADE_MESSAGE } from "@actions/utils/"
import Logo from "@src/public/aspera/upgrade_screen_logo.png"
import Example from "@src/public/aspera/upgrade_screen_example.png"
import { GLOBALS } from "@src/config"
import 'styled-components/macro'

const AsperaUpgradeMessage = ({ show, toggle }) => {
  if (!show) return null
  else
    return (
      <div
        className='fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center px3 py2'
        css={`
          background: radial-gradient(circle, rgba(76, 81, 88, 0.8) 0%, #35393f 100%);
          z-index: 1000;
        `}>
        <div
          css={`
            max-width: 514px;
          `}
          className='white'>
          <div className='center'>
            <h2
              css={`
                margin-bottom: -0.75em;
                font-weight: 400;
              `}
              className='white'>
              Faster uploads with
            </h2>
            <img src={Logo} className='cs-img block mx-auto mb1' alt='Aspera logo'/>
            <img
              src={Example}
              css={`
                @media all and (max-height: 544px) {
                  display: none;
                }
              `}
              className='cs-img block mx-auto'
              alt='Aspera upload interface'/>
          </div>
          <div className='px1'>
            <p
              css={`
                margin-bottom: 0;
              `}>
              CineSend partners with IBM&apos;s Aspera to provide you with the same industrial transfer technology used
              by the biggest media companies in the world. Aspera makes uploading and downloading large files easy and
              reliable, even if you have a poor internet connection.
              <br/>
              <br/>
              To use Aspera, you will need to install a small app on your computer that will run in the background while
              a file is uploading or downloading. From inside the app, you can pause and resume transfers.
            </p>
          </div>
          <div className='center mt2'>
            <a href={GLOBALS.ACCOUNTS_URL + '/settings/billing'} target='_blank' rel='noopener noreferrer'>
              <button
                className='cs-button'
                css={`
                  background: #00b2ef;
                  :hover,
                  :focus,
                  :active {
                    background: #00b2ef;
                  }
                `}>
                Upgrade to upload with Aspera
              </button>
            </a>
            <div className='mt1 center'>
              <button
                css={`
                  color: white !important;
                `}
                onClick={toggle}
                className='cs-button link'>
                Maybe Later
              </button>
            </div>
          </div>
        </div>
      </div>
    )
}

AsperaUpgradeMessage.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  show: state.utils.show_aspera_upgrade_message,
  toggle: PropTypes.func.isRequired
})

const mapDispatchToProps = dispatch => ({
  toggle: () => dispatch({ type: TOGGLE_ASPERA_UPGRADE_MESSAGE })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AsperaUpgradeMessage)
