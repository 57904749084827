import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Transfer from "./transfer"

const Transfers = ({ transfers }) =>
  Object.keys(transfers).length > 0 && (
    <div className='max-width-3 mx-auto mt2'>
      <strong className='block muted mb1'>Active Uploads ({Object.keys(transfers).length})</strong>
      {Object.keys(transfers).map(key => (
        <Transfer key={key} transfer={transfers[key]}/>
      ))}
    </div>
  )

Transfers.propTypes = {
  transfers: PropTypes.object.isRequired
}

const mapStateToProps = ({ transfers }) => ({
  transfers
})

export default connect(
  mapStateToProps,
  null
)(Transfers)
