import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { getFiles, resetPagination } from "@api/project/files/"
import { getFileAndFolderDropdownOptions } from "@api/project/files/utils/dropdown_options"
import { isFileDisabled, onCheck, onClick, onHeaderCheck } from "@api/project/files/utils/file_actions"
import { resetFilter } from "@api/project/filter"
import ErrorBoundary from "@components/error_boundaries/"
import { Status } from "@bitcine/cs-theme"
import RowDragger from "@src/components/project/files/components/dnd/dragger"
import ScrollableDND from "@src/components/project/files/components/dnd/scrollable_dnd"
import GridView from "./components/views/grid"
import RowView from "./components/views/row"
import NewFolder from "./components/folder/new"
import DeleteFiles from "./modals/delete"
import FileDND from "./components/dnd/file_dnd"
import Header from "./components/header"
import 'styled-components/macro'

class Files extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keys: []
    }
  }
  componentDidMount() {
    this.props.getFiles(this.props.match.params.projectID, this.props.match.params.folderID)
    document.addEventListener("click", this.onClick)
    document.addEventListener("keydown", this.onKeyDown)
    document.addEventListener("keyup", this.onKeyUp)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown)
    document.removeEventListener("keyup", this.onKeyUp)
    document.removeEventListener("click", this.onClick)
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.projectID !== this.props.match.params.projectID
      || prevProps.match.params.folderID !== this.props.match.params.folderID) {
      this.props.getFiles(this.props.match.params.projectID, this.props.match.params.folderID)
      this.props.resetPagination()
      this.props.resetFilter()
    }
  }
  onClick = e => {
    this.parseKeys(e)
  }
  onKeyDown = e => {
    this.parseKeys(e)
  }
  onKeyUp = e => {
    this.setState({ keys: [] })
  }
  parseKeys(e) {
    const keys = ["shiftKey", "metaKey", "ctrlKey"]
    this.setState({
      keys: keys.filter(key => e[key])
    })
  }
  render() {
    const { pagination } = this.props
    const projectID = this.props.match.params.projectID
    const folderID = this.props.match.params.folderID 
    return (
      <ErrorBoundary>
        <div
          css={`
            margin-right: ${this.props.dialogOpen ? "400px" : "0"};
          `}
          className='p2'>
          <Header folderID={folderID} projectID={projectID}/>
          <Status pending={this.props.files.status === "PENDING"} error={this.props.files.status === "ERROR"}>
            <FileDND folderID={folderID} projectID={projectID}>
              <ScrollableDND>
                {this.props.view === "grid" ? (
                  <GridView
                    onHeaderCheck={() => this.props.onHeaderCheck()}
                    projectID={projectID}
                    isRowDisabled={status => isFileDisabled(status)}
                    onRowClick={(e, file) => this.props.onClick(e, file)}
                    getMenuOptions={file => this.props.getFileAndFolderDropdownOptions(file)}
                    onRowCheck={file => this.props.onCheck(file, this.state.keys)}/>
                ) : (
                  <RowView
                    onHeaderCheck={() => this.props.onHeaderCheck()}
                    projectID={projectID}
                    isRowDisabled={status => isFileDisabled(status)}
                    onRowClick={(e, file) => this.props.onClick(e, file)}
                    getMenuOptions={file => this.props.getFileAndFolderDropdownOptions(file)}
                    onRowCheck={file => this.props.onCheck(file, this.state.keys)}/>
                )}
              </ScrollableDND>
            </FileDND>
            {(pagination.size - pagination.take > pagination.skip ||
              pagination.skip < pagination.size - pagination.take) && (
              <div className='flex items-stretch justify-center py2 list-style-none mt2 cs-paginate mb4'>
                <button
                  className='cs-button white small mr1'
                  disabled={pagination.skip === 0}
                  onClick={() => {
                    this.props.getFiles(
                      projectID,
                      folderID,
                      pagination.skip - pagination.take
                    )
                  }}>
                  Prev
                </button>
                <button
                  className='cs-button white small'
                  disabled={pagination.skip >= pagination.size - pagination.take}
                  onClick={() => {
                    this.props.getFiles(
                      projectID,
                      folderID,
                      pagination.skip + pagination.take
                    )
                  }}>
                  Next
                </button>
              </div>
            )}
          </Status>
          {this.props.modals.delete.visible && <DeleteFiles/>}
          <RowDragger folderID={folderID} projectID={projectID}/>
          <NewFolder folderID={folderID}/>
        </div>
        {this.props.children}
      </ErrorBoundary>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getFiles: bindActionCreators(getFiles, dispatch),
  resetFilter: bindActionCreators(resetFilter, dispatch),
  getFileAndFolderDropdownOptions: bindActionCreators(getFileAndFolderDropdownOptions, dispatch),
  onHeaderCheck: bindActionCreators(onHeaderCheck, dispatch),
  onCheck: bindActionCreators(onCheck, dispatch),
  onClick: bindActionCreators(onClick, dispatch),
  resetPagination: bindActionCreators(resetPagination, dispatch)
})

const mapStateToProps = state => ({
  modals: state.project.files.modals,
  view: state.user.auth.file_view_type || "grid",
  files: state.project.files.items,
  pagination: state.project.files.items.pagination,
  dialogOpen: state.utils.dialogOpen
})

Files.propTypes = {
  getFiles: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  files: PropTypes.object.isRequired,
  resetFilter: PropTypes.func.isRequired,
  modals: PropTypes.object.isRequired,
  folderID: PropTypes.string,
  children: PropTypes.object,
  dialogOpen: PropTypes.bool.isRequired,
  getFileAndFolderDropdownOptions: PropTypes.func.isRequired,
  view: PropTypes.string,
  onHeaderCheck: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  resetPagination: PropTypes.func.isRequired
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Files))
