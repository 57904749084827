import {
  GET_CART_COUNT_FULFILLED,
  GET_CART_PENDING,
  GET_CART_FAILED,
  GET_CART_FULFILLED,
  UPDATE_CART_ITEM,
  DELETE_CART_ITEM_FULFILLED,
  UPDATE_CART,
  TOGGLE_GIFT_CARD_PENDING,
  TOGGLE_GIFT_CARD_FULFILLED,
  APPLY_PROMO_PENDING,
  APPLY_PROMO_FULFILLED,
  APPLY_PROMO_FAILED,
  PURCHASE_CART_PENDING,
  PURCHASE_CART_FULFILLED,
  PURCHASE_CART_FAILED,
  GET_SHIPPING_RATES_PENDING,
  GET_SHIPPING_RATES_FAILED,
  GET_SHIPPING_RATES_FULFILLED,
  SET_SHIPPING_RATE_PENDING,
  SET_SHIPPING_RATE_FAILED,
  SET_SHIPPING_RATE_FULFILLED
} from "@actions/account/cart"

import { ADD_CREDIT_CARD_FULFILLED } from "@actions/account/billing"

import { ADD_ORDER_TO_CART_FULFILLED } from "@actions/orders"

const steps = [
  { label: "Shipping", to: "/cart/shipping" },
  { label: "Payment", to: "/cart/payment" },
  { label: "Confirm Order", to: "/cart/confirmation" }
]

const initialState = {
  status: "PENDING",
  step: "shipping",
  steps,
  count: null,
  promo: {
    value: "",
    status: "READY"
  },
  newAddress: {
    search: "",
    visible: false,
    step: "address"
  },
  error: {
    code: "",
    message: ""
  },
  willPickup: false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CART_COUNT_FULFILLED:
      return {
        ...state,
        count: action.payload.count
      }

    case ADD_ORDER_TO_CART_FULFILLED:
      return {
        ...state,
        count: action.payload.cart_count
      }

    case GET_CART_PENDING:
      return {
        ...initialState,
        count: state.count
      }

    case GET_CART_FULFILLED:
      return {
        ...state,
        status: "READY",
        ...action.payload
      }

    case GET_CART_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case ADD_CREDIT_CARD_FULFILLED:
      return {
        ...state,
        credit_card_id: action.payload.new_credit_card_id
      }

    case UPDATE_CART_ITEM:
      return {
        ...state,
        orders: state.orders.map(order =>
          order._id === action.orderID
            ? {
              ...order,
              [action.key]: action.value
            }
            : order
        )
      }

    case DELETE_CART_ITEM_FULFILLED:
      return {
        ...state,
        ...action.payload
      }

    case UPDATE_CART:
      return {
        ...state,
        [action.key]: action.value
      }

    case TOGGLE_GIFT_CARD_PENDING:
      return {
        ...state
      }

    case TOGGLE_GIFT_CARD_FULFILLED:
      return {
        ...state,
        ...action.payload.orders
      }

    case APPLY_PROMO_PENDING:
      return {
        ...state,
        promo: {
          ...state.promo,
          status: "PENDING"
        }
      }

    case APPLY_PROMO_FAILED:
      return {
        ...state,
        promo: {
          ...state.promo,
          status: "ERROR"
        }
      }

    case APPLY_PROMO_FULFILLED:
      return {
        ...state,
        orders: action.payload.orders || state.orders,
        payment_total_formatted: action.payload.payment_total_formatted || state.payment_total_formatted,
        shipping_total_formatted: action.payload.shipping_total_formatted || state.payment_total_formatted,
        subtotal_formatted: action.payload.subtotal_formatted || state.payment_total_formatted,
        tax_total_formatted: action.payload.tax_total_formatted || state.payment_total_formatted,
        total_formatted: action.payload.total_formatted || state.payment_total_formatted,
        promo: {
          ...state.promo,
          value: action.payload.promo_code || state.promo.value,
          status: "SUCCESS"
        }
      }

    case PURCHASE_CART_PENDING:
      return {
        ...state,
        status: "PURCHASING"
      }

    case PURCHASE_CART_FULFILLED:
      return {
        ...initialState,
        status: "PURCHASED",
        invoice: action.payload.new_invoice
      }

    case PURCHASE_CART_FAILED:
      return {
        ...state,
        status: "FAILED",
        error: {
          code: action.payload.code,
          message: action.payload.message
        }
      }

    case GET_SHIPPING_RATES_PENDING:
      return {
        ...state,
        newAddress: {
          ...state.newAddress,
          status: "PENDING"
        }
      }

    case GET_SHIPPING_RATES_FAILED:
      return {
        ...state,
        newAddress: {
          ...state.newAddress,
          status: "ERROR"
        }
      }

    case GET_SHIPPING_RATES_FULFILLED:
      return {
        ...state,
        newAddress: {
          ...state.newAddress,
          status: "READY",
          step: "rates",
          rates: action.payload
        }
      }

    case SET_SHIPPING_RATE_PENDING:
      return {
        ...state,
        newAddress: {
          ...state.newAddress,
          status: "PENDING"
        }
      }

    case SET_SHIPPING_RATE_FAILED:
      return {
        ...state,
        newAddress: {
          ...state.newAddress,
          status: "ERROR"
        }
      }

    case SET_SHIPPING_RATE_FULFILLED:
      return {
        ...state,
        newAddress: initialState.newAddress,
        addressError: false,
        ...action.payload
      }

    default:
      return state
  }
}
