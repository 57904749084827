export const GET_STORAGE_PLANS = "GET_STORAGE_PLANS"
export const GET_STORAGE_PLANS_PENDING = "GET_STORAGE_PLANS_PENDING"
export const GET_STORAGE_PLANS_FAILED = "GET_STORAGE_PLANS_REJECTED"
export const GET_STORAGE_PLANS_FULFILLED = "GET_STORAGE_PLANS_FULFILLED"

export const GET_STORAGE_PLAN_DETAILS = "GET_STORAGE_PLAN_DETAILS"
export const GET_STORAGE_PLAN_DETAILS_PENDING = "GET_STORAGE_PLAN_DETAILS_PENDING"
export const GET_STORAGE_PLAN_DETAILS_FAILED = "GET_STORAGE_PLAN_DETAILS_REJECTED"
export const GET_STORAGE_PLAN_DETAILS_FULFILLED = "GET_STORAGE_PLAN_DETAILS_FULFILLED"

export const UPDATE_NEW_PLAN = "UPDATE_NEW_PLAN"

export const CHANGE_PLAN = "CHANGE_PLAN"
export const CHANGE_PLAN_PENDING = "CHANGE_PLAN_PENDING"
export const CHANGE_PLAN_FAILED = "CHANGE_PLAN_REJECTED"
export const CHANGE_PLAN_FULFILLED = "CHANGE_PLAN_FULFILLED"
