import {
  TOGGLE_ACCESS_MODAL,
  SHOW_ACCESS_INVITES,
  HIDE_ACCESS_INVITES,
  ADD_ACCESS_INVITE_ROW,
  UPDATE_INVITE_ACCESS_ROW,
  RESET_ACCESS_INVITES,
  REMOVE_ACCESS_INVITE_ROW,
  INVITE_USERS_PENDING,
  INVITE_USERS_FULFILLED,
  INVITE_USERS_FAILED
} from "@actions/project/access/"

import update from "immutability-helper"

const defaultMemberInvite = {
  name: "",
  email: "",
  role_id: ""
}

const initialState = {
  modalOpen: false,
  project: {},
  showInvite: false,
  membersToInvite: [defaultMemberInvite],
  isSending: false,
  error: ""
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_ACCESS_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        project: !state.modalOpen ? action.payload.project : {}
      }

    case SHOW_ACCESS_INVITES:
      return {
        ...state,
        showInvite: true
      }

    case HIDE_ACCESS_INVITES:
      return {
        ...state,
        showInvite: false
      }

    case ADD_ACCESS_INVITE_ROW:
      return update(state, {
        membersToInvite: {
          $push: [defaultMemberInvite]
        }
      })

    case UPDATE_INVITE_ACCESS_ROW:
      return update(state, {
        membersToInvite: {
          [action.payload.i]: {
            [action.payload.target]: {
              $set: action.payload.value
            }
          }
        }
      })

    case RESET_ACCESS_INVITES:
      return update(state, {
        membersToInvite: {
          $set: [defaultMemberInvite]
        }
      })

    case REMOVE_ACCESS_INVITE_ROW:
      return update(state, {
        membersToInvite: {
          $splice: [[action.payload.i, 1]]
        }
      })

    case INVITE_USERS_PENDING:
      return {
        ...state,
        isSending: true,
        error: ""
      }

    case INVITE_USERS_FULFILLED:
      return {
        ...state,
        isSending: false,
        showInvite: false,
        membersToInvite: [defaultMemberInvite]
      }

    case INVITE_USERS_FAILED:
      return {
        ...state,
        isSending: false,
        error: action.payload.message
      }

    default:
      return state
  }
}
