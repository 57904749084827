import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getPackages, updatePackage, toggleModal } from "@api/project/packages"
import { Status, Table, ButtonDropdown } from "@bitcine/cs-theme"
import EmptySharedFilesIcon from "@src/public/icons/empty/shared_files.inline.svg"
import dayjs from "dayjs"
import copy from "copy-to-clipboard"
import { addGlobalMessage } from "@api/utils/"
import DayPickerModal from "@src/helpers/dayPickerModal"
import { push } from "connected-react-router"
import formatDate from "@src/helpers/formatDate"
import SharingIcons from "./components/sharing_icons"
import PasswordModal from "./modals/password"

class Packages extends React.Component {
  constructor() {
    super()
    this.state = {}
  }
  componentDidMount() {
    this.props.getPackages(this.props.match.params.fileID)
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.fileID !== this.props.match.params.fileID) {
      this.props.getPackages(this.props.match.params.fileID)
    }
  }
  render() {
    const { list, status, modal, toggleModal, match } = this.props
    return (
      <div className='p2'>
        <Status
          pending={status === "PENDING"}
          error={status === "ERROR"}
          empty={list.length === 0}
          emptyMessage={{
            icon: <EmptySharedFilesIcon/>,
            title: `You have not shared ${match.params.fileID ? "this file" : "content from this project"} yet.`,
            text: `Once you've shared ${
              match.params.fileID ? "this file" : "content from this project"
            }, return here to see sharing history.`
          }}>
          <Table
            widths={[90, "auto", 200, 165, 165, 65]}
            header={{
              columns: ["Details", "Content", "Shared To", "Expires", "Created", ""].map(opt => ({ text: opt }))
            }}
            body={{
              data: list,
              row: {
                render: [
                  data => <SharingIcons sharedPackage={data}/>,
                  data => (
                    <div>
                      {data.description}
                      <span className='small block light'>{data.kind}</span>
                    </div>
                  ),
                  data => data.shared_email || "Shared via Link",
                  data =>
                    data.is_expired ? (
                      <span className='red'>Expired</span>
                    ) : data.expires_at ? (
                      formatDate(data.expires_at, false)
                    ) : (
                      "No Expiry"
                    ),
                  data => formatDate(data.created_at, false),
                  data => (
                    <ButtonDropdown
                      button={{
                        className: "link material-icons",
                        style: { width: "48px" },
                        text: "more_vert"
                      }}
                      dropdown={{
                        clickCloses: true,
                        content: [
                          {
                            text: "Password Settings",
                            icon: "lock",
                            disabled: data.is_expired,
                            onClick: () => toggleModal("password", data),
                            show: true
                          },
                          {
                            text: "Expire Package",
                            icon: "block",
                            disabled: data.is_expired,
                            onClick: () => {
                              if (window.confirm("Are you sure you want to expire access to this file?")) {
                                this.props.updatePackage(data._id, "is_expired", true)
                              }
                            },
                            show: true
                          },
                          {
                            text: "Update Expiry Date",
                            icon: "date_range",
                            onClick: () => toggleModal("expiry", data),
                            show: true
                          },
                          {
                            text: "Copy URL",
                            icon: "link",
                            disabled: data.is_expired,
                            onClick: () => {
                              this.props.addGlobalMessage("Link Copied!", "success")
                              copy(data.url, { format: "text/plain" })
                            },
                            show: data.method !== "aspera"
                          }
                        ].filter(opt => opt.show)
                      }}/>
                  )
                ]
              }
            }}/>

          {modal.visible && modal.type === "password" && (
            <PasswordModal selectedPackage={modal.selectedPackage} onClose={() => this.toggleModal()}/>
          )}
          {modal.visible && modal.type === "expiry" && (
            <DayPickerModal
              title={"Change Expiry Date"}
              showCancel={true}
              expiry={dayjs(modal.selectedPackage.expires_at || dayjs()).toDate()}
              saveButtonText={"Save"}
              onSave={date => {
                this.props.updatePackage(modal.selectedPackage._id, "expires_at", date)
              }}
              onClose={() => this.toggleModal()}/>
          )}
        </Status>
      </div>
    )
  }
}

Packages.propTypes = {
  list: PropTypes.array.isRequired,
  size: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  getPackages: PropTypes.func.isRequired,
  updatePackage: PropTypes.func.isRequired,
  addGlobalMessage: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  modal: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired
}

const mapStateToProps = (state, context) => ({
  list: state.project.packages.list,
  size: state.project.packages.size,
  status: state.project.packages.status,
  project: state.project.details,
  modal: state.project.packages.modal
})

const mapDispatchToProps = dispatch => ({
  getPackages: bindActionCreators(getPackages, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  updatePackage: bindActionCreators(updatePackage, dispatch),
  addGlobalMessage: bindActionCreators(addGlobalMessage, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Packages)
