export const types = {
  "closed-captions": {
    type: "closed-captions",
    title: "Order Closed Captions",
    step: "file-picker",
    returnStep: "file-picker",
    files: [
      {
        type: "video_file",
        acceptedCineSendFileTypes: ["source_file", "dcp"],
        isRequired: true,
        description:
          "<p>Drag a video file to the box below to begin creating your closed captions!<br/>After our technicians complete your captions they will live in your account and can be shared or downloaded at any time.</p>"
      }
    ],
    error: false
  },
  dcp: {
    type: "dcp",
    title: "Order DCP",
    step: "file-picker",
    returnStep: "file-picker",
    files: [
      {
        type: "source_file",
        acceptedCineSendFileTypes: ["source_file"],
        isRequired: true,
        description:
          "<p><b>Required: </b>Drag and drop the source file from your files list that you would like to use to create a DCP.</p>"
      },
      {
        type: "audio_tracks",
        allow_multiple: true,
        acceptedCineSendFileTypes: ["audio_track"],
        description:
          "<p><b>Optional: </b>Add any additional files (audio tracks, subtitles, or closed captions) you would like to include on this DCP.</p>"
      },
      {
        type: "closed_caption_track",
        acceptedCineSendFileTypes: ["closed_caption"]
      },
      {
        type: "subtitle_track",
        acceptedCineSendFileTypes: ["subtitle_track"]
      }
    ],
    audio_tracks: [],
    tmpLength: "",
    error: false,
    use_custom_title: false,
    dcp_title: "",
    drive_type: "",
    drive_quantity: 1
  },
  disc: {
    type: "disc",
    title: "Order DVD / Blu-ray",
    step: "file-picker",
    returnStep: "file-picker",
    files: [
      {
        type: "source_file",
        isRequired: true,
        acceptedCineSendFileTypes: ["source_file"],
        description: "<p>Drag the source file from your files that is needed to create discs.</p>"
      },
      {
        type: "audio_tracks",
        allow_multiple: true,
        acceptedCineSendFileTypes: ["audio_track"],
        description:
          "<p><b>Optional: </b>Add any additional files (audio tracks, subtitles, or closed captions) you would like to include on these discs.</p>"
      },
      {
        type: "closed_caption_track",
        acceptedCineSendFileTypes: ["closed_caption"]
      },
      {
        type: "subtitle_track",
        acceptedCineSendFileTypes: ["subtitle_track"]
      }
    ],
    audio_tracks: [],
    error: false,
    disc_title: "",
    disc_type: "",
    dvd_quantity: 0,
    blu_ray_quantity: 0
  },
  "send-physical-copy": {
    type: "send-physical-copy",
    title: "Send on Hard Drive",
    step: "send-physical-copy-drives",
    returnStep: "send-physical-copy-drives",
    files: [
      {
        type: "video_file",
        acceptedCineSendFileTypes: ["source_file", "dcp"],
        isRequired: true,
        description:
          "<p>Send a physical copy of your video file anywhere in the world. Simply drag your video file below to proceed.</p>"
      }
    ],
    drive_type: "",
    drive_quantity: 1
  },
  "send-discs": {
    type: "send-discs",
    title: "Burn and Send Discs",
    step: "send-discs",
    returnStep: "send-discs",
    files: [
      {
        type: "disc_output",
        acceptedCineSendFileTypes: ["disc_output"],
        isRequired: true,
        description:
          "<p>Send a physical copy of your disc anywhere in the world. Simply drag your disc below to proceed.</p>"
      }
    ],
    disc_quantity: 1
  },
  conversion: {
    type: "conversion",
    title: "Order a Format Conversion",
    step: "file-picker",
    returnStep: "file-picker",
    files: [
      {
        type: "video_file",
        isRequired: true,
        acceptedCineSendFileTypes: ["source_file", "dcp"],
        description: "<p>A format conversion will convert your file from one format type to another.</p>"
      }
    ],
    error: false
  },
  inspection: {
    type: "inspection",
    title: "Order Quality Inspection Report",
    step: "file-picker",
    returnStep: "file-picker",
    files: [
      {
        type: "video_file",
        isRequired: true,
        acceptedCineSendFileTypes: ["source_file", "dcp"],
        description: "<p>A quality inspection will check that your file is valid.</p>"
      }
    ],
    error: false
  }
}
