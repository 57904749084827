import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  updateFilesModal,
  updateSharedEmail,
  addSharedEmail,
  removeSharedEmail,
  closeFileSharingModal,
  updateShareLabel
} from "@api/project/files/modals"
import { validateEmail } from "@api/account/"
import { Creatable } from "react-select"
import { addGlobalMessage } from "@api/utils/"
import { shareFiles, createPublicLink } from "@api/project/files/"
import copy from "copy-to-clipboard"
import { Status, Modal } from "@bitcine/cs-theme"
import uniqBy from "lodash.uniqby"

const Share = ({
  modals,
  updateFilesModal,
  updateSharedEmail,
  addSharedEmail,
  removeSharedEmail,
  shareFiles,
  createPublicLink,
  addGlobalMessage,
  closeFileSharingModal,
  updateShareLabel
}) => (
  <Modal
    title={`Share File ${modals.share.files.length > 1 ? "Package" : ""}`}
    open
    showConfirmationOnClose={modals.share.status === "READY"}
    onClose={closeFileSharingModal}>
    <Status
      pending={modals.share.status === "PENDING"}
      error={modals.share.status === "ERROR"}
      errorMessage={modals.share.errorMessage}>
      {modals.share.status === "COMPLETE" ? (
        <div className='center'>
          <i className='material-icons red' style={{ fontSize: "5em" }}>
            check_circle
          </i>
          <h3 className='pb1'>Success!</h3>
          <div className='pb1'>
            {modals.share.url ? (
              <div>
                <p>
                  You have successfully created a {modals.share.password.length ? "password protected" : "public"} link.
                </p>
                <div className='flex items-center'>
                  <input className='cs-input flex-auto mr1' disabled={true} value={modals.share.url}/>
                  <button
                    className='cs-button white'
                    onClick={() => {
                      addGlobalMessage("Link Copied!", "success")
                      copy(modals.share.url, { format: "text/plain" })
                    }}>
                    Copy URL
                  </button>
                </div>
              </div>
            ) : (
              <p>Your recipient(s) should be receiving your content shortly! You can revoke access at any time.</p>
            )}
          </div>
        </div>
      ) : (
        <div>
          <h4>Sharing Details</h4>
          <label className='cs-label' htmlFor='download-link-password'>
            (Optional) Add a password:
          </label>
          <input
            type='password'
            id='download-link-password'
            placeholder='Enter a password...'
            className='cs-input col-12'
            value={modals.share.password}
            autoComplete='new-password'
            onChange={({ target: { value } }) => updateFilesModal("share", "password", value)}/>
          {modals.share.files.length > 1 && (
            <div>
              <label className='cs-label mt1' htmlFor='package_name'>
                Add a package name:
              </label>
              <input
                id='package_name'
                type='text'
                className='cs-input col-12'
                value={modals.share.label}
                disabled={modals.share.url}
                onChange={({ target: { value } }) => updateShareLabel(value)}
                placeholder='Enter a package name that represents your selected files.'/>
              <sup className='muted red'>* Required</sup>
            </div>
          )}
          <div className='my2 border-bottom border-gray-5'/>
          <div className='flex items-center'>
            <h4 className='flex-auto'>Share with others</h4>
            <button
              onClick={() => {
                createPublicLink()
                updateFilesModal("share", "password", "")
              }}
              disabled={
                modals.share.status === "PENDING" || (modals.share.files.length > 1 && !modals.share.label.length)
              }
              className={`cs-button small white`}>
              Create {modals.share.password.length ? "password protected" : "public"} link
            </button>
          </div>
          <label className='cs-label'>Share by email:</label>
          <Creatable
            multi={true}
            options={[]}
            placeholder='Enter an email address and press [ENTER]...'
            promptTextCreator={string => `Add email: ${string}`}
            isValidNewOption={({ label }) => validateEmail(label)}
            onChange={val => updateFilesModal("share", "emails", val)}
            inputProps={{
              onPaste: e => {
                e.preventDefault()
                const email = e.clipboardData.getData("Text")
                if (!email) return
                if (validateEmail(email)) {
                  let options = modals.share.emails
                  options.push({
                    value: email,
                    label: email,
                    className: "Select-create-option-placeholder"
                  })
                  const emails = uniqBy(options, option => option.value)
                  updateFilesModal("share", "emails", emails)
                }
              }
            }}
            value={modals.share.emails}/>
          <label className='cs-label mt1'>Add a custom message:</label>
          <textarea
            value={modals.share.message}
            id='message'
            className='cs-textarea col-12 block'
            placeholder='This message will be sent with the email to your recipients. This can be left blank.'
            onChange={e => {
              updateFilesModal("share", "message", e.target.value)
            }}/>
          {modals.share.status === "ERROR" && <div className='red my2'>An error occured</div>}
          <div className='flex justify-end mt2'>
            <button
              onClick={shareFiles}
              disabled={
                modals.share.status === "PENDING" ||
                modals.share.emails.length === 0 ||
                (modals.share.files.length > 1 && !modals.share.label.length)
              }
              className={`cs-button small`}>
              Share by email
            </button>
          </div>
        </div>
      )}
    </Status>
  </Modal>
)

Share.propTypes = {
  modals: PropTypes.object.isRequired,
  updateFilesModal: PropTypes.func.isRequired,
  updateSharedEmail: PropTypes.func.isRequired,
  addSharedEmail: PropTypes.func.isRequired,
  removeSharedEmail: PropTypes.func.isRequired,
  shareFiles: PropTypes.func.isRequired,
  createPublicLink: PropTypes.func.isRequired,
  addGlobalMessage: PropTypes.func.isRequired,
  closeFileSharingModal: PropTypes.func.isRequired,
  updateShareLabel: PropTypes.func.isRequired
}

const mapStateToProps = (state, context) => ({
  modals: state.project.files.modals
})

const mapDispatchToProps = dispatch => ({
  updateFilesModal: bindActionCreators(updateFilesModal, dispatch),
  updateSharedEmail: bindActionCreators(updateSharedEmail, dispatch),
  removeSharedEmail: bindActionCreators(removeSharedEmail, dispatch),
  addSharedEmail: bindActionCreators(addSharedEmail, dispatch),
  shareFiles: bindActionCreators(shareFiles, dispatch),
  createPublicLink: bindActionCreators(createPublicLink, dispatch),
  addGlobalMessage: bindActionCreators(addGlobalMessage, dispatch),
  closeFileSharingModal: bindActionCreators(closeFileSharingModal, dispatch),
  updateShareLabel: bindActionCreators(updateShareLabel, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Share)
