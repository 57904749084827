import React from "react"
import PropTypes from "prop-types"
import humanFileSize from "@src/helpers/humanFileSize"
import styled from "react-emotion"
import DragDropFile from "@src/components/project/files/components/dnd/file"
import { Checkbox, ButtonDropdown, ContextMenu } from "@bitcine/cs-theme"
import Video from "./video"
import { ui } from "./data"
import "./styles.scss"
import Document from "./document"
import MediaPreparing from "./media_preparing"

const ButtonWrap = styled("div")`
  width: 34px;
  min-width: 34px;
`

class File extends React.Component {
  isThumbnailPending(screener) {
    return false
    return screener && screener.mp4_360p_job_status === "Complete" && !screener.thumbnail_url_md
  }
  isEncodingProxy(screener) {
    return screener && screener.mp4_360p_job_status !== "Complete"
  }
  render() {
    const { file, checked, onRowClick, onRowCheck, dialogOpen, disableContextMenu } = this.props
    return (
      <div className='px1 mb2 xl-col-2 lg-col-3 md-col-4 sm-col-4 col-6'>
        <ContextMenu content={disableContextMenu ? [] : this.props.getMenuOptions()}>
          <DragDropFile file={file}>
            <div
              style={{
                boxShadow: checked ? "0 0 1px 1px #e30a33" : ""
              }}
              role='button'
              onClick={onRowClick}
              className={`gridView ${checked ? "activeGridView" : ""} rounded bg-white ${
                file.type !== "source_file" ? "pointer" : ""
              }`}>
              <div
                style={{
                  paddingTop: "56.25%",
                  background: "#E0E3E4",
                  borderTopLeftRadius: "3px",
                  borderTopRightRadius: "3px"
                }}
                className='relative overflow-hidden'>
                <div className={`absolute top-0 left-0 ml2 mt2 z1 gridCheckbox`}>
                  {!file.frozen_at && (
                    <Checkbox className='absolute top-0 left-0' checked={checked} onChange={onRowCheck}/>
                  )}
                </div>
                <div>
                  {file.type === "source_file" ? (
                    <Video
                      isEncodingProxy={this.isEncodingProxy(file.screener)}
                      isThumbnailPending={this.isThumbnailPending(file.screener)}
                      thumbnail={file.screener ? file.screener.thumbnail_url_md || "" : ""}
                      scrubber={file.screener ? file.screener.scrubber_url || "" : ""}/>
                  ) : (
                    <div>
                      {file.type === "document" ? (
                        <Document document={file}/>
                      ) : (
                        <div className='absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center'>
                          {file.preview_image_status === "IN_PROGRESS" ? (
                            <MediaPreparing text='Preparing Preview'/>
                          ) : (
                            <span style={{ fontSize: "44px", color: "#92999F" }} className='material-icons'>
                              {ui[file.type].icon}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className='pl1 py1 pr0 flex items-center'>
                <div className='flex-auto pr2'>
                  <div title={file.name} className='truncate'>
                    {file.name || " "}
                  </div>
                  <div style={{ color: "#92999F", fontSize: "12px" }} className='flex items-center truncate'>
                    <span style={{ fontSize: "18px" }} className='material-icons mr1'>
                      {!!file.frozen_at ? ui.frozen.icon : ui[file.type].icon}
                    </span>
                    {!!file.frozen_at ? <strong className='orange'>&nbsp;Frozen</strong> : <strong>{file.kind}</strong>}
                    {file.type !== "folder" && (
                      <span>
                        ,&nbsp;
                        {file.size ? humanFileSize(file.size) : "--"}
                      </span>
                    )}
                  </div>
                </div>
                {!dialogOpen && (
                  <ButtonWrap className='mr1'>
                    <ButtonDropdown
                      button={{
                        menu: true,
                        text: "more_vert",
                        onClick: () => this.props.onMenuOpen()
                      }}
                      dropdown={{
                        clickCloses: true,
                        content: this.props.getMenuOptions()
                      }}
                      onDropdownClose={() => this.props.onMenuClose()}/>
                  </ButtonWrap>
                )}
              </div>
            </div>
          </DragDropFile>
        </ContextMenu>
      </div>
    )
  }
}

File.propTypes = {
  file: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  onRowCheck: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  getMenuOptions: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool,
  disableContextMenu: PropTypes.bool,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func
}

export default File
