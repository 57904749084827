export const GET_MFA_QRCODE = "GET_MFA_QRCODE"
export const GET_MFA_QRCODE_PENDING = "GET_MFA_QRCODE_PENDING"
export const GET_MFA_QRCODE_FULFILLED = "GET_MFA_QRCODE_FULFILLED"

export const VALIDATE_MFA_CODE = "VALIDATE_MFA_CODE"
export const VALIDATE_MFA_CODE_PENDING = "VALIDATE_MFA_CODE_PENDING"
export const VALIDATE_MFA_CODE_FULFILLED = "VALIDATE_MFA_CODE_FULFILLED"
export const VALIDATE_MFA_CODE_FAILED = "VALIDATE_MFA_CODE_REJECTED"

export const UPDATE_MFA_CODE = "UPDATE_MFA_CODE"

export const DISABLE_MFA = "DISABLE_MFA"
export const DISABLE_MFA_PENDING = "DISABLE_MFA_PENDING"
export const DISABLE_MFA_FULFILLED = "DISABLE_MFA_FULFILLED"
