import { INIT_DEFROST_PENDING, INIT_DEFROST_FULFILLED, INIT_DEFROST_FAILED } from "@actions/project"

const initialState = {
  status: "READY"
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INIT_DEFROST_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case INIT_DEFROST_FULFILLED:
      return {
        ...state,
        status: "READY"
      }

    case INIT_DEFROST_FAILED:
      return {
        ...state,
        status: "FAILED"
      }

    default:
      return state
  }
}
