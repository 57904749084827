import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toggleEditFolder, editFolder } from "@api/project/folders/"
import { Modal, Status } from "@bitcine/cs-theme"

class EditFolder extends React.Component {
  constructor(props) {
    super(props)
    this.state = { name: props.folder.name }
  }
  updateNewFolder(key, value) {
    // this.props.updateNewFolder(key, value)
  }
  saveFolder(e) {
    e.preventDefault()
    e.stopPropagation()
    this.props.editFolder(this.props.folder._id, "name", this.state.name)
  }
  render() {
    return (
      <Modal title={`Edit ${this.props.folder.name}`} onClose={() => this.props.toggleEditFolder(null)} open={true}>
        <Status height={200} pending={this.props.status === "PENDING"} error={this.props.status === "ERROR"}>
          <form>
            <label className='cs-label' htmlFor='New Folder'>
              Folder Name
            </label>
            <input
              autoFocus
              className='cs-input col-12'
              value={this.state.name}
              onChange={e => this.setState({ name: e.target.value })}/>
            <div className='mt3 right-align'>
              <button className='cs-button' disabled={!this.state.name} onClick={e => this.saveFolder(e)}>
                Save
              </button>
            </div>
          </form>
        </Status>
      </Modal>
    )
  }
}

EditFolder.propTypes = {
  folder: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  toggleEditFolder: PropTypes.func.isRequired,
  editFolder: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  ...state.project.files.folders.editFolder
})

const mapDispatchToProps = dispatch => ({
  toggleEditFolder: bindActionCreators(toggleEditFolder, dispatch),
  editFolder: bindActionCreators(editFolder, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditFolder)
