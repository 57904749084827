import React from "react"
import PropTypes from "prop-types"
import { Status } from "@bitcine/cs-theme"
import Wrap from "../components/wrap"
import { DEFAULTS } from "@constants"
import { GLOBALS } from "@src/config"
import Watch from "./watch"

class Preview extends React.Component {
  constructor(props) {
    super(props)
    this.url = `${GLOBALS.API_URL}/projects/${props.projectID}/screeners/${props.screenerID}`
    this.state = {
      status: "PENDING",
      encoding_status: "PENDING"
    }
  }
  componentDidMount() {
    fetch(this.url, DEFAULTS)
      .then(res => res.json())
      .then(screener => this.setState({ status: "READY", encoding_status: screener.mp4_360p_job_status }))
      .catch(err => this.setState({ status: "ERROR" }))
  }
  render() {
    const { status, encoding_status } = this.state
    return (
      <Wrap title='Preview' defaultOpen={false}>
        <Status pending={status === "PENDING"} error={status === "ERROR"} height={210}>
          <div>
            {encoding_status !== "Complete" ? (
              <div className='center px3 py4 rounded bg-gray-1'>
                <span className='material-icons bg-white circle p1 inline-block large mb1'>refresh</span>
                <h4 className='white'>Preview Encoding</h4>
              </div>
            ) : (
              <Watch url={this.url + "/endpoints/mp4_360p"}/>
            )}
          </div>
        </Status>
      </Wrap>
    )
  }
}

Preview.propTypes = {
  screenerID: PropTypes.string.isRequired,
  projectID: PropTypes.string.isRequired
}

export default Preview
