import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Modal } from "@bitcine/cs-theme"
import { DEFAULTS } from "@constants"
import { saveAs } from "file-saver"
import JSZip from "jszip"
import JSZipUtils from "jszip-utils"
import { GLOBALS } from "@src/config"
import 'styled-components/macro'

class Zip extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalFiles: props.list.length,
      readyCount: 0,
      failedFiles: []
    }
  }
  componentDidMount() {
    const files = this.props.list
    var zipper = new JSZip()
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      fetch(`${GLOBALS.API_URL}/s3/download?fileID=${file._id}&fileType=${file.type}`, { ...DEFAULTS })
        .then(res => res.json())
        .then(res => {
          JSZipUtils.getBinaryContent(res.token, (err, data) => {
            if (err) {
              this.setState({ failedFiles: [...this.state.failedFiles, file] })
            } else {
              zipper.file(file.name, data, { binary: true })
              if (this.state.readyCount + 1 === this.state.totalFiles) {
                zipper.generateAsync({ type: "blob" }).then(content => {
                  saveAs(content, "package.zip")
                })
              }
            }
            this.setState({ readyCount: this.state.readyCount + 1 })
          })
        })
    }
  }
  render() {
    const { onClose } = this.props
    const { totalFiles, readyCount, failedFiles } = this.state
    const isPreparing = readyCount < totalFiles
    const perc = ((readyCount / totalFiles) * 100).toFixed(2)
    return (
      <Modal open={true} onClose={onClose} title='Download all files'>
        <div>
          {isPreparing ? (
            <div>
              <p className='mb1'>Preparing Zip...</p>
              <div
                css={`
                  position: relative;
                  height: 30px;
                  width: 100%;
                  background: #f8f8f8;
                  border-radius: 8px;
                  overflow: hidden;
                `}>
                <div
                  css={`
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 30px;
                    transition: width 300ms;
                    width: ${perc}%;
                    background: #fb0f3b;
                  `}>
                  <span
                    css={`
                      position: absolute;
                      top: 50%;
                      right: 4px;
                      transform: translateY(-50%);
                      color: #fff;
                      padding: 0 8px;
                      border-radius: 3px;
                    `}>
                    {readyCount} / {totalFiles}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h3>Success!</h3>
              <p>Your download should start shortly.</p>
              {failedFiles.length > 0 && (
                <>
                  <p>
                    We were not able to add the following files to the archive. You will have to download them manually.
                  </p>
                  <ul>
                    {failedFiles.map(file => (
                      <li key={file._id}>{file.name}</li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          )}
        </div>
      </Modal>
    )
  }
}

Zip.propTypes = {
  list: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  list: state.download_link.files.list
})

export default connect(mapStateToProps)(Zip)
