import React from "react"
import PropTypes from "prop-types"
import { DEFAULTS } from "@constants"
import { Status } from "@bitcine/cs-theme"
import CineSendPlayer from "@bitcine/cinesend-player"

class Watch extends React.Component {
  constructor(props) {
    super(props)
    this.state = { endpoints: null }
  }
  componentDidMount() {
    fetch(this.props.url, DEFAULTS)
      .then(res => res.json())
      .then(res => this.setState({ endpoints: res.data.endpoints }))
  }
  render() {
    return (
      <div style={{ height: '100%', aspectRatio: '16/9' }}>
        <Status pending={!this.state.endpoints} height={210}>
          <CineSendPlayer
            playerID={"files-cinesend-player-id"}
            debug={process.env.NODE_ENV !== "production"}
            playerCode={"bitmovin"}
            endpoints={this.state.endpoints}
            autoPlay={false}
            activeIndex={0}
            fillViewport={false}
            onVideoChange={video => console.log("Video set to: ", video)}
            onPlayerCreation={player => this.setState({ player })}
            onPlayerEvent={event => console.log("Event log: ", event)}/>
        </Status>
      </div>
    )
  }
}

Watch.propTypes = {
  url: PropTypes.string.isRequired
}

export default Watch
