import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getFolders } from "@api/project/folders/"
import { Status, Table } from "@bitcine/cs-theme"
import Icon from "@components/files/icon/"
import Breadcrumb from "@components/breadcrumb"

class SelectFolder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: "",
      currentFolderID: props.currentFolderID,
      showCreate: false,
      new: ""
    }
  }
  componentDidMount() {
    this.props.getFolders(this.state.currentFolderID)
  }
  onRowCheck(_id) {
    this.setState(state => ({ checked: state.checked === _id ? "" : _id }))
  }
  onRowClick(_id) {
    this.setState({ checked: "", currentFolderID: _id }, () => {
      this.props.getFolders(_id)
    })
  }
  move() {
    this.props.select(this.props.folders.list.find(f => f._id === this.state.checked))
  }
  create() {
    this.props.create(this.state.currentFolderID, this.state.new)
  }
  render() {
    const { folders } = this.props
    return (
      <div>
        <Breadcrumb
          breadcrumb={folders.breadcrumb || []}
          onAllClick={() => this.onRowClick(null)}
          onCrumbClick={crumb => this.onRowClick(crumb._id)}/>
        <Status pending={folders.status === "PENDING"} error={folders.status === "ERROR"}>
          <Table
            className='my3'
            widths={[55, "auto", 75]}
            header={{
              columns: []
            }}
            body={{
              data: folders.list,
              empty: {
                icon: "folder",
                title: "No Folders Found",
                text: ``
              },
              row: {
                customRender: (children, data) => (
                  <div
                    style={{
                      opacity: this.state.showCreate ? "0.2" : "1"
                    }}>
                    {children}
                  </div>
                ),
                checkbox: {
                  checked: data => this.state.checked === data._id && !this.state.showCreate,
                  onChange: (data, index) => this.onRowCheck(data._id),
                  disabled: () => this.state.showCreate
                },
                onClick: (e, data, index) => this.onRowCheck(data._id),
                render: [
                  data => <Icon projectID={data.project_id} data={data}/>,
                  data => data.name,
                  data => (
                    <button
                      data-tooltip={`Go to ${data.name}`}
                      disabled={this.state.showCreate}
                      onClick={() => this.onRowClick(data._id)}
                      className='cs-button link material-icons tooltip'>
                      keyboard_arrow_right
                    </button>
                  )
                ]
              }
            }}/>
        </Status>
        <div className='flex justify-between items-end'>
          {this.state.showCreate ? (
            <div className='flex items-end relative flex-auto mr3'>
              <div className='col-12'>
                <label className='cs-label' htmlFor='new'>
                  New Folder Name
                </label>
                <input
                  value={this.state.new}
                  className='cs-input col-12'
                  id='new'
                  autoFocus
                  onChange={e => this.setState({ new: e.target.value })}
                  placeholder='Folder name'/>
              </div>
              <button
                onClick={() => this.setState({ showCreate: false })}
                className='cs-button link material-icons absolute right-0 mr2'>
                close
              </button>
            </div>
          ) : (
            <button className='cs-button white' onClick={() => this.setState({ showCreate: true })}>
              Create Folder
            </button>
          )}
          {this.state.showCreate ? (
            <button className='cs-button' onClick={() => this.create()} disabled={!this.state.new}>
              Create Folder and Move Files
            </button>
          ) : (
            <button className='cs-button' onClick={() => this.move()}>
              Select
            </button>
          )}
        </div>
      </div>
    )
  }
}

SelectFolder.propTypes = {
  moveFolder: PropTypes.object.isRequired,
  getFolders: PropTypes.func.isRequired,
  folders: PropTypes.object.isRequired,
  currentFolderID: PropTypes.string,
  create: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  moveFolder: state.project.files.folders.moveFolder,
  folders: state.project.files.folders.folders,
  // currentFolderID: state.routerParams.location.query.folderID
})

const mapDispatchToProps = dispatch => ({
  getFolders: bindActionCreators(getFolders, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectFolder)
