import React from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"

const Iframe = styled("iframe")`
  height: calc(100vh - 8em);
  width: calc(100vw - 5em);
`

const google = "https://drive.google.com/viewerng/viewer?embedded=true&url="

const Document = ({ url }) => <Iframe src={`${google}${encodeURIComponent(url)}`}/>

Document.propTypes = {
  url: PropTypes.string.isRequired
}

export default Document
