import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getFileRequests, toggleModal } from "@api/project/requests"
import { Link } from "react-router-dom"
import { Table, ButtonDropdown, Status, Empty } from "@bitcine/cs-theme"
import EmptyFileRequestsIcon from "@src/public/icons/empty/file-request.inline.svg"
import SearchIcon from "@src/public/icons/empty/search.inline.svg"
import dayjs from "dayjs"
import Submissions from "./modals/submissions"
import EditRequest from "./modals/edit"
import Share from "./modals/share"
import CreateRequest from "./modals/create"

class FileRequests extends Component {
  constructor() {
    super()
    this.state = {
      showCreate: false,
      showShare: true,
      search: ""
    }
  }
  componentDidMount() {
    if (this.props.canRequestFiles) {
      this.props.getFileRequests()
    }
  }
  filterRequests = () => {
    return this.props.requests.items.filter(req => {
      const title = req.title || ""
      return title
        .trim()
        .toLowerCase()
        .includes(this.state.search)
    })
  }
  render() {
    if (!this.props.canRequestFiles) {
      return (
        <Empty
          icon={<EmptyFileRequestsIcon/>}
          title='Aspera file requests'
          text={
            <React.Fragment>
              <div className='mb2'>
                Upgrade your account to unlock Aspera file requests. Requests allow anyone to upload specified files to
                this project.
                <br/>
                All paid plans include access to Aspera file requests and other helpful features.
              </div>
              <Link to='/settings/user/account/plan'>
                <button className='cs-button'>View Plans</button>
              </Link>
            </React.Fragment>
          }/>
      )
    } else
      return (
        <Status pending={this.props.requests.status === "PENDING"} error={this.props.requests.status === "ERROR"}>
          <div className='p2'>
            <div className='flex items-center justify-between'>
              <h4>File requests</h4>
              <div className='flex'>
                <input
                  className='cs-input'
                  value={this.state.search}
                  style={{ height: "34px" }}
                  onChange={e => this.setState({ search: e.target.value })}
                  placeholder='Search for a file request...'/>
                <button onClick={() => this.props.toggleModal("show_create")} className='cs-button small ml1'>
                  Request File
                </button>
              </div>
            </div>
            <Table
              className='my3'
              widths={["auto", "150", "150", "180", "180", "65"]}
              header={{
                columns: ["Request", "Status", "Submissions", "Destination Folder", "Created", ""].reduce(
                  (all, text) => [...all, { text }],
                  []
                )
              }}
              body={{
                data: this.filterRequests(),
                row: {
                  render: [
                    data => data.title,
                    data => (
                      <div className={`flex-auto capitalize ${data.status.toLowerCase() === "open" ? "green" : "red"}`}>
                        {data.status}
                      </div>
                    ),
                    data => data.files_count,
                    data => (data.folder ? data.folder.name : "All Files"),
                    data => dayjs(data.created_at).format("MMM Do, YYYY"),
                    data => (
                      <ButtonDropdown
                        button={{
                          className: "link material-icons",
                          style: { width: "48px" },
                          text: "more_vert"
                        }}
                        dropdown={{
                          clickCloses: true,
                          content: [
                            {
                              text: "Edit File Request",
                              onClick: () => this.props.toggleModal("show_edit", { active_request_id: data._id })
                            },
                            {
                              text: "Share",
                              onClick: () => this.props.toggleModal("show_share", { active_request: data })
                            },
                            {
                              text: "View Submissions",
                              onClick: () => this.setState({ fileRequestID: data._id })
                            }
                          ]
                        }}/>
                    )
                  ]
                },
                empty: {
                  icon: this.state.search.length ? <SearchIcon/> : <EmptyFileRequestsIcon/>,
                  title: this.state.search.length ? "No results found" : "No file requests",
                  text: this.state.search.length
                    ? "Try your search again"
                    : 'Click "Request File" above to request files'
                }
              }}/>
            {this.props.requests.show_create && <CreateRequest/>}
            {this.props.requests.show_share && <Share/>}
            {this.props.requests.show_edit && <EditRequest/>}
            {this.state.fileRequestID && (
              <Submissions
                fileRequestID={this.state.fileRequestID}
                onClose={() => this.setState({ fileRequestID: null })}/>
            )}
          </div>
        </Status>
      )
  }
}

FileRequests.propTypes = {
  getFileRequests: PropTypes.func.isRequired,
  requests: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  canRequestFiles: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  requests: state.project.requests,
  canRequestFiles: state.user.auth.can_request_files
})

const mapDispatchToProps = dispatch => ({
  getFileRequests: bindActionCreators(getFileRequests, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileRequests)
