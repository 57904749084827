import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import 'styled-components/macro'

const Wrap = ({ children }) => {
  const element = document.getElementById("nav")
  if (!element) return null
  else {
    return ReactDOM.createPortal(
      <div
        css={`
          height: 48px;
        `}
        className='col-12 flex items-center bg-white'>
        {children}
      </div>,
      element
    )
  }
}

Wrap.propTypes = {
  children: PropTypes.object.isRequired
}

export default Wrap
