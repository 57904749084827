import {
  UPDATE_ACCOUNT,
  LOGIN_FULFILLED,
  LOGIN_FAILED,
  SIGNUP_FAILED,
  SIGNUP_FULFILLED,
  SEND_RESET_LINK_FAILED,
  SEND_RESET_LINK_FULFILLED,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_FULFILLED,
  RESET_PASSWORD_PENDING,
  TOGGLE_OPEN_AUTH_MODAL,
  GET_SIGNUP_INVITE_PENDING,
  GET_SIGNUP_INVITE_FAILED,
  GET_SIGNUP_INVITE_FULFILLED
} from "@actions/account/"
import { GET_USER_LOCATION_FULFILLED } from "@actions/utils/"
import { LOCATION_CHANGE } from "@actions/utils/location"

const initialState = {
  status: "READY",
  mode: "LOGIN",
  error_message: "",
  full_name: "",
  email: "",
  password: "",
  confirm_password: "",
  country_code: "",
  showModal: false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        mode: action.payload.location.pathname.includes("signup")
          ? "SIGNUP"
          : action.payload.location.pathname.includes("reset")
            ? "RESET"
            : "LOGIN"
      }

    case UPDATE_ACCOUNT:
      return {
        ...state,
        status: action.key === "mode" ? "READY" : state.status,
        [action.key]: action.value
      }

    case LOGIN_FULFILLED:
      return initialState

    case LOGIN_FAILED:
      const errorMessages = {
        user_error: "This account does not exist.",
        password_error: "Incorrect email or password.",
        blocked_device: "Too many incorrect logins. This device has been blocked from accessing this account."
      }
      return {
        ...state,
        status: "ERROR",
        error_message: errorMessages[action.payload.message] || "An error occurred",
        error_code: action.payload.message
      }

    case SIGNUP_FULFILLED:
      return initialState

    case SIGNUP_FAILED:
      return {
        ...state,
        status: "ERROR",
        error_message: "An error occured"
      }

    case SEND_RESET_LINK_FAILED:
      return {
        ...state,
        status: "ERROR",
        error_message: "This email was not found"
      }

    case SEND_RESET_LINK_FULFILLED:
      return {
        ...state,
        status: "READY",
        mode: "LOGIN"
      }

    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        status: "ERROR",
        error_message: action.payload.message || "An error occurred"
      }

    case RESET_PASSWORD_PENDING:
      return {
        ...state,
        status: "PENDING",
        error_message: null
      }

    case RESET_PASSWORD_FULFILLED:
      return {
        ...state,
        status: "READY",
        mode: "LOGIN"
      }

    case TOGGLE_OPEN_AUTH_MODAL:
      return {
        ...state,
        showModal: !state.showModal
      }

    case GET_SIGNUP_INVITE_PENDING:
      return {
        ...state,
        status: "INVITE_PENDING",
        mode: "INVITE"
      }

    case GET_SIGNUP_INVITE_FULFILLED:
      return {
        ...state,
        status: "READY",
        email: action.payload.email,
        full_name: action.payload.full_name,
        organization_id: action.payload.organization_id
      }

    case GET_SIGNUP_INVITE_FAILED:
      return {
        ...state,
        status: "INVITE_ERROR"
      }

    case GET_USER_LOCATION_FULFILLED:
      return {
        ...state,
        country_code: action.payload.country_code
      }

    default:
      return state
  }
}
