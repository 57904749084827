import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Dropzone from "react-dropzone"
import { initializeHttpUpload } from "@api/transfer/http/upload"
import { canModifyScreenerRoom } from "@src/helpers/authorization"
import Poster from "@src/components/projects/components/project/poster/"
import "../styles.scss"

class PosterContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      url: props.project.poster
    }
  }
  render() {
    const { project, initializeHttpUpload, canModify } = this.props
    return (
      <Dropzone
        multiple={false}
        accept='image/*'
        activeClassName={"activeDropzone"}
        disabled={!canModify}
        className={"posterUpload"}
        onDrop={(accepted, rejected) => {
          accepted.map(file =>
            initializeHttpUpload(
              file,
              {
                is_project_poster: true,
                project_id: project._id
              },
              result => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => this.setState({ url: reader.result })
              }
            )
          )
        }}>
        <Poster src={this.state.url}>
          <div className={"clickToUpload"}>Click to upload poster</div>
        </Poster>
      </Dropzone>
    )
  }
}

PosterContainer.propTypes = {
  canModify: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  initializeHttpUpload: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  canModify: canModifyScreenerRoom(state.user.auth, state.screener_rooms.screenerRoom.data)
})

const mapDispatchToProps = dispatch => ({
  initializeHttpUpload: bindActionCreators(initializeHttpUpload, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PosterContainer)
