import { PREVIEW_DOCUMENT, CLOSE_DOCUMENT_PREVIEW } from "../actions/preview/documents"

const initialState = {
  isOpen: false,
  document: null
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PREVIEW_DOCUMENT:
      return {
        ...state,
        isOpen: true,
        document: action.payload.document
      }

    case CLOSE_DOCUMENT_PREVIEW:
      return initialState

    default:
      return state
  }
}
