import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateBilling, setDefaultCreditCard } from "@api/account/billing"
import { updateCart } from "@api/account/cart"
import { Checkbox, ButtonDropdown } from "@bitcine/cs-theme"
import { can, MANAGE_CREDIT_CARDS } from "@helpers/authorization"
import { cardExpired } from "@src/helpers/card_expired"
import "./styles.scss"

class CreditCard extends React.Component {
  constructor() {
    super()
    this.state = { open: false }
  }
  render() {
    const {
      card,
      updateBilling,
      cardToDelete,
      setDefaultCreditCard,
      cart,
      updateCart,
      activeCreditCardID,
      defaultCreditCardID,
      canManageCards,
      usingPostPay,
      isPendingPayment
    } = this.props

    return (
      <div
        data-testid='credit-card'
        className={`${cart ? "lg-col-4 xl-col-3" : "lg-col-3 xl-col-2"} col-12 sm-col-6 md-col-4 px1 pb2`}>
        <div
          onClick={() => {
            if (cart && !usingPostPay && !cardExpired(card)) {
              updateCart("credit_card_id", card._id)
            }
          }}
          className={`${cart ? "cursor" : ""} col-12 card bg-cover relative ${
            card.brand.toLowerCase().replace(" ", "_")
          } rounded box-shadow`}>
          <div className='absolute left-0 right-0 bottom-0 top-0 flex flex-column p1'>
            {!cart && (
              <div className='flex justify-end item-start flex-auto relative'>
                {defaultCreditCardID === card._id && (
                  <div className='flex-auto white flex items-start pt1 small'>
                    <span className='material-icons mr1 small'>check_circle</span>
                    Default Card
                  </div>
                )}
                {canManageCards && (
                  <ButtonDropdown
                    button={{
                      className: "link",
                      style: { width: "48px" },
                      text: <span className='material-icons white'>more_vert</span>
                    }}
                    dropdown={{
                      clickCloses: true,
                      content: [
                        {
                          text: "Set as Default",
                          onClick: () => {
                            this.setState({ open: false })
                            setDefaultCreditCard(card._id)
                          },
                          icon: "credit_card"
                        },
                        {
                          text: "Remove Card",
                          icon: "delete",
                          onClick: () => {
                            this.setState({ open: false })
                            updateBilling("cardToDelete", {
                              ...cardToDelete,
                              _id: card._id
                            })
                          }
                        }
                      ]
                    }}/>
                )}
              </div>
            )}
            {cart && (
              <div className='flex flex-auto items-start white'>
                {!cardExpired(card) ? (
                  <Checkbox
                    disabled={usingPostPay}
                    checked={activeCreditCardID === card._id}
                    onChange={() => updateCart("credit_card_id", card._id)}/>
                ) : (
                  <label className='cs-label block light white'>Card Expired</label>
                )}
              </div>
            )}
            <strong className={`white py1 mb0 flex item-center justify-center cardNumber`}>
              {card.brand === "American Express" ? `**** ****** *${card.last4}` : `**** **** **** ${card.last4}`}
            </strong>
            <div className='flex items-end small'>
              <div className={`flex-auto expiry`}>
                <small className='block light white muted'>Expiry Date</small>
                <strong className='block white'>
                  {card.exp_month < 10 ? "0" : ""}
                  {card.exp_month} / {card.exp_year}
                </strong>
              </div>
              <div
                className={`${card.brand.toLowerCase().replace(" ", "_")}-icon card-icon bg-contain bg-no-repeat bg-center`}/>
            </div>
          </div>
        </div>
        {card.has_failed_payment && (
          <div
            className={`mt1 col-12 p2 bg-cover relative ${card.brand.toLowerCase().replace(" ", "_")} rounded box-shadow`}>
            <div className='flex white'>
              <span className='material-icons mr1'>warning</span>
              <div>
                {isPendingPayment ? (
                  <React.Fragment>
                    <h4>Payment declined</h4>
                    <div className='small'>
                      This card failed a storage payment.{" "}
                      <a
                        className='pointer white underline'
                        disabled={this.props.isPayingPayment}
                        onClick={() => this.props.retryPendingPayment()}>
                        Try again
                      </a>{" "}
                      or select a different payment method to resolve this issue.
                    </div>
                  </React.Fragment>
                ) : (
                  <div className='small'>
                    This card has recently failed a payment. This message will disappear once a payment has successfully
                    been made using this card.
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

CreditCard.propTypes = {
  card: PropTypes.object.isRequired,
  cardToDelete: PropTypes.object.isRequired,
  updateBilling: PropTypes.func.isRequired,
  setDefaultCreditCard: PropTypes.func.isRequired,
  updateCart: PropTypes.func.isRequired,
  cart: PropTypes.bool.isRequired,
  activeCreditCardID: PropTypes.string,
  defaultCreditCardID: PropTypes.string,
  usingPostPay: PropTypes.bool.isRequired,
  canManageCards: PropTypes.bool.isRequired,
  isPendingPayment: PropTypes.bool.isRequired,
  retryPendingPayment: PropTypes.func.isRequired,
  isPayingPayment: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  cardToDelete: state.user.billing.cardToDelete,
  activeCreditCardID: state.user.cart.credit_card_id,
  defaultCreditCardID: state.user.auth.default_credit_card_id,
  isPendingPayment: state.user.auth.subscription.is_pending_storage_payment,
  canManageCards: can(state.user.auth, [MANAGE_CREDIT_CARDS]),
  usingPostPay: state.user.billing.paymentMethods.list.using_post_pay === true,
  isPayingPayment: state.user.billing.payment_status === "UPDATING"
})

const mapDispatchToProps = dispatch => ({
  updateBilling: bindActionCreators(updateBilling, dispatch),
  setDefaultCreditCard: bindActionCreators(setDefaultCreditCard, dispatch),
  updateCart: bindActionCreators(updateCart, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CreditCard)
