import { TOGGLE_NEW_REVIEWERS, UPDATE_NEW_REVIEWERS } from "@actions/preview/send"
import { CREATE_SHARED_SCREENERS } from "@actions/shared_screeners"

import { post } from "@api/fetch"

/**
 * Toggle new reviewers
 *
 * @returns dispatch
 */

export const toggleNewReviewers = (mode, shareType, screenerRoom = null) => (dispatch, getState) => {
  const canUseForensicWatermark =
    (shareType === "screener" && getState().project.files.screener.use_forensic_watermark) ||
    (shareType === "screenerRoom" && screenerRoom.use_forensic_watermark)
  const useForensicWatermark =
    canUseForensicWatermark &&
    getState().user.auth.subscription.reviewers.remaining === 0 &&
    getState().user.auth.subscription.forensic_watermark_reviewers.remaining > 0
  dispatch({
    type: TOGGLE_NEW_REVIEWERS,
    mode,
    shareType,
    canUseForensicWatermark,
    useForensicWatermark
  })
}

/**
 * Update the new reviewers state
 *
 * @returns dispatch
 */

export const updateNewReviewers = (kind, key, value) => dispatch =>
  dispatch({ type: UPDATE_NEW_REVIEWERS, kind, key, value })

/**
 * Creates the new shared screeners
 *
 * @returns dispatch
 */
export const createSharedScreeners = payload => dispatch =>
  dispatch(post(CREATE_SHARED_SCREENERS.REQUEST, "shared-screeners", payload, null))

/**
 * Generates a sharing url for a screener preview
 *
 * @returns dispatch
 */

export const generateSharingLink = () => (dispatch, getState) => {
  const newReviewers = getState().preview.send.link
  const shareType = getState().preview.send.shareType
  const payload = {
    emails: [
      {
        full_name: newReviewers.full_name,
        email: ""
      }
    ],
    sendWatchNotification: newReviewers.send_email_notification || false,
    ipProtectionEnabled: newReviewers.ip_protection_enabled || false,
    shareType: "link",
    watermark: parseWatermark(newReviewers, "link"),
    password: newReviewers.password,
    geoblockCountryCodes: newReviewers.geoblock_country_codes,
    geoblockRegionCodes: newReviewers.geoblock_region_codes,
    expiry: newReviewers.expiry,
    availableAt: newReviewers.available_at,
    viewCountLimit: newReviewers.view_count_limit,
    useForensicWatermark: newReviewers.use_forensic_watermark,
    commentingEnabled: newReviewers.commenting_enabled || false
  }
  if (shareType === "screener") payload.screenerID = getState().project.files.screener._id
  else payload.screenerGroupID = getState().screener_rooms.screenerRoom.data._id
  dispatch(createSharedScreeners(payload))
}

/**
 * Send email links to screener reviewers
 *
 * @returns dispatch
 */

export const sendReviewerLinks = () => (dispatch, getState) => {
  const newReviewers = getState().preview.send.email
  const shareType = getState().preview.send.shareType
  const emails = []
  Object.keys(newReviewers.list).forEach(key => emails.push(newReviewers.list[key]))
  const payload = {
    emails,
    message: newReviewers.message,
    sendWatchNotification: newReviewers.send_email_notification || false,
    ipProtectionEnabled: newReviewers.ip_protection_enabled || false,
    shareType: "email",
    watermark: parseWatermark(newReviewers, "email"),
    password: newReviewers.password,
    geoblockCountryCodes: newReviewers.geoblock_country_codes,
    geoblockRegionCodes: newReviewers.geoblock_region_codes,
    expiry: newReviewers.expiry,
    availableAt: newReviewers.available_at,
    viewCountLimit: newReviewers.view_count_limit,
    useForensicWatermark: newReviewers.use_forensic_watermark,
    commentingEnabled: newReviewers.commenting_enabled
  }
  if (shareType === "screener") payload.screenerID = getState().project.files.screener._id
  else payload.screenerGroupID = getState().screener_rooms.screenerRoom.data._id
  dispatch(createSharedScreeners(payload))
}

const parseWatermark = (reviewer, mode) => ({
  is_active: reviewer.watermarkEnabled,
  text: reviewer.text,
  position: reviewer.position,
  second_line_text_type: reviewer.second_line_text_type,
  size: reviewer.size,
  opacity: reviewer.opacity,
  mode
})
