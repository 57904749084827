export const ADD_GLOBAL_MESSAGE = "ADD_GLOBAL_MESSAGE"
export const REMOVE_GLOBAL_MESSAGE = "REMOVE_GLOBAL_MESSAGE"

export const GET_COUNTRIES = "GET_COUNTRIES"
export const GET_COUNTRIES_FULFILLED = "GET_COUNTRIES_FULFILLED"

export const GET_LANGUAGES = "GET_LANGUAGES"
export const GET_LANGUAGES_FULFILLED = "GET_LANGUAGES_FULFILLED"

export const GET_TIMEZONES = "GET_TIMEZONES"
export const GET_TIMEZONES_FULFILLED = "GET_TIMEZONES_FULFILLED"

export const GET_USER_LOCATION = "GET_USER_LOCATION"
export const GET_USER_LOCATION_FULFILLED = "GET_USER_LOCATION_FULFILLED"

export const GET_RESOLUTIONS = "GET_RESOLUTIONS"
export const GET_RESOLUTIONS_FULFILLED = "GET_RESOLUTIONS_FULFILLED"

export const GET_VIDEO_FORMATS = "GET_VIDEO_FORMATS"
export const GET_VIDEO_FORMATS_FULFILLED = "GET_VIDEO_FORMATS_FULFILLED"

export const GET_FRAME_RATES = "GET_FRAME_RATES"
export const GET_FRAME_RATES_FULFILLED = "GET_FRAME_RATES_FULFILLED"

export const UPDATE_UTILS = "UPDATE_UTILS"

export const GET_GENRES = "GET_GENRES"
export const GET_GENRES_FULFILLED = "GET_GENRES_FULFILLED"

export const TOGGLE_ASPERA_UPGRADE_MESSAGE = "TOGGLE_ASPERA_UPGRADE_MESSAGE"
