import React, { Component } from "react"
import PropTypes from "prop-types"
import { DragLayer } from "react-dnd"
import shouldPureComponentUpdate from "@src/helpers/shouldPureComponentUpdate"
import ProjectDragging from "@components/projects/dragging"
import ActiveRow from "./dragging"

const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%"
}

function getItemStyles(props) {
  const { clientOffset } = props
  if (!clientOffset) {
    return {
      display: "none"
    }
  }

  const { x, y } = clientOffset

  const transform = `translate(${x}px, ${y}px)`

  return {
    transform,
    WebkitTransform: transform
  }
}

class CustomDragger extends Component {
  static propTypes = {
    item: PropTypes.object,
    itemType: PropTypes.string,
    // initialOffset: PropTypes.shape({
    // 	x: PropTypes.number.isRequired,
    // 	y: PropTypes.number.isRequired,
    // }),
    currentOffset: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired
    }),
    // clientOffset: PropTypes.shape({
    // 	x: PropTypes.number.isRequired,
    // 	y: PropTypes.number.isRequired,
    // }),
    isDragging: PropTypes.bool.isRequired
  }
  shouldComponentUpdate = shouldPureComponentUpdate
  renderItem(type, item) {
    switch (type) {
      case "FILE":
        return <ActiveRow name={item.name}/>

      case "PROJECT":
        return <ProjectDragging item={item}/>

      default:
        return null
    }
  }
  render() {
    const { item, itemType, isDragging } = this.props

    if (!isDragging) {
      return null
    }

    return (
      <div style={layerStyles}>
        <div style={getItemStyles(this.props)}>{this.renderItem(itemType, item)}</div>
      </div>
    )
  }
}

function collect(monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    // currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    // initialOffset: monitor.getInitialSourceClientOffset(),
    clientOffset: monitor.getClientOffset()
  }
}

export default DragLayer(collect)(CustomDragger)
