import React from "react"
import AsperaImg from "../images/aspera_demo.png"
import BaseSlide from "./components/base"
import Footer from "./components/footer"
import 'styled-components/macro'

const AsperaSlide = () => (
  <BaseSlide>
    <div
      css={`
        padding: 40px 60px 0 60px;
      `}>
      <img className='cs-img' src={AsperaImg} alt='Aspera'/>
    </div>
    <Footer
      bg='blue'
      header='Aspera transfers built-in'
      content={`
				IBM's Aspera technology facilitates fast and reliable file transfers and is built 
				right into CineSend. Using Aspera also gives you the control to pause and resume, and 
				ensures that your content remains encrypted during the transfer.
			`}/>
  </BaseSlide>
)

export default AsperaSlide
