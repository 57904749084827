import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateAuthUser, saveAuthUser } from "@api/account/auth"
import { ButtonDropdown } from "@bitcine/cs-theme"

const FileViewToggle = ({ updateAuthUser, saveAuthUser, view }) => (
  <ButtonDropdown
    button={{
      menu: true,
      text: view === "grid" ? "view_module" : "view_list"
    }}
    dropdown={{
      clickCloses: true,
      content: ["grid", "list"].map(v => ({
        text: (
          <div className='flex items-center capitalize'>
            <span style={{ width: "24px", color: "#FB0F3B" }} className='material-icons mr1'>
              {v === view ? "check" : ""}
            </span>
            {v}
          </div>
        ),
        onClick: () => {
          updateAuthUser("file_view_type", v)
          saveAuthUser("file_view_type", v)
        }
      }))
    }}/>
)

const mapDispatchToProps = dispatch => ({
  updateAuthUser: bindActionCreators(updateAuthUser, dispatch),
  saveAuthUser: bindActionCreators(saveAuthUser, dispatch)
})

const mapStateToProps = state => ({
  view: state.user.auth.file_view_type || "grid"
})

FileViewToggle.propTypes = {
  view: PropTypes.string.isRequired,
  saveAuthUser: PropTypes.func.isRequired,
  updateAuthUser: PropTypes.func.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileViewToggle)
