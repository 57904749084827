import { TOGGLE_PASSWORD_MODAL, TOGGLE_ONBOARDING_MODAL, TOGGLE_ASPERA_MODAL } from "@actions/modals"
import { UPDATE_SCREENER_PASSWORD_FULFILLED } from "@actions/screener_rooms"
import { UPDATE_SHARED_SCREENER_PASSWORD_FULFILLED } from "@actions/project/screener"

const initialState = {
  passwordModal: {
    visible: false,
    activeViewer: null
  },
  onboardingModal: {
    visible: false
  },
  asperaModal: false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_ASPERA_MODAL:
      return {
        ...state,
        asperaModal: !state.asperaModal
      }
    case TOGGLE_PASSWORD_MODAL:
      return {
        ...state,
        passwordModal: {
          visible: !state.passwordModal.visible,
          activeViewer: !state.passwordModal.visible ? action.payload.viewer : null
        }
      }

    case UPDATE_SCREENER_PASSWORD_FULFILLED:
      return {
        ...state,
        passwordModal: {
          visible: false,
          activeViewer: null
        }
      }

    case UPDATE_SHARED_SCREENER_PASSWORD_FULFILLED:
      return {
        ...state,
        passwordModal: {
          visible: false,
          activeViewer: null
        }
      }

    case TOGGLE_ONBOARDING_MODAL:
      return {
        ...state,
        onboardingModal: {
          visible: !state.onboardingModal.visible
        }
      }

    default:
      return state
  }
}
