import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Checkbox } from "@bitcine/cs-theme"

const Commenting = ({ onChange, checked }) => {
  return (
    <div className='py2 border-bottom border-gray-5 flex items-center'>
      <Checkbox checked={checked} onChange={onChange}/>
      <div className='pl1'>
        <h4>Enable Comments</h4>
        <p className='mb0'>Set whether reviewers have the ability to add timestamped comments.</p>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({})

Commenting.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(Commenting)
