import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateNewPaymentMethod } from "@api/account/billing"
import NewPaymentMethod from "@components/settings/billing/new"
import { updateCart } from "@api/account/cart"
import CreditCard from "@components/settings/billing/creditcard"
import GiftCard from "@components/settings/billing/giftcard"
import PostPay from "@components/settings/billing/postpay"
import { Status } from "@bitcine/cs-theme"
import NoPaymentMethodsIcon from "@src/public/icons/empty/payment-methods.inline.svg"

const Payment = ({
  updateNewPaymentMethod,
  newPaymentMethod: { visible },
  paymentMethods: {
    list: { gift_cards, credit_cards, can_post_pay, using_post_pay },
    status
  },
  updateCart,
  cart,
  activeCreditCardID
}) => (
  <div>
    <div className='flex items-center'>
      <div className='flex flex-auto items-center'>
        <h4 className='mb0'>Payment</h4>
        {cart.error === "CREDIT_CARD" && (
          <div className='red bold flex items-center ml2'>
            <span className='material-icons mr1'>error</span>A credit card is required.
          </div>
        )}
      </div>

      <button onClick={() => updateNewPaymentMethod("visible", true)} className='cs-button white small'>
        Add Payment Method
      </button>
    </div>
    <p>Select the credit card and/or gift cards you would like to use to complete this purchase.</p>
    <Status
      pending={status === "PENDING"}
      empty={!gift_cards.length && !credit_cards.length && !can_post_pay}
      emptyMessage={{
        icon: <NoPaymentMethodsIcon/>,
        title: "No payment methods",
        text: 'Click "Add payment method" above to add credit cards or gift cards'
      }}>
      <div className='flex flex-wrap my3' style={{ marginLeft: "-.5rem", marginRight: "-.5rem" }}>
        {credit_cards.map(card => (
          <CreditCard key={card._id} cart={true} card={card}/>
        ))}
        {gift_cards.map(card => (
          <GiftCard key={card._id} cart={true} card={card}/>
        ))}
        {can_post_pay && <PostPay cart={true}/>}
      </div>
    </Status>
    {visible && <NewPaymentMethod disableGiftCards={false}/>}
  </div>
)

Payment.propTypes = {
  paymentMethods: PropTypes.object.isRequired,
  updateNewPaymentMethod: PropTypes.func.isRequired,
  newPaymentMethod: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  activeCreditCardID: PropTypes.string,
  updateCart: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  paymentMethods: state.user.billing.paymentMethods,
  newPaymentMethod: state.user.billing.newPaymentMethod,
  cart: state.user.cart,
  activeCreditCardID: state.user.cart.credit_card_id
})

const mapDispatchToProps = dispatch => ({
  updateNewPaymentMethod: bindActionCreators(updateNewPaymentMethod, dispatch),
  updateCart: bindActionCreators(updateCart, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payment)
