import { SET_FILES } from "@actions/project/files"
import { SET_FILE_REQUEST } from "@actions/file_request"
import { removeTransfer, updateTransfer } from "@api/transfer/"
import { addGlobalMessage } from "@api/utils/"
import Tracking from "@helpers/tracking"
import { GLOBALS } from "@src/config"
import { PUT_DEFAULTS } from "@constants"

export const updateUpload = (transfer, error = null) => (dispatch, getState) => {
  if (typeof window.uploadingEvent !== "undefined") {
    window.dispatchEvent(window.uploadingEvent)
  }
  fetch(`${GLOBALS.API_URL}/uploads/${transfer.upload_id}`, {
    ...PUT_DEFAULTS,
    body: JSON.stringify(error ? { error } : {
      current_size: transfer.current_size,
      total_size: transfer.total_size,
      status: transfer.status,
      progress: transfer.progress
    })
  })
}

export const completeUpload = transfer => (dispatch, getState) => {
  dispatch(updateTransfer("current_size", transfer.total_size, transfer.uuid))
  dispatch(updateTransfer("status", "Complete", transfer.uuid))

  const OPTS = {
    ...PUT_DEFAULTS,
    body: JSON.stringify({
      total_size: transfer.total_size,
      status: "Complete"
    })
  }

  fetch(`${GLOBALS.API_URL}/uploads/${transfer.upload_id}`, OPTS)
    .then(res => res.json())
    .then(({ upload, result }) => {
      dispatch(addGlobalMessage(`Completed upload of ${transfer.name}!`, "success"))
      dispatch(removeTransfer(transfer.uuid))
      Tracking.record("Upload file", {
        "Upload method": transfer.method,
        "File type": transfer.type,
        "File name": transfer.name,
        "File size": transfer.total_size
      })

      const folderID = getState().project.files.items.folder_id || null
      const projectID = getState().project.files.items.project_id || null

      if (result.project_id === projectID && result.folder_id === folderID) {
        dispatch({
          type: SET_FILES,
          ...result
        })
      }

      if (upload.file_request_id || upload.cinerequest_id) {
        dispatch({
          type: SET_FILE_REQUEST,
          result
        })
      }

      /**
       * A callback is sometimes needed for state management reasons
       * Note: Ideally, this should be avoided, and instead use server response for state updates
       */
      if (transfer.callback) transfer.callback(result)
    })
    .catch(err => {
      throw new Error(err)
    })
}
