import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Checkbox } from "@bitcine/cs-theme"

const SingleIPProtection = ({ onChange, checked }) => {
  return (
    <div className='py2 border-bottom border-gray-5 flex items-center'>
      <Checkbox checked={checked} onChange={onChange}/>
      <div className='pl1'>
        <h4>Single IP Protection</h4>
        <p className='mb0'>Set whether this link can only be accessed by one IP address.</p>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({})

SingleIPProtection.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(SingleIPProtection)
