import React from "react"
import PropTypes from "prop-types"
import DateTime from "./datetime"

const LastSent = ({ sharedScreener }) =>
  sharedScreener.last_sent_at ? (
    <div className='caption'>
      <DateTime date={sharedScreener.last_sent_at}/>
      <span className='block light'>By: {sharedScreener.last_sent_by}</span>
    </div>
  ) : (
    "N/A"
  )

LastSent.propTypes = {
  sharedScreener: PropTypes.object.isRequired
}

export default LastSent
