import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import copy from "copy-to-clipboard"
import { updateLastSent } from "@api/shared_screeners"
import { canShareScreenerRoom } from "@helpers/authorization"
import "./styles.scss"

const getTooltip = (user, sharedScreener) => {
  return sharedScreener.type === "screener_lab"
    ? "screenerlab"
    : sharedScreener.share_type === "link" &&
      (sharedScreener.type === "screener" ||
        (sharedScreener.screener_group && canShareScreenerRoom(user, sharedScreener)))
      ? "copyurl"
      : sharedScreener.share_type === "link"
        ? "url"
        : sharedScreener.stats && sharedScreener.stats.is_opened
          ? "emailopened"
          : "emailsent"
}

const ShareType = ({ user, sharedScreener, updateLastSent }) => (
  <div
    className={`icon ${getTooltip(user, sharedScreener)}
    relative circle flex items-center justify-center box-shadow`}>
    {sharedScreener.share_type === "link" &&
    (sharedScreener.type === "screener" ||
      (sharedScreener.screener_group && canShareScreenerRoom(user, sharedScreener))) ? (
        <span
          onClick={() => {
            copy(sharedScreener.url, { format: "text/plain" })
            updateLastSent(sharedScreener._id)
          }}
          className={`material-icons white pointer`}
          style={{ fontSize: "14px" }}>
        link
        </span>
      ) : (
        <span className={`material-icons white`} style={{ fontSize: "14px" }}>
          {sharedScreener.type === "screener_lab"
            ? "subscriptions"
            : sharedScreener.share_type === "link"
              ? "link"
              : sharedScreener.stats && sharedScreener.stats.is_opened
                ? "drafts"
                : "mail"}
        </span>
      )}
  </div>
)

ShareType.propTypes = {
  sharedScreener: PropTypes.object.isRequired,
  updateLastSent: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  user: state.user.auth
})

const mapDispatchToProps = dispatch => ({
  updateLastSent: bindActionCreators(updateLastSent, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ShareType)
