import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toggleOrderVisibility } from "@api/project/orders"
import { getPaymentMethods } from "@api/account/billing"
import { getCart } from "@api/account/cart"
import Summary from "@components/cart/summary/"
import Stepper from "@components/cart/ui/stepper"
import { Helmet } from "react-helmet"
import { GLOBALS } from "@src/config"

class Cart extends React.Component {
  componentDidMount() {
    this.props.toggleOrderVisibility()
    this.props.getCart()
    this.props.getPaymentMethods()
  }
  render() {
    const { cart, children, location, isLoadingPayment } = this.props
    return (
      <div>
        <div className='p2 border-top border-gray-5'>
          <Helmet title='CineSend | Cart'/>
          {cart.status === "PURCHASED" ? (
            <div className='box-shadow rounded bg-white py4 max-width-5 mx-auto'>
              <div className='py4 flex items-center justify-center'>
                <div className='center'>
                  <span className='large-icon red material-icons block'>check_circle</span>
                  <h3>Purchase Complete!</h3>
                  <a target='_blank' rel='noopener noreferrer' href={`${GLOBALS.API_URL}/settings/invoices/${cart.invoice._id}`}>
                    <button className='cs-button white mt2'>Print Invoice</button>
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className='box-shadow rounded bg-white p3 max-width-5 mx-auto'>
              {cart.status === "PENDING" || isLoadingPayment ? (
                <div className='my4 py4 center'>
                  <div className='spinner'/>
                </div>
              ) : (
                <div className='flex items-start'>
                  <div className='col-8'>
                    <Stepper currentStep={location.pathname}/>
                    {children}
                  </div>
                  <div className='col-4 pl4'>
                    <Summary step={location.pathname}/>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

Cart.propTypes = {
  toggleOrderVisibility: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  getCart: PropTypes.func.isRequired,
  cart: PropTypes.object,
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isLoadingPayment: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  cart: state.user.cart,
  isLoadingPayment: state.user.billing.paymentMethods.list.status === "PENDING"
})

const mapDispatchToProps = dispatch => ({
  toggleOrderVisibility: bindActionCreators(toggleOrderVisibility, dispatch),
  getCart: bindActionCreators(getCart, dispatch),
  getPaymentMethods: bindActionCreators(getPaymentMethods, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Cart)
