import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { prevStep, nextStep, updateOrder } from "@api/project/orders"
import { Checkbox } from "@bitcine/cs-theme"
import "../styles.scss"

const DCPAddons = ({ prevStep, nextStep, order, items, lengthInMins, updateOrder }) => (
  <div className='col-12'>
    <div className={"orderInnerContainer"}>
      {/* Encryption */}
      <div className='pb3'>
        <div className='flex items-center mb1'>
          <span className='material-icons mr1'>lock</span>
          <strong>Encryption</strong>
        </div>
        <p>Encrypt your DCP for more security.</p>
        <div className='mb1 flex items-center'>
          <Checkbox
            id={`encrypt`}
            checked={order.encrypt === true}
            onChange={() => updateOrder("encrypt", !order.encrypt)}/>
          <label className='cs-label flex-auto' htmlFor={`encrypt`}>
            <strong className='px1'>Add Encryption</strong>
          </label>
          <strong>
            {items.encrypted_dcp_base.currency_symbol}
            {(items.encrypted_dcp_base.price + items.encrypted_dcp_per_minute.price * lengthInMins).toFixed(2)}
          </strong>
        </div>
      </div>
      {/* QC Report */}
      <div className='pb3'>
        <div className='flex items-center mb1'>
          <span className='material-icons mr1'>check_circle</span>
          <strong>QA Report</strong>
        </div>
        <p>Purchase a copy of the QA report outlining the technical inspection of your DCP.</p>
        <div className='mb1 flex items-center'>
          <Checkbox
            id={`qa`}
            checked={order.qa_report === true}
            onChange={() => updateOrder("qa_report", !order.qa_report)}/>
          <label className='cs-label flex-auto' htmlFor={`qa`}>
            <strong className='px1'>Add QA Report</strong>
          </label>
          <strong>{items.qa_report.price_formatted}</strong>
        </div>
      </div>
      {/* Captions */}
      {!order.closed_captions && (
        <div className='pb3'>
          <div className='flex items-center mb1'>
            <span className='material-icons mr1'>closed_caption</span>
            <strong>Closed Captioning</strong>
          </div>
          <p>Create closed captioning for this DCP.</p>
          <div className='mb1 flex items-center'>
            <Checkbox
              id={`cc`}
              checked={order.add_captioning === true}
              onChange={() => updateOrder("add_captioning", !order.add_captioning)}/>
            <label className='cs-label flex-auto' htmlFor={`cc`}>
              <strong className='px1'>Add Closed Captioning</strong>
            </label>
            <strong>
              {items.include_captioning.currency_symbol}
              {(
                items.include_captioning.price +
                (items.captions_base.price + items.captions_per_minute_5_days.price * lengthInMins)
              ).toFixed(2)}
            </strong>
          </div>
        </div>
      )}
    </div>
    <div className='flex justify-between pt3 col-12'>
      <button className='cs-button white' onClick={prevStep}>
        Back
      </button>
      <button className='cs-button' disabled={false} onClick={nextStep}>
        Next
      </button>
    </div>
  </div>
)

DCPAddons.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  order: PropTypes.object,
  items: PropTypes.object.isRequired,
  lengthInMins: PropTypes.number.isRequired,
  updateOrder: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const order = state.orders.active_order
  return {
    order,
    items: state.utils.dcp_items,
    lengthInMins: order.source_file.length_in_minutes
  }
}
const mapDispatchToProps = dispatch => ({
  prevStep: bindActionCreators(prevStep, dispatch),
  nextStep: bindActionCreators(nextStep, dispatch),
  updateOrder: bindActionCreators(updateOrder, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DCPAddons)
