import {
  GET_PROJECTS,
  UPDATE_PROJECTS,
  ADD_PROJECT,
  UPDATE_PROJECTS_LIST,
  TOGGLE_CHECKED_PROJECT,
  SET_CONVERT_PROJECT_TO_FOLDER,
  DELETE_PROJECTS,
  CLOSE_PROJECT_DELETION_ERROR_MODAL,
  TOGGLE_DELETE_PROJECT_MODAL
} from "@actions/projects/"

import { push } from "connected-react-router"

// import { SET_PROJECT } from '@actions/project'

import { get, post, del } from "@api/fetch"
import { addGlobalMessage } from "@api/utils/"

import Tracking from "@helpers/tracking"

/**
 * Get Projects
 * - get the user/orgs projects
 *
 * @returns dispatch
 */

export const getProjects = (folderSlug = null) => dispatch =>
  dispatch(get(GET_PROJECTS, `projects${folderSlug ? `?folder=${folderSlug}` : ``}`))

export const searchProjects = (query = "") => dispatch => dispatch(get(GET_PROJECTS, `projects?query=${query}`))

export const getAllProjects = () => dispatch => dispatch(get(GET_PROJECTS, `projects/all`))

/**
 * Update Projects
 * - update the projects state
 *
 * @returns dispatch
 */

export const updateProjects = (key, value) => dispatch => dispatch({ type: UPDATE_PROJECTS, key, value })

/**
 * Delete Projects
 * - Delete selected projects
 *
 * @returns dispatch
 */

export const deleteProjects = projectIDs => (dispatch, getState) => {
  const currentFolderSlug = getState().projects.breadcrumb.length
    ? getState().projects.breadcrumb[getState().projects.breadcrumb.length - 1].slug
    : null

  dispatch(
    del(
      DELETE_PROJECTS,
      `projects`,
      {
        projectIDs,
        currentFolderSlug
      },
      () => {
        dispatch(addGlobalMessage("Project(s) Deleted", "success"))
      }
    )
  )
}

/**
 * Close error modal
 * - update the deletingProjects state
 *
 * @returns dispatch
 */

export const closeErrorModal = () => dispatch => dispatch({ type: CLOSE_PROJECT_DELETION_ERROR_MODAL })

export const toggleDeleteProjectModal = () => dispatch => dispatch({ type: TOGGLE_DELETE_PROJECT_MODAL })

/**
 * Update Projects List
 * - update the projects state
 *
 * @returns dispatch
 */

export const updateProjectsList = (projectID, key, value) => dispatch =>
  dispatch({ type: UPDATE_PROJECTS_LIST, projectID, key, value })

/**
 * Add Project
 * - creates a new project
 *
 * @returns dispatch
 */

export const addProject = (cb = null) => (dispatch, getState) => {
  const currentFolderSlug = getState().projects.breadcrumb.length
    ? getState().projects.breadcrumb[getState().projects.breadcrumb.length - 1].slug
    : null

  dispatch(
    post(ADD_PROJECT, `projects`, { title: getState().projects.newProject.title, currentFolderSlug }, result => {
      dispatch(addGlobalMessage(`${result.title} Created!`, "success"))
      dispatch(push(`/projects/${result._id}/files`))

      Tracking.record("Create project", {
        "Project name": result.title,
        "Name length": result.title.length
      })

      if (typeof cb === "function") {
        cb(result)
      }
    })
  )
}

export const toggleChecked = project => dispatch => dispatch({ type: TOGGLE_CHECKED_PROJECT, payload: { project } })

export const setConvertProjectToFolder = project => dispatch =>
  dispatch({ type: SET_CONVERT_PROJECT_TO_FOLDER, payload: { project } })
