export const GET_CUSTOM_FIELDS = "GET_CUSTOM_FIELDS"
export const GET_CUSTOM_FIELDS_PENDING = "GET_CUSTOM_FIELDS_PENDING"
export const GET_CUSTOM_FIELDS_FULFILLED = "GET_CUSTOM_FIELDS_FULFILLED"
export const GET_CUSTOM_FIELDS_FAILED = "GET_CUSTOM_FIELDS_REJECTED"
export const CREATE_CUSTOM_FIELD = "CREATE_CUSTOM_FIELD"
export const CREATE_CUSTOM_FIELD_PENDING = "CREATE_CUSTOM_FIELD_PENDING"
export const CREATE_CUSTOM_FIELD_FULFILLED = "CREATE_CUSTOM_FIELD_FULFILLED"
export const UPDATE_CUSTOM_FIELD = "UPDATE_CUSTOM_FIELD"
export const UPDATE_CUSTOM_FIELD_PENDING = "UPDATE_CUSTOM_FIELD_PENDING"
export const UPDATE_CUSTOM_FIELD_FULFILLED = "UPDATE_CUSTOM_FIELD_FULFILLED"
export const SAVE_CUSTOM_FIELD = "SAVE_CUSTOM_FIELD"
export const SAVE_CUSTOM_FIELD_PENDING = "SAVE_CUSTOM_FIELD_PENDING"
export const SAVE_CUSTOM_FIELD_FULFILLED = "SAVE_CUSTOM_FIELD_FULFILLED"
export const DELETE_CUSTOM_FIELD = "DELETE_CUSTOM_FIELD"
export const DELETE_CUSTOM_FIELD_PENDING = "DELETE_CUSTOM_FIELD_PENDING"
export const DELETE_CUSTOM_FIELD_FULFILLED = "DELETE_CUSTOM_FIELD_FULFILLED"
