import {
  GET_CUSTOM_FIELDS,
  CREATE_CUSTOM_FIELD,
  UPDATE_CUSTOM_FIELD,
  SAVE_CUSTOM_FIELD,
  DELETE_CUSTOM_FIELD
} from "@actions/project/custom_fields"

import { get, post, put, del } from "@api/fetch"

/**
 * Get custom fields
 *
 * @param {string} projectID
 *
 * @returns dispatch
 */

export const getCustomFields = projectID => (dispatch, getState) => {
  dispatch(get(GET_CUSTOM_FIELDS, `projects/${projectID}/customFields`))
}

/**
 * Create custom field
 *
 * @param {string} projectID
 *
 * @returns dispatch
 */

export const createCustomField = projectID => (dispatch, getState) => {
  dispatch(post(CREATE_CUSTOM_FIELD, `projects/${projectID}/customFields`))
}

/**
 * Update custom field
 * - update custom field state on client
 * - NOTE: does not save to server
 *
 * @returns dispatch
 */

export const updateCustomField = (customFieldID, key, value) => dispatch =>
  dispatch({ type: UPDATE_CUSTOM_FIELD, customFieldID, key, value })

/**
 * Saves custom field
 *
 * @param {string} projectID, key, value
 *
 * @returns dispatch
 */

export const saveCustomField = (projectID, customFieldID, key, value) => (dispatch, getState) => {
  dispatch(
    put(SAVE_CUSTOM_FIELD, `projects/${projectID}/customFields/${customFieldID}`, {
      key,
      value
    })
  )
}

/**
 * Delete custom field
 *
 * @param {string} customFieldID
 *
 * @returns dispatch
 */

export const deleteCustomField = (projectID, customFieldID) => (dispatch, getState) => {
  dispatch(del(DELETE_CUSTOM_FIELD, `projects/${projectID}/customFields/${customFieldID}`))
}
