import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { showGlobalAuthModal } from "@api/account/auth"
import Logo from "@src/public/logo.inline.svg"
import environment from "@src/helpers/environment"
import { GLOBALS } from "@src/config"
import "./styles.scss"

class Header extends React.Component {
  onClick = () => {
    if (this.props.authenticated) {
      window.open(environment.get("URL"), "_blank")
    }
    else {
      window.open(`${GLOBALS.ACCOUNTS_URL}/login?redirect=${window.location.href}`, "_self")
      // this.props.showGlobalAuthModal()
    }
  }
  render() {
    return (
      <nav
        className={`nav fixed top-0 left-0 right-0 bg-white box-shadow flex items-center justify-between z4 px3`}>
        <a href='//cinesend.com' target='_blank' rel='noopener noreferrer' className='flex items-center'>
          {this.props.logoUrl ? (
            <img src={this.props.logoUrl} className='cs-image' style={{ maxWidth: "100px", maxHeight: "50px" }}/>
          ) : (
            <Logo className={"logo"}/>
          )}
        </a>
        <button onClick={this.onClick} className='cs-button outline small'>
          {this.props.authenticated ? "View My Account" : "Log In"}
        </button>
      </nav>
    )
  }
}

Header.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  authenticated: PropTypes.bool.isRequired,
  showGlobalAuthModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  logoUrl: state.file_request.logo_url ?? state.download_link.logo_url,
  authenticated: state.user.auth.status === "AUTHENTICATED"
})

const mapDispatchToProps = dispatch => ({
  showGlobalAuthModal: bindActionCreators(showGlobalAuthModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
