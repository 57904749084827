import dayjs from "dayjs"
import uniq from "lodash.uniq"

/**
 * Parse string for searching/filtering
 *
 * @param {String} string
 *
 * @returns {String}
 */

const parseString = string => {
  const s = string || ""
  return s
    .toString()
    .trim()
    .toLowerCase()
}

/**
 * Search by Key Value
 *
 * @param {String} fileName
 * @param {String} searchText
 *
 * @returns {Boolean}
 */

const search = (fileName = "", searchText) => parseString(fileName).includes(parseString(searchText))

/**
 * Filter File Status
 *
 * @param {String} Status
 *
 * @returns {Boolean}
 */

const ready = status => status !== "Awaiting Content"

/**
 * Filter File Tags
 *
 * @param {Array} fileTags
 * @param {Array} filterTags
 *
 * @returns {Boolean}
 */

const tagged = (fileTags = [], filterTags = []) => {
  if(filterTags.length > 0) {
    const foundMatch = fileTags.filter(tag => filterTags.includes(tag._id))
    return (foundMatch.length > 0)
  }
  return true
}

/**
 * Filter File Type
 *
 * @param {Object} file1
 * @param {Object} file2
 * @param {String} key
 *
 * @returns {Boolean}
 */

const sort = (file1, file2, key) =>
  key === "created_at"
    ? parseFloat(dayjs(file1.created_at).format("X")) - parseFloat(dayjs(file2.created_at).format("X"))
    : key === "size"
      ? (file1.size || 0) - (file2.size || 0)
      : parseString(file1[key]).localeCompare(parseString(file2[key]))

/**
 * Filter File Type
 *
 * @param {String} fileType
 * @param {Array} filterTypes
 *
 * @returns {Boolean}
 */

const type = (fileType = "", filterTypes) => !filterTypes.length || filterTypes.includes(fileType)

/**
 * System "dot files"
 *
 * @param {Object} file
 *
 * @returns {Boolean}
 */

const systemFile = file => parseString(file.name).charAt(0) !== "."

/**
 * Filter Files
 *
 * @param {Array} files
 * @param {Object} filter
 *
 * @returns {Array}
 */

export const filterFiles = (files, filter) => {
  let filtered = files.filter(
    file =>
      search(file.name, filter.search) &&
      ready(file.status) &&
      tagged(file.tags, filter.tags) &&
      type(file.type, filter.types) &&
      systemFile(file)
  )
  if (filter.key) {
    filtered = filtered.sort((a, b) => sort(a, b, filter.key))
  }

  return filter.direction === "ASC" ? filtered : filtered.reverse()
}
