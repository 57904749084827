import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Select from "react-select"
import { Modal } from "@bitcine/cs-theme"
import {
  HIDE_ACCESS_INVITES,
  ADD_ACCESS_INVITE_ROW,
  UPDATE_INVITE_ACCESS_ROW,
  RESET_ACCESS_INVITES,
  REMOVE_ACCESS_INVITE_ROW
} from "@actions/project/access"

import { getTeamRoles } from "@api/settings/organizations/roles"

import { sendInvites } from "@api/project/access"

class ProjectInvites extends Component {
  componentDidMount() {
    this.props.getTeamRoles()
  }
  render() {
    const {
      hideInviteView,
      roles,
      membersToInvite,
      addRow,
      update,
      resetInvites,
      removeRow,
      sendInvites,
      isSending,
      errorMessage
    } = this.props

    return (
      <Modal
        open
        width={3}
        onClose={() => {
          hideInviteView()
          resetInvites()
        }}
        title='Invite Team Members'>
        <div className='col-12'>
          {membersToInvite.map((invite, i) => (
            <div key={i} className='flex mb2 pb2 border-bottom border-gray-4'>
              <div className='col-4 pr1'>
                <label className='cs-label' htmlFor={`invite_name_${i}`}>
                  Name
                </label>
                <input
                  id={`invite_name_${i}`}
                  placeholder='First and last name'
                  className='cs-input col-12'
                  value={invite.name}
                  onChange={e => update(i, "name", e.target.value)}/>
              </div>
              <div className='col-4 px1'>
                <label className='cs-label' htmlFor={`invite_email_${i}`}>
                  Email
                </label>
                <input
                  id={`invite_email_${i}`}
                  placeholder='Enter email address'
                  className='cs-input col-12'
                  value={invite.email}
                  onChange={e => update(i, "email", e.target.value)}/>
              </div>
              <div className={`${i > 0 ? "flex-auto" : "col-4"} pl1`}>
                <label className='cs-label'>Role</label>
                <Select
                  options={roles.map(role => ({
                    value: role._id,
                    label: role.name
                  }))}
                  value={invite.role_id}
                  clearable={false}
                  className={`col-12 no-min-width`}
                  onChange={e => update(i, "role_id", e.value)}/>
              </div>

              {i > 0 && (
                <div className='pl1' style={{ paddingTop: "22px" }}>
                  <button className='cs-button link tooltip' data-tooltip='Remove' onClick={() => removeRow(i)}>
                    <i className='material-icons'>delete</i>
                  </button>
                </div>
              )}
            </div>
          ))}

          <div className='col-12 mb2'>
            <button className='cs-button white small' onClick={addRow}>
              Invite another team member
            </button>
          </div>

          <div className='flex'>
            <div className='flex-auto'/>
            <div className='flex items-center red pr2'>{errorMessage.length > 0 ? errorMessage : ""}</div>
            <button
              disabled={isSending}
              className='cs-button white'
              onClick={() => {
                hideInviteView()
                resetInvites()
              }}>
              Back
            </button>

            <button className='cs-button ml1' disabled={isSending || (membersToInvite.filter((member) => member.role_id === '').length > 0)} onClick={sendInvites}>
              {isSending ? "Sending..." : "Send Invite"}
            </button>
          </div>
        </div>
      </Modal>
    )
  }
}

ProjectInvites.propTypes = {
  hideInviteView: PropTypes.func.isRequired,
  getTeamRoles: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  membersToInvite: PropTypes.array.isRequired,
  addRow: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  resetInvites: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  sendInvites: PropTypes.func.isRequired,
  isSending: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string
}

const mapStateToProps = state => ({
  roles: state.settings.organizations.roles.items,
  membersToInvite: state.project.access.membersToInvite,
  isSending: state.project.access.isSending,
  errorMessage: state.project.access.error
})

const mapDispatchToProps = dispatch => ({
  getTeamRoles: bindActionCreators(getTeamRoles, dispatch),
  hideInviteView: () => dispatch({ type: HIDE_ACCESS_INVITES }),
  addRow: () => dispatch({ type: ADD_ACCESS_INVITE_ROW }),
  update: (i, target, value) => dispatch({ type: UPDATE_INVITE_ACCESS_ROW, payload: { i, target, value } }),
  resetInvites: () => dispatch({ type: RESET_ACCESS_INVITES }),
  removeRow: i => dispatch({ type: REMOVE_ACCESS_INVITE_ROW, payload: { i } }),
  sendInvites: bindActionCreators(sendInvites, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectInvites)
