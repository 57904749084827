import React from "react"

const ForensicWatermark = () => (
  <div className='center white'>
    <span className={`material-icons block large-icon`}>lock</span>
    <h4>Protected Content!</h4>
    <div className='flex'>
      <p>
        To keep your content secure, the DRM-encrypted, forensic watermarked version of this video is not available for
        preview in-app.
      </p>
    </div>
  </div>
)

export default ForensicWatermark
