import {
  GET_COUNTRIES,
  GET_TIMEZONES,
  GET_LANGUAGES,
  GET_USER_LOCATION,
  GET_FRAME_RATES,
  GET_RESOLUTIONS,
  GET_VIDEO_FORMATS,
  ADD_GLOBAL_MESSAGE,
  REMOVE_GLOBAL_MESSAGE,
  GET_GENRES,
  UPDATE_UTILS
} from "@actions/utils/"
import { get } from "@api/fetch"
import uuidv4 from "uuid/v4"

/**
 * Update utils redux state
 *
 * @param {String} $key
 * @param {String} $value
 *
 * @returns {Function}
 */

export const updateUtils = (key, value) => dispatch => dispatch({ type: UPDATE_UTILS, key, value })

/**
 * Adds a message to the global snackbar UI
 *
 * Based on @bitcine/cs-theme <Snackbar/>
 * For more info see: https://bitcine.github.io/@bitcine/cs-theme/
 *
 * @param {String/Node} $message
 * A message or React element to render
 * Examples: 'Files Deleted!' or <div>Files Deleted</div>
 * String should be used if possible for performance reasons
 * @param {String} $type
 * Either 'info', 'warning', 'error' or 'success'
 * Defauls to 'info'
 * @param {String/Null} $icon
 * A material-icon https://material.io/tools/icons
 * If left blank, the icon will be rendered based on 'type' param
 * @param {Int/Null} $timeout
 * The time to leave the snackbar in the UI.
 * Defaults to 3000 and 'null' will keep it always open (use with caution)
 * @param {Boolean} $showCloseButton
 * Shows the close button in the UI
 *
 * @returns {String}
 * The unique identifer for the message
 */

export const addGlobalMessage = (message, type = "info", icon, timeout = 3000, showCloseButton = false) => dispatch => {
  const _id = uuidv4()
  dispatch({
    type: ADD_GLOBAL_MESSAGE,
    payload: {
      _id,
      message,
      icon,
      type,
      timeout,
      showCloseButton,
      onClose: () => dispatch(removeGlobalMessage(_id))
    }
  })
  return _id
}

/**
 * Removes a message from the global snackbar UI
 *
 * @param {String} $_id
 * The unique identifer of the snackbar item
 * that is created when its added to state
 *
 * @returns {Function}
 */

export const removeGlobalMessage = _id => dispatch => dispatch({ type: REMOVE_GLOBAL_MESSAGE, _id })

/**
 * Utility Functions
 *
 * Various utils functions to get data from the server
 *
 * @returns {Function}
 */

export const getCountries = () => dispatch => {
  if (dispatch(validateShouldGetUtil("countries"))) dispatch(get(GET_COUNTRIES, `utilities/countries`))
}

export const getTimezones = () => dispatch => {
  if (dispatch(validateShouldGetUtil("timezones"))) dispatch(get(GET_TIMEZONES, `utilities/timezones`))
}

export const getLanguages = () => dispatch => {
  if (dispatch(validateShouldGetUtil("languages"))) dispatch(get(GET_LANGUAGES, `utilities/languages`))
}

export const getFrameRates = () => dispatch => {
  if (dispatch(validateShouldGetUtil("frame_rates"))) dispatch(get(GET_FRAME_RATES, `utilities/framerates`))
}

export const getResolutions = () => dispatch => {
  if (dispatch(validateShouldGetUtil("resolutions"))) dispatch(get(GET_RESOLUTIONS, `utilities/resolutions`))
}

export const getVideoFormats = () => dispatch => {
  if (dispatch(validateShouldGetUtil("video_formats"))) dispatch(get(GET_VIDEO_FORMATS, `utilities/videoformats`))
}

export const getGenres = () => dispatch => {
  if (dispatch(validateShouldGetUtil("genres"))) dispatch(get(GET_GENRES, `utilities/genres`))
}

/**
 * Validates that the api call should be made
 *
 * If the util being fetched in state is already in redux,
 * there is no need to make the api call again. Utility
 * data do not change, so only one fetch per session is required
 *
 *
 * @param {String} $utility
 *
 * @returns {Boolean}
 */

const validateShouldGetUtil = utility => (dispatch, getState) => !getState().utils[utility]

/**
 * Retrieve the users geo location
 *
 * @returns {Function}
 */

export const getUserLocation = () => dispatch => dispatch(get(GET_USER_LOCATION, `users/location`))
