import React from "react"
import PropTypes from "prop-types"
import Viewer from "react-viewer"

class Image extends React.Component {
  constructor(props) {
    super(props)
    this.img = null
  }
  render() {
    return (
      <div>
        <div ref={ref => (this.img = ref)}/>
        <Viewer
          visible={true}
          container={this.img}
          onClose={() => null}
          noClose={true}
          noFooter={false}
          rotatable={true}
          noNavbar={false}
          zIndex={5}
          drag={false}
          images={[{ src: this.props.url }]}/>
      </div>
    )
  }
}

Image.propTypes = {
  url: PropTypes.string.isRequired
}

export default Image
