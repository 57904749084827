import React from "react"
import ExpiredIcon from "@src/public/icons/expired.inline.svg"
import "../styles.scss"

const Expired = () => (
  <div data-testId='download-link-expired-ui' className='center py4 mt3 mb4'>
    <ExpiredIcon className={"expired"}/>
    <h2>Expired</h2>
    <p>The owner of this content has expired this link.</p>
  </div>
)

export default Expired
