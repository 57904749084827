import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import AppSwitcher from "@bitcine/cinesend-app-switcher"
import { Link } from "react-router-dom"
import Logo from "@src/public/logo.inline.svg"
import User from "./components/main/user"
import Cart from "./components/main/cart"
import Transfers from "./components/main/transfers"
import GhostingBar from "./components/ghosting_bar"
import OrganizationNavigation from "./containers/organization"
import 'styled-components/macro'

const DesktopNav = ({ user }) => 
  <nav
    css={`
      z-index: 10;
    `}
    className='fixed top-0 left-0 col-12 bg-white px3 box-shadow'
    id='nav'>
    <div
      css={`
        height: 56px;
      `}
      className='flex items-center col-12'>
      <div
        css={`
          margin-left: -12px;
        `}
        className='mr2'>
        <AppSwitcher
          apps={Object.keys(user.app_permissions)
            .filter(key => !user.app_permissions[key].hide)
            .reduce((all, key) => ({ ...all, [key]: user.app_permissions[key] }), {})}
          activeApplicationKey='files'
          onActivate={() => {}}/>
      </div>

      <Link className='flex items-center' to='/'>
        <Logo
          css={`
            width: 100px;
            height: 40px;
          `}/>
        <strong className='ml2'>Files</strong>
      </Link>
      <div className='flex flex-auto items-center justify-end'>
        {user.organization_id !== null && <OrganizationNavigation/>}
        <Transfers/>
        <Cart/>
        <User/>
      </div>
    </div>  
    <GhostingBar/>
  </nav>

DesktopNav.propTypes = {
  user: PropTypes.shape({
    organization_id: PropTypes.string,
    app_permissions: PropTypes.object.isRequired
  }).isRequired
}

const mapStateToProps = state => ({
  user: state.user.auth
})

export default connect(mapStateToProps)(DesktopNav)
