export const INITIALIZE_FINE_UPLOADER = "INITIALIZE_FINE_UPLOADER"
export const INITIALIZE_FINE_UPLOADER_PENDING = "INITIALIZE_FINE_UPLOADER_PENDING"
export const INITIALIZE_FINE_UPLOADER_FAILED = "INITIALIZE_FINE_UPLOADER_REJECTED"
export const INITIALIZE_FINE_UPLOADER_FULFILLED = "INITIALIZE_FINE_UPLOADER_FULFILLED"

export const ADD_TRANSFER = "ADD_TRANSFER"
export const REMOVE_TRANSFER = "REMOVE_TRANSFER"

export const UPDATE_CHILD_TRANSFER = "UPDATE_CHILD_TRANSFER"
export const UPDATE_TRANSFER = "UPDATE_TRANSFER"
export const TRANSFER_PROGRESS = "TRANSFER_PROGRESS"
export const ASPERA_PROGRESS = "ASPERA_PROGRESS"

export const SET_TRANSFER_FULFILLED = "SET_TRANSFER_FULFILLED"
export const SET_TRANSFER_FULFILLED_FULFILLED = "SET_TRANSFER_FULFILLED_FULFILLED"

export const TRANSFER_ERROR = "TRANSFER_ERROR"
export const POST_UPLOAD_PROGRESS = "POST_UPLOAD_PROGRESS"
