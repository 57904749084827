import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Select from "react-select"
import { Modal, Status, Button } from "@bitcine/cs-theme"
import { getAvailableProjects, addProjectsToRoom } from "@api/screener_rooms/"

const AddProjects = ({
  organizationID,
  screenerRoomID,
  isPending,
  items,
  getAvailableProjects,
  addProjectsToRoom,
  onClose
}) => {
  const [selectedProjectIDs, setSelectedProjectIDs] = useState([])
  useEffect(() => {
    getAvailableProjects(organizationID, screenerRoomID)
  }, [organizationID, screenerRoomID, getAvailableProjects])
  return (
    <Modal open={true} title='Add Projects to Room' onClose={onClose}>
      <Status pending={isPending}>
        <p>Select which projects you would like to add to this screener room.</p>
        <Select
          className='col-12'
          options={items}
          multi={true}
          value={items.filter(option => selectedProjectIDs.includes(option.value))}
          onChange={values => setSelectedProjectIDs(values.map(({ value }) => value))}/>
        <div className='flex justify-end mt2'>
          <Button onClick={() => addProjectsToRoom(organizationID, screenerRoomID, selectedProjectIDs)}>
            Add Projects
          </Button>
        </div>
      </Status>
    </Modal>
  )
}

AddProjects.propTypes = {
  organizationID: PropTypes.string.isRequired,
  screenerRoomID: PropTypes.string.isRequired,
  isPending: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  getAvailableProjects: PropTypes.func.isRequired,
  addProjectsToRoom: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  isPending: state.screener_rooms.screenerRoom.availableProjects.status === "PENDING",
  items: state.screener_rooms.screenerRoom.availableProjects.items.map(item => ({
    value: item._id,
    label: item.title
  })),
  organizationID: state.user.auth.organization_id,
  screenerRoomID: state.screener_rooms.screenerRoom.data._id
})

const mapDispatchToProps = dispatch => ({
  getAvailableProjects: bindActionCreators(getAvailableProjects, dispatch),
  addProjectsToRoom: bindActionCreators(addProjectsToRoom, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AddProjects)
