import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Status } from "@bitcine/cs-theme"
import { getScreenerEndpoints } from "@api/project/files/screeners"
import CineSendPlayer from "@bitcine/cinesend-player"
import EncodingScreener from "./encoding_screener"

class Screener extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      endpoints: null
    }
  }
  componentDidMount() {
    this.props.getScreenerEndpoints(this.props.screener._id, "mp4_360p", res => {
      this.setState({ endpoints: res.data.endpoints })
    })
  }
  viewable() {
    return this.props.screener.mp4_360p_job_status === "Complete" && this.state.endpoints
  }
  render() {
    return (
      <Status pending={!this.state.endpoints || this.props.screener.status === "PENDING"} error={this.props.screener.status === "ERROR"}>
        {this.viewable()
          ? <div style={{ height: '500px', aspectRatio: '16/9' }}>
              <CineSendPlayer
                playerID={"files-cinesend-player-id"}
                debug={process.env.NODE_ENV !== "production"}
                playerCode={"bitmovin"}
                endpoints={this.state.endpoints}
                autoPlay={false}
                activeIndex={0}
                fillViewport={false}
                onVideoChange={video => console.log("Video set to: ", video)}
                onPlayerCreation={player => this.setState({ player })}
                onPlayerEvent={event => console.log("Event log: ", event)}/>
            </div>
          : <EncodingScreener/>}
      </Status>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getScreenerEndpoints: bindActionCreators(getScreenerEndpoints, dispatch)
})

Screener.propTypes = {
  screener: PropTypes.object.isRequired,
  getScreenerEndpoints: PropTypes.func.isRequired
}

export default connect(null, mapDispatchToProps)(Screener)
