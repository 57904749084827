import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Modal, Status } from "@bitcine/cs-theme"
import Project from "./project"
import File from "./file"

class SelectExisting extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active_project_id: "",
      open: "project"
    }
  }
  render() {
    const { request } = this.props
    const { open } = this.state
    return (
      <Modal title={`Select ${open}`} open={true} onClose={this.props.onClose}>
        <Status pending={request.import_status === "PENDING"} error={request.import_status === "ERROR"}>
          {open === "file" ? (
            <File
              close={() => this.setState({ active_project_id: "", open: "project" })}
              selectFiles={(files, project_id) => this.props.selectFiles(files, project_id)}
              projectID={this.state.active_project_id}/>
          ) : (
            <Project setProject={_id => this.setState({ active_project_id: _id, open: "file" })}/>
          )}
        </Status>
      </Modal>
    )
  }
}

SelectExisting.propTypes = {
  request: PropTypes.object.isRequired,
  selectFiles: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

const mapStateToProps = ({ file_request }) => ({
  request: file_request
})

export default connect(mapStateToProps)(SelectExisting)
