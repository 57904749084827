import React from "react"
import ErrorBoundary from "@components/error_boundaries/"
import TourManager from "@components/tour/manager"
import Project from "./project"

const ProjectWrap = props => (
  <ErrorBoundary>
    <Project {...props}/>

    <TourManager
      steps={[
        {
          key: "upload_files"
        },
        {
          key: "project_members"
        }
      ]}/>
  </ErrorBoundary>
)

export default ProjectWrap
