import React from "react"
import UploadImg from "../images/upload.png"
import BaseSlide from "./components/base"
import Footer from "./components/footer"
import 'styled-components/macro'

const FilesSlide = () => (
  <BaseSlide>
    <div
      css={`
        padding: 0px 40px 0 40px;
        transform: translateY(-20px);
      `}>
      <img className='cs-img' src={UploadImg} alt='Upload'/>
    </div>
    <Footer
      bg='red'
      header='Start uploading files'
      content='You can upload any kind of file to CineSend, regardless of the type or size. If you have a large file, or you have internet connection issues, try using the Aspera Connect option.'/>
  </BaseSlide>
)

export default FilesSlide
