import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Link } from "react-router-dom"
import { css } from "emotion"
import { can, MANAGE_SCREENERS, VIEW_SCREENER_ROOMS } from "@src/helpers/authorization"
import { ButtonDropdown } from "@bitcine/cs-theme"
import { push } from "connected-react-router"

const linkStyle = css`
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`

const activeLinkStyle = css`
  text-decoration: underline !important;
  color: #fb0f3b !important;
`

class MainNav extends React.Component {
  render() {
    const { location, user, mobile, push } = this.props
    if (mobile) {
      return (
        <ButtonDropdown
          button={{
            className: "link ml2 material-icons",
            text: "menu"
          }}
          dropdown={{
            clickCloses: true,
            content: [
              {
                text: 'Projects',
                onClick: () => push('/projects'),
                default: true
              },
              {
                text: 'Screeners Rooms',
                permission: VIEW_SCREENER_ROOMS,
                onClick: () => push('/screener-rooms')
              },
              {
                text: 'Screener Summary',
                permission: MANAGE_SCREENERS,
                onClick: () => push('/reviewers')
              }
            ].filter(opt => opt.default || can(user, [opt.permission]))
          }}/>
      )
    }
    return (
      <div className='ml3 flex items-center justify-center relative'>
        {[
          { label: "Projects", key: "/projects", startsWith: "/projects", default: true },
          {
            label: "Screener Rooms",
            key: "/screener-rooms",
            startsWith: "/screener-rooms",
            permission: VIEW_SCREENER_ROOMS
          },
          { label: "Screener Summary", key: "/reviewers", startsWith: "/reviewers", permission: MANAGE_SCREENERS }
        ]
          .filter(link => link.default || can(user, [link.permission]))
          .map(link => (
            <Link
              key={link.key}
              className={`mr2 py1 px1 ${linkStyle} ${location.startsWith(link.startsWith) ? activeLinkStyle : ""}`}
              to={link.key}>
              {link.label}
            </Link>
          ))}
      </div>
    )
  }
}

MainNav.propTypes = {
  location: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  location: state.router.location.pathname,
  user: state.user.auth
})


const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MainNav)
