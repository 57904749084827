import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { bindActionCreators } from "redux"
import { toggleNewReviewers } from "@api/preview/send"
import { ButtonDropdown } from "@bitcine/cs-theme"
import { updateScreenerRoom, setScreenerRoom, toggleScreenerDeleteModal, duplicateScreenerRoom } from "@api/screener_rooms/"
import NoScreenerRoomIcon from "@src/public/icons/empty/screener-room.inline.svg"
import { canShareScreenerRoom, canModifyScreenerRoom } from "@src/helpers/authorization"
import "../style.scss"
import Title from "./title"
import 'styled-components/macro'

class ScreenerRoomCard extends Component {
  constructor() {
    super()
    // The slide update timing in milliseconds
    this.slideShowInterval = 1200
    this.loop = null
    this.totalSlides = 0
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.state = {
      visibleSlideIdx: 0,
      editing: false
    }
  }
  componentDidMount() {
    this.totalSlides = this.props.screeners.filter(s => !!s).length
  }
  componentWillUnmount() {
    this.reset()
  }
  handleMouseEnter = () => {
    this.updateSlider()
    this.tick()
  }
  handleMouseLeave = () => {
    this.reset()
  }
  tick = () => {
    this.loop = setTimeout(() => {
      this.updateSlider()
      this.tick()
    }, this.slideShowInterval)
  }
  updateSlider = () => {
    if (this.totalSlides < 2) return

    this.setState({
      visibleSlideIdx: this.totalSlides - 1 === this.state.visibleSlideIdx ? 0 : this.state.visibleSlideIdx + 1
    })
  }
  reset = () => {
    if (this.loop) {
      clearTimeout(this.loop)
      this.loop = null
    }

    this.setState({
      visibleSlideIdx: 0
    })
  }
  menu() {
    const user = this.props.user
    const room = this.props
    if (!canShareScreenerRoom(user, room) && !canShareScreenerRoom(user, room)) return null
    return (
      <div
        className={`absolute top-0 right-0 p1 z4`}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}>
        <div className={"menu"}>
          <ButtonDropdown
            button={{
              className: "link white",
              menu: true,
              text: <span className={"option"}>more_vert</span>
            }}
            dropdown={{
              clickCloses: true,
              content: [
                {
                  text: "Share by Email",
                  show: canShareScreenerRoom(user, room),
                  onClick: e => {
                    this.props.toggleNewReviewers("email", "screenerRoom", room)
                    this.props.setScreenerRoom({
                      _id: this.props._id,
                      name: this.props.name
                    })
                  }
                },
                {
                  text: "Share by URL",
                  testId: `screener_rooms_card_sburl_button_${this.props.idx}`,
                  show: canShareScreenerRoom(user, room),
                  onClick: e => {
                    this.props.toggleNewReviewers("link", "screenerRoom", room)
                    this.props.setScreenerRoom({
                      _id: this.props._id,
                      name: this.props.name
                    })
                  },
                  breakAfter: true
                },
                {
                  text: "Rename Screener Room",
                  show: canModifyScreenerRoom(user, room),
                  onClick: e => this.setState({ editing: true })
                },
                {
                  text: "Duplicate Screener Room",
                  show: canModifyScreenerRoom(user, room),
                  onClick: e => {
                    if (window.confirm("Are you sure you want to duplicate this screener room?")) {
                      this.props.duplicateScreenerRoom(room)
                    }
                  }
                },
                {
                  text: "Delete Screener Room",
                  show: canModifyScreenerRoom(user, room),
                  onClick: e => {
                    this.props.toggleScreenerDeleteModal({
                      _id: this.props._id,
                      name: this.props.name
                    })
                  }
                }
              ].filter(opt => opt.show)
            }}/>
        </div>
      </div>
    )
  }
  render() {
    const { _id, name, screeners, idx } = this.props
    const { visibleSlideIdx } = this.state
    const screenerURLs = screeners.filter(s => !!s)
    return (
      <div className='pt1 px1 pb2' data-testid={`screener_rooms_card_el_${idx}`}>
        <Link to={`/screener-rooms/${_id}`}>
          {screeners.length && screenerURLs.length ? (
            <div
              className={"screenerRoomCard"}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}>
              <div className={"slideShow"}>
                {screenerURLs.map((screener, i) => (
                  <div
                    key={i}
                    className={`slide ${visibleSlideIdx === i ? "visible" : ""}`}
                    style={{ backgroundImage: `url(${screener})` }}/>
                ))}
              </div>
              <div className={"grad"}/>
              {this.menu()}
            </div>
          ) : (
            <div className={"screenerRoomCard"}>
              <div className={`flex items-center justify-center inner`}>
                <div
                  className='center'
                  css={`
                    margin-top: -1em;
                  `}>
                  <NoScreenerRoomIcon
                    css={`
                      width: 100px;
                    `}/>
                  <h4
                    css={`
                      color: #c7cdcf;
                      margin-top: -1em;
                    `}>
                    {screeners.length ? "No thumbnails available" : "Empty screener room"}
                  </h4>
                </div>
                {this.menu()}
              </div>
            </div>
          )}
        </Link>
        <Title
          save={title =>
            this.props.updateScreenerRoom(this.props.orgId, _id, {
              key: "name",
              value: title
            })
          }
          close={() => this.setState({ editing: false })}
          editing={this.state.editing}
          title={name}
          _id={_id}/>
      </div>
    )
  }
}

ScreenerRoomCard.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  orgId: state.user.auth.organization_id,
  user: state.user.auth
})

const mapDispatchToProps = dispatch => ({
  updateScreenerRoom: bindActionCreators(updateScreenerRoom, dispatch),
  duplicateScreenerRoom: bindActionCreators(duplicateScreenerRoom, dispatch),
  toggleNewReviewers: bindActionCreators(toggleNewReviewers, dispatch),
  setScreenerRoom: bindActionCreators(setScreenerRoom, dispatch),
  toggleScreenerDeleteModal: bindActionCreators(toggleScreenerDeleteModal, dispatch)
})

ScreenerRoomCard.propTypes = {
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  screeners: PropTypes.array.isRequired,
  updateScreenerRoom: PropTypes.func.isRequired,
  duplicateScreenerRoom: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  toggleNewReviewers: PropTypes.func.isRequired,
  setScreenerRoom: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
  toggleScreenerDeleteModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenerRoomCard)
