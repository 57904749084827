import {
  GET_PAYMENT_METHODS_PENDING,
  GET_PAYMENT_METHODS_FULFILLED,
  GET_PAYMENT_METHODS_FAILED,
  GET_INVOICES_PENDING,
  GET_INVOICES_FULFILLED,
  GET_INVOICES_FAILED,
  UPDATE_NEW_PAYMENT_METHOD,
  ADD_CREDIT_CARD_FAILED,
  ADD_CREDIT_CARD_FULFILLED,
  ADD_GIFT_CARD_FAILED,
  ADD_GIFT_CARD_FULFILLED,
  UPDATE_BILLING,
  REMOVE_CREDIT_CARD_PENDING,
  REMOVE_CREDIT_CARD_FAILED,
  REMOVE_CREDIT_CARD_FULFILLED,
  UPDATE_GIFT_CARD,
  RETRY_PENDING_PAYMENT_PENDING,
  RETRY_PENDING_PAYMENT_FULFILLED,
  GET_ORDERS_PENDING,
  GET_ORDERS_FULFILLED,
  GET_ORDERS_FAILED
} from "@actions/account/billing"

import { TOGGLE_GIFT_CARD_FULFILLED } from "@actions/account/cart"

const initialState = {
  paymentMethods: {
    status: "PENDING",
    list: {
      gift_cards: [],
      credit_cards: []
    },
    activeGiftCards: [],
    giftCardTotal: "",
    giftCardsBeingUsed: false
  },
  invoices: {
    status: "PENDING",
    list: []
  },
  orders: {
    status: "PENDING",
    list: [],
    total: 0
  },
  newPaymentMethod: {
    status: "READY",
    visible: false,
    mode: "CREDIT_CARD",
    creditCardNumber: "",
    fullName: "",
    expiryMonth: "",
    expiryYear: "",
    cvc: "",
    code: "",
    pin: "",
    activeCardType: ""
  },
  cardToDelete: {
    status: "READY",
    _id: ""
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_BILLING:
      return {
        ...state,
        [action.key]: action.value
      }

    case GET_PAYMENT_METHODS_PENDING:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          status: state.paymentMethods.status === "READY" ? "UPDATING" : "PENDING"
        }
      }

    case GET_PAYMENT_METHODS_FULFILLED:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          status: "READY",
          list: action.payload
        }
      }

    case GET_PAYMENT_METHODS_FAILED:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          status: "ERROR"
        }
      }

    case GET_INVOICES_PENDING:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          status: state.invoices.status === "READY" ? "UPDATING" : "PENDING"
        }
      }

    case GET_INVOICES_FULFILLED:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          status: "READY",
          list: action.payload
        }
      }

    case GET_INVOICES_FAILED:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          status: "ERROR"
        }
      }

    case UPDATE_NEW_PAYMENT_METHOD:
      return {
        ...state,
        newPaymentMethod: {
          ...state.newPaymentMethod,
          [action.key]: action.value,
          ...(action.key === "mode" ? { error: null } : {})
        }
      }

    case ADD_CREDIT_CARD_FULFILLED:
      return {
        ...state,
        newPaymentMethod: initialState.newPaymentMethod,
        paymentMethods: {
          ...state.paymentMethods,
          list: action.payload
        }
      }

    case ADD_CREDIT_CARD_FAILED:
      return {
        ...state,
        newPaymentMethod: {
          ...state.newPaymentMethod,
          status: "ERROR",
          error: {
            code: action.payload.code,
            message: action.payload.message
          }
        }
      }

    case ADD_GIFT_CARD_FAILED:
      return {
        ...state,
        newPaymentMethod: {
          ...state.newPaymentMethod,
          status: "ERROR",
          error: {
            code: "server_error",
            message: "The code and pin provided do not match any available gift cards."
          }
        }
      }

    case ADD_GIFT_CARD_FULFILLED:
      return {
        ...state,
        newPaymentMethod: initialState.newPaymentMethod,
        paymentMethods: {
          ...state.paymentMethods,
          list: action.payload
        }
      }

    case REMOVE_CREDIT_CARD_PENDING:
      return {
        ...state,
        cardToDelete: {
          ...state.cardToDelete,
          status: "PENDING"
        }
      }

    case REMOVE_CREDIT_CARD_FAILED:
      return {
        ...state,
        cardToDelete: {
          ...state.cardToDelete,
          status: "ERROR"
        }
      }

    case REMOVE_CREDIT_CARD_FULFILLED:
      return {
        ...state,
        cardToDelete: initialState.cardToDelete,
        paymentMethods: {
          ...state.paymentMethods,
          list: action.payload
        }
      }

    case RETRY_PENDING_PAYMENT_PENDING:
      return {
        ...state,
        payment_status: "UPDATING"
      }

    case RETRY_PENDING_PAYMENT_FULFILLED:
      return {
        ...state,
        payment_status: "COMPLETE",
        paymentMethods: {
          ...state.paymentMethods,
          list: action.payload.payment_methods
        }
      }

    case TOGGLE_GIFT_CARD_FULFILLED:
      state.paymentMethods.activeGiftCards = action.payload.gift_cards.filter(card => card.is_payment_active)

      let total = 0
      for (let i = 0; i < state.paymentMethods.activeGiftCards.length; i += 1) {
        total += parseInt(state.paymentMethods.activeGiftCards[i].current_amount)
      }
      state.paymentMethods.giftCardTotal = total

      if (state.paymentMethods.activeGiftCards.length > 0) {
        state.paymentMethods.giftCardsBeingUsed = true
      } else {
        state.paymentMethods.giftCardsBeingUsed = false
      }

      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          list: {
            ...state.paymentMethods.list,
            gift_cards: action.payload.gift_cards
          }
        }
      }

    case UPDATE_GIFT_CARD:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          list: {
            ...state.paymentMethods.list,
            gift_cards: state.paymentMethods.list.gift_cards.map(card =>
              card._id === action.cardID ? { ...card, [action.key]: action.value } : card
            )
          }
        }
      }

    case GET_ORDERS_PENDING:
      return {
        ...state,
        orders: {
          ...state.orders,
          status: "PENDING"
        }
      }

    case GET_ORDERS_FULFILLED:
      return {
        ...state,
        orders: {
          ...state.orders,
          status: "READY",
          list: action.payload.list,
          total: action.payload.total
        }
      }

    case GET_ORDERS_FAILED:
      return {
        ...state,
        orders: {
          ...state.orders,
          status: "ERROR"
        }
      }

    default:
      return state
  }
}
