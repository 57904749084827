import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal, Status, Table } from "@bitcine/cs-theme"
import { getSharedScreenerStats, toggleModal } from "@api/shared_screeners/modals"
import Heatmap from "../components/heatmap"

class ScreenerRoomViewers extends Component {
  componentDidMount() {
    this.props.getSharedScreenerStats(this.props.sharedScreener.screener_group_id, this.props.sharedScreener._id)
  }
  render() {
    const { status, analytics, toggleModal } = this.props
    return (
      <Modal open width={3} clickOutsideCloses title='Viewer Stats' onClose={() => toggleModal("screenerRoomViewers")}>
        <Status
          pending={status === "PENDING"}
          error={status === "ERROR"}
          empty={!analytics.length}
          emptyMessage={{
            icon: "show_chart",
            title: "No Views",
            text: "This reviewer has not yet viewed any screeners in this room."
          }}>
          <Table
            widths={["auto", 130, 200]}
            header={{
              columns: [{ text: "Viewer" }, { text: "Times Watched" }, { text: "Heatmap" }]
            }}
            body={{
              data: analytics.map(a => ({ ...a, _id: a.screener_id })) || [],
              row: {
                compact: true,
                render: [
                  data => (
                    <div className='truncate'>
                      {data.screener_name}
                      {data.project_title && <span className='small block light'>{data.project_title}</span>}
                    </div>
                  ),
                  data => data.times_watched || 0,
                  data => <Heatmap data={data}/>
                ]
              }
            }}/>
        </Status>
      </Modal>
    )
  }
}

ScreenerRoomViewers.propTypes = {
  getSharedScreenerStats: PropTypes.func.isRequired,
  sharedScreener: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  analytics: PropTypes.array.isRequired,
  toggleModal: PropTypes.func.isRequired
}

const mapStateToProps = ({ shared_screeners }) => ({
  sharedScreener: shared_screeners.modals.screenerRoomViewers.sharedScreener,
  status: shared_screeners.modals.screenerRoomViewers.status,
  analytics: shared_screeners.modals.screenerRoomViewers.analytics
})

const mapDispatchToProps = dispatch => ({
  getSharedScreenerStats: bindActionCreators(getSharedScreenerStats, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ScreenerRoomViewers)
