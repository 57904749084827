import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toggleNewReviewers } from "@api/preview/send"
import { ButtonDropdown } from "@bitcine/cs-theme"

class ReviewerButtons extends React.Component {
  render() {
    let { toggleNewReviewers } = this.props
    return (
      <ButtonDropdown
        button={{
          className: "ml1 small",
          style: { paddingTop: "0.3rem" },
          text: (
            <span className='flex items-center'>
              Send Screener <i className='material-icons ml1'>hd</i>
            </span>
          )
        }}
        dropdown={{
          clickCloses: true,
          content: [
            {
              text: {
                title: "Share by email",
                description: "Send an email containing a link to reviewers"
              },
              onClick: () => toggleNewReviewers("email", "screener"),
              icon: "email"
            },
            {
              text: {
                title: "Create a link",
                description: "Create a link that can be copied and sent to reviewers"
              },
              onClick: () => toggleNewReviewers("link", "screener"),
              icon: "link"
            }
          ]
        }}/>
    )
  }
}

ReviewerButtons.propTypes = {
  toggleNewReviewers: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  toggleNewReviewers: bindActionCreators(toggleNewReviewers, dispatch)
})

export default connect(
  null,
  mapDispatchToProps
)(ReviewerButtons)
