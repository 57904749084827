export const GET_VIDEO = {
  REQUEST: "GET_VIDEO",
  PENDING: "GET_VIDEO_PENDING",
  FAILED: "GET_VIDEO_REJECTED",
  COMPLETE: "GET_VIDEO_FULFILLED"
}

export const GET_LANGUAGES = {
  REQUEST: "GET_LANGUAGES",
  PENDING: "GET_LANGUAGES_PENDING",
  FAILED: "GET_LANGUAGES_REJECTED",
  COMPLETE: "GET_LANGUAGES_FULFILLED"
}

export const UPDATE_VIDEO = {
  REQUEST: "UPDATE_VIDEO",
  PENDING: "UPDATE_VIDEO_PENDING",
  FAILED: "UPDATE_VIDEO_REJECTED",
  COMPLETE: "UPDATE_VIDEO_FULFILLED"
}

export const GET_GENRES = {
  REQUEST: "GET_GENRES",
  PENDING: "GET_GENRES_PENDING",
  FAILED: "GET_GENRES_REJECTED",
  COMPLETE: "GET_GENRES_FULFILLED"
}

export const GET_CUSTOM_FIELDS = {
  REQUEST: "GET_CUSTOM_FIELDS",
  PENDING: "GET_CUSTOM_FIELDS_PENDING",
  FAILED: "GET_CUSTOM_FIELDS_REJECTED",
  COMPLETE: "GET_CUSTOM_FIELDS_FULFILLED"
}

export const SET_CUSTOM_FIELD_VALUE = "SET_CUSTOM_FIELD_VALUE"

export const INIT_TRAILER = {
  REQUEST: "INIT_TRAILER",
  PENDING: "INIT_TRAILER_PENDING",
  FAILED: "INIT_TRAILER_REJECTED",
  COMPLETE: "INIT_TRAILER_FULFILLED"
}

export const INIT_DCP_REQUEST = {
  REQUEST: "INIT_DCP_REQUEST",
  PENDING: "INIT_DCP_REQUEST_PENDING",
  FAILED: "INIT_DCP_REQUEST_REJECTED",
  COMPLETE: "INIT_DCP_REQUEST_FULFILLED"
}

export const UPDATE_TRAILER = {
  REQUEST: "UPDATE_TRAILER",
  PENDING: "UPDATE_TRAILER_PENDING",
  FAILED: "UPDATE_TRAILER_REJECTED",
  COMPLETE: "UPDATE_TRAILER_FULFILLED"
}

export const GET_SOURCE_FILES = {
  REQUEST: "GET_SOURCE_FILES",
  PENDING: "GET_SOURCE_FILES_PENDING",
  FAILED: "GET_SOURCE_FILES_REJECTED",
  COMPLETE: "GET_SOURCE_FILES_FULFILLED"
}

export const SUBMIT_SOURCE_FILE = {
  REQUEST: "SUBMIT_SOURCE_FILE",
  PENDING: "SUBMIT_SOURCE_FILE_PENDING",
  FAILED: "SUBMIT_SOURCE_FILE_REJECTED",
  COMPLETE: "SUBMIT_SOURCE_FILE_FULFILLED"
}

export const GET_TIMED_TEXTS = {
  REQUEST: "GET_TIMED_TEXTS",
  PENDING: "GET_TIMED_TEXTS_PENDING",
  REJECTED: "GET_TIMED_TEXTS_REJECTED",
  COMPLETE: "GET_TIMED_TEXTS_FULFILLED"
}

export const UPDATE_TIMED_TEXT = {
  REQUEST: "UPDATE_TIMED_TEXT",
  PENDING: "UPDATE_TIMED_TEXT_PENDING",
  REJECTED: "UPDATE_TIMED_TEXT_REJECTED",
  COMPLETE: "UPDATE_TIMED_TEXT_FULFILLED"
}

export const DELETE_TIMED_TEXT = {
  REQUEST: "DELETE_TIMED_TEXT",
  PENDING: "DELETE_TIMED_TEXT_PENDING",
  REJECTED: "DELETE_TIMED_TEXT_REJECTED",
  COMPLETE: "DELETE_TIMED_TEXT_FULFILLED"
}

export const UPLOAD_TIMED_TEXT = {
  REQUEST: "UPLOAD_TIMED_TEXT",
  PENDING: "UPLOAD_TIMED_TEXT_PENDING",
  REJECTED: "UPLOAD_TIMED_TEXT_REJECTED",
  COMPLETE: "UPLOAD_TIMED_TEXT_FULFILLED"
}
