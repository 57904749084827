import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Empty, ButtonDropdown } from "@bitcine/cs-theme"
import { keyframes } from "react-emotion"
import ErrorBoundary from "@components/error_boundaries/"
import EmptyTransfersIcon from "@src/public/icons/empty/transfers.inline.svg"
import Transfer from "./transfer"
import 'styled-components/macro'

const rotate = keyframes`
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(-360deg);
	}
`
const Transfers = ({ transfers, hasInProgressTransfers }) => {
  const transfersLength = Object.keys(transfers).length
  const hasAsperaTransfers = Object.keys(transfers).filter(key => transfers[key].method === "aspera").length > 0
  return (
    <ButtonDropdown
      button={{
        className: "link mr3",
        text: (
          <div>
            <div className='flex items-center'>
              <i
                css={`
                  animation: ${hasInProgressTransfers ? `${rotate} 3s linear infinite` : ""};
                `}
                className='material-icons mr1'>
                cached
              </i>
              Transfers
            </div>
            {transfersLength > 0 && (
              <span
                css={`
                  width: ${transfersLength > 99 ? "24px" : "20px"};
                  min-height: ${transfersLength > 99 ? "24px" : "20px"};
                  top: ${transfersLength > 99 ? "-.3em" : "-.1em"};
                  right: -0.5rem;
                  border: 1px solid white;
                `}
                className='absolute flex items-center justify-center small bg-purple white circle white bold'>
                {transfersLength}
              </span>
            )}
          </div>
        )
      }}
      dropdown={{
        clickCloses: false,
        content: (
          <div
            css={`
              max-height: 300px;
              overflow: auto;
              width: 480px;
            `}>
            <ErrorBoundary>
              {transfersLength ? (
                <ul className='m0 p0'>
                  {Object.keys(transfers).map((key, i) => (
                    <Transfer isFirst={i === 0} transfer={transfers[key]} key={transfers[key].uuid}/>
                  ))}
                </ul>
              ) : (
                <div
                  css={`
                    > div {
                      height: 300px;
                      padding-top: 0;
                    }
                  `}>
                  <Empty
                    icon={<EmptyTransfersIcon/>}
                    title='No transfers'
                    text='When you upload or download files, the progress will show here'/>
                </div>
              )}
              {hasAsperaTransfers && (
                <div className='py1 right-align border-top border-gray-5 px2'>
                  <a
                    className='inline-flex justify-end items-center pointer'
                    onClick={() => {
                      window.asperaWeb.showTransferManager()
                      this.setState({ open: false })
                    }}>
                    View transfers in the aspera app
                    <span className='material-icons ml1 caption pointer'>launch</span>
                  </a>
                </div>
              )}
            </ErrorBoundary>
          </div>
        )
      }}/>
  )
}

const mapStateToProps = state => ({
  transfers: state.transfers,
  hasInProgressTransfers: Object.keys(state.transfers).length > 0
})

Transfers.propTypes = {
  transfers: PropTypes.object.isRequired,
  hasInProgressTransfers: PropTypes.bool.isRequired
}

export default connect(
  mapStateToProps,
  null
)(Transfers)
