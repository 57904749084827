import React from "react"
import PropTypes from "prop-types"
import shouldPureComponentUpdate from "@src/helpers/shouldPureComponentUpdate"

const styles = {
  display: "inline-block",
  transform: "rotate(-7deg)",
  WebkitTransform: "rotate(-7deg)",
  height: "120px",
  width: "83px",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat"
}

class ProjectDragging extends React.Component {
  constructor(props) {
    super(props)
  }
  shouldComponentUpdate = shouldPureComponentUpdate
  render() {
    const { item } = this.props

    return (
      <div
        className='bg-white rounded box-shadow px1 pt3'
        style={{
          ...styles,
          ...(item.project.active_poster_url
            ? {
              backgroundImage: `url(${item.project.active_poster_url})`
            }
            : {})
        }}>
        <div className='truncate center'>
          <small>{item.project.title}</small>
        </div>
      </div>
    )
  }
}

ProjectDragging.propTypes = {
  item: PropTypes.object.isRequired
}

export default ProjectDragging
