import {
  GET_USER_ORGANIZATION_PENDING,
  GET_USER_ORGANIZATION_FAILED,
  GET_USER_ORGANIZATION_FULFILLED,
  UPDATE_USER_ORGANIZATION
} from "@actions/organizations/"

const initialState = {
  details: null
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER_ORGANIZATION_PENDING:
      return state

    case GET_USER_ORGANIZATION_FULFILLED:
      return {
        ...state,
        details: action.payload
      }

    case GET_USER_ORGANIZATION_FAILED:
      return state

    case UPDATE_USER_ORGANIZATION:
      return {
        ...state,
        details: {
          ...state.details,
          [action.key]: action.value
        }
      }

    default:
      return state
  }
}
