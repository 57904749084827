import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { prevStep, nextStep, updateOrder } from "@api/project/orders"
import { Radio } from "@bitcine/cs-theme"
import "../styles.scss"

const DCPOptions = ({ prevStep, nextStep, order, items, updateOrder, frameRate, resolution, subtitleTrack }) => (
  <div className='col-12'>
    {!items ? (
      <div className={`orderInnerContainer center py2`}>
        <div className='spinner'/>
      </div>
    ) : (
      <div className={"orderInnerContainer"}>
        <div className='pb3'>
          <div className='flex items-center mb1'>
            <span className='material-icons mr1'>label</span>
            <strong htmlFor='__dcp_title'>Filename</strong>
          </div>
          <p>
            DCP filenames should follow the Digital Cinema Naming Convention. If you are unfamiliar with the naming
            convention, stick with the default option, otherwise, you can specify a different filename.
          </p>
          {[
            {
              label: "Use the default DCNC filename",
              useCustomTitle: false
            },
            {
              label: "I'd like to specify a filename",
              useCustomTitle: true
            }
          ].map((item, i) => (
            <div className='mb1 flex items-center' key={i}>
              <Radio
                id={`title_handling_${i}`}
                checked={order.use_custom_title === item.useCustomTitle}
                onChange={() => updateOrder("use_custom_title", item.useCustomTitle)}/>
              <label className='cs-label flex-auto' htmlFor={`title_handling_${i}`}>
                <strong className='px1'>{item.label}</strong>
              </label>
            </div>
          ))}
          {order.use_custom_title && (
            <input
              value={order.dcp_title}
              id='__dcp_title'
              autoFocus
              autoComplete='off'
              onChange={e => updateOrder("dcp_title", e.target.value)}
              className={`cs-input col-12 ${order.inputError === "dcp_title" ? "error" : ""}`}/>
          )}
          {order.use_custom_title && order.inputError === "dcp_title" && (
            <small className='block red'>This field is required.</small>
          )}
        </div>
        {subtitleTrack && (
          <div className='pb3'>
            <div className='flex items-center mb1'>
              <span className='material-icons mr1'>subtitles</span>
              <strong>Subtitle Handling</strong>
            </div>
            <p>Select how we should add your subtitles to this DCP</p>
            {[
              {
                label: "Burn in subtitle track",
                value: "burnt_in_subtitles",
                price: items.burnt_in_subtitles.price_formatted
              },
              {
                label: "XML subtitle track",
                value: "xml_subtitles",
                price: `${items.xml_subtitles.price_formatted}`
              }
            ].map((item, i) => (
              <div className='mb1 flex items-center' key={i}>
                <Radio
                  id={`subtitle_handling_${i}`}
                  checked={order.subtitle_handling === item.value}
                  onChange={() => updateOrder("subtitle_handling", item.value)}/>
                <label className='cs-label flex-auto' htmlFor={`subtitle_handling_${i}`}>
                  <strong className='px1'>{item.label}</strong>
                </label>
                <strong>{item.price}</strong>
              </div>
            ))}
            {order.inputError === "subtitles" && <small className='block red'>This field is required.</small>}
          </div>
        )}
        {resolution === "1920x1080" && (
          <div className='pb3'>
            <div className='flex items-center mb1'>
              <span className='material-icons mr1'>photo_size_select_large</span>
              <strong>Image Size</strong>
            </div>
            <p>Adjust the image size of your film.</p>
            {[
              {
                label: "Zoom & crop to fill frame",
                value: true,
                price: items.cropping.price_formatted
              },
              {
                label: "Pillarbox",
                value: false,
                price: `${items.cropping.currency_symbol}0.00`
              }
            ].map((item, i) => (
              <div className='mb1 flex items-center' key={i}>
                <Radio
                  id={`crop_${i}`}
                  checked={order.crop === item.value}
                  onChange={() => updateOrder("crop", item.value)}/>
                <label className='cs-label flex-auto' htmlFor={`crop_${i}`}>
                  <strong className='px1'>{item.label}</strong>
                </label>
                <strong>{item.price}</strong>
              </div>
            ))}
            {order.inputError === "resolution" && <small className='block red'>This field is required.</small>}
          </div>
        )}
        {(frameRate === "25" || frameRate === "48") && (
          <div className='pb3'>
            <div className='flex items-center mb1'>
              <span className='material-icons mr1'>burst_mode</span>
              <strong>Frame Rate</strong>
            </div>
            <p>Convert your films frame rate to play in all cinemas.</p>
            {[
              {
                label: `Keep at ${frameRate} FPS`,
                value: false,
                price: `${items.cropping.currency_symbol}0.00`
              },
              {
                label: `Convert to 24 FPS`,
                value: true,
                price: items[`${frameRate === "48" ? "convert_48_to_24_fps" : "convert_25_to_24_fps"}`].price_formatted
              }
            ].map((item, i) => (
              <div className='mb1 flex items-center' key={i}>
                <Radio
                  id={`convert_${i}`}
                  checked={order.convert === item.value}
                  onChange={() => updateOrder("convert", item.value)}/>
                <label className='cs-label flex-auto' htmlFor={`convert_${i}`}>
                  <strong className='px1'>{item.label}</strong>
                </label>
                <strong>{item.price}</strong>
              </div>
            ))}
            {order.inputError === "frame_rate" && <small className='block red'>This field is required.</small>}
          </div>
        )}
      </div>
    )}
    <div className='flex justify-between col-12 pt3'>
      <button className='cs-button white' onClick={prevStep}>
        Back
      </button>
      <button className='cs-button' disabled={false} onClick={nextStep}>
        Next
      </button>
    </div>
  </div>
)

DCPOptions.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  items: PropTypes.object,
  updateOrder: PropTypes.func.isRequired,
  subtitleTrack: PropTypes.object,
  frameRate: PropTypes.string,
  resolution: PropTypes.string
}

const mapStateToProps = state => {
  const order = state.orders.active_order
  const file = order.source_file
  const subtitleTrack = order.subtitle_track
  return {
    order,
    items: state.utils.dcp_items,
    frameRate: file.frame_rate,
    resolution: file.resolution,
    subtitleTrack
  }
}

const mapDispatchToProps = dispatch => ({
  prevStep: bindActionCreators(prevStep, dispatch),
  nextStep: bindActionCreators(nextStep, dispatch),
  updateOrder: bindActionCreators(updateOrder, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DCPOptions)
