export const ui = {
  folder: {
    icon: "folder",
    label: "Folder"
  },
  source_file: {
    icon: "movie",
    label: "Video"
  },
  document: {
    icon: "insert_drive_file",
    label: "Document"
  },
  dcp: {
    icon: "movie",
    label: "DCP"
  },
  subtitle_track: {
    icon: "subtitles",
    label: "Subtitles Track"
  },
  closed_caption: {
    icon: "closed_caption",
    label: "Closed Captions"
  },
  audio_track: {
    icon: "audiotrack",
    label: "Audio"
  },
  disc_output: {
    icon: "album",
    label: "ISO"
  },
  frozen: {
    icon: "ac_unit",
    label: "Frozen"
  }
}
