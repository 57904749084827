import {
  UPDATE_FILES_MODAL,
  TOGGLE_MODAL_VISIBILITY,
  ADD_SHARED_EMAIL,
  REMOVE_SHARED_EMAIL,
  UPDATE_SHARED_EMAIL,
  VIEW_FILE_INFO,
  TOGGLE_FOLDER_CONTENT_VISIBILITY,
  CLOSE_FILE_SHARING_MODAL,
  UPDATE_ASPERA_SHARING,
  GET_ASPERA_SHARING_PRICES,
  CREATE_PACKAGE,
  UPDATE_SHARE_LABEL,
  UPDATE_ASPERA_SHARE_LABEL,
  GET_ASPERA_ORDER
} from "@actions/project/files"

import { addGlobalMessage } from "@api/utils/"
import { get, post } from "@api/fetch"
import Tracker from "@helpers/tracking"

/**
 * Updates Files Modals
 *
 * @returns dispatch
 */

export const updateFilesModal = (modal, key, value) => dispatch =>
  dispatch({ type: UPDATE_FILES_MODAL, modal, key, value })

/**
 * View File Info
 * - opens a dialog with the file info
 *
 * @param {Object} - file
 *
 * @returns dispatch
 *
 */

export const viewFileInfo = (file, refToFocus = null) => dispatch =>
  dispatch({ type: VIEW_FILE_INFO, file, refToFocus })

/**
 * Updates Files Modals Visibility
 *
 * @param {modal} - string - the modal to update
 * @param {files} - array - the files that will be acted on
 *
 * @returns dispatch
 */

export const toggleModalVisibility = (modal, files) => dispatch =>
  dispatch({ type: TOGGLE_MODAL_VISIBILITY, modal, files })

export const addSharedEmail = () => dispatch => dispatch({ type: ADD_SHARED_EMAIL })

export const removeSharedEmail = uuid => dispatch => dispatch({ type: REMOVE_SHARED_EMAIL, uuid })

export const updateSharedEmail = (uuid, key, value) => dispatch =>
  dispatch({ type: UPDATE_SHARED_EMAIL, uuid, key, value })

export const toggleFolderContentVisibility = file => dispatch => {
  dispatch({ type: TOGGLE_FOLDER_CONTENT_VISIBILITY, file })
}

export const updateShareLabel = label => dispatch => dispatch({ type: UPDATE_SHARE_LABEL, payload: { label } })
export const updateAsperaShareLabel = label => dispatch =>
  dispatch({ type: UPDATE_ASPERA_SHARE_LABEL, payload: { label } })

export const closeFileSharingModal = () => dispatch => dispatch({ type: CLOSE_FILE_SHARING_MODAL })

export const updateAsperaSharing = (key, value) => dispatch => dispatch({ type: UPDATE_ASPERA_SHARING, key, value })

export const showAsperaSharing = files => dispatch => {
  dispatch(get(GET_ASPERA_SHARING_PRICES, "items/getItemsByCodes?codes=aspera_transfers"))
  dispatch({ type: UPDATE_ASPERA_SHARING, key: "files", value: files })
}

export const getAsperaOrder = () => (dispatch, getState) => {
  const share = getState().project.files.asperaSharing
  dispatch(
    post(GET_ASPERA_ORDER, `projects/${getState().project.details._id}/packages/aspera-quote`, {
      _files: share.files,
      emails: share.emails.map(email => email.value),
      projectID: getState().project.details._id
    })
  )
}

export const sendAsperaLinks = () => (dispatch, getState) => {
  const share = getState().project.files.asperaSharing
  dispatch(
    post(
      CREATE_PACKAGE,
      `projects/${getState().project.details._id}/packages`,
      {
        projectID: getState().project.details._id,
        shareType: "email",
        method: "aspera",
        _files: share.files,
        emails: share.emails.map(email => email.value),
        customerCardID: share.credit_card_id,
        label: share.label,
        password: share.password
      },
      r => {
        Tracker.inc("Aspera Purchase")
        dispatch(addGlobalMessage(`Files shared`, "success"))
      }
    )
  )
}
