import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Table } from "@bitcine/cs-theme"
import { can, MODIFY_USERS_AND_ROLES } from "@src/helpers/authorization"
import { saveAccess, togglePermissionsModal } from "@api/screener_rooms/"
import Search from "@components/search/"
import { SHOW_ACCESS_INVITES } from "@actions/project/access"
import update from "immutability-helper"
import MemberIcon from "@components/member/MemberIcon"

class Permissions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      previousAuthorizedUsers: [],
      authorizedUserIds: [],
      showSearch: false,
      searchString: ""
    }

    this.handleOnChange = this.handleOnChange.bind(this)
    this.save = this.save.bind(this)
    this.toggleCheckAll = this.toggleCheckAll.bind(this)
    this.getButton = this.getButton.bind(this)
  }
  componentDidMount() {
    this.setState({
      authorizedUserIds: this.props.membersWithAccess.map(m => m._id),
      previousAuthorizedUsers: this.props.membersWithAccess.map(m => m._id)
    })
  }
  handleOnChange(id) {
    const index = this.state.authorizedUserIds.findIndex(userId => userId === id)

    this.setState(
      update(this.state, {
        authorizedUserIds: index === -1 ? { $push: [id] } : { $splice: [[index, 1]] }
      })
    )
  }
  toggleCheckAll(filteredUsers) {
    if (filteredUsers.filter(user => !user.is_admin).length === this.state.authorizedUserIds.length) {
      this.setState({ authorizedUserIds: [] })
    } else {
      this.setState(
        {
          authorizedUserIds: []
        },
        () => {
          this.setState({
            authorizedUserIds: filteredUsers.filter(user => !user.is_admin).map(user => user._id)
          })
        }
      )
    }
  }
  save = () => {
    this.props.saveAccess(this.props.room._id, [...this.state.authorizedUserIds, this.props.authUserID])
  }
  getButton() {
    const difference = this.state.authorizedUserIds.filter(id => this.state.previousAuthorizedUsers.includes(id)).length

    let msg = ""

    if (difference < this.state.previousAuthorizedUsers.length) {
      const c = this.state.previousAuthorizedUsers.length - difference
      msg += `Remove ${c} ${c > 1 ? "people" : "person"}`
    }

    const c2 = this.state.authorizedUserIds.length - difference
    if (c2) {
      msg += `${msg.length > 0 ? ", " : ""}Add ${c2} ${c2 > 1 ? "people" : "person"}`
    }

    return (
      <button
        className={`cs-button ${msg.length ? "" : "white"}`}
        onClick={() => {
          if (msg.length) {
            this.save()
          } else {
            this.props.togglePermissionsModal() // Closes modal
          }
        }}>
        {msg.length ? msg : "Close"}
      </button>
    )
  }
  render() {
    const { canEditAccess, members, showInviteView } = this.props

    const { searchString, showSearch, authorizedUserIds } = this.state

    const filteredUsers = members.filter(member =>
      member.full_name
        .trim()
        .toLowerCase()
        .includes(searchString.trim().toLowerCase())
    )
    return (
      <div>
        <div className='flex mb2'>
          <div className='flex-auto'/>
          <Search
            visible={showSearch}
            disabled={false}
            onChange={({ target: { value } }) =>
              this.setState({
                searchString: value,
                authorizedUserIds: []
              })
            }
            onCancel={() => {
              this.setState({ showSearch: false, searchString: "" })
            }}
            value={searchString}
            onOpen={() => this.setState({ showSearch: true })}
            placeholder='Search for a user...'/>

          {canEditAccess && (
            <button className='cs-button white ml1' onClick={showInviteView}>
              Invite new team members
            </button>
          )}
        </div>
        <Table
          widths={[75, "auto", 160, 140]}
          header={{
            columns: [
              {
                text: "User"
              },
              {
                text: "Role"
              },
              {
                text: "Status"
              }
            ],
            checkbox: {
              checked: filteredUsers.filter(user => !user.is_admin).length === authorizedUserIds.length,
              onChange: () => this.toggleCheckAll(filteredUsers),
              disabled: !canEditAccess
            }
          }}
          body={{
            data: filteredUsers || [],
            row: {
              checkbox: {
                checked: user =>
                  user.is_admin || authorizedUserIds.includes(user._id) || user._id === this.props.authUserID,
                onChange: user => this.handleOnChange(user._id),
                disabled: user => user.is_admin || !canEditAccess || user._id === this.props.authUserID
              },
              render: [
                data => <MemberIcon user={data}/>,
                user => user.full_name,
                user => user.role_name,
                user => user.status
              ]
            }
          }}/>
        <div className='pt3 right-align'>
          {canEditAccess ? (
            this.getButton()
          ) : (
            <button className='cs-button white' onClick={this.save}>
              Close
            </button>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const room = state.screener_rooms.screenerRoom.data
  const membersWithAccess = room ? room.users : []

  return {
    members: state.settings.organizations.members.items,
    canEditAccess: can(state.user.auth, [MODIFY_USERS_AND_ROLES]),
    membersWithAccess,
    authUserID: state.user.auth._id,
    room
  }
}

const mapDispatchToProps = dispatch => ({
  showInviteView: () => dispatch({ type: SHOW_ACCESS_INVITES }),
  togglePermissionsModal: bindActionCreators(togglePermissionsModal, dispatch),
  saveAccess: bindActionCreators(saveAccess, dispatch)
})

Permissions.propTypes = {
  togglePermissionsModal: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  showInviteView: PropTypes.func.isRequired,
  members: PropTypes.array.isRequired,
  canEditAccess: PropTypes.bool.isRequired,
  membersWithAccess: PropTypes.array.isRequired,
  saveAccess: PropTypes.func.isRequired,
  authUserID: PropTypes.string.isRequired,
  room: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(Permissions)
