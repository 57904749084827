import React, { useState, useRef, useEffect } from "react"
import Select from "react-select"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"
import { Modal, Button } from "@bitcine/cs-theme"
import { getTimedText, uploadTimedText, updateTimedText, deleteTimedText, getLanguages } from "@api/ondemand_videos"

const acceptedExtensions = ["vtt", "srt"]

const TimedTextUpload = ({
  video,
  timedTexts,
  languages,
  getTimedText,
  updateTimedText,
  uploadTimedText,
  getLanguages,
  deleteTimedText
}) => {
  const [newLanguageModalOpen, setNewLanguageModalOpen] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(null)
  useEffect(() => {
    getTimedText(video._id)
    getLanguages()
  }, [getTimedText, video._id])
  const inputFile = useRef(null)
  return (
    <div className='mt2'>
      {video.is_video_submitted ? (
        <>
          {timedTexts.map(timedText => (
            <div key={timedText._id} className='flex items-center'>
              <i className='material-icons green'>check_circle</i>
              <span className='ml1'>
                Provided file: <strong>{timedText.name}</strong>
              </span>
            </div>
          ))}
        </>
      ) : (
        <>
          {timedTexts.map(timedText => (
            <div key={timedText._id} className='py1 px2 border border-gray-5 rounded flex items-center justify-between'>
              <div className='truncate'>{timedText.name}</div>
              <div className='flex items-center'>
                <Select
                  className='mr3'
                  options={languages}
                  clearable={false}
                  placeholder={"Select language..."}
                  value={timedText.language}
                  onChange={option => {
                    timedText.language = option.value
                    updateTimedText(video._id, timedText._id, { language: option.value })
                  }}/>
                <i
                  className='material-icons ml2 pointer'
                  onClick={() => {
                    if (window.confirm("Are you sure you want to permanently delete this track?")) {
                      deleteTimedText(video._id, timedText._id)
                    }
                  }}>
                  delete_forever
                </i>
              </div>
            </div>
          ))}
          <div className='mt1'/>
          <span className='pointer' onClick={() => setNewLanguageModalOpen(true)}>
            + Add a subtitle or closed captioning track
          </span>
          <input
            type='file'
            id='file'
            ref={inputFile}
            accept='.vtt,.srt'
            style={{ display: "none" }}
            onChange={e => {
              const file = e.target.files[0]
              const name = file.name
              const extension = name.substr(name.lastIndexOf(".") + 1, name.length)
              if (!acceptedExtensions.includes(extension)) {
                window.alert("We only accept .vtt and .srt files for maximum compability with streaming devices.")
                return
              }
              uploadTimedText(video._id, e.target.files[0], selectedLanguage)
            }}/>
        </>
      )}
      {newLanguageModalOpen && (
        <Modal open onClose={() => setNewLanguageModalOpen(false)} title='Select Language'>
          <p>Select a new language below:</p>
          <Select
            className='mr3'
            options={languages}
            clearable={false}
            placeholder={"Select language..."}
            value={selectedLanguage}
            onChange={option => setSelectedLanguage(option.value)}/>
          <div className='flex justify-end mt2'>
            <Button
              disabled={!selectedLanguage}
              onClick={() => {
                inputFile.current.click()
                setNewLanguageModalOpen(false)
              }}>
              Select
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  video: state.ondemand_videos.video,
  languages: state.ondemand_videos.realLanguages,
  timedTexts: state.ondemand_videos.timedTexts
})

const mapDispatchToProps = dispatch => ({
  uploadTimedText: bindActionCreators(uploadTimedText, dispatch),
  updateTimedText: bindActionCreators(updateTimedText, dispatch),
  deleteTimedText: bindActionCreators(deleteTimedText, dispatch),
  getTimedText: bindActionCreators(getTimedText, dispatch),
  getLanguages: bindActionCreators(getLanguages, dispatch)
})

TimedTextUpload.propTypes = {
  video: PropTypes.object.isRequired,
  languages: PropTypes.array,
  timedTexts: PropTypes.array,
  getLanguages: PropTypes.func.isRequired,
  getTimedText: PropTypes.func.isRequired,
  deleteTimedText: PropTypes.func.isRequired,
  updateTimedText: PropTypes.func.isRequired,
  uploadTimedText: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(TimedTextUpload)
