import React from "react"
import TeamImg from "../images/team_demo.png"
import BaseSlide from "./components/base"
import Footer from "./components/footer"
import 'styled-components/macro'

const TeamSlide = () => (
  <BaseSlide>
    <div
      css={`
        padding: 5px 50px 0 50px;
      `}>
      <img className='cs-img' src={TeamImg} alt='Team'/>
    </div>
    <Footer
      bg='red'
      header='Work as a team'
      content='CineSend team accounts allow admins to set up roles and permissions for each project. You can add team members to projects they are currently working on and have a detailed backlog of all activity.'/>
  </BaseSlide>
)

export default TeamSlide
