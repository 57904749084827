import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toggleModalVisibility } from "@api/project/files/modals"
import { Modal } from "@bitcine/cs-theme"
import { defrost } from "@api/project/defroster"
import { keyframes } from "emotion"
import 'styled-components/macro'

const spin = `
  animation: ${keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
  `} 1.5s ease infinite;
`

class DefrostFile extends React.Component {
  render() {
    const { toggleModalVisibility, file, defrost, defroster } = this.props
    const busy = defroster.status === "PENDING"
    const failed = defroster.status === "FAILED"
    const isThawing = !!file.started_defrosting_at
    return (
      <Modal
        open
        width={2}
        onClose={() => {
          if (busy) return
          toggleModalVisibility("defrost", [])
        }}
        title={`Restore File`}>
        <div>
          <>
            <h3 className='flex items-center mb2 justify-center'>
              <i className='material-icons' css={isThawing ? spin : ``}>
                ac_unit
              </i>{" "}
              <span className='ml1'>{isThawing ? "File Thawing" : "File Frozen"}</span>
            </h3>
            <p className='mb3'>
              {isThawing
                ? `This file is thawing. Please check back later. We'll email you when the content is available.`
                : `This file has been frozen due to inactivity. You may thaw this content at anytime by clicking
              the button below. This process may take several hours, we will email you when it's complete.`}
            </p>
            <div className='flex'>
              <div className='flex-auto'>
                <button
                  disabled={busy}
                  onClick={() => toggleModalVisibility("defrost", [])}
                  className='cs-button white'>
                  Close
                </button>
              </div>
              {!isThawing && (
                <button disabled={busy} onClick={() => defrost(file)} className='cs-button'>
                  Restore
                </button>
              )}
            </div>
            {failed && (
              <div className='pt3 red'>
                {`Unable to thaw content. Please refresh the page and try again. If the issue persists, please contact support.`}
              </div>
            )}
          </>
        </div>
      </Modal>
    )
  }
}

DefrostFile.propTypes = {
  toggleModalVisibility: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  defrost: PropTypes.func.isRequired,
  defroster: PropTypes.object.isRequired
}

const mapStateToProps = (state, context) => ({
  file: state.project.files.modals.defrost.files[0],
  defroster: state.project.defroster
})

const mapDispatchToProps = dispatch => ({
  toggleModalVisibility: bindActionCreators(toggleModalVisibility, dispatch),
  defrost: bindActionCreators(defrost, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefrostFile)
