import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Status } from '@bitcine/cs-theme'
import { authCheck } from '@api/account/auth'

const AuthenticationWrapper = ({ authCheck, authenticated, children }) => {
  useEffect(() => {
    authCheck(true)
  }, [authCheck])
  return (
    <Status pending={!authenticated}>
      {authenticated && <>{children}</>}
    </Status>
  )
}

AuthenticationWrapper.propTypes = {
  authCheck: PropTypes.func,
  status: PropTypes.status,
  children: PropTypes.array
}

const mapStateToProps = state => ({
  authenticated: state.user.auth.status === "AUTHENTICATED"
})

const mapDispatchToProps = dispatch => ({
  authCheck: bindActionCreators(authCheck, dispatch)
})

AuthenticationWrapper.propTypes = {
  status: PropTypes.string.isRequired,
  authCheck: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper)