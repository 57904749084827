import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateSupport, sendSupport } from "@api/account/support"
import { Modal } from "@bitcine/cs-theme"

const Support = ({ support: { visible, status, message, order }, updateSupport, sendSupport, email }) => (
  <Modal
    open={visible}
    onClose={() => updateSupport("visible", false)}
    clickOutsideCloses={true}
    showConfirmationOnClose={message.length > 1}
    title='CineSend Support'>
    <div>
      {order && <h4 className='muted'>{`${order}`}</h4>}
      <label className='cs-label mt2' htmlFor='support'>
        Message
      </label>
      <textarea
        id='support'
        className='cs-textarea col-12'
        value={message}
        placeholder='How can we help you?'
        onChange={({ target: { value } }) => updateSupport("message", value)}/>
      <small className='flex-auto'>Response will be sent to {email} within 1 business day.</small>
      {status === "ERROR" && <div className='red'>An error occured.</div>}
      <div className='mt3 flex items-center justify-end'>
        <button
          onClick={sendSupport}
          className={`cs-button ${status === "PENDING" ? "pending" : ""}`}
          disabled={!message || status === "PENDING"}>
          Send
        </button>
      </div>
    </div>
  </Modal>
)

Support.propTypes = {
  support: PropTypes.object.isRequired,
  updateSupport: PropTypes.func.isRequired,
  sendSupport: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  support: state.user.support,
  email: state.user.auth.email
})

const mapDispatchToProps = dispatch => ({
  updateSupport: bindActionCreators(updateSupport, dispatch),
  sendSupport: bindActionCreators(sendSupport, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Support)
