import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import uniqBy from "lodash.uniqby"
import PropTypes from "prop-types"
import MemberIcon from "@components/member/MemberIcon"
import { TOGGLE_ACCESS_MODAL } from "@actions/project/access"
import { can, MODIFY_USERS_AND_ROLES } from "@src/helpers/authorization"
import ScreenerPermissions from "@components/screener_rooms/modals/permissions/"
import { togglePermissionsModal } from "@api/screener_rooms/"
import ProjectInvites from "@components/project/ProjectInvites"
import "../styles.scss"

class Members extends Component {
  render() {
    const {
      members,
      visible,
      admins,
      canEditAccess,
      togglePermissionsModal,
      showScreenerPermissionsModal,
      screenerRoom,
      showInvite
    } = this.props

    if (!visible) return null

    const allMembers = uniqBy([...admins, ...members], user => user._id)

    return (
      <div className='flex items-center pl3 py1'>
        <small className='mr2 bold'>Team members</small>
        <div className='flex items-center row-reverse'>
          {canEditAccess && (
            <button
              className={`user addBtn`}
              onClick={() => togglePermissionsModal(screenerRoom._id)}>
              <span>
                <i className='material-icons'>add</i>
              </span>
            </button>
          )}
          {allMembers.length > 4 && (
            <button
              className={`user moreUsers tooltip`}
              data-tooltip='View members'
              onClick={() => togglePermissionsModal(screenerRoom._id)}>
              <span>+{allMembers.length - 4}</span>
            </button>
          )}
          {allMembers.slice(0, 4).map((user, i) => (
            <MemberIcon key={i} user={user} zindex={allMembers.length + 4 - i}/>
          ))}
        </div>
        {showScreenerPermissionsModal ? showInvite ? <ProjectInvites/> : <ScreenerPermissions/> : null}
      </div>
    )
  }
}

Members.propTypes = {
  members: PropTypes.array,
  project: PropTypes.object.isRequired,
  toggleAccessModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  admins: PropTypes.array,
  showInvite: PropTypes.bool.isRequired,
  canEditAccess: PropTypes.bool.isRequired,
  togglePermissionsModal: PropTypes.func.isRequired,
  screenerRoom: PropTypes.object.isRequired,
  showScreenerPermissionsModal: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    showInvite: state.project.access.showInvite,
    members: state.screener_rooms.screenerRoom.status !== "PENDING" ? state.screener_rooms.screenerRoom.data.users : [],
    project: state.project.details.status !== "PENDING" ? state.project.details : {},
    visible: state.user.auth.organization_id !== null,
    admins: state.user.auth.organization_id
      ? state.settings.organizations.members.items.filter(user => user.is_admin)
      : [],
    showScreenerPermissionsModal: state.screener_rooms.showScreenerPermissionsModal,
    canEditAccess: can(state.user.auth, [MODIFY_USERS_AND_ROLES]),
    screenerRoom: state.screener_rooms.screenerRoom.data
  }
}

const mapDispatchToProps = (dispatch, state) => ({
  toggleAccessModal: project => dispatch({ type: TOGGLE_ACCESS_MODAL, payload: { project } }),
  togglePermissionsModal: bindActionCreators(togglePermissionsModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Members)
