import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Header from "@components/requests/header"
import { getVideo } from "@api/ondemand_videos/"
import { Status } from "@bitcine/cs-theme"
import Footer from "@components/requests/footer"
import styled from "react-emotion"
import Video from '@components/ondemand_video'

const PageContainer = styled("div")`
  position: relative;
  min-height: 100vh;
`

const ContentContainer = styled("div")`
  position: relative;
  padding-top: 64px;
  padding-bottom: 140px;
  max-width: 1600px;
`

const VideoPage = ({ match, video, getVideo, status, children }) => {
  useEffect(() => {
    getVideo(match.params.videoID)
  }, [getVideo])
  return (
    <PageContainer>
      <Header/>
      <Status pending={status === "PENDING"} error={status === "ERROR"}>
        <ContentContainer className='m2 mx-auto'>
          <div className='box-shadow bg-white rounded p3 mt1 mb3'>
            <div className='center'>
              {video.organization_logo_url ? (
                <img
                  className='cs-img'
                  src={video.organization_logo_url}
                  style={{ maxWidth: "140px", maxHeight: "80px" }}
                  alt={video.organization_name}/>
              ) : (
                <h4 className='truncate'>{video.organization_name}</h4>
              )}
            </div>
            <Video/>
          </div>
        </ContentContainer>
      </Status>
      <Footer/>
    </PageContainer>
  )
}

const mapStateToProps = state => ({
  video: state.ondemand_videos.video,
  status: state.ondemand_videos.status
})

const mapDispatchToProps = dispatch => ({
  getVideo: bindActionCreators(getVideo, dispatch)
})

VideoPage.propTypes = {
  video: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  getVideo: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoPage)
