import React from "react"
import ProjectAccess from "@components/project/ProjectAccess"
import ProjectInvites from "@components/project/ProjectInvites"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { TOGGLE_ACCESS_MODAL } from "@actions/project/access"

const AccessModal = ({ toggleAccessModal, project, showInvite }) =>
  showInvite ? <ProjectInvites/> : <ProjectAccess project={project} onSave={toggleAccessModal}/>

AccessModal.propTypes = {
  toggleAccessModal: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  showInvite: PropTypes.bool.isRequired
}

const mapStateToProp = state => ({
  project: state.project.access.project,
  showInvite: state.project.access.showInvite
})

const mapDispatchToProps = dispatch => ({
  toggleAccessModal: () => dispatch({ type: TOGGLE_ACCESS_MODAL, payload: { project: {} } })
})

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(AccessModal)
