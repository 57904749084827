import React from "react"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"
import { connect } from "react-redux"
import { DETAILS_NAV_ITEMS } from "@components/project/details/constants"
import 'styled-components/macro'

const Details = ({ children, projectID }) => (
  <div>
    <div
      css={`
        margin-top: 104px;
        width: 210px;
      `}
      className='bg-white border-right border-gray-5 fixed top-0 left-0 bottom-0 pt1'>
      {DETAILS_NAV_ITEMS.map((item, i) => (
        <div key={i}>
          <NavLink
            activeClassName='active-sidebar-link'
            className='sidebar-link'
            to={`/projects/${projectID}/details/${item.to}`}>
            {item.text}
          </NavLink>
        </div>
      ))}
    </div>
    <div
      css={`
        margin-left: 210px;
      `}
      className='p2'>
      {children}
    </div>
  </div>
)

const mapStateToProps = (state, context) => ({
  projectID: state.project.details._id
})

const mapDispatchToProps = dispatch => ({})

Details.propTypes = {
  children: PropTypes.object.isRequired,
  projectID: PropTypes.string.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Details)
