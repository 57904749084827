import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getDCPContent } from "@api/project/files"
import { toggleFolderContentVisibility } from "@api/project/files/modals"
import humanFileSize from "@src/helpers/humanFileSize"
import { Modal } from "@bitcine/cs-theme"

class FolderContent extends React.Component {
  componentDidMount() {
    this.props.getDCPContent(this.props.folderContent.file)
  }
  getFileName(name) {
    if (!name) return ""
    else {
      const split = name.split("/")
      return split.map((part, index) => (
        <span key={index} className={index < split.length - 1 ? "bold" : ""}>
          {part} {index < split.length - 1 ? " / " : ""}
        </span>
      ))
    }
  }
  render() {
    const { toggleFolderContentVisibility, folderContent } = this.props

    return (
      <Modal open width={3} onClose={toggleFolderContentVisibility} title={`DCP Content`}>
        <div>
          {folderContent.status === "PENDING" ? (
            <div className='center my4'>
              <div className='spinner'/>
            </div>
          ) : folderContent.status === "ERROR" ? (
            <div className='my4'>An error occured</div>
          ) : (
            <div>
              <div className='bg-gray-5 rounded mb2 p2 flex items-center'>
                <span className='material-icons'>info</span>
                <span className='ml1'>
                  You will need to create one or more folders to reassemble this DCP upon download. Folders are bolded
                  below.
                </span>
              </div>
              {folderContent.files.map((f, i) => (
                <div className='flex items-center py2' key={i}>
                  <div className='flex-auto truncate pr3'>
                    {this.getFileName(f.name)}
                    <small className='block muted'>{humanFileSize(f.size)}</small>
                  </div>
                  <a href={f.download_url} target='_blank' rel='noopener noreferrer'>
                    <button style={{ minWidth: "100px" }} className='cs-button small white'>
                      Download
                    </button>
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
      </Modal>
    )
  }
}

FolderContent.propTypes = {
  getDCPContent: PropTypes.func.isRequired,
  toggleFolderContentVisibility: PropTypes.func.isRequired,
  folderContent: PropTypes.object.isRequired
}

const mapStateToProps = (state, context) => ({
  folderContent: state.project.files.modals.folderContent
})

const mapDispatchToProps = dispatch => ({
  getDCPContent: bindActionCreators(getDCPContent, dispatch),
  toggleFolderContentVisibility: bindActionCreators(toggleFolderContentVisibility, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(FolderContent)
