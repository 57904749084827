import {
  GET_PAYMENT_METHODS,
  GET_INVOICES,
  UPDATE_NEW_PAYMENT_METHOD,
  ADD_CREDIT_CARD,
  ADD_GIFT_CARD,
  REMOVE_CREDIT_CARD,
  UPDATE_BILLING,
  RETRY_PENDING_PAYMENT,
  GET_ORDERS
} from "@actions/account/billing"
import { updateAuthUser } from "@api/account/auth"
import { saveUserOrganization } from "@api/organizations/"
import { SAVE_USER } from "@actions/account/auth"
import { post, get, del, put } from "@api/fetch"
import { addGlobalMessage } from "@api/utils"
import { GLOBALS } from "@src/config"

/**
 * Update Billing
 * - updates general state of billing
 * @returns dispatch
 */

export const updateBilling = (key, value) => dispatch => dispatch({ type: UPDATE_BILLING, key, value })

/**
 * Get payment methods
 * - retrieves the credit card and gift cards of the user/org
 * @returns dispatch
 */

export const getPaymentMethods = () => dispatch => dispatch(get(GET_PAYMENT_METHODS, "settings/paymentmethods"))

/**
 * Set default credit card
 * @returns dispatch
 */

export const updatePaymentMethod = (key, value) => (dispatch, getState) => {
  dispatch(
    put(GET_PAYMENT_METHODS, `settings/paymentmethods`, {
      key: key,
      value: value
    })
  )
}

/**
 * Update new payment method
 * - updates the state to add credit card/gift card
 * @returns dispatch
 */

export const updateNewPaymentMethod = (key, value) => dispatch => {
  dispatch({ type: UPDATE_NEW_PAYMENT_METHOD, key, value })
}

/**
 * Attempt a pending storage payment
 * @returns dispatch
 */

export const retryPendingPayment = (creditCardID = null) => (dispatch, getState) => {
  dispatch(
    put(RETRY_PENDING_PAYMENT, `settings/storageplans/retry/${creditCardID}`, {}, res => {
      if (res.success) dispatch(addGlobalMessage("Payment successful!", "success"))
      else dispatch(addGlobalMessage("Your payment was unsuccessful.", "error"))
    })
  )
}

/**
 * Get invoices
 * - retrieves the invoices of the user/org
 * @returns dispatch
 */

export const getInvoices = () => dispatch => dispatch(get(GET_INVOICES, "settings/invoices/"))

/**
 * Get orders
 * - retrieves the orders of the user/org
 * @returns dispatch
 */

export const getOrders = (page, take) => dispatch => dispatch(get(GET_ORDERS, `settings/orders?page=${page}&take=${take}`))

/**
 * Submit
 * - validates and submits the gift card/credit card
 * @returns dispatch
 */

export const submit = () => (dispatch, getState) => {
  dispatch(updateNewPaymentMethod("status", "PENDING"))
  dispatch(updateNewPaymentMethod("error", null))

  const { newPaymentMethod } = getState().user.billing

  switch (newPaymentMethod.mode) {
    case "CREDIT_CARD":
      if (!newPaymentMethod.fullName || newPaymentMethod.fullName === " ") {
        dispatch(updateNewPaymentMethod("status", "ERROR"))
        dispatch(
          updateNewPaymentMethod("error", {
            code: "invalid_name",
            message: "The full name on the credit card is required."
          })
        )
      }
      if (!newPaymentMethod.cvc || newPaymentMethod.cvc === " ") {
        dispatch(updateNewPaymentMethod("status", "ERROR"))
        dispatch(
          updateNewPaymentMethod("error", {
            code: "invalid_cvc",
            message: `Your card's security code is invalid.`
          })
        )
      } else {
        fetch(`${GLOBALS.API_URL}/utilities/getStripeKey`)
          .then(res => res.text())
          .then(res => Stripe.setPublishableKey(res.trim()))
          .then(res => {
            const card = {
              name: newPaymentMethod.fullName,
              number: newPaymentMethod.cardNumber,
              exp_month: newPaymentMethod.expiryMonth,
              exp_year: newPaymentMethod.expiryYear,
              cvc: newPaymentMethod.cvc
            }
            Stripe.card.createToken(card, (status, res) => {
              if (res.error) {
                dispatch(updateNewPaymentMethod("status", "ERROR"))
                dispatch(updateNewPaymentMethod("error", res.error))
              } else {
                dispatch(
                  post(ADD_CREDIT_CARD, `settings/creditcards`, { stripeToken: res }, () =>
                    dispatch(addGlobalMessage("Credit card added!", "success"))
                  )
                )
              }
            })
          })
      }
      break
    case "GIFT_CARD":
      const { code, pin } = newPaymentMethod
      if (!code) {
        dispatch(updateNewPaymentMethod("status", "ERROR"))
        dispatch(
          updateNewPaymentMethod("error", {
            code: "invalid_code",
            message: "The gift card code is required."
          })
        )
      } else if (!pin) {
        dispatch(updateNewPaymentMethod("status", "ERROR"))
        dispatch(
          updateNewPaymentMethod("error", {
            code: "invalid_pin",
            message: "The gift card pin is required."
          })
        )
      } else {
        dispatch(
          post(ADD_GIFT_CARD, `settings/giftcards/redeem`, { code, pin }, () =>
            dispatch(addGlobalMessage("Gift card added!", "success"))
          )
        )
      }
      break
    default:
      break
  }
}

/**
 * Delete credit card
 * - deletes card from db
 * - Note: default credit cards cannot be deleted
 * @returns dispatch
 */

export const deleteCreditCard = () => (dispatch, getState) =>
  dispatch(
    del(REMOVE_CREDIT_CARD, `settings/creditcards`, { cardID: getState().user.billing.cardToDelete._id }, () =>
      dispatch(addGlobalMessage("Credit card deleted!", "success"))
    )
  )

/**
 * Set default credit card
 * @returns dispatch
 */

export const setDefaultCreditCard = cardID => (dispatch, getState) => {
  if (getState().user.auth.organization_id) {
    dispatch(saveUserOrganization("default_credit_card_id", cardID))
    dispatch(updateAuthUser("default_credit_card_id", cardID))
  } else {
    dispatch(
      put(SAVE_USER, `users/${getState().user.auth._id}`, { default_credit_card_id: cardID }, () =>
        dispatch(addGlobalMessage("Credit card set as default!", "success"))
      )
    )
  }
}
