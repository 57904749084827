import React, { useState } from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { Modal } from "@bitcine/cs-theme"
import { showGlobalAuthModal } from "@api/account/auth"
import { videoStatusLabels } from "@helpers/constants"
import { updateVideo } from "@api/ondemand_videos/"
import VideoUpload from "./video_upload"
import VideoSelect from "./video_select"
import Card from "./card"
import TimedTextUpload from "./timed_text_upload"
import CommonImporter from "./common_importer"

const Video = ({ video, uploadLimit, updateVideo }) => {
  const [submitting, setSubmitting] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [importing, setImporting] = useState(false)
  const [importedName, setImportedName] = useState(null)
  const [showSpecsModal, setShowSpecsModal] = useState(false)
  const providedStatuses = [
    videoStatusLabels.PUBLISHED,
    videoStatusLabels.UNPUBLISHED,
    videoStatusLabels.ENCODING,
    videoStatusLabels.UPLOADED,
    videoStatusLabels.QUEUED,
    videoStatusLabels.READY
  ]
  const isProvided = providedStatuses.includes(video.status) || importedName
  return (
    <Card
      title='Video On-Demand Upload - Online Presentation'
      submitted={video.is_video_submitted}
      isUpload={true}
      isSource={true}
      className='mr2'
      submitButtonDisabled={!isProvided}
      isSubmitting={submitting}
      onSubmit={() => {
        setSubmitting(true)
        updateVideo(video._id, { is_video_submitted: true }, () => setSubmitting(false))
      }}>
      <>
        {!isProvided ? (
          <>
            <p>
              Select a video file complying with our{" "}
              <span className='pointer bold underline' onClick={() => setShowSpecsModal(true)}>
                file specifications
              </span>
              . In summary, we recommend a 1080p (1920x1080) H.264 file that has a frame rate of 30fps or lower and is
              under {uploadLimit}GB in size.
            </p>
            <div className='flex flex-column' style={{ gap: "0.5em" }}>
              {!importing && <VideoUpload uploading={() => setUploading(true)}/>}
              {!uploading && !importing && <VideoSelect/>}
              {!uploading && (
                <CommonImporter
                  setImporting={() => setImporting(true)}
                  target={video._id}
                  forceType='videos'
                  setImportedName={name => setImportedName(name)}/>
              )}
            </div>
          </>
        ) : (
          <div className='flex items-center'>
            <i className='material-icons green'>check_circle</i>
            <span className='ml1'>
              Provided file: <strong>{importedName ? importedName : video.source_file?.name}</strong>
            </span>
          </div>
        )}
        <TimedTextUpload/>
        {showSpecsModal && (
          <Modal open title='File Specifications' clickOutsideCloses onClose={() => setShowSpecsModal(false)}>
            <ul>
              <li>
                <b>H.264</b> video file encoded at <b>10-20 Mbps</b> (if possible, select <b>High Profile</b> H.264
                setting instead of <b>Main Profile</b> when exporting the video)
              </li>
              <li>
                <b>Stereo or ProLogicII encoded 5.1 (LtRt)</b> - ONLY include a single stereo track or mono track pair -
                do not include additional mixes/tracks
              </li>
              <li>
                <b>1920x1080</b> resolution
              </li>
              <li>
                <b>23.98, 24, 25, or 29.97</b> progressive
              </li>
              <li>
                Under <b>{uploadLimit}GB</b> file size
              </li>
            </ul>
          </Modal>
        )}
      </>
    </Card>
  )
}

const mapStateToProps = state => ({
  video: state.ondemand_videos.video,
  authenticated: state.user.auth.status === "AUTHENTICATED",
  organizationName: state.ondemand_videos.organizationName,
  uploadLimit: state.ondemand_videos.uploadLimitInGB
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  showGlobalAuthModal: bindActionCreators(showGlobalAuthModal, dispatch),
  updateVideo: bindActionCreators(updateVideo, dispatch)
})

Video.propTypes = {
  video: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  showGlobalAuthModal: PropTypes.func.isRequired,
  organizationName: PropTypes.string,
  updateVideo: PropTypes.func.isRequired,
  uploadLimit: PropTypes.number.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Video)
