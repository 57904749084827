import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"

import projects from "./projects"
import account from "./account"
import utils from "./utils"
import messages from "./messages"
import transfers from "./transfers"

/* Project */
import details from "./project/details"
import files from "./project/files"
import folders from "./project/folders"
import filesModals from "./project/modals"
import filter from "./project/filter"
import history from "./project/history"
import screener from "./project/screener"
import tags from "./project/tags"
import asperaSharing from "./project/aspera_sharing"
import requests from "./project/requests"
import packages from "./project/packages"
import access from "./project/access"
import credits from "./project/credits"
import customFields from "./project/custom_fields"
import defroster from "./project/defroster"
import screenerAttachments from "./project/screener_attachments"

import orders from "./orders"
import auth from "./user/auth"
import billing from "./user/billing"
import password from "./user/password"
import devices from "./user/devices"
import connections from "./user/connections"
import notifications from "./user/notifications"
import mfa from "./user/mfa"
import plans from "./user/plans"
import support from "./user/support"
import cart from "./user/cart"
import reviewers from "./user/reviewers"
import shippingLabels from "./user/shipping_labels"
import organizations from "./organizations"
import roles from "./settings/organizations/roles"
import members from "./settings/organizations/members"
import departments from "./settings/organizations/departments"
import send from "./preview/send"
import projectFolders from "./project_folders"
import screenerRequest from "./screener_request"
import documentPreviewer from "./document_previewer"
import downloadLink from "./download_link"
import fileRequest from "./file_request"
import modals from "./modals"
import tour from "./tour"
import sharedScreeners from "./shared_screeners"
import ondemandVideos from "./ondemand_videos"
import screenerRooms from "./screener_rooms"

const createRootReducer = routerHistory => combineReducers({
  router: connectRouter(routerHistory),
  projects,
  projectFolders,
  user: combineReducers({
    auth,
    billing,
    password,
    devices,
    connections,
    notifications,
    mfa,
    cart,
    support,
    plans,
    reviewers,
    shippingLabels
  }),
  orders,
  account,
  modals,
  utils,
  messages,
  project: combineReducers({
    details,
    files: combineReducers({
      modals: filesModals,
      filter,
      items: files,
      folders,
      screener: screener,
      tags,
      asperaSharing
    }),
    customFields,
    history,
    credits,
    requests,
    packages,
    access,
    defroster,
    screenerAttachments
  }),
  transfers,
  organizations,
  shared_screeners: sharedScreeners,
  settings: combineReducers({
    organizations: combineReducers({
      roles,
      members,
      departments
    })
  }),
  preview: combineReducers({
    send
  }),
  screenerRequest,
  documentPreviewer,
  download_link: downloadLink,
  file_request: fileRequest,
  tour,
  ondemand_videos: ondemandVideos,
  screener_rooms: screenerRooms
})

export default createRootReducer
