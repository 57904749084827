import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { prevStep, nextStep, updateOrder } from "@api/project/orders"
import Select from "react-select"
import Slider from "rc-slider"
import { Status, Radio } from "@bitcine/cs-theme"
import "../styles.scss"

const SelectConversionFormat = ({
  prevStep,
  nextStep,
  items,
  filteredFormatItems,
  filteredResolutionItems,
  filteredFrameRateItems,
  requiresScaling,
  scaleItems,
  videoFormats,
  bitrateMarks,
  order,
  updateOrder,
  extension
}) => (
  <div className='col-12'>
    <Status pending={!items}>
      <div className={"orderInnerContainer"}>
        <label htmlFor='order-conversion-format-select' className='cs-label mt1'>
          Format
        </label>
        <Select
          inputProps={{
            id: "order-conversion-format-select"
          }}
          placeholder='Select which format you would like to convert to'
          options={filteredFormatItems}
          onChange={({ value }) => {
            updateOrder("output_video_type", value)
            updateOrder("bitrate", null)
          }}
          clearable={false}
          value={order.output_video_type}/>
        {bitrateMarks && (
          <div className='mt1 mb3'>
            <p>Select a bitrate:</p>
            <div className='px2'>
              <Slider
                step={null}
                onChange={value => {
                  updateOrder("bitrate_value", value)
                  updateOrder("bitrate", bitrateMarks[value])
                }}
                value={order.bitrate_value}
                marks={bitrateMarks}/>
            </div>
          </div>
        )}
        <label htmlFor='order-conversion-resolution-select' className='cs-label mt2'>
          Resolution
        </label>
        <Select
          inputProps={{
            id: "order-conversion-resolution-select"
          }}
          placeholder='Select which resolution you would like to convert to'
          options={filteredResolutionItems}
          onChange={({ value }) => {
            updateOrder("resolution", value)
            updateOrder("scale_value", null)
          }}
          clearable={false}
          value={order.resolution}/>
        {requiresScaling && (
          <div className='mt1'>
            {scaleItems.map((item, i) => (
              <div key={item.value} className={`flex items-center py1`}>
                <Radio
                  id={item.value}
                  checked={order.scale_value === item.value}
                  onChange={() => updateOrder("scale_value", item.value)}/>
                <label htmlFor={item.key} className='cs-label pl2 flex-auto'>
                  <strong>{item.label}</strong>
                </label>
              </div>
            ))}
          </div>
        )}
        <label htmlFor='order-conversion-frame-rate-select' className='cs-label mt2'>
          Frame Rate
        </label>
        <Select
          inputProps={{
            id: "order-conversion-frame-rate-select"
          }}
          placeholder='Select which frame rate you would like to convert to'
          options={filteredFrameRateItems}
          onChange={({ value }) => updateOrder("frame_rate", value)}
          clearable={false}
          value={order.frame_rate}/>
      </div>
    </Status>
    <div className='flex justify-between pt3 col-12'>
      <button className='cs-button white' onClick={prevStep}>
        Back
      </button>
      <button
        className='cs-button'
        disabled={
          !order.output_video_type ||
          !order.resolution ||
          !order.frame_rate ||
          (requiresScaling && !order.scale_value) ||
          (bitrateMarks && order.bitrate === null) ||
          (order.output_video_type === "same_as_source" &&
            order.resolution === "same_as_source" &&
            order.frame_rate === "same_as_source")
        }
        onClick={nextStep}>
        Next
      </button>
    </div>
  </div>
)

SelectConversionFormat.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  items: PropTypes.object,
  filteredFormatItems: PropTypes.array.isRequired,
  filteredResolutionItems: PropTypes.array.isRequired,
  filteredFrameRateItems: PropTypes.array.isRequired,
  scaleItems: PropTypes.array.isRequired,
  requiresScaling: PropTypes.bool.isRequired,
  videoFormats: PropTypes.array.isRequired,
  order: PropTypes.object.isRequired,
  updateOrder: PropTypes.func.isRequired,
  extension: PropTypes.string.isRequired,
  bitrateMarks: PropTypes.object,
  resolutions: PropTypes.array,
  frameRates: PropTypes.array
}

const buildFormattedItems = function(conversionItems, file, items, code, property) {
  if (items.length === 0) return []
  const keysArray = conversionItems ? Object.keys(conversionItems) : []
  const filteredItemCodes = keysArray.filter(function(itemCode) {
    return itemCode.indexOf("convert_" + code) !== -1 && itemCode.indexOf("_base") !== -1
  })
  let filteredItems = filteredItemCodes.map(function(itemCode) {
    const baseItem = conversionItems[itemCode]
    const basePrice = baseItem.price
    const toCode = baseItem.code.replace("convert_" + code + "_to_", "").replace("_base", "")
    const perMinutePrice = conversionItems[itemCode.replace("_base", "_per_minute")].price
    const totalPrice = basePrice + perMinutePrice * file.length_in_minutes
    const formattedPrice = baseItem.currency_symbol + totalPrice + " " + baseItem.currency_code
    const outputObject = items.find(item => item.value === toCode)
    return {
      label: outputObject.label + " (" + formattedPrice + ")",
      value: toCode
    }
  })
  if (code !== "scale") filteredItems.unshift({ label: "Same as Source", value: "same_as_source" })
  return filteredItems
}

const mapStateToProps = state => {
  const order = state.orders.active_order
  const file = order.video_file
  const conversionItems = state.utils.conversion_items

  // Grab all video formats and build line items:
  const videoFormats = state.utils.video_formats ? state.utils.video_formats : []
  const extension = file.type === "dcp" ? "dcp" : file.file_name.substring(file.file_name.lastIndexOf(".") + 1)
  const filteredFormatItems = buildFormattedItems(conversionItems, file, videoFormats, extension, "extension")

  // Grab all resolutions and build line items:
  const resolutions = state.utils.resolutions ? state.utils.resolutions : []
  const resolution = file.resolution
  const filteredResolutionItems = buildFormattedItems(conversionItems, file, resolutions, resolution, "value")

  // Grab all frame rates and build line items:
  const frameRates = state.utils.frame_rates ? state.utils.frame_rates : []
  const frameRate = file.frame_rate
  const filteredFrameRateItems = buildFormattedItems(conversionItems, file, frameRates, frameRate, "value")

  let requiresScaling = false
  const inputResolutionObject = resolutions.find(res => res.value === resolution)
  const outputResolutionObject = resolutions.find(res => res.value === order.resolution)
  if (inputResolutionObject && outputResolutionObject) {
    requiresScaling = inputResolutionObject.aspect_ratio !== outputResolutionObject.aspect_ratio
  }

  // Grab all scale items and build line items:
  const scales = [{ label: "Scale to Fit", value: "fit" }, { label: "Scale to Fill", value: "fill" }]
  const scaleItems = buildFormattedItems(conversionItems, file, scales, "scale", "value")

  // Build the bitrate marks for the slider
  const formatOutput = order.output_video_type ? videoFormats.find(fmt => fmt.value === order.output_video_type) : null
  const bitrates = formatOutput ? formatOutput.bitrates : []
  let bitrateMarks = {}
  bitrates.forEach((bitrate, i) => {
    let key = (100 / (bitrates.length - 1)) * i
    bitrateMarks[key] = bitrate.label
  })
  if (bitrates.length === 0) bitrateMarks = null

  return {
    items: state.utils.conversion_items,
    filteredFormatItems,
    filteredResolutionItems,
    filteredFrameRateItems,
    scaleItems,
    requiresScaling,
    order,
    extension,
    videoFormats,
    bitrateMarks,
    resolutions: state.utils.resolutions,
    frameRates: state.utils.frame_rates
  }
}

const mapDispatchToProps = dispatch => ({
  prevStep: bindActionCreators(prevStep, dispatch),
  nextStep: bindActionCreators(nextStep, dispatch),
  updateOrder: bindActionCreators(updateOrder, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectConversionFormat)
