import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Dropzone from "react-dropzone"
import { updateTrailer, initTrailer, updateVideo } from "@api/ondemand_videos/"
import { initializeHttpUpload } from "@api/transfer/http/upload"
import humanFileSize from "@src/helpers/humanFileSize"
import { videoStatusLabels } from "@helpers/constants"
import Card from "./card"
import CommonImporter from "./common_importer"

class Trailer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
      importing: false,
      importedName: null
    }
  }
  onDropSourceFile(file) {
    this.setState({ file_size_error: false, upload_pending: true })
    if (file.size > this.props.uploadLimitInGB * 1024 * 1024 * 1024) {
      this.setState({ file_size_error: true, upload_pending: false })
      return
    }
    const specs = {
      video_id: this.props.video.trailer._id
    }
    this.props.initializeHttpUpload(
      file,
      specs,
      result => {
        this.props.updateTrailer(this.props.video._id, { status: videoStatusLabels.UPLOADED })
        this.setState({
          upload_FULFILLED: true,
          upload_pending: false
        })
      },
      transfer => {
        this.setState({
          message: transfer.status,
          uploaded: transfer.current_size,
          total: transfer.total_size
        })
      }
    )
  }
  render() {
    const { video, updateVideo } = this.props
    const providedStatuses = [
      videoStatusLabels.PUBLISHED,
      videoStatusLabels.UNPUBLISHED,
      videoStatusLabels.ENCODING,
      videoStatusLabels.UPLOADED
    ]
    const isProvided = video.trailer && (providedStatuses.includes(video.trailer.status) || this.state.importedName)
    return (
      <Card
        title='Trailer Video'
        submitted={video.is_trailer_submitted}
        isUpload={true}
        className='mr2'
        submitButtonDisabled={!isProvided}
        isSubmitting={this.state.submitting || this.props.trailerIsInitializing}
        onSubmit={() => {
          this.setState({ submitting: true })
          updateVideo(video._id, { is_trailer_submitted: true }, () => {
            this.setState({ submitting: false })
          })
        }}>
        {video.trailer && isProvided ? (
          <div className='flex items-center'>
            <i className='material-icons green'>check_circle</i>
            <span className='ml1'>
              Provided file: <strong>{this.state.importedName ? this.state.importedName : video.trailer.source_file.name}</strong>
            </span>
          </div>
        ) : video.trailer ? (
          <div>
            <p>
              Optionally include a trailer for your video here. Just like the main video, we recommend a 1080p
              (1920x1080) H.264 / MP4 file that has a frame rate of 30fps or lower, and is under{" "}
              {this.props.uploadLimitInGB}GB in size.
            </p>
            <div className='flex flex-column' style={{ gap: "0.5em" }}>
              <Dropzone
                multiple={false}
                disabled={false}
                accept='.mp4,.mov'
                onDrop={(accepted, rejected) => this.onDropSourceFile(accepted[0])}
                className='dropzone'>
                {!this.state.upload_pending && !this.state.importing && (
                  <button
                    data-testid='video-request-select-image-btn'
                    disabled={this.state.upload_pending}
                    className={`cs-button col-12 outline small nowrap ${this.state.upload_pending ? "pending" : ""}`}>
                    {!video.trailer.is_active ? "Upload File From Computer" : "Reupload Video"}
                  </button>
                )}
              </Dropzone>
              <CommonImporter
                setImporting={() => this.setState({ importing: true })}
                target={video.trailer._id}
                setImportedName={importedName => this.setState({ importedName })}
                forceType='videos'/>
            </div>
            {this.state.upload_pending && !this.state.importing && (
              <div className='col-12'>
                {this.state.message}
                <progress max={this.state.total} value={this.state.uploaded} className='cs-progress col-12 block my1'/>
                <div className='small muted'>
                  {humanFileSize(this.state.uploaded)} / {humanFileSize(this.state.total)} uploaded
                </div>
              </div>
            )}
          </div>
        ) : (
          <button onClick={() => this.props.initTrailer(video._id)} className='cs-button outline small'>
            Include Trailer
          </button>
        )}
      </Card>
    )
  }
}

Trailer.propTypes = {
  updateTrailer: PropTypes.func.isRequired,
  video: PropTypes.object.isRequired,
  initializeHttpUpload: PropTypes.func.isRequired,
  uploadLimitInGB: PropTypes.any,
  initTrailer: PropTypes.func,
  trailerIsInitializing: PropTypes.bool,
  updateVideo: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  video: state.ondemand_videos.video,
  uploadLimitInGB: state.ondemand_videos.uploadLimitInGB,
  trailerIsInitializing: state.ondemand_videos.trailerIsInitializing
})

const mapDispatchToProps = dispatch => ({
  updateTrailer: bindActionCreators(updateTrailer, dispatch),
  initializeHttpUpload: bindActionCreators(initializeHttpUpload, dispatch),
  initTrailer: bindActionCreators(initTrailer, dispatch),
  updateVideo: bindActionCreators(updateVideo, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Trailer)
