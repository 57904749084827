import React from "react"
import PropTypes from "prop-types"
import Upload from "@src/public/upload.inline.svg"
import "./styles.scss"

const Summary = ({ request }) => (
  <section className='center py3'>
    {request.logo_url ? (
      <img src={request.logo_url} className='cs-image' style={{ maxWidth: "200px", maxHeight: "100" }}/>
    ) : (
      <Upload className={"largeLogo"} style={{ maxWidth: "200px", maxHeight: "50px" }}/>
    )}
    <h3>{request.name || request.requestee_name || ""} has requested files</h3>
  </section>
)

Summary.propTypes = {
  request: PropTypes.object.isRequired
}

export default Summary
