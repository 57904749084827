import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { prevStep, nextStep, updateOrder } from "@api/project/orders"
import "../styles.scss"

const DiscOptions = ({ prevStep, nextStep, order, items, updateOrder }) => (
  <div className='col-12'>
    <div className={"orderInnerContainer"}>
      <p>We just need a few more details about how you would like us to create your discs.</p>
      {[
        {
          label: "Disc Title",
          key: "disc_title",
          maxLength: 45,
          placeholder: "Title Printed on Disc"
        },
        {
          label: "Disc Type",
          key: "disc_type",
          maxLength: 30,
          placeholder: "e.g. Theatrical Screener"
        }
      ].map(d => (
        <div key={d.key} className='mb2'>
          <label className='cs-label' htmlFor={d.key}>
            {d.label}
          </label>
          <input
            value={order[d.key] || ""}
            onPaste={e => e.preventDefault()}
            onChange={e => {
              if (e.target.value.length < d.maxLength) updateOrder(d.key, e.target.value)
            }}
            placeholder={d.placeholder}
            className='cs-input col-12'/>
        </div>
      ))}
    </div>
    <div className='flex justify-between pt3 col-12'>
      <button className='cs-button white' onClick={prevStep}>
        Back
      </button>
      <button className='cs-button' disabled={!order.disc_title || !order.disc_type} onClick={nextStep}>
        Next
      </button>
    </div>
  </div>
)

DiscOptions.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  items: PropTypes.object,
  updateOrder: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const order = state.orders.active_order
  return {
    order,
    items: state.utils.disc_items
  }
}

const mapDispatchToProps = dispatch => ({
  prevStep: bindActionCreators(prevStep, dispatch),
  nextStep: bindActionCreators(nextStep, dispatch),
  updateOrder: bindActionCreators(updateOrder, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiscOptions)
