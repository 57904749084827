import React from "react"
import NoPosterIcon from "@src/public/icons/empty/poster.inline.svg"
import 'styled-components/macro'

const Empty = () => (
  <div className='light absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center'>
    <div className='center muted'>
      <NoPosterIcon
        css={`
          margin: -2em 0;
          width: 100px;
          height: 100px;
        `}/>
      <h4>No Poster</h4>
    </div>
  </div>
)

export default Empty
