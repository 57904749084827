import creditCardType from "credit-card-type"
import validator from "card-validator"

// Add out custom card type
creditCardType.types.CINESEND_GIFT_CARD = "cinesend-gift-card"

// Remove the MIR card type since it uses
// the same number pattern as our gift cards
// if (creditCardType.types && creditCardType.types.MIR) {
// 	validator.creditCardType.removeCard(creditCardType.types.MIR)
// }

// Add our custom card
validator.creditCardType.addCard({
  niceType: "CineSend Gift Card",
  type: creditCardType.types.CINESEND_GIFT_CARD,
  patterns: ['220'],
  // prefixPattern: /^220[0-3]/,
  // exactPattern: /^220[0-3][0-9]{12}/,
  gaps: [4, 8, 12],
  lengths: [16],
  code: {
    name: "PIN",
    size: 4
  }
})

const errorMsgs = {
  INVALID_NUMBER: "Not a valid credit card number",
  UNSUPPORTED_CARD: "We only accept Visa, Mastercard or American Express"
}

const status = {
  INCOMPLETE: "incomplete",
  UNSUPPORTED: "unsupported",
  INVALID: "invalid",
  VALID: "valid"
}

export const cardStatus = status
export const cardErrorMessages = errorMsgs
export const cardTypes = creditCardType.types
export const cardValidator = validator
