import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal, Table, Status } from "@bitcine/cs-theme"
import { getScreenerAnalytics, toggleAnalyticsModal } from "@api/screener_rooms/"
import Heatmap from "@src/components/shared_screeners/components/heatmap"

class ScreenerAnalytics extends Component {
  componentDidMount() {
    this.props.getScreenerAnalytics(this.props.screenerRoomId, this.props.screenerId)
  }
  render() {
    const { status, toggleAnalyticsModal, analytics } = this.props
    return (
      <Modal open width={3} clickOutsideCloses title='Analytics' onClose={() => toggleAnalyticsModal(null)}>
        <Status
          pending={status === "PENDING"}
          error={status === "ERROR"}
          empty={!analytics.length}
          emptyMessage={{
            icon: "show_chart",
            title: "No Views",
            text: "This screener has not yet been viewed by any reviewers from this room."
          }}>
          <div>
            <Table
              widths={["auto", 300]}
              header={{
                columns: [{ text: "Viewer" }, { text: "Watched" }]
              }}
              body={{
                data: analytics.map(a => ({ ...a, _id: a.screener_id })) || [],
                row: {
                  render: [
                    data => <div className='truncate'>{data.shared_screener_email || data.shared_screener_name}</div>,
                    data => (
                      <div className='flex items-center col-12 pr2'>
                        <Heatmap height='32px' data={data.watched}/>
                        <div style={{ width: "40px" }} className='ml2 muted right-align'>
                          {data.percentage_watched}%
                        </div>
                      </div>
                    )
                  ]
                }
              }}/>
          </div>
        </Status>
      </Modal>
    )
  }
}

ScreenerAnalytics.propTypes = {
  status: PropTypes.string.isRequired,
  analytics: PropTypes.array.isRequired,
  getScreenerAnalytics: PropTypes.func.isRequired,
  toggleAnalyticsModal: PropTypes.func.isRequired,
  screenerId: PropTypes.string.isRequired,
  screenerRoomId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired
}

const mapStateToProps = state => {
  return {
    status: state.screener_rooms.analytics.status,
    analytics: state.screener_rooms.analytics.data,
    organizationId: state.user.auth.organization_id,
    screenerRoomId: state.screener_rooms.screenerRoom.data._id,
    screenerId: state.screener_rooms.activeScreenerId
  }
}

const mapDispatchToProps = dispatch => ({
  getScreenerAnalytics: bindActionCreators(getScreenerAnalytics, dispatch),
  toggleAnalyticsModal: bindActionCreators(toggleAnalyticsModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ScreenerAnalytics)
