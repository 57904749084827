import React from "react"
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom"
import Project from "@containers/project"
import Files from "@components/project/files/index"
import FileRequests from "@components/project/requests"
import Details from "@components/project/details"
import General from "@components/project/details/general"
import Credits from "@components/project/details/credits"
import History from "@components/project/details/history"
import ScreenerAttachments from "@components/project/details/screener-attachments"
import Packages from "@components/project/packages"
import Screener from "@components/project/screener"

const ProjectRoutes = props => {
  const { path } = useRouteMatch()
  return (
    <Project {...props}>
      <Switch>
        <Redirect exact from={path} to={`${path}/files`}/>
        <Route exact path={`${path}/files`} component={Files}/>
        <Route path={`${path}/files/preview-link/:screenerID`} component={Screener}/>
        <Route path={`${path}/files/folder/:folderID`} component={Files}/>
        <Route path={`${path}/requests`} component={FileRequests}/>
        <Route path={`${path}/packages/:fileID`} component={Packages}/>
        <Route path={`${path}/details`}>
          <Details>
            <Switch>
                <Redirect exact from={`${path}/details`} to={`${path}/details/general`}/>
                <Route path={`${path}/details/general`} component={General}/>
                <Route path={`${path}/details/credits`} component={Credits}/>
                <Route path={`${path}/details/history`} component={History}/>
                <Route path={`${path}/details/screener-attachments`} component={ScreenerAttachments}/>
            </Switch>
          </Details>
        </Route>
      </Switch>
    </Project>
  )
}

export default ProjectRoutes