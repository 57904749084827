import React from "react"
import PropTypes from "prop-types"
import "../styles.scss"
import 'styled-components/macro'

const AddScreenerButton = ({ empty = false, onClick }) => (
  <div className='muted xl-col-3 lg-col-4 md-col-6 col-6 pr2 mt2' onClick={onClick}>
    <div className={`rounded container`}>
      <div
        className={`pointer light absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center z3`}
        css={`
          border: dashed;
        `}>
        <div className='center'>
          <span className='large-icon block material-icons'>queue_play_next</span>
          <h4>{empty ? "Add screener" : "Add another screener"}</h4>
        </div>
      </div>
    </div>
  </div>
)

AddScreenerButton.propTypes = {
  empty: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default AddScreenerButton
