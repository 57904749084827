import {
  GET_SCREENER_ROOMS_PENDING,
  GET_SCREENER_ROOMS_FULFILLED,
  UPDATE_SEARCH_STRING,
  UPDATE_NEW_SCREENER_ROOM,
  TOGGLE_CREATE_ROOM_MODAL,
  CREATE_SCREENER_ROOM_FULFILLED,
  GET_SCREENER_ROOM_FULFILLED,
  GET_SCREENER_ROOM_PENDING,
  ADD_PROJECTS_TO_ROOM_PENDING,
  ADD_PROJECTS_TO_ROOM_FULFILLED,
  ADD_SCREENERS_TO_ROOM_PENDING,
  ADD_SCREENERS_TO_ROOM_FULFILLED,
  TOGGLE_ANALYTICS_MODAL,
  GET_SCREENER_ANALYTICS_PENDING,
  GET_SCREENER_ANALYTICS_FULFILLED,
  REMOVE_SCREENER_FROM_ROOM_PENDING,
  REMOVE_SCREENER_FROM_ROOM_FULFILLED,
  REMOVE_PROJECT_FROM_ROOM_PENDING,
  REMOVE_PROJECT_FROM_ROOM_FULFILLED,
  UPDATE_SCREENER_ROOM_PENDING,
  UPDATE_SCREENER_ROOM_FULFILLED,
  DELETE_SCREENER_ROOM_PENDING,
  DELETE_SCREENER_ROOM_FULFILLED,
  GENERATE_PREVIEW_PENDING,
  GENERATE_PREVIEW_FULFILLED,
  TOGGLE_ADD_VIEWERS_MODAL,
  SET_SCREENER_ROOM,
  TOGGLE_SCREENER_ROOM_PERMISSIONS_MODAL,
  SAVE_SCREENER_GROUP_ACCESS_PENDING,
  SAVE_SCREENER_GROUP_ACCESS_FULFILLED,
  TOGGLE_SCREENER_GROUP_DELETE_MODAL,
  GET_SCREENER_ROOM_AVAILABLE_PROJECTS_PENDING,
  GET_SCREENER_ROOM_AVAILABLE_PROJECTS_FULFILLED,
  GET_SCREENER_ROOM_AVAILABLE_SCREENERS_PENDING,
  GET_SCREENER_ROOM_AVAILABLE_SCREENERS_FULFILLED,
  UPDATE_SCREENER_FULFILLED
} from "@actions/screener_rooms/"

import { CREATE_SHARED_SCREENERS } from "@actions/shared_screeners"

const initialState = {
  screenerRooms: {
    status: "PENDING",
    items: []
  },

  screenerRoom: {
    status: "PENDING",
    data: {},
    availableProjects: {
      status: "PENDING",
      items: []
    },
    availableScreeners: {
      status: "PENDING",
      items: []
    }
  },

  screeners: {
    status: "PENDING",
    items: []
  },

  projects: {
    status: "PENDING",
    items: []
  },

  analytics: {
    status: "PENDING",
    data: []
  },

  preview: {
    status: "PENDING",
    data: {}
  },

  searchString: "",
  cacheBuster: new Date().getTime(),
  showCreateRoomModal: false,
  showAddViewersModal: false,
  showAnalyticsModal: false,
  newScreenerRoom: "",
  managingScreenerRoom: false,
  activeScreenerId: null,
  showScreenerPermissionsModal: false,
  isSavingAccess: false,
  showDeleteModal: false,
  roomToDelete: null,
  isDeletingRoom: false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SCREENER_ROOMS_PENDING:
      return {
        ...state,
        screenerRooms: {
          status: "PENDING",
          items: []
        }
      }

    case GET_SCREENER_ROOMS_FULFILLED:
      return {
        ...state,
        screenerRooms: {
          status: "COMPLETE",
          items: action.payload.list,
          count: action.payload.count
        }
      }

    case GET_SCREENER_ROOM_PENDING:
    case ADD_PROJECTS_TO_ROOM_PENDING:
    case REMOVE_PROJECT_FROM_ROOM_PENDING:
    case ADD_SCREENERS_TO_ROOM_PENDING:
    case REMOVE_SCREENER_FROM_ROOM_PENDING:
    case UPDATE_SCREENER_ROOM_PENDING:
      return {
        ...state,
        screenerRoom: {
          ...state.screenerRoom,
          status: "PENDING",
          data: {}
        }
      }

    case GET_SCREENER_ROOM_FULFILLED:
    case ADD_PROJECTS_TO_ROOM_FULFILLED:
    case REMOVE_PROJECT_FROM_ROOM_FULFILLED:
    case ADD_SCREENERS_TO_ROOM_FULFILLED:
    case REMOVE_SCREENER_FROM_ROOM_FULFILLED:
    case UPDATE_SCREENER_ROOM_FULFILLED:
    case UPDATE_SCREENER_FULFILLED:
      return {
        ...state,
        screenerRoom: {
          ...state.screenerRoom,
          status: "COMPLETE",
          data: action.payload
        }
      }

    case SET_SCREENER_ROOM:
      return {
        ...state,
        screenerRoom: {
          ...state.screenerRoom,
          status: "COMPLETE",
          data: action.payload.screenerRoom
        }
      }

    case GET_SCREENER_ROOM_AVAILABLE_PROJECTS_PENDING:
      return {
        ...state,
        screenerRoom: {
          ...state.screenerRoom,
          availableProjects: {
            status: "PENDING",
            items: []
          }
        }
      }

    case GET_SCREENER_ROOM_AVAILABLE_PROJECTS_FULFILLED:
      return {
        ...state,
        screenerRoom: {
          ...state.screenerRoom,
          availableProjects: {
            status: "COMPLETE",
            items: action.payload
          }
        }
      }

    case GET_SCREENER_ROOM_AVAILABLE_SCREENERS_PENDING:
      return {
        ...state,
        screenerRoom: {
          ...state.screenerRoom,
          availableScreeners: {
            status: "PENDING",
            items: []
          }
        }
      }

    case GET_SCREENER_ROOM_AVAILABLE_SCREENERS_FULFILLED:
      return {
        ...state,
        screenerRoom: {
          ...state.screenerRoom,
          availableScreeners: {
            status: "COMPLETE",
            items: action.payload
          }
        }
      }

    case UPDATE_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload.searchString
      }

    case UPDATE_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload.searchString
      }

    case UPDATE_NEW_SCREENER_ROOM:
      return {
        ...state,
        newScreenerRoom: action.payload.text
      }

    case CREATE_SCREENER_ROOM_FULFILLED:
      return {
        ...state,
        newScreenerRoom: "",
        showCreateRoomModal: false
        // screenerRooms: {
        //  status: 'COMPLETE',
        //  items: action.payload.list,
        //  count: action.payload.count
        // }
      }

    case TOGGLE_ANALYTICS_MODAL:
      return {
        ...state,
        showAnalyticsModal: !state.showAnalyticsModal,
        activeScreenerId: action.payload.screenerId
      }

    case GET_SCREENER_ANALYTICS_PENDING:
      return {
        ...state,
        analytics: {
          status: "PENDING",
          data: []
        }
      }

    case GET_SCREENER_ANALYTICS_FULFILLED:
      return {
        ...state,
        analytics: {
          status: "COMPLETE",
          data: action.payload
        }
      }

    case DELETE_SCREENER_ROOM_PENDING:
      return {
        ...state,
        isDeletingRoom: true
      }

    case DELETE_SCREENER_ROOM_FULFILLED:
      return {
        ...state,
        screenerRooms: {
          ...state.screenerRooms,
          items: action.payload.list,
          count: action.payload.count
        },
        showDeleteModal: false,
        roomToDelete: null,
        isDeletingRoom: false
      }

    case GENERATE_PREVIEW_PENDING:
      return {
        ...state,
        preview: {
          status: "FETCHING",
          data: {}
        }
      }

    case GENERATE_PREVIEW_FULFILLED:
      return {
        ...state,
        preview: {
          status: "COMPLETE",
          data: action.payload
        }
      }

    case TOGGLE_ADD_VIEWERS_MODAL:
      return {
        ...state,
        showAddViewersModal: !state.showAddViewersModal
      }

    case CREATE_SHARED_SCREENERS.COMPLETE:
      return {
        ...state,
        viewers: {
          status: "COMPLETE",
          items: action.payload.shared_screeners
        }
      }

    case TOGGLE_SCREENER_ROOM_PERMISSIONS_MODAL:
      return {
        ...state,
        showScreenerPermissionsModal: !state.showScreenerPermissionsModal,
        activeScreenerId: action.payload.screenerRoomId
      }

    case SAVE_SCREENER_GROUP_ACCESS_FULFILLED:
      return {
        ...state,
        isSavingAccess: false,
        showScreenerPermissionsModal: false,
        screenerRoom: {
          ...state.screenerRoom,
          data: {
            ...state.screenerRoom.data,
            users: action.payload.users,
            user_ids: action.payload.user_ids
          }
        }
      }

    case SAVE_SCREENER_GROUP_ACCESS_PENDING:
      return {
        ...state,
        isSavingAccess: true
      }

    case TOGGLE_SCREENER_GROUP_DELETE_MODAL:
      return {
        ...state,
        showDeleteModal: !state.showDeleteModal,
        roomToDelete: !state.showDeleteModal ? action.payload.screenerRoom : null
      }

    case TOGGLE_CREATE_ROOM_MODAL:
      return {
        ...state,
        showCreateRoomModal: !state.showCreateRoomModal
      }

    default:
      return state
  }
}
