import {
  TOGGLE_ORDER_VISIBILITY,
  UPDATE_ORDER,
  ADD_ORDER_TO_CART_FAILED,
  GET_DRIVES_FULFILLED,
  GET_DRIVES_PENDING
} from "@actions/orders"

import { types } from "./constants"

const initialState = {
  active_order: null
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_ORDER_VISIBILITY:
      return {
        ...state,
        active_order: types[action.orderType]
      }

    case UPDATE_ORDER:
      return {
        ...state,
        active_order: {
          ...state.active_order,
          [action.key]: action.value
        }
      }

    case ADD_ORDER_TO_CART_FAILED:
      return {
        ...state,
        active_order: {
          ...state.active_order,
          error: true
        }
      }

    case GET_DRIVES_PENDING:
      return {
        ...state,
        active_order: {
          ...state.active_order,
          drive: null
        }
      }

    case GET_DRIVES_FULFILLED:
      return {
        ...state,
        active_order: {
          ...state.active_order,
          drive: action.payload
        }
      }

    default:
      return state
  }
}
