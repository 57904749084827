import React from "react"
import { Checkbox } from "@bitcine/cs-theme"
import DriveSelection from "../drives/selection"

const Drives = () => (
  <div className='mt3 py3 border-top border-gray-5'>
    <p>
      This DCP will be saved to your files in this account and can be sent, shared or downloaded at any time. If you
      need to send a physical copy now, you can order a drive below.
    </p>
    <div className='flex items-center mb1'>
      <span className='muted flex items-center'>
        <Checkbox checked={true} disabled={true} onChange={() => null}/>
      </span>
      <label className='cs-label flex-auto pl1'>
        <strong className='capitalize'>Save to CineSend Account</strong>
      </label>
      <strong>Included</strong>
    </div>
    <DriveSelection/>
  </div>
)

export default Drives
