import React from "react"
import PropTypes from "prop-types"
import { DEFAULTS } from "@constants"
import { GLOBALS } from "@src/config"
import { Status } from "@bitcine/cs-theme"
import dayjs from "dayjs"
import Logo from "@src/public/logo.inline.svg"

class Extension extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: "PENDING",
      screenerName: "",
      sharedTo: "",
      expiry: dayjs()
    }
  }
  componentDidMount() {
    this.api("GET", `${GLOBALS.API_URL}/shared-screeners/${this.props.match.params.sharedScreenerID}`, null, res =>
      this.setState({ screenerName: res.name, sharedTo: res.sharedScreener.shared_name, status: "READY" })
    )
  }
  extend() {
    this.setState({ status: "PENDING" }, () => {
      this.api(
        "PUT",
        `${GLOBALS.API_URL}/shared-screeners/${this.props.match.params.sharedScreenerID}/extension`,
        JSON.stringify({ expiry: this.state.expiry.toISOString() }),
        res => this.setState({ status: "SUCCESS" })
      )
    })
  }
  api(method, url, body, callback) {
    fetch(url, { ...DEFAULTS, method, body })
      .then(res => res.json())
      .then(res => callback(res))
      .catch(err => this.setState({ status: "ERROR" }))
  }
  render() {
    const { status, screenerName, sharedTo } = this.state
    return (
      <div
        style={{ zIndex: "10" }}
        /*
          This page in the app needs to be reponsive
          Since none of the app is responsive, setting this page as fixed above other components is a temp solution
        */
        className='fixed top-0 right-0 left-0 bottom-0 bg-white flex items-center justify-center p2'>
        <Status
          pending={status === "PENDING"}
          error={status === "ERROR"}
          /*
            Cheating and using the empty prop as a success message
            In @bitcine/cs-theme, this prop name should be updated to a more generic 'message'
            The props could be used for empty, success or custom states, or have a func render callback
          */
          empty={status === "SUCCESS"}
          emptyMessage={{
            title: "Expiry Extended",
            text: `The expiry date was updated to ${dayjs(this.state.expiry).format("MMMM, Do YYYY")}`,
            icon: "check_circle"
          }}>
          <div className='max-width-3 center mx-auto py3 relative col-12'>
            <Logo className='fixed top-0 left-0 ml2 mt2' style={{ maxWidth: "110px", maxHeight: "50px" }}/>
            <h4>{screenerName}</h4>
            <div className='mb2'>
              {sharedTo}
            </div>
            <div className='col-12 max-width-1 mx-auto mt3'>
              <label htmlFor='date' className='cs-label left-align'>
                New Expiry Date
              </label>
              <input
                id='date'
                placeholder='New Expiry Date'
                className='cs-input col-12'
                value={this.state.expiry.format("YYYY-MM-DD") || dayjs().format("YYYY-MM-DD")}
                onChange={e =>
                  this.setState({
                    expiry: dayjs(e.target.value || dayjs().format("YYYY-MM-DD"))
                  })
                }
                type='date'/>
              <button onClick={() => this.extend()} disabled={!this.state.expiry} className='cs-button col-12 mt2'>
                Confirm Expiry Date
              </button>
            </div>
          </div>
        </Status>
      </div>
    )
  }
}

Extension.propTypes = {
  match: PropTypes.object.isRequired
}

export default Extension
