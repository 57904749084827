export const fileTypes = {
  folder: "Folder",
  document: "Document",
  source_file: "Video",
  dcp: "DCP",
  audio_track: "Audio",
  subtitle_track: "Subtitles",
  closed_caption: "Captions",
  disc_output: "Disc"
}

export const fileHeaders = [
  {
    text: "",
    hideWithDialog: false
  },
  {
    text: "File",
    key: "name",
    hideWithDialog: false
  },
  {
    text: "Type",
    key: "type",
    hideWithDialog: false
  },
  {
    text: "Size",
    key: "size",
    hideWithDialog: true
  },
  {
    text: "Created",
    key: "created_at",
    hideWithDialog: true
  },
  {
    text: "",
    hideWithDialog: false
  }
]

export const filters = [
  { label: "Video Files", key: "source_file" },
  { label: "Discs", key: "disc_output" },
  { label: "DCPs", key: "dcp" },
  { label: "Audio", key: "audio_track" },
  { label: "Captions", key: "closed_caption" },
  { label: "Subtitles", key: "subtitle_track" },
  { label: "Documents", key: "document" },
  { label: "Folders", key: "folder" }
]
