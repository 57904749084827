import {
  GET_PROJECT_FILE_REQUESTS,
  SAVE_PROJECT_FILE_REQUEST,
  SEND_PROJECT_FILE_REQUEST,
  UPDATE_PROJECT_FILE_REQUEST,
  CREATE_PROJECT_FILE_REQUEST,
  TOGGLE_PROJECT_FILE_REQUEST_MODAL
} from "@actions/project/requests"
import { get, post, put } from "@api/fetch"
import { addGlobalMessage } from "@api/utils"

/**
 * Gets a file request for a project
 *
 * @returns {Function}
 */

export const getFileRequests = () => (dispatch, getState) =>
  dispatch(get(GET_PROJECT_FILE_REQUESTS, `projects/${getState().project.details._id}/fileRequests`))

/**
 * Creates a file request for a project
 *
 * @param {String} $title
 * @param {String} $destinationFolderID the destination_folder_id
 *
 * @returns {Function}
 */

export const createRequest = (title, destinationFolderID) => (dispatch, getState) => {
  dispatch(
    post(
      CREATE_PROJECT_FILE_REQUEST,
      `projects/${getState().project.details._id}/fileRequests`,
      {
        requests: [
          {
            title,
            destination_folder_id: destinationFolderID || "",
            project_id: getState().project.details._id
          }
        ]
      },
      data => {
        dispatch(toggleModal("show_share", { active_request: data[data.length - 1] }))
      }
    )
  )
}

/**
 * Updates file request state
 *
 * @param {String} $requestID
 * @param {String} $key
 * @param {Any} $value
 *
 * @returns {Function}
 */

export const updateRequest = (requestID, key, value) => (dispatch, getState) =>
  dispatch({ type: UPDATE_PROJECT_FILE_REQUEST, requestID, key, value })

/**
 * Saves a file request for a project
 *
 * @param {String} $requestID
 * @param {String} $key
 * @param {Any} $value
 *
 * @returns {Function}
 */

export const saveRequest = (requestID, key, value) => (dispatch, getState) =>
  dispatch(
    put(
      SAVE_PROJECT_FILE_REQUEST,
      `projects/${getState().project.details._id}/fileRequests/${requestID}`,
      { key, value },
      () => {
        dispatch(addGlobalMessage("Request saved!", "success"))
      }
    )
  )

/**
 * Emails a file request
 *
 * @param {String} $requestID
 * @param {String} $email
 * @param {String} $message
 *
 * @returns {Function}
 */

export const sendRequest = (requestID, email, message = null) => (dispatch, getState) =>
  dispatch(
    post(
      SEND_PROJECT_FILE_REQUEST,
      `projects/${getState().project.details._id}/fileRequests/${requestID}/send`,
      {
        email,
        message
      },
      () => dispatch(addGlobalMessage("Request sent!", "success"))
    )
  )

/**
 * Toggle modal visibility
 *
 * @param {String} $modal,
 * @param {Object} $payload
 *
 * @returns {Function}
 */

export const toggleModal = (modal, payload) => dispatch =>
  dispatch({ type: TOGGLE_PROJECT_FILE_REQUEST_MODAL, modal, payload: payload ? { ...payload } : {} })
