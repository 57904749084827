import React from "react"
import PropTypes from "prop-types"
import Messages from "@components/messages"
import { DragDropContext } from "react-dnd"
import HTML5Backend from "react-dnd-html5-backend"
import GlobalAuthModal from "@components/account/authModal"
import { updateUtils } from "@api/utils"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import OnboardingSlideshow from "@components/onboarding/slideshow"
import { toggleOnboardingModal } from "@api/modals"
import { getTeamMembers } from "@api/settings/organizations/members"
import AsperaModal from "../../components/project/aspera_modal"
import AccountWarningChecks from "./account_warning_checks"
import AsperaUpgradeMessage from "./aspera_upgrade_message"

class App extends React.Component {
  componentDidMount() {
    if (this.notOnboarded()) {
      this.props.toggleOnboardingModal()
    }
    this.props.getTeamMembers()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.authenticated !== this.props.authenticated && this.props.authenticated && this.notOnboarded()) {
      this.props.toggleOnboardingModal()
    }
  }
  notOnboarded = () => {
    return !this.props.hasBeenOnboarded
  }
  render() {
    return (
      <AccountWarningChecks>
        <AsperaUpgradeMessage/>
        {this.props.children}
        <Messages/>
        <GlobalAuthModal/>
        {this.props.showOnboardingModal && <OnboardingSlideshow/>}
        {this.props.asperaModal && <AsperaModal/>}
      </AccountWarningChecks>
    )
  }
}

App.propTypes = {
  children: PropTypes.node.isRequired,
  updateUtils: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  showOnboardingModal: PropTypes.bool.isRequired,
  toggleOnboardingModal: PropTypes.func.isRequired,
  hasBeenOnboarded: PropTypes.bool.isRequired,
  asperaModal: PropTypes.bool
}

const mapStateToProps = state => ({
  authenticated: state.user.auth.status === "AUTHENTICATED",
  showOnboardingModal: state.modals.onboardingModal.visible,
  hasBeenOnboarded: state.user.auth.onboarded,
  asperaModal: state.modals.asperaModal ?? false
})

const mapDispatchToProps = dispatch => ({
  updateUtils: bindActionCreators(updateUtils, dispatch),
  toggleOnboardingModal: bindActionCreators(toggleOnboardingModal, dispatch),
  getTeamMembers: bindActionCreators(getTeamMembers, dispatch)
})

App = DragDropContext(HTML5Backend)(App)

export default connect(mapStateToProps, mapDispatchToProps)(App)
