import { GET_RECENT_TAGS_FULFILLED, GET_RECENT_TAGS_PENDING, GET_TAGS_FULFILLED } from "@actions/project/tags"

const initialState = {
  status: "PENDING",
  recentStatus: "PENDING",
  list: [],
  recent: []
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_RECENT_TAGS_PENDING:
      return {
        ...state,
        recentStatus: "PENDING"
      }

    case GET_RECENT_TAGS_FULFILLED:
      return {
        ...state,
        recent: action.payload,
        recentStatus: "COMPLETE"
      }

    case GET_TAGS_FULFILLED:
      return {
        ...state,
        status: "COMPLETE",
        list: action.payload
      }

    default:
      return state
  }
}
