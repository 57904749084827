import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateCart } from "@api/account/cart"
import { Modal } from "@bitcine/cs-theme"
import Address from "./address"
import Rates from "./rates"

const NewShippingAddress = ({ cart: { newAddress }, updateCart }) => (
  <Modal
    open={true}
    onClose={() => updateCart("newAddress", { ...newAddress, visible: false })}
    title='Shipping Address'>
    {newAddress.step === "address" ? <Address/> : newAddress.step === "rates" ? <Rates/> : "An error occured"}
  </Modal>
)

NewShippingAddress.propTypes = {
  cart: PropTypes.object.isRequired,
  updateCart: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  cart: state.user.cart
})

const mapDispatchToProps = dispatch => ({
  updateCart: bindActionCreators(updateCart, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewShippingAddress)
