import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getProjects, updateProjects, deleteProjects, toggleDeleteProjectModal, searchProjects } from "@api/projects/"
import { push } from "connected-react-router"
import { Helmet } from "react-helmet"
import { openProjectFolderModal, moveProjectsToFolder } from "@api/project_folders"
import { Link } from "react-router-dom"
import { Radio, ButtonDropdown, Modal, Status, Empty } from "@bitcine/cs-theme"
import Icon from "@src/public/icons/upgrade-project-folders.inline.svg"
import CustomDragger from "@components/dragging/"
import Breadcrumb from "@components/projects/breadcrumb"
import Folder from "@components/projects/components/folder/"
import CreateProjectFolder from "@components/projects/components/folder/create/"
import DeleteProjectFolder from "@components/projects/components/folder/delete/"
import Project from "@components/projects/components/project/"
import AccessModal from "@components/projects/components/project/access/"
import AddProject from "@components/projects/components/project/create/"
import DeleteProjects from "@components/projects/components/project/delete/"
import TourTip from "@components/tour/tip"
import NoProjectsIcon from "@src/public/icons/empty/projects.inline.svg"
import SearchIcon from "@src/public/icons/empty/search.inline.svg"
import { can, MODIFY_PROJECTS, DELETE_PROJECTS } from "@src/helpers/authorization"
import _ from "lodash"
import 'styled-components/macro'

class Projects extends React.Component {
  constructor() {
    super()
    this.state = {
      showMoveDropDown: false,
      showFolderUpgradeModal: false,
      selectedFolder: null,
      moveDir: "down",
      text: "",
      searchQuery: ""
    }

    this.search = _.debounce(this.search.bind(this), 400)
  }
  componentDidMount() {
    this.getProjects()
  }
  getProjects() {
    this.props.getProjects(typeof this.props.match.params.folderSlug === "string" ? this.props.match.params.folderSlug : null)
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname === "/projects" && prevProps.location.pathname !== "/projects") {
      this.props.getProjects(false)
    } else if (typeof this.props.match.params.folderSlug === "string") {
      if (prevProps.match.params.folderSlug !== this.props.match.params.folderSlug) {
        this.props.getProjects(this.props.match.params.folderSlug)
      }
    }
  }
  search() {
    if (this.state.searchQuery.length > 0) {
      this.props.searchProjects(this.state.searchQuery)
    } else {
      this.getProjects()
    }
  }
  render() {
    const {
      status,
      list,
      updateProjects,
      filter,
      newProject,
      hasOrganization,
      accessModal,
      showCreateFolderModal,
      openProjectFolderModal,
      match: {
        params: { folderSlug }
      },
      breadcrumb,
      checkedProjects,
      moveProjectsToFolder,
      showDeleteFolder,
      folderBeingCreated,
      canUseProjectFolders,
      canEdit,
      canDelete,
      showDeleteProjectModal,
      toggleDeleteProjectModal
    } = this.props

    // Filter by search query + sort alphabetically
    const projects = list
      .filter(project => {
        const project_title = project.title || ""
        return project_title
          .toLowerCase()
          .trim()
          .includes(filter.search.toLowerCase().trim())
      })
      .sort((a, b) => {
        let a_title = a.title || ""
        let b_title = b.title || ""
        var titleA = a_title.toUpperCase()
        var titleB = b_title.toUpperCase()

        return titleA < titleB ? -1 : titleA > titleB ? 1 : 0
      })
    const folders = list.filter(project => project.is_folder)
    return (
      <React.Fragment>
        <Helmet title='CineSend | My Projects'/>
        <div className='p2'>
          <div className='flex items-center'>
            <Breadcrumb breadcrumb={breadcrumb}/>
            {checkedProjects.length ? (
              <div className='flex'>
                {((hasOrganization || canUseProjectFolders) && canEdit) && (
                  <ButtonDropdown
                    button={{
                      className: "white small",
                      testId: "projects-move-project-to-folder-btn",
                      onClick: () => {
                        this.setState({
                          showMoveDropDown: !this.state.showMoveDropDown
                        })
                      },
                      text: `Move Project${checkedProjects.length > 1 ? "s" : ""}`
                    }}
                    dropdown={{
                      clickCloses: false,
                      content: (
                        <div className='p2' style={{ width: "325px" }}>
                          <div className='mb2'>
                            <small className='muted'>
                              Move project
                              {checkedProjects.length > 1 && "s"} to folder
                            </small>
                          </div>
                          <div className='mb2'>
                            {typeof breadcrumb[breadcrumb.length - 2] === "object" ? (
                              <div className='flex items-center mb1'>
                                <Radio
                                  checked={
                                    this.state.selectedFolder !== null &&
                                    this.state.selectedFolder._id === breadcrumb[breadcrumb.length - 2]._id
                                  }
                                  onChange={() =>
                                    this.setState({
                                      selectedFolder: breadcrumb[breadcrumb.length - 2]
                                    })
                                  }/>

                                <span
                                  className='ml1 flex items-center'
                                  onClick={() =>
                                    this.setState({
                                      selectedFolder: breadcrumb[breadcrumb.length - 2]
                                    })
                                  }>
                                  <i className='material-icons' style={{ fontSize: "1em" }}>
                                    arrow_upward
                                  </i>
                                  <span className='ml1'>{breadcrumb[breadcrumb.length - 2].title}</span>
                                </span>
                              </div>
                            ) : (
                              breadcrumb.length > 0 && (
                                <div className='flex items-center mb1'>
                                  <Radio
                                    checked={
                                      this.state.selectedFolder !== null &&
                                      this.state.selectedFolder._id === "all_projects"
                                    }
                                    onChange={() =>
                                      this.setState({
                                        selectedFolder: {
                                          _id: "all_projects",
                                          title: "All projects"
                                        }
                                      })
                                    }/>

                                  <span
                                    className='ml1 flex items-center'
                                    onClick={() =>
                                      this.setState({
                                        selectedFolder: {
                                          _id: "all_projects",
                                          title: "All projects"
                                        }
                                      })
                                    }>
                                    <i className='material-icons' style={{ fontSize: "1em" }}>
                                      arrow_upward
                                    </i>
                                    <span className='ml1'>All projects</span>
                                  </span>
                                </div>
                              )
                            )}
                            {folders.map((folder, i) => (
                              <div
                                data-testid='projects-move-project-to-folder-select-btn'
                                key={i}
                                onClick={() => this.setState({ selectedFolder: folder })}
                                className='flex items-center mb1'>
                                <Radio
                                  checked={
                                    this.state.selectedFolder !== null && this.state.selectedFolder._id === folder._id
                                  }
                                  onChange={() => this.setState({ selectedFolder: folder })}/>
                                <span className='ml1'>{folder.title}</span>
                              </div>
                            ))}
                          </div>
                          <div className='right-align'>
                            <button
                              data-testid='projects-move-project-to-folder-submit-btn'
                              className='cs-button small ml1'
                              disabled={!this.state.selectedFolder}
                              onClick={() => {
                                moveProjectsToFolder(checkedProjects, this.state.selectedFolder)
                                this.setState({
                                  showMoveDropDown: false,
                                  selectedFolder: null
                                })
                              }}>
                              Move
                            </button>
                          </div>
                        </div>
                      )
                    }}/>
                )}
                {canDelete && <button className='cs-button ml2 small' onClick={() => toggleDeleteProjectModal()}>
                  Delete Project
                  {checkedProjects.length > 1 ? "s" : ""}
                </button>}
              </div>
            ) : (
              <div className='flex'>
                <input
                  type='text'
                  className='cs-input search mr2'
                  data-testid='projects-search-input'
                  value={this.state.searchQuery}
                  css={`
                    height: 34px;
                  `}
                  placeholder='Search for a project...'
                  onChange={({ target }) =>
                    this.setState(
                      {
                        searchQuery: target.value
                      },
                      () => {
                        this.search()
                      }
                    )
                  }/>
                {canEdit && <TourTip tipId='new_project'>
                  <ButtonDropdown
                    button={{
                      className: "small",
                      text: "New",
                      style: { width: "120px" },
                      testId: "projects-new-dropdown-btn"
                    }}
                    dropdown={{
                      clickCloses: true,
                      content: [
                        {
                          text: {
                            title: "New project",
                            description: "Create projects to store and manage your content"
                          },
                          icon: "note_add",
                          disabled: false,
                          onClick: () => {
                            updateProjects("newProject", {
                              ...newProject,
                              visible: true
                            })
                          }
                        },
                        {
                          disabled: !projects.length,
                          text: {
                            title: "New project grouping",
                            description: !projects.length
                              ? "You must have one project before creating a group"
                              : "Organize mutliple projects at once"
                          },
                          icon: "create_new_folder",
                          onClick: () => {
                            if (canUseProjectFolders) openProjectFolderModal()
                            else this.setState({ showFolderUpgradeModal: true })
                          }
                        }
                      ]
                    }}/>
                </TourTip>}
              </div>
            )}
          </div>
          <Status pending={["PENDING", "UPDATING"].includes(status)} error={status === "ERROR"}>
            {projects.length === 0 && !folderBeingCreated ? (
              <Empty
                icon={this.state.searchQuery.length > 0 ? <SearchIcon/> : <NoProjectsIcon/>}
                title={
                  this.state.searchQuery.length > 0
                    ? "No projects found"
                    : typeof this.props.match.params.folderSlug === "string"
                      ? "This folder has no projects"
                      : `You haven't created a project yet!`
                }
                text={this.state.searchQuery.length > 0 ? "Try your search again" : 'Click "New" above to get started'}/>
            ) : (
              <div
                data-testid='projects-list-wrap'
                style={{ marginLeft: "-1rem", marginRight: "-1rem" }}
                className='flex flex-wrap my3'>
                {projects.map(project => (
                  <div key={project._id} className='px2 mb3 col-6 sm-col-4 md-col-3 lg-col-2'>
                    {project.is_folder ? <Folder folder={project}/> : <Project project={project}/>}
                  </div>
                ))}
              </div>
            )}
          </Status>
        </div>
        {newProject.visible && <AddProject/>}
        {accessModal && <AccessModal/>}
        {showCreateFolderModal && <CreateProjectFolder folder={folderSlug}/>}
        {showDeleteFolder && <DeleteProjectFolder/>}
        <CustomDragger/>

        {showDeleteProjectModal && <DeleteProjects/>}
        <Modal
          open={this.state.showFolderUpgradeModal}
          onClose={() => this.setState({ showFolderUpgradeModal: false })}
          title='Project Folders'
          clickOutsideCloses={true}>
          <div className='center'>
            <Icon
              style={{
                width: "100px",
                height: "100px",
                marginBottom: "-1rem",
                marginTop: "-1rem"
              }}/>
            <h4>Plan Upgrade Required</h4>
            <p>
              Project folders help keep your projects organized into genres, categories or status.
              <br/>
              Project folders are included with all paid plans.
            </p>
            <Link to='settings/user/account/plan'>
              <button className='cs-button'>Upgrade My Plan</button>
            </Link>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  ...state.projects,
  newProject: state.projects.newProject,
  accessModal: state.project.access.modalOpen,
  hasOrganization: state.user.auth.organization_id !== null,
  showCreateFolderModal: state.projectFolders.showCreateModal,
  breadcrumb: state.projects.breadcrumb,
  checkedProjects: state.projects.checkedProjects,
  showDeleteFolder: !!state.projectFolders.folderToDelete,
  folderBeingCreated: state.projectFolders.isSaving,
  showDeleteProjectModal: state.projects.showDeleteModal,
  canUseProjectFolders: state.user.auth.can_use_project_folders,
  canEdit: can(state.user.auth, [MODIFY_PROJECTS]),
  canDelete: can(state.user.auth, [DELETE_PROJECTS]),
})

const mapDispatchToProps = dispatch => ({
  getProjects: bindActionCreators(getProjects, dispatch),
  updateProjects: bindActionCreators(updateProjects, dispatch),
  push: bindActionCreators(push, dispatch),
  openProjectFolderModal: bindActionCreators(openProjectFolderModal, dispatch),
  moveProjectsToFolder: bindActionCreators(moveProjectsToFolder, dispatch),
  deleteProjects: bindActionCreators(deleteProjects, dispatch),
  toggleDeleteProjectModal: bindActionCreators(toggleDeleteProjectModal, dispatch),
  searchProjects: bindActionCreators(searchProjects, dispatch)
})

Projects.propTypes = {
  deleteProjects: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  updateProjects: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  getProjects: PropTypes.func.isRequired,
  newProject: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  accessModal: PropTypes.bool.isRequired,
  user: PropTypes.object,
  toggleDeleteProjectModal: PropTypes.func.isRequired,
  showDeleteProjectModal: PropTypes.bool.isRequired,
  hasOrganization: PropTypes.bool.isRequired,
  push: PropTypes.func.isRequired,
  showCreateFolderModal: PropTypes.bool.isRequired,
  openProjectFolderModal: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  breadcrumb: PropTypes.array,
  checkedProjects: PropTypes.array.isRequired,
  moveProjectsToFolder: PropTypes.func.isRequired,
  showDeleteFolder: PropTypes.bool.isRequired,
  folderBeingCreated: PropTypes.bool.isRequired,
  canUseProjectFolders: PropTypes.bool.isRequired,
  searchProjects: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
