import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"
import { Status, Empty } from "@bitcine/cs-theme"
import EmptyCreditsIcon from "@src/public/icons/empty/credits.inline.svg"
import {
  getCreditCategories,
  reorderCreditCategories,
  saveCreditCategoryOrder,
  addCreditCategory,
  getCreditRoles
} from "@api/credits"
import "./credits/credits.scss"
import CreditCategory from "./credits/credit-category"

class Credits extends Component {
  constructor(props) {
    super(props)

    this.addCreditCategory = this.addCreditCategory.bind(this)
  }
  componentDidMount() {
    this.props.getCreditCategories(this.props.projectID)
    this.props.getCreditRoles(this.props.projectID)
  }
  addCreditCategory() {
    this.props.addCreditCategory(this.props.projectID)
  }
  render() {
    const { status, categories, reorderCreditCategories, saveCreditCategoryOrder } = this.props
    return (
      <div>
        <div className='flex items-center justify-between mb3'>
          <h3>Credits</h3>
          {!categories.length && (
            <button className='cs-button' onClick={this.addCreditCategory}>
              Add Credits
            </button>
          )}
        </div>
        <Status pending={status === "PENDING"} error={status === "ERROR"}>
          <div>
            {this.props.isAdding === true && (
              <div className='py3 center'>
                <div className='spinner'/>
              </div>
            )}
            {!categories.length ? (
              !this.props.isAdding ? (
                <Empty
                  icon={<EmptyCreditsIcon/>}
                  title='No credits'
                  text='Click "Add credits" above to add credits to this project'/>
              ) : null
            ) : (
              <div>
                <div
                  role='button'
                  onClick={this.addCreditCategory}
                  className={`link rounded flex items-stretch p2 col-12 bg-white box-shadow mb2 cursor`}>
                  <i className='material-icons mr2'>add_circle</i> Add Credit Category
                </div>
                {categories.map((category, i) => (
                  <CreditCategory
                    category={category}
                    key={i}
                    index={i}
                    total={categories.length}
                    moveCreditCategory={reorderCreditCategories}
                    projectID={this.props.projectID}
                    saveCreditCategory={saveCreditCategoryOrder}/>
                ))}
              </div>
            )}
          </div>
        </Status>
      </div>
    )
  }
}

Credits.propTypes = {
  getCreditCategories: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  reorderCreditCategories: PropTypes.func.isRequired,
  saveCreditCategoryOrder: PropTypes.func.isRequired,
  addCreditCategory: PropTypes.func.isRequired,
  params: PropTypes.any,
  isAdding: PropTypes.bool.isRequired,
  getCreditRoles: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    status: state.project.credits.status,
    categories: state.project.credits.categories,
    isAdding: state.project.credits.isAddingCategory,
    projectID: state.project.details._id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCreditCategories: bindActionCreators(getCreditCategories, dispatch),
    reorderCreditCategories: bindActionCreators(reorderCreditCategories, dispatch),
    addCreditCategory: bindActionCreators(addCreditCategory, dispatch),
    saveCreditCategoryOrder: bindActionCreators(saveCreditCategoryOrder, dispatch),
    getCreditRoles: bindActionCreators(getCreditRoles, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Credits)
