import {
  GET_TEAM_ROLES_PENDING,
  GET_TEAM_ROLES_FULFILLED,
  TOGGLE_CREATE_ROLE_MODAL,
  GET_DEFAULT_PERMISSIONS_PENDING,
  GET_DEFAULT_PERMISSIONS_FULFILLED,
  UPDATE_NEW_ROLE_PERMISSION,
  UPDATE_NEW_ROLE,
  SAVE_NEW_ROLE_PENDING,
  SAVE_NEW_ROLE_FULFILLED,
  TOGGLE_EDIT_MODAL,
  UPDATE_ROLE_PERMISSION,
  UPDATE_ROLE,
  SAVE_ROLE_PENDING,
  SAVE_ROLE_FULFILLED,
  DELETE_ROLE_FULFILLED
} from "@actions/settings/organizations"

const initialState = {
  status: "PENDING",
  items: [],

  newRole: {
    status: "PENDING",
    name: "",
    description: "",
    data: {}
  },

  activeRole: null,
  isSaving: false,

  showCreateModal: false,
  showEditModal: false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TEAM_ROLES_PENDING:
      return {
        ...state,
        status: "PENDING",
        items: []
      }

    case GET_TEAM_ROLES_FULFILLED:
      return {
        ...state,
        status: "COMPLETE",
        items: action.payload
      }

    case TOGGLE_CREATE_ROLE_MODAL:
      return {
        ...state,
        showCreateModal: !state.showCreateModal
      }

    case GET_DEFAULT_PERMISSIONS_PENDING:
      return {
        ...state,
        newRole: {
          ...state.newRole,
          status: "PENDING",
          name: "",
          description: "",
          data: {}
        }
      }

    case GET_DEFAULT_PERMISSIONS_FULFILLED:
      return {
        ...state,
        newRole: {
          ...state.newRole,
          status: "COMPLETE",
          data: action.payload
        }
      }

    case UPDATE_NEW_ROLE:
      return {
        ...state,
        newRole: {
          ...state.newRole,
          [action.payload.field]: action.payload.text
        }
      }

    case UPDATE_NEW_ROLE_PERMISSION:
      return {
        ...state,
        newRole: {
          ...state.newRole,
          data: {
            ...state.newRole.data,
            [action.payload.role]: {
              ...state.newRole.data[action.payload.role],
              permissions: {
                ...state.newRole.data[action.payload.role].permissions,
                [action.payload.permission]: {
                  ...state.newRole.data[action.payload.role].permissions[action.payload.permission],
                  value: !state.newRole.data[action.payload.role].permissions[action.payload.permission].value
                }
              }
            }
          }
        }
      }

    case SAVE_NEW_ROLE_PENDING:
      return {
        ...state,
        newRole: {
          ...state.newRole,
          status: "SAVING"
        }
      }

    case SAVE_NEW_ROLE_FULFILLED:
      return {
        ...state,
        items: action.payload,

        newRole: {
          ...state.newRole,
          status: "PENDING",
          name: "",
          description: "",
          data: {}
        },

        showCreateModal: false
      }

    case TOGGLE_EDIT_MODAL:
      return {
        ...state,
        showEditModal: !state.showEditModal,
        activeRole: action.payload.role
      }

    case UPDATE_ROLE:
      return {
        ...state,
        activeRole: {
          ...state.activeRole,
          [action.payload.field]: action.payload.text
        }
      }

    case UPDATE_ROLE_PERMISSION:
      return {
        ...state,
        activeRole: {
          ...state.activeRole,
          permissions: {
            ...state.activeRole.permissions,
            [action.payload.role]: {
              ...state.activeRole.permissions[action.payload.role],
              permissions: {
                ...state.activeRole.permissions[action.payload.role].permissions,
                [action.payload.permission]: {
                  ...state.activeRole.permissions[action.payload.role].permissions[action.payload.permission],
                  value: !state.activeRole.permissions[action.payload.role].permissions[action.payload.permission].value
                }
              }
            }
          }
        }
      }

    case SAVE_ROLE_PENDING:
      return {
        ...state,
        isSaving: true
      }

    case SAVE_ROLE_FULFILLED:
      return {
        ...state,
        items: action.payload,
        showEditModal: false,
        activeRole: null,
        isSaving: false
      }

    case DELETE_ROLE_FULFILLED:
      return {
        ...state,
        items: action.payload
      }

    default:
      return state
  }
}
