import React from "react"
import PropTypes from "prop-types"

class Pusher extends React.Component {
  static pusherClient = null
  static channels = {}
  componentDidMount() {
    this.bindPusherEvent(this.props.channel, this.props.event)
  }
  componentDidUpdate(prevProps) {
    if (prevProps.channel === this.props.channel && prevProps.event === this.props.event) {
      return
    }

    this.bindPusherEvent(this.props.channel, this.props.event)
    this.unbindPusherEvent(prevProps.channel, prevProps.event)
  }
  componentWillUnmount() {
    this.unbindPusherEvent(this.props.channel, this.props.event)
  }
  unbindPusherEvent = (channel, event) => {
    const pusherChannel = Pusher.pusherClient.channels.find(channel)
    if (pusherChannel) {
      pusherChannel.unbind(event, this.props.onUpdate)
    }

    pusherChannel.unbind("error", this.props.onError)

    Pusher.channels[channel]--

    if (Pusher.channels[channel] <= 0) {
      delete Pusher.channels[channel]
      Pusher.pusherClient.unsubscribe(channel)
    }
  }
  bindPusherEvent = (channel, event) => {
    const pusherChannel = Pusher.pusherClient.channels.find(channel) || Pusher.pusherClient.subscribe(channel)

    pusherChannel.bind(event, this.props.onUpdate)
    pusherChannel.bind("error", this.props.onError)

    if (Pusher.channels[channel] === undefined) {
      Pusher.channels[channel] = 0
    }

    Pusher.channels[channel]++
  }
  render() {
    return null
  }
}

Pusher.propTypes = {
  channel: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  event: PropTypes.string.isRequired,
  onError: PropTypes.func
}

export default Pusher

export const setPusherClient = pusherClient => {
  Pusher.pusherClient = pusherClient
}
