import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { removeProjectFromRoom } from "@api/screener_rooms/"
import { Status } from "@bitcine/cs-theme"
import EmptyScreenerRoomIcon from "@src/public/icons/empty/screener-room.inline.svg"
import SearchIcon from "@src/public/icons/empty/search.inline.svg"
import { canModifyScreenerRoom } from "@src/helpers/authorization"
import AnalyticsModal from "../../modals/screener_analytics"
import AddProjectsModal from "../../modals/add_projects"
import Project from "../components/project"

class Projects extends Component {
  constructor() {
    super()
    this.state = {
      search: "",
      sortDir: "ASC",
      showAddProjectsModal: false
    }
  }
  filter() {
    return this.props.screenerRoom.projects.filter(project =>
      project.title
        .trim()
        .toLowerCase()
        .includes(this.state.search.trim().toLowerCase())
    )
  }
  render() {
    const { showAnalyticsModal, canModify, preview } = this.props
    const filteredProjects = this.filter()
    return (
      <div>
        <div className='flex items-center justify-between'>
          <h4>Selected Projects</h4>
          <div className='flex items-center'>
            <input
              className='cs-input'
              onChange={({ target }) => this.setState({ search: target.value })}
              style={{ height: "34px" }}
              value={this.state.search}
              placeholder='Search...'/>
            <button
              className='cs-button small ml1 white'
              onClick={e => window.open(preview.data.url, "_blank")}
              disabled={preview.status !== "COMPLETE"}>
              Preview Room
            </button>
            {canModify && (
              <button onClick={() => this.setState({ showAddProjectsModal: true })} className='cs-button ml1 small'>
                Add Projects
              </button>
            )}
          </div>
        </div>
        <Status
          empty={!filteredProjects.length}
          emptyMessage={{
            icon: this.state.search ? <SearchIcon/> : <EmptyScreenerRoomIcon/>,
            title: this.state.search ? "No results found!" : "This screener room is empty!",
            text: this.state.search
              ? "Try your search again."
              : 'Click "Add Projects" above to browse your projects and add screeners to this room.'
          }}>
          <div className='m2 mx-auto'>
            {filteredProjects.map(project => (
              <div className='col-12' key={project._id}>
                <Project project={project} showRoomOptions={true}/>
              </div>
            ))}
          </div>
        </Status>
        {showAnalyticsModal && <AnalyticsModal/>}
        {this.state.showAddProjectsModal && (
          <AddProjectsModal onClose={() => this.setState({ showAddProjectsModal: false })}/>
        )}
      </div>
    )
  }
}

Projects.propTypes = {
  projects: PropTypes.array.isRequired,
  showAnalyticsModal: PropTypes.bool.isRequired,
  screenerRoom: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  removeProjectFromRoom: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  canModify: PropTypes.bool.isRequired,
  preview: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  user: state.user,
  screenerRoom: state.screener_rooms.screenerRoom.data,
  showAnalyticsModal: state.screener_rooms.showAnalyticsModal,
  organizationId: state.user.auth.organization_id,
  preview: state.screener_rooms.preview,
  canModify: canModifyScreenerRoom(state.user.auth, state.screener_rooms.screenerRoom.data)
})

const mapDispatchToProps = dispatch => ({
  removeProjectFromRoom: bindActionCreators(removeProjectFromRoom, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
