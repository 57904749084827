import { UPDATE_FILES_FILTER, RESET_FILES_FILTER, UPDATE_ALL_FILES_FILTER } from "@actions/project/filter/"

const initialState = {
  search: "",
  direction: "ASC",
  key: "",
  types: [],
  tags: []
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_FILES_FILTER:
      return {
        ...state,
        [action.key]: action.value
      }

    case RESET_FILES_FILTER:
      return initialState

    case UPDATE_ALL_FILES_FILTER:
      return {
        ...state,
        ...action.filters
      }

    default:
      return state
  }
}
