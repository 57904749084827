export const NEW_PAYMENT_INPUTS = {
  CREDIT_CARD: [
    {
      label: "Card Number",
      type: "text",
      key: "cardNumber",
      className: "col-12 mb2",
      errorTypes: ["invalid_number"]
    },
    {
      label: "Name on card",
      type: "text",
      key: "fullName",
      className: "col-12 mb2",
      errorTypes: ["invalid_name"]
    },
    {
      label: "Expiry",
      type: "select",
      placeholder: "Month",
      key: "expiryMonth",
      className: "col-4 pr2",
      options: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      errorTypes: ["invalid_expiry_month"]
    },
    {
      label: " ",
      placeholder: "Year",
      type: "select",
      key: "expiryYear",
      className: "col-4 pr2 flex items-end",
      options: [
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
        "2032",
        "2033",
        "2034",
        "2035"
      ],
      errorTypes: ["invalid_expiry_year"]
    },
    {
      label: "CVC",
      type: "text",
      key: "cvc",
      className: "col-4 pl4",
      errorTypes: ["invalid_cvc"]
    }
  ],
  GIFT_CARD: [
    {
      label: "Gift Card Number",
      key: "code",
      placeholder: "xxxx xxxx xxxx xxxx",
      type: "text",
      className: "col-8 pr2",
      errorTypes: ["invalid_code"]
    },
    {
      label: "Pin",
      key: "pin",
      placeholder: "xxxx",
      type: "text",
      className: "col-4",
      errorTypes: ["invalid_pin"]
    }
  ]
}
