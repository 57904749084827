import {
  GET_CREDIT_CATEGORIES_PENDING,
  GET_CREDIT_CATEGORIES_FULFILLED,
  REORDER_CREDIT_CATEGORIES,
  ADD_CREDIT_CATEGORY_PENDING,
  ADD_CREDIT_CATEGORY_FULFILLED,
  REORDER_CREDIT_ROLES_FULFILLED,
  UPDATE_CREDIT_CATEGORY_NAME,
  TOGGLE_MENU,
  TOGGLE_ROLES,
  REMOVE_CREDIT_CATEGORY,
  ADD_CREDIT_ROLE,
  ADD_CREDIT_ROLE_FULFILLED,
  UPDATE_CREDIT_ROLE_NAME,
  REMOVE_CREDIT_ROLE_FULFILLED,
  UPDATE_CREDIT_ROLE_FULFILLED,
  GET_CREDIT_ROLES_FULFILLED
} from "@actions/credits/"

import update from "immutability-helper"

const defaultRoles = [
  { value: "Producer", label: "Producer" },
  { value: "Director", label: "Director" },
  { value: "Writer", label: "Writer" },
  { value: "Actor", label: "Actor" },
  { value: "Camera Operator", label: "Camera Operator" },
  { value: "Rigger", label: "Rigger" },
  { value: "Lights", label: "Lights" },
  { value: "Executive Producer", label: "Executive Producer" }
]

const initialState = {
  status: "PENDING",
  isAddingCategory: false,
  showRoles: false,
  categories: [],
  roles: [],
  availableRoles: defaultRoles
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CREDIT_CATEGORIES_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_CREDIT_CATEGORIES_FULFILLED:
      const categories = []

      action.payload.forEach(category => {
        category["showDropdown"] = false
        categories.push(category)
      })

      return {
        ...state,
        categories: categories,
        status: "READY"
      }

    case UPDATE_CREDIT_CATEGORY_NAME:
      return update(state, {
        categories: {
          [action.payload.index]: {
            name: { $set: action.payload.name }
          }
        }
      })

    case REORDER_CREDIT_CATEGORIES:
      return update(state, {
        categories: {
          $splice: [
            [action.payload.dragIndex, 1],
            [action.payload.hoverIndex, 0, state.categories[action.payload.dragIndex]]
          ]
        }
      })

    case TOGGLE_MENU:
      return update(state, {
        categories: {
          [action.payload.index]: {
            showDropdown: {
              $set: !state.categories[action.payload.index].showDropdown
            }
          }
        }
      })

    case TOGGLE_ROLES:
      return update(state, {
        categories: {
          [action.payload.index]: {
            showRoles: {
              $set: !state.categories[action.payload.index].showRoles
            }
          }
        }
      })

    case REMOVE_CREDIT_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter(category => action.payload.category._id !== category._id)
      }

    case ADD_CREDIT_CATEGORY_PENDING:
      return {
        ...state,
        isAddingCategory: true
      }

    case ADD_CREDIT_CATEGORY_FULFILLED:
      return {
        ...state,
        categories: action.payload.map((res, i) => (i === 0 ? { ...res, showRoles: true } : res)),
        isAddingCategory: false
      }

    case ADD_CREDIT_ROLE:
      return state

    case ADD_CREDIT_ROLE_FULFILLED:
      const cats = []

      action.payload.forEach((category, i) => {
        category.showRoles = state.categories[i].showRoles
        cats.push(category)
      })

      return {
        ...state,
        categories: cats
      }

    case UPDATE_CREDIT_ROLE_NAME:
      return update(state, {
        categories: {
          [action.payload.categoryIndex]: {
            credits: {
              [action.payload.index]: {
                name: { $set: action.payload.name }
              }
            }
          }
        }
      })

    case REORDER_CREDIT_ROLES_FULFILLED:
      return update(state, {
        categories: {
          [action.meta.categoryIndex]: {
            credits: {
              $splice: [
                [action.meta.dragIndex, 1],
                [
                  action.meta.hoverIndex,
                  0,
                  state.categories[action.meta.categoryIndex].credits[action.meta.dragIndex]
                ]
              ]
            }
          }
        }
      })

    case REMOVE_CREDIT_ROLE_FULFILLED: 
      return update(state, {
        categories: {
          [action.meta.categoryIndex]: {
            credits: {
              $set: action.payload.credits
            }
          }
        }
      })

    case UPDATE_CREDIT_ROLE_FULFILLED:
      return update(state, {
        categories: {
          [action.meta.categoryIndex]: {
            credits: {
              [action.meta.index]: {
                role: { $set: action.meta.value }
              }
            }
          }
        }
      })

    case GET_CREDIT_ROLES_FULFILLED:
      return {
        ...state,
        availableRoles: [...state.availableRoles, ...action.payload]
      }

    default:
      return state
  }
}
