import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { nextStep } from "@api/account/cart"

const tmpSteps = {
  "/cart/shipping": {
    label: "Next: Payment",
    show_review_message: true
  },
  "/cart/payment": {
    label: "Next: Confirmation",
    show_review_message: true
  },
  "/cart/confirmation": {
    label: "Place Order",
    show_review_message: false
  }
}

const Next = ({ step, nextStep, disabled, status, message }) => (
  <div>
    {status === "FAILED" && <div className='red mb2'>{message}</div>}
    <button
      disabled={disabled}
      className={`cs-button col-12 ${status === "PURCHASING" ? "pending" : ""}`}
      onClick={() => nextStep(step)}>
      {tmpSteps[step]?.label}
    </button>
    {tmpSteps[step]?.show_review_message && (
      <small className='muted block right-align mt1'>You can review your order before payment</small>
    )}
  </div>
)

Next.propTypes = {
  step: PropTypes.string.isRequired,
  nextStep: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  disabled:
    state.user.cart.promo.status === "PENDING" ||
    state.user.cart.status === "PURCHASING" ||
    !state.user.cart.orders.length,
  status: state.user.cart.status,
  message: state.user.cart.error.message
})

const mapDispatchToProps = dispatch => ({
  nextStep: bindActionCreators(nextStep, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Next)
