import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { prevStep, nextStep } from "@api/project/orders"
import "../styles.scss"

const ConfirmConversionDetails = ({
  prevStep,
  nextStep,
  file,
  items,
  relevantItems,
  order,
  lengthInMins,
  formattedTotal
}) => (
  <div className='col-12'>
    <div className={orderInnerContainer}>
      <div className='bg-gray-6 rounded mb2 p2'>
        {relevantItems.map((item, i) => (
          <div key={i} className={`mb2 ${i > 0 ? "border-top border-gray-4 pt2" : ""}`}>
            <div className='flex items-center mb1'>
              <strong>{item.title}</strong>
            </div>
            <div className='flex items-center mb1'>
              <div className='flex-auto pr2'>{items[item.base].name.replace(item.title + " -", "")}</div>
              <strong>{items[item.base].price_formatted}</strong>
            </div>
            <div className='flex items-center mb1'>
              <div className='flex-auto pr2'>
                {items[item.perMinute].name.replace(item.title + " -", "")}
                <small style={{ marginTop: "-.25rem" }} className='block'>
                  {items[item.perMinute].price_formatted} per minute
                </small>
              </div>
              <strong>
                {items[item.perMinute].currency_symbol}
                {(items[item.perMinute].price * lengthInMins).toFixed(2)}
              </strong>
            </div>
          </div>
        ))}
      </div>
      <div className='flex items-center'>
        <strong className='flex-auto'>Subtotal</strong>
        <h4 className='mb0'>{formattedTotal}</h4>
      </div>
    </div>
    <div className='flex justify-between pt3 col-12'>
      <button className='cs-button white' onClick={prevStep}>
        Back
      </button>
      <button className='cs-button' onClick={nextStep}>
        Add to Cart
      </button>
    </div>
  </div>
)

ConfirmConversionDetails.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  items: PropTypes.object.isRequired,
  relevantItems: PropTypes.array.isRequired,
  lengthInMins: PropTypes.string.isRequired,
  formattedTotal: PropTypes.string.isRequired
}

const mapStateToProps = state => {
  const order = state.orders.active_order
  const file = order.video_file
  let relevantItems = []
  const conversionItems = state.utils.conversion_items

  const extension = file.type === "dcp" ? "dcp" : file.file_name.substring(file.file_name.lastIndexOf(".") + 1)
  const lengthInMins = file.length_in_minutes
  let totalPrice = 0
  let currencyCode = ""
  let currencySymbol = ""

  if (order.output_video_type !== "same_as_source") {
    const baseCode = "convert_" + extension + "_to_" + order.output_video_type + "_base"
    const perMinuteCode = "convert_" + extension + "_to_" + order.output_video_type + "_per_minute"
    relevantItems.push({ title: "Format Conversion", base: baseCode, perMinute: perMinuteCode })
  }
  if (order.resolution !== "same_as_source") {
    const baseCode = "convert_" + file.resolution + "_to_" + order.resolution + "_base"
    const perMinuteCode = "convert_" + file.resolution + "_to_" + order.resolution + "_per_minute"
    relevantItems.push({ title: "Resolution Conversion", base: baseCode, perMinute: perMinuteCode })
  }
  if (order.scale_value) {
    const baseCode = "convert_scale_to_" + order.scale_value + "_base"
    const perMinuteCode = "convert_scale_to_" + order.scale_value + "_per_minute"
    relevantItems.push({ title: "Resolution Conversion", base: baseCode, perMinute: perMinuteCode })
  }
  if (order.frame_rate !== "same_as_source") {
    const baseCode = "convert_" + file.frame_rate + "_to_" + order.frame_rate + "_base"
    const perMinuteCode = "convert_" + file.frame_rate + "_to_" + order.frame_rate + "_per_minute"
    relevantItems.push({ title: "Frame Rate Conversion", base: baseCode, perMinute: perMinuteCode })
  }

  relevantItems.forEach(item => {
    totalPrice += conversionItems[item.base].price
    totalPrice += conversionItems[item.perMinute].price * lengthInMins
    currencyCode = conversionItems[item.base].currency_code
    currencySymbol = conversionItems[item.base].currency_symbol
  })

  const formattedTotal = currencySymbol + totalPrice.toFixed(2) + " " + currencyCode

  return {
    order,
    file,
    items: conversionItems,
    relevantItems,
    lengthInMins: lengthInMins,
    formattedTotal: formattedTotal
  }
}

const mapDispatchToProps = dispatch => ({
  prevStep: bindActionCreators(prevStep, dispatch),
  nextStep: bindActionCreators(nextStep, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmConversionDetails)
