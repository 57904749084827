import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { getDownloadLink } from "@api/download_link/"
import Links from "@components/download_link"
import Footer from "@components/requests/footer"
import Header from "@components/requests/header"
import Expired from "@components/download_link/components/expired"
import { Status } from "@bitcine/cs-theme"
import { withRouter } from "react-router-dom"
import DocumentPreviewer from "@components/document_previewer"
import FolderContent from "@components/project/files/modals/folder"
import styled from "react-emotion"
import Password from "./password"

const PageContainer = styled("div")`
  position: relative;
  min-height: 100vh;
`

const ContentContainer = styled("div")`
  position: relative;
  padding-top: 64px;
  padding-bottom: 140px;
`

class DownloadLink extends React.Component {
  componentDidMount() {
    this.props.getDownloadLink(this.props.match.params.transferID)
  }
  render() {
    let { showDocumentPreviewer, modals } = this.props
    return (
      <PageContainer>
        <Header/>
        <ContentContainer>
          <div className='px3 pb3 pt2'>
            <Status
              pending={this.props.status === "PENDING"}
              error={this.props.status === "ERROR"}
              errorMessage={this.props.errorMessage}>
              {this.props.status === "EXPIRED" ? (
                <Expired/>
              ) : this.props.status === "PROTECTED" ? (
                <Password/>
              ) : (
                <Links/>
              )}
            </Status>
          </div>
        </ContentContainer>
        <Footer/>
        {showDocumentPreviewer && <DocumentPreviewer hideActions={true}/>}
        {modals.folderContent.visible && <FolderContent/>}
      </PageContainer>
    )
  }
}

DownloadLink.propTypes = {
  status: PropTypes.oneOf(["PENDING", "ERROR", "READY", "EXPIRED", "PROTECTED"]).isRequired,
  errorMessage: PropTypes.string,
  getDownloadLink: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  showDocumentPreviewer: PropTypes.bool.isRequired,
  modals: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  status: state.download_link.status,
  errorMessage: state.download_link.error_message,
  showDocumentPreviewer: state.documentPreviewer.isOpen,
  modals: state.project.files.modals
})

const mapDispatchToProps = dispatch => ({
  getDownloadLink: bindActionCreators(getDownloadLink, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DownloadLink))
