import {
  GET_SCREENER_ATTACHMENTS,
  UPDATE_SCREENER_ATTACHMENTS_LIST,
  DELETE_SCREENER_ATTACHMENT,
  IMPORT_FILES_FOR_SCREENER_ATTACHMENT
} from "@actions/project/screener_attachments"

import { get, del, post } from "@api/fetch"
import { addGlobalMessage } from "@api/utils"

export const getScreenerAttachments = () => (dispatch, getState) => {
  dispatch(get(GET_SCREENER_ATTACHMENTS, `projects/${getState().project.details._id}/screener-attachments`))
}

export const updateList = items => dispatch => {
  dispatch({
    type: UPDATE_SCREENER_ATTACHMENTS_LIST,
    payload: {
      items
    }
  })
}

export const deleteAttachment = item => (dispatch, getState) => {
  dispatch(
    del(
      DELETE_SCREENER_ATTACHMENT,
      `projects/${getState().project.details._id}/screener-attachments/${item._id}`,
      {},
      () => {
        dispatch(addGlobalMessage("Attachment Deleted!", "success"))
      },
      () => {
        dispatch(addGlobalMessage("Failed to delete attachment. Refresh and try again.", "error"))
      }
    )
  )
}

export const importFiles = (files, projectID) => (dispatch, getState) => {
  dispatch(
    post(
      IMPORT_FILES_FOR_SCREENER_ATTACHMENT,
      `projects/${getState().project.details._id}/screener-attachments/import`,
      {
        fileList: files,
        projectID
      }
    )
  )
}
