export default function loadStyles(url, id, cb) {
  var ss = document.createElement("link")

  ss.rel = "stylesheet"
  ss.type = "text/css"
  ss.id = id

  document.getElementsByTagName("head")[0].appendChild(ss)

  if (ss.readyState) {
    // IE
    ss.onreadystatechange = function() {
      if (ss.readyState === "loaded" || ss.readyState === "complete") {
        ss.onreadystatechange = null
        cb()
      }
    }
  } else {
    ss.onload = cb
  }

  ss.href = url
}
