import React from "react"
import OrderImg from "../images/order_v2.png"
import BaseSlide from "./components/base"
import Footer from "./components/footer"
import 'styled-components/macro'

const OrderSlide = () => (
  <BaseSlide>
    <div
      css={`
        padding: 60px 200px 0 200px;
      `}>
      <img className='cs-img' src={OrderImg} alt='Order'/>
    </div>
    <Footer
      bg='red'
      header='Order DCPs, disc screeners, and more!'
      content='Through CineSend, you can easily convert video files into different digital formats such as DCP, IMF, ProRes and more. You can also order physical formats like DVDs, Blu-rays, or broadcast tapes.'/>
  </BaseSlide>
)

export default OrderSlide
