import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getOrders } from "@api/account/billing"
import { Status, Table } from "@bitcine/cs-theme"
import NoOrdersIcon from "@src/public/icons/empty/orders.inline.svg"
import dayjs from "dayjs"
import { CART_ICONS, STATUS_MAP } from "@constants"
import { updateSupport } from "@api/account/support"
import { push } from "connected-react-router"
import { GLOBALS } from "@src/config"

const Orders = ({ getOrders, orders, status, total }) => {
  const [page, setPage] = useState(0)
  const [take, setTake] = useState(10)
  useEffect(() => {
    getOrders(page, take)
  }, [page])
  return (
    <section className='p3'>
      <h3>Orders</h3>
      <Status
        empty={orders.length === 0}
        emptyMessage={{
          title: "No orders created",
          icon: <NoOrdersIcon/>,
          text: "Once you've made a purchase, return here to track and see the status of your orders."
        }}
        pending={status === "PENDING"}
        error={status === "ERROR"}>
        <Table
          widths={[55, 120, 300, 200, "auto", 140, 280]}
          paginate={{
            currentPage: page,
            pageCount: total / take,
            onPageChange: page => setPage(page)
          }}
          header={{
            columns: [
              { text: "" },
              { text: "Order #" },
              { text: "Project" },
              { text: "Order Type" },
              { text: "Status" },
              { text: "Date created", key: "" },
              { text: "" }
            ]
          }}
          body={{
            data: orders,
            row: {
              render: [
                data => <span style={{ fontSize: "24px" }} className='material-icons'>
                  {CART_ICONS[data.order_type
                    .split(" ")
                    .join("_")
                    .toLowerCase()]}
                </span>,
                data => data.order_number,
                data => data.project_title,
                data => data.order_title,
                order => <div className='flex flex-column'>
                  <div className='flex items-center'>
                    <div
                      className='flex items-center'
                      style={{
                        color: STATUS_MAP[order.job_status].color
                      }}>
                      <span className='material-icons mr1'>{STATUS_MAP[order.job_status].icon}</span>
                      <b>{STATUS_MAP[order.job_status].text}</b>
                    </div>
                    {order.shipment && order.upload_status && (
                      <div
                        className='flex items-center ml2'
                        style={{
                          color: STATUS_MAP[order.upload_status].color
                        }}>
                        <span className='material-icons mr1'>{STATUS_MAP[order.upload_status].icon}</span>
                        <b>Upload {STATUS_MAP[order.upload_status].text}</b>
                      </div>
                    )}
                  </div>
                  {order.shipment && order.shipment.destination_address && (
                    <div className='small flex'>
                      <div className='mr2'>FedEx Tracking #:</div>
                      {order.shipment.tracking_number ? <b>{order.shipment.tracking_number}</b> : <span className='muted'>Waiting for shipment</span>}
                    </div>
                  )}
                </div>,
                data => dayjs.utc(data.created_at).format("MMM, Do, YYYY"),
                order => <div className='flex'>
                  <button
                    className='cs-button white small mr1'
                    onClick={() =>
                      window.open(`${GLOBALS.API_URL}/settings/invoices/${order.invoice_id}`, "_blank")
                    }>
                    View Invoice
                  </button>
                  {(order.job_status === "Complete" || order.upload_status === "Complete") && (
                    <button
                      className='cs-button white small'
                      onClick={() => push(`/projects/${order.project_id}`)}>
                      View File
                    </button>
                  )}
                  {order.job_status !== "Complete" && order.job_status !== "Shipped" && order.upload_status !== "Complete" && (
                    <button
                      className='cs-button white small'
                      onClick={() => {
                        updateSupport("order", order.order_number + " - " + order.order_title)
                        updateSupport("visible", true)
                      }}>
                      Contact Support
                    </button>
                  )}
                </div>
              ]
            }
          }}/>
      </Status>
    </section>
  )
}

Orders.propTypes = {
  getOrders: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  updateSupport: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  orders: state.user.billing.orders.list,
  total: state.user.billing.orders.total,
  status: state.user.billing.orders.status
})

const mapDispatchToProps = dispatch => ({
  getOrders: bindActionCreators(getOrders, dispatch),
  push: bindActionCreators(push, dispatch),
  updateSupport: bindActionCreators(updateSupport, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Orders)
