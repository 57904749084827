import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toggleModal } from "@api/shared_screeners/modals"
import { updateSharedScreener } from "@api/shared_screeners"
import { Modal } from "@bitcine/cs-theme"

const ViewCount = ({ sharedScreener, updateSharedScreener, toggleModal }) => {
  const [limit, setLimit] = useState(sharedScreener.view_count_limit);

  return (
    <Modal open title={"View Count Limit - " + sharedScreener.shared_name} onClose={() => toggleModal("viewCountLimit")}>
      <div>
        <div>
        <label htmlFor="viewCount">Update View Count</label>
        <input
          min={"0"} 
          label={"View Count Limit"}
          id='viewCount'
          value={limit}
          type='number'
          autoFocus
          className='cs-input col-12 block'
          onChange={(input) => {
            setLimit(input.target.value)
          }}  
        />
      </div>
      <div className={`mt3 flex right`}>
        <button
          className='cs-button'
          disabled={limit === null}
          onClick={() => {
            updateSharedScreener(sharedScreener._id, "view_count_limit", limit)
          }}>
          Save
        </button>
      </div>
      </div>
      
    </Modal>
  )}

ViewCount.propTypes = {
  sharedScreener: PropTypes.object.isRequired,
  updateSharedScreener: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired
}

const mapStateToProps = ({ shared_screeners }) => ({
  sharedScreener: shared_screeners.modals.viewCountLimit.sharedScreener
})

const mapDispatchToProps = dispatch => ({
  updateSharedScreener: bindActionCreators(updateSharedScreener, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewCount)
