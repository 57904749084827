import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { setTourSteps, startTour } from "@api/tour"

class TourManager extends React.Component {
  componentDidMount() {
    if (this.props.disabled) {
      return
    }

    this.initTour()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.disabled !== this.props.disabled) {
      this.initTour()
    }
  }
  initTour = () => {
    const cachedSteps = JSON.parse(localStorage.getItem("cinesend:viewed_tour_steps")) || []
    const mergedSteps = [...cachedSteps, ...this.props.user.viewed_tour_steps]

    const steps = this.props.steps.filter(step => !mergedSteps.includes(step.key))

    if (steps.length > 0) {
      this.props.setTourSteps(steps)

      setTimeout(() => {
        this.props.startTour()
      }, 1200)
    }
  }
  render() {
    return null
  }
}

TourManager.propTypes = {
  steps: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  setTourSteps: PropTypes.func.isRequired,
  startTour: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

TourManager.defaultProps = {
  disabled: false
}

const mapStateToProps = state => ({
  user: state.user.auth
})

const mapDispatchToProps = dispatch => ({
  setTourSteps: bindActionCreators(setTourSteps, dispatch),
  startTour: bindActionCreators(startTour, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TourManager)
