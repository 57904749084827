import {
  GET_SCREENER_REQUEST_PENDING,
  GET_SCREENER_REQUEST_FAILED,
  GET_SCREENER_REQUEST_FULFILLED,
  UPDATE_SCREENER_IN_SCREENER_REQUEST,
  GET_AVAILABLE_SCREENERS_FOR_SCREENER_REQUEST_PENDING,
  GET_AVAILABLE_SCREENERS_FOR_SCREENER_REQUEST_FAILED,
  GET_AVAILABLE_SCREENERS_FOR_SCREENER_REQUEST_FULFILLED,
  SYNC_SCREENER_WITH_SCREENERLAB_PENDING,
  SYNC_SCREENER_WITH_SCREENERLAB_FAILED,
  SYNC_SCREENER_WITH_SCREENERLAB_FULFILLED,
  UPDATE_SCREENER
} from "@actions/screener_request/"

const initialState = {
  status: "PENDING",
  availableScreeners: {
    status: "PENDING",
    list: []
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SCREENER_REQUEST_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_SCREENER_REQUEST_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case GET_SCREENER_REQUEST_FULFILLED:
      return {
        ...state,
        status: "READY",
        ...action.payload
      }

    case UPDATE_SCREENER.COMPLETE:
      return {
        ...state,
        status: "READY",
        screener: action.payload
      }

    case UPDATE_SCREENER_IN_SCREENER_REQUEST:
      return {
        ...state,
        screener: {
          ...state.screener,
          [action.key]: action.value
        }
      }

    case GET_AVAILABLE_SCREENERS_FOR_SCREENER_REQUEST_PENDING:
      return {
        ...state,
        availableScreeners: initialState.availableScreeners
      }

    case GET_AVAILABLE_SCREENERS_FOR_SCREENER_REQUEST_FULFILLED:
      return {
        ...state,
        availableScreeners: {
          status: "READY",
          list: action.payload.filter(project => project.screeners && project.screeners.length > 0)
        }
      }

    case GET_AVAILABLE_SCREENERS_FOR_SCREENER_REQUEST_FAILED:
      return {
        ...state,
        availableScreeners: {
          ...state.availableScreeners,
          status: "ERROR"
        }
      }

    case SYNC_SCREENER_WITH_SCREENERLAB_PENDING:
      return {
        ...state,
        availableScreeners: {
          ...state.availableScreeners,
          status: "SYNCING"
        }
      }

    case SYNC_SCREENER_WITH_SCREENERLAB_FAILED:
      return {
        ...state,
        availableScreeners: {
          ...state.availableScreeners,
          status: "ERROR"
        }
      }

    case SYNC_SCREENER_WITH_SCREENERLAB_FULFILLED:
      return {
        ...state,
        ...action.payload,
        availableScreeners: {
          ...state.availableScreeners,
          status: "COMPLETE"
        }
      }

    default:
      return state
  }
}
