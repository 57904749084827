export const CLEAR_SCREENER = "CLEAR_SCREENER"
export const SET_SCREENER = "SET_SCREENER"
export const UPDATE_SCREENER = "UPDATE_SCREENER"

export const GET_REVIEWERS = "GET_REVIEWERS"
export const GET_REVIEWERS_PENDING = "GET_REVIEWERS_PENDING"
export const GET_REVIEWERS_FAILED = "GET_REVIEWERS_REJECTED"
export const GET_REVIEWERS_FULFILLED = "GET_REVIEWERS_FULFILLED"

export const UPDATE_SHARED_SCREENER_PASSWORD = "UPDATE_SHARED_SCREENER_PASSWORD"
export const UPDATE_SHARED_SCREENER_PASSWORD_PENDING = "UPDATE_SHARED_SCREENER_PASSWORD_PENDING"
export const UPDATE_SHARED_SCREENER_PASSWORD_FULFILLED = "UPDATE_SHARED_SCREENER_PASSWORD_FULFILLED"
export const UPDATE_SHARED_SCREENER_PASSWORD_FAILED = "UPDATE_SHARED_SCREENER_PASSWORD_REJECTED"

export const POLL_REVIEWERS = "POLL_REVIEWERS"
export const POLL_REVIEWERS_PENDING = "POLL_REVIEWERS_PENDING"
export const POLL_REVIEWERS_FULFILLED = "POLL_REVIEWERS_FULFILLED"

export const SET_REVIEWERS = "SET_REVIEWERS"

export const SAVE_SCREENER = "SAVE_SCREENER"

export const GET_SCREENER_VIEWS = "GET_SCREENER_VIEWS"
export const GET_SCREENER_VIEWS_PENDING = "GET_SCREENER_VIEWS_PENDING"
export const GET_SCREENER_VIEWS_FAILED = "GET_SCREENER_VIEWS_REJECTED"
export const GET_SCREENER_VIEWS_FULFILLED = "GET_SCREENER_VIEWS_FULFILLED"

export const GET_SCREENER_ANALYTICS = "GET_SCREENER_ANALYTICS"
export const GET_SCREENER_ANALYTICS_PENDING = "GET_SCREENER_ANALYTICS_PENDING"
export const GET_SCREENER_ANALYTICS_FAILED = "GET_SCREENER_ANALYTICS_REJECTED"
export const GET_SCREENER_ANALYTICS_FULFILLED = "GET_SCREENER_ANALYTICS_FULFILLED"

export const ACTIVATE_SCREENER = "ACTIVATE_SCREENER"
export const ACTIVATE_SCREENER_PENDING = "ACTIVATE_SCREENER_PENDING"
export const ACTIVATE_SCREENER_FAILED = "ACTIVATE_SCREENER_REJECTED"
export const ACTIVATE_SCREENER_FULFILLED = "ACTIVATE_SCREENER_FULFILLED"

export const RESEND_REVIEWER_EMAIL = "RESEND_REVIEWER_EMAIL"
export const UPDATE_REVIEWER = "UPDATE_REVIEWER"
export const SAVE_REVIEWER = "SAVE_REVIEWER"

export const GET_SCREENER = "GET_SCREENER"
export const GET_SCREENER_PENDING = "GET_SCREENER_PENDING"
export const GET_SCREENER_FULFILLED = "GET_SCREENER_FULFILLED"
export const GET_SCREENER_FAILED = "GET_SCREENER_REJECTED"
