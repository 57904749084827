import React from "react"
import PropTypes from "prop-types"
import { Modal, Status } from "@bitcine/cs-theme"
import { POST_DEFAULTS } from "@constants"
import { GLOBALS } from "@src/config"
import ImportsTable from "./imports_table"

const map = {
  dropbox: {
    label: "Dropbox",
    template: (
      <>
        <b>https://www.dropbox.com/s/</b> (or <b>/sh/</b>)
      </>
    )
  },
  google_drive: {
    label: "Google Drive",
    template: <b>https://drive.google.com/</b>
  }
}

class ImportModal extends React.Component {
  constructor(props) {
    super(props)

    this.timer = null

    this.state = {
      link_status: "WAITING",
      link_to_import: "",
      link_to_confirm: {},
      message: "",
      view_imports: false
    }
  }
  viewImports() {
    this.setState({ view_imports: true })
  }
  hideImports() {
    this.setState({ view_imports: false })
  }
  submitLink() {
    //
    this.importLink = `${GLOBALS.API_URL}/import-link` // post
    let payload = {
      type: this.props.importType,
      project_id: this.props.projectID,
      folder_id: this.props.folderID,
      url: this.state.link_to_import,
      file_request_id: this.props.requestID,
      cinerequest_id: this.props.cinerequestID
    }
    this.setState({ ...this.state, link_status: "PENDING", message: "" })
    // post to api
    fetch(this.importLink, {
      ...POST_DEFAULTS,
      body: JSON.stringify(payload)
    })
      .then(res => res.json())
      .then(res =>
        this.setState({
          ...this.state,
          link_status: res.status === "success" ? "CONFIRMATION" : "ERROR",
          link_to_confirm: res.response,
          message: res.message
        })
      )
      .catch(error => this.setState({ ...this.state, link_status: "ERROR", message: res.message }))
    // trigger timeout to poll for pending/queued
  }
  confirmLink() {
    this.setState({ ...this.state, link_status: "PENDING" })
    this.confirmlink = `${GLOBALS.API_URL}/confirm-import/${this.state.link_to_confirm._id}` // post
    fetch(this.confirmlink, { ...POST_DEFAULTS })
      .then(res => res.json())
      .then(res => {
        this.setState({
          ...this.state,
          link_status: "WAITING",
          link_to_confirm: {},
          link_to_import: ""
        })
        if (this.props.updateFileRequest) {
          this.props.updateFileRequest(true)
        }
        if (this.props.showStatus) {
          this.viewImports()
        } else {
          this.props.closeImportModal()
        }
      })
      .catch(error => this.setState({ ...this.state, link_status: "ERROR", error }))
  }
  render() {
    const { link_status, link_to_import, view_imports, message } = this.state
    const { active, closeImportModal, showStatus, importType } = this.props
    const typeLabel = map[importType].label
    const template = map[importType].template
    return (
      <Modal title={`Import from ${typeLabel}`} open={active} onClose={closeImportModal} width={3}>
        {!view_imports ? (
          <>
            <p>
              Use a {typeLabel} shared link to import files and folders. Imports will process in the background. You can
              return here to view the status at any time.
            </p>
            <p>Folder shared links will be imported recursively with the original folder structure retained.</p>
            <p>Enter a link below. The link should begin with {template} and must be publicly viewable.</p>
            <Status pending={link_status === "PENDING"} height={44}>
              {link_status === "CONFIRMATION" && (
                <>
                  <p className='border-top border-gray-5 mt2 pt2'>
                    You are about to import <b className='underline italic'>{this.state.link_to_confirm.origin_name}</b>
                    .
                  </p>
                  <div className='flex justify-end'>
                    <button
                      onClick={() => {
                        this.confirmLink()
                      }}
                      className='cs-button'>
                      Confirm Import
                    </button>
                  </div>
                </>
              )}
              {link_status !== "CONFIRMATION" && (
                <>
                  <input
                    placeholder={`${typeLabel} URL`}
                    value={link_to_import}
                    autoFocus
                    className='cs-input col-12'
                    onChange={e => {
                      this.setState({ link_to_import: e.target.value })
                    }}/>
                  <div className='flex justify-end mt2'>
                    {showStatus && (
                      <button
                        onClick={() => {
                          this.viewImports()
                        }}
                        className='cs-button white mr2'>
                        Show Imports
                      </button>
                    )}
                    <button
                      onClick={() => {
                        this.submitLink()
                      }}
                      className='cs-button'>
                      Import Link
                    </button>
                  </div>
                  {message && (
                    <>
                      <p className='error red mt2'>{message}</p>
                    </>
                  )}
                </>
              )}
            </Status>
          </>
        ) : (
          <ImportsTable {...this.props} hideImports={() => this.hideImports()}/>
        )}
      </Modal>
    )
  }
}

ImportModal.propTypes = {
  active: PropTypes.bool.isRequired,
  closeImportModal: PropTypes.func.isRequired,
  projectID: PropTypes.string,
  folderID: PropTypes.string,
  requestID: PropTypes.string,
  cinerequestID: PropTypes.string,
  showStatus: PropTypes.bool,
  updateFileRequest: PropTypes.func,
  importType: PropTypes.string
}

export default ImportModal
