import {
  GET_FILES_PENDING,
  GET_FILES_FAILED,
  GET_FILES_FULFILLED,
  UPDATE_FILES,
  UPDATE_FILE,
  SET_FILES,
  REPLACE_FILE,
  UPDATE_FILE_PREVIEW_IMAGE,
  RESET_PAGINATION,
  UPDATE_SEARCH,
  DELETE_FILE_FULFILLED,
  MOVE_FILES_TO_PROJECT_FULFILLED
} from "@actions/project/files"

import { INIT_DEFROST_FULFILLED, SET_PROJECT } from "@actions/project/"

import { UPDATE_ORDER, TOGGLE_ORDER_VISIBILITY } from "@actions/orders"

import { ADD_NEW_FOLDER_FULFILLED } from "@actions/project/folders"

import { UPDATE_TAGS } from "@actions/project/tags"

import findIndex from "lodash.findindex"

import update from "immutability-helper"

const initialState = {
  status: "PENDING",
  list: [],
  breadcrumb: [],
  folder: null,
  checked: [],
  pagination: {
    take: 100,
    skip: 0,
    size: 0
  },
  searchTerm: "",
  folder_id: null,
  project_id: null
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PROJECT:
      return initialState

    case GET_FILES_PENDING:
      return {
        ...state,
        status: "PENDING",
        filters: initialState.filters,
        checked: []
      }

    case TOGGLE_ORDER_VISIBILITY:
      return {
        ...state,
        fileOrder: initialState.fileOrder
      }

    case UPDATE_ORDER:
      return {
        ...state,
        fileOrder: {
          ...state.fileOrder,
          [action.key]: state.list.find(({ _id }) => _id === action.value)
        }
      }

    case GET_FILES_FULFILLED:
    case DELETE_FILE_FULFILLED:
      return {
        ...state,
        status: "READY",
        list: action.payload.files,
        breadcrumb: action.payload.breadcrumb,
        folder_id: action.payload.folder_id,
        project_id: action.payload.project_id,
        checked: [],
        pagination: {
          ...state.pagination,
          skip: action.payload.skipped,
          take: state.pagination.take,
          size: action.payload.size
        }
      }

    case MOVE_FILES_TO_PROJECT_FULFILLED:
      return {
        ...state,
        checked: []
      }

    case GET_FILES_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case UPDATE_FILES:
      return {
        ...state,
        [action.key]: action.value
      }

    case SET_FILES:
      return {
        ...state,
        list: action.files,
        breadcrumb: action.breadcrumb
      }

    case UPDATE_FILE:
      return {
        ...state,
        list: state.list.map(file =>
          file._id === action.fileID
            ? {
              ...file,
              [action.key]: action.value
            }
            : file
        )
      }

    case ADD_NEW_FOLDER_FULFILLED:
      return {
        ...state,
        list: [action.payload, ...state.list]
      }

    case UPDATE_TAGS:
      return {
        ...state,
        list: state.list.map(file =>
          findIndex(action.payload.files, checkedFile => checkedFile._id === file._id) !== -1
            ? {
              ...file,
              tags: action.payload.tags
            }
            : file
        )
      }

    case REPLACE_FILE:
      return {
        ...state,
        list: state.list.map(file => {
          return file._id === action.payload.file._id ? action.payload.file : file
        })
      }

    case UPDATE_FILE_PREVIEW_IMAGE:
      const idx = state.list.findIndex(i => i._id === action.payload.id)
      return update(state, {
        list: {
          [idx]: {
            preview_image_status: { $set: action.payload.status },
            preview_image_url: { $set: action.payload.url },
            preview_image_url_sm: { $set: action.payload.url_sm },
            preview_image_url_md: { $set: action.payload.url_md },
            preview_image_url_lg: { $set: action.payload.url_lg },
            preview_image_key: { $set: action.payload.key }
          }
        }
      })

    case RESET_PAGINATION:
      return {
        ...state,
        pagination: {
          take: 25,
          skip: 0
        }
      }

    case UPDATE_SEARCH:
      return {
        ...state,
        searchTerm: action.payload.searchTerm
      }

    case INIT_DEFROST_FULFILLED:
      return {
        ...state,
        list: state.list.map((file, idx) => {
          if (file._id === action.payload.file_id) {
            return {
              ...state.list[idx],
              started_defrosting_at: action.payload.started_defrosting_at
            }
          }

          return file
        })
      }

    default:
      return state
  }
}
