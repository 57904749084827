const filterScreeners = ({ screeners, searchString }) => {
  const items = screeners.items.filter(s => s.is_available)

  return searchString.length
    ? items.filter(screener =>
      screener.name
        .trim()
        .toLowerCase()
        .includes(searchString.trim().toLowerCase())
    )
    : items
}

export default filterScreeners
