export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS"
export const GET_PAYMENT_METHODS_PENDING = "GET_PAYMENT_METHODS_PENDING"
export const GET_PAYMENT_METHODS_FAILED = "GET_PAYMENT_METHODS_REJECTED"
export const GET_PAYMENT_METHODS_FULFILLED = "GET_PAYMENT_METHODS_FULFILLED"

export const GET_INVOICES = "GET_INVOICES"
export const GET_INVOICES_PENDING = "GET_INVOICES_PENDING"
export const GET_INVOICES_FAILED = "GET_INVOICES_REJECTED"
export const GET_INVOICES_FULFILLED = "GET_INVOICES_FULFILLED"

export const UPDATE_NEW_PAYMENT_METHOD = "UPDATE_NEW_PAYMENT_METHOD"

export const ADD_CREDIT_CARD = "ADD_CREDIT_CARD"
export const ADD_CREDIT_CARD_PENDING = "ADD_CREDIT_CARD_PENDING"
export const ADD_CREDIT_CARD_FAILED = "ADD_CREDIT_CARD_REJECTED"
export const ADD_CREDIT_CARD_FULFILLED = "ADD_CREDIT_CARD_FULFILLED"

export const ADD_GIFT_CARD = "ADD_GIFT_CARD"
export const ADD_GIFT_CARD_PENDING = "ADD_GIFT_CARD_PENDING"
export const ADD_GIFT_CARD_FAILED = "ADD_GIFT_CARD_REJECTED"
export const ADD_GIFT_CARD_FULFILLED = "ADD_GIFT_CARD_FULFILLED"

export const REMOVE_CREDIT_CARD = "REMOVE_CREDIT_CARD"
export const REMOVE_CREDIT_CARD_PENDING = "REMOVE_CREDIT_CARD_PENDING"
export const REMOVE_CREDIT_CARD_FAILED = "REMOVE_CREDIT_CARD_REJECTED"
export const REMOVE_CREDIT_CARD_FULFILLED = "REMOVE_CREDIT_CARD_FULFILLED"

export const UPDATE_BILLING = "UPDATE_BILLING"

export const UPDATE_GIFT_CARD = "UPDATE_GIFT_CARD"

export const RETRY_PENDING_PAYMENT = "RETRY_PENDING_PAYMENT"
export const RETRY_PENDING_PAYMENT_PENDING = "RETRY_PENDING_PAYMENT_PENDING"
export const RETRY_PENDING_PAYMENT_FULFILLED = "RETRY_PENDING_PAYMENT_FULFILLED"

export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_PENDING = "GET_ORDERS_PENDING"
export const GET_ORDERS_FAILED = "GET_ORDERS_REJECTED"
export const GET_ORDERS_FULFILLED = "GET_ORDERS_FULFILLED"
