import {
  UPDATE_SUPPORT,
  SEND_SUPPORT_PENDING,
  SEND_SUPPORT_FAILED,
  SEND_SUPPORT_FULFILLED
} from "@actions/account/support"

const initialState = {
  message: "",
  visible: false,
  status: "READY",
  order: null
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_SUPPORT: {
      return {
        ...state,
        [action.key]: action.value
      }
    }

    case SEND_SUPPORT_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case SEND_SUPPORT_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case SEND_SUPPORT_FULFILLED:
      return {
        ...state,
        status: "READY",
        visible: false
      }

    default:
      return state
  }
}
