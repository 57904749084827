import { UPDATE_TAGS, GET_RECENT_TAGS } from "@actions/project/tags"
import { POST_DEFAULTS } from "@constants"
import { get } from "@api/fetch"
import { GLOBALS } from "@src/config"

export const saveTags = (files, tags, organizationId) => async dispatch => {
  try {
    
    //Dispatching immediately so the user sees the responsiveness of tag adding
    dispatch({ type: UPDATE_TAGS, payload: { files, tags } });

    // Perform the fetch operation
    const response = await fetch(
      `${GLOBALS.API_URL}/tags${organizationId ? "?organization_id=" + organizationId : ""}`,
      {
        ...POST_DEFAULTS,
        body: JSON.stringify({
          values: tags.map((tag) => ({
            _id: tag._id,
            name: tag.name,
          })),
          targetFiles: files.map((file) => ({
            _id: file._id,
            type: file.type,
          })),
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Saving tags has failed.");
    }

    const data = await response.json();
    tags = data.tags;

    //dispatching again to update the tag with appropriate api data used for filtering
    dispatch({ type: UPDATE_TAGS, payload: { files, tags } });
  } catch (error) {
    console.error("Error:", error);
  }
}

export const getRecentTags = organizationId => dispatch =>
  dispatch(get(GET_RECENT_TAGS, `tags/recent${organizationId ? "?organization_id=" + organizationId : ""}`))
