import React from "react"
import PropTypes from "prop-types"
import ReactTooltip from "react-tooltip"

const Title = ({ screener }) => (
  <div className={`title truncate small mx1 mb1 white absolute bottom-0 left-0 right-0`}>
    <strong data-tip data-for={screener._id} className='white block truncate pt1'>
      {screener.name}
    </strong>
    <ReactTooltip id={screener._id} place='bottom' effect='solid'>
      {screener.name}
    </ReactTooltip>
  </div>
)

Title.propTypes = {
  screener: PropTypes.object.isRequired
}

export default Title
