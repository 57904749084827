import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { addNewFolder, toggleNewFolder, updateNewFolder } from "@api/project/folders/"
import { Modal, Status } from "@bitcine/cs-theme"

class NewFolder extends React.Component {
  updateNewFolder(key, value) {
    this.props.updateNewFolder(key, value)
  }
  addNewFolder(e) {
    e.preventDefault()
    e.stopPropagation()
    this.props.addNewFolder(this.props.folderID, this.props.newFolder.name)
  }
  render() {
    const { newFolder } = this.props
    return (
      <Modal title='Add New Folder' onClose={() => this.props.toggleNewFolder()} open={newFolder.show}>
        <Status pending={newFolder.status === "PENDING"} error={newFolder.status === "ERROR"}>
          <form>
            <label className='cs-label' htmlFor='New Folder'>
              Folder Name
            </label>
            <input
              autoFocus
              className='cs-input col-12'
              value={newFolder.name}
              onChange={e => this.updateNewFolder("name", e.target.value)}/>
            <div className='mt3 right-align'>
              <button
                className={`cs-button ${newFolder.status === "PENDING" ? "pending" : ""}`}
                disabled={!newFolder.name || newFolder.status === "PENDING"}
                onClick={e => this.addNewFolder(e)}>
                Add Folder
              </button>
            </div>
          </form>
        </Status>
      </Modal>
    )
  }
}

NewFolder.propTypes = {
  newFolder: PropTypes.object.isRequired,
  toggleNewFolder: PropTypes.func.isRequired,
  updateNewFolder: PropTypes.func.isRequired,
  addNewFolder: PropTypes.func.isRequired,
  folderID: PropTypes.string
}

const mapStateToProps = state => ({
  newFolder: state.project.files.folders.newFolder
})

const mapDispatchToProps = dispatch => ({
  addNewFolder: bindActionCreators(addNewFolder, dispatch),
  toggleNewFolder: bindActionCreators(toggleNewFolder, dispatch),
  updateNewFolder: bindActionCreators(updateNewFolder, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewFolder)
