import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { closeProjectFolderModal, saveProjectFolder } from "@api/project_folders"
import { Modal } from "@bitcine/cs-theme"

class CreateProjectFolder extends Component {
  constructor() {
    super()
    this.state = {
      name: ""
    }
  }
  save = e => {
    e.preventDefault()
    e.stopPropagation()
    this.props.saveProjectFolder(this.state.name)
  }
  render() {
    const { isSaving } = this.props
    return (
      <Modal
        testId='projects-add-folder-modal'
        onClose={this.props.closeProjectFolderModal}
        title='Create a Project Folder'
        open>
        <form>
          <div>
            <label className='cs-label' htmlFor='folder_name'>
              Folder name
            </label>
            <input
              data-testid='projects-add-folder-modal-input'
              className='cs-input col-12'
              autoFocus
              id='folder_name'
              value={this.state.name}
              placeholder='Archived Titles'
              onChange={({ target }) => this.setState({ name: target.value })}/>
          </div>
          <div className='flex justify-end mt3'>
            <button
              className='cs-button'
              type='submit'
              data-testid='projects-add-folder-modal-btn'
              disabled={isSaving || !this.state.name}
              onClick={this.save}>
              {isSaving ? "Saving..." : "Create"}
            </button>
          </div>
        </form>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  isSaving: state.projectFolders.isSaving
})

const mapDispatchToProps = dispatch => ({
  closeProjectFolderModal: bindActionCreators(closeProjectFolderModal, dispatch),
  saveProjectFolder: bindActionCreators(saveProjectFolder, dispatch)
})

CreateProjectFolder.propTypes = {
  closeProjectFolderModal: PropTypes.func.isRequired,
  saveProjectFolder: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProjectFolder)
