export const GET_CART_COUNT = "GET_CART_COUNT"
export const GET_CART_COUNT_FULFILLED = "GET_CART_COUNT_FULFILLED"

export const GET_CART = "GET_CART"
export const GET_CART_PENDING = "GET_CART_PENDING"
export const GET_CART_FAILED = "GET_CART_REJECTED"
export const GET_CART_FULFILLED = "GET_CART_FULFILLED"

export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM"

export const DELETE_CART_ITEM = "DELETE_CART_ITEM"
export const DELETE_CART_ITEM_PENDING = "DELETE_CART_ITEM_PENDING"
export const DELETE_CART_ITEM_FULFILLED = "DELETE_CART_ITEM_FULFILLED"
export const DELETE_CART_ITEM_FAILED = "DELETE_CART_ITEM_REJECTED"

export const UPDATE_CART = "UPDATE_CART"

export const TOGGLE_GIFT_CARD = "TOGGLE_GIFT_CARD"
export const TOGGLE_GIFT_CARD_PENDING = "TOGGLE_GIFT_CARD_PENDING"
export const TOGGLE_GIFT_CARD_FULFILLED = "TOGGLE_GIFT_CARD_FULFILLED"
export const TOGGLE_GIFT_CARD_FAILED = "TOGGLE_GIFT_CARD_REJECTED"

export const APPLY_PROMO = "APPLY_PROMO"
export const APPLY_PROMO_PENDING = "APPLY_PROMO_PENDING"
export const APPLY_PROMO_FULFILLED = "APPLY_PROMO_FULFILLED"
export const APPLY_PROMO_FAILED = "APPLY_PROMO_REJECTED"

export const PURCHASE_CART = "PURCHASE_CART"
export const PURCHASE_CART_PENDING = "PURCHASE_CART_PENDING"
export const PURCHASE_CART_FAILED = "PURCHASE_CART_REJECTED"
export const PURCHASE_CART_FULFILLED = "PURCHASE_CART_FULFILLED"

export const GET_SHIPPING_RATES = "GET_SHIPPING_RATES"
export const GET_SHIPPING_RATES_PENDING = "GET_SHIPPING_RATES_PENDING"
export const GET_SHIPPING_RATES_FAILED = "GET_SHIPPING_RATES_REJECTED"
export const GET_SHIPPING_RATES_FULFILLED = "GET_SHIPPING_RATES_FULFILLED"

export const SET_SHIPPING_RATE = "SET_SHIPPING_RATE"
export const SET_SHIPPING_RATE_PENDING = "SET_SHIPPING_RATE_PENDING"
export const SET_SHIPPING_RATE_FULFILLED = "SET_SHIPPING_RATE_FULFILLED"
export const SET_SHIPPING_RATE_FAILED = "SET_SHIPPING_RATE_REJECTED"
