import React from "react"
import ErrorBoundary from "@components/error_boundaries/"
import TourManager from "@components/tour/manager"
import Projects from "./projects"

const ProjectsWrap = props => (
  <ErrorBoundary>
    <Projects {...props}/>

    <TourManager
      steps={[
        {
          key: "new_project"
        }
      ]}/>
  </ErrorBoundary>
)

export default ProjectsWrap
