import React, { Component } from "react"
import { Creatable } from "react-select"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateCreditRoleName, deleteCreditRole, saveCreditRoleName, updateCreditRole } from "@api/credits"
import "./credits.scss"

class CreditCategoryRole extends Component {
  constructor(props) {
    super(props)

    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleRoleChange = this.handleRoleChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleOnBlur = this.handleOnBlur.bind(this)
  }
  handleNameChange(e) {
    this.props.updateCreditRoleName(this.props.categoryIndex, this.props.index, e.target.value)
  }
  handleRoleChange(e) {
    this.props.updateCreditRole(
      this.props.categoryIndex,
      this.props.index,
      this.props.category,
      this.props.person,
      e.value
    )
  }
  handleDelete() {
    this.props.deleteCreditRole(this.props.categoryIndex, this.props.index, this.props.category, this.props.person)
  }
  handleOnBlur(name) {
    this.props.saveCreditRoleName(this.props.category, this.props.person, name)
  }
  render() {
    const container = "rounded flex items-stretch col-12 p2"
    const { person, availableRoles, index, total } = this.props

    return (
      <div
        className={`${container} mb2 role`}
        ref={elm => (this.creditRoleElm = elm)}>
        <div className='flex items-center col-12'>
          <div className='mr2'>
            {index !== 0 && <span 
              className={`flex items-center pointer`}
              onClick={() => {
                this.props.moveCreditRole(index - 1, index)
                this.props.saveCreditRole(index - 1, index, this.props.projectID, this.props.category._id)
              }}>
              <i className='material-icons'>keyboard_arrow_up</i>
            </span>}
            {index + 1 !== total && <span 
              className={`flex items-center pointer`}
              onClick={() => {
                this.props.moveCreditRole(index, index + 1)
                this.props.saveCreditRole(index, index + 1, this.props.projectID, this.props.category._id)
              }}>
              <i className='material-icons'>keyboard_arrow_down</i>
            </span>}
          </div>
          <div className='flex col-12 items-center'>
            <div className='flex-auto px2'>
              <Creatable
                multi={false}
                clearable={false}
                className='col-12'
                placeholder='Select or create a credit role...'
                options={availableRoles}
                value={person.role}
                onChange={this.handleRoleChange}/>
            </div>
            <div className='flex-auto px2'>
              <input
                value={person.name}
                className='cs-input col-12'
                onChange={this.handleNameChange}
                placeholder='John Smith'
                onBlur={() => this.handleOnBlur(person.name)}/>
            </div>
            <div className='pt1 flex items-center'>
              <button
                className='cs-button link tooltip flex items-center justify-center'
                data-tooltip='Remove Credit'
                onClick={this.handleDelete}>
                <i className='material-icons'>delete_forever</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CreditCategoryRole.propTypes = {
  person: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  categoryIndex: PropTypes.number.isRequired,
  moveCreditRole: PropTypes.func.isRequired,
  updateCreditRoleName: PropTypes.func.isRequired,
  deleteCreditRole: PropTypes.func.isRequired,
  saveCreditRole: PropTypes.func.isRequired,
  saveCreditRoleName: PropTypes.func.isRequired,
  updateCreditRole: PropTypes.func.isRequired,
  availableRoles: PropTypes.array.isRequired
}

function mapStateToProps(state) {
  return {
    availableRoles: state.project.credits.availableRoles
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateCreditRoleName: bindActionCreators(updateCreditRoleName, dispatch),
    deleteCreditRole: bindActionCreators(deleteCreditRole, dispatch),
    saveCreditRoleName: bindActionCreators(saveCreditRoleName, dispatch),
    updateCreditRole: bindActionCreators(updateCreditRole, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditCategoryRole)
