import { deleteProjects, closeErrorModal, toggleDeleteProjectModal } from "@api/projects/"
import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Checkbox, Modal } from "@bitcine/cs-theme"
import 'styled-components/macro'

class DeleteProjects extends React.Component {
  constructor() {
    super()

    this.state = {
      text: "",
      video: false,
      links: false
    }
  }
  render() {
    const { toggleDeleteProjectModal, list, checkedProjects, deletingProjects } = this.props
    const trail = checkedProjects.length > 1 ? "s" : ""
    return deletingProjects.projectsErroring.length > 0 ? (
      <Modal open onClose={() => this.props.closeErrorModal()} title='Error Deleting Projects'>
        <div>
          <div className='border-gray-5 border-bottom red flex items-center mb2 pb2'>
            <span className='material-icons mr2'>error</span>
            <h4 className='flex-auto mb0'>Warning</h4>
          </div>
          <div>
            <p>The below projects could not be deleted because they contain orders that have not been completed.</p>
            {deletingProjects.projectsErroring.map((project, i) => (
              <div key={i}>
                {i + 1}. {project}
              </div>
            ))}
          </div>
          <div className='right-align mt3'>
            <button
              className='cs-button'
              onClick={() => {
                this.props.closeErrorModal()
              }}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    ) : (
      <Modal open onClose={() => toggleDeleteProjectModal()} title={`Delete Project${trail}`}>
        <div>
          <div
            className='border-gray-5 border-bottom pb2'
            css={`
              white-space: pre-line;
              overflow-wrap: break-word;
            `}>
            <div className='red flex items-center pb1'>
              <span className='material-icons mr1'>error</span>
              <strong>
                You are about to delete {checkedProjects.length} project
                {trail}:{" "}
              </strong>
            </div>
            {list
              .filter(project => checkedProjects.includes(project._id))
              .map((project, i) => (
                <div key={i} className='red'>
                  {project.title}
                </div>
              ))}
          </div>
          <div className='py2'>
            We strongly recommend you download a copy of each file before deleting these projects.{" "}
            <strong>This cannot be undone.</strong> Please verify you wish to delete this project from your CineSend
            account.
          </div>
          <label className='cs-label flex items-center py1'>
            <Checkbox checked={this.state.links} onChange={() => this.setState({ links: !this.state.links })}/>
            <span className='ml1'>All shared files links will be expired</span>
          </label>
          <label className='cs-label flex items-center py1'>
            <Checkbox checked={this.state.video} onChange={() => this.setState({ video: !this.state.video })}/>
            <span className='ml1'>All screeners, reviewers and analytics will be removed</span>
          </label>
          <label htmlFor='_delete' className='cs-label block mt2'>
            To delete this project please type &#34;Delete&#34; below
          </label>
          <input
            type='text'
            id='_delete'
            style={{ minWidth: "18rem" }}
            className='cs-input search mb2'
            value={this.state.text}
            onChange={e => this.setState({ text: e.target.value })}/>
          <div className='flex justify-end items-center mt3'>
            <button
              disabled={
                deletingProjects.status === "PENDING" ||
                this.state.text.toLowerCase() !== "delete" ||
                !this.state.links ||
                !this.state.video
              }
              onClick={() => {
                this.props.deleteProjects(checkedProjects)
              }}
              className={`cs-button ${deletingProjects.status === "PENDING" ? "pending" : ""}`}>
              Delete Project
              {trail}
            </button>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  ...state.projects,
  checkedProjects: state.projects.checkedProjects,
  deletingProjects: state.projects.deletingProjects
})

const mapDispatchToProps = dispatch => ({
  deleteProjects: bindActionCreators(deleteProjects, dispatch),
  closeErrorModal: bindActionCreators(closeErrorModal, dispatch),
  toggleDeleteProjectModal: bindActionCreators(toggleDeleteProjectModal, dispatch)
})

DeleteProjects.propTypes = {
  deleteProjects: PropTypes.func.isRequired,
  closeErrorModal: PropTypes.func.isRequired,
  deletingProjects: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  toggleDeleteProjectModal: PropTypes.func.isRequired,
  checkedProjects: PropTypes.array.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProjects)
