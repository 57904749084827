export const DETAILS_NAV_ITEMS = [
  {
    text: "General",
    to: "general"
  },
  {
    text: "Credits",
    to: "credits"
  },
  {
    text: "Activity",
    to: "history"
  },
  {
    text: "Screener Attachments",
    to: "screener-attachments"
  }
]

export const OPTS = [
  { label: "All", value: "" },
  { label: "File", value: "File" },
  { label: "Order", value: "Order" },
  { label: "Project", value: "Project" },
  { label: "Screener", value: "Screener" }
]

export const GENERAL_INPUTS = [
  {
    type: "text",
    className: "col-6 mb2",
    key: "title",
    label: "Project Title"
  },
  {
    type: "text",
    className: "col-3 pl2 mb2",
    key: "release_year",
    label: "Release Year"
  },
  {
    type: "multi",
    className: "col-12 mb3",
    key: "genres",
    label: "Genre(s)",
    options: "genres"
  },
  {
    type: "textarea",
    className: "col-12",
    key: "logline",
    label: "Logline"
  }
]
