import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

class Title extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.title || ""
    }
  }
  handleSaveName = () => {
    const { title } = this.state
    if (title.length && title !== this.props.title) {
      this.props.save(title)
    }
    this.props.close()
  }
  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.handleSaveName()
    }
  }
  render() {
    return (
      <div className='pt1'>
        {this.props.editing ? (
          <input
            value={this.state.title}
            onChange={e => this.setState({ title: e.target.value })}
            onBlur={() => this.handleSaveName()}
            onKeyPress={e => this.handleKeyPress(e)}
            className='cs-input no-min-width col-12 small-input'/>
        ) : (
          <Link to={`/screener-rooms/${this.props._id}`}>
            <strong>{this.state.title}</strong>
          </Link>
        )}
      </div>
    )
  }
}

Title.propTypes = {
  title: PropTypes.string,
  save: PropTypes.func.isRequired,
  _id: PropTypes.string.isRequired,
  editing: PropTypes.bool.inRequired,
  close: PropTypes.func.isRequired
}

export default Title
