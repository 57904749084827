import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { prevStep, nextStep, updateOrder } from "@api/project/orders"
import { Radio } from "@bitcine/cs-theme"
import "../styles.scss"

const turnoverOptions = [
  {
    title: "Regular turnaround (3 days)",
    base: "encoding_base_price",
    per_minute: "encoding_per_minute",
    rush_days: 3
  },
  {
    title: "Two-day rush fee",
    base: "encoding_base_two_day_rush",
    per_minute: "encoding_per_minute_two_day_rush",
    rush_days: 2
  },
  {
    title: "One-day rush fee",
    base: "encoding_base_one_day_rush",
    per_minute: "encoding_per_minute_one_day_rush",
    rush_days: 1
  }
]

const TurnaroundDays = ({ prevStep, nextStep, order, items, updateOrder }) => (
  <div className='col-12'>
    {!items ? (
      <div className={`orderInnerContainer center py2`}>
        <div className='spinner'/>
      </div>
    ) : (
      <div className={"orderInnerContainer"}>
        Select your desired turnaround time.
        <br/>
        <strong className='small'>* Note all options exclude shipping days.</strong>
        {turnoverOptions.map((turnaround, i) => (
          <div key={i} className='mb1 flex items-center'>
            <Radio
              id={`turnaround_${i}`}
              checked={order.dcp_base === turnaround.base}
              onChange={() => {
                updateOrder("dcp_base", turnaround.base)
                updateOrder("dcp_per_minute", turnaround.per_minute)
                updateOrder("rush_days", turnaround.rush_days)
              }}/>
            <label className='cs-label flex-auto' htmlFor={`turnaround${i}`}>
              <strong className='px1'>{turnaround.title}</strong>
            </label>
            <strong>
              {items[turnaround.base].price_formatted} + {items[turnaround.per_minute].price_formatted}
              /min
            </strong>
          </div>
        ))}
      </div>
    )}
    <div className='flex justify-between col-12 pt3'>
      <button className='cs-button white' onClick={prevStep}>
        Back
      </button>
      <button className='cs-button' disabled={!order.dcp_base} onClick={nextStep}>
        Next
      </button>
    </div>
  </div>
)

TurnaroundDays.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  items: PropTypes.object,
  updateOrder: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const order = state.orders.active_order
  return {
    order,
    items: state.utils.dcp_items
  }
}

const mapDispatchToProps = dispatch => ({
  prevStep: bindActionCreators(prevStep, dispatch),
  nextStep: bindActionCreators(nextStep, dispatch),
  updateOrder: bindActionCreators(updateOrder, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TurnaroundDays)
