const recursiveBuilder = (entry, files) => {
  files = files || []

  if (entry.isDirectory) {
    const dir = entry.createReader()

    dir.readEntries(entries => {
      entries.forEach(childEntry => {
        recursiveBuilder(childEntry, files)
      })
    })
  } else {
    entry.file(file => {
      file.fullPath = entry.fullPath
      files.push(file)
    })
  }

  return files
}

export default recursiveBuilder
