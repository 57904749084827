import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { prevStep, nextStep, updateOrder } from "@api/project/orders"
import "../styles.scss"
import Drives from "./drives"

const DCPConfirmation = ({ prevStep, nextStep, order, items, lengthInMins, subtotal, updateOrder }) => (
  <div className='col-12'>
    <div className={"orderInnerContainer"}>
      <div className='bg-gray-6 rounded mb2 p2'>
        <h4>{order.title}</h4>
        <div className='flex items-center mb1'>
          <div className='flex-auto pr2'>Encoding Base</div>
          <strong>{items[order.dcp_base].price_formatted}</strong>
        </div>
        <div className='flex items-center mb1'>
          <div className='flex-auto pr2'>
            Encoding Per Minute
            <small style={{ marginTop: "-.25rem" }} className='block'>
              {items[order.dcp_per_minute].price_formatted} per minute
            </small>
          </div>
          <strong>
            {items[order.dcp_per_minute].currency_symbol}
            {(items[order.dcp_per_minute].price * lengthInMins).toFixed(2)}
          </strong>
        </div>
        {order.qa_report && (
          <div className='flex items-center mb1'>
            <div className='flex-auto pr2'>QA Report</div>
            <strong>{items.qa_report.price_formatted}</strong>
          </div>
        )}
        {order.encrypt && (
          <div>
            <div className='flex items-center mb1'>
              <div className='flex-auto pr2'>Encryption Base</div>
              <strong>{items.encrypted_dcp_base.price_formatted}</strong>
            </div>
            <div className='flex items-center mb1'>
              <div className='flex-auto pr2'>
                Encryption Per Minute
                <small style={{ marginTop: "-.25rem" }} className='block'>
                  {items.encrypted_dcp_per_minute.price_formatted} per minute
                </small>
              </div>
              <strong>
                {items.encrypted_dcp_per_minute.currency_symbol}
                {(items.encrypted_dcp_per_minute.price * lengthInMins).toFixed(2)}
              </strong>
            </div>
          </div>
        )}
        {order.crop !== undefined && (
          <div className='flex items-center mb1'>
            <div className='flex-auto pr2'>
              Cropping
              <small style={{ marginTop: "-.25rem" }} className='block'>
                {order.crop ? "Zoom & crop to fill frame" : "Pillarbox"}
              </small>
            </div>
            <strong>{order.crop ? items.cropping.price_formatted : `${items.cropping.currency_symbol}0.00`}</strong>
          </div>
        )}
        {order.closed_caption_id && (
          <div className='flex items-center mb1'>
            <div className='flex-auto pr2'>Include Closed Captioning</div>
            <strong>{items.include_captioning.price_formatted}</strong>
          </div>
        )}
        {order.add_captioning && (
          <div className='flex items-center mb1'>
            <div className='flex-auto pr2'>Add Closed Captioning</div>
            <strong>
              {items.include_captioning.currency_symbol}
              {(
                items.include_captioning.price +
                (items.captions_base.price + items.captions_per_minute_5_days.price * lengthInMins)
              ).toFixed(2)}
            </strong>
          </div>
        )}
        {order.subtitle_handling && (
          <div className='flex items-center mb1'>
            <div className='flex-auto pr2'>Include Subtitles</div>
            <strong>{items[order.subtitle_handling].price_formatted}</strong>
          </div>
        )}
      </div>
      <div className='flex items-center'>
        <strong className='flex-auto'>Subtotal</strong>
        <h4 className='mb0'>
          {items.encoding_base_price.currency_symbol}
          {subtotal.toFixed(0)}
        </h4>
      </div>
      <Drives/>
    </div>
    <div className='flex justify-between pt3 col-12'>
      <button className='cs-button white' onClick={prevStep}>
        Back
      </button>
      <button className='cs-button' onClick={nextStep}>
        Add to Cart
      </button>
    </div>
  </div>
)

DCPConfirmation.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  items: PropTypes.object.isRequired,
  lengthInMins: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
  updateOrder: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const order = state.orders.active_order
  const file = order.source_file
  const lengthInMins = file.length_in_minutes
  const frameRate = file.frame_rate
  const items = state.utils.dcp_items

  let subtotal = 0
  subtotal += items[order.dcp_base].price
  subtotal += items[order.dcp_per_minute].price * lengthInMins

  if (order.crop) subtotal += items.cropping.price
  if (order.encrypt) subtotal += items.encrypted_dcp_base.price + items.encrypted_dcp_per_minute.price * lengthInMins
  if (order.convert) subtotal += items[`${frameRate === "48" ? "convert_48_to_24_fps" : "convert_25_to_24_fps"}`].price
  if (order.qa_report) subtotal += items.qa_report.price
  if (order.add_captioning)
    subtotal +=
      items.captions_base.price + items.captions_per_minute_5_days.price * lengthInMins + items.include_captioning.price
  if (order.include_captioning) subtotal += items.include_captioning.price
  if (order.subtitle_handling) {
    subtotal += items[order.subtitle_handling].price
  }

  return {
    order,
    items,
    lengthInMins,
    subtotal
  }
}

const mapDispatchToProps = dispatch => ({
  prevStep: bindActionCreators(prevStep, dispatch),
  nextStep: bindActionCreators(nextStep, dispatch),
  updateOrder: bindActionCreators(updateOrder, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DCPConfirmation)
