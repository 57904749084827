import { get, put, post, del } from "@api/fetch"
import {
  GET_VIDEO,
  GET_LANGUAGES,
  UPDATE_VIDEO,
  GET_GENRES,
  GET_CUSTOM_FIELDS,
  SET_CUSTOM_FIELD_VALUE,
  INIT_TRAILER,
  INIT_DCP_REQUEST,
  UPDATE_TRAILER,
  GET_SOURCE_FILES,
  SUBMIT_SOURCE_FILE,
  GET_TIMED_TEXTS,
  UPLOAD_TIMED_TEXT,
  UPDATE_TIMED_TEXT,
  DELETE_TIMED_TEXT
} from "@actions/ondemand_videos/"
import { addGlobalMessage } from "@api/utils"

export const getVideo = videoID => dispatch =>
  dispatch(get(GET_VIDEO.REQUEST, `ondemand/video-requests/${videoID}`))

export const getLanguages = () => dispatch => dispatch(get(GET_LANGUAGES.REQUEST, `utilities/languages`))

export const updateVideo = (videoID, values, callback = null) => (dispatch, getState) =>
  dispatch(
    put(
      UPDATE_VIDEO.REQUEST,
      `ondemand/video-requests/${videoID}`,
      {
        ...values,
        customFieldValues: getState().ondemand_videos.customFieldValues
      },
      res => {
        dispatch(addGlobalMessage("Saved!", "success"))
        if (typeof callback === "function") {
          callback(res)
        }
      }
    )
  )

export const updateTrailer = (videoID, values) => dispatch =>
  dispatch(
    put(UPDATE_TRAILER.REQUEST, `ondemand/video-requests/${videoID}/trailer`, values, () => {
      dispatch(addGlobalMessage("Saved!", "success"))
    })
  )

export const getGenres = videoID => dispatch =>
  dispatch(get(GET_GENRES.REQUEST, `ondemand/video-requests/${videoID}/genres`))
export const getCustomFields = videoID => dispatch =>
  dispatch(get(GET_CUSTOM_FIELDS.REQUEST, `ondemand/video-requests/${videoID}/custom-fields`))

export const setCustomFieldValue = (fieldID, value) => dispatch =>
  dispatch({
    type: SET_CUSTOM_FIELD_VALUE,
    payload: { fieldID, value }
  })

export const initTrailer = videoID => dispatch =>
  dispatch(
    post(INIT_TRAILER.REQUEST, `ondemand/video-requests/${videoID}/trailer`, {}, () => {
      dispatch(addGlobalMessage("Trailer created! You can now upload the video.", "success"))
    })
  )

export const initDCPRequest = videoID => dispatch =>
  dispatch(
    post(INIT_DCP_REQUEST.REQUEST, `ondemand/video-requests/${videoID}/dcp-request`, {}, () => {
      dispatch(addGlobalMessage("You can now upload your DCP.", "success"))
    })
  )

export const getSourceFiles = () => dispatch => dispatch(get(GET_SOURCE_FILES.REQUEST, `projects/source-files`))

export const submitSourceFile = (videoID, sourceFileID) => dispatch =>
  dispatch(
    post(SUBMIT_SOURCE_FILE.REQUEST, `ondemand/video-requests/${videoID}/submit/${sourceFileID}`, {}, () => {
      dispatch(addGlobalMessage("Source file imported!", "success"))
    })
  )

export const getTimedText = videoID => dispatch =>
  dispatch(get(GET_TIMED_TEXTS.REQUEST, `ondemand/video-requests/${videoID}/timed-texts`))

export const updateTimedText = (videoID, timedTextID, data) => dispatch =>
  dispatch(
    put(UPDATE_TIMED_TEXT.REQUEST, `ondemand/video-requests/${videoID}/timed-texts/${timedTextID}`, data, res =>
      dispatch(addGlobalMessage("Timed text updated!", "success"))
    )
  )

export const deleteTimedText = (videoID, timedTextID) => dispatch =>
  dispatch(
    del(DELETE_TIMED_TEXT.REQUEST, `ondemand/video-requests/${videoID}/timed-texts/${timedTextID}`, () =>
      dispatch(addGlobalMessage("Timed text removed!", "success"))
    )
  )

export const uploadTimedText = (videoID, file, language) => dispatch => {
  dispatch(
    post(
      UPLOAD_TIMED_TEXT.REQUEST,
      `ondemand/video-requests/${videoID}/timed-texts`,
      {
        fileName: file.name,
        language
      },
      results => {
        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest()
          xhr.open("PUT", results.signedUrl)
          xhr.onreadystatechange = function() {
            if (xhr.readyState !== 4) return
            if (xhr.status === 200) {
              dispatch(updateTimedText(videoID, results.timedTextID, { status: "Uploaded" }))
            } else {
              dispatch(addGlobalMessage("An error occurred uploading your file. Please try again."))
            }
          }
          xhr.send(file)
        })
      }
    )
  )
}
