import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Status } from "@bitcine/cs-theme"
import TourManager from "@components/tour/manager"
import { getScreener } from "@api/project/files/screeners"
import Header from "./header"
import Screener from "./body"
import Reviewers from "./reviewers"
import "./styles.scss"

class ScreenerLoader extends React.Component {
  componentDidMount() {
    this.props.getScreener(this.props.match.params.screenerID)
  }
  render() {
    const { screener } = this.props
    return (
      <div className={`fixed top-0 left-0 right-0 bottom-0 pt4 wrap bg-white overflow-auto`}>
        <Status pending={screener.status === "PENDING"} error={screener.status === "ERROR"}>
          <Header params={this.props.match.params}/>
          <Screener params={this.props.match.params}/>
          {screener.is_available && (
            <div className='p3'>
              <Reviewers screenerID={this.props.match.params.screenerID}/>
            </div>
          )}
        </Status>

        <TourManager
          steps={[
            {
              key: "send_screener_btn"
            },
            {
              key: "screeners_tab_analytics"
            }
          ]}/>
      </div>
    )
  }
}

ScreenerLoader.propTypes = {
  match: PropTypes.object.isRequired,
  getScreener: PropTypes.func.isRequired,
  screener: PropTypes.object
}

const mapStateToProps = (state, context) => ({
  screener: state.project.files.screener
})

const mapDispatchToProps = dispatch => ({
  getScreener: bindActionCreators(getScreener, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ScreenerLoader)
