import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import 'styled-components/macro'

const Back = ({ to, tooltip }) => (
  <Link className='mr1 flex items-center' to={to}>
    <button
      data-tooltip={tooltip}
      css={`
        border-radius: 50%;
        width: 30px;
        height: 30px;
        &:hover {
          background: #ededed;
          &::after {
            transform: translateX(-25px) !important;
          }
        }
      `}
      className='cs-button link tooltip material-icons flex items-center justify-center'>
      keyboard_arrow_left
    </button>
  </Link>
)

Back.propTypes = {
  to: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired
}

export default Back
