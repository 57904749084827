import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { closeGlobalAuth, setAuthUser } from "@api/account/auth"
import { POST_DEFAULTS } from "@constants"
import { Modal, Status } from "@bitcine/cs-theme"

class GlobalAuthModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      pending: false
    }
  }
  login(e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ pending: true, error: false })
    const payload = {
      email: this.state.email.toLowerCase(),
      password: this.state.password,
      promoCode: ""
    }
    fetch("${GLOBALS.API_URL}/auth/login", {
      ...POST_DEFAULTS,
      body: JSON.stringify(payload)
    })
      .then(res => {
        if (res.ok) return res.json()
      })
      .then(res => {
        if (res.user) {
          if (this.props.callback && typeof this.props.callback === "function") {
            this.props.callback()
          }
          this.props.closeGlobalAuth()
          this.props.setAuthUser(res.user)
        }
      })
      .catch(err => {
        this.setState({ error: true, pending: false })
      })
  }
  render() {
    return (
      <Modal
        open={this.props.show}
        title='Login'
        testId='global-auth-modal'
        onClose={this.props.closeGlobalAuth}
        isDismissable={this.props.allow_dismiss}>
        <Status pending={this.state.pending}>
          <form className='mx2'>
            <label className='cs-label' htmlFor='email'>
              Email
            </label>
            <input
              autoFocus
              value={this.state.email}
              data-testid='global-auth-modal-email-input'
              className='cs-input col-12 block mb1'
              onChange={e => this.setState({ email: e.target.value })}/>
            <label className='cs-label' htmlFor='password'>
              Password
            </label>
            <input
              value={this.state.password}
              className='cs-input col-12 block mb2'
              data-testid='global-auth-modal-password-input'
              type='password'
              onChange={e => this.setState({ password: e.target.value })}/>
            {this.state.error && <small className='block red'>Incorrect credentials</small>}
            <div className='mt3 right-align'>
              <button
                data-testid='global-auth-modal-submit-btn'
                className='cs-button'
                disabled={!this.state.email || !this.state.password}
                onClick={e => this.login(e)}>
                Login
              </button>
            </div>
          </form>
        </Status>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  ...state.utils.global_auth
})

const mapDispatchToProps = dispatch => ({
  closeGlobalAuth: bindActionCreators(closeGlobalAuth, dispatch),
  setAuthUser: bindActionCreators(setAuthUser, dispatch)
})

GlobalAuthModal.propTypes = {
  closeGlobalAuth: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  callback: PropTypes.func,
  allow_dismiss: PropTypes.bool.isRequired,
  setAuthUser: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalAuthModal)
