import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { GLOBALS } from "@src/config"
import { updateNewReviewers } from "@api/preview/send"
import { Checkbox } from "@bitcine/cs-theme"

const Password = ({ password, updateNewReviewers, allowed, stateKey }) => {
  const [passwordVisible, togglePasswordVisibility] = useState(false)
  useEffect(() => {
    if (!passwordVisible) {
      updateNewReviewers(stateKey, "password", "")
    }
  }, [passwordVisible])
  return (
    <div className='py2 border-bottom border-gray-5'>
      <div className='flex items-center'>
        <div className='col-8 flex items-center'>
          <Checkbox
            checked={passwordVisible}
            disabled={!allowed}
            onChange={e => togglePasswordVisibility(!passwordVisible)}/>
          <div className='pl1'>
            <h4>Password Protect Link</h4>
            <p className='mb0'>Force users to enter a password before playback can be started.</p>
          </div>
        </div>
        <div className='pl2 col-4'>
          {!allowed && (
            <div className='right-align'>
              <a href={GLOBALS.ACCOUNTS_URL + '/settings/billing'} target='_blank' rel='noopener noreferrer'>
                <button className='cs-button white small'>Upgrade Your Account</button>
              </a>
              <small className='block muted mt1'>All paid plans include password protection</small>
            </div>
          )}
          {passwordVisible && (
            <input
              id='password'
              value={password}
              autoFocus
              className='cs-input col-12 block'
              placeholder='Enter a password'
              onChange={e => updateNewReviewers(stateKey, "password", e.target.value)}/>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  allowed: state.user.auth.can_password_protect_screener_groups,
  password: state.preview.send[ownProps.stateKey].password
})

const mapDispatchToProps = dispatch => ({
  updateNewReviewers: bindActionCreators(updateNewReviewers, dispatch)
})

Password.propTypes = {
  updateNewReviewers: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  allowed: PropTypes.bool.isRequired,
  stateKey: PropTypes.oneOf(["email", "link"]).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Password)
