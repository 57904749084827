import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Modal, Status } from "@bitcine/cs-theme"
import { bindActionCreators } from "redux"
import { addNewFolder } from "@api/project/folders/"
import { toggleModal, updateRequest, saveRequest } from "@api/project/requests"
import SelectFolder from "./select"

class EditRequest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show_folders: false,
      selected_folder_name: props.request.folder ? props.request.folder.name : "All Files"
    }
  }
  close = () => {
    this.props.toggleModal("show_edit")
  }
  update = (key, value) => {
    this.props.updateRequest(this.props.request._id, key, value)
  }
  save = (key, value) => {
    this.props.saveRequest(this.props.request._id, key, value)
  }
  selectFolder(folder) {
    this.save("destination_folder_id", folder ? folder._id : null)
    this.setState({
      show_folders: false,
      selected_folder_name: folder ? folder.name : "All Files"
    })
  }
  createFolder(folderID, name) {
    this.props.addNewFolder(folderID, name, folder => {
      this.selectFolder(folder)
    })
  }
  render() {
    return !this.state.show_folders ? (
      <Modal open title='Edit File Request' onClose={this.close}>
        <Status pending={this.props.status === "PENDING"} error={this.props.status === "ERROR"}>
          <div>
            <div className='border-bottom border-gray-5 pb3'>
              <h4 className='mb1'>What are you requesting?</h4>
              <label className='cs-label'>Name of request</label>
              <input
                onChange={e => this.update("title", e.target.value)}
                value={this.props.request.title}
                type='text'
                placeholder='File name, image name...'
                onBlur={e => {
                  if (e.target.value.length) {
                    this.save("title", e.target.value)
                  } else {
                    alert("Name cannot be empty.")
                  }
                }}
                className='cs-input col-12'/>
            </div>

            <div className='py3 '>
              <h4 className='mb1'>Where is this file going?</h4>
              <label className='cs-label'>Destination folder</label>
              <div className='col-12 flex'>
                <div
                  className='flex-auto flex items-center rounded border border-gray-5 pl1'
                  style={{ height: "34px" }}>
                  <i className='material-icons'>folder</i>
                  <strong className='ml1'>{this.state.selected_folder_name}</strong>
                </div>
                <button
                  className='cs-button outline capitalize small ml1'
                  onClick={() => this.setState({ show_folders: true })}>
                  Change folder
                </button>
              </div>
            </div>

            <div className='col-12'>
              <button
                className='cs-button white'
                onClick={() => {
                  this.update("status", this.props.request.status === "open" ? "closed" : "open")
                  this.save("status", this.props.request.status === "open" ? "closed" : "open")
                }}>
                {this.props.request.status === "open" ? "Close" : "Re-open"} Request
              </button>
            </div>
          </div>
        </Status>
      </Modal>
    ) : (
      <Modal open title='Choose a Folder for Submissions' onClose={() => this.setState({ show_folders: false })}>
        <SelectFolder
          select={folder => this.selectFolder(folder)}
          create={(folderID, name) => this.createFolder(folderID, name)}/>
      </Modal>
    )
  }
}

EditRequest.propTypes = {
  status: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  updateRequest: PropTypes.func.isRequired,
  saveRequest: PropTypes.func.isRequired,
  addNewFolder: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  request: state.project.requests.items.find(item => item._id === state.project.requests.active_request_id),
  status: state.project.requests.edit_status
})

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  updateRequest: bindActionCreators(updateRequest, dispatch),
  saveRequest: bindActionCreators(saveRequest, dispatch),
  addNewFolder: bindActionCreators(addNewFolder, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRequest)
