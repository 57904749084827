import { UPDATE_FILES_FILTER, RESET_FILES_FILTER, UPDATE_ALL_FILES_FILTER } from "@actions/project/filter/"

/**
 * Update Filter
 *
 * @param {String} key
 * @param {String} value
 *
 * @returns {Function} dispatch
 */

export const updateFilter = (key, value) => dispatch => {
  const cachedFilters = localStorage.getItem("file_filters") ? JSON.parse(localStorage.getItem("file_filters")) : null

  const filters = cachedFilters || {
    search: "",
    direction: "ASC",
    key: "",
    types: [],
    tags: []
  }

  filters[key] = value

  localStorage.setItem("file_filters", JSON.stringify(filters))

  return dispatch({ type: UPDATE_FILES_FILTER, key, value })
}

export const updateAllFilters = filters => dispatch => dispatch({ type: UPDATE_ALL_FILES_FILTER, filters })

/**
 * Reset Filter
 *
 * @returns {Function} dispatch
 */

export const resetFilter = () => dispatch => dispatch({ type: RESET_FILES_FILTER })
