import { TOGGLE_MODAL, GET_SHARED_SCREENER_STATS, GET_SHARED_SCREENER_SESSIONS } from "@actions/shared_screeners"

import { get } from "@api/fetch"

export const toggleModal = (key, sharedScreener) => dispatch => {
  dispatch({
    type: TOGGLE_MODAL,
    payload: { key, sharedScreener }
  })
}

export const getSharedScreenerStats = (screenerGroupID, sharedScreenerID) => dispatch => {
  dispatch(
    get(
      GET_SHARED_SCREENER_STATS.REQUEST,
      `screener-groups/${screenerGroupID}/reviewer-analytics/${sharedScreenerID}`
    )
  )
}

export const getSharedScreenerSessions = sharedScreenerID => dispatch => {
  dispatch(get(GET_SHARED_SCREENER_SESSIONS.REQUEST, `shared-screeners/${sharedScreenerID}/sessions`))
}
