import React, { useState } from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { getDownloadLinkWithPassword } from "@api/download_link/"

import { withRouter } from "react-router-dom"

const Password = ({ match, getDownloadLinkWithPassword, download }) => {
  const [password, setPassword] = useState("")
  return (
    <div className='col-4 mx-auto py3 mt4'>
      <label className='cs-label' htmlFor='password'>
        Password Protected
      </label>
      <input
        type='password'
        id='password'
        value={password}
        onChange={e => setPassword(e.target.value)}
        className='cs-input col-12 mb2'
        placeholder='Enter the password'/>
      {download.errorMsg.length > 0 && <div className='red py2'>{download.errorMsg}</div>}
      <button
        className='cs-button'
        disabled={password.length === 0}
        onClick={() => {
          getDownloadLinkWithPassword(match.params.transferID, password)
        }}>
        Submit
      </button>
    </div>
  )
}

Password.propTypes = {
  match: PropTypes.object.isRequired,
  getDownloadLinkWithPassword: PropTypes.func.isRequired,
  download: PropTypes.object.isRequired
}

const mapStateToProps = ({ download_link }) => ({
  download: download_link
})

const mapDispatchToProps = dispatch => ({
  getDownloadLinkWithPassword: bindActionCreators(getDownloadLinkWithPassword, dispatch)
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Password)
)
