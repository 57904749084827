import React from "react"
import PropTypes from "prop-types"
import DateTime from "./datetime"

const Created = ({ sharedScreener }) => (
  <div className='caption'>
    <DateTime date={sharedScreener.created_at}/>
    <span className='block light'>By: {sharedScreener.created_by}</span>
  </div>
)

Created.propTypes = {
  sharedScreener: PropTypes.object.isRequired
}

export default Created
