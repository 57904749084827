import React from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"

const SpinnerWrap = styled("div")`
  color: #92999f;
  font-size: 12px;
  .spinner {
    border-right-color: #92999f;
    width: 2.8em;
    height: 2.8em;
  }
`

const MediaPreparing = ({ text }) => (
  <SpinnerWrap className='center'>
    <div className='spinner'/>
    <div className='bold'>{text}</div>
  </SpinnerWrap>
)

MediaPreparing.defaultProps = {
  text: "Media Preparing"
}

MediaPreparing.propTypes = {
  text: PropTypes.string
}

export default MediaPreparing
