import { GET_CONNECTIONS_PENDING, GET_CONNECTIONS_FULFILLED, REMOVE_CONNECTION } from "@actions/account/connections"

const initialState = {
  connections: [],
  status: ""
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CONNECTIONS_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_CONNECTIONS_FULFILLED:
      return {
        ...state,
        status: "SUCCESS",
        connections: action.payload
      }

    case REMOVE_CONNECTION:
      return {
        ...state,
        connections: state.connections.filter(connection => connection._id !== action.payload.connection._id)
      }

    default:
      return state
  }
}
