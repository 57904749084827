import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Checkbox, Modal } from "@bitcine/cs-theme"
import { toggleScreenerDeleteModal, deleteScreenerRoom } from "@api/screener_rooms/"

class Delete extends React.Component {
  constructor() {
    super()

    this.state = {
      check1: false,
      check2: false,
      check3: false,
      confirm: ""
    }
  }
  render() {
    const { toggleScreenerDeleteModal, screenerRoom, deleteScreenerRoom, orgId, isDeletingRoom } = this.props

    const { check1, check2, check3, confirm } = this.state

    const needsToMeetDeleteConditions = !check1 || !check2 || !check3 || confirm.trim().toLowerCase() !== "delete"

    return (
      <Modal open={true} title='Delete Screener Room' onClose={toggleScreenerDeleteModal} clickOutsideCloses={true}>
        <div>
          <div className='pt2 pb3 mb3 red flex items-center border-bottom border-gray-5' style={{ fontSize: "1.25em" }}>
            <i className='material-icons'>error</i>
            <span className='ml2'>
              Delete screener room: <strong>{screenerRoom.name}</strong>
            </span>
          </div>

          <div className='mb3'>
            <p className='mb2'>
              <strong>This cannot be undone</strong>. Please verify you wish to delete this screener room from your
              project and acknowledge the changes below.
            </p>

            <label className='cs-label flex items-center mb2'>
              <Checkbox checked={check1} onChange={() => this.setState({ check1: !check1 })}/>
              <div className='flex-auto ml1'>All shared screener room links will be inaccessible</div>
            </label>
            <div/>
            <label className='cs-label flex items-center mb2'>
              <Checkbox checked={check2} onChange={() => this.setState({ check2: !check2 })}/>
              <div className='flex-auto ml1'>Screener room will be deleted</div>
            </label>

            <label className='cs-label flex items-center mb2'>
              <Checkbox checked={check3} onChange={() => this.setState({ check3: !check3 })}/>
              <div className='flex-auto ml1'>Team members will lose access</div>
            </label>
          </div>

          <div className='mb4'>
            <label className='cs-label mb1' htmlFor='delete_confirm'>
              To delete this file please type &quot;Delete&quot; below
            </label>
            <input
              type='text'
              id='delete_confirm'
              className='cs-input col-8'
              value={confirm}
              onChange={e => this.setState({ confirm: e.target.value })}/>
          </div>

          <div className='right-align'>
            <button className='cs-button white small' onClick={toggleScreenerDeleteModal} disabled={isDeletingRoom}>
              Cancel
            </button>
            <button
              className='cs-button ml1 small'
              disabled={needsToMeetDeleteConditions || isDeletingRoom}
              onClick={() => {
                deleteScreenerRoom(orgId, screenerRoom._id)
              }}>
              {isDeletingRoom ? "Please wait..." : "Delete screener room"}
            </button>
          </div>
        </div>
      </Modal>
    )
  }
}

Delete.propTypes = {
  toggleScreenerDeleteModal: PropTypes.func.isRequired,
  screenerRoom: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  deleteScreenerRoom: PropTypes.func.isRequired,
  isDeletingRoom: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  screenerRoom: state.screener_rooms.roomToDelete,
  orgId: state.user.auth.organization_id,
  isDeletingRoom: state.screener_rooms.isDeletingRoom
})

const mapDispatchToProps = dispatch => ({
  toggleScreenerDeleteModal: bindActionCreators(toggleScreenerDeleteModal, dispatch),
  deleteScreenerRoom: bindActionCreators(deleteScreenerRoom, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Delete)
