import React from "react"
import PropTypes from "prop-types"
import Aspera from "@src/public/aspera.png"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ButtonDropdown } from "@bitcine/cs-theme"
import { toggleModalVisibility, showAsperaSharing } from "@api/project/files/modals"
import { deleteFiles } from "@api/project/files/"
import { toggleMoveFolder } from "@api/project/folders/"
import { packageAndAsperaDownload } from "@api/download_link/aspera_downloads"
import { can, MODIFY_PROJECTS, DOWNLOAD_FILES, DELETE_FILES, SHARE_FILES } from "@src/helpers/authorization"
import Tagger from "../tags/tagger"
import 'styled-components/macro'

class Actions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMove: false
    }
  }
  showAsperaSharing() {
    this.props.showAsperaSharing(this.getCheckedFiles())
  }
  showDownloadSharing() {
    this.props.toggleModalVisibility("share", this.getFiles())
  }
  getCheckedFiles() {
    return this.props.files.list.filter(f => this.props.files.checked.includes(f._id))
  }
  deleteFiles() {
    this.props.toggleModalVisibility("delete", this.getFiles())
  }
  getFiles() {
    return this.props.files.list
      .filter(f => this.props.files.checked.includes(f._id))
      .map(({ _id, type, name }) => ({ _id, type, name }))
  }
  packageAndAsperaDownload() {
    this.props.packageAndAsperaDownload(this.props.projectID, this.getFiles())
  }
  render() {
    return (
      <div className='flex items-center'>
        {this.props.canDownload && <ButtonDropdown
          button={{
            className: `white ml1 small`,
            text: (
              <div className='flex items-center'>
                <span style={{ fontSize: "16px" }} className='material-icons mr1'>
                  download
                </span>
                Download
              </div>
            )
          }}
          dropdown={{
            clickCloses: true,
            arrow: true,
            content: [
              {
                text: {
                  title: "Download with Aspera",
                  description: "Faster speeds, uses Aspera Entitlements"
                },
                icon: (
                  <img
                    className='cs-img'
                    src={Aspera}
                    css={`
                      width: 20px;
                      height: 20px;
                      filter: grayscale(100%);
                    `}/>
                ),
                onClick: () => this.packageAndAsperaDownload(),
                show: true
              }
            ]
          }}/>}
        {this.props.canEdit && <ButtonDropdown
          button={{
            className: `white ml1 small`,
            text: (
              <div className='flex items-center'>
                <span style={{ fontSize: "16px" }} className='material-icons mr1'>
                  compare_arrows
                </span>
                Move
              </div>
            )
          }}
          dropdown={{
            clickCloses: true,
            arrow: true,
            content: [
              {
                text: {
                  title: "Move to Folder",
                  description: "Move to another folder in this project"
                },
                icon: "folder",
                onClick: () => this.props.toggleMoveFolder()
              },
              {
                text: {
                  title: "Move to Project",
                  description: "Move to another project in your account"
                },
                icon: "movie",
                onClick: () => this.props.toggleModalVisibility("moveFilesToProject", this.getFiles())
              }
            ]
          }}/>}
        {this.props.canShare && <ButtonDropdown
          button={{
            className: `white ml1 small`,
            text: (
              <div className='flex items-center'>
                <span style={{ fontSize: "16px" }} className='material-icons mr1'>
                  send
                </span>
                Share
              </div>
            )
          }}
          dropdown={{
            clickCloses: true,
            arrow: true,
            content: [
              {
                text: "Send a download Link",
                onClick: () => this.showDownloadSharing()
              },
              {
                text: "Send an Aspera download link",
                onClick: () => this.showAsperaSharing()
              }
            ]
          }}/>}
        
        {/* temporarily disable until tagging is fixed
        {this.props.canEdit && <ButtonDropdown
          button={{
            className: `small ml1 white`,
            text: (
              <div className='flex items-center'>
                <span style={{ fontSize: "16px" }} className='material-icons mr1'>
                  label
                </span>
                Tag
              </div>
            )
          }}
          dropdown={{
            clickCloses: false,
            arrow: true,
            content: <Tagger files={this.getCheckedFiles()}/>
          }}/>} */}
        {this.props.canDelete && <button className='cs-button small ml1 flex items-center white' onClick={() => this.deleteFiles()}>
          <span style={{ fontSize: "16px" }} className='material-icons mr1'>
            delete
          </span>
          Delete
        </button>}
      </div>
    )
  }
}

Actions.propTypes = {
  user: PropTypes.object,
  folderId: PropTypes.string,
  showAsperaSharing: PropTypes.func.isRequired,
  files: PropTypes.object.isRequired,
  toggleModalVisibility: PropTypes.func.isRequired,
  toggleMoveFolder: PropTypes.func.isRequired,
  filesToDelete: PropTypes.array,
  deleteFiles: PropTypes.func.isRequired,
  packageAndAsperaDownload: PropTypes.func.isRequired,
  projectID: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canDownload: PropTypes.bool.isRequired,
  canShare: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  files: state.project.files.items,
  canEdit: can(state.user.auth, [MODIFY_PROJECTS]),
  canDelete: can(state.user.auth, [DELETE_FILES]),
  canDownload: can(state.user.auth, [DOWNLOAD_FILES]),
  canShare: can(state.user.auth, [SHARE_FILES]),
})

const mapDispatchToProps = dispatch => ({
  showAsperaSharing: bindActionCreators(showAsperaSharing, dispatch),
  toggleModalVisibility: bindActionCreators(toggleModalVisibility, dispatch),
  deleteFiles: bindActionCreators(deleteFiles, dispatch),
  toggleMoveFolder: bindActionCreators(toggleMoveFolder, dispatch),
  packageAndAsperaDownload: bindActionCreators(packageAndAsperaDownload, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Actions)
