import React from "react"
import styled, { keyframes } from "react-emotion"

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`

const Icon = styled("i")`
  animation: ${rotate} 3s linear infinite;
  font-size: 5em;
`

const EncodingScreener = () => (
  <div className='center'>
    <Icon className='material-icons'>cached</Icon>
    <h4>Your Preview is Encoding!</h4>
    <p>Your preview is still encoding, but should be ready shortly. Check back soon!</p>
  </div>
)

export default EncodingScreener
