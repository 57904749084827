import React from "react"
import PropTypes from "prop-types"
import "./style.scss"

const FormError = ({ visible, msg }) =>
  visible && (
    <div className={`flex items-center error`}>
      <i className='material-icons'>error</i>
      <span>{msg}</span>
    </div>
  )

FormError.propTypes = {
  msg: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired
}

export default FormError
