import {
  GET_PROJECTS_PENDING,
  GET_PROJECTS_FULFILLED,
  GET_PROJECTS_FAILED,
  UPDATE_PROJECTS,
  ADD_PROJECT_PENDING,
  ADD_PROJECT_FULFILLED,
  ADD_PROJECT_FAILED,
  UPDATE_PROJECTS_LIST,
  PROJECT_IS_DRAGGING,
  PROJECT_IS_FINISHED_DRAGGING,
  TOGGLE_CHECKED_PROJECT,
  SET_CONVERT_PROJECT_TO_FOLDER,
  DELETE_PROJECTS_PENDING,
  DELETE_PROJECTS_FULFILLED,
  CLOSE_PROJECT_DELETION_ERROR_MODAL,
  TOGGLE_DELETE_PROJECT_MODAL
} from "@actions/projects/"

import { SAVE_ACCESS_FULFILLED } from "@actions/project/access"

import {
  CREATE_PROJECT_FOLDER_FULFILLED,
  REMOVE_PROJECTS_FROM_VIEW,
  MOVE_PROJECTS_TO_FOLDER_FULFILLED,
  FOLDER_HOVERED,
  UPDATE_PROJECT_FOLDER_TITLE,
  REMOVE_FOLDER_FROM_VIEW,
  DELETE_PROJECT_FOLDER_FULFILLED
} from "@actions/project_folders"

import { INIT_DEFROST_FULFILLED } from "@actions/project"

import update from "immutability-helper"

const initialState = {
  status: "PENDING",
  searchTerm: "",
  list: [],
  breadcrumb: [],
  filter: {
    search: ""
  },
  newProject: {
    visible: false,
    title: "",
    status: "READY"
  },
  showDeleteModal: false,
  deletingProjects: {
    projectsDeleted: [],
    projectsErroring: [],
    status: "READY",
    visible: false
  },
  editProjectTitle: "",
  projectIsDragging: false,
  hoveredFolder: {},
  checkedProjects: [],
  hoveredProject: null
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PROJECTS_PENDING:
      return {
        ...state,
        status: state.list.length ? "UPDATING" : "PENDING"
      }

    case GET_PROJECTS_FULFILLED:
    case CREATE_PROJECT_FOLDER_FULFILLED:
    case MOVE_PROJECTS_TO_FOLDER_FULFILLED:
    case DELETE_PROJECT_FOLDER_FULFILLED:
      return {
        ...state,
        status: "READY",
        list: action.payload.projects,
        breadcrumb: action.payload.breadcrumb,
        checkedProjects: [],
        hoveredProject: null
      }

    case SAVE_ACCESS_FULFILLED:
      return {
        ...state,
        list: state.list.map(project => (project._id === action.payload._id ? action.payload : project))
      }

    case GET_PROJECTS_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case UPDATE_PROJECTS:
      return {
        ...state,
        [action.key]: action.value
      }

    case ADD_PROJECT_PENDING:
      return {
        ...state,
        newProject: {
          ...state.newProject,
          status: "PENDING"
        }
      }

    case ADD_PROJECT_FAILED:
      return {
        ...state,
        newProject: {
          ...state.newProject,
          status: "ERROR"
        }
      }

    case ADD_PROJECT_FULFILLED:
      return {
        ...state,
        newProject: initialState.newProject
      }

    case UPDATE_PROJECTS_LIST:
      return {
        ...state,
        list: state.list.map(project =>
          project._id === action.projectID
            ? {
              ...project,
              [action.key]: action.value
            }
            : project
        )
      }

    case TOGGLE_DELETE_PROJECT_MODAL:
      return {
        ...state,
        showDeleteModal: !state.showDeleteModal
      }

    case DELETE_PROJECTS_PENDING:
      return {
        ...state,
        deletingProjects: {
          ...state.deletingProjects,
          status: "PENDING"
        }
      }

    case DELETE_PROJECTS_FULFILLED:
      return {
        ...state,
        list: action.payload.projects,
        breadcrumb: action.payload.breadcrumb,
        checkedProjects: [],
        hoveredProject: null,
        showDeleteModal: false,
        deletingProjects: {
          ...state.deletingProjects,
          status: "READY"
        }
      }

    case CLOSE_PROJECT_DELETION_ERROR_MODAL:
      return {
        ...state,
        showDeleteModal: false,
        deletingProjects: {
          ...state.deletingProjects,
          projectsErroring: []
        }
      }

    case UPDATE_PROJECTS_LIST:
      return {
        ...state,
        list: state.list.map(project =>
          project._id === action.projectID
            ? {
              ...project,
              [action.key]: action.value
            }
            : project
        )
      }

    case PROJECT_IS_DRAGGING:
      return {
        ...state,
        projectIsDragging: true,
        draggingProjectID: action._id
      }

    case PROJECT_IS_FINISHED_DRAGGING:
      return {
        ...state,
        projectIsDragging: false,
        draggingProjectID: null
      }

    case REMOVE_PROJECTS_FROM_VIEW:
      return {
        ...state,
        list: state.list.filter(project => !action.payload.projectIds.includes(project._id))
      }

    case FOLDER_HOVERED:
      return {
        ...state,
        hoveredFolderID:
          action.payload.folderID !== state.hoveredFolderID ? action.payload.folderID : state.hoveredFolderID
      }

    case TOGGLE_CHECKED_PROJECT:
      const index = state.checkedProjects.findIndex(projectId => projectId === action.payload.project._id)

      return update(state, {
        checkedProjects: index === -1 ? { $push: [action.payload.project._id] } : { $splice: [[index, 1]] }
      })

    case UPDATE_PROJECT_FOLDER_TITLE:
      return update(state, {
        list: {
          [state.list.findIndex(item => item.is_folder && item._id === action.payload.folder._id)]: {
            title: {
              $set: action.payload.title
            }
          }
        }
      })

    case REMOVE_FOLDER_FROM_VIEW:
      return {
        ...state,
        list: state.list.filter(project => project._id !== action.payload.folder._id)
      }

    case SET_CONVERT_PROJECT_TO_FOLDER:
      return {
        ...state,
        hoveredProject: action.payload.project
      }

    case INIT_DEFROST_FULFILLED:
      return {
        ...state,
        list: state.list.map(project =>
          project._id === action.payload.project_id
            ? {
              ...project,
              started_defrosting_at: action.payload.started_defrosting_at
            }
            : project
        )
      }

    default:
      return state
  }
}
