/**
 * Environment helpers
 *
 * Examples:
 *
 * 		import environment from '@helpers/environment'
 *
 * 		if (environment.is('production')) {
 * 			// Do something in production
 * 		}
 *
 * 		if (environment.is(['production', 'staging'])) {
 * 			// Do something in either the production or staging environments
 * 		}
 *
 * 		const api = environment.get('API_URL')
 */

import { GLOBALS } from "../config"

const environment = {
  is: envs => {
    return Array.isArray(envs) ? envs.includes(process.env.NODE_ENV) : envs === process.env.NODE_ENV
  },
  get: variable => {
    return GLOBALS[variable]
  }
}

export default environment
