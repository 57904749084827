import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toggleOrderVisibility, updateOrder } from "@api/project/orders"

const ConfirmClose = ({ toggleOrderVisibility, returnStep, updateOrder }) => (
  <div className='center py3'>
    <span className='muted inline-block large-icon material-icons'>remove_shopping_cart</span>
    <h4 className='mb0'>Cancel Order</h4>
    <p>Are you sure you want to cancel this order?</p>
    <div className='flex justify-center items-center'>
      <button
        style={{ width: "120px" }}
        onClick={() => updateOrder("step", returnStep)}
        className='cs-button white small mr1'>
        Continue
      </button>
      <button style={{ width: "120px" }} className='cs-button small ml1' onClick={() => toggleOrderVisibility()}>
        Cancel Order
      </button>
    </div>
  </div>
)

ConfirmClose.propTypes = {
  toggleOrderVisibility: PropTypes.func.isRequired,
  returnStep: PropTypes.string,
  updateOrder: PropTypes.func.isRequired
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  toggleOrderVisibility: bindActionCreators(toggleOrderVisibility, dispatch),
  updateOrder: bindActionCreators(updateOrder, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmClose)
