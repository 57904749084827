import {
  GET_TEAM_MEMBERS_PENDING,
  GET_TEAM_MEMBERS_FULFILLED,
  UPDATE_SEARCH_STRING,
  TOGGLE_ACTIVE_STATE_FULFILLED,
  DELETE_USER_FULFILLED,
  CHANGE_ROLE_FULFILLED,
  UPDATE_NEW_MEMBER_FIELD,
  INVITE_USER_PENDING,
  INVITE_USER_FULFILLED,
  TOGGLE_INVITE_MODAL,
  SHOW_PROJECT_ACCESS_MODAL,
  HIDE_PROJECT_ACCESS_MODAL,
  GET_USER_ACCESS_PROJECTS_PENDING,
  GET_USER_ACCESS_PROJECTS_FULFILLED
} from "@actions/settings/organizations"
import { SAVE_ACCESS_PENDING, SAVE_ACCESS_FULFILLED } from "@actions/project/access"

const initialState = {
  status: "PENDING",
  items: [],
  searchString: "",

  newMemberName: "",
  newMemberEmail: "",
  newMemberRole: "",
  isSendingInvite: false,
  showInviteModal: false,
  showAccessModal: false,
  projectAccessUser: null,
  isFetchingProjects: true,
  projects: []
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload.searchString
      }

    case GET_TEAM_MEMBERS_PENDING:
    case SAVE_ACCESS_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_TEAM_MEMBERS_FULFILLED:
    case TOGGLE_ACTIVE_STATE_FULFILLED:
    case CHANGE_ROLE_FULFILLED:
    case DELETE_USER_FULFILLED:
      return {
        ...state,
        status: "COMPLETE",
        items: action.payload
      }

    case SAVE_ACCESS_FULFILLED:
      return {
        ...state,
        status: "COMPLETE"
      }

    case UPDATE_NEW_MEMBER_FIELD:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      }

    case INVITE_USER_PENDING:
      return {
        ...state,
        isSendingInvite: true
      }

    case INVITE_USER_FULFILLED:
      return {
        ...state,
        newMemberName: "",
        newMemberEmail: "",
        newMemberRole: "",
        isSendingInvite: false,
        showInviteModal: false,
        items: action.payload
      }

    case TOGGLE_INVITE_MODAL:
      return {
        ...state,
        newMemberName: "",
        newMemberEmail: "",
        newMemberRole: "",
        showInviteModal: !state.showInviteModal
      }

    case SHOW_PROJECT_ACCESS_MODAL:
      return {
        ...state,
        showAccessModal: true,
        projectAccessUser: action.payload.user
      }

    case HIDE_PROJECT_ACCESS_MODAL:
      return {
        ...state,
        showAccessModal: false,
        projectAccessUser: null
      }

    case GET_USER_ACCESS_PROJECTS_PENDING:
      return {
        ...state,
        isFetchingProjects: true
      }

    case GET_USER_ACCESS_PROJECTS_FULFILLED:
      return {
        ...state,
        isFetchingProjects: false,
        projects: action.payload
      }

    default:
      return state
  }
}
