export const TOGGLE_ACCESS_MODAL = "TOGGLE_ACCESS_MODAL"
export const SHOW_ACCESS_INVITES = "SHOW_ACCESS_INVITES"
export const HIDE_ACCESS_INVITES = "HIDE_ACCESS_INVITES"
export const SAVE_ACCESS = "SAVE_ACCESS"
export const SAVE_ACCESS_PENDING = "SAVE_ACCESS_PENDING"
export const SAVE_ACCESS_FULFILLED = "SAVE_ACCESS_FULFILLED"
export const ADD_ACCESS_INVITE_ROW = "ADD_ACCESS_INVITE_ROW"
export const UPDATE_INVITE_ACCESS_ROW = "UPDATE_INVITE_ACCESS_ROW"
export const RESET_ACCESS_INVITES = "RESET_ACCESS_INVITES"
export const REMOVE_ACCESS_INVITE_ROW = "REMOVE_ACCESS_INVITE_ROW"
export const INVITE_USERS = "INVITE_USERS"
export const INVITE_USERS_PENDING = "INVITE_USERS_PENDING"
export const INVITE_USERS_FULFILLED = "INVITE_USERS_FULFILLED"
export const INVITE_USERS_FAILED = "INVITE_USERS_REJECTED"
export const UPDATE_ACCESS_USER_LIST = "UPDATE_ACCESS_USER_LIST"
