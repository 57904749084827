import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getScreenerEndpoints } from "@api/project/files/screeners"
import CineSendPlayer from "@bitcine/cinesend-player"

class Preview extends React.Component {
  constructor(props) {
    super(props)
    this.state = { endpoints: null }
  }
  componentDidMount() {
    this.props.getScreenerEndpoints(this.props.screener._id, "drm", res => {
      this.setState({ endpoints: res.data.endpoints })
    })
  }
  render() {
    return (
      <div style={{ height: '100%', aspectRatio: '16/9' }}>
        {this.state.endpoints &&
          <CineSendPlayer
            playerID={"files-cinesend-player-id"}
            debug={process.env.NODE_ENV !== "production"}
            playerCode={"bitmovin"}
            endpoints={this.state.endpoints}
            autoPlay={false}
            activeIndex={0}
            fillViewport={false}
            onVideoChange={video => console.log("Video set to: ", video)}
            onPlayerCreation={player => this.setState({ player })}
            onPlayerEvent={event => console.log("Event log: ", event)}
          />}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getScreenerEndpoints: bindActionCreators(getScreenerEndpoints, dispatch)
})

Preview.propTypes = {
  screener: PropTypes.object,
  getScreenerEndpoints: PropTypes.func.isRequired
}

export default connect(null, mapDispatchToProps)(Preview)
