import React from "react"
import PropTypes from "prop-types"
import Logo from "@src/public/logo.inline.svg"
import { Checkbox } from "@bitcine/cs-theme"

const PostPayLine = ({ checked, onSelect }) => (
  <label className='cs-label flex items-center p1 rounded my1 border-gray-5 border'>
    <Checkbox checked={checked} onChange={onSelect}/>
    <div className='inline-flex pl3'>Post Pay</div>
    <Logo style={{ width: "80px", float: "right" }} alt='CineSend'/>
  </label>
)

PostPayLine.propTypes = {
  onSelect: PropTypes.func,
  checked: PropTypes.bool.isRequired
}

export default PostPayLine
