import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toggleModalVisibility } from "@api/project/files/modals"
import { moveFilesToProject } from "@api/project/files/"
import { getAllProjects } from "@api/projects/"
import { Modal, Status } from "@bitcine/cs-theme"
import Select from "react-select"

class MoveFilesToProject extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      projectID: null
    }
  }
  componentDidMount() {
    this.props.getAllProjects()
  }
  getButtonText() {
    const activeProject = this.props.projects.list.find(({ _id }) => _id === this.state.projectID)
    return activeProject ? `to ${activeProject.title}` : "Here"
  }
  move() {
    this.props.moveFilesToProject(this.props.filesToMove, this.state.projectID)
  }
  render() {
    const { projects, toggleModalVisibility, filesToMoveStatus } = this.props
    return (
      <Modal title='Move Into Project' onClose={() => toggleModalVisibility("moveFilesToProject")} open={true}>
        <Status
          pending={projects.status === "PENDING" || filesToMoveStatus === "PENDING"}
          error={projects.status === "ERROR"}>
          {filesToMoveStatus === "COMPLETE" ? (
            <div>
              <h4>Success!</h4>
              <span className='mt1'>
                Your files are now transferring! This may take a few minutes. It is recommended that you do not modify
                these files until they are in your destination project. You will receive an email when the transfer is
                complete.
              </span>
            </div>
          ) : (
            <React.Fragment>
              <Select
                placerholder='Select the destination project...'
                options={projects.list.map(opt => ({ label: opt.title, value: opt._id }))}
                onChange={({ value }) => this.setState({ projectID: value })}
                clearable={false}
                value={this.state.projectID}/>
              <div className='mt2 flex justify-end'>
                <button disabled={!this.state.projectID} className='cs-button' onClick={() => this.move()}>
                  Move {this.getButtonText()}
                </button>
              </div>
            </React.Fragment>
          )}
        </Status>
      </Modal>
    )
  }
}

MoveFilesToProject.propTypes = {
  moveFilesToProject: PropTypes.func.isRequired,
  getAllProjects: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
  filesToMove: PropTypes.array,
  toggleModalVisibility: PropTypes.func.isRequired,
  filesToMoveStatus: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  projects: state.projects,
  filesToMove: state.project.files.modals.moveFilesToProject.files,
  filesToMoveStatus: state.project.files.modals.moveFilesToProject.status
})

const mapDispatchToProps = dispatch => ({
  getAllProjects: bindActionCreators(getAllProjects, dispatch),
  moveFilesToProject: bindActionCreators(moveFilesToProject, dispatch),
  toggleModalVisibility: bindActionCreators(toggleModalVisibility, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveFilesToProject)
