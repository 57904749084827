import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Table, Status } from "@bitcine/cs-theme"
import SearchIcon from "@src/public/icons/empty/search.inline.svg"
import NoReviewersIcon from "@src/public/icons/empty/reviewers.inline.svg"
import { setQueryValue } from "@api/shared_screeners"
import { can, VIEW_SCREENER_ANALYTICS } from "@helpers/authorization"
import ReviewerName from "../details/reviewer_name"
import ReviewerExpiry from "../details/reviewer_expiry"
import ReviewerAvailableAt from "../details/reviewer_available"
import ReviewerIcons from "../details/reviewer_icons"
import Created from "../details/created"
import LastSent from "../details/last_sent"
import LastViewed from "../details/last_viewed"
import Watched from "../details/watched"
import Dropdown from "./dropdown"
import ReviewerViewCountLimit from "../details/reviewer_view_count_limit"

class ReviewersTable extends React.Component {
  constructor() {
    super()
    this.state = {
      page: 0,
      take: 25
    }
  }
  onPageChange(page) {
    this.setState(
      state => ({ page }),
      () => this.props.setQueryValue("skip", page * this.state.take)
    )
  }
  render() {
    let { list, size, status, search, canViewAnalytics, useForensicWatermark } = this.props
    let { page, take } = this.state
    return (
      <Status
        pending={status === "PENDING"}
        error={status === "ERROR"}
        empty={list.length === 0}
        emptyMessage={{
          title: search.length ? "No results found" : "No reviewers",
          text: search.length ? "Try your search again" : "You have not shared any screeners yet!",
          icon: search.length ? <SearchIcon/> : <NoReviewersIcon/>
        }}>
        <Table
          className='mt3'
          widths={[useForensicWatermark ? 160 : 160, "auto", 160, 160, 125, 125, 110]
            .concat(canViewAnalytics ? [115, 120] : [])
            .concat([65])}
          paginate={{
            currentPage: page,
            pageCount: Math.ceil(size / take),
            onPageChange: page => this.onPageChange(page)
          }}
          header={{
            columns: ["Shared Details", "Reviewer", "Created", "Last Sent", "Available At", "Expiry Date", "View Count Limit"]
              .concat(canViewAnalytics ? ["Last Viewed", "Watched"] : [])
              .concat([""])
              .map(opt => ({
                text: opt
              }))
          }}
          body={{
            data: list,
            row: {
              render: [
                sharedScreener => (
                  <ReviewerIcons sharedScreener={sharedScreener} useForensicWatermark={useForensicWatermark}/>
                ),
                sharedScreener => <ReviewerName sharedScreener={sharedScreener}/>,
                sharedScreener => <Created sharedScreener={sharedScreener}/>,
                sharedScreener => <LastSent sharedScreener={sharedScreener}/>,
                sharedScreener => <ReviewerAvailableAt available_at={sharedScreener.available_at ?? null}/>,
                sharedScreener => <ReviewerExpiry expiry={sharedScreener.expiry}/>,
                sharedScreener => <ReviewerViewCountLimit viewCountLimit={sharedScreener.view_count_limit ?? null}/>
              ]
                .concat(
                  canViewAnalytics
                    ? [
                      sharedScreener => <LastViewed sharedScreener={sharedScreener}/>,
                      sharedScreener => (
                        <Watched sharedScreener={sharedScreener} isFirstReviewer={list[0] === sharedScreener._id}/>
                      )
                    ]
                    : []
                )
                .concat([sharedScreener => <Dropdown sharedScreener={sharedScreener}/>])
            }
          }}/>
      </Status>
    )
  }
}

ReviewersTable.propTypes = {
  status: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  size: PropTypes.number.isRequired,
  search: PropTypes.string,
  setQueryValue: PropTypes.func.isRequired,
  canViewAnalytics: PropTypes.bool.isRequired,
  useForensicWatermark: PropTypes.bool
}

const mapStateToProps = state => ({
  status: state.shared_screeners.status,
  list: state.shared_screeners.list,
  size: state.shared_screeners.size,
  search: state.shared_screeners.query.search,
  canViewAnalytics: can(state.user.auth, [VIEW_SCREENER_ANALYTICS]),
  useForensicWatermark:
    (state.project.files.screener && state.project.files.screener.use_forensic_watermark) ||
    (state.screener_rooms.screenerRoom.data && state.screener_rooms.screenerRoom.data.use_forensic_watermark)
})

const mapDispatchToProps = dispatch => ({
  setQueryValue: bindActionCreators(setQueryValue, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewersTable)
