import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Checkbox, Status } from "@bitcine/cs-theme"
import { connect } from "react-redux"
import { updateVideo } from "@api/ondemand_videos/"
import { bindActionCreators } from "redux"

const Card = ({
  video,
  children,
  title,
  submitButtonDisabled = false,
  submitted,
  onSubmit,
  isSubmitting = false,
  isUpload = false,
  isSource = false,
  allowedToCheckDownloadSource = true,
  className = "",
  updateVideo,
  organizationName
}) => {
  const [allowDownloadSource, setAllowDownloadSource] = useState(video.allow_download_source || false)
  const [checked, setChecked] = useState(false)
  return (
    <div className={`border ${submitted ? "border-gray-3" : "border-red"} mb3 rounded ${className}`}>
      <div className={`${submitted ? "bg-gray-3" : "bg-red"} p1 white`}>
        <h4>
          {title} ({submitted ? "Complete" : "Incomplete"})
        </h4>
      </div>
      <div className='p2'>
        <Status pending={isSubmitting}>
          {children}
          {!submitted && (
            <div className='flex flex-column border-top border-gray-5 pt1 mt2'>
              {isUpload && (
                <div className='flex flex-column'>
                  <Checkbox
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    className='ml2'
                    label={`I attest that I have the right to submit this content.`}/>
                </div>
              )}
              {isSource && checked && allowedToCheckDownloadSource && (
                <div className='ml2 pt1'>
                  <Checkbox
                    checked={allowDownloadSource}
                    onChange={() => {
                      updateVideo(video._id, { allow_download_source: !allowDownloadSource })
                      setAllowDownloadSource(!allowDownloadSource)
                    }}
                    className='pl4'
                    label={`(optional) Allow ${organizationName} to download this file.`}/>
                </div>
              )}
              <div className='flex justify-end pt2'>
                <Button
                  className='col-3'
                  small
                  disabled={(isUpload && !checked) || submitButtonDisabled}
                  onClick={onSubmit}>
                  Submit
                </Button>
              </div>
            </div>
          )}
        </Status>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  video: state.ondemand_videos.video,
  organizationName: state.ondemand_videos.organizationName,
  allowedToCheckDownloadSource: state.ondemand_videos.settings.can_allow_source_file_download
})

const mapDispatchToProps = dispatch => ({
  updateVideo: bindActionCreators(updateVideo, dispatch)
})

Card.propTypes = {
  video: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  submitButtonDisabled: PropTypes.bool,
  submitted: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onDownloadableToggle: PropTypes.func,
  isSubmitting: PropTypes.bool.isRequired,
  isUpload: PropTypes.bool.isRequired,
  isSource: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  updateVideo: PropTypes.func.isRequired,
  organizationName: PropTypes.string.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Card)
