import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import filterScreeners from "@helpers/filterScreeners"
import { can, MANAGE_SCREENERS } from "@src/helpers/authorization"
import ReactPaginate from "react-paginate"
import { getScreenerRooms, toggleCreateRoomModal } from "@api/screener_rooms/"
import { Status } from "@bitcine/cs-theme"
import NoScreenerRoomsIcon from "@src/public/icons/empty/screener-rooms.inline.svg"
import SearchIcon from "@src/public/icons/empty/search.inline.svg"
import CreateScreenerRoom from "../modals/create_screener_room"
import Delete from "../modals/delete"
import ScreenerRoomCard from "./components/card"
import 'styled-components/macro'

class ScreenerRooms extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: "",
      sortDir: "ASC",
      take: 12,
      skip: 0,
      currentPage: 0
    }
  }
  componentDidMount() {
    this.getRooms()
  }
  getRooms() {
    this.props.getScreenerRooms(this.props.organizationId, this.state.take, this.state.skip, this.state.search)
  }
  render() {
    const { toggleCreateRoomModal, showCreateRoomModal, canModify, screenerRooms, showDeleteModal } = this.props
    return (
      <div className='p2'>
        <div className='flex items-center mb2'>
          <h4 className='flex-auto' data-testid='screener_rooms_index_header'>
            Screener Rooms
          </h4>
          <div className='flex items-center'>
            <input
              className='cs-input'
              style={{ height: "34px" }}
              value={this.state.search}
              onChange={e => {
                this.setState({ search: e.target.value }, () => {
                  this.getRooms()
                })
              }}
              placeholder='Search...'/>
            {canModify && (
              <button
                onClick={toggleCreateRoomModal}
                className='cs-button ml1 small'
                data-testid='screener_rooms_create_btn'>
                Create Screener Room
              </button>
            )}
          </div>
        </div>
        <Status
          pending={screenerRooms.status === "PENDING"}
          error={screenerRooms.status === "ERROR"}
          empty={screenerRooms.items.length === 0}
          emptyMessage={{
            icon: this.state.search ? <SearchIcon/> : <NoScreenerRoomsIcon/>,
            title: this.state.search ? "No results found" : "You haven't created a Screener Room yet",
            text: this.state.search ? "Try your search again" : 'Click "Create screener room" above to get started'
          }}>
          <div
            className='flex flex-wrap'
            css={`
              margin: 0 -0.5em;
            `}>
            {screenerRooms.items.map((room, i) => (
              <div key={room._id} className='col-12 md-col-3'>
                <ScreenerRoomCard {...room} idx={i}/>
              </div>
            ))}
          </div>
        </Status>
        <div>
          {screenerRooms.count > 0 && screenerRooms.count >= this.state.take && (
            <ReactPaginate
              containerClassName='flex items-stretch justify-end py2 list-style-none mt2 cs-paginate'
              previousLabel='Back'
              forcePage={this.state.currentPage}
              pageCount={Math.ceil(screenerRooms.count / this.state.take)}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={({ selected }) => {
                this.setState(
                  {
                    currentPage: selected,
                    skip: selected * this.state.take
                  },
                  () => {
                    this.getRooms()
                  }
                )
              }}/>
          )}
        </div>
        {showCreateRoomModal && canModify && <CreateScreenerRoom/>}
        {showDeleteModal && <Delete/>}
      </div>
    )
  }
}

ScreenerRooms.propTypes = {
  screeners: PropTypes.array.isRequired,
  organizationId: PropTypes.string.isRequired,
  toggleCreateRoomModal: PropTypes.func.isRequired,
  showCreateRoomModal: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  canModify: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  screenerRooms: PropTypes.object.isRequired,
  getScreenerRooms: PropTypes.func.isRequired,
  showDeleteModal: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  screeners: filterScreeners(state.screener_rooms),
  status: state.screener_rooms.screeners.status,
  organizationId: state.user.auth.organization_id,
  showCreateRoomModal: state.screener_rooms.showCreateRoomModal,
  canModify: can(state.user.auth, [MANAGE_SCREENERS]),
  screenerRooms: state.screener_rooms.screenerRooms,
  showDeleteModal: state.screener_rooms.showDeleteModal
})

const mapDispatchToProps = dispatch => ({
  getScreenerRooms: bindActionCreators(getScreenerRooms, dispatch),
  toggleCreateRoomModal: bindActionCreators(toggleCreateRoomModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ScreenerRooms)
