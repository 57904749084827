import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import PiracyDetector from "@src/public/piracy-detector.inline.svg"
import { Checkbox } from "@bitcine/cs-theme"
import { GLOBALS } from "@src/config"
import "../styles.scss"
import 'styled-components/macro'

const Watermarking = ({ newReviewers, onUpdate, allowed, settingsLink, mode }) => (
  <div className='py2 border-bottom border-gray-5'>
    <div className='flex items-center'>
      <div className='flex-auto'>
        <div className='flex items-center'>
          <Checkbox
            id='enableWatermark'
            checked={newReviewers.watermarkEnabled || false}
            disabled={!allowed}
            onChange={e => onUpdate("watermarkEnabled", !newReviewers.watermarkEnabled)}/>
          <div className='pl1'>
            <h4 className='mb0 flex items-center'>
              Active Watermarking with
              <PiracyDetector
                css={`
                  height: 32px;
                  width: 132px;
                `}
                alt='Piracy Detector'/>
            </h4>
            <p className='mb0'>
              Enable active watermarking to protect your film from piracy.&nbsp;
              <a href='https://piracydetector.com' target='_blank' className='underline' rel='noopener noreferrer'>
                Learn More
              </a>
            </p>
          </div>
        </div>
      </div>
      {!allowed && (
        <div className='right-align'>
          <a href={GLOBALS.ACCOUNTS_URL + '/settings/billing'} target='_blank' rel='noopener noreferrer'>
            <button className='cs-button white small'>Upgrade Your Account</button>
          </a>
          <small className='block muted mt1'>All paid plans include Piracy Detector</small>
        </div>
      )}
    </div>
    {newReviewers.watermarkEnabled && (
      <div
        className='mt2'
        css={`
          padding-left: 2.8em;
        `}>
        <div className='col-8'>
          <label className='cs-label flex justify-between' htmlFor='watermarkName'>
            Watermark Text
          </label>
          <input
            value={newReviewers.text}
            id='watermarkName'
            onChange={e => {
              if (e.target.value.length < 21) {
                onUpdate("text", e.target.value)
              }
            }}
            className='cs-input col-12 block'/>
          <small className='muted block right-align'>{newReviewers.text.length} / 20</small>
        </div>
        {[
          { key: "ip", show: mode !== "email", label: "IP address" },
          { key: "email", show: mode === "email", label: "email" },
          { key: "name", show: mode === "email", label: "name" }
        ]
          .filter(opt => opt.show)
          .map(opt => (
            <div key={opt.key} className='flex items-center flex-auto'>
              <Checkbox
                id={"checkbox_" + opt.key}
                checked={newReviewers.second_line_text_type === opt.key}
                onChange={e =>
                  onUpdate("second_line_text_type", newReviewers.second_line_text_type === opt.key ? null : opt.key)
                }/>
              <label className='cs-label ml1' htmlFor={"checkbox_" + opt.key}>
                Include reviewer {opt.label} below watermark text
              </label>
            </div>
          ))}
        <div className='mt2'>
          <label className='cs-label'>Watermark Opacity</label>
          <div className='flex items-center'>
            {[50, 75, 100].map(o => (
              <div key={o} className='mr2 flex items-center'>
                <Checkbox
                  id={`${o}-label`}
                  checked={newReviewers.opacity === o}
                  onChange={() => onUpdate("opacity", o)}/>
                <label className='cs-label pl1' htmlFor={`${o}-label`}>
                  {o}%
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className='mt2'>
          <label className='cs-label'>Watermark Size</label>
          <div className='flex items-center'>
            {["small", "medium", "large"].map(size => (
              <div key={size} className='mr2 flex items-center'>
                <Checkbox
                  id={`${size}-label`}
                  checked={newReviewers.size === size}
                  onChange={() => onUpdate("size", size)}/>
                <label className='cs-label pl1 capitalize' htmlFor={`${size}-label`}>
                  {size}
                </label>
              </div>
            ))}
          </div>
        </div>
        <label className='cs-label mt2'>Watermark Position</label>
        <div
          style={{
            backgroundImage: `url(https://bitcinestorageaccount.blob.core.windows.net/cinesend-assets/media/patrick-tomasso-588212-unsplash.jpg)`
          }}
          className={`bg-cover bg-center rounded fakeFilm`}>
          <div className='absolute top-0 left-0 right-0 bottom-0 flex flex-column px2 py1 '>
            <div className='col-12 flex-auto flex items-stretch py1'>
              <a
                onClick={() => onUpdate("position", "top-left")}
                className={`col-5 rounded border ${
                  newReviewers.position === "top-left" ? "border-red" : "border-white"
                } flex items-center justify-center`}>
                {newReviewers.position === "top-left" && <ReviewerInfo mode={mode} newReviewers={newReviewers}/>}
              </a>
              <div className='flex-auto'/>
              <a
                onClick={() => onUpdate("position", "top-right")}
                className={`col-5 rounded border ${
                  newReviewers.position === "top-right" ? "border-red" : "border-white"
                } flex items-center justify-center`}>
                {newReviewers.position === "top-right" && <ReviewerInfo mode={mode} newReviewers={newReviewers}/>}
              </a>
            </div>
            <div className='col-12 flex-auto flex items-stretch py1'>
              <div className='flex-auto'/>
              <a
                onClick={() => onUpdate("position", "center-center")}
                className={`col-5 rounded border ${
                  newReviewers.position === "center-center" ? "border-red" : "border-white"
                } flex items-center justify-center`}>
                {newReviewers.position === "center-center" && <ReviewerInfo mode={mode} newReviewers={newReviewers}/>}
              </a>
              <div className='flex-auto'/>
            </div>
            <div className='col-12 flex-auto flex items-stretch py1'>
              <a
                onClick={() => onUpdate("position", "bottom-left")}
                className={`col-5 rounded border pointer ${
                  newReviewers.position === "bottom-left" ? "border-red" : "border-white"
                } flex items-center justify-center`}>
                {newReviewers.position === "bottom-left" && <ReviewerInfo mode={mode} newReviewers={newReviewers}/>}
              </a>
              <div className='flex-auto'/>
              <a
                onClick={() => onUpdate("position", "bottom-right")}
                className={`col-5 rounded border ${
                  newReviewers.position === "bottom-right" ? "border-red" : "border-white"
                } flex items-center justify-center`}>
                {newReviewers.position === "bottom-right" && <ReviewerInfo mode={mode} newReviewers={newReviewers}/>}
              </a>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
)

const scales = {
  small: 0.8,
  medium: 1,
  large: 1.2
}

const opacities = {
  "50": 0.65,
  "75": 0.85,
  "100": 1
}

const secondLines = {
  ip: "127.0.0.1",
  email: "email",
  name: "full_name"
}

const ReviewerInfo = ({ newReviewers, mode }) => (
  <div className='center white'>
    <div
      style={{
        transition: "all .3s linear",
        transform: `scale(${scales[newReviewers.size]})`,
        opacity: opacities[newReviewers.opacity.toString()]
      }}>
      <p className='my0 bold'>{newReviewers.text}</p>
      {newReviewers.second_line_text_type && (
        <small className='block bold'>
          {newReviewers.second_line_text_type == "ip"
            ? secondLines[newReviewers.second_line_text_type]
            : newReviewers.list[Object.keys(newReviewers.list)[0]][secondLines[newReviewers.second_line_text_type]]}
        </small>
      )}
    </div>
  </div>
)

ReviewerInfo.propTypes = {
  newReviewers: PropTypes.object.isRequired,
  mode: PropTypes.string
}

const mapStateToProps = state => ({
  allowed: state.user.auth.can_use_piracy_detector,
  settingsLink: state.user.auth.settings_link,
  mode: state.preview.send.mode
})

Watermarking.propTypes = {
  newReviewers: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  allowed: PropTypes.bool.isRequired,
  settingsLink: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired
}

export default connect(mapStateToProps)(Watermarking)
