export const GET_FILE_REQUEST = "GET_FILE_REQUEST"
export const GET_FILE_REQUEST_PENDING = "GET_FILE_REQUEST_PENDING"
export const GET_FILE_REQUEST_FULFILLED = "GET_FILE_REQUEST_FULFILLED"
export const GET_FILE_REQUEST_FAILED = "GET_FILE_REQUEST_REJECTED"

export const GET_FILE_REQUEST_SILENT = "GET_FILE_REQUEST_SILENT"
export const GET_FILE_REQUEST_SILENT_PENDING = "GET_FILE_REQUEST_SILENT_PENDING"
export const GET_FILE_REQUEST_SILENT_FULFILLED = "GET_FILE_REQUEST_SILENT_FULFILLED"
export const GET_FILE_REQUEST_SILENT_FAILED = "GET_FILE_REQUEST_SILENT_REJECTED"

export const SET_FILE_REQUEST = "SET_FILE_REQUEST"
export const UPDATE_FILE_REQUEST = "UPDATE_FILE_REQUEST"

export const SELECT_EXISTING_FILE_REQUEST = "SELECT_EXISTING_FILE_REQUEST"
export const SELECT_EXISTING_FILE_REQUEST_PENDING = "SELECT_EXISTING_FILE_REQUEST_PENDING"
export const SELECT_EXISTING_FILE_REQUEST_FAILED = "SELECT_EXISTING_FILE_REQUEST_REJECTED"
export const SELECT_EXISTING_FILE_REQUEST_FULFILLED = "SELECT_EXISTING_FILE_REQUEST_FULFILLED"
