import { DISMISS_TOUR_STEP, SET_TOUR_STEPS, START_TOUR, TRIGGER_TOUR_STEP } from "@src/redux/actions/tour"

const initialState = {
  steps: []
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_TOUR_STEPS:
      return {
        ...state,
        steps: action.steps.map(step => ({
          ...step,
          active: false
        }))
      }

    case START_TOUR:
      return {
        ...state,
        steps: state.steps.map((step, i) => ({
          ...step,
          active: i === 0
        }))
      }

    case DISMISS_TOUR_STEP:
      const idx = state.steps.findIndex(step => step.active)

      return {
        ...state,
        steps: state.steps.map((step, i) => ({
          ...step,
          active: idx + 1 === state.steps.length ? false : i === idx + 1
        }))
      }

    case TRIGGER_TOUR_STEP:
      return {
        ...state,
        steps: [
          {
            key: action.key,
            active: true
          }
        ]
      }

    default:
      return state
  }
}
