export const GET_DEPARTMENTS = "GET_DEPARTMENTS"
export const GET_DEPARTMENTS_PENDING = "GET_DEPARTMENTS_PENDING"
export const GET_DEPARTMENTS_FULFILLED = "GET_DEPARTMENTS_FULFILLED"

export const TOGGLE_CREATE_DEPARTMENT_MODAL = "TOGGLE_CREATE_DEPARTMENT_MODAL"
export const TOGGLE_EDIT_DEPARTMENT_MODAL = "TOGGLE_EDIT_DEPARTMENT_MODAL"

export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT"
export const CREATE_DEPARTMENT_PENDING = "CREATE_DEPARTMENT_PENDING"
export const CREATE_DEPARTMENT_FULFILLED = "CREATE_DEPARTMENT_FULFILLED"

export const EDIT_DEPARTMENT = "EDIT_DEPARTMENT"
export const EDIT_DEPARTMENT_PENDING = "EDIT_DEPARTMENT_PENDING"
export const EDIT_DEPARTMENT_FULFILLED = "EDIT_DEPARTMENT_FULFILLED"

export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT"
export const DELETE_DEPARTMENT_PENDING = "DELETE_DEPARTMENT_PENDING"
export const DELETE_DEPARTMENT_FULFILLED = "DELETE_DEPARTMENT_FULFILLED"
