import React from "react"
import PropTypes from "prop-types"
import Watermark from "@components/icons/watermark"
import ForensicWatermark from "@components/icons/forensic_watermark"
import Password from "@components/icons/password"
import Type from "@components/icons/type"
import ShareType from "@components/icons/share_type"
import Comment from "@components/icons/comment"

const ReviewerIcons = ({ sharedScreener, useForensicWatermark }) => (
  <div className='flex items-center justify-between col-12 mr2'>
    <Watermark active={!!(sharedScreener.watermark && typeof sharedScreener.watermark === "object")}/>
    {useForensicWatermark && <ForensicWatermark active={!!sharedScreener.use_forensic_watermark}/>}
    <Password active={sharedScreener.has_password}/>
    <Type sharedScreener={sharedScreener}/>
    <ShareType sharedScreener={sharedScreener}/>
    <Comment enabled={sharedScreener?.commenting_enabled} />
  </div>
)

ReviewerIcons.propTypes = {
  sharedScreener: PropTypes.object.isRequired,
  useForensicWatermark: PropTypes.bool
}

export default ReviewerIcons
